export const gamesBreakdown = {
    battles: (
        gameId = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        publicSeed = new Date().getTime() * 3,
        roundCount = 2,
        playerCount = 2
    ) => `
const crypto = require('crypto');
const gameId = '${gameId}';
const serverSeed = '${serverSeed}';
const publicSeed = '${publicSeed}';
const roundCount = ${roundCount};
const playerCount = ${playerCount};

const fairGetBattlesOutcome = () => {
    for(round = 0; round < roundCount; round++) {
        for(player = 0; player < playerCount; player++) {
            const combined = \`\${gameId}-\${serverSeed}-\${publicSeed}-\${round}-\${player}\`;
            const hash = crypto.createHash('sha256').update(combined).digest('hex');
            const outcome = parseInt(hash.substr(0, 8), 16) % 10000;

            console.log(\`Round: \${round + 1} - Player: \${player + 1} - Outcome: \${outcome}\`);
        }
    }
}

fairGetBattlesOutcome();`,
    blackjack: (
        gameId = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        publicSeed = new Date().getTime() * 3
    ) => `
const crypto = require('crypto');
const ChanceJs = require('chance');

const cardRanks = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
const cardSuits = ['heart', 'spade', 'diamond', 'club'];

const deckCount = 8;
const gameId = '${gameId}';
const serverSeed = '${serverSeed}';
const publicSeed = '${publicSeed}';

const fairGenerateBlackjackDeck = () => {
    let deck = [];

    for(let d =  0; d &lt; deckCount; d++) {
        for(let s = 0; s &lt; cardSuits.length; s++) {
            for(let r = 0; r &lt; cardRanks.length; r++) {
                deck.push({ rank: cardRanks[r], suit: cardSuits[s] });
            }
        }
    }

    return deck;
}

const fairShuffleBlackjackDeck = (deck) => {
    const combined = \`\${gameId}-\${serverSeed}-\${publicSeed}\`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const chance = new ChanceJs(hash);
    deck = chance.shuffle(deck);

    console.log(deck);
}

let deck = fairGenerateBlackjackDeck();
fairShuffleBlackjackDeck(deck);`,
    crash: (gameId = new Date().getTime(), serverSeed = new Date().getTime() * 2) => `
const crypto = require('crypto');

const gameId = '${gameId}';
const serverSeed = '${serverSeed}';

const divisible = (hash, mod) => {
    let val = 0;

    let o = hash.length % 4;
    for (let i = o > 0 ? o - 4 : 0; i < hash.length; i += 4) {
        val = ((val << 16) + parseInt(hash.substring(i, i + 4), 16)) % mod;
    }

    return val === 0;
}

const fairGetCrashOutcome = () => {
    const combined = \`\${gameId}-\${serverSeed}\`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const mod = parseInt(100 / (0.05 * 100));
    if(divisible(hash, mod) === true) {
        return 100;
    }

    const h = parseInt(hash.slice(0, 52 / 4), 16);
    const e = Math.pow(2, 52);
    const roll = Math.floor((100 * e - h) / (e - h));

    console.log(\`Outcome: \${roll}\`);
}

fairGetCrashOutcome();`,
    duels: (
        gameId = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        publicSeed = new Date().getTime() * 3,
        playerCount = 2
    ) => `
const crypto = require('crypto');

const gameId = '${gameId}';
const serverSeed = '${serverSeed}';
const publicSeed = '${publicSeed}';
const playerCount = ${playerCount};

const fairGetDuelsOutcome = () => {
    for(index = 0; index < playerCount; index++) {
        const combined = \`\${gameId}-\${serverSeed}-\${publicSeed}-\${index}\`;
        const hash = crypto.createHash('sha256').update(combined).digest('hex');
        const roll = parseInt(hash.substr(0, 8), 16) % 10000;

        console.log(\`Player: \${index + 1} - Outcome: \${roll}\`);
    }
}

fairGetDuelsOutcome();`,
    mines: (
        clientSeed = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        nonce = new Date().getTime() * 3,
        minesCount = 1
    ) => `
const crypto = require('crypto');
const ChanceJs = require('chance');

const clientSeed = '${clientSeed}';
const serverSeed = '${serverSeed}';
const nonce = ${nonce};
const minesCount = ${minesCount};

const fairGenerateMinesDeck= () => {
    let deck = [];

    for(let i = 0; i &lt; 25; i++) {
        if(i &lt; minesCount) {
            deck.push('mine');
        } else {
            deck.push('coin');
        }
    }

    return deck;
}

const fairGetMinesOutcome = () => {
    let deck = fairGenerateMinesDeck();

    const combined = \`\${serverSeed}-\${nonce}-\${clientSeed}\`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const chance = new ChanceJs(hash);
    deck = chance.shuffle(deck);

    console.log(deck);
}
fairGetMinesOutcome();`,
    roll: (
        gameId = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        publicSeed = new Date().getTime() * 3
    ) => `
const crypto = require('crypto');

const gameId = '${gameId}';
const serverSeed = '${serverSeed}';
const publicSeed = '${publicSeed}';

const divisible = (hash, mod) => {
    let val = 0;

    let o = hash.length % 4;
    for (let i = o > 0 ? o - 4 : 0; i < hash.length; i += 4) {
        val = ((val << 16) + parseInt(hash.substring(i, i + 4), 16)) % mod;
    }

    return val === 0;
}

const fairGetRollOutcome = () => {
    const combined = \`\${gameId}-\${serverSeed}-\${publicSeed}\`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const mod = parseInt(100 / (0.05 * 100));
    if(divisible(hash, mod) === true) {
        return 100;
    }

    const h = parseInt(hash.slice(0, 52 / 4), 16);
    const e = Math.pow(2, 52);

    const roll = Math.floor((100 * e - h) / (e - h));

    console.log(\`Outcome: \${roll}\`);
}

fairGetRollOutcome();`,
    towers: (
        clientSeed = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        nonce = new Date().getTime() * 3,
        towersRisk = 'easy'
    ) => `
const crypto = require('crypto');
const ChanceJs = require('chance');

const clientSeed = '${clientSeed}';
const serverSeed = '${serverSeed}';
const nonce = ${nonce};
const towersRisk = '${towersRisk}';

const fairGenerateTowersDeck = () => {
    let deck = [];

    for(let rowIndex = 0; rowIndex &lt; 8; rowIndex++) {
        const tilesPerRow = towersRisk === 'medium' ? 2 : 3;
        const losePerRow = towersRisk === 'hard' ? 2 : 1;

        deck[rowIndex] = [];
        for(let tileIndex = 0; tileIndex &lt; tilesPerRow; tileIndex++) {
            if(tileIndex &lt; losePerRow) {
                deck[rowIndex].push('lose');
            } else {
                deck[rowIndex].push('coin');
            }
        }
    }

    return deck;
}

const fairGetTowersOutcome = () => {
    let deck = fairGenerateTowersDeck();
    const combined = \`\${serverSeed}-\${nonce}-\${clientSeed}\`;

    for(let rowIndex = 0; rowIndex &lt; 8; rowIndex++) {
        const hash = crypto.createHash('sha256').update(\`\${combined}-\${rowIndex}\`).digest('hex');

        const chance = new ChanceJs(hash);
        deck[rowIndex] = chance.shuffle(deck[rowIndex]);
    }

    console.log(deck);
}

fairGetTowersOutcome();`,
    unbox: (
        clientSeed = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        nonce = new Date().getTime() * 3
    ) => `
const crypto = require('crypto');

const clientSeed = '${clientSeed}';
const serverSeed = '${serverSeed}';
const nonce = ${nonce};

const fairGetUnboxOutcome = () => {
    const combined = \`\${serverSeed}-\${nonce}-\${clientSeed}\`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');
    const outcome = parseInt(hash.substr(0, 8), 16) % 100000;

    console.log(outcome);
}

fairGetUnboxOutcome();`,
    upgrader: (
        clientSeed = new Date().getTime(),
        serverSeed = new Date().getTime() * 2,
        nonce = new Date().getTime() * 3,
        multiplier = 2,
        betAmount = 100
    ) => `
const crypto = require('crypto');

const clientSeed = '${clientSeed}';
const serverSeed = '${serverSeed}';
const nonce = ${nonce};
const multiplier = ${multiplier};
const betAmount = ${betAmount};

const fairGetUpgraderOutcome = () => {
    const combined = \`\${serverSeed}-\${nonce}-\${clientSeed}\`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const outcome = parseInt(hash.substr(0, 8), 16) % 100000;

    const payout = outcome < (100000 / multiplier) ? Math.floor(betAmount * multiplier) : 0;

    return {
        outcome: payout !== 0 ? 'Win' : 'Lose',
        payout: payout
    };
}

console.log(fairGetUpgraderOutcome());
`
};
