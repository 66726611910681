<template>
    <div :class="$style['cashier-deposit']">
        <div :class="$style['section-title']">Roblox</div>
        <div :class="$style['deposit-section']">
            <CashierElement
                v-on:click.native="modalRobuxButton()"
                type="roblox"
                method="robux"
                v-bind:enabled="generalSettings.robux.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalLimitedsButton()"
                type="roblox"
                method="limiteds"
                v-bind:enabled="generalSettings.limited.deposit.enabled"
            />
            <!-- <CashierElement
                v-on:click.native="modalSteamButton('csgo')"
                type="skinify"
                method="csgo"
                v-bind:enabled="generalSettings.steam.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalSteamButton('dota2')"
                type="skinify"
                method="dota"
                v-bind:enabled="generalSettings.steam.deposit.enabled"
            /> -->
        </div>
        <div :class="$style['section-title']">Crypto & Fiat</div>
        <div :class="$style['deposit-section']">
            <CashierElement
                v-on:click.native="modalCreditButton()"
                type="fiat"
                method="credit"
                v-bind:enabled="generalSettings.credit.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('btc')"
                type="crypto"
                method="btc"
                v-bind:enabled="generalSettings.crypto.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('eth')"
                type="crypto"
                method="eth"
                v-bind:enabled="generalSettings.crypto.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('ltc')"
                type="crypto"
                method="ltc"
                v-bind:enabled="generalSettings.crypto.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('usdt')"
                type="crypto"
                method="usdt"
                v-bind:enabled="generalSettings.crypto.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('usdc')"
                type="crypto"
                method="usdc"
                v-bind:enabled="generalSettings.crypto.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('sol')"
                type="crypto"
                method="sol"
                v-bind:enabled="generalSettings.crypto.deposit.enabled"
            />
        </div>
        <div :class="$style['section-title']">Gift Cards</div>
        <div :class="$style['deposit-section']">
            <CashierElement
                v-on:click.native="modalGiftButton('kinguin')"
                type="gift"
                method="kinguin"
                v-bind:enabled="generalSettings.gift.deposit.enabled"
            />
            <CashierElement
                v-on:click.native="modalGiftButton('g2a')"
                type="gift"
                method="g2a"
                v-bind:enabled="generalSettings.gift.deposit.enabled"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import CashierElement from '@/components/cashier/CashierElement';

    export default {
        name: 'CashierDepositTab',
        components: {
            CashierElement
        },
        data() {
            return {
                modalGiftCode: null
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'modalsSetShow', 'modalsSetData']),
            modalRobuxButton() {
                this.$parent.modalTab = 'Robux';
                this.modalsSetData({ typeCashier: 'deposit' });
            },
            modalLimitedsButton() {
                this.modalsSetShow(null);
                this.modalsSetData({ typeCashier: 'deposit' });

                this.$router.push({ name: 'LimitedsDeposit' });
            },
            modalSteamButton(game) {
                this.$parent.modalTab = 'Proceed';
                this.modalsSetData({ typeCashier: 'deposit', provider: 'skinify', game: game });
            },
            modalCreditButton() {
                this.$parent.modalTab = 'Credit';
                this.modalsSetData({ typeCashier: 'deposit' });
            },
            modalCryptoButton(currency) {
                this.$parent.modalTab = 'Crypto';
                this.modalsSetData({ typeCashier: 'deposit', currency: currency });
            },
            modalGiftButton(provider) {
                this.$parent.modalTab = 'Gift';
                this.modalsSetData({ typeCashier: 'deposit', provider: provider });
            }
        },
        computed: {
            ...mapGetters(['generalSettings', 'socketSendLoading', 'userBalanceData'])
        }
    };
</script>

<style module>
    .cashier-deposit {
        margin-bottom: 9px;
        width: 100%;
    }

    .cashier-deposit .deposit-section {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-top: 7px;
        gap: 7px;
    }

    .cashier-deposit .deposit-section:not(:last-child) {
        margin-bottom: 20px;
    }

    .cashier-deposit .section-title {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        color: #5b85ae;
    }

    .cashier-deposit .section-gift {
        width: calc(50% - 18px);
        margin-left: 12px;
    }

    .cashier-deposit .gift-title {
        font-size: 13px;
        font-weight: 400;
        color: #5191b1;
    }

    .cashier-deposit .gift-input {
        width: 100%;
        height: 64px;
        position: relative;
        margin-top: 7px;
    }

    .cashier-deposit .gift-input input {
        width: 100%;
        height: 100%;
        padding: 0 115px 0 20px;
        border-radius: 12px;
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        background: #072131;
        border: 1px dashed #123651;
    }

    .cashier-deposit .gift-input input::placeholder {
        color: #bbbfd0;
    }

    .cashier-deposit button.button-redeem {
        width: 95px;
        height: 36px;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(0, -50%);
    }

    .cashier-deposit button.button-redeem .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 800;
        color: #ffffff;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        clip-path: polygon(
            5px 0,
            calc(100% - 5px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 5px) 100%,
            5px 100%,
            0 75%,
            0 25%
        );
    }

    @media only screen and (max-width: 840px) {
        .cashier-deposit .section-gift {
            width: calc(33.33% - 8px);
            margin-left: 0;
        }
    }

    @media only screen and (max-width: 840px) {
        .cashier-deposit .section-gift {
            width: 100%;
            margin-top: 12px;
            margin-left: 0;
        }
    }

    @media only screen and (max-width: 700px) {
        .cashier-deposit .deposit-section {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 460px) {
        .cashier-deposit .deposit-section {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
</style>
