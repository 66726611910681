<template>
    <div :class="$style['limiteds-deposit']">
        <transition name="fade" mode="out-in">
            <div
                v-if="cashierGetItems === null || cashierLimitedData.loading === true"
                :class="$style['deposit-loading']"
                key="loading"
            >
                <LoadingAnimation />
            </div>
            <div v-else-if="cashierGetItems.length > 0" :class="$style['deposit-list']" key="data">
                <div :class="$style['data-list']">
                    <CashierItemElement
                        v-for="item in cashierGetItems"
                        v-bind:key="item.uniqueId"
                        v-bind:item="{ ...item, amountFixed: item.amount }"
                    />
                </div>
            </div>
            <div v-else :class="$style['deposit-empty']" key="empty">No items found.</div>
        </transition>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import CashierItemElement from '@/components/cashier/CashierItemElement';

    export default {
        name: 'LimitedsDeposit',
        components: {
            LoadingAnimation,
            CashierItemElement
        },
        methods: {
            ...mapActions(['cashierEmptyLimitedDataSelected'])
        },
        computed: {
            ...mapGetters([
                'authUser',
                'cashierFilterSearch',
                'cashierFilterAmountMin',
                'cashierFilterAmountMax',
                'cashierFilterSort',
                'cashierLimitedData'
            ]),
            cashierGetItems() {
                let items = this.cashierLimitedData.deposit;

                if (items !== null) {
                    items = items.filter(
                        (element) =>
                            this.cashierLimitedData.withdraw.some(
                                (transaction) =>
                                    transaction.items.some(
                                        (item) => item.uniqueId === element.uniqueId
                                    ) === true
                            ) === false
                    );
                    items = items.filter(
                        (element) =>
                            element.name
                                .toLowerCase()
                                .includes(this.cashierFilterSearch.toLowerCase().trim()) === true
                    );

                    if (this.cashierFilterSort === 'Highest') {
                        items.sort((a, b) => {
                            return b.amount - a.amount;
                        });
                    } else {
                        items.sort((a, b) => {
                            return a.amount - b.amount;
                        });
                    }

                    if (
                        this.cashierFilterAmountMin.trim() !== '' &&
                        isNaN(this.cashierFilterAmountMin) !== true
                    ) {
                        items = items.filter(
                            (element) =>
                                element.amount >= Math.floor(this.cashierFilterAmountMin * 1000)
                        );
                    }

                    if (
                        this.cashierFilterAmountMax.trim() !== '' &&
                        isNaN(this.cashierFilterAmountMax) !== true
                    ) {
                        items = items.filter(
                            (element) =>
                                element.amount <= Math.floor(this.cashierFilterAmountMax * 1000)
                        );
                    }

                    items = items.slice(0, this.cashierLimitedData.page * 60);
                }

                return items;
            }
        },
        destroyed() {
            this.cashierEmptyLimitedDataSelected();
        }
    };
</script>

<style scoped>
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active,
    .fade-enter-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-enter-from {
        opacity: 0;
    }
</style>

<style module>
    .limiteds-deposit {
        width: 100%;
    }

    .limiteds-deposit .deposit-loading {
        width: 100%;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .limiteds-deposit .deposit-list {
        width: 100%;
    }

    .limiteds-deposit .deposit-empty {
        width: 100%;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    .data-list {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 6px;
    }

    @media screen and (max-width: 1700px) {
        .data-list .unbox-item-element {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 1700px) {
        .data-list .unbox-item-element {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 1300px) {
        .data-list .unbox-item-element {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 1100px) {
        .data-list .unbox-item-element {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 900px) {
        .data-list .unbox-item-element {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 800px) {
        .data-list {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        .data-list .unbox-item-element {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 700px) {
        .data-list .unbox-item-element {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 600px) {
        .data-list {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .data-list .unbox-item-element {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 550px) {
        .data-list .unbox-item-element {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 500px) {
        .data-list {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .data-list .unbox-item-element {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 450px) {
        .data-list .unbox-item-element {
            font-size: 9px;
        }
    }
</style>
