<template>
    <div :class="[$style['roll-game'], $style[rollState]]">
        <div :class="$style['game-timer']">
            <!-- <div class="timer-text">{{ rollText }}</div> -->
            <div
                :class="$style['timer-progress']"
                v-bind:style="{ width: (100 / 13) * rollTimer + '%' }"
            ></div>
        </div>
        <div :class="$style['game-spinner']">
            <canvas id="roll-confetti" :class="$style['spinner-confetti']"></canvas>
            <div :class="$style['spinner-selector']"></div>
            <div :class="$style['spinner-inner']">
                <ol :class="$style['inner-reel']" v-bind:style="rollReelStyle">
                    <RollCard
                        v-for="(multiplier, index) in rollGetMultipliers"
                        v-bind:key="index"
                        v-bind:multiplier="multiplier"
                        v-bind:active="
                            [9, 49].includes(index) && ['betting', 'win'].includes(rollState)
                        "
                    />
                </ol>
            </div>
        </div>
        <!-- <div class="game-win">
            <transition name="fade-slide" mode="out-in">
                <RollGameWin
                    v-if="rollWin === true && rollGetWin.cryptoWin + rollGetWin.robuxWin > 0"
                    v-bind:win="rollGetWin"
                    v-bind:multiplier="rollGame.outcome"
                />
            </transition>
        </div> -->
    </div>
</template>

<script>
    import CryptoJS from 'crypto-js';
    import JSConfetti from 'js-confetti';
    import { mapGetters } from 'vuex';
    import RollCard from '@/components/roll/RollCard';
    import RollGameWin from '@/components/roll/RollGameWin';

    export default {
        name: 'RollGame',
        components: {
            RollCard,
            RollGameWin
        },
        data() {
            return {
                rollConfetti: null,
                rollTimerRepeater: null,
                rollTimer: 0,
                rollState: 'betting',
                rollReelStyle: {
                    transform: `translateX(${this.rollGetPercentage(9)}%)`,
                    transition: 'none'
                },
                rollWin: false
            };
        },
        methods: {
            rollGetPercentage(index, offset = 0.5) {
                const total = 60;
                const gap = 4;
                const item = 168;
                const totalWidth = total * item + gap * (total - 1);
                const pinPoint = index * item + Math.max(0, (index - 1) * gap) + item * offset;
                return (pinPoint / totalWidth) * -100;
            },
            rollIsHashDivisible(combined, mod) {
                let val = 0;

                let o = combined.length % 4;
                for (let i = o > 0 ? o - 4 : 0; i < combined.length; i += 4) {
                    val = ((val << 16) + parseInt(combined.substring(i, i + 4), 16)) % mod;
                }

                return val === 0;
            },
            rollStartTimer() {
                const timeEnding = new Date(this.rollGame.createdAt).getTime() + 1000 * 13;
                this.rollTimer =
                    (timeEnding - (new Date().getTime() + this.generalTimeDiff)) / 1000;

                this.rollState = 'betting';

                if (this.rollTimer <= 0) {
                    this.rollTimer = 0;
                    this.rollState = 'rolling';
                } else {
                    this.rollTimerRepeater = requestAnimationFrame(this.rollStartTimer);
                }
            }
        },
        computed: {
            ...mapGetters([
                'generalTimeDiff',
                'authUser',
                'rollGame',
                'rollBets',
                'rollHistory',
                'soundVolume',
                'soundRoll'
            ]),
            rollGetMultipliers() {
                let multipliers = [];

                if (this.rollGame !== null) {
                    for (let row = 0; row < 3; row++) {
                        for (let card = 0; card < 20; card++) {
                            if (row === 0 && card === 9) {
                                multipliers.push(this.rollHistory[0].outcome / 100);
                            } else if (
                                (this.rollGame.state === 'rolling' ||
                                    this.rollGame.state === 'completed') &&
                                row === 2 &&
                                card === 9
                            ) {
                                multipliers.push(this.rollGame.outcome / 100);
                            } else {
                                const gameId =
                                    row === 0 ? this.rollHistory[0]._id : this.rollGame._id;
                                const mod = parseInt(100 / (0.05 * 100));
                                let combined = CryptoJS.SHA256(card + gameId).toString();

                                if (this.rollIsHashDivisible(combined, mod) === true) {
                                    multipliers.push(1);
                                    continue;
                                }

                                const h = parseInt(combined.slice(0, 52 / 4), 16);
                                const e = Math.pow(2, 52);

                                multipliers.push(Math.floor((100 * e - h) / (e - h)) / 100);
                            }
                        }
                    }
                }

                return multipliers;
            },
            rollGetWin() {
                let confetti = false;
                let cryptoWin = 0;
                let robuxWin = 0;

                if (this.authUser.user !== null && this.rollGame.outcome !== undefined) {
                    for (const bet of this.rollBets.filter(
                        (element) => element.user._id === this.authUser.user._id
                    )) {
                        if (this.rollGame.outcome >= bet.multiplier) {
                            if (bet.multiplier >= 750) confetti = true;

                            if (bet.coinType === 'robuxCoin')
                                robuxWin =
                                    robuxWin + Math.floor(bet.amount * (bet.multiplier / 100));
                            if (bet.coinType === 'cryptoCoin')
                                cryptoWin =
                                    cryptoWin + Math.floor(bet.amount * (bet.multiplier / 100));
                        }
                    }
                }

                if (cryptoWin + robuxWin >= 10000 * 1000) confetti = true;

                return {
                    confetti,
                    robuxWin,
                    cryptoWin
                };
            }
        },
        watch: {
            rollGame: {
                handler(data, oldData) {
                    if (data.state === 'created') {
                        this.rollWin = false;

                        // Get random 0.1 to 0.9 from previous roll ._id
                        const offset =
                            0.1 +
                            ((parseInt(this.rollHistory[0]._id.substr(0, 8), 16) % 9) / 8) * 0.8;
                        Math.abs(parseInt(this.rollHistory[0]._id.substr(0, 8), 16)) % 9;

                        this.rollReelStyle = {
                            transform: `translateX(${this.rollGetPercentage(9, offset)}%)`,
                            transition: 'none'
                        };

                        this.rollStartTimer();
                    } else if (data.state === 'rolling') {
                        // Get random 0.1 to 0.9 from current roll ._id
                        const offset = 0.1 + ((parseInt(data._id.substr(0, 8), 16) % 9) / 8) * 0.8;

                        const timeEnding = new Date(this.rollGame.updatedAt).getTime() + 5000;
                        let timeLeft = timeEnding - (new Date().getTime() + this.generalTimeDiff);
                        timeLeft = timeLeft > 0 ? timeLeft : 0;

                        this.rollReelStyle = {
                            transform: `translateX(${this.rollGetPercentage(49, offset)}%)`,
                            transition:
                                'transform ' +
                                timeLeft / 1000 +
                                's cubic-bezier(0.05, 0.85, 0.25, 1)'
                        };

                        this.soundRoll.volume = Math.max(0, this.soundVolume / 6);
                        this.soundRoll.currentTime = 0;
                        this.soundRoll.play();

                        setTimeout(() => {
                            this.rollWin = true;
                            this.rollState = 'win';
                        }, timeLeft);
                    } else {
                        this.rollTimer = 0;

                        if (
                            this.rollWin === true &&
                            this.rollGetWin.cryptoWin + this.rollGetWin.robuxWin > 0
                        ) {
                            if (this.rollConfetti && this.rollGetWin.confetti)
                                this.rollConfetti.addConfetti({
                                    confettiRadius: 3,
                                    confettiNumber: 300
                                });
                        }
                    }
                },
                deep: true
            }
        },
        mounted() {
            const canvas = document.getElementById('roll-confetti');
            this.rollConfetti = new JSConfetti({ canvas });
        },
        beforeDestroy() {
            cancelAnimationFrame(this.rollTimerRepeater);
        }
    };
</script>

<style scoped>
    .fade-slide-enter-active,
    .fade-slide-leave-active {
        transition: all 0.3s ease;
    }

    .fade-slide-enter,
    .fade-slide-leave-to {
        transform: translateY(-30px);
        opacity: 0;
    }
</style>

<style module>
    .roll-game {
        width: 100%;
        margin-bottom: 22px;
    }

    .roll-game .game-timer {
        width: calc(100% - 20px);
        height: 6px;
        position: absolute;
        top: 0;
        left: 10px;
        display: flex;
        border-radius: 5px;
        background: #00000042;
    }

    .roll-game .timer-progress {
        height: 100%;
        border-radius: 5px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)),
            linear-gradient(263.46deg, #00ffc2 -10.36%, #00aa6d 44.58%);
    }

    .roll-game.rolling .game-timer .timer-progress {
        animation: game-timer-active 1s 0.25s;
    }

    .roll-game:not(.rolling) ol li:not(.a) {
        opacity: 0.5;
    }

    @keyframes game-timer-active {
        0% {
            filter: brightness(2);
        }
        10% {
            filter: brightness(2);
        }
        10.1% {
            filter: brightness(1);
        }
        45% {
            filter: brightness(1);
        }
        45.1% {
            filter: brightness(2);
        }
        55% {
            filter: brightness(2);
        }
        55.1% {
            filter: brightness(1);
        }
        90% {
            filter: brightness(1);
        }
        90.1% {
            filter: brightness(2);
        }
        100% {
            filter: brightness(2);
        }
    }

    .roll-game .game-spinner {
        width: 100%;
        position: relative;
        font-size: 10px;
    }

    .roll-game .spinner-confetti {
        mask-image: linear-gradient(
            90deg,
            transparent,
            black 5em,
            black calc(100% - 5em),
            transparent
        );
        width: calc(100% + 10em);
        height: 100%;
        bottom: 0;
        left: -5em;
        position: absolute;
        z-index: 1;
    }

    .roll-game .spinner-selector {
        width: 0.3em;
        height: calc(100% + 2.1em - 20em);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        z-index: 1;
        opacity: 1;
        transition: opacity 0.2s ease;
    }

    .roll-game:not(.rolling) .spinner-selector {
        opacity: 0;
    }

    .roll-game .spinner-selector::before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: -6px;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-top: 14px solid #ffffff;
    }

    .roll-game .spinner-selector::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        left: -6px;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 14px solid #ffffff;
    }

    .roll-game .spinner-inner {
        position: relative;
        height: calc(23.2em + 20em);
        margin-top: -10em;
        margin-bottom: -10em;
        width: calc(100% + 15vw);
        margin-left: -7.5vw;
        mask-image: linear-gradient(
            90deg,
            transparent,
            black 7.5vw,
            black calc(100% - 15vw),
            transparent
        );
    }

    .roll-game .inner-reel {
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        list-style-type: none;
        left: 50%;
    }

    .roll-game ol li {
        font-size: inherit;
    }

    .roll-game .game-win {
        position: absolute;
        top: 170px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
    }

    @media screen and (max-width: 700px) {
        .roll-game .game-spinner {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 550px) {
        .roll-game .game-inner {
            font-size: 7px;
        }
    }
</style>
