var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['towers-row'],
        _vm.$style[_vm.towersRisk],
        {
            [_vm.$style['row-active']]:
                _vm.towersGame !== null &&
                _vm.towersGame.state !== 'completed' &&
                _vm.towersGame.revealed.length === _vm.row,
            [_vm.$style['row-revealed']]:
                _vm.towersGame !== null && _vm.towersGame.revealed[_vm.row] !== undefined
        }
    ]},_vm._l((_vm.towersGetTilesCount),function(tile){return _c('div',{key:tile,class:_vm.$style['row-tile']},[_c('transition',{attrs:{"name":"tile","mode":"out-in"}},[(
                    _vm.towersGame !== null &&
                    _vm.towersGame.revealed[_vm.row] !== undefined &&
                    _vm.towersGame.revealed[_vm.row].tile === tile - 1 &&
                    _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'coin'
                )?_c('div',{class:_vm.$style['tile-coin']},[_c('div',{class:_vm.$style['coin-inner']},[(_vm.towersRowCoinType === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}):(_vm.towersRowCoinType === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}}):_vm._e(),_c('div',{class:_vm.$style['inner-value']},[_c('span',[_vm._v(_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split('.')[0]))]),_vm._v("."+_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split('.')[1])+" ")]),_c('img',{class:_vm.$style['image-knight'],attrs:{"src":require("@/assets/img/char-robot.png")}})])]):(
                    _vm.towersGame !== null &&
                    ((_vm.towersGame.revealed[_vm.row] !== undefined &&
                        _vm.towersGame.revealed[_vm.row].tile === tile - 1 &&
                        _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'lose') ||
                        (_vm.towersGame.state === 'completed' &&
                            _vm.towersGame.revealed.length <= _vm.row &&
                            _vm.towersGame.deck[_vm.row][tile - 1] === 'lose'))
                )?_c('div',{class:_vm.$style['tile-lose']},[_c('div',{class:_vm.$style['lose-inner']},[_c('IconSkull')],1)]):_c('button',{class:_vm.$style['button-reveal'],attrs:{"disabled":_vm.socketSendLoading !== null ||
                    _vm.towersGame === null ||
                    _vm.towersGame.state === 'completed' ||
                    _vm.towersGame.revealed.length !== _vm.row},on:{"click":function($event){return _vm.towersRevealButton(tile - 1)}}},[_c('div',{class:_vm.$style['button-inner']},[(_vm.towersRowCoinType === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}):(_vm.towersRowCoinType === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}}):_vm._e(),_c('div',{class:_vm.$style['inner-value']},[_c('span',[_vm._v(_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split('.')[0]))]),_vm._v("."+_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split('.')[1])+" ")])])])])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }