<template>
    <button v-on:click="itemButton()" v-bind:class="[$style['cashier-item-element']]">
        <svg
            v-if="itemIsActive"
            :class="$style.check"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_287_6712)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.5 0C3.81172 0 0 3.81172 0 8.5C0 13.1883 3.81172 17 8.5 17C13.1883 17 17 13.1883 17 8.5C17 3.81172 13.1883 0 8.5 0Z"
                    fill="url(#paint0_linear_287_6712)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6104 5.63455C12.8162 5.84041 12.8162 6.17908 12.6104 6.38494L7.62988 11.3654C7.52695 11.4683 7.39082 11.5215 7.25469 11.5215C7.11855 11.5215 6.98242 11.4683 6.87949 11.3654L4.38926 8.87517C4.1834 8.66931 4.1834 8.33064 4.38926 8.12478C4.59512 7.91892 4.93379 7.91892 5.13965 8.12478L7.25469 10.2398L11.86 5.63455C12.0658 5.42537 12.4045 5.42537 12.6104 5.63455Z"
                    fill="#16173A"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_287_6712"
                    x1="17"
                    y1="0"
                    x2="-2.96701"
                    y2="4.8934"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00FFC2" />
                    <stop offset="1" stop-color="#00AA6D" />
                </linearGradient>
                <clipPath id="clip0_287_6712">
                    <rect width="17" height="17" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <UnboxItemElement
            v-bind:item="{
                item: {
                    amountFixed: item.amount,
                    ...item
                },
                color: itemGetColor
            }"
            v-bind:coinType="itemGetCoinType"
            v-bind:hoverable="true"
        />
    </button>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import UnboxItemElement from '@/components/unbox/UnboxItemElement';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';

    export default {
        name: 'CashierItemElement',
        props: ['item', 'disabled'],
        components: {
            UnboxItemElement
        },
        methods: {
            ...mapActions(['upgraderToggleSelected']),
            itemButton() {
                this.upgraderToggleSelected(this.item);
            }
        },
        computed: {
            ...mapGetters([
                'modalsShow',
                'modalsData',
                'userBalanceData',
                'authUser',
                'upgraderSelected',
                'upgraderGame'
            ]),
            itemGetColor() {
                return getLimitedItemColorFromAmount(this.item.amountFixed / 1000);
            },
            itemIsActive() {
                return (
                    this.upgraderSelected.find((item) => item._id === this.item._id) !== undefined
                );
            },
            itemGetCoinType() {
                return this.upgraderGame?.coinType || this.userBalanceData.coinType;
            }
        }
    };
</script>

<style module>
    .cashier-item-element {
        position: relative;
        width: 100%;
        font-size: 1em;
    }

    .cashier-item-element > *:not(svg) {
        font-size: 1em !important;
    }

    .cashier-item-element svg.check {
        position: absolute;
        left: 7%;
        top: 7%;
    }

    .cashier-item-element svg.user {
        position: absolute;
        left: 7%;
        top: 7%;
    }

    .cashier-item-element svg.locked {
        position: absolute;
        right: 7%;
        top: 7%;
    }

    .cashier-item-element.active > *:not(svg)::before {
        background: currentColor;
    }
</style>
