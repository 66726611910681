<template>
    <div :class="$style['roll']">
        <RollBets />

        <div :class="$style['roll-container']">
            <RollGame />
            <RollHistory />
            <RollControls />
            <RollUserBets />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import RollBets from '@/components/roll/RollBets';
    import RollGame from '@/components/roll/RollGame';
    import RollHistory from '@/components/roll/RollHistory';
    import RollControls from '@/components/roll/RollControls';
    import RollUserBets from '@/components/roll/RollUserBets';

    export default {
        name: 'Roll',
        metaInfo: {
            title: 'Roll - Rollbet.gg'
        },
        components: {
            RollBets,
            RollGame,
            RollHistory,
            RollControls,
            RollUserBets
        },
        methods: {
            ...mapActions(['socketConnectRoll', 'socketDisconnectRoll'])
        },
        created() {
            this.socketConnectRoll();
        },
        beforeRouteLeave(to, from, next) {
            this.socketDisconnectRoll();
            next();
        }
    };
</script>

<style module>
    .roll {
        width: 100%;
        max-width: 1545px;
        margin: 0 auto;
        position: relative;
        display: flex;
        margin-top: 35px;
        padding: 39px 10px;
        gap: 24px;
    }

    .roll .roll-container {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 313px - 22px);
        flex: 1;
    }

    @media screen and (max-width: 1000px) {
        .roll {
            gap: 8px;
        }

        .roll .roll-container {
            max-width: calc(100% - 8px - 75px);
        }
    }
</style>
