var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
        _vm.$style['duels-games-element'],
        {
            [_vm.$style['element-full']]: _vm.game.bets.length >= _vm.game.playerCount,
            [_vm.$style['element-completed']]: _vm.game.state === 'completed'
        }
    ],on:{"click":_vm.duelsGameButton}},[_c('div',{class:_vm.$style['element-info']},[_c('div',{class:_vm.$style['info-bet']},[_vm._v(" BET: "),_c('div',{class:_vm.$style['bet-box']},[(_vm.duelsGameCoinType === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}):(_vm.duelsGameCoinType === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}}):_vm._e(),_c('div',{class:_vm.$style['box-value']},[_c('span',[_vm._v(_vm._s(_vm.duelsFormatValue(_vm.game.amount).split('.')[0]))]),_vm._v("."+_vm._s(_vm.duelsFormatValue(_vm.game.amount).split('.')[1])+" ")]),_vm._v(" / "),_c('div',{class:_vm.$style['box-value']},[_c('span',[_vm._v(" "+_vm._s(_vm.duelsFormatValue( Math.floor(_vm.game.amount * _vm.game.playerCount * 0.95) ).split('.')[0])+" ")]),_vm._v("."+_vm._s(_vm.duelsFormatValue( Math.floor(_vm.game.amount * _vm.game.playerCount * 0.95) ).split('.')[1])+" ")])])]),_c('div',{class:[
                _vm.$style['info-creator'],
                _vm.$style['creator-' + _vm.duelsGetRank(_vm.game.bets[0].user)],
                _vm.$style['creator-' + _vm.duelsGetLevelColor(_vm.game.bets[0].user)]
            ]},[_vm._v(" CREATED BY: "),_c('AvatarImage',{attrs:{"image":_vm.game.bets[0].user.avatar,"rank":_vm.game.bets[0].user.rank,"level":_vm.game.bets[0].user.level}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.game.bets[0].user.username)}})],1)]),_c('div',{class:_vm.$style['element-users']},[_c('div',{class:_vm.$style['users-inner']},[_c('IconUserGradient'),(_vm.game.bets.length >= _vm.game.playerCount)?_c('div',{class:_vm.$style['inner-full']},[_c('span',{staticClass:"fade-green"},[_vm._v(_vm._s(_vm.game.playerCount))])]):_c('div',{class:_vm.$style['inner-waiting']},[_c('span',[_vm._v(_vm._s(_vm.game.bets.length))]),_vm._v(" /"+_vm._s(_vm.game.playerCount)+" ")])],1),_c('div',{class:_vm.$style['users-progress'],style:({ height: (100 / _vm.game.playerCount) * _vm.game.bets.length + '%' })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }