<template>
    <div
        v-if="['mod', 'partner'].includes(rank)"
        v-bind:class="[$style['box-rank'], $style['rank-' + rank]]"
    >
        <div :class="$style['rank-inner']">
            <img v-bind:src="require('@/assets/img/ranks/' + rank + '.svg')" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BoxRank',
        props: ['rank']
    };
</script>

<style module>
    .box-rank {
        width: 25px;
        height: 25px;
        position: relative;
    }

    .box-rank::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #d0833f 100%);
        clip-path: var(--octagonal-before);
        font-size: 3px;
    }

    .box-rank.rank-silver::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #c0c0c0 100%);
    }

    .box-rank.rank-gold::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #ffa24b 100%);
    }

    .box-rank.rank-platinum::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #0c88ff 100%);
    }

    .box-rank.rank-titanium::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #8674f2 100%);
    }

    .box-rank.rank-mod::before {
        background: linear-gradient(180deg, #08295d 0%, #498fdd 100%);
    }

    .box-rank.rank-vip::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #ffb703 100%);
    }

    .box-rank.rank-partner::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #e34343 100%);
    }

    .box-rank.rank-admin::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #00ffc2 100%);
    }

    .box-rank::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #063141;
        clip-path: var(--octagonal-after);
        font-size: 3px;
    }

    li .box-rank:after {
        background-color: #052139;
    }

    li .box-rank.rank-mod::after {
        background: #08295d;
    }

    .rewards-rakeback .box-rank:after {
        background: #121f24;
    }

    .box-rank .rank-inner {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 1px;
        left: 1px;
        background: rgb(151, 119, 91, 0.05);
        clip-path: polygon(
            5px 0,
            calc(100% - 5px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 5px) 100%,
            5px 100%,
            0 75%,
            0 25%
        );
        z-index: 1;
    }

    .box-rank.rank-silver .rank-inner {
        background: rgb(169, 169, 169, 0.05);
    }

    .box-rank.rank-gold .rank-inner {
        background: linear-gradient(
            270deg,
            rgba(246, 138, 37, 0.05) 15%,
            rgba(255, 189, 89, 0.05) 100%
        );
    }

    .box-rank.rank-platinum .rank-inner {
        background: linear-gradient(
            270deg,
            rgba(85, 158, 228, 0.05) 15%,
            rgba(163, 163, 180, 0.05) 100%
        );
    }

    .box-rank.rank-titanium .rank-inner {
        background: linear-gradient(
            270deg,
            rgba(105, 83, 241, 0.05) 15%,
            rgba(163, 163, 180, 0.05) 100%
        );
    }

    .box-rank.rank-vip .rank-inner,
    .box-rank.rank-mod .rank-inner {
        background: rgb(252, 163, 17, 0.05);
    }

    .box-rank.rank-admin .rank-inner {
        background: linear-gradient(
            180deg,
            rgba(0, 255, 194, 0.05) 0%,
            rgba(0, 170, 109, 0.05) 100%
        );
    }

    .box-rank.rank-mod .rank-inner img {
        width: 15px;
        height: 15px;
    }
</style>
