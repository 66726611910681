<template>
    <div :class="$style['unbox-box']">
        <transition name="fade" mode="out-in">
            <div
                v-if="socketUnbox.connected === false || unboxBoxData.loading === true"
                :class="$style['box-loading']"
                key="loading"
            ></div>
            <div v-else :class="$style['box-content']" key="data">
                <UnboxSpinner />
                <UnboxControls />
            </div>
        </transition>

        <div :class="$style['box-items']">
            <div :class="$style['items-header']">
                <div :class="$style['header-icon']">
                    <svg
                        width="25"
                        height="20"
                        viewBox="0 0 25 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0 7.26413H8.46421V9.04961H0V7.26413Z" fill="black" />
                        <path
                            d="M0 7.26413H8.46421V9.04961H0V7.26413Z"
                            fill="url(#paint0_linear_187_21052)"
                        />
                        <path
                            d="M22.8838 0.141998C23.0536 0.506556 23.149 0.913323 23.149 1.34238V5.78143H25V3.06405C25 1.69567 24.1092 0.534234 22.8838 0.141998Z"
                            fill="black"
                        />
                        <path
                            d="M22.8838 0.141998C23.0536 0.506556 23.149 0.913323 23.149 1.34238V5.78143H25V3.06405C25 1.69567 24.1092 0.534234 22.8838 0.141998Z"
                            fill="url(#paint1_linear_187_21052)"
                        />
                        <path
                            d="M23.2016 10.5324V16.4202H22.4691C21.9962 16.4202 21.6115 16.8097 21.6115 17.2884V18.0297H3.38853V17.2884C3.38853 16.8096 3.00376 16.4202 2.53086 16.4202H1.79844V10.5324H0V20H25V10.5324H23.2016Z"
                            fill="black"
                        />
                        <path
                            d="M23.2016 10.5324V16.4202H22.4691C21.9962 16.4202 21.6115 16.8097 21.6115 17.2884V18.0297H3.38853V17.2884C3.38853 16.8096 3.00376 16.4202 2.53086 16.4202H1.79844V10.5324H0V20H25V10.5324H23.2016Z"
                            fill="url(#paint2_linear_187_21052)"
                        />
                        <path
                            d="M12.5 12.4896C13.9176 12.4896 15.0709 11.3222 15.0709 9.88721V7.26418H9.92905V9.88721C9.92905 11.3221 11.0824 12.4896 12.5 12.4896ZM11.7562 8.77594H13.221V10.2587H11.7562V8.77594Z"
                            fill="black"
                        />
                        <path
                            d="M12.5 12.4896C13.9176 12.4896 15.0709 11.3222 15.0709 9.88721V7.26418H9.92905V9.88721C9.92905 11.3221 11.0824 12.4896 12.5 12.4896ZM11.7562 8.77594H13.221V10.2587H11.7562V8.77594Z"
                            fill="url(#paint3_linear_187_21052)"
                        />
                        <path
                            d="M4.73511 16.547H20.2649C20.4957 15.8461 21.0443 15.2908 21.7368 15.0572V10.5324H16.485C16.1785 12.4794 14.5085 13.9723 12.5 13.9723C10.4915 13.9723 8.82158 12.4794 8.51499 10.5324H3.26328V15.0572C3.95576 15.2908 4.50439 15.8461 4.73511 16.547Z"
                            fill="black"
                        />
                        <path
                            d="M4.73511 16.547H20.2649C20.4957 15.8461 21.0443 15.2908 21.7368 15.0572V10.5324H16.485C16.1785 12.4794 14.5085 13.9723 12.5 13.9723C10.4915 13.9723 8.82158 12.4794 8.51499 10.5324H3.26328V15.0572C3.95576 15.2908 4.50439 15.8461 4.73511 16.547Z"
                            fill="url(#paint4_linear_187_21052)"
                        />
                        <path
                            d="M3.31577 1.34238V5.78143H21.6842V1.34238C21.6842 0.602193 21.0893 3.04217e-09 20.3581 3.04217e-09H4.64194C3.91069 -4.94219e-05 3.31577 0.602144 3.31577 1.34238Z"
                            fill="black"
                        />
                        <path
                            d="M3.31577 1.34238V5.78143H21.6842V1.34238C21.6842 0.602193 21.0893 3.04217e-09 20.3581 3.04217e-09H4.64194C3.91069 -4.94219e-05 3.31577 0.602144 3.31577 1.34238Z"
                            fill="url(#paint5_linear_187_21052)"
                        />
                        <path
                            d="M0 5.78138H1.85093V1.34238C1.85093 0.913323 1.94634 0.506556 2.11611 0.141998C0.89082 0.534234 0 1.69567 0 3.064V5.78138Z"
                            fill="black"
                        />
                        <path
                            d="M0 5.78138H1.85093V1.34238C1.85093 0.913323 1.94634 0.506556 2.11611 0.141998C0.89082 0.534234 0 1.69567 0 3.064V5.78138Z"
                            fill="url(#paint6_linear_187_21052)"
                        />
                        <path d="M16.5358 7.26413H25V9.04961H16.5358V7.26413Z" fill="black" />
                        <path
                            d="M16.5358 7.26413H25V9.04961H16.5358V7.26413Z"
                            fill="url(#paint7_linear_187_21052)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint2_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint3_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint4_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint5_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint6_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint7_linear_187_21052"
                                x1="72.3293"
                                y1="-0.804659"
                                x2="16.8683"
                                y2="40.4883"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <div :class="$style['items-content']">
                <transition name="fade" mode="out-in">
                    <div
                        v-if="socketUnbox.connected === false || unboxBoxData.loading === true"
                        :class="$style['content-loading']"
                        key="loading"
                    >
                        <div :class="$style['loading-placeholder']"></div>
                        <div :class="$style['loading-placeholder']"></div>
                        <div :class="$style['loading-placeholder']"></div>
                    </div>
                    <div v-else :class="$style['content-list']" key="data">
                        <UnboxItemElement
                            v-for="item of unboxGetBoxItems"
                            v-bind:key="item._id"
                            v-bind:item="item"
                        />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import UnboxSpinner from '@/components/unbox/UnboxSpinner';
    import UnboxControls from '@/components/unbox/UnboxControls';
    import UnboxItemElement from '@/components/unbox/UnboxItemElement';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';

    export default {
        name: 'UnboxBox',
        components: {
            UnboxSpinner,
            UnboxControls,
            UnboxItemElement
        },
        methods: {
            ...mapActions(['unboxSetCount', 'unboxSetGames', 'unboxGetBoxDataSocket'])
        },
        computed: {
            ...mapGetters(['socketUnbox', 'unboxBoxData']),
            unboxGetBoxItems() {
                return this.unboxBoxData.box.items.map((item) => {
                    const color = getLimitedItemColorFromAmount(item.item.amountFixed / 1000);
                    return { ...item, color };
                });
            }
        },
        watch: {
            'socketUnbox.connected': {
                handler() {
                    const boxId = this.$route.params.boxId;

                    if (
                        (this.unboxBoxData.box === null || this.unboxBoxData.box._id !== boxId) &&
                        this.unboxBoxData.loading === false
                    ) {
                        const data = { boxId: boxId };
                        this.unboxGetBoxDataSocket(data);
                    }
                }
            }
        },
        created() {
            const boxId = this.$route.params.boxId;

            if (
                this.socketUnbox.connected === true &&
                (this.unboxBoxData.box === null || this.unboxBoxData.box._id !== boxId) &&
                this.unboxBoxData.loading === false
            ) {
                const data = { boxId: this.$route.params.boxId };
                this.unboxGetBoxDataSocket(data);
            }
        },
        beforeDestroy() {
            this.unboxSetCount(1);
            this.unboxSetGames([]);
        }
    };
</script>

<style module>
    .unbox-box {
        width: 100%;
    }

    .unbox-box .box-items {
        width: 100%;
        margin-top: 35px;
    }

    .unbox-box .items-header {
        display: flex;
        align-items: center;
        gap: 20px;
        mask-image: linear-gradient(90deg, transparent, #fff, transparent);
    }

    .unbox-box .items-header::before,
    .unbox-box .items-header::after {
        content: '';
        flex: 1;
        height: 1px;
        background: #174a73;
    }

    .unbox-box .header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        position: relative;
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.68deg,
                rgba(0, 0, 0, 0.128) -11.11%,
                rgba(0, 33, 24, 0.132) 16.04%,
                rgba(0, 99, 73, 0.144986) 58.69%,
                rgba(1, 193, 143, 0.0925409) 79.88%,
                rgba(1, 237, 176, 0.068) 99.83%
            );
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .unbox-box .header-icon::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        font-size: 5px;
        background: #01e1a422;
        clip-path: var(--octagonal-before);
    }

    .unbox-box .items-content {
        width: 100%;
        margin-top: 25px;
    }

    .unbox-box .content-loading,
    .unbox-box .content-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 7px;
    }

    @media screen and (max-width: 1000px) {
        .unbox-box .content-loading,
        .unbox-box .content-list {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 800px) {
        .unbox-box .content-loading,
        .unbox-box .content-list {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 700px) {
        .unbox-box .content-loading,
        .unbox-box .content-list {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 550px) {
        .unbox-box .content-loading,
        .unbox-box .content-list {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 450px) {
        .unbox-box .content-list .unbox-item-element {
            font-size: 9px;
        }
    }
</style>
