<template>
    <div :class="$style['upgrader-filter-amount']">
        <div :class="$style['amount-item']" class="octagonal before after hoverable">
            <input v-model="upgraderFilterAmount.min" type="text" placeholder="Min." />
            <img
                v-if="userBalanceData.type === 'robuxCoin'"
                width="14"
                src="@/assets/img/icons/robuxCoin.svg"
            />
            <img v-else width="12" src="@/assets/img/icons/cryptoCoin.svg" />
        </div>
        <div :class="$style['amount-item']" class="octagonal before after hoverable">
            <input v-model="upgraderFilterAmount.max" type="text" placeholder="Max." />
            <img
                v-if="userBalanceData.type === 'robuxCoin'"
                width="14"
                src="@/assets/img/icons/robuxCoin.svg"
            />
            <img v-else width="12" src="@/assets/img/icons/cryptoCoin.svg" />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'UpgraderFilterPrice',
        data() {
            return {
                upgraderDropdown: false
            };
        },
        methods: {
            ...mapActions(['upgraderSetFilterAmount']),
            upgraderToggleDropdown() {
                this.upgraderDropdown = !this.upgraderDropdown;
            },
            upgraderAmountButton(value) {
                this.upgraderSetFilterAmount(value);
                this.upgraderToggleDropdown();
            }
        },
        computed: {
            ...mapGetters(['upgraderFilterAmount', 'userBalanceData'])
        },
        created() {
            let self = this;
            document.addEventListener('click', function (event) {
                if (!self.$el.contains(event.target) && self.upgraderDropdown == true) {
                    self.upgraderToggleDropdown();
                }
            });
        }
    };
</script>

<style module>
    .upgrader-filter-amount {
        width: 100%;
        max-width: 210px;
        display: flex;
        gap: 7px;
    }

    .amount-item {
        flex: 1;
        height: 46px;
        margin-right: auto;
    }

    .amount-item img {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 11px;
    }

    .amount-item input {
        appearance: none;
        background: none;
        padding-left: calc(9px + 15px + 7px);
        font-size: 14px;
        color: #406992;
        font-weight: 500;
        height: 100%;
        width: 100%;
    }

    .amount-item::before {
        background: #112a43;
        font-size: 3px;
    }

    .amount-item::after {
        background: linear-gradient(0deg, #0b243b, #0c2339);
        font-size: 3px;
    }
</style>
