<template>
    <div :class="$style['home-banner-aio']">
        <div :class="$style['aio-inner']">
            <button
                v-if="homeBanners.length > 1"
                v-on:click="homePreviousButton"
                :class="$style['button-previous']"
            >
                <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.398287 7.54628L6.62846 13.7763C6.77256 13.9206 6.96491 14 7.17001 14C7.37512 14 7.56747 13.9206 7.71157 13.7763L8.17038 13.3176C8.46893 13.0188 8.46893 12.533 8.17038 12.2345L2.93875 7.00291L8.17619 1.76548C8.32028 1.62127 8.39984 1.42902 8.39984 1.22403C8.39984 1.01882 8.32028 0.826572 8.17619 0.682249L7.71738 0.223667C7.57317 0.0794572 7.38092 1.1355e-05 7.17582 1.13371e-05C6.97072 1.13192e-05 6.77836 0.0794571 6.63426 0.223667L0.398287 6.45942C0.25385 6.60408 0.174517 6.79723 0.174972 7.00257C0.174517 7.20869 0.25385 7.40173 0.398287 7.54628Z"
                    />
                </svg>
            </button>
            <div
                :class="$style['inner-content']"
                v-on:mouseenter="homeMouseEnter"
                v-on:mouseleave="homeMouseLeave"
            >
                <div v-if="homeBanners.length > 1" :class="$style['banner-index']">
                    <button
                        v-for="(banner, index) in homeBanners"
                        @click="homeJumpTo(index)"
                        :class="[$style['index-item'], { [$style.active]: index === homePosition }]"
                        v-bind:key="index"
                    ></button>
                </div>
                <HomeAIOElement
                    v-for="(banner, index) in homeBanners"
                    v-bind:key="index"
                    v-bind:banner="banner"
                    v-bind:direction="homeDirection"
                    v-bind:position="homePosition"
                    v-bind:index="index"
                />
            </div>
            <button
                v-if="homeBanners.length > 1"
                v-on:click="homeNextButton"
                :class="$style['button-next']"
            >
                <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.398287 7.54628L6.62846 13.7763C6.77256 13.9206 6.96491 14 7.17001 14C7.37512 14 7.56747 13.9206 7.71157 13.7763L8.17038 13.3176C8.46893 13.0188 8.46893 12.533 8.17038 12.2345L2.93875 7.00291L8.17619 1.76548C8.32028 1.62127 8.39984 1.42902 8.39984 1.22403C8.39984 1.01882 8.32028 0.826572 8.17619 0.682249L7.71738 0.223667C7.57317 0.0794572 7.38092 1.1355e-05 7.17582 1.13371e-05C6.97072 1.13192e-05 6.77836 0.0794571 6.63426 0.223667L0.398287 6.45942C0.25385 6.60408 0.174517 6.79723 0.174972 7.00257C0.174517 7.20869 0.25385 7.40173 0.398287 7.54628Z"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    import HomeAIOElement from '@/components/home/HomeAIOElement';

    export default {
        name: 'HomeBannerAIO',
        components: {
            HomeAIOElement
        },
        data() {
            return {
                // homeBanners: ['Glory', 'Links', 'Mystery', 'WhatsNew'],
                homeBanners: ['WhatsNew'],
                homePosition: 0,
                homeDirection: null,
                homeInterval: null,
                homeMouseActive: false
            };
        },
        methods: {
            homeMouseEnter() {
                this.homeMouseActive = true;
            },
            homeMouseLeave() {
                this.homeMouseActive = false;
            },
            homeStartInterval(ms) {
                clearInterval(this.homeInterval);
                if (this.homeBanners.length > 1) {
                    this.homeInterval = setInterval(() => {
                        if (this.homeMouseActive === false) this.homeNextButton();
                    }, ms || 6000);
                }
            },
            homeClearInterval() {
                clearInterval(this.homeInterval);
            },
            homePreviousButton() {
                this.homeDirection = 'left';
                this.homePosition =
                    this.homePosition === 0 ? this.homeBanners.length - 1 : this.homePosition - 1;
                this.homeStartInterval();
            },
            homeNextButton() {
                this.homeDirection = 'right';
                this.homePosition =
                    this.homePosition >= this.homeBanners.length - 1 ? 0 : this.homePosition + 1;
                this.homeStartInterval();
            },
            homeJumpTo(index) {
                if (index === this.homePosition) return;
                if (index > this.homePosition) this.homePosition = 'right';
                if (index < this.homePosition) this.homePosition = 'left';
                this.homePosition = index;
                this.homeStartInterval();
            }
        },
        beforeUnmount() {
            clearInterval(this.homeInterval);
        },
        mounted() {
            this.homeStartInterval();
        }
    };
</script>

<style module>
    .home-banner-aio {
        width: 100%;
        height: 200px;
        position: relative;
        padding: 0 0 1px 0;
    }

    .home-banner-aio .aio-inner {
        width: calc(100% + 28px);
        margin-left: -14px;
        height: calc(100% + 14px + 14px);
        margin-top: -14px;
        padding: 14px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

    .home-banner-aio .aio-inner img {
        height: 100%;
    }

    .home-banner-aio .aio-inner button.button-previous,
    .home-banner-aio .aio-inner button.button-next {
        width: 60px;
        height: calc(75% - 28px);
        display: flex;
        justify-content: center;
        border: 1px solid #1a3958;
        align-items: center;
        background: radial-gradient(91.3% 91.3% at 50% 28.88%, #062641 0%, #021729 100%);
        border-radius: 8px;
        position: absolute;
        bottom: 24px;
        opacity: 0;
        z-index: 1;
    }

    .home-banner-aio .aio-inner button.button-previous {
        bottom: 24px;
        left: 24px;
    }

    .home-banner-aio .aio-inner button.button-next {
        right: 24px;
    }

    .home-banner-aio .aio-inner button.button-previous svg,
    .home-banner-aio .aio-inner button.button-next svg {
        fill: #93adc5;
        transition: fill 0.3s ease;
    }

    .home-banner-aio .aio-inner button.button-next svg {
        transform: rotate(180deg);
    }

    .home-banner-aio .aio-inner button.button-previous:hover svg,
    .home-banner-aio .aio-inner button.button-next:hover svg {
        fill: #ffffff;
    }

    .home-banner .banner-index {
        display: flex;
        gap: 8px;
        z-index: 2;
        width: fit-content;
        position: absolute;
        right: 34px;
        top: 32px;
    }

    .home-banner .banner-index .index-item {
        clip-path: var(--octagonal-main);
        position: relative;
        background: #010e1a;
        font-size: 1px;
        height: 12px;
        width: 12px;
    }

    .home-banner .banner-index .index-item.active {
        background: linear-gradient(90deg, #175048, #216960);
    }

    .home-banner .banner-index .index-item.active::after {
        content: '';
        display: block;
        position: absolute;
        background: linear-gradient(90deg, #3bb47c, #55f5bc);
        transform: translate(-50%, -50%);
        clip-path: var(--octagonal-main);
        font-size: 0.1px;
        height: 6px;
        width: 6px;
        left: 50%;
        top: 50%;
    }

    .home-banner-aio .inner-content {
        width: 100%;
        height: calc(100% + 28px);
        margin-top: -14px;
        margin-bottom: -14px;
        padding-top: 14px;
        padding-bottom: 14px;
        position: relative;
        mask-image: linear-gradient(
            90deg,
            transparent,
            white 14px,
            white calc(100% - 14px),
            transparent
        );
    }

    @media only screen and (max-width: 900px) {
        .home-banner-aio {
            width: 100%;
        }
    }

    @media screen and (max-width: 800px) {
        .home-banner-aio .aio-inner button.button-previous {
            width: 50px;
            bottom: 25px;
            left: 25px;
        }
        .home-banner-aio .aio-inner button.button-next {
            width: 50px;
            bottom: 25px;
            right: 25px;
        }
    }
</style>
