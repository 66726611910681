<template>
    <form
        :class="[$style['modal-link-email'], $style['email-content']]"
        v-on:submit.prevent="modalResetButton"
    >
        <h2 :class="$style['email-title']">
            <svg
                stroke="#21B894"
                fill="#21B894"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                    d="M13 3a9 9 0 0 0-9 9H1l4 4 4-4H6c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7c-1.9 0-3.62-.76-4.88-1.99L6.7 18.42A8.982 8.982 0 0 0 13 21a9 9 0 0 0 0-18zm2 8v-1c0-1.1-.9-2-2-2s-2 .9-2 2v1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1zm-1 0h-2v-1c0-.55.45-1 1-1s1 .45 1 1v1z"
                ></path>
            </svg>
            Reset Password
        </h2>
        <hr :class="$style['email-hr']" />
        <div :class="$style['email-content']">
            <div :class="$style['email-element']">
                <div :class="$style['element-title']">New Password</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input v-model="modalPassword" type="password" required="required" />
                </div>
            </div>
            <div :class="$style['email-element']">
                <div :class="$style['element-title']">Repeat New Password</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input v-model="modalPasswordConfirm" type="password" required="required" />
                </div>
            </div>
        </div>
        <div :class="$style['email-button']">
            <div :class="$style.divider"></div>
            <button :class="$style['button-action']" class="octagonal hoverable">
                <span>
                    <div :class="$style['inner-content']">Confirm</div>
                </span>
            </button>
            <div :class="$style.divider"></div>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalReset',
        data() {
            return {
                modalPassword: null,
                modalPasswordConfirm: null
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'modalsSetShow', 'modalsSetData']),
            modalResetButton() {
                if (this.modalPassword === null || this.modalPassword.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered password is invalid.'
                    });
                    return;
                }

                if (this.modalPassword !== this.modalPasswordConfirm) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered passwords does not match.'
                    });
                    return;
                }

                this.modalsSetData({
                    typeCaptcha: 'credentialsReset',
                    data: { ...this.modalsData, password: this.modalPassword }
                });
                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetShow('Captcha');
                }, 200);
            }
        },
        computed: {
            ...mapGetters(['modalsData'])
        }
    };
</script>

<style module>
    .modal-link-email {
        width: 838px;
        padding: 30px 35px 35px 35px;
    }

    .email-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .email-title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        color: white;
    }

    .email-element .element-title {
        margin-bottom: 7px;
        color: #5b85ae;
        font-size: 15px;
    }

    .element-title .button-forgot {
        margin-left: 2px;
        color: #5b85ae;
        opacity: 0.5;
    }

    .email-element .element-input input {
        background: none;
        height: 57px;
        color: #5b85ae;
        width: 100%;
        font-size: 14px;
        padding: 14px 20px;
        appearance: none;
    }

    .email-element .element-input {
        background: linear-gradient(0deg, #0f426b55, #0d335555);
        width: 100%;
        position: relative;
        clip-path: var(--octagonal-main);
        color: #5b85ae;
        font-size: 3px;
    }

    .email-element .element-input::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #1e4b7e59;
        clip-path: var(--octagonal-before);
    }

    .email-button {
        display: flex;
        align-items: center;
        gap: 14px;
    }

    .email-button .divider {
        height: 1px;
        background: #0a2f4d;
        flex: 1;
    }

    .email-button .divider:last-child {
        max-width: 24px;
    }

    .email-button .button-action {
        font-size: 16px;
        font-weight: 600;
        height: 34px;
        color: white;
        padding: 5px 20px;
        position: relative;
    }

    .email-button span {
        position: relative;
        z-index: 1;
    }

    .email-button .or {
        font-weight: 500;
        font-size: 15px;
        color: #5b85ae;
        margin-top: -5px;
    }

    .email-button .button-action::before,
    .email-button .button-action::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .email-button .button-action::before {
        clip-path: var(--octagonal-before);
        background: #21b894;
        font-size: 3px;
    }

    .email-button .button-action::after {
        clip-path: var(--octagonal-after);
        background: #21b894;
        font-size: 3px;
    }

    @media screen and (max-width: 600px) {
        .modal-link-email {
            padding: 30px 20px 30px 20px;
        }
    }
</style>
