<template>
    <div
        :class="[
            $style['blackjack-controls-action'],
            { [$style.inactive]: !blackjackTimerInstance }
        ]"
    >
        <p v-if="blackjackTimerInstance" :class="$style['action-timer']">
            MAKE A DECISION
            <span class="fade-green">({{ parseFloat(blackjackTimer).toFixed(2) }}s)</span>
        </p>
        <div
            v-if="table.playersPos === 'all'"
            :class="[$style['action-container'], $style['insurance']]"
        >
            <button v-on:click="$parent.blackjackInsuranceButton(true)">
                <div>
                    <svg
                        width="14"
                        height="13"
                        viewBox="0 0 14 13"
                        fill="#00AA6D"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.36089 11.9683C5.22721 12.109 5.04493 12.1875 4.8555 12.1875C4.66607 12.1875 4.48379 12.109 4.35011 11.9683L0.314169 7.74446C-0.104723 7.30614 -0.104723 6.59555 0.314169 6.15798L0.819556 5.62916C1.23845 5.19084 1.91682 5.19084 2.33572 5.62916L4.8555 8.26581L11.6643 1.14124C12.0832 0.70292 12.7623 0.70292 13.1804 1.14124L13.6858 1.67007C14.1047 2.10839 14.1047 2.81897 13.6858 3.25655L5.36089 11.9683Z"
                        />
                        <path
                            d="M5.36089 11.9683C5.22721 12.109 5.04493 12.1875 4.8555 12.1875C4.66607 12.1875 4.48379 12.109 4.35011 11.9683L0.314169 7.74446C-0.104723 7.30614 -0.104723 6.59555 0.314169 6.15798L0.819556 5.62916C1.23845 5.19084 1.91682 5.19084 2.33572 5.62916L4.8555 8.26581L11.6643 1.14124C12.0832 0.70292 12.7623 0.70292 13.1804 1.14124L13.6858 1.67007C14.1047 2.10839 14.1047 2.81897 13.6858 3.25655L5.36089 11.9683Z"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_72_7720"
                                x1="14"
                                y1="0.8125"
                                x2="-1.97741"
                                y2="5.63176"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <span>Take Insurance</span>
            </button>
            <button v-on:click="$parent.blackjackInsuranceButton(false)">
                <div>
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="#FE585D"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M-0.00195312 1.60229L1.60228 -0.00193937L12.0015 10.3973L10.3973 12.0015L-0.00195312 1.60229Z"
                        />
                        <path
                            d="M-0.00195312 10.3975L10.3973 -0.00172314L12.0015 1.60251L1.60228 12.0018L-0.00195312 10.3975Z"
                        />
                    </svg>
                </div>
                <span>Deny Insurance</span>
            </button>
        </div>
        <div v-else :class="$style['action-container']">
            <button
                :class="$style['hit']"
                v-on:click="$parent.blackjackHitButton()"
                v-bind:disabled="!blackjackTimerInstance"
            >
                <div>
                    <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="#00AA6D"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 0C6.81886 0 5.17547 0.498516 3.77765 1.43251C2.37984 2.3665 1.29037 3.69402 0.647027 5.24719C0.003683 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.48959 14.5104C3.67834 15.6992 5.19289 16.5087 6.84173 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM8.5 14.7333C7.26716 14.7333 6.06201 14.3678 5.03695 13.6828C4.01188 12.9979 3.21294 12.0244 2.74115 10.8854C2.26937 9.7464 2.14593 8.49308 2.38644 7.28394C2.62695 6.07479 3.22062 4.96411 4.09237 4.09237C4.96412 3.22062 6.07479 2.62695 7.28394 2.38644C8.49308 2.14592 9.7464 2.26936 10.8854 2.74115C12.0244 3.21294 12.9979 4.01188 13.6828 5.03694C14.3677 6.06201 14.7333 7.26716 14.7333 8.5C14.7333 10.1532 14.0766 11.7387 12.9076 12.9076C11.7387 14.0766 10.1532 14.7333 8.5 14.7333Z"
                        />
                        <path
                            d="M8.5 0C6.81886 0 5.17547 0.498516 3.77765 1.43251C2.37984 2.3665 1.29037 3.69402 0.647027 5.24719C0.003683 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.48959 14.5104C3.67834 15.6992 5.19289 16.5087 6.84173 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM8.5 14.7333C7.26716 14.7333 6.06201 14.3678 5.03695 13.6828C4.01188 12.9979 3.21294 12.0244 2.74115 10.8854C2.26937 9.7464 2.14593 8.49308 2.38644 7.28394C2.62695 6.07479 3.22062 4.96411 4.09237 4.09237C4.96412 3.22062 6.07479 2.62695 7.28394 2.38644C8.49308 2.14592 9.7464 2.26936 10.8854 2.74115C12.0244 3.21294 12.9979 4.01188 13.6828 5.03694C14.3677 6.06201 14.7333 7.26716 14.7333 8.5C14.7333 10.1532 14.0766 11.7387 12.9076 12.9076C11.7387 14.0766 10.1532 14.7333 8.5 14.7333Z"
                        />
                        <path
                            d="M8.50039 3.3999C7.4917 3.3999 6.50567 3.69901 5.66698 4.25941C4.82829 4.8198 4.17461 5.61631 3.78861 6.54822C3.4026 7.48012 3.3016 8.50556 3.49839 9.49486C3.69517 10.4842 4.1809 11.3929 4.89415 12.1061C5.60739 12.8194 6.51613 13.3051 7.50543 13.5019C8.49473 13.6987 9.52017 13.5977 10.4521 13.2117C11.384 12.8257 12.1805 12.172 12.7409 11.3333C13.3013 10.4946 13.6004 9.50859 13.6004 8.4999C13.6004 7.1473 13.0631 5.85009 12.1066 4.89366C11.1502 3.93722 9.85299 3.3999 8.50039 3.3999ZM8.50039 11.8999C7.82793 11.8999 7.17058 11.7005 6.61145 11.3269C6.05232 10.9533 5.61654 10.4223 5.3592 9.80102C5.10186 9.17976 5.03453 8.49613 5.16572 7.83659C5.29691 7.17706 5.62073 6.57124 6.09623 6.09574C6.57173 5.62024 7.17755 5.29642 7.83708 5.16523C8.49662 5.03404 9.18024 5.10137 9.80151 5.35871C10.4228 5.61605 10.9538 6.05184 11.3274 6.61096C11.701 7.17009 11.9004 7.82745 11.9004 8.4999C11.9004 9.40164 11.5422 10.2664 10.9046 10.9041C10.2669 11.5417 9.40212 11.8999 8.50039 11.8999Z"
                        />
                        <path
                            d="M8.50039 3.3999C7.4917 3.3999 6.50567 3.69901 5.66698 4.25941C4.82829 4.8198 4.17461 5.61631 3.78861 6.54822C3.4026 7.48012 3.3016 8.50556 3.49839 9.49486C3.69517 10.4842 4.1809 11.3929 4.89415 12.1061C5.60739 12.8194 6.51613 13.3051 7.50543 13.5019C8.49473 13.6987 9.52017 13.5977 10.4521 13.2117C11.384 12.8257 12.1805 12.172 12.7409 11.3333C13.3013 10.4946 13.6004 9.50859 13.6004 8.4999C13.6004 7.1473 13.0631 5.85009 12.1066 4.89366C11.1502 3.93722 9.85299 3.3999 8.50039 3.3999ZM8.50039 11.8999C7.82793 11.8999 7.17058 11.7005 6.61145 11.3269C6.05232 10.9533 5.61654 10.4223 5.3592 9.80102C5.10186 9.17976 5.03453 8.49613 5.16572 7.83659C5.29691 7.17706 5.62073 6.57124 6.09623 6.09574C6.57173 5.62024 7.17755 5.29642 7.83708 5.16523C8.49662 5.03404 9.18024 5.10137 9.80151 5.35871C10.4228 5.61605 10.9538 6.05184 11.3274 6.61096C11.701 7.17009 11.9004 7.82745 11.9004 8.4999C11.9004 9.40164 11.5422 10.2664 10.9046 10.9041C10.2669 11.5417 9.40212 11.8999 8.50039 11.8999Z"
                        />
                        <path
                            d="M8.50006 10.7667C9.75191 10.7667 10.7667 9.75191 10.7667 8.50006C10.7667 7.24822 9.75191 6.2334 8.50006 6.2334C7.24822 6.2334 6.2334 7.24822 6.2334 8.50006C6.2334 9.75191 7.24822 10.7667 8.50006 10.7667Z"
                        />
                        <path
                            d="M8.50006 10.7667C9.75191 10.7667 10.7667 9.75191 10.7667 8.50006C10.7667 7.24822 9.75191 6.2334 8.50006 6.2334C7.24822 6.2334 6.2334 7.24822 6.2334 8.50006C6.2334 9.75191 7.24822 10.7667 8.50006 10.7667Z"
                        />
                    </svg>
                </div>
                <span>Hit</span>
            </button>
            <button
                :class="$style['stand']"
                v-on:click="$parent.blackjackStandButton()"
                v-bind:disabled="!blackjackTimerInstance"
            >
                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="#FE585D"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15.3181 8.25829C14.6249 8.25829 13.973 8.52764 13.4814 9.01697C11.9047 10.8456 12.0087 10.7255 11.9983 10.7359C11.5006 11.2343 10.65 10.882 10.65 10.1769C10.65 8.62818 10.6463 8.74104 10.6587 8.69408L12.4005 2.18051C12.5344 1.67956 12.237 1.16249 11.737 1.0283C11.2351 0.893795 10.7219 1.19249 10.588 1.69344C10.5874 1.69666 10.6255 1.56209 8.7636 8.0712C8.68167 8.35729 8.25749 8.29797 8.25749 8.00024V0.941212C8.25749 0.422526 7.83655 0.000610352 7.31915 0.000610352C6.80175 0.000610352 6.38081 0.422526 6.38081 0.941212V7.7938C6.38081 8.09462 5.9507 8.1501 5.87373 7.86025L4.30606 1.99826C4.24091 1.75537 4.08543 1.55215 3.86802 1.42667C3.14883 1.01147 2.27745 1.67656 2.49357 2.48533L4.10123 8.49731C4.17917 8.78807 3.77754 8.95239 3.62835 8.69279L1.50361 5.00522C1.35391 4.74568 1.08395 4.60137 0.804935 4.60137C0.191706 4.60137 -0.202179 5.27337 0.108839 5.81324L1.60103 8.40248C2.20616 9.45308 2.52615 10.6495 2.52615 11.8627C2.52615 14.1439 4.37832 15.9999 6.65466 15.9999H7.59688C8.80489 15.9999 9.94096 15.5286 10.7958 14.6732L15.783 9.44279C15.8739 9.37089 15.9892 9.17599 15.9991 8.97765C16.019 8.5857 15.7092 8.25829 15.3181 8.25829ZM10.149 10.9217C8.30233 11.1678 6.90981 12.7603 6.90981 14.6264C6.90981 14.769 6.79434 14.8844 6.65176 14.8844C6.50951 14.8844 6.39371 14.769 6.39371 14.6264C6.39371 12.5026 7.9788 10.6901 10.0806 10.4101C10.2222 10.3914 10.3519 10.4908 10.3706 10.6321C10.3893 10.7733 10.29 10.903 10.149 10.9217Z"
                        />
                    </svg>
                </div>
                <span>Stand</span>
            </button>
            <button
                :class="$style['split']"
                v-on:click="$parent.blackjackSplitButton()"
                v-bind:disabled="
                    blackjackGetPlayer(table.playersPos)?.bet.actions.includes('split') === true ||
                    blackjackGetPlayer(table.playersPos)?.bet.cards.length !== 2 ||
                    blackjackGetPlayer(table.playersPos)?.bet.cards[0].rank !==
                        blackjackGetPlayer(table.playersPos)?.bet.cards[1].rank ||
                    !blackjackTimerInstance
                "
            >
                <div>
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="#4369D6"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.14403 0.106663L0.0475357 4.51079C-0.00822262 4.58883 -0.0154937 4.69154 0.0287143 4.77666C0.0729223 4.86177 0.161141 4.91491 0.257073 4.91422H1.8092V5.69005C1.80877 7.6164 2.64052 9.44908 4.09083 10.7174L4.7272 11.2734C5.50321 11.953 5.94824 12.9342 5.94821 13.9656V14.7414C5.94821 14.8842 6.06402 15 6.20689 15H8.79377C8.93664 15 9.05246 14.8842 9.05246 14.7414V13.9656C9.04766 12.0403 8.21684 10.2097 6.77083 8.93819L6.13446 8.38218C5.35845 7.7026 4.91343 6.72138 4.91346 5.69005V4.91422H6.46558C6.56151 4.91491 6.64973 4.86177 6.69394 4.77666C6.73815 4.69154 6.73088 4.58883 6.67512 4.51079L3.5631 0.106663C3.51435 0.0396479 3.43646 0 3.35357 0C3.27068 0 3.19279 0.0396479 3.14403 0.106663Z"
                        />
                        <path
                            d="M8.27614 4.65832C8.27641 4.72658 8.30384 4.79194 8.35237 4.83997C8.4009 4.88799 8.46654 4.91475 8.53483 4.91434H10.087V5.69017C10.087 6.72151 9.64196 7.70272 8.86595 8.3823L8.22958 8.93831C8.09764 9.05468 7.97089 9.17623 7.8493 9.30036C7.86483 9.32105 7.88293 9.33915 7.89846 9.35984C8.6394 10.2462 9.15504 11.2984 9.40143 12.427C9.60947 11.9866 9.90627 11.5939 10.2732 11.2736L10.9096 10.7175C12.3599 9.4492 13.1916 7.61652 13.1912 5.69017V4.91434H14.7433C14.8393 4.91504 14.9275 4.86189 14.9717 4.77678C15.0159 4.69166 15.0086 4.58895 14.9529 4.51091L11.8409 0.106785C11.7921 0.03977 11.7142 0.00012207 11.6313 0.00012207C11.5484 0.00012207 11.4705 0.03977 11.4218 0.106785L8.32529 4.51091C8.29379 4.55365 8.27659 4.60523 8.27614 4.65832Z"
                        />
                    </svg>
                </div>
                <span>Split</span>
            </button>
            <button
                :class="$style['double']"
                v-on:click="$parent.blackjackDoubleButton()"
                v-bind:disabled="
                    blackjackGetPlayer(table.playersPos)?.bet.actions.includes('split') === true ||
                    blackjackGetPlayer(table.playersPos)?.bet.cards.length !== 2 ||
                    blackjackGetCardValue(blackjackGetPlayer(table.playersPos)?.bet.cards) < 9 ||
                    blackjackGetCardValue(blackjackGetPlayer(table.playersPos)?.bet.cards) > 11 ||
                    !blackjackTimerInstance
                "
            >
                <div>
                    <svg
                        width="15"
                        height="9"
                        viewBox="0 0 15 9"
                        fill="#F8C02A"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2.58747 2.84348L3.41315 4.05652L4.22685 2.84348C4.37842 2.6 4.55792 2.47826 4.76533 2.47826H6.29701C6.41668 2.47826 6.48847 2.52174 6.51241 2.6087C6.54432 2.68696 6.52437 2.77391 6.45258 2.86957L4.45421 5.71304L6.47651 8.6087C6.54831 8.70435 6.56825 8.79565 6.53634 8.88261C6.51241 8.96087 6.44061 9 6.32095 9H4.56191C4.37842 9 4.19893 8.87826 4.02342 8.63478L3.18579 7.4087L2.34815 8.63478C2.17264 8.87826 1.99714 9 1.82163 9H0.23012C0.118435 9 0.0466377 8.96087 0.0147277 8.88261C-0.0171823 8.79565 0.00276143 8.70435 0.074559 8.6087L2.14472 5.68696L0.194222 2.86957C0.122424 2.77391 0.0984915 2.68696 0.122424 2.6087C0.154334 2.52174 0.23012 2.47826 0.349783 2.47826H2.03703C2.24444 2.47826 2.42792 2.6 2.58747 2.84348Z"
                        />
                        <path
                            d="M10.6443 7.14783H14.641C14.8803 7.14783 15 7.26957 15 7.51304V8.62174C15 8.87391 14.8803 9 14.641 9H8.88524C8.64592 9 8.52625 8.87391 8.52625 8.62174V6.66522C8.52625 6.16087 8.62996 5.78261 8.83738 5.53044C9.04479 5.26957 9.36788 5.04783 9.80664 4.86522L12.3674 3.8087C12.535 3.73913 12.6586 3.66087 12.7384 3.57391C12.8182 3.47826 12.858 3.33044 12.858 3.13044V2.63478C12.858 2.07826 12.4512 1.8 11.6375 1.8C10.8397 1.8 10.4409 2.0913 10.4409 2.67391V3.07826C10.4409 3.33913 10.3252 3.46957 10.0938 3.46957H8.70575C8.48238 3.46957 8.37069 3.33913 8.37069 3.07826V2.33478C8.37069 1.65652 8.62996 1.1 9.1485 0.665217C9.67501 0.221739 10.5087 0 11.6494 0C12.7982 0 13.6358 0.221739 14.1624 0.665217C14.6889 1.1 14.9521 1.65652 14.9521 2.33478V3.14348C14.9521 3.76087 14.8205 4.23913 14.5573 4.57826C14.294 4.9087 13.939 5.16087 13.4923 5.33478L11.1708 6.26087C10.9474 6.34783 10.8038 6.43043 10.74 6.5087C10.6762 6.58696 10.6443 6.71304 10.6443 6.88696V7.14783Z"
                        />
                    </svg>
                </div>
                <span>Double</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'BlackjackControlsAction',
        props: ['active', 'table'],
        data() {
            return {
                blackjackTimerRepeater: null,
                blackjackTimer: 13
            };
        },
        methods: {
            blackjackGetPlayer(seat) {
                return this.table.players[
                    this.table.players.findIndex((element) => element.seat === seat)
                ];
            },
            blackjackGetCardValue(cards) {
                let value = 0;
                let aces = false;

                for (const card of cards) {
                    if (card.rank === 'A') {
                        value = value + 1;
                        aces = true;
                    } else if (card.rank == 'K' || card.rank == 'Q' || card.rank == 'J') {
                        value = value + 10;
                    } else if (card.rank != 'hidden') {
                        value = value + Math.floor(card.rank);
                    }
                }

                if (aces == true && value <= 11) {
                    value = value + 10;
                }

                return value;
            },
            blackjackStartTimer() {
                const timeEnding = new Date(this.table.game.updatedAt).getTime() + 1000 * 10;
                this.blackjackTimer =
                    (timeEnding - (new Date().getTime() + this.generalTimeDiff)) / 1000;

                if (this.blackjackTimer <= 0) {
                    this.blackjackTimer = 0;
                } else {
                    this.blackjackTimerRepeater = requestAnimationFrame(this.blackjackStartTimer);
                }
            }
        },
        computed: {
            ...mapGetters(['generalTimeDiff']),
            blackjackTimerInstance() {
                return (
                    this.active &&
                    ((this.table.game.state === 'running' && this.table.playerPos !== null) ||
                        this.table.game.state === 'countdown')
                );
            }
        },
        watch: {
            table: {
                handler(data, oldData) {
                    if (this.blackjackTimerInstance) {
                        this.blackjackStartTimer();
                    }
                },
                deep: true
            }
        },
        beforeUnmount() {
            cancelAnimationFrame(this.blackjackTimerRepeater);
        },
        created() {
            if (
                this.table.game.state === 'countdown' ||
                (this.table.game.state === 'running' && this.table.playerPos !== null)
            ) {
                this.blackjackStartTimer();
            }
        }
    };
</script>

<style module>
    .blackjack-controls-action {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        transition: height 0.2s ease;
        height: 57px;
        width: 100%;
    }

    .blackjack-controls-action:not(.inactive) {
        height: calc(57px + 24px + 3px);
        animation: bj-controls-pop ease-out 1s;
    }

    @keyframes bj-controls-pop {
        0% {
            filter: brightness(1);
        }
        25% {
            filter: brightness(1.5);
        }
        50% {
            filter: brightness(1);
        }
        75% {
            filter: brightness(1.5);
        }
        100% {
            filter: brightness(1);
        }
    }

    .blackjack-controls-action .action-container {
        border-radius: 8px;
        border: 1px solid #0b3152;
        background: #04192b;
        padding: 7px;
        height: 57px;
        width: 100%;
        display: flex;
        gap: 7px;
    }

    .blackjack-controls-action .action-container button {
        background: #0d2d47;
        display: flex;
        align-items: center;
        padding: 0px 7px;
        position: relative;
        clip-path: var(--octagonal-main);
        font-size: 5px;
        flex: 1;
    }

    .blackjack-controls-action.inactive {
        opacity: 0.4;
    }

    .blackjack-controls-action:not(.inactive) .action-container button:disabled {
        opacity: 0.4;
    }

    .blackjack-controls-action .action-container button.hit {
        width: 20%;
        flex: unset;
    }

    .blackjack-controls-action .action-container button.stand {
        width: 25%;
        flex: unset;
    }

    .blackjack-controls-action .action-container button.split {
        width: 23%;
        flex: unset;
    }

    .blackjack-controls-action .action-container button div {
        height: 27px;
        width: 27px;
        background: #00000047;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: var(--octagonal-main);
        font-size: 3px;
    }

    .blackjack-controls-action .action-container button span {
        color: white;
        font-size: 16px;
        font-weight: 600;
        flex: 1;
        text-align: center;
    }

    .blackjack-controls-action.inactive .action-container button span,
    .blackjack-controls-action.inactive .action-container button svg,
    .blackjack-controls-action .action-container:not(.insurance) button:not(:hover) span,
    .blackjack-controls-action .action-container:not(.insurance) button:not(:hover) svg {
        fill: #5b85ae;
        color: #5b85ae;
    }

    .blackjack-controls-action .action-timer {
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: white;
        text-align: center;
        margin-bottom: 3px;
        opacity: 0;
        top: 0;
        position: absolute;
        transition: opacity 0.2s ease;
    }

    .blackjack-controls-action:not(.inactive) .action-timer {
        opacity: 1;
    }

    .blackjack-controls-action .action-timer span {
        font-size: 13px;
        display: inline-block;
        font-weight: 500;
        transform: translateY(-1px);
    }
</style>
