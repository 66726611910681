var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['roll-user-bets-element']},[_c('div',{class:_vm.$style['element-info']},[_c('div',{class:_vm.$style['info-item']},[_c('span',[_vm._v("BET:")]),_c('div',{class:_vm.$style['item-box']},[_c('img',{attrs:{"src":require(`@/assets/img/icons/${_vm.bet.coinType || 'cryptoCoin'}.svg`),"width":"11em","alt":"icon"}}),_c('p',{class:_vm.$style['box-value']},[_vm._v(" "+_vm._s(Math.floor(_vm.bet.amount / 1000))),_c('span',[_vm._v(_vm._s((_vm.bet.amount / 1000) .toFixed(2) .slice(-3) .replace('.00', '') .replace(/(?<=[.]\d)[0]/, '')))])])]),_c('div',{staticClass:"multiplier",class:_vm.$style['item-box']},[_c('p',[_vm._v(" "+_vm._s(parseFloat(_vm.bet.multiplier / 100) .toFixed(2) .replace('.00', '') .replace(/(?<=[.]\d)[0]/, ''))+"x ")])])]),_c('div',{class:_vm.$style['info-item']},[_c('span',[_vm._v("PAYOUT:")]),_c('div',{class:_vm.$style['item-box']},[_c('img',{attrs:{"src":require(`@/assets/img/icons/${_vm.bet.coinType || 'cryptoCoin'}.svg`),"width":"11em","alt":"icon"}}),_c('p',{class:_vm.$style['box-value']},[_vm._v(" "+_vm._s(Math.floor((_vm.bet.amount / 1000) * (_vm.bet.multiplier / 100)))),_c('span',[_vm._v(_vm._s(((_vm.bet.amount / 1000) * (_vm.bet.multiplier / 100)) .toFixed(2) .slice(-3) .replace('.00', '') .replace(/(?<=[.]\d)[0]/, '')))])])])])]),_c('div',{class:_vm.$style['element-percentage']},[_c('span',{class:[
                _vm.$style['percentage-amount'],
                _vm.bet.coinType === 'robuxCoin' ? 'fade-gold' : 'fade-green'
            ]},[_vm._v(_vm._s(parseFloat(9500 / _vm.bet.multiplier) .toFixed(2) .replace('.00', '') .replace(/(?<=[.]\d)[0]/, ''))+"%")]),_c('div',{class:[
                _vm.$style['percentage-progress'],
                _vm.$style[_vm.bet.coinType === 'robuxCoin' ? 'gold' : 'green']
            ],style:({ height: 9500 / _vm.bet.multiplier + '%' })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }