<template>
    <div
        ref="controlSort"
        :class="[$style['upgrader-filter-sort'], { [$style.active]: sortDropdown === true }]"
    >
        <button
            v-on:click="sortDropdown = !sortDropdown"
            :class="$style['sort-button']"
            class="octagonal before after hoverable"
        >
            <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.0537109 1.00548C0.0979443 0.913659 0.167173 0.836172 0.25345 0.781913C0.339727 0.727654 0.439555 0.698824 0.541475 0.698731H11.3807C11.4828 0.698692 11.5829 0.727508 11.6693 0.781858C11.7558 0.836208 11.8251 0.91388 11.8694 1.00592C11.9136 1.09797 11.9309 1.20064 11.9194 1.3021C11.9078 1.40357 11.8678 1.4997 11.804 1.57942L7.58696 6.85053V12.0799C7.58699 12.1871 7.55524 12.2919 7.49572 12.381C7.4362 12.4701 7.35158 12.5396 7.25257 12.5807C7.18682 12.6081 7.11625 12.6221 7.045 12.6219C6.90128 12.6218 6.76345 12.5647 6.66184 12.4631L4.49399 10.2952C4.39235 10.1936 4.33523 10.0558 4.3352 9.91207V6.85053L0.118204 1.57942C0.0544376 1.49956 0.0145483 1.40331 0.00313663 1.30176C-0.00827408 1.2002 0.00925732 1.0975 0.0537109 1.00548Z"
                    fill="white"
                />
                <path
                    d="M0.0537109 1.00548C0.0979443 0.913659 0.167173 0.836172 0.25345 0.781913C0.339727 0.727654 0.439555 0.698824 0.541475 0.698731H11.3807C11.4828 0.698692 11.5829 0.727508 11.6693 0.781858C11.7558 0.836208 11.8251 0.91388 11.8694 1.00592C11.9136 1.09797 11.9309 1.20064 11.9194 1.3021C11.9078 1.40357 11.8678 1.4997 11.804 1.57942L7.58696 6.85053V12.0799C7.58699 12.1871 7.55524 12.2919 7.49572 12.381C7.4362 12.4701 7.35158 12.5396 7.25257 12.5807C7.18682 12.6081 7.11625 12.6221 7.045 12.6219C6.90128 12.6218 6.76345 12.5647 6.66184 12.4631L4.49399 10.2952C4.39235 10.1936 4.33523 10.0558 4.3352 9.91207V6.85053L0.118204 1.57942C0.0544376 1.49956 0.0145483 1.40331 0.00313663 1.30176C-0.00827408 1.2002 0.00925732 1.0975 0.0537109 1.00548Z"
                    fill="url(#paint0_linear_137_1024)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_137_1024"
                        x1="-1.77603"
                        y1="0.176841"
                        x2="14.1147"
                        y2="4.46101"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                </defs>
            </svg>
            <div></div>
            <p v-if="upgraderFilterSort === 'price-descend'">
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M330.17 258.91a79 79 0 0 0-55 54.17c-14.27 51.05 21.19 97.77 68.83 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zM304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zM107.31 36.69a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31z"
                    ></path>
                </svg>
                Price
            </p>
            <span v-else>
                <svg
                    v-if="upgraderFilterSort.split('-')[1] === 'ascend'"
                    stroke="#E34343"
                    fill="#E34343"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zm26.15 162.91a79 79 0 0 0-55 54.17c-14.25 51.05 21.21 97.77 68.85 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zm-176-4h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.36 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352z"
                    ></path>
                </svg>
                <svg
                    v-else
                    stroke="#01DA9C"
                    fill="#01DA9C"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M330.17 258.91a79 79 0 0 0-55 54.17c-14.27 51.05 21.19 97.77 68.83 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zM304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zM107.31 36.69a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31z"
                    ></path>
                </svg>
                {{
                    upgraderFilterSort.split('-')[0][0].toUpperCase() +
                    upgraderFilterSort.split('-')[0].slice(1)
                }}
            </span>
            <div></div>
            <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547937 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84762C4.84671 6.05079 5.15326 6.05079 5.33889 5.84762L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                    fill="#406992"
                />
            </svg>
        </button>
        <div :class="$style['sort-menu']">
            <div :class="$style['menu-container']">
                <div :class="$style['menu-content']" class="octagonal before">
                    <button
                        v-for="(item, index) in [
                            'price-descend',
                            'price-ascend',
                            'alphabet-descend',
                            'alphabet-ascend'
                        ]"
                        v-bind:key="item"
                        class="octagonal after before hoverable"
                        :class="[
                            $style['content-item'],
                            { [$style.active]: upgraderFilterSort === item }
                        ]"
                        v-on:click="
                            upgraderSetFilterSort(item);
                            sortDropdown = false;
                        "
                    >
                        <svg
                            v-if="item.split('-')[1] === 'ascend'"
                            stroke="#E34343"
                            fill="#E34343"
                            stroke-width="0"
                            viewBox="0 0 448 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zm26.15 162.91a79 79 0 0 0-55 54.17c-14.25 51.05 21.21 97.77 68.85 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zm-176-4h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.36 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352z"
                            ></path>
                        </svg>
                        <svg
                            v-else
                            stroke="#01DA9C"
                            fill="#01DA9C"
                            stroke-width="0"
                            viewBox="0 0 448 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M330.17 258.91a79 79 0 0 0-55 54.17c-14.27 51.05 21.19 97.77 68.83 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zM304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zM107.31 36.69a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31z"
                            ></path>
                        </svg>
                        {{ item.split('-')[0][0].toUpperCase() + item.split('-')[0].slice(1) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'UpgraderFilterSortCases',
        data() {
            return {
                sortDropdown: false
            };
        },
        methods: {
            ...mapActions(['upgraderSetFilterSort'])
        },
        computed: {
            ...mapGetters(['upgraderFilterSort'])
        },
        created() {
            document.addEventListener('click', (event) => {
                const controlSort = this.$refs.controlSort;
                if (
                    controlSort &&
                    !controlSort.contains(event.target) &&
                    this.sortDropdown == true
                ) {
                    this.sortDropdown = false;
                }
            });
        }
    };
</script>

<style module>
    .upgrader-filter-sort {
        position: relative;
    }

    .upgrader-filter-sort .sort-button {
        display: flex;
        gap: 7px;
        align-items: center;
        filter: drop-shadow(0px 2px 0px #00000059);
        color: #406992;
        font-size: 15px;
        font-weight: 600;
        justify-content: center;
        height: 46px;
        padding: 0 20px;
    }

    .upgrader-filter-sort .sort-button {
        justify-content: space-between;
        min-width: 90px;
        padding: 0 12px;
        gap: 10px;
    }

    .upgrader-filter-sort .sort-button span {
        margin-left: -2px;
        color: white;
    }

    .upgrader-filter-sort .sort-button span svg,
    .upgrader-filter-sort .sort-button p svg {
        margin-bottom: -3px;
        margin-right: 1px;
    }

    .upgrader-filter-sort .sort-button:before,
    .upgrader-filter-sort .sort-button:after {
        background: linear-gradient(0deg, #0e273f, #0c2339);
        font-size: 3px;
    }

    .upgrader-filter-sort .sort-menu {
        position: absolute;
        left: 50%;
        top: calc(100% + 7px);
        min-width: calc(100% + 14px);
        transition: max-height 0.3s ease;
        transform: translateX(-50%);
        max-height: 0vh;
        z-index: 2;
        overflow: auto;
    }

    .upgrader-filter-sort.active .sort-menu {
        font-size: 7px;
        clip-path: var(--octagonal-main);
        max-height: 55vh;
    }

    .upgrader-filter-sort .menu-container {
        width: 100%;
        height: 100%;
    }

    .upgrader-filter-sort .menu-content {
        display: flex;
        flex-direction: column;
        background: #0e293e;
        padding: 7px 7px 8px 7px;
        gap: 7px;
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .upgrader-filter-sort .menu-content::before {
        background: #1f3d56;
    }

    .upgrader-filter-filter .content-item,
    .upgrader-filter-sort .content-item,
    .upgrader-filter-sort .content-item {
        filter: drop-shadow(0px 2px 0px #0f2133);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 10px;
        color: #6a8ba6;
        gap: 5px;
    }

    .upgrader-filter-sort .content-item.active {
        filter: drop-shadow(0px 2px 0px #0f2133) brightness(0.8);
    }

    .upgrader-filter-sort .content-item::after {
        font-size: 3px;
        background: #12283e;
    }

    .upgrader-filter-sort .content-item::before {
        font-size: 3px;
        background: #203e59;
    }
</style>
