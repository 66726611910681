<template>
    <div id="rollbet">
        <AppLoader v-if="appGetPageState === 'loading'" key="loading" />
        <Modals v-if="appGetPageState === 'active'" />
        <Notifications v-if="appGetPageState === 'active'" />
        <Navbar v-if="appGetPageState === 'active'" />
        <Chat v-if="appGetPageState === 'active'" />
        <main
            v-if="appGetPageState === 'active'"
            ref="mainContainer"
            :class="{
                [$style['main-bg']]: appHasBackground === '00',
                [$style['main-bg_01']]: appHasBackground === '01'
            }"
        >
            <transition name="slide-fade" mode="out-in">
                <router-view />
            </transition>
            <Footer />
        </main>
        <AppMaintenance v-if="appGetPageState === 'maintenance'" key="maintenance" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AppLoader from '@/components/AppLoader';
    import AppMaintenance from '@/components/AppMaintenance';
    import Navbar from '@/components/navbar/Navbar';
    import Chat from '@/components/chat/Chat';
    import Footer from '@/components/footer/Footer';
    import Modals from '@/components/modals/Modals';
    import Notifications from '@/components/notifications/Notifications';

    export default {
        components: {
            AppLoader,
            AppMaintenance,
            Navbar,
            Chat,
            Footer,
            Modals,
            Notifications
        },
        methods: {
            ...mapActions(['socketConnectGeneral'])
        },
        computed: {
            ...mapGetters(['socketGeneral', 'generalSettings', 'authToken', 'authUser']),
            appGetRouteName() {
                return this.$route.name;
            },
            appHasBackground() {
                let background = false;

                if (
                    [
                        'Home',
                        'Profile',
                        'ProfileTransactions',
                        'ProfileGames',
                        'ProfileSettings'
                    ].includes(this.appGetRouteName) === true
                ) {
                    background = '00';
                } else if (
                    ['Rewards', 'RewardsLeaderboard'].includes(this.appGetRouteName) === true
                ) {
                    background = '01';
                }

                return background;
            },
            appGetPageState() {
                if (
                    this.generalSettings === null ||
                    (this.authToken !== null && this.authUser.user === null)
                )
                    return 'loading';
                else if (
                    this.generalSettings.general.maintenance.enabled === false ||
                    (this.authUser.user !== null && this.authUser.user.rank === 'admin')
                )
                    return 'active';

                return 'maintenance';
            }
        },
        watch: {
            $route: {
                handler(to, from) {
                    if (this.$refs.mainContainer !== undefined) {
                        this.$nextTick(() => {
                            const mainContainer = this.$refs.mainContainer;
                            mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
                        });
                    }
                }
            }
        },
        created() {
            this.socketConnectGeneral();
            const asciiArt = `
      _______      _________      _____       ______     _
     / _____ \\    |____ ____|    / ___ \\     | ____ \\   | |
    / /     \\_\\       | |       / /   \\ \\    | |   \\ \\  | |
    | |               | |      / /     \\ \\   | |   | |  | |
    \\ \\______         | |      | |     | |   | |___/ /  | |
     \\______ \\        | |      | |     | |   |  ____/   | |
            \\ \\       | |      | |     | |   | |        | |
     _      | |       | |      \\ \\     / /   | |        |_|
    \\ \\_____/ /       | |       \\ \\___/ /    | |         _
     \\_______/        |_|        \\_____/     |_|        |_|

     Keep your account safe! Do not send any information from
     here to anyone or paste any text here.

     If someone is asking you to copy or paste text here then
     you're giving someone access to your account, your gear,
     and your Robux.

     For any questions or request for customer support, reach
     out to us in our Discord:
     
     https://discord.gg/rollbet
`;

            console.log(asciiArt);
        }
    };
</script>

<style>
    body main > .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    body main > .fade-leave-active {
        opacity: 0;
    }

    body main > .fade-enter-active,
    body main > .fade-enter-active {
        transition: opacity 0.5s ease;
    }

    body main > .fade-enter-from,
    body main > .fade-enter-from {
        opacity: 0;
    }

    body main > .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    body main > .slide-fade-enter {
        opacity: 0;
    }
</style>

<style module>
    body main {
        max-width: 100vw;
        left: 0;
        width: calc(100% - 325px);
        height: calc(100% - 84px);
        position: fixed;
        top: 84px;
        right: 325px;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .main-bg::after {
        background-image: url('~@/assets/img/home-background.png');
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: fixed;
        content: '';
        height: 62vh;
        width: 100%;
        top: 72px;
        left: 0;
        z-index: -1;
    }

    .main-bg_01::after {
        background-image: url('~@/assets/img/home-background_01.png');
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: fixed;
        content: '';
        height: 62vh;
        width: 100%;
        top: 72px;
        left: 6vw;
        z-index: -1;
    }

    body main::-webkit-scrollbar-track {
        background-color: transparent;
    }

    body main::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    body main::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    @media only screen and (max-width: 1500px) {
        body main {
            width: 100%;
            right: 0;
        }

        body main {
            height: calc(100% - 72px);
            top: 72px;
        }
    }
</style>
