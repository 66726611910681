import { render, staticRenderFns } from "./DuelsReel.vue?vue&type=template&id=8bb4ba48"
import script from "./DuelsReel.vue?vue&type=script&lang=js"
export * from "./DuelsReel.vue?vue&type=script&lang=js"
import style0 from "./DuelsReel.vue?vue&type=style&index=0&id=8bb4ba48&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports