import { cipher, decipher } from '../../../utils/cipher';
import router from '../../../router';
import axios from 'axios';

const state = {
    authSendLoginLoading: false,
    authStep: 'login',
    authToken: localStorage.getItem('token') === null ? null : localStorage.getItem('token'),
    authUser: {
        user: null,
        loading: false
    }
};

const getters = {
    authSendLoginLoading: (state) => state.authSendLoginLoading,
    authStep: (state) => state.authStep,
    authToken: (state) => state.authToken,
    authUser: (state) => state.authUser
};

const mutations = {
    auth_set_send_login_loading(state, status) {
        state.authSendLoginLoading = status;
    },
    auth_set_step(state, value) {
        state.authStep = value;
    },
    auth_set_token(state, token) {
        state.authToken = token;
    },
    auth_set_user(state, user) {
        state.authUser.user = user;
    },
    auth_update_user(state, user) {
        // state.authUser.user.balance = user.balance;
        state.authUser.user.balance.robuxCoin = user.balance.robuxCoin;
        state.authUser.user.balance.cryptoCoin = user.balance.cryptoCoin;
        state.authUser.user.xp = user.xp;
        state.authUser.user.stats = user.stats;
        state.authUser.user.rakeback = user.rakeback;
        state.authUser.user.mute = user.mute;
        state.authUser.user.ban = user.ban;
        state.authUser.user.verifiedAt = user.verifiedAt;
        state.authUser.user.updatedAt = user.updatedAt;
    },
    auth_set_user_anonymous(state, anonymous) {
        state.authUser.user.anonymous = anonymous;
    },
    auth_set_user_loading(state, status) {
        state.authUser.loading = status;
    }
};

const actions = {
    async authGetUser({ getters, commit, dispatch }, data) {
        if (getters.authUser.loading === true) {
            return;
        }
        commit('auth_set_user_loading', true);

        try {
            const res = await axios.get('/auth/whoami', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                if (res.data.user !== null) {
                    commit('auth_set_user', res.data.user);

                    dispatch('socketConnectCashier');

                    if (res.data.user.rank === 'admin' || res.data.user.rank === 'mod') {
                        dispatch('socketConnectAdmin');
                    }

                    // Check if sessionStorage has 'affiliate-code'
                    const affiliateCode = sessionStorage.getItem('affiliate-code');
                    if (affiliateCode !== null) {
                        // Re-route user to /rewards/affiliate
                        router.push({ path: '/rewards/affiliate' });
                    }
                } else {
                    localStorage.removeItem('token');
                    delete axios.defaults.headers.common['x-auth-token'];
                    location.reload();
                }
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                if (err.response.status === 401) {
                    dispatch('authLogoutUser');
                }
                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_user_loading', false);
    },
    async authSendCredentialsLogin({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/login', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                localStorage.setItem('token', res.data.token);
                axios.defaults.headers.common['x-auth-token'] = res.data.token;

                commit('auth_set_token', res.data.token);
                commit('auth_set_user', res.data.user);

                dispatch('socketConnectGeneral');
                dispatch('socketConnectCashier');

                if (res.data.user.rank === 'admin' || res.data.user.rank === 'mod') {
                    dispatch('socketConnectAdmin');
                }

                dispatch('modalsSetShow', null);

                // Check if sessionStorage has 'affiliate-code'
                const affiliateCode = sessionStorage.getItem('affiliate-code');
                if (affiliateCode !== null) {
                    // Re-route user to /rewards/affiliate
                    router.push({ path: '/rewards/affiliate' });
                }
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendCredentialsRegister({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/register', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                localStorage.setItem('token', res.data.token);
                axios.defaults.headers.common['x-auth-token'] = res.data.token;

                commit('auth_set_token', res.data.token);
                commit('auth_set_user', res.data.user);

                dispatch('socketConnectGeneral');
                dispatch('socketConnectCashier');

                if (res.data.user.rank === 'admin' || res.data.user.rank === 'mod') {
                    dispatch('socketConnectAdmin');
                }

                dispatch('modalsSetShow', null);

                // Check if sessionStorage has 'affiliate-code'
                const affiliateCode = sessionStorage.getItem('affiliate-code');
                if (affiliateCode !== null) {
                    // Re-route user to /rewards/affiliate
                    router.push({ path: '/rewards/affiliate' });
                }
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendCredentialsLink({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/link', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                commit('auth_set_user', { ...getters.authUser.user, local: res.data.user.local });

                dispatch('modalsSetShow', null);

                dispatch('notificationShow', {
                    type: 'success',
                    message: 'Your email has been successfully linked.'
                });
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendCredentialsRequest({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/request', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                dispatch('notificationShow', {
                    type: 'success',
                    message: `Please check your email's inbox/spam to ` + data.type
                });
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendCredentialsVerify({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/verify', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                dispatch('notificationShow', {
                    type: 'success',
                    message: 'Your email has been successfully verified.'
                });
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendCredentialsReset({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/reset', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                dispatch('modalsSetShow', null);

                dispatch('notificationShow', {
                    type: 'success',
                    message: 'Your password has been successfully reset.'
                });
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendRoblox({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        function trackRobloxLoginAttempts() {
            // Get current date and time
            const currentTime = new Date();

            // Retrieve attempt count and last attempt date from localStorage
            let attemptCount = localStorage.getItem('robloxLoginAttempts');
            let lastAttempt = localStorage.getItem('lastRobloxLoginAttempt');

            // Initialize if not present in localStorage
            if (attemptCount === null) attemptCount = 0;
            else attemptCount = parseInt(attemptCount, 10);

            // Set to epoch time
            if (lastAttempt === null) lastAttempt = new Date(0);
            else lastAttempt = new Date(lastAttempt);

            // Increment attempt count
            attemptCount++;

            // Update localStorage with new attempt count and current date as last attempt
            localStorage.setItem('robloxLoginAttempts', attemptCount);
            localStorage.setItem('lastRobloxLoginAttempt', currentTime.toISOString());

            // Check if attempt count is even and last attempt was less than a minute ago
            const timeDifference = (currentTime - lastAttempt) / 1000; // time difference in seconds
            if (attemptCount % 2 === 0 && timeDifference < 60) {
                const attemptTips = [
                    `Troubled connecting? try the .Roblosecurity method instead`,
                    `For faster way on signing in, link your email and set a Rollbet password`,
                    `Pro tip: logging in with the .Roblosecurity method is better`,
                    `Setting a Rollbet password via email linking is faster for signing in`,
                    `If .Roblosecurity doesn't work as well, please try again later`
                ];

                dispatch('notificationShow', {
                    type: 'error',
                    message: attemptTips[(attemptCount / 2) % attemptTips.length]
                });
            }
        }

        try {
            // Initiate roblox login attempt tracker
            if (data.step === 'login') trackRobloxLoginAttempts();

            const res = await axios.post('/auth/roblox', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                if (res.data.step === 'captcha') {
                    commit('auth_set_step', 'captcha');
                    dispatch('modalsSetData', {
                        challengeId: res.data.challengeId,
                        captchaId: res.data.captchaId,
                        captchaUrl: `${process.env.VUE_APP_BACKEND_URL}/captcha/iframe?blob=${res.data.captchaBlob}`,
                        secureAuthenticationIntent: res.data.secureAuthenticationIntent
                    });
                } else if (res.data.step === 'twostep') {
                    commit('auth_set_step', 'twostep');
                    dispatch('modalsSetData', {
                        type: res.data.type,
                        ticket: res.data.ticket,
                        robloxId: res.data.robloxId
                    });
                } else {
                    commit('auth_set_step', 'login');
                    commit('auth_set_user', res.data.user);

                    // Sanitize roblox login attempt tracker
                    localStorage.removeItem('robloxLoginAttempts');
                    localStorage.removeItem('lastRobloxLoginAttempt');

                    if (res.data.token !== undefined) {
                        localStorage.setItem('token', res.data.token);
                        axios.defaults.headers.common['x-auth-token'] = res.data.token;

                        commit('auth_set_token', res.data.token);
                        dispatch('socketConnectGeneral');
                        dispatch('socketConnectCashier');

                        if (res.data.user.rank === 'admin' || res.data.user.rank === 'mod') {
                            dispatch('socketConnectAdmin');
                        }
                    }

                    dispatch('modalsSetShow', null);

                    // Check if sessionStorage has 'affiliate-code'
                    const affiliateCode = sessionStorage.getItem('affiliate-code');
                    if (affiliateCode !== null) {
                        // Re-route user to /rewards/affiliate
                        router.push({ path: '/rewards/affiliate' });
                    }
                }
            }
        } catch (err) {
            commit('auth_set_step', 'login');
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendRobloxTwoStep({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/roblox/twostep', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                commit('auth_set_user', res.data.user);

                if (res.data.token !== undefined) {
                    localStorage.setItem('token', res.data.token);
                    axios.defaults.headers.common['x-auth-token'] = res.data.token;

                    commit('auth_set_token', res.data.token);
                    dispatch('socketConnectGeneral');
                    dispatch('socketConnectCashier');

                    if (res.data.user.rank === 'admin' || res.data.user.rank === 'mod') {
                        dispatch('socketConnectAdmin');
                    }
                }

                dispatch('modalsSetShow', null);

                // Check if sessionStorage has 'affiliate-code'
                const affiliateCode = sessionStorage.getItem('affiliate-code');
                if (affiliateCode !== null) {
                    // Re-route user to /rewards/affiliate
                    router.push({ path: '/rewards/affiliate' });
                }
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    async authSendRobloxCookie({ getters, commit, dispatch }, data) {
        if (getters.authSendLoginLoading === true) {
            return;
        }
        commit('auth_set_send_login_loading', true);

        try {
            const res = await axios.post('/auth/roblox/cookie', { data: cipher(data) });
            const decipheredData = decipher(res.data?.data);
            if (decipheredData) res.data = { ...res.data, ...decipheredData };

            if (res.data.success) {
                commit('auth_set_user', res.data.user);

                if (res.data.token !== undefined) {
                    localStorage.setItem('token', res.data.token);
                    axios.defaults.headers.common['x-auth-token'] = res.data.token;

                    commit('auth_set_token', res.data.token);
                    dispatch('socketConnectGeneral');
                    dispatch('socketConnectCashier');

                    if (res.data.user.rank === 'admin' || res.data.user.rank === 'mod') {
                        dispatch('socketConnectAdmin');
                    }
                }

                dispatch('modalsSetShow', null);

                // Check if sessionStorage has 'affiliate-code'
                const affiliateCode = sessionStorage.getItem('affiliate-code');
                if (affiliateCode !== null) {
                    // Re-route user to /rewards/affiliate
                    router.push({ path: '/rewards/affiliate' });
                }
            }
        } catch (err) {
            if (err.response !== undefined && err.response !== null) {
                const decipheredData = decipher(err.response.data?.data);
                if (decipheredData) err.response.data = { ...err.response.data, ...decipheredData };

                dispatch('notificationShow', err.response.data?.error);
            }
        }

        commit('auth_set_send_login_loading', false);
    },
    authLogoutUser({ commit }) {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['x-auth-token'];
        location.reload();
    }
};

const auth = {
    state,
    mutations,
    actions,
    getters
};

export default auth;
