var Emitter = require('component-emitter');

exports.protocol = 5;

/**
 * Developer's note:
 * Same exact parser script should be used on both frontend & backend
 */

var PacketType = (exports.PacketType = {
    CONNECT: 0,
    DISCONNECT: 1,
    EVENT: 2,
    ACK: 3,
    CONNECT_ERROR: 4
});

var isInteger =
    Number.isInteger ||
    function (value) {
        return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    };

var isString = function (value) {
    return typeof value === 'string';
};

var isObject = function (value) {
    return Object.prototype.toString.call(value) === '[object Object]';
};

var xorCipher = function (text, key = 'diwatapares') {
    const CHARSET = '_-ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charsetLength = CHARSET.length;

    const input = typeof text === 'string' ? text : JSON.stringify(text);

    const cipheredText = Array.from(input, (char, index) => {
        const textCharCode = CHARSET.indexOf(char);
        if (textCharCode === -1) {
            return char;
        }
        const keyCharCode = CHARSET.indexOf(key[index % key.length]);
        const cipheredCharCode = (textCharCode ^ keyCharCode) % charsetLength;
        return CHARSET[cipheredCharCode < 0 ? cipheredCharCode + charsetLength : cipheredCharCode];
    }).join('');

    return cipheredText;
};

var cipher = function (data) {
    const encrypted = xorCipher(
        typeof data === 'object' ? JSON.stringify(data) : data?.toString() || ''
    );

    return encrypted;
};

var decipher = function (data) {
    const decrypted = xorCipher(data);
    try {
        return JSON.parse(decrypted);
    } catch (error) {
        return decrypted;
    }
};

function Encoder() {}

Encoder.prototype.encode = function (packet) {
    return [cipher(packet)];
};

function Decoder() {}

Emitter(Decoder.prototype);

function isDataValid(decoded) {
    switch (decoded.type) {
        case PacketType.CONNECT:
            return decoded.data === undefined || isObject(decoded.data);
        case PacketType.DISCONNECT:
            return decoded.data === undefined;
        case PacketType.CONNECT_ERROR:
            return isObject(decoded.data);
        default:
            return Array.isArray(decoded.data);
    }
}

Decoder.prototype.add = function (obj) {
    var decoded = decipher(obj);

    var isTypeValid =
        isInteger(decoded.type) &&
        decoded.type >= PacketType.CONNECT &&
        decoded.type <= PacketType.CONNECT_ERROR;
    if (!isTypeValid) {
        throw new Error('invalid packet type');
    }

    if (!isString(decoded.nsp)) {
        throw new Error('invalid namespace');
    }

    if (!isDataValid(decoded)) {
        throw new Error('invalid payload');
    }

    var isAckValid = decoded.id === undefined || isInteger(decoded.id);
    if (!isAckValid) {
        throw new Error('invalid packet id');
    }

    this.emit('decoded', decoded);
};

Decoder.prototype.destroy = function () {};

exports.Encoder = Encoder;
exports.Decoder = Decoder;
