<template>
    <div :class="$style['app-loader']">
        <div>
            <img src="/chip.png" alt="" />
            <img src="/chip.png" alt="" />
            <img src="/chip.png" alt="" />
        </div>
        <p>
            Loading into Rollbet
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'AppLoader'
    };
</script>

<style module>
    .app-loader {
        width: 100%;
        height: 100%;
        position: fixed;
        display: flex;
        flex-direction: column;
        background-image: url('~@/assets/img/loader-background.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
        justify-content: center;
        align-items: center;
        gap: 14px;
        top: 0;
        left: 0;
    }

    .app-loader div {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        width: 70vw;
        max-width: 430px;
        display: grid;
    }

    .app-loader div img {
        width: 100%;
    }

    .app-loader div img:nth-child(1) {
        animation: app-loader-chip 2s ease-out infinite 0s;
    }

    .app-loader div img:nth-child(2) {
        animation: app-loader-chip 2s ease-out infinite 0.5s;
    }

    .app-loader div img:nth-child(3) {
        animation: app-loader-chip 2s ease-out infinite 1s;
    }

    @keyframes app-loader-chip {
        0% {
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }

    .app-loader p {
        text-align: center;
        font-size: 25px;
        color: white;
        font-weight: 600;
        letter-spacing: 0.05em;
    }

    .app-loader p span:nth-child(1) {
        animation: dot-00 2s linear infinite;
    }

    .app-loader p span:nth-child(2) {
        animation: dot-01 2s linear infinite;
    }

    .app-loader p span:nth-child(3) {
        animation: dot-02 2s linear infinite;
    }

    @keyframes dot-00 {
        0% {
            font-size: 0px;
        }
        20% {
            font-size: 0px;
        }
        25% {
            font-size: inherit;
        }
        99% {
            font-size: inherit;
        }
        100% {
            font-size: 0px;
        }
    }

    @keyframes dot-01 {
        0% {
            font-size: 0px;
        }
        45% {
            font-size: 0px;
        }
        50% {
            font-size: inherit;
        }
        99% {
            font-size: inherit;
        }
        100% {
            font-size: 0px;
        }
    }

    @keyframes dot-02 {
        0% {
            font-size: 0px;
        }
        70% {
            font-size: 0px;
        }
        75% {
            font-size: inherit;
        }
        99% {
            font-size: inherit;
        }
        100% {
            font-size: 0px;
        }
    }

    @media screen and (max-width: 1280px) {
        .app-loader {
            background-size: cover;
        }
    }
</style>
