<template>
    <div :class="$style['battles-create-filters']">
        <div :class="$style['filters-left']">
            <BattlesFilterMode />
            <div :class="$style['left-type']">
                <button
                    v-on:click="battlesTypeButton('standard')"
                    v-bind:class="{ [$style['button-active']]: battlesFilterType === 'standard' }"
                >
                    <span>STANDARD</span>
                </button>
                <button
                    v-on:click="battlesTypeButton('group')"
                    v-bind:class="{ [$style['button-active']]: battlesFilterType === 'group' }"
                    v-bind:disabled="battlesFilterMode === '2v2'"
                >
                    <span>GROUP</span>
                </button>
            </div>
        </div>
        <div :class="$style['filters-settings']">
            <div
                :class="[$style['settings-level'], { [$style['level-active']]: battlesLevel >= 1 }]"
            >
                MIN. LEVEL
                <span>{{ battlesLevel }}</span>
                <div :class="$style['level-range']">
                    <div :class="$style['range-fill']" :style="{ width: battlesLevel + '%' }"></div>
                    <input
                        v-model="battlesLevel"
                        v-on:input="battlesSetFilterLevel(battlesLevel)"
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                    />
                </div>
            </div>
            <div
                :class="[
                    $style['settings-funding'],
                    { [$style['funding-active']]: battlesFunding >= 1 }
                ]"
            >
                BATTLE FUNDING
                <span>{{ battlesFunding }}%</span>
                <div :class="$style['funding-range']">
                    <div
                        :class="$style['range-fill']"
                        :style="{ width: battlesFunding + '%' }"
                    ></div>
                    <input
                        v-model="battlesFunding"
                        v-on:input="battlesSetFilterFunding(battlesFunding)"
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import BattlesFilterMode from '@/components/battles/BattlesFilterMode';

    export default {
        name: 'BattlesCreateFilters',
        components: {
            BattlesFilterMode
        },
        data() {
            return {
                battlesLevel: 0,
                battlesFunding: 0
            };
        },
        methods: {
            ...mapActions([
                'battlesSetFilterType',
                'battlesSetFilterLevel',
                'battlesSetFilterFunding'
            ]),
            battlesTypeButton(value) {
                this.battlesSetFilterType(value);
            }
        },
        computed: {
            ...mapGetters([
                'battlesFilterMode',
                'battlesFilterType',
                'battlesFilterLevel',
                'battlesFilterFunding'
            ])
        },
        created() {
            this.battlesLevel = this.battlesFilterLevel;
            this.battlesFunding = this.battlesFilterFunding;
        }
    };
</script>

<style module>
    .battles-create-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .battles-create-filters .filters-left,
    .battles-create-filters .filters-settings {
        display: flex;
        align-items: center;
    }

    .battles-create-filters .left-type {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 20px;
        padding-left: 20px;
    }

    .battles-create-filters .left-type:before {
        content: '';
        width: 1px;
        height: 27px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        background: linear-gradient(
            0deg,
            rgba(97, 112, 241, 0) 0%,
            rgba(64, 92, 212, 0.35) 48.86%,
            rgba(28, 71, 182, 0) 100%
        );
    }

    .battles-create-filters .left-type button {
        height: 40px;
        margin-right: 9px;
        padding: 0 23px;
        font-size: 5px;
        font-weight: 700;
        color: #6e95b6;
        position: relative;
        background: #0c2134;
        opacity: 0.4;
        transition: all 0.3s ease;
        clip-path: var(--octagonal-main);
    }

    .battles-create-filters .left-type button span {
        font-size: 14px;
    }

    .battles-create-filters .left-type button::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #22425f;
        z-index: 1;
        font-size: 5px;
        clip-path: var(--octagonal-before);
    }

    .battles-create-filters .left-type button:last-child {
        margin-right: 0;
    }

    .battles-create-filters .left-type button.button-active {
        color: #ffffff;
        opacity: 1;
    }

    .battles-create-filters .settings-level,
    .battles-create-filters .settings-funding {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #537795;
    }

    .battles-create-filters .settings-funding {
        position: relative;
        margin-left: 20px;
        padding-left: 20px;
    }

    .battles-create-filters .settings-funding:before {
        content: '';
        width: 1px;
        height: 27px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        background: linear-gradient(
            0deg,
            rgba(97, 112, 241, 0) 0%,
            rgba(64, 92, 212, 0.35) 48.86%,
            rgba(28, 71, 182, 0) 100%
        );
    }

    .battles-create-filters .settings-funding span {
        width: 3.5em;
        margin-left: 5px;
        color: white;
    }

    .battles-create-filters .settings-level span {
        width: 2.5em;
        margin-left: 5px;
        color: white;
    }

    .battles-create-filters .settings-level .level-range,
    .battles-create-filters .settings-funding .funding-range {
        position: relative;
        height: 11px;
        width: 81px;
    }

    .level-range .range-fill,
    .funding-range .range-fill {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #41c48b;
        font-size: 1px;
        clip-path: var(--octagonal-main);
    }

    .battles-create-filters .settings-level .level-range::before,
    .battles-create-filters .settings-funding .funding-range::before {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        content: '';
        font-size: 1px;
        clip-path: var(--octagonal-main);
        background: #091621;
    }

    .battles-create-filters .settings-level input,
    .battles-create-filters .settings-funding input {
        width: 85px;
        height: 100%;
        left: -2px;
        top: 0;
        position: absolute;
        font-size: 0px;
        -webkit-appearance: none;
        -moz-apperance: none;
        background-color: transparent;
        filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .battles-create-filters .settings-level.level-active input::before,
    .battles-create-filters .settings-funding.funding-active input::before {
        background: rgba(0, 255, 194, 0.12);
    }

    .battles-create-filters .settings-level input::-webkit-slider-thumb,
    .battles-create-filters .settings-funding input::-webkit-slider-thumb {
        width: 21px;
        height: 21px;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        background: #0d3355;
        font-size: 2px;
        clip-path: var(--octagonal-after);
    }
    /* 
    .battles-create-filters .settings-level.level-active input::-webkit-slider-thumb,
    .battles-create-filters .settings-funding.funding-active input::-webkit-slider-thumb {
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    } */

    .battles-create-filters .settings-level input::-moz-range-thumb,
    .battles-create-filters .settings-funding input::-moz-range-thumb {
        width: 21px;
        height: 21px;
        background: #0d3355;
        cursor: pointer;
        font-size: 2px;
        clip-path: var(--octagonal-after);
    }

    .battles-create-filters .settings-level.level-active input::-moz-range-thumb,
    .battles-create-filters .settings-funding.funding-active input::-moz-range-thumb {
        background: #dcdcdc;
        border: 5.5px solid white;
        box-sizing: border-box;
    }

    @media only screen and (max-width: 1100px) {
        .battles-create-filters {
            flex-direction: column;
        }

        .battles-create-filters .filters-left,
        .battles-create-filters .filters-settings {
            width: 100%;
        }

        .battles-create-filters .filters-settings {
            margin-top: 25px;
        }
    }

    @media only screen and (max-width: 500px) {
        .battles-create-filters .filters-left {
            flex-direction: column;
        }

        .battles-create-filters .left-type {
            width: 100%;
            margin-top: 10px;
            margin-left: 0;
            padding-left: 0;
        }

        .battles-create-filters .left-type:before {
            display: none;
        }

        .battles-create-filters .left-type button {
            width: calc(50% - 4.5px);
        }
    }
</style>
