export function getLinkFromCryptoTransaction(transaction) {
    if (transaction.data === undefined || transaction.data.txID === undefined) return null;

    const type = transaction.data.cryptoCurrency || transaction.data.currency;
    switch (type?.toLowerCase()) {
        case 'eth':
        case 'usdt':
        case 'usdc':
            return `https://etherscan.io/tx/${transaction.data.txID}`;
        case 'sol':
            return `https://solscan.io/tx/${transaction.data.txID}`;
        case 'ltc':
            return `https://blockchair.com/litecoin/transactions/${transaction.data.txID}`;
        case 'btc':
            return `https://blockchair.com/bitcoin/transactions/${transaction.data.txID}`;
        default:
            return null;
    }
}

export function getLimitedItemColorFromAmount(amount) {
    let color = '';

    switch (true) {
        case amount > 135000:
            color = 'yellow';
            break;
        case amount > 80000:
            color = 'red';
            break;
        case amount > 35000:
            color = 'purple';
            break;
        case amount > 10000:
            color = 'green';
            break;
        case amount > 2500:
            color = 'blue';
            break;
        default:
            color = 'gray';
    }
    return color;
}
