import { render, staticRenderFns } from "./BattlesOverview.vue?vue&type=template&id=7ac90ad6&scoped=true"
import script from "./BattlesOverview.vue?vue&type=script&lang=js"
export * from "./BattlesOverview.vue?vue&type=script&lang=js"
import style0 from "./BattlesOverview.vue?vue&type=style&index=0&id=7ac90ad6&prod&scoped=true&lang=css"
import style1 from "./BattlesOverview.vue?vue&type=style&index=1&id=7ac90ad6&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7ac90ad6",
  null
  
)

export default component.exports