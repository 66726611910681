var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['battles-game-boxes'],
        { [_vm.$style['boxes-rolling']]: _vm.game !== null && _vm.game.state === 'rolling' }
    ]},[_c('div',{class:_vm.$style['boxes-background']},[_c('div',{class:_vm.$style['background-inner']})]),_c('div',{class:_vm.$style['boxes-selector']},[_c('div',{class:_vm.$style['selector-inner']},[(_vm.game !== null && _vm.game.state === 'completed')?_c('IconCheckGradient'):_vm._e()],1)]),_c('transition-group',{class:_vm.$style['boxes-list'],attrs:{"name":"list","tag":"div"}},_vm._l((_vm.battlesGetBoxes),function(box,index){return _c('button',{key:box.pos,class:[
                _vm.$style['button-box'],
                {
                    [_vm.$style['button-active']]:
                        (index === 0 &&
                            ['created', 'countdown', 'pending'].includes(_vm.game.state) ===
                                true) ||
                        box.pos === _vm.battlesGetRound - 1
                }
            ],on:{"click":function($event){return _vm.battlesBoxButton(box.box)}}},[_c('img',{style:({
                    opacity:
                        !index && ['rolling', 'completed'].includes(_vm.game.state)
                            ? 0.45
                            : !index
                              ? 1
                              : 0.3
                }),attrs:{"src":_vm.unboxImagePath + '/public/img/' + box.box.slug + '.png'}})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }