<template>
    <div :class="$style['modal-chat-user']" v-bind:key="generalUserInfo?.data?._id">
        <div :class="$style['user-header']">
            <div :class="$style['header-title']">
                <AvatarImage
                    v-bind:image="generalUserInfo.data?.avatar"
                    v-bind:rank="generalUserInfo.data?.rank"
                    v-bind:level="generalUserInfo.data?.level"
                />
                <p>{{ generalUserInfo.data?.username }}</p>
                <BoxLevel
                    v-bind:level="generalUserInfo.data?.level"
                    v-bind:rank="generalUserInfo.data?.rank"
                    v-bind:xp="generalUserInfo.data?.xp"
                    v-bind:color="modalGetLevelColor"
                />
            </div>
            <div :class="$style['header-actions']">
                <button
                    v-on:click="tip = true"
                    class="octagonal before after"
                    :class="[
                        $style['tip'],
                        $style['actions-item'],
                        { [$style.hoverable]: tip !== true }
                    ]"
                    v-bind:disabled="tip"
                >
                    <svg
                        v-if="tip === false"
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1.25098 2.45163H4.54579V3.50632H1.25098V2.45163Z" fill="white" />
                        <path d="M1.25098 8.47052H4.54579V9.52521H1.25098V8.47052Z" fill="white" />
                        <path d="M0 5.46106H4.5457V6.51575H0V5.46106Z" fill="white" />
                        <path
                            d="M12.0114 0C8.70934 0 6.02295 2.68639 6.02295 5.98841C6.02295 9.29043 8.70934 11.9768 12.0114 11.9768C15.3134 11.9768 17.9998 9.29043 17.9998 5.98841C17.9998 2.68639 15.3134 0 12.0114 0ZM12.0114 5.46107C12.92 5.46107 13.6592 6.20026 13.6592 7.10888C13.6592 7.83306 13.1894 8.44917 12.5387 8.66964V9.52671H11.484V8.66964C10.8333 8.44917 10.3636 7.83306 10.3636 7.10888H11.4182C11.4182 7.43593 11.6843 7.702 12.0114 7.702C12.3384 7.702 12.6045 7.43593 12.6045 7.10888C12.6045 6.78182 12.3384 6.51575 12.0114 6.51575C11.1027 6.51575 10.3636 5.77656 10.3636 4.86795C10.3636 4.14376 10.8333 3.52765 11.484 3.30718V2.45011H12.5387V3.30718C13.1894 3.52765 13.6592 4.14376 13.6592 4.86795H12.6045C12.6045 4.54089 12.3384 4.27482 12.0114 4.27482C11.6843 4.27482 11.4182 4.54089 11.4182 4.86795C11.4182 5.195 11.6843 5.46107 12.0114 5.46107Z"
                            fill="white"
                        />
                    </svg>
                    <svg
                        v-else
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.25098 2.45163H4.54579V3.50632H1.25098V2.45163Z"
                            fill="url(#paint0_linear_42_1879)"
                        />
                        <path
                            d="M1.25098 8.47052H4.54579V9.52521H1.25098V8.47052Z"
                            fill="url(#paint1_linear_42_1879)"
                        />
                        <path
                            d="M0 5.46106H4.5457V6.51575H0V5.46106Z"
                            fill="url(#paint2_linear_42_1879)"
                        />
                        <path
                            d="M12.0114 0C8.70934 0 6.02295 2.68639 6.02295 5.98841C6.02295 9.29043 8.70934 11.9768 12.0114 11.9768C15.3134 11.9768 17.9998 9.29043 17.9998 5.98841C17.9998 2.68639 15.3134 0 12.0114 0ZM12.0114 5.46107C12.92 5.46107 13.6592 6.20026 13.6592 7.10888C13.6592 7.83306 13.1894 8.44917 12.5387 8.66964V9.52671H11.484V8.66964C10.8333 8.44917 10.3636 7.83306 10.3636 7.10888H11.4182C11.4182 7.43593 11.6843 7.702 12.0114 7.702C12.3384 7.702 12.6045 7.43593 12.6045 7.10888C12.6045 6.78182 12.3384 6.51575 12.0114 6.51575C11.1027 6.51575 10.3636 5.77656 10.3636 4.86795C10.3636 4.14376 10.8333 3.52765 11.484 3.30718V2.45011H12.5387V3.30718C13.1894 3.52765 13.6592 4.14376 13.6592 4.86795H12.6045C12.6045 4.54089 12.3384 4.27482 12.0114 4.27482C11.6843 4.27482 11.4182 4.54089 11.4182 4.86795C11.4182 5.195 11.6843 5.46107 12.0114 5.46107Z"
                            fill="url(#paint3_linear_42_1879)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_42_1879"
                                x1="4.54579"
                                y1="2.45163"
                                x2="1.95947"
                                y2="4.43172"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_42_1879"
                                x1="4.54579"
                                y1="8.47052"
                                x2="1.95947"
                                y2="10.4506"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint2_linear_42_1879"
                                x1="4.5457"
                                y1="5.46106"
                                x2="1.8706"
                                y2="8.28669"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint3_linear_42_1879"
                                x1="17.9998"
                                y1="0"
                                x2="3.93263"
                                y2="3.44749"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                        </defs>
                    </svg>
                    {{ tip ? 'Tipping...' : 'Send tip' }}
                </button>
                <a
                    v-if="generalUserInfo?.data?.roblox !== undefined"
                    v-bind:href="
                        'https://www.roblox.com/users/' +
                        generalUserInfo?.data?.roblox?.id +
                        '/profile'
                    "
                    target="_blank"
                    :class="[$style['actions-item'], $style['roblox']]"
                    class="octagonal before after hoverable"
                >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                        ></path>
                    </svg>
                    R<svg
                        width="13"
                        height="13"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 0L0 16L15.5 20L20 4L4 0ZM7.5 11L8.5 8L12 9L11 12L7.5 11Z"
                        /></svg
                    >BLOX
                </a>
            </div>
        </div>

        <hr :class="$style['user-hr']" />

        <div v-if="tip !== true" :class="$style['user-info']">
            <div
                v-for="(item, index) in getInfoList"
                :class="$style['info-item']"
                v-bind:key="index"
            >
                <div
                    class="octagonal before"
                    :class="[
                        $style['item-content'],
                        {
                            [$style.negative]:
                                index === getInfoList.length - 1 && item.text.startsWith('-')
                        }
                    ]"
                >
                    <h4>
                        <span :class="{ 'fade-green': index === getInfoList.length - 1 }">{{
                            item.title
                        }}</span>
                    </h4>
                    <hr />
                    <p>
                        <span v-if="item.type !== 'text'" :class="$style.coins">
                            <img width="10" src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                            /
                            <img width="10" src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                        </span>
                        {{ item.text }}
                    </p>
                </div>
            </div>
        </div>

        <div v-else :class="$style['user-tip']">
            <div :class="$style['tip-input']" class="octagonal before after hoverable">
                <input v-model="tipAmount" v-on:input="tipInput" v-on:keydown.enter="tipSend" />

                <div :class="$style['input-icon']" class="octagonal">
                    <img
                        width="15"
                        v-bind:src="require(`@/assets/img/icons/${userBalanceData.type}.svg`)"
                    />
                </div>
                <button
                    v-on:click="tipAmount = 0"
                    :class="$style['input-clear']"
                    class="octagonal before after hoverable"
                >
                    Clear
                </button>
            </div>
            <div :class="$style['tip-controls']">
                <button
                    v-on:click="tip = false"
                    :class="$style['controls-item']"
                    class="octagonal before after hoverable"
                >
                    Go back
                </button>
                <button
                    v-on:click="tipSend"
                    :class="[$style['controls-item'], $style['send']]"
                    class="octagonal before after hoverable"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1.25098 2.45163H4.54579V3.50632H1.25098V2.45163Z" fill="white" />
                        <path d="M1.25098 8.47052H4.54579V9.52521H1.25098V8.47052Z" fill="white" />
                        <path d="M0 5.46106H4.5457V6.51575H0V5.46106Z" fill="white" />
                        <path
                            d="M12.0114 0C8.70934 0 6.02295 2.68639 6.02295 5.98841C6.02295 9.29043 8.70934 11.9768 12.0114 11.9768C15.3134 11.9768 17.9998 9.29043 17.9998 5.98841C17.9998 2.68639 15.3134 0 12.0114 0ZM12.0114 5.46107C12.92 5.46107 13.6592 6.20026 13.6592 7.10888C13.6592 7.83306 13.1894 8.44917 12.5387 8.66964V9.52671H11.484V8.66964C10.8333 8.44917 10.3636 7.83306 10.3636 7.10888H11.4182C11.4182 7.43593 11.6843 7.702 12.0114 7.702C12.3384 7.702 12.6045 7.43593 12.6045 7.10888C12.6045 6.78182 12.3384 6.51575 12.0114 6.51575C11.1027 6.51575 10.3636 5.77656 10.3636 4.86795C10.3636 4.14376 10.8333 3.52765 11.484 3.30718V2.45011H12.5387V3.30718C13.1894 3.52765 13.6592 4.14376 13.6592 4.86795H12.6045C12.6045 4.54089 12.3384 4.27482 12.0114 4.27482C11.6843 4.27482 11.4182 4.54089 11.4182 4.86795C11.4182 5.195 11.6843 5.46107 12.0114 5.46107Z"
                            fill="white"
                        />
                    </svg>
                    Send tip
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';
    import BoxLevel from '@/components/BoxLevel';
    import BoxRank from '@/components/BoxRank';

    export default {
        name: 'ModalChatUser',
        components: {
            AvatarImage,
            BoxLevel,
            BoxRank
        },
        data() {
            return {
                tip: false,
                tipAmount: 0
            };
        },
        methods: {
            ...mapActions([
                'modalsSetShow',
                'modalsSetData',
                'notificationShow',
                'userSendUserTipSocket'
            ]),
            modalFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            modalTipButton() {
                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetShow('Tip');
                }, 300);
            },
            tipSend() {
                if (this.socketSendLoading !== null) return;
                const amount = Math.floor(this.tipAmount * 1000);

                if (amount === undefined || isNaN(amount) === true || amount < 10 * 1000) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered tip amount is invalid, should be more than 10.'
                    });
                    return;
                }

                const data = {
                    receiverId: this.generalUserInfo.data?._id,
                    amount: amount,
                    type: this.userBalanceData.type
                };
                this.userSendUserTipSocket(data);
            },
            tipInput() {
                this.tipAmount =
                    Math.max(parseFloat(this.tipAmount) || 0, 0) +
                    (this.tipAmount.split('.').length < 3 && this.tipAmount.endsWith('.')
                        ? '.'
                        : '');
            }
        },
        computed: {
            ...mapGetters([
                'generalUserInfo',
                'socketSendLoading',
                'userBalanceData',
                'modalsData'
            ]),
            modalGetLevelColor() {
                let color = '';

                if (
                    this.generalUserInfo.data?.level >= 2 &&
                    this.generalUserInfo.data?.level < 26
                ) {
                    color = 'blue';
                } else if (
                    this.generalUserInfo.data?.level >= 26 &&
                    this.generalUserInfo.data?.level < 51
                ) {
                    color = 'sky';
                } else if (
                    this.generalUserInfo.data?.level >= 51 &&
                    this.generalUserInfo.data?.level < 76
                ) {
                    color = 'yellow';
                } else if (
                    this.generalUserInfo.data?.level >= 76 &&
                    this.generalUserInfo.data?.level <= 90
                ) {
                    color = 'purple';
                } else if (this.generalUserInfo.data?.level > 90) {
                    color = 'gold';
                }

                return color;
            },
            modalGetRank() {
                let rank = this.generalUserInfo.data?.rakeback;

                if (this.generalUserInfo.data?.rank !== 'user') {
                    rank = this.generalUserInfo.data?.rank;
                }

                return rank;
            },
            modalGetDate() {
                const date = new Date(this.generalUserInfo?.data?.createdAt);
                return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
            },
            getInfoList() {
                return [
                    {
                        title: 'MEMBER SINCE',
                        text: this.modalGetDate
                    },
                    {
                        title: 'DEPOSITED',
                        text: this.modalFormatValue(this.generalUserInfo?.data?.stats?.deposit)
                    },
                    {
                        title: 'WITHDRAWN',
                        text: this.modalFormatValue(this.generalUserInfo?.data?.stats?.withdraw)
                    },
                    {
                        title: 'NET PROFIT',
                        text: this.modalFormatValue(
                            this.generalUserInfo?.data?.stats?.withdraw -
                                this.generalUserInfo?.data?.stats?.deposit
                        )
                    }
                ];
            }
        },
        created() {
            if (this.modalsData?.chatUserTab === 'tip') {
                this.tip = true;
            }
        },
        destroyed() {
            if (this.modalsData?.chatUserTab === 'tip') {
                this.modalsSetData(null);
            }
        }
    };
</script>

<style module>
    .modal-chat-user {
        width: 838px;
        padding: 25px;
        max-width: calc(100vw - 20px);
    }

    .modal-chat-user .user-header,
    .modal-chat-user .header-actions {
        align-items: center;
        display: flex;
        gap: 7px;
    }

    .modal-chat-user .user-header {
        justify-content: space-between;
        margin-bottom: 14px;
        padding-right: 37px;
        margin-top: -3px;
    }

    .modal-chat-user .header-title > img {
        min-width: 36px;
        border-width: 2px;
        margin-right: 5px;
        width: 36px;
    }

    .modal-chat-user .header-title {
        display: flex;
        align-items: center;
        color: white;
        font-weight: 500;
        gap: 7px;
        font-size: 16px;
    }

    .modal-chat-user .user-hr {
        border-color: #0a2f4d;
        margin-bottom: 20px;
    }

    .modal-chat-user .user-info {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 4px;
        flex: 1;
        max-width: 966px;
    }

    .modal-chat-user .info-item {
        background: #0d243a;
        font-size: 10px;
        clip-path: var(--octagonal-main);
        padding: 4px;
        flex: 1;
    }

    .modal-chat-user .actions-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-weight: 600;
        padding: 7px 11px;
        z-index: 1;
        font-size: 14px;
        white-space: nowrap;
        color: white;
    }

    .modal-chat-user .actions-item.tip:not(:disabled) {
        filter: drop-shadow(0 2px 0 #136762) drop-shadow(0 0px 10px #21b89429);
    }

    .modal-chat-user .actions-item.tip::before,
    .modal-chat-user .actions-item.tip::after {
        font-size: 3px;
        background: #46b896;
    }

    .modal-chat-user .actions-item.tip:disabled:before {
        background: linear-gradient(0deg, #2a8073, transparent);
    }
    .modal-chat-user .actions-item.tip:disabled:after {
        font-size: 3px;
        background: linear-gradient(45deg, #143e48, #184b51);
    }

    .modal-chat-user .actions-item.roblox {
        filter: drop-shadow(0 2px 0 #00000059);
        color: #5b85ae;
    }

    .modal-chat-user .actions-item.roblox svg:last-of-type {
        mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), black);
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: -1px;
    }

    .modal-chat-user .actions-item.roblox::after {
        background: linear-gradient(0deg, #173552, #15314b);
        font-size: 3px;
    }

    .modal-chat-user .actions-item.roblox::before {
        background: #1c3b5e;
        font-size: 3px;
    }

    .modal-chat-user .item-content::before {
        background: #162a3d;
    }

    .modal-chat-user .info-item:last-of-type .item-content::before {
        background: #01d79a;
    }

    .modal-chat-user .info-item:last-of-type .item-content.negative::before {
        background: #c94f4f;
    }

    .modal-chat-user .item-content {
        align-items: center;
        padding: 14px;
        display: flex;
        flex-direction: column;
        height: 89px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #091d30, #091b2d, #081b2d);
        font-size: 8px;
        clip-path: var(--octagonal-main);
    }

    .modal-chat-user .item-content h4 {
        color: #5b84ae;
        font-size: 14px;
    }

    .modal-chat-user .item-content > hr {
        border-color: #1d466b;
        width: 104px;
        mask-image: linear-gradient(90deg, transparent, #1d466b, transparent);
    }

    .modal-chat-user .item-content p {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        color: white;
    }

    .modal-chat-user .item-content p .coins {
        color: #5b84ae;
        position: relative;
        margin: 0 10px;
    }

    .modal-chat-user .item-content p .coins img:first-of-type {
        position: absolute;
        left: -9px;
        top: 2px;
    }

    .modal-chat-user .item-content p .coins img:last-of-type {
        position: absolute;
        right: -10px;
        bottom: -2px;
    }

    .modal-chat-user .user-tip {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .modal-chat-user .tip-input {
        z-index: 1;
        width: 100%;
    }
    .modal-chat-user .tip-input::after {
        background: linear-gradient(0deg, #0e2841, #0e2841);
        font-size: 3px;
    }
    .modal-chat-user .tip-input::before {
        background: #122d48;
        font-size: 3px;
        color: #5a87ac;
    }

    .modal-chat-user .tip-input input {
        appearance: none;
        background: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 15px;
        font-weight: 500;
        padding: 20px 90px 20px 50px;
        color: #5a87ac;
    }

    .modal-chat-user .tip-input .input-icon {
        position: absolute;
        pointer-events: none;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
        width: 33px;
        height: 33px;
        font-size: 2px;
        background: linear-gradient(0deg, #163555, #122e49);
        clip-path: var(--octagonal-main);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-chat-user .tip-input .input-clear {
        display: flex;
        align-items: center;
        justify-content: center;
        right: 13px;
        position: absolute;
        top: 50%;
        font-size: 14px;
        font-weight: 600;
        transform: translateY(-50%);
        filter: drop-shadow(0 2px 0 #00000059);
        height: 30px;
        width: 70px;
        color: #5b85ae;
        z-index: 1;
    }

    .modal-chat-user .tip-input .input-clear::after {
        background: linear-gradient(0deg, #173552, #15314b);
        font-size: 2px;
    }

    .modal-chat-user .tip-input .input-clear::before {
        background: #1c3b5e;
        font-size: 2px;
    }

    .modal-chat-user .tip-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
    }

    .modal-chat-user .tip-controls .controls-item {
        display: flex;
        align-items: center;
        min-width: 127px;
        justify-content: center;
        filter: drop-shadow(0 2px 0 #00000059);
        gap: 7px;
        padding: 10px 20px;
        font-size: 15px;
        font-weight: 600;
        color: #5b85ae;
        z-index: 1;
    }

    .modal-chat-user .tip-controls .controls-item::after {
        background: linear-gradient(0deg, #173552, #15314b);
        font-size: 3px;
    }

    .modal-chat-user .tip-controls .controls-item::before {
        background: #1c3b5e;
        font-size: 3px;
    }

    .modal-chat-user .tip-controls .controls-item.send {
        filter: drop-shadow(0 2px 0 #136762) drop-shadow(0 0px 10px #21b89429);
        color: white;
    }

    .modal-chat-user .tip-controls .controls-item.send::before,
    .modal-chat-user .tip-controls .controls-item.send::after {
        font-size: 3px;
        background: #46b896;
    }

    button:global(.x) {
        transform: translateY(7.5px);
    }

    @media screen and (max-width: 550px) {
        button:global(.x) {
            transform: translateY(3px);
        }
    }

    @media only screen and (max-width: 550px) {
        .modal-chat-user {
            padding: 20px;
        }

        .modal-chat-user .user-header {
            flex-direction: column;
            padding-right: unset;
        }
    }
</style>
