<template>
    <div :class="$style.blackjack">
        <transition name="slide-fade" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'Blackjack',
        metaInfo: {
            title: 'Blackjack - Rollbet.gg'
        },
        methods: {
            ...mapActions(['socketConnectBlackjack', 'socketDisconnectBlackjack'])
        },
        created() {
            this.socketConnectBlackjack();
        },
        beforeRouteLeave(to, from, next) {
            this.socketDisconnectBlackjack();
            next();
        }
    };
</script>

<style scoped>
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-enter {
        opacity: 0;
    }
</style>

<style module>
    .blackjack {
        width: 100%;
    }
</style>
