<template>
    <div
        v-bind:class="[
            $style['cashier-limited-selected'],
            { [$style['selected-open']]: cashierOpen === true }
        ]"
    >
        <button v-on:click="cashierSelectedButton(true)" :class="$style['button-open']">
            <p
                :class="$style['open-count']"
                v-bind:key="cashierGetSelected.length"
                v-if="cashierGetSelected.length"
            >
                {{ cashierGetSelected.length }}
            </p>
            <div :class="$style['button-inner']">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                    <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                    />
                </svg>
            </div>
        </button>
        <button v-on:click="cashierSelectedButton(false)" :class="$style['selected-overlay']" />
        <div :class="$style['selected-container']">
            <div :class="$style['container-content']">
                <div :class="$style['content-title']">
                    <svg
                        width="7"
                        height="16"
                        viewBox="0 0 7 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.546955 4.19815C0.58419 4.20475 0.621873 4.2078 0.659575 4.20726L4.97945 4.20726L4.83946 4.27843C4.70263 4.34922 4.57814 4.44556 4.47159 4.5631L3.37129 5.76575C3.13419 6.01315 3.09435 6.41113 3.27689 6.70872C3.48933 7.02583 3.89673 7.09469 4.18688 6.86248C4.21032 6.84371 4.2326 6.82325 4.25354 6.80123L6.80904 4.008C7.06346 3.73022 7.06368 3.27962 6.80955 3.00153L4.25354 0.20774C3.99891 -0.0697904 3.58667 -0.0691678 3.33274 0.209141C3.31276 0.231047 3.2941 0.254354 3.27688 0.278906C3.09435 0.576497 3.13419 0.974478 3.37129 1.22187L4.46833 2.42808C4.56386 2.53261 4.67369 2.62026 4.79388 2.68784L4.98921 2.78392L0.695406 2.78392C0.362795 2.77042 0.0709798 3.02438 0.00849447 3.38172C-0.0490669 3.7697 0.192003 4.13521 0.546955 4.19815Z"
                            fill="#406992"
                        />
                        <path
                            d="M0.546955 4.19815C0.58419 4.20475 0.621873 4.2078 0.659575 4.20726L4.97945 4.20726L4.83946 4.27843C4.70263 4.34922 4.57814 4.44556 4.47159 4.5631L3.37129 5.76575C3.13419 6.01315 3.09435 6.41113 3.27689 6.70872C3.48933 7.02583 3.89673 7.09469 4.18688 6.86248C4.21032 6.84371 4.2326 6.82325 4.25354 6.80123L6.80904 4.008C7.06346 3.73022 7.06368 3.27962 6.80955 3.00153L4.25354 0.20774C3.99891 -0.0697904 3.58667 -0.0691678 3.33274 0.209141C3.31276 0.231047 3.2941 0.254354 3.27688 0.278906C3.09435 0.576497 3.13419 0.974478 3.37129 1.22187L4.46833 2.42808C4.56386 2.53261 4.67369 2.62026 4.79388 2.68784L4.98921 2.78392L0.695406 2.78392C0.362795 2.77042 0.0709798 3.02438 0.00849447 3.38172C-0.0490669 3.7697 0.192003 4.13521 0.546955 4.19815Z"
                            fill="url(#paint0_linear_287_4761)"
                        />
                        <path
                            d="M6.45305 11.8019C6.41581 11.7952 6.37813 11.7922 6.34043 11.7927H2.02055L2.16054 11.7216C2.29737 11.6508 2.42186 11.5544 2.52841 11.4369L3.62871 10.2342C3.86581 9.98685 3.90565 9.58887 3.72312 9.29128C3.51067 8.97417 3.10327 8.90531 2.81312 9.13752C2.78968 9.15629 2.7674 9.17675 2.74646 9.19876L0.190961 11.992C-0.0634566 12.2698 -0.0636805 12.7204 0.190452 12.9985L2.74646 15.7923C3.00109 16.0698 3.41333 16.0692 3.66726 15.7909C3.68724 15.769 3.7059 15.7456 3.72312 15.7211C3.90565 15.4235 3.86581 15.0255 3.62871 14.7781L2.53167 13.5719C2.43614 13.4674 2.32631 13.3797 2.20612 13.3122L2.01079 13.2161H6.30459C6.63721 13.2296 6.92902 12.9756 6.99151 12.6183C7.04907 12.2303 6.808 11.8648 6.45305 11.8019Z"
                            fill="#406992"
                        />
                        <path
                            d="M6.45305 11.8019C6.41581 11.7952 6.37813 11.7922 6.34043 11.7927H2.02055L2.16054 11.7216C2.29737 11.6508 2.42186 11.5544 2.52841 11.4369L3.62871 10.2342C3.86581 9.98685 3.90565 9.58887 3.72312 9.29128C3.51067 8.97417 3.10327 8.90531 2.81312 9.13752C2.78968 9.15629 2.7674 9.17675 2.74646 9.19876L0.190961 11.992C-0.0634566 12.2698 -0.0636805 12.7204 0.190452 12.9985L2.74646 15.7923C3.00109 16.0698 3.41333 16.0692 3.66726 15.7909C3.68724 15.769 3.7059 15.7456 3.72312 15.7211C3.90565 15.4235 3.86581 15.0255 3.62871 14.7781L2.53167 13.5719C2.43614 13.4674 2.32631 13.3797 2.20612 13.3122L2.01079 13.2161H6.30459C6.63721 13.2296 6.92902 12.9756 6.99151 12.6183C7.04907 12.2303 6.808 11.8648 6.45305 11.8019Z"
                            fill="url(#paint1_linear_287_4761)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_287_4761"
                                x1="7"
                                y1="0"
                                x2="-1.61646"
                                y2="0.923856"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_287_4761"
                                x1="7"
                                y1="0"
                                x2="-1.61646"
                                y2="0.923856"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <p>
                        {{
                            cashierGetRouteName === 'LimitedsDeposit'
                                ? 'Your Listings'
                                : 'Your Trades'
                        }}
                    </p>
                </div>
                <div :class="$style['content-list']">
                    <CashierSelectedElement
                        v-for="selected in cashierGetSelected[0]?.items"
                        v-if="
                            cashierGetSelected.length < 2 &&
                            cashierGetRouteName === 'LimitedsWithdraw'
                        "
                        v-bind:mainSelected="cashierGetSelected[0]"
                        v-bind:key="selected.uniqueId"
                        v-bind:selected="selected"
                    />
                    <CashierSelectedElement
                        v-for="selected in cashierGetSelected"
                        v-if="
                            selected.items === undefined &&
                            cashierGetRouteName === 'LimitedsDeposit'
                        "
                        v-bind:key="selected.uniqueId"
                        v-bind:selected="selected"
                    />
                </div>
                <div
                    :class="[
                        $style['content-action'],
                        { [$style['proceed']]: cashierProceed === true }
                    ]"
                >
                    <div :class="$style['action-note']" class="octagonal before after">
                        <button
                            v-on:click="cashierProceed = false"
                            class="octagonal before after hoverable"
                        >
                            <svg
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.32453 4.49997L8.82895 0.995447C9.05701 0.767492 9.05701 0.398921 8.82895 0.170966C8.601 -0.0569887 8.23242 -0.0569887 8.00447 0.170966L4.49994 3.67549L0.995527 0.170966C0.767466 -0.0569887 0.399001 -0.0569887 0.171046 0.170966C-0.0570154 0.398921 -0.0570154 0.767492 0.171046 0.995447L3.67546 4.49997L0.171046 8.0045C-0.0570154 8.23245 -0.0570154 8.60102 0.171046 8.82898C0.28465 8.94269 0.434022 8.99981 0.583287 8.99981C0.732552 8.99981 0.881817 8.94269 0.995527 8.82898L4.49994 5.32445L8.00447 8.82898C8.11818 8.94269 8.26744 8.99981 8.41671 8.99981C8.56597 8.99981 8.71524 8.94269 8.82895 8.82898C9.05701 8.60102 9.05701 8.23245 8.82895 8.0045L5.32453 4.49997Z"
                                    fill="#5B84AE"
                                />
                            </svg>
                        </button>
                        <h4>Note!</h4>
                        <p v-if="cashierGetRouteName === 'LimitedsWithdraw'">
                            Your balance will subtract the amount once the listed item&lsquo;s owner
                            accepts the occurring trade offer.
                        </p>
                        <p v-else>
                            After your item listing is sold and carried out successfully it may take
                            a minute or two to receive your balance.
                        </p>
                    </div>
                    <button
                        v-if="cashierProceed === true"
                        v-on:click="cashierActionButton()"
                        :class="[
                            $style['action-button'],
                            $style[
                                userBalanceData.type === 'robuxCoin' ||
                                cashierGetRouteName === 'LimitedsDeposit'
                                    ? 'robuxCoin'
                                    : 'cryptoCoin'
                            ]
                        ]"
                        v-bind:disabled="socketSendLoading !== null"
                        class="octagonal hoverable"
                    >
                        <span
                            :class="
                                $style[
                                    userBalanceData.type === 'robuxCoin' ||
                                    cashierGetRouteName === 'LimitedsDeposit'
                                        ? 'fade-gold'
                                        : 'fade-green'
                                ]
                            "
                        >
                            Proceed
                        </span>
                    </button>
                    <button
                        v-else
                        v-on:click="cashierActionButton()"
                        :class="[
                            $style['action-button'],
                            $style[
                                userBalanceData.type === 'robuxCoin' ||
                                cashierGetRouteName === 'LimitedsDeposit'
                                    ? 'robuxCoin'
                                    : 'cryptoCoin'
                            ]
                        ]"
                        v-bind:disabled="socketSendLoading !== null"
                        class="octagonal hoverable"
                    >
                        <!-- <ButtonLoading
                            v-if="
                                ['LimitedEnable', 'LimitedDeposit', 'LimitedWithdraw'].includes(
                                    socketSendLoading
                                ) === true
                            "
                        /> -->
                        <span
                            :class="
                                $style[
                                    userBalanceData.type === 'robuxCoin' ||
                                    cashierGetRouteName === 'LimitedsDeposit'
                                        ? 'fade-gold'
                                        : 'fade-green'
                                ]
                            "
                        >
                            {{
                                (function () {
                                    const word =
                                        cashierGetRouteName === 'LimitedsDeposit'
                                            ? 'List'
                                            : 'Withdraw';
                                    const suffix =
                                        (cashierGetSelected?.[0]?.items?.length ||
                                            cashierGetSelected.length) > 1
                                            ? 's'
                                            : '';
                                    return `${word} Item${suffix}`;
                                })()
                            }}
                        </span>
                        <img
                            width="18"
                            v-if="
                                userBalanceData.type === 'robuxCoin' ||
                                cashierGetRouteName === 'LimitedsDeposit'
                            "
                            src="@/assets/img/icons/robuxCoin.svg"
                        />
                        <img width="13" v-else src="@/assets/img/icons/cryptoCoin.svg" />
                        <p>
                            {{ Math.floor(cashierGetAmount).toLocaleString() }}
                            <span>{{ cashierGetAmount.toFixed(2).slice(-3) }}</span>
                        </p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconClose from '@/components/icons/IconClose';
    import ButtonLoading from '@/components/ButtonLoading';
    import CashierSelectedElement from '@/components/cashier/CashierSelectedElement';

    export default {
        name: 'CashierLimitedSelected',
        components: {
            IconClose,
            ButtonLoading,
            CashierSelectedElement
        },
        data() {
            return {
                cashierOpen: false,
                cashierProceed: false
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'cashierSendLimitedEnableSocket',
                'cashierSendLimitedDepositSocket',
                'cashierSendLimitedWithdrawSocket'
            ]),
            cashierSelectedButton(value) {
                this.cashierOpen = value;
            },
            cashierActionButton() {
                if (this.cashierLimitedData.selected.length < 1) {
                    this.notificationShow({
                        type: 'error',
                        message: `Your cart is empty`
                    });
                    return;
                }

                if (this.cashierProceed === false) {
                    this.cashierProceed = true;
                    return;
                } else {
                    this.cashierProceed = false;
                }

                if (this.cashierGetRouteName === 'LimitedsDeposit') {
                    let items = [];

                    for (let item of this.cashierLimitedData.selected) {
                        items.push({ uniqueId: item.uniqueId });
                    }

                    const data = { items: items, amount: this.cashierGetAmount * 1000 };
                    this.cashierSendLimitedDepositSocket(data);
                } else if (
                    this.authUser.user.verifiedAt === undefined ||
                    new Date().getTime() + this.generalTimeDiff >=
                        new Date(this.authUser.user.verifiedAt).getTime() + 1000 * 60 * 30
                ) {
                    const data = {
                        transactionId: this.cashierLimitedData.selected[0]._id,
                        type: this.userBalanceData.type
                    };
                    this.cashierSendLimitedEnableSocket(data);
                } else {
                    const data = {
                        transactionId: this.cashierLimitedData.selected[0]._id,
                        type: this.userBalanceData.type
                    };
                    this.cashierSendLimitedWithdrawSocket(data);
                }
            }
        },
        computed: {
            ...mapGetters([
                'generalTimeDiff',
                'socketSendLoading',
                'authUser',
                'cashierLimitedData',
                'userBalanceData'
            ]),
            cashierGetRouteName() {
                return this.$route.name;
            },
            cashierGetSelected() {
                let selected = this.cashierLimitedData.selected;

                selected.sort((a, b) => {
                    return b.amount - a.amount;
                });

                return selected;
            },
            cashierGetAmount() {
                return (
                    this.cashierGetSelected.reduce(
                        (acc, item) =>
                            acc + (item.amountFixed === undefined ? item.amount : item.amountFixed),
                        0
                    ) / 1000
                );
            }
        },
        watch: {
            cashierLimitedData: {
                deep: true,
                handler() {
                    this.cashierProceed = false;
                }
            }
        }
    };
</script>

<style module>
    .cashier-limited-selected {
        width: 100%;
        max-width: 305px;
    }

    .cashier-limited-selected .selected-overlay {
        display: none;
        width: 120vw;
        height: 120vh;
        position: fixed;
        top: -91px;
        right: -10px;
        transform: translateX(0px);
        background-color: rgba(2, 20, 35, 0.425);
        transition:
            opacity 0.3s ease,
            transform 0.3s ease;
        pointer-events: none;
        opacity: 0;
        user-select: none;
        z-index: 2;
    }

    .cashier-limited-selected button.button-open {
        width: 50px;
        height: 50px;
        position: absolute;
        display: none;
        bottom: 50px;
        left: -65px;
        filter: drop-shadow(0px 4px 25px rgba(1, 230, 169, 0.15))
            drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.35));
    }

    .cashier-limited-selected button.button-open .open-count {
        background: #c83434;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 12px;
        height: 17px;
        min-width: 17px;
        font-weight: 500;
        padding: 0px 4px 1.5px 4px;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        left: 90%;
        top: 10%;
        transform: translate(-50%, -50%) scale(1);
        animation: open-count 0.2s ease;
    }

    @keyframes open-count {
        0% {
            transform: translate(-50%, -50%) scale(1);
        }
        20% {
            transform: translate(-50%, -50%) scale(2);
        }
        100% {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    .cashier-limited-selected button.button-open .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 75%);
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .cashier-limited-selected button.button-open .button-inner svg {
        width: 19px;
        height: 19px;
        margin-top: 3px;
        fill: #ffffff;
    }

    .cashier-limited-selected button.button-close {
        position: absolute;
        display: none;
        top: 3px;
        left: 10px;
        z-index: 1;
    }

    .cashier-limited-selected button.button-close svg {
        fill: #6e95b6;
    }

    .cashier-limited-selected .selected-container {
        width: 100%;
        padding: 4px;
        font-size: 20px;
        clip-path: var(--octagonal-main);
        position: sticky;
        top: 45px;
        background: #0d243a;
        z-index: 3;
    }

    .cashier-limited-selected .container-content {
        padding: 14px;
        width: 100%;
        height: 100%;
        min-height: 50vh;
        max-height: calc(100vh - 91px - 45px - 45px - 4px - 4px);
        font-size: 18px;
        position: relative;
        clip-path: var(--octagonal-main);
        background: linear-gradient(120deg, #0b2137, #0b1f32);
        display: flex;
        flex-direction: column;
    }

    .cashier-limited-selected .container-content::before {
        background: #14283e;
        font-size: 18px;
    }

    .cashier-limited-selected .content-title p {
        color: white;
        font-size: 15px;
        font-weight: 500;
    }

    .cashier-limited-selected .content-title {
        display: flex;
        align-items: center;
        justify-content: center;
        filter: drop-shadow(0px 2px 0px #00000059);
        position: relative;
        height: 46px;
        min-height: 46px;
        margin-bottom: -5px;
        z-index: 1;
        gap: 7px;
    }

    .cashier-limited-selected .content-title::after {
        clip-path: var(--octagonal-main) !important;
        font-size: 5px;
        background: #0f2840;
    }

    .cashier-limited-selected .content-list {
        border-bottom: 1px solid #183157;
        margin-bottom: 12px;
        overflow: auto;
        display: flex;
        padding: 16px 0px 12px 0px;
        width: calc(100% + 8px);
        margin-left: -4px;
        flex-direction: column;
        gap: 4px;
        flex: 1;
    }

    .cashier-limited-selected .content-action {
        position: relative;
        width: 100%;
    }

    .cashier-limited-selected .action-note {
        transition: all 0.3s ease;
        position: absolute;
        text-align: center;
        font-size: 13px;
        line-height: 1.2;
        font-weight: 500;
        color: #406992;
        bottom: calc(100% + 27px);
        padding: 8px 10px 12px 10px;
        transform: translateY(25%);
        pointer-events: none;
        opacity: 0;
        width: 100%;
        left: 0;
        z-index: 5;
    }

    .cashier-limited-selected .action-note button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
    }

    .cashier-limited-selected .action-note button::before {
        background: #1e4b7e59;
        font-size: 2px;
    }

    .cashier-limited-selected .action-note button::after {
        font-size: 2px;
        background: linear-gradient(0deg, #0d2d47, #0d2d47),
            linear-gradient(360deg, rgba(15, 66, 107, 0.35) 8.33%, rgba(13, 51, 85, 0.35) 108.33%);
    }

    .cashier-limited-selected .content-action.proceed .action-note {
        transform: translateY(0%);
        pointer-events: all;
        opacity: 1;
    }

    .cashier-limited-selected .action-note h4 {
        margin-bottom: 2px;
        color: #21b894;
    }

    .cashier-limited-selected .action-note::before {
        font-size: 5px;
        background: #12385e;
    }

    .cashier-limited-selected .action-note::after {
        font-size: 5px;
        background: linear-gradient(360deg, #041f35 19.46%, #0d3355 84.18%);
    }

    .cashier-limited-selected .action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        min-height: 44px;
        position: relative;
        font-weight: 600;
        color: white;
        font-size: 15px;
        width: 100%;
        gap: 7px;
    }

    .cashier-limited-selected .action-button p span {
        opacity: 0.6;
        font-size: 11px;
    }

    .cashier-limited-selected .action-button::after {
        font-size: 5px;
        background: linear-gradient(90deg, #1c5556, #206162);
    }

    .cashier-limited-selected .action-button::before {
        font-size: 5px;
        background: linear-gradient(0deg, #55f5bf, transparent);
    }

    .cashier-limited-selected .action-button.robuxCoin::after {
        font-size: 5px;
        background: linear-gradient(90deg, #56511c, #626020);
    }

    .cashier-limited-selected .action-button.robuxCoin::before {
        font-size: 5px;
        background: linear-gradient(0deg, #f0f555, transparent);
    }

    @media screen and (max-width: 1300px) {
        .cashier-limited-selected .selected-container {
            top: 21px;
        }
    }

    @media only screen and (max-width: 1100px) {
        .cashier-limited-selected {
            position: fixed;
            top: 91px;
            bottom: 0;
            right: 0;
            transform: translateX(100%);
            transition: transform 0.3s ease;
            z-index: 10;
        }

        .cashier-limited-selected .container-content {
            height: calc(100vh - 91px - 21px - 4px - 4px);
            max-height: unset;
        }

        .cashier-limited-selected button.button-open {
            display: block;
        }

        .cashier-limited-selected.selected-open button.button-open {
            display: none;
        }

        .cashier-limited-selected button.button-close {
            display: block;
        }

        .cashier-limited-selected .selected-overlay {
            display: block;
        }

        .cashier-limited-selected:not(.selected-open) .selected-overlay {
            transform: translateX(-315px);
        }

        .cashier-limited-selected.selected-open .selected-overlay {
            pointer-events: auto;
            opacity: 1;
        }

        .cashier-limited-selected.selected-open {
            transform: translateX(-10px);
        }
    }

    .cashier-limited-selected .action-button::before,
    .cashier-limited-selected .container-content::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-before);
    }

    .cashier-limited-selected .action-button::after,
    .cashier-limited-selected .content-title::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-after);
        z-index: -1;
    }
</style>
