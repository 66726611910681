<template>
    <div
        :class="[
            $style['upgrader-bets'],
            {
                [$style['mounted']]: mounted,
                [$style['expand']]: expand
            }
        ]"
    >
        <button v-on:click="expand = false" :class="$style['bets-overlay']"></button>
        <div v-on:click="expand = true" :class="$style['bets-option']">
            <button
                v-on:click="upgraderListMode('recent')"
                :class="{ [$style['active']]: mode === 'recent' }"
            >
                <p class="octagonal before after hoverable">Recent</p>
            </button>
            <button
                v-on:click="upgraderListMode('whale')"
                :class="{ [$style['active']]: mode === 'whale' }"
            >
                <p class="octagonal before after hoverable">Big Wins</p>
            </button>
        </div>
        <div v-on:click="expand = !expand" :class="$style['bets-list-wrapper']">
            <ol :class="$style['bets-list']">
                <li
                    v-for="(bet, index) in betsList"
                    :class="$style['list-item-wrapper']"
                    v-bind:key="mode + index"
                >
                    <div :class="$style['list-item']" class="octagonal">
                        <div
                            :class="[
                                $style['item-content'],
                                { [$style['lose']]: bet.payout === 0 }
                            ]"
                            class="octagonal before"
                        >
                            <button
                                v-on:click="upgraderBetUserButton(bet.user)"
                                :class="$style['content-user']"
                            >
                                <AvatarImage
                                    v-bind:image="bet.user ? bet.user?.avatar : 'anonymous'"
                                    v-bind:rank="bet.user?.rank"
                                    v-bind:level="bet.user?.level"
                                />
                                <p>{{ bet.user?.username || 'Anonymous' }}</p>
                                <hr />
                            </button>
                            <div :class="$style['content-game']">
                                <div :class="$style['game-side']">
                                    <div
                                        :class="[
                                            $style['side-item'],
                                            $style[
                                                'item-' +
                                                    (bet.coinType === 'robuxCoin'
                                                        ? 'yellow'
                                                        : 'green')
                                            ]
                                        ]"
                                    >
                                        <img
                                            v-if="bet.coinType === 'cryptoCoin'"
                                            src="@/assets/img/icons/cryptoCoin.svg"
                                            width="25"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/img/icons/robuxCoinSingle.svg"
                                            width="30"
                                        />
                                        <div :class="$style['item-spiral']">
                                            <div></div>
                                        </div>
                                    </div>
                                    <p>
                                        {{
                                            parseFloat(
                                                (bet.amount / 1000).toFixed(2)
                                            ).toLocaleString()
                                        }}
                                    </p>
                                </div>
                                <div :class="$style['game-multiplier']">
                                    <svg
                                        width="20"
                                        height="40"
                                        viewBox="0 0 20 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.711 18.1157H18.8632L1.54762 37.0012L7.75089 22.0362L8.03752 21.3447H7.289H1.13675L18.4524 2.45814L12.2491 17.4242L11.9625 18.1157H12.711Z"
                                            fill="url(#paint0_linear_137_1576)"
                                            stroke="#97FFF7"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_137_1576"
                                                x1="20"
                                                y1="0.0302734"
                                                x2="-4.52194"
                                                y2="3.08096"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stop-color="#00FFC2" />
                                                <stop offset="1" stop-color="#00AA6D" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p>{{ bet.multiplier / 100 }}x</p>
                                </div>
                                <div :class="$style['game-side']">
                                    <div
                                        :class="[
                                            $style['side-item'],
                                            $style['side-item-' + bet.items?.length],
                                            $style[
                                                'item-' + upgraderGetItemsColor(bet.payout / 1000)
                                            ]
                                        ]"
                                    >
                                        <img
                                            v-for="(item, i) in bet.items"
                                            v-bind:src="item.image"
                                            width="55"
                                            v-bind:key="i"
                                        />
                                        <div :class="$style['item-spiral']">
                                            <div></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span
                                            :class="
                                                bet.coinType === 'cryptoCoin'
                                                    ? 'fade-lime'
                                                    : 'fade-gold'
                                            "
                                            >{{
                                                parseFloat(
                                                    (bet.payout / 1000).toFixed(2)
                                                ).toLocaleString()
                                            }}</span
                                        >
                                    </p>
                                </div>
                            </div>

                            <svg :class="$style['content-dots']" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <pattern
                                        id="polka-dots"
                                        x="0"
                                        y="0"
                                        width="7"
                                        height="7"
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <circle cx="2" cy="2" r="2" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" fill="url(#polka-dots)" />
                            </svg>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { mapGetters, mapActions } from 'vuex';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';

    export default {
        name: 'UpgraderBets',
        components: {
            AvatarImage
        },
        data() {
            return {
                mode: 'recent',
                mounted: false,
                betsList: [],
                expand: false
            };
        },
        methods: {
            ...mapActions(['generalSetUserInfoData', 'modalsSetShow']),
            upgraderListMode(mode) {
                this.mode = mode;
                this.betsList = this.upgraderBets[this.mode];
            },
            upgraderBetUserButton(user) {
                if (user === null) {
                    return;
                }

                this.generalSetUserInfoData(user);
                this.modalsSetShow('ChatUser');
            },
            upgraderGetItemsColor(amount) {
                return getLimitedItemColorFromAmount(amount);
            }
        },
        computed: {
            ...mapGetters(['upgraderBets'])
        },
        created() {
            this.betsList = this.upgraderBets[this.mode];
        },
        watch: {
            upgraderBets: {
                deep: true,
                handler() {
                    this.mounted = true;
                    this.betsList = this.upgraderBets[this.mode];
                    setTimeout(() => {
                        this.mounted = false;
                    }, 300);
                }
            }
        }
    };
</script>

<style module>
    .upgrader-bets {
        display: flex;
        height: 100%;
        flex-direction: column;
        width: 100%;
        transition: width 0.3s ease;
    }

    .upgrader-bets .bets-overlay {
        display: none;
        width: 120vw;
        height: 120vh;
        position: fixed;
        top: -91px;
        right: -10px;
        transform: translateX(0px);
        background-color: rgba(2, 20, 35, 0.425);
        transition:
            opacity 0.3s ease,
            transform 0.3s ease;
        pointer-events: none;
        opacity: 0;
        user-select: none;
        z-index: -1;
    }

    .upgrader-bets .bets-option {
        margin-bottom: 12px;
        display: flex;
        width: 100%;
        gap: 2px;
    }

    .upgrader-bets .bets-option button {
        position: relative;
        overflow: hidden;
        height: 34px;
        filter: drop-shadow(0px 2px 0px #092033);
        flex: 1;
    }

    .upgrader-bets .bets-option button p {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 10px);
        height: 100%;
        min-width: 120px;
        padding-right: 10px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #446b8c;
        font-weight: 600;
    }

    .upgrader-bets .bets-option button p::before,
    .upgrader-bets .bets-option button p::after {
        background: #132c41;
        font-size: 3px;
    }

    .upgrader-bets .bets-option button:last-child p {
        right: 0;
        left: unset;
        padding-right: unset;
        padding-left: 10px;
    }

    .upgrader-bets .bets-option button.active {
        filter: unset;
    }

    .upgrader-bets .bets-option button.active:first-child {
        border-right: 1px solid #255075;
    }

    .upgrader-bets .bets-option button.active:last-child {
        border-left: 1px solid #255075;
    }

    .upgrader-bets .bets-option button.active p {
        color: white;
    }

    .upgrader-bets .bets-option button.active p::after {
        background: #17354f;
    }

    .upgrader-bets .bets-option button.active p::before {
        background: #255075;
    }

    .upgrader-bets .bets-list-wrapper {
        position: relative;
        flex: 1;
        width: 100%;
    }

    .upgrader-bets .bets-list {
        mask-image: linear-gradient(0deg, transparent, black 50px);
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .upgrader-bets .list-item-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
    }

    .upgrader-bets.mounted .list-item-wrapper:first-child {
        animation: upgrader-bets-list-item 0.3s ease forwards;
    }

    @keyframes upgrader-bets-list-item {
        0% {
            max-height: 0px;
        }
        100% {
            max-height: 160px;
        }
    }

    .upgrader-bets .list-item {
        margin-bottom: 5px;
        width: 100%;
        padding: 4px;
        background: #0d243a;
        clip-path: var(--octagonal-main);
        font-size: 10px;
    }

    .upgrader-bets .item-content {
        transition: height 0.3s ease;
        height: 148px;
        width: 100%;
        padding: 10px;
        font-size: 8px;
        display: flex;
        flex-direction: column;
        clip-path: var(--octagonal-main);
        background: linear-gradient(45deg, #0b2034, #0e2437 25%, #0a1e32 75%);
    }

    .upgrader-bets .item-content > * {
        min-width: 200px;
    }

    .upgrader-bets .item-content.lose > * {
        opacity: 0.6;
    }

    .upgrader-bets .item-content::before {
        font-size: 8px;
        background: #1a2f51;
    }

    .upgrader-bets .content-dots {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: #0e2437;
        z-index: -1;
    }

    .upgrader-bets .content-user {
        display: flex;
        gap: 14px;
        align-items: center;
    }

    .upgrader-bets .content-user img {
        min-width: 31px;
        width: 31px;
        border-width: 2px;
    }

    .upgrader-bets .content-user p {
        font-size: 13px;
        font-weight: 600;
        color: white;
    }

    .upgrader-bets .content-user hr {
        height: 1px;
        width: 100%;
        margin: unset;
        border-color: #1c384e;
        mask-image: linear-gradient(90deg, black, transparent);
    }

    .upgrader-bets .content-game {
        padding-top: 4px;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-evenly;
        font-size: 12px;
        font-weight: 600;
        color: white;
    }

    .upgrader-bets .game-multiplier {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .upgrader-bets .game-side p {
        margin-top: 10px;
    }

    .upgrader-bets .game-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
    }

    .upgrader-bets .side-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 60px;
        width: 60px;
    }

    .upgrader-bets .side-item-2 img:first-child {
        position: relative;
        z-index: 1;
        margin-right: 20%;
        width: 60%;
    }

    .upgrader-bets .side-item-2 img:not(:first-child) {
        margin-top: -20%;
        margin-left: 20%;
        width: 60%;
    }

    .upgrader-bets .side-item-3 img:first-child {
        position: relative;
        z-index: 1;
        width: 80%;
    }

    .upgrader-bets .side-item-3 img:not(:first-child) {
        margin-top: -30%;
        width: 30%;
    }

    .upgrader-bets .side-item-4 img:first-child {
        position: relative;
        z-index: 1;
        width: 90%;
    }

    .upgrader-bets .side-item-4 img:not(:first-child) {
        margin-top: -20%;
        width: 20%;
    }

    .upgrader-bets .item-spiral {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }
    .upgrader-bets .item-spiral div {
        z-index: 1;
    }

    .upgrader-bets .item-spiral div::after,
    .upgrader-bets .item-spiral div::before,
    .upgrader-bets .item-spiral::before,
    .upgrader-bets .item-spiral::after {
        transform: rotate(0deg) translateY(5%);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        border: 1px solid currentColor;
        box-sizing: border-box;
        content: '';
        transform-origin: 50% 50%;
        animation: spiral 12s linear infinite;
        left: 0%;
        top: 0%;
    }

    .upgrader-bets .game-side:first-child .item-spiral::after {
        color: #19466a;
    }

    .upgrader-bets .item-spiral div::before {
        animation: spiral 14s linear infinite 2s;
        color: #19466a;
        opacity: 0.8;
    }

    .upgrader-bets .item-spiral div::after {
        animation: spiral 16s linear infinite 3s;
        color: #19466a;
        opacity: 0.7;
    }
    .upgrader-bets .item-spiral::before {
        animation: spiral 18s linear infinite 4s;
        color: #19466a;
        opacity: 0.6;
    }

    .upgrader-bets .game-side:not(:first-child) .item-spiral::after {
        animation: spiral 12s linear reverse infinite;
    }
    .upgrader-bets .game-side:not(:first-child) .item-spiral div::before {
        animation: spiral 14s linear reverse infinite;
    }
    .upgrader-bets .game-side:not(:first-child) .item-spiral div::after {
        animation: spiral 16s linear reverse infinite;
    }
    .upgrader-bets .game-side:not(:first-child) .item-spiral::before {
        animation: spiral 18s linear reverse infinite;
    }

    @keyframes spiral {
        0% {
            transform: rotate(0deg) scale(1);
        }
        12.5% {
            transform: rotate(45deg) scale(0.995);
        }
        25% {
            transform: rotate(90deg) scale(0.99);
        }
        37.5% {
            transform: rotate(135deg) scale(0.985);
        }
        50% {
            transform: rotate(180deg) scale(0.98);
        }
        62.5% {
            transform: rotate(225deg) scale(0.985);
        }
        75% {
            transform: rotate(270deg) scale(0.99);
        }
        87.5% {
            transform: rotate(315deg) scale(0.995);
        }
        100% {
            transform: rotate(360deg) scale(1);
        }
    }

    .upgrader-bets .side-item {
        color: #666666;
    }
    .upgrader-bets .side-item.item-blue {
        color: #31a5e5;
    }
    .upgrader-bets .side-item.item-purple {
        color: #9564ff;
    }
    .upgrader-bets .side-item.item-green {
        color: #4bd01d;
    }
    .upgrader-bets .side-item.item-red {
        color: #df552a;
    }
    .upgrader-bets .side-item.item-yellow {
        color: #e39f1b;
    }

    .upgrader-bets .side-item img {
        filter: drop-shadow(0 0 25px color-mix(in hsl, currentColor 50%, transparent));
    }

    @media screen and (max-width: 700px) {
        .upgrader-bets {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .upgrader-bets.expand {
            width: 300px;
        }

        .upgrader-bets .bets-overlay {
            display: block;
        }

        .upgrader-bets.expand .bets-overlay {
            pointer-events: auto;
            opacity: 1;
        }

        .upgrader-bets .item-content {
            height: 51px;
        }

        .upgrader-bets .content-user {
            pointer-events: none;
        }

        .upgrader-bets.expand .item-content {
            height: 148px;
        }

        .upgrader-bets.expand .content-user {
            pointer-events: all;
        }
    }
</style>
