<template>
    <div v-bind:class="[$style['chat-emojis'], { [$style.active]: dropdownOpen === true }]">
        <button
            v-on:click="chatToggleDropdown"
            :class="$style['emojis-button']"
            class="octagonal after before hoverable"
        >
            <img
                width="20"
                v-bind:src="'/emojis/' + specialEmojis[0].name + '.' + specialEmojis[0].extension"
            />
        </button>
        <div :class="$style['emojis-menu']">
            <div :class="$style['menu-container']">
                <div :class="$style['menu-content']" class="octagonal before">
                    <p :class="$style['content-title']">
                        <span class="fade-green">Special Emotes</span>
                    </p>
                    <div :class="$style['content-list']">
                        <img
                            v-for="(emoji, index) in specialEmojis"
                            v-on:click="chatEmojiButton(emoji)"
                            v-bind:src="'/emojis/' + emoji.name + '.' + emoji.extension"
                            :class="{ [$style.wide]: emoji.name.includes('wide') }"
                        />
                    </div>
                    <p :class="$style['content-title']">Standard Emotes</p>
                    <div :class="$style['content-list']">
                        <button
                            v-for="(emoji, index) in standardEmojis"
                            v-on:click="chatEmojiButton(emoji)"
                        >
                            {{ emoji }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IconEmojisGradient from '@/components/icons/IconEmojisGradient';
    import { emojis } from '@/utils/emojis';

    export default {
        name: 'ChatEmojisDropdown',
        components: {
            IconEmojisGradient
        },
        data() {
            return {
                dropdownOpen: false,
                standardEmojis: [
                    '😀',
                    '😃',
                    '😄',
                    '😁',
                    '😆',
                    '😅',
                    '🤣',
                    '😂',
                    '🙂',
                    '🙃',
                    '😉',
                    '😊',
                    '😇',
                    '🥰',
                    '😍',
                    '🤩',
                    '😘',
                    '😗',
                    '😚',
                    '😙',
                    '😋',
                    '😛',
                    '😜',
                    '🤪',
                    '😝',
                    '🤑',
                    '🤗',
                    '🤭',
                    '🤫',
                    '🤔',
                    '🤐',
                    '🤨',
                    '😐',
                    '😑',
                    '😶',
                    '😏',
                    '😒',
                    '🙄',
                    '😬',
                    '🤥',
                    '😌',
                    '😔',
                    '😪',
                    '🤤',
                    '😴',
                    '😷',
                    '🤒',
                    '🤕',
                    '🤢',
                    '🤮',
                    '🤧',
                    '🥵',
                    '🥶',
                    '🥴',
                    '😵',
                    '🤯',
                    '🤠',
                    '🥳',
                    '😎',
                    '🤓',
                    '🧐',
                    '😕',
                    '😟',
                    '🙁',
                    '😮',
                    '😯',
                    '😲',
                    '😳',
                    '🥺',
                    '😦',
                    '😧',
                    '😨',
                    '😰',
                    '😥',
                    '😢',
                    '😭',
                    '😱',
                    '😖',
                    '😣',
                    '😞',
                    '😓',
                    '😩',
                    '😫',
                    '🥱',
                    '😤',
                    '😡',
                    '😠',
                    '🤬',
                    '😈',
                    '👿',
                    '💀'
                ],
                specialEmojis: emojis
            };
        },
        methods: {
            chatToggleDropdown() {
                this.dropdownOpen = !this.dropdownOpen;
            },
            chatEmojiButton(emoji) {
                if (typeof emoji !== 'string') {
                    this.$parent.chatAddEmoji(`:${emoji.name}: `);
                } else {
                    this.$parent.chatAddEmoji(emoji);
                }
            }
        },
        created() {
            let self = this;
            document.addEventListener('click', function (event) {
                if (!self.$el.contains(event.target) && self.dropdownOpen == true) {
                    self.chatToggleDropdown();
                }
            });
        }
    };
</script>

<style module>
    .chat.emojis {
        position: relative;
    }

    .chat-emojis .emojis-button {
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3px;
        color: #ffb31a;
    }

    .chat-emojis .emojis-button::after {
        background: #11273d;
    }

    .chat-emojis .emojis-button::before {
        background: #2f4d68;
    }

    .chat-emojis .emojis-menu {
        position: absolute;
        bottom: 100%;
        max-height: 0vh;
        font-size: 14px;
        clip-path: var(--octagonal-main);
        transition: max-height 0.3s ease;
        right: 0;
        width: 297px;
        overflow: auto;
    }

    .chat-emojis.active .emojis-menu {
        max-height: 20vh;
    }

    .chat-emojis .menu-container {
        width: 100%;
        height: 100%;
    }

    .chat-emojis .menu-content {
        width: 100%;
        font-size: 14px;
        background: #0e293e;
        clip-path: var(--octagonal-main);
        padding: 14px;
    }

    .chat-emojis .menu-content::before {
        background: #24445e;
    }

    .chat-emojis .content-title {
        color: #59758d;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .chat-emojis .content-title:not(:first-child) {
        margin-top: 14px;
    }

    .chat-emojis .content-list {
        display: grid;
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .chat-emojis .content-list > * {
        cursor: pointer;
        min-width: 1.25em;
        max-width: 100%;
        object-fit: fill;
        height: 1.25em;
        font-size: 20px;
    }

    .chat-emojis .content-list > *.wide {
        grid-column: span 2 / span 2;
    }
    .chat-emojis .content-list > *:not(img) {
        margin-top: -0.05em;
        margin-left: -0.05em;
    }

    .chat-emojis .content-list > *:hover,
    .chat-emojis .content-list > *:focus {
        filter: brightness(1.2);
    }

    .chat-emojis .content-list > *:active {
        filter: brightness(0.8);
    }

    @media screen and (max-width: 1000px) {
        .chat-emojis .content-list {
            grid-template-columns: repeat(8, minmax(0, 1fr));
        }
        .chat-emojis .content-list > * {
            font-size: 25px;
        }
    }

    @media screen and (max-width: 700px) {
        .chat-emojis.active .emojis-menu {
            max-height: 30vh;
        }

        .chat-emojis .content-list {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }
        .chat-emojis .content-list > * {
            font-size: 32.5px;
        }
    }

    aside .chat-emojis {
        position: absolute;
        right: calc(30px + 5px + 14px);
        top: -1px;
    }

    aside .chat-emojis .emojis-menu {
        right: calc(-30px - 5px - 14px);
        bottom: calc(100% + 14px + 14px);
    }
</style>
