<template>
    <div :class="$style.limiteds">
        <div :class="$style['limiteds-items']">
            <div :class="$style['items-header']">
                <button
                    v-on:click="cashierGetBack"
                    :class="$style['header-back']"
                    class="octagonal hoverable"
                >
                    <svg
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.21864 3.60238C9.16544 3.59389 9.11161 3.58997 9.05775 3.59066H2.88651L3.08649 3.49916C3.28196 3.40815 3.45979 3.28428 3.61202 3.13316L5.18387 1.58689C5.52258 1.26881 5.5795 0.757122 5.31874 0.374506C5.01525 -0.0332154 4.43324 -0.121742 4.01874 0.176807C3.98526 0.200941 3.95343 0.227247 3.92352 0.255555L0.272801 3.84686C-0.0906526 4.204 -0.0909723 4.78334 0.272075 5.14088L0.272801 5.1416L3.92352 8.73291C4.28726 9.08973 4.87619 9.08893 5.23895 8.7311C5.26749 8.70294 5.29415 8.67297 5.31874 8.64141C5.5795 8.25879 5.52258 7.7471 5.18387 7.42902L3.61667 5.87818C3.4802 5.74379 3.3233 5.6311 3.1516 5.5442L2.87255 5.42068H9.00656C9.48172 5.43803 9.8986 5.11152 9.98787 4.65207C10.0701 4.15325 9.72571 3.68331 9.21864 3.60238Z"
                            fill="#406992"
                        />
                    </svg>
                </button>
                <button
                    v-if="cashierGetRouteName !== 'LimitedsDeposit'"
                    :class="$style['header-adurite']"
                    disabled
                >
                    <img src="@/assets/img/cashier/adurite.png" alt="Adurite" />
                </button>
                <button :class="$style['header-title']" class="octagonal hoverable">
                    <span>
                        {{ cashierGetRouteName === 'LimitedsDeposit' ? 'Stock / P2P' : 'Limiteds' }}
                    </span>
                </button>
                <CashierFilterAmount />
                <CashierFilterSearch />
                <CashierFilterSort />
            </div>
            <div
                v-on:scroll="cashierContentScroll"
                :class="$style['items-content']"
                ref="itemsContent"
            >
                <transition name="slide-fade" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>

        <CashierLimitedSelected />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import CashierFilterSearch from '@/components/cashier/CashierFilterSearch';
    import CashierFilterAmount from '@/components/cashier/CashierFilterAmount';
    import CashierFilterSort from '@/components/cashier/CashierFilterSort';
    import CashierLimitedSelected from '@/components/cashier/CashierLimitedSelected';

    export default {
        name: 'Limiteds',
        metaInfo: {
            title: 'Limiteds - Rollbet.gg'
        },
        components: {
            CashierFilterSearch,
            CashierFilterAmount,
            CashierFilterSort,
            CashierLimitedSelected
        },
        data() {
            return {
                cashierFilterMin: '',
                cashierFilterMax: ''
            };
        },
        methods: {
            ...mapActions([
                'modalsSetShow',
                'cashierGetLimitedDataSocket',
                'cashierSetLimitedDataPage'
            ]),
            cashierContentScroll() {
                const container = this.$refs.itemsContent;

                if (
                    container.scrollTop + container.clientHeight >= container.scrollHeight &&
                    this.cashierLimitedData[
                        this.cashierGetRouteName.replace('Limiteds', '').toLowerCase()
                    ].length >
                        this.cashierLimitedData.page * 60
                ) {
                    this.cashierSetLimitedDataPage(this.cashierLimitedData.page + 1);
                }
            },
            cashierGetBack() {
                this.modalsSetShow('Cashier');
            }
        },
        computed: {
            ...mapGetters(['cashierLimitedData']),
            cashierGetRouteName() {
                return this.$route.name;
            }
        },
        created() {
            if (
                this.cashierLimitedData.loading === false &&
                new Date().getTime() - new Date(this.cashierLimitedData.loadedAt).getTime() >=
                    1000 * 60 * 2
            ) {
                const data = {};
                this.cashierGetLimitedDataSocket(data);
            }
        }
    };
</script>

<style module>
    .limiteds {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 45px 30px;
    }

    .limiteds .limiteds-items {
        width: calc(100% - 280px);
        padding-right: 35px;
        max-width: 1255px;
    }

    .limiteds .items-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 7px;
        padding-bottom: 20px;
        border-bottom: 1px solid #183157;
        margin-bottom: 14px;
    }

    .limiteds .header-title {
        margin-right: auto;
    }

    .limiteds .header-title span {
        font-size: 15px;
        font-weight: 500;
        color: white;
    }

    .limiteds .header-adurite img {
        width: 75px;
        height: 21px;
        opacity: 0.5;
        mix-blend-mode: luminosity;
        margin-top: -6px;
    }

    .limiteds .header-back {
        margin-right: 3px;
    }

    .limiteds .header-back,
    .limiteds .header-adurite,
    .limiteds .header-title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 5px;
        clip-path: var(--octagonal-main);
        background: #0a2036;
        height: 44px;
        padding: 7px 18px;
    }

    .limiteds .header-back::before,
    .limiteds .header-title::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        font-size: 5px;
        background: #193650;
        clip-path: var(--octagonal-before);
    }

    @media only screen and (max-width: 1300px) {
        .limiteds {
            padding: 21px 10px;
        }
    }

    @media only screen and (max-width: 1100px) {
        .limiteds .limiteds-items {
            width: 100%;
            padding-right: 0;
        }
    }
</style>
