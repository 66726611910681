var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['roll-bets-element'],
        _vm.high ? _vm.$style[_vm.bet.coinType === 'cryptoCoin' ? 'crypto-high' : 'robux-high'] : ''
    ]},[_c('div',{class:_vm.$style['element-avatar']},[_c('AvatarImage',{attrs:{"image":_vm.bet.user.avatar,"rank":_vm.bet.user.rank,"level":_vm.bet.user.level}})],1),_c('div',{class:_vm.$style['element-info']},[_c('p',{class:_vm.$style['info-username'],domProps:{"innerHTML":_vm._s(_vm.bet.user.username)}}),_c('span',{class:[
                _vm.$style['info-multiplier'],
                _vm.high ? (_vm.bet.coinType === 'cryptoCoin' ? 'fade-green' : 'fade-gold') : ''
            ]},[_vm._v(_vm._s(parseFloat(_vm.bet.multiplier / 100) .toFixed(2) .replace('.00', '') .replace(/(?<=[.]\d)[0]/, ''))+"x")]),_c('div',{class:_vm.$style['info-amount']},[(_vm.bet.coinType === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"width":"12","alt":"icon"}}):(_vm.bet.coinType === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"width":"14","alt":"icon"}}):_vm._e(),_c('div',{class:_vm.$style['amount-value']},[_c('span',[_vm._v(_vm._s(Math.round(_vm.bet.amount / 1000)))]),_vm._v(_vm._s((_vm.bet.amount / 1000) .toFixed(2) .slice(-3) .replace('.00', '') .replace(/(?<=[.]\d)[0]/, ''))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }