var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['modal-blackjack-rules']},[_c('div',{class:_vm.$style['rules-content']},[_vm._m(0),_c('br'),_c('br'),_c('h2',[_vm._v("1. Blackjack")]),_vm._m(1),_c('p',[_vm._v("Achieving a total of 21 pays 1.5 times your original bet.")]),_c('br'),_c('br'),_c('h2',[_vm._v("2. 21 + 3 Payouts")]),_vm._m(2),_c('p',[_vm._v("A Flush pays 5 times your bet when you have three cards of the same suit.")]),_vm._m(3),_c('p',[_vm._v(" A Straight pays 10 times your bet for three consecutive cards in any suit, such as 2, 3, 4; Aces count as high or low. ")]),_vm._m(4),_c('p',[_vm._v(" Three of a Kind rewards you with 30 times your bet when you have three cards of the same rank or face value. ")]),_vm._m(5),_c('p',[_vm._v(" A Straight Flush pays 40 times your bet for three consecutive cards all in the same suit. ")]),_vm._m(6),_c('p',[_vm._v(" A Suited Three of a Kind grants you 100 times your bet when all three cards are of the same rank and suit. ")]),_c('br'),_c('br'),_c('h2',[_vm._v("3. Perfect Pairs")]),_vm._m(7),_c('p',[_vm._v(" A Perfect Pair pays 25 times your side bet when you have two identical cards in both rank and suit. ")]),_vm._m(8),_c('p',[_vm._v(" A Colored Pair rewards you with 10 times your side bet for two cards of the same rank and color but different suits. ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('span',{staticClass:"fade-lime"},[_vm._v("Blackjack Game Rules")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("1.1 "),_c('span',{staticClass:"fade-lime"},[_vm._v("1.5x - Blackjack")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("2.1 "),_c('span',{staticClass:"fade-lime"},[_vm._v("5x - Flush")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("2.2 "),_c('span',{staticClass:"fade-lime"},[_vm._v("10x - Straight")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("2.3 "),_c('span',{staticClass:"fade-lime"},[_vm._v("30x - Three of a Kind")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("2.4 "),_c('span',{staticClass:"fade-lime"},[_vm._v("40x - Straight Flush")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("2.5 "),_c('span',{staticClass:"fade-lime"},[_vm._v("100x - Suited Three of a Kind")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("3.1 "),_c('span',{staticClass:"fade-lime"},[_vm._v("25x - Perfect Pair")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("3.2 "),_c('span',{staticClass:"fade-lime"},[_vm._v("10x - Colored Pair")])])
}]

export { render, staticRenderFns }