var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['modal-login']},[_c('div',{class:_vm.$style['side-content']},[_c('div',{class:_vm.$style['roll-banner']},[_c('img',{class:_vm.$style['banner-logo'],attrs:{"src":require("@/assets/img/logo.png"),"alt":"ROLLBET"}}),_c('img',{class:_vm.$style['banner-robot'],attrs:{"src":require("@/assets/img/char-robot.png"),"alt":""}})]),_c('div',{class:_vm.$style['divider']}),_c('p',{class:_vm.$style['tos']},[_vm._v(" By accessing this site, I confirm that I am at least 18 years old and have read and agree with the  "),_c('span',{class:_vm.$style['cyan'],on:{"click":function($event){return _vm.modalsSetShow('Terms')}}},[_vm._v("Terms of Service")]),_vm._v(". ")])]),_c('div',{class:_vm.$style['login-content']},[(
                ['login', 'register', 'roblox login', 'roblox cookie'].includes(_vm.modalTab) ===
                true
            )?_c('div',{class:_vm.$style['content-title']},[_c('div',{class:_vm.$style['button-group']},[_c('button',{staticClass:"octagonal hoverable",class:[_vm.$style['button-item'], { [_vm.$style.active]: _vm.modalTab === 'login' }],on:{"click":function($event){return _vm.modalSetTab('login')}}},[_c('span',{class:{ 'fade-green': _vm.modalTab === 'login' }},[_vm._v("Sign In")])]),_c('button',{staticClass:"octagonal hoverable",class:[
                        _vm.$style['button-item'],
                        { [_vm.$style.active]: _vm.modalTab === 'register' }
                    ],on:{"click":function($event){return _vm.modalSetTab('register')}}},[_c('span',{class:{ 'fade-green': _vm.modalTab === 'register' }},[_vm._v("Register")])])]),_c('div',{class:_vm.$style['content-title']},[_c('p',[_vm._v("or")]),_c('div',{class:_vm.$style['button-group']},[_c('button',{staticClass:"octagonal hoverable",class:[
                            _vm.$style['button-item'],
                            { [_vm.$style.active]: _vm.modalTab === 'roblox login' }
                        ],on:{"click":function($event){return _vm.modalSetTab('roblox login')}}},[_c('svg',{attrs:{"width":"17","height":"18","viewBox":"0 0 17 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M3.5 0.5L0 14L13.5 17.5L17 4L3.5 0.5ZM10.2005 8.09743L7.56738 7.40509L6.87332 10.0448L9.50644 10.7371L10.2005 8.09743Z","fill":"currentColor"}})]),_c('span',{class:{ 'fade-green': _vm.modalTab === 'roblox login' }},[_vm._v(" Sign in with Roblox ")])])])])]):_c('div',{class:_vm.$style['content-title']},[_c('div',{class:_vm.$style['button-group']},[_c('button',{staticClass:"octagonal hoverable",class:_vm.$style['button-item'],on:{"click":function($event){return _vm.modalSetTab('login')}}},[_c('span',[_vm._v("Go back")])]),_c('button',{staticClass:"octagonal hoverable",class:[_vm.$style['button-item'], _vm.$style['active']]},[_c('span',{staticClass:"fade-green"},[_vm._v("Reset password")])])])]),_c('div',{class:_vm.$style['divider']}),(
                ['login', 'register', 'roblox login', 'roblox cookie'].includes(_vm.modalTab) ===
                true
            )?_c('div',{class:_vm.$style['content-auth']},[(['roblox login', 'roblox cookie'].includes(_vm.modalTab) === true)?_c('LoginRoblox',{attrs:{"tab":_vm.modalTab}}):_c('LoginCredentials',{attrs:{"tab":_vm.modalTab}})],1):_c('LoginForgot')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }