<template>
    <button
        v-on:click="modalsSetShow('Login')"
        class="octagonal before after hoverable"
        :class="$style['auth-button']"
    >
        <p>Sign in</p>
    </button>
</template>

<script>
    import { mapActions } from 'vuex';
    import IconRoblox from '@/components/icons/IconRoblox';

    export default {
        name: 'AuthButton',
        components: {
            IconRoblox
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    };
</script>

<style module>
    button.auth-button {
        height: 42px;
        filter: drop-shadow(0px 2px 0px #2a6f66);
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        font-weight: 800;
        color: #ffffff;
        font-size: 16px;
    }

    button.auth-button::before,
    button.auth-button::after {
        background: #21b894;
        font-size: 5px;
    }
</style>
