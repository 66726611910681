var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['duels-game-winner']},[_c('span',{staticClass:"fade-green"},[_vm._v("DUEL WINNER:")]),(_vm.duelsGame.state !== 'completed')?_c('div',{class:_vm.$style['winner-placeholder']}):_c('div',{class:_vm.$style['winner-content']},[_c('div',{class:_vm.$style['content-inner']},[_c('div',{class:[
                    _vm.$style['inner-user'],
                    _vm.$style[
                        'user-' +
                            (_vm.duelsGame.winner.bot === true
                                ? 'bot'
                                : _vm.duelsGetRank(_vm.duelsGame.winner.user))
                    ],
                    _vm.$style[
                        'user-' +
                            (_vm.duelsGame.winner.bot === true
                                ? ''
                                : _vm.duelsGetLevelColor(_vm.duelsGame.winner.user))
                    ]
                ]},[_c('AvatarImage',{attrs:{"image":_vm.duelsGame.winner.bot ? _vm.duelsGetBot.avatar : _vm.duelsGame.winner.user.avatar,"rank":_vm.duelsGame.winner.bot ? null : _vm.duelsGame.winner.user.rank,"level":_vm.duelsGame.winner.bot ? null : _vm.duelsGame.winner.user.level}}),_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.duelsGame.winner.bot === true
                            ? _vm.duelsGetBot.username
                            : _vm.duelsGame.winner.user.username
                    )}})],1),_c('div',{class:_vm.$style['inner-roll']},[_c('div',{class:_vm.$style['roll-inner']},[_c('span',{staticClass:"fade-green"},[_vm._v(" "+_vm._s(parseFloat(_vm.duelsGame.winner.roll / 100).toFixed(2))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }