var render = function render(){var _vm=this,_c=_vm._self._c;return _c('img',{class:[
        _vm.$style['avatar-image'],
        _vm.$style[_vm.avatarGetLevelColor],
        _vm.$style[_vm.avatarRank],
        { [_vm.$style.vip]: _vm.avatarGetLevel > 90 },
        { [_vm.$style.tilt]: _vm.tiltBorder },
        { [_vm.$style.anonymous]: _vm.image === 'anonymous' }
    ],attrs:{"src":_vm.avatarImage},on:{"error":function($event){return _vm.avatarImageError()}}})
}
var staticRenderFns = []

export { render, staticRenderFns }