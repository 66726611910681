<template>
    <div :class="$style['upgrader-items']">
        <div :class="$style['items-header']">
            <UpgraderFilterSearch />
            <UpgraderFilterAmount />
            <UpgraderFilterSort />
        </div>
        <div :class="$style['items-content']">
            <UpgraderItemElement
                v-for="item in upgraderItemList.data"
                v-bind:key="item._id"
                v-bind:item="item"
            />
        </div>
        <hr />
        <div :class="$style['items-pagination']">
            <button
                v-on:click="upgraderSetPage(upgraderItemList.page - 1)"
                :class="$style['button-prev']"
                class="octagonal before after hoverable"
                v-bind:disabled="upgraderItemList.page <= 1"
            >
                <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.0374 4.52545C9.97962 4.51623 9.92117 4.51198 9.86269 4.51272L3.16249 4.51272L3.37961 4.41338C3.59184 4.31456 3.78491 4.18007 3.95018 4.016L5.65676 2.3372C6.02451 1.99185 6.0863 1.43631 5.80319 1.02089C5.47369 0.578226 4.84179 0.482111 4.39177 0.806251C4.35542 0.832453 4.32086 0.861014 4.28839 0.891748L0.324749 4.79088C-0.0698583 5.17863 -0.0702055 5.80763 0.32396 6.19582L0.324749 6.1966L4.28839 10.0957C4.68331 10.4831 5.32271 10.4823 5.71656 10.0938C5.74755 10.0632 5.77649 10.0307 5.80319 9.99639C6.0863 9.58097 6.02451 9.02543 5.65676 8.68008L3.95523 6.99631C3.80707 6.8504 3.63671 6.72805 3.4503 6.63371L3.14734 6.49959L9.80712 6.49959C10.323 6.51844 10.7756 6.16394 10.8725 5.66511C10.9618 5.12353 10.5879 4.61331 10.0374 4.52545Z"
                        fill="#406992"
                    />
                </svg>
            </button>
            <div :class="$style['pagination-info']">
                Page
                <div :class="$style['info-input']" class="octagonal before after hoverable">
                    <input
                        v-model="pagePrompt"
                        v-bind:style="{
                            width: pagePrompt?.toString().length * 0.75 + 'em'
                        }"
                        v-on:keydown.enter="upgraderSetPage(parseInt(pagePrompt), true)"
                    />
                </div>
                /
                {{
                    Math.ceil(upgraderItemList.count / 28) <= 0
                        ? '1'
                        : Math.ceil(upgraderItemList.count / 28)
                }}
            </div>
            <button
                v-on:click="upgraderSetPage(upgraderItemList.page + 1)"
                :class="$style['button-next']"
                class="octagonal before after hoverable"
                v-bind:disabled="upgraderItemList.page >= Math.ceil(upgraderItemList.count / 28)"
            >
                <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.876938 6.47455C0.93469 6.48377 0.993137 6.48803 1.05161 6.48728L7.75182 6.48728L7.5347 6.58662C7.32247 6.68544 7.12939 6.81993 6.96412 6.984L5.25755 8.6628C4.8898 9.00815 4.828 9.56369 5.11111 9.97911C5.44062 10.4218 6.07251 10.5179 6.52254 10.1937C6.55889 10.1675 6.59345 10.139 6.62592 10.1083L10.5896 6.20912C10.9842 5.82137 10.9845 5.19237 10.5903 4.80418L10.5896 4.8034L6.62592 0.904271C6.231 0.516862 5.59159 0.517731 5.19774 0.906227C5.16675 0.936806 5.13781 0.969341 5.11111 1.00361C4.828 1.41903 4.88979 1.97457 5.25755 2.31992L6.95907 4.00369C7.10724 4.1496 7.27759 4.27195 7.46401 4.36629L7.76697 4.50041L1.10719 4.50041C0.591302 4.48156 0.13869 4.83607 0.0417743 5.33489C-0.0475039 5.87647 0.3264 6.38669 0.876938 6.47455Z"
                        fill="#406992"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import UpgraderFilterSearch from '@/components/upgrader/UpgraderFilterSearch';
    import UpgraderFilterAmount from '@/components/upgrader/UpgraderFilterAmount';
    import UpgraderFilterSort from '@/components/upgrader/UpgraderFilterSort';
    import UpgraderItemElement from '@/components/upgrader/UpgraderItemElement';

    export default {
        name: 'UpgraderItems',
        components: {
            LoadingAnimation,
            UpgraderFilterSearch,
            UpgraderFilterAmount,
            UpgraderFilterSort,
            UpgraderItemElement
        },
        data() {
            return {
                upgraderGetItemTimeout: null,
                pagePrompt: 1
            };
        },
        methods: {
            ...mapActions([
                'upgraderGetItemListSocket',
                'upgraderSetItemListPage',
                'upgraderSetFilterAmount'
            ]),
            upgraderGetItemList(timeout = true, page, delay) {
                const data = {
                    page: page || this.upgraderItemList.page,
                    search: this.upgraderFilterSearch,
                    filter: {
                        min: this.upgraderFilterAmount.min * 1000 || 0,
                        max: this.upgraderFilterAmount.max * 1000 || 0
                    },
                    sort: this.upgraderFilterSort
                };

                if (timeout) {
                    clearTimeout(this.upgraderGetItemTimeout);
                    this.upgraderGetItemTimeout = setTimeout(() => {
                        this.upgraderGetItemListSocket(data);
                    }, delay || 100);
                } else {
                    this.upgraderGetItemListSocket(data);
                }
            },
            upgraderSetPage(page, kickback) {
                if (this.upgraderItemList.page === page) {
                    return;
                }
                if (isNaN(page) || page < 1 || page > Math.ceil(this.upgraderItemList.count / 28)) {
                    if (kickback === true) this.pagePrompt = this.upgraderItemList.page;
                    return;
                }

                this.pagePrompt = page;
                this.upgraderSetItemListPage(page);

                this.upgraderGetItemList(true, page);
            }
        },
        computed: {
            ...mapGetters([
                'socketUpgrader',
                'upgraderFilterSearch',
                'upgraderFilterAmount',
                'upgraderFilterSort',
                'upgraderItemList',
                'userBalanceData',
                'authUser'
            ])
        },
        created() {
            if (
                this.authUser.user?._id !== undefined &&
                this.authUser.user.balance[this.userBalanceData.type] < 135000 * 1000
            ) {
                this.upgraderSetFilterAmount({
                    max: Math.max(
                        100,
                        Math.round(this.authUser.user.balance[this.userBalanceData.type] / 1000) ||
                            0
                    )
                });
            }
            this.upgraderGetItemList(false);
        },
        watch: {
            upgraderFilterSearch: {
                deep: true,
                handler() {
                    this.pagePrompt = 1;
                    this.upgraderGetItemList(true, 1, 500);
                }
            },
            upgraderFilterAmount: {
                deep: true,
                handler() {
                    this.pagePrompt = 1;
                    this.upgraderGetItemList(true, 1, 500);
                }
            },
            upgraderFilterSort: {
                deep: true,
                handler() {
                    this.pagePrompt = 1;
                    this.upgraderGetItemList(true, 1);
                }
            }
        }
    };
</script>

<style module>
    .upgrader-items {
        border-color: #2972a929;
    }
    .items-header {
        align-items: center;
        margin-bottom: 20px;
        flex-wrap: wrap;
        display: flex;
        gap: 7px;
    }

    .items-content {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        font-size: 10px;
        gap: 7px;
    }

    .items-pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .items-pagination button {
        width: 38px;
        height: 38px;
        font-size: 3px;
        color: #406992;
    }

    .items-pagination button::before {
        background: #142f4a;
    }

    .items-pagination button::after {
        background: linear-gradient(0deg, #102b43, #102940);
    }

    .items-pagination .pagination-info {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #5e768e;
    }

    .items-pagination .pagination-info .info-input {
        margin-right: -4px;
        font-size: 16px;
    }

    .items-pagination .pagination-info .info-input::before,
    .items-pagination .pagination-info .info-input::after {
        background: #00111f;
        font-size: 2px;
    }

    .items-pagination .pagination-info .info-input input {
        appearance: none;
        background: none;
        padding: 2px 0px 2.5px 0px;
        width: 1.5em;
        min-width: 2em;
        text-align: center;
        max-width: 7.5em;
        color: white;
    }

    @media screen and (max-width: 1600px) {
        .items-content {
            grid-template-columns: repeat(6, minmax(0, 1fr));
            font-size: 10px;
        }
    }

    @media screen and (max-width: 1500px) {
        .items-content {
            grid-template-columns: repeat(7, minmax(0, 1fr));
            font-size: 10px;
        }
    }

    @media screen and (max-width: 1300px) {
        .items-content {
            grid-template-columns: repeat(6, minmax(0, 1fr));
            font-size: 10px;
        }
    }

    @media screen and (max-width: 1100px) {
        .items-content {
            grid-template-columns: repeat(5, minmax(0, 1fr));
            font-size: 10px;
        }
    }

    @media screen and (max-width: 1000px) {
        .items-content {
            grid-template-columns: repeat(5, minmax(0, 1fr));
            font-size: 8px;
        }
    }

    @media screen and (max-width: 600px) {
        .items-content {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            font-size: 8px;
        }
    }

    @media screen and (max-width: 600px) {
        .items-content {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            font-size: 8px;
        }
    }
</style>
