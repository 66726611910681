<template>
    <div
        :class="[
            $style['home-game-element'],
            { [$style.extended]: isExtended, [$style.locked]: gameName === '' }
        ]"
        class="octagonal after"
    >
        <div :class="$style['element-wrapper']">
            <router-link
                v-if="gameName !== ''"
                v-bind:to="'/' + gameName"
                :class="$style['element-content']"
                class="octagonal before hoverable"
            >
                <IconCrash v-if="gameName === 'crash'" />
                <IconRoll v-else-if="gameName === 'roll'" />
                <IconBlackjack v-else-if="gameName === 'blackjack'" />
                <IconUpgrader v-else-if="gameName === 'upgrader'" />
                <IconDuels v-else-if="gameName === 'duels'" />
                <IconMines v-else-if="gameName === 'mines'" />
                <IconTowers v-else-if="gameName === 'towers'" />
                <IconUnbox v-else-if="gameName === 'unbox'" />
                <IconBattles v-else-if="gameName === 'battles'" />
                <div :class="$style['content-img']">
                    <img
                        v-bind:src="
                            require(
                                '@/assets/img/games/static/' +
                                    gameMode +
                                    (isExtended ? '-extended' : '') +
                                    '.png'
                            )
                        "
                        v-bind:alt="gameName"
                    />
                </div>
            </router-link>
            <div v-else :class="$style['element-content']" class="octagonal before">
                <svg width="12" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.34753 4.41718V5.95268H1.23896C0.555615 5.95268 0 6.47553 0 7.11857V13.54C0 14.1831 0.555615 14.7059 1.23896 14.7059H10.761C11.4444 14.7059 12 14.1831 12 13.54V7.11557C12 6.47252 11.4444 5.94967 10.761 5.94967H10.7291V4.41418C10.7291 1.98022 8.6248 0 6.03832 0C3.45184 0.00300488 1.34753 1.98322 1.34753 4.41718ZM6.73443 10.6133C6.63864 10.6794 6.61309 10.7425 6.61309 10.8476C6.61628 11.3194 6.61628 11.7882 6.61628 12.2599C6.62586 12.4552 6.52049 12.6476 6.33528 12.7347C5.89782 12.945 5.46035 12.6506 5.46035 12.2599V12.2569C5.46035 11.7852 5.46035 11.3134 5.46354 10.8416C5.46354 10.7455 5.44119 10.6824 5.35178 10.6193C4.89835 10.3037 4.74827 9.76587 4.97499 9.29411C5.19532 8.83436 5.73497 8.56392 6.24268 8.66008C6.80788 8.76825 7.20064 9.19495 7.20702 9.72681C7.20702 10.0964 7.05056 10.3969 6.73443 10.6133ZM3.40074 4.41718C3.40074 3.04695 4.58542 1.93515 6.03832 1.93515C7.49441 1.93515 8.67589 3.04996 8.67589 4.41718V5.95268H3.40074V4.41718Z"
                        fill="#406992"
                    />
                </svg>
                <img
                    v-bind:src="
                        require(
                            '@/assets/img/games/static/' +
                                gameMode +
                                (isExtended ? '-extended' : '') +
                                '.png'
                        )
                    "
                    v-bind:alt="gameName"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import IconCrash from '@/components/icons/IconCrash';
    import IconRoll from '@/components/icons/IconRoll';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconUpgrader from '@/components/icons/IconUpgrader';
    import IconDuels from '@/components/icons/IconDuels';
    import IconMines from '@/components/icons/IconMines';
    import IconTowers from '@/components/icons/IconTowers';
    import IconUnbox from '@/components/icons/IconUnbox';
    import IconBattles from '@/components/icons/IconBattles';

    export default {
        name: 'HomePlaceholderElement',
        components: {
            IconCrash,
            IconRoll,
            IconBlackjack,
            IconUpgrader,
            IconDuels,
            IconMines,
            IconTowers,
            IconUnbox,
            IconBattles
        },
        props: ['game', 'extended'],
        computed: {
            gameName() {
                return this.game || '';
            },
            gameMode() {
                return this.game ? this.game : 'placeholder';
            },
            isExtended() {
                return this.extended === true;
            }
        }
    };
</script>

<style module>
    .home-game-element {
        aspect-ratio: 330 / 172.5;
        display: flex;
        padding: 6px;
        width: 100%;
        font-size: 14px;
    }

    .home-game-element::after {
        clip-path: var(--octagonal-main) !important;
        background: #4d99ff0f;
    }

    .home-game-element.extended {
        aspect-ratio: 666 / 172;
        grid-column: span 2 / span 2;
    }

    .element-wrapper {
        transition: filter 0.3s ease;
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .home-game-element .element-content,
    .home-game-element img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .home-game-element .element-content {
        clip-path: var(--octagonal-main);
        color: white;
        font-size: 10px;
    }

    .home-game-element div.element-content::before,
    .home-game-element a.element-content::before {
        transition: background 0.3s ease;
        background: #3fdd615c;
        z-index: 5;
    }

    .home-game-element.locked .element-content::before {
        background: #9999995c;
    }

    .home-game-element:not(.locked):hover .element-content::before {
        background: linear-gradient(45deg, #27c294, #00ffc2);
    }

    .home-game-element:not(.locked):hover .element-wrapper {
        filter: drop-shadow(0px 0px 25px #19efa240);
    }

    .home-game-element .element-content svg {
        position: absolute;
        top: calc(100% - 25px);
        right: calc(100% - 30px);
        z-index: 3;
    }

    .home-game-element .element-content:has(svg)::after {
        position: absolute;
        content: '';
        width: 44px;
        height: 34px;
        background: #00000045;
        backdrop-filter: blur(5px);
        bottom: 0px;
        left: 0px;
        z-index: 2;
        clip-path: polygon(
            0% 0%,
            calc(100% - 3px) 0%,
            100% 3px,
            100% 100%,
            14px 100%,
            0% calc(100% - 14px)
        );
    }

    .home-game-element .content-img {
        clip-path: var(--octagonal-main);
        width: 100%;
        height: 100%;
    }

    .home-game-element .content-img img {
        object-fit: cover;
        object-position: bottom left;
        transition: transform 0.3s ease;
    }

    .home-game-element:not(.locked) .element-content:hover img,
    .home-game-element:not(.locked) .element-content:focus img {
        transform: scale(1.1);
    }
</style>
