<template>
    <div :class="$style['cashier-gift-tab']">
        <p :class="$style['tab-title']">Redeem Gift Card</p>
        <form :class="$style['tab-amount']" v-on:submit.prevent="modalRedeemButton">
            <input v-model="giftCode" type="text" placeholder="xxxx-xxxx-xxxx-xxxx" />
            <div :class="$style['amount-icon']">
                <img width="17" src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
            </div>
            <button type="submit" :class="$style['amount-redeem']">
                <p>Redeem</p>
            </button>
        </form>
        <div :class="$style['tab-forms']">
            <div :class="$style['forms-tab']">
                <p :class="$style['tab-title']">Redeem Promo Code</p>
                <form :class="$style['tab-amount']" v-on:submit.prevent="modalPromoButton">
                    <input v-model="promoCode" type="text" placeholder="..." />
                    <div :class="$style['amount-icon']">
                        <img width="17" src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                    </div>
                    <button type="submit" :class="$style['amount-redeem']">
                        <p>Redeem</p>
                    </button>
                </form>
            </div>
            <div
                v-if="
                    affiliatesData.data !== null && affiliatesData.data?.referrer?._id === undefined
                "
                :class="$style['forms-tab']"
            >
                <p :class="$style['tab-title']">Redeem Affiliate Code</p>
                <form :class="$style['tab-amount']" v-on:submit.prevent="modalAffiliateButton">
                    <input v-model="affiliateCode" type="text" placeholder="..." />
                    <div :class="$style['amount-icon']">
                        <img width="17" src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                    </div>
                    <button type="submit" :class="$style['amount-redeem']">
                        <p>Redeem</p>
                    </button>
                </form>
            </div>
        </div>
        <div :class="$style['tab-popular-title']">
            <p><span class="fade-gold">Popular Choices</span></p>
        </div>
        <div :class="$style['tab-popular']">
            <a
                :class="$style['tab-gift']"
                v-for="(gift, index) in giftCards"
                v-bind:key="index"
                v-bind:href="gift.link"
                target="_blank"
            >
                <img v-bind:src="gift.image" v-bind:alt="gift.alt" />
            </a>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'CashierCryptoTab',
        data() {
            return {
                affiliateCode: null,
                promoCode: null,
                giftCode: null,
                giftCards: [
                    {
                        alt: '$100',
                        link: 'https://www.kinguin.net/category/250481/rollbet-100-gift-card',
                        image: require('@/assets/img/kinguin/100.png')
                    },
                    {
                        alt: '$50',
                        link: 'https://www.kinguin.net/category/250480/rollbet-50-gift-card',
                        image: require('@/assets/img/kinguin/50.png')
                    },
                    {
                        alt: '$25',
                        link: 'https://www.kinguin.net/category/250479/rollbet-25-gift-card',
                        image: require('@/assets/img/kinguin/25.png')
                    }
                ]
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'modalsSetShow', 'modalsSetData']),
            modalRedeemButton() {
                if (this.socketSendLoading !== null) return;
                if (this.giftCode === null || this.giftCode.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered gift code is invalid.'
                    });
                    return;
                }

                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetData({
                        typeCaptcha: 'giftRedeem',
                        data: { code: this.giftCode.replaceAll('-', '') }
                    });
                    this.modalsSetShow('Captcha');
                }, 200);
            },
            modalAffiliateButton() {
                if (this.affiliateCode === null || this.affiliateCode.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered affiliate code is invalid.'
                    });
                    return;
                }

                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetData({
                        typeCaptcha: 'affiliatesClaim',
                        data: { code: this.affiliateCode }
                    });
                    this.modalsSetShow('Captcha');
                }, 200);
            },
            modalPromoButton() {
                if (this.promoCode === null || this.promoCode.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered promo code is invalid.'
                    });
                    return;
                }

                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetData({
                        typeCaptcha: 'promoClaim',
                        data: { code: this.promoCode }
                    });
                    this.modalsSetShow('Captcha');
                }, 200);
            }
        },
        computed: {
            ...mapGetters([
                'generalSettings',
                'socketSendLoading',
                'userBalanceData',
                'affiliatesData'
            ])
        }
    };
</script>

<style module>
    .cashier-gift-tab {
        margin-bottom: 9px;
        width: 100%;
    }

    .cashier-gift-tab .tab-title {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 7px;
        color: #5885ae;
    }

    .cashier-gift-tab .tab-amount {
        position: relative;
        background: linear-gradient(45deg, #0f2941, #0c2339);
        height: 57px;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .cashier-gift-tab .tab-amount::before {
        font-size: 5px;
        background: #122c47;
    }

    .cashier-gift-tab .tab-amount:has(input:focus)::before {
        filter: brightness(1.8);
    }

    .cashier-gift-tab .tab-amount input {
        appearance: none;
        background: none;
        font-size: 15px;
        font-weight: 600;
        color: #5186ae;
        width: 100%;
        height: 100%;
        padding-left: 57px;
    }

    .cashier-gift-tab .tab-forms {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 14px;
        margin-top: 20px;
    }

    .cashier-gift-tab .forms-tab {
        flex: 1;
        min-width: 260px;
    }

    .cashier-gift-tab .amount-icon {
        width: 33px;
        height: 33px;
        position: absolute;
        background: #153453;
        top: 12px;
        left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: var(--octagonal-main);
        font-size: 3px;
    }

    .cashier-gift-tab .amount-redeem {
        position: absolute;
        top: 13px;
        right: 13px;
        height: 30px;
        width: 70px;
        display: flex;
        align-items: center;
        font-weight: 500;
        justify-content: center;
        color: #6285ab;
        filter: drop-shadow(0px 1px 0px #00000059);
    }

    .cashier-gift-tab .amount-redeem::before,
    .cashier-gift-tab .amount-redeem::after {
        font-size: 3px;
    }

    .cashier-gift-tab .amount-redeem::before {
        background: #1a3756;
    }

    .cashier-gift-tab .amount-redeem::after {
        background: #15314c;
    }

    .cashier-gift-tab .tab-popular-title {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        margin-top: 24px;
        gap: 14px;
    }

    .cashier-gift-tab .tab-popular-title p {
        font-size: 15px;
        font-weight: 600;
        filter: drop-shadow(0 0 20px #d7ad17);
    }

    .cashier-gift-tab .tab-popular-title::before,
    .cashier-gift-tab .tab-popular-title::after {
        content: '';
        flex: 1;
        height: 1px;
        background: #0a2f4d;
    }

    .cashier-gift-tab .tab-popular {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        display: grid;
        width: 100%;
        gap: 12px;
    }

    .cashier-gift-tab .tab-gift {
        background: linear-gradient(45deg, #ffe600, #fff195, #c18f0f);
        border-radius: 8px;
        overflow: hidden;
        aspect-ratio: 5/3;
        position: relative;
        width: 100%;
        padding: 1px;
    }

    .cashier-gift-tab .tab-gift img {
        border-radius: 8px;
        height: 100%;
        width: 100%;
    }

    .cashier-gift-tab .tab-gift::before {
        content: '';
        position: absolute;
        left: -150%;
        top: 50%;
        width: 100%;
        height: 1000px;
        animation: tab-gift 1.5s ease-out;
        background: linear-gradient(90deg, transparent, rgba(255, 217, 0, 0.256), transparent);
        transform: translateY(-50%) rotate(45deg);
    }

    .cashier-gift-tab .tab-gift:nth-child(2)::before {
        animation: tab-gift 1.5s ease-out 0.21s;
    }

    .cashier-gift-tab .tab-gift:nth-child(3)::before {
        animation: tab-gift 1.5s ease-out 0.42s;
    }

    .cashier-gift-tab .tab-gift:is(:hover)::before,
    .cashier-gift-tab .tab-gift:is(:active)::before,
    .cashier-gift-tab .tab-gift:is(:focus)::before {
        animation: tab-gift 1.5s ease-out infinite;
    }

    @keyframes tab-gift {
        0% {
            left: -150%;
        }
        70% {
            left: 200%;
        }
        70.01% {
            left: -150%;
        }
        100% {
            left: 200%;
        }
    }

    .cashier-gift-tab .tab-amount::before,
    .cashier-gift-tab .amount-redeem::before {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: '';
        z-index: -1;
        clip-path: var(--octagonal-before);
    }

    .cashier-gift-tab .amount-redeem::after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: '';
        clip-path: var(--octagonal-after);
        z-index: -1;
    }

    @media screen and (max-width: 600px) {
        .cashier-gift-tab .tab-popular {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .cashier-gift-tab .tab-gift:nth-child(2)::before,
        .cashier-gift-tab .tab-gift:nth-child(3)::before {
            animation: tab-gift 1.5s ease-out 0.21s;
        }
    }
</style>
