<template>
    <form
        :class="[$style['modal-link-email'], $style['email-content']]"
        v-on:submit.prevent="modalLinkButton"
    >
        <h2 :class="$style['email-title']">
            <svg
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.57056 10.0472L0.0304672 3.03947C0.0120027 3.04896 0.0184644 3.04564 0 3.05514V13.6596C0 14.831 0.940202 15.7805 2.1 15.7805H18.9C20.0598 15.7805 21 14.831 21 13.6596V3.02974C20.9966 3.02799 20.9978 3.0286 20.9944 3.02686L11.4297 10.0476C10.8754 10.4544 10.1247 10.4543 9.57056 10.0472Z"
                    fill="#21B894"
                />
                <path
                    d="M11.4298 7.42858L19.9025 1.20576C19.6057 1.04174 19.2641 0.939941 18.9001 0.939941H2.10006C1.73606 0.939941 1.40006 1.04174 1.09766 1.20576L9.57036 7.42858C10.1246 7.83567 10.8755 7.83567 11.4298 7.42858Z"
                    fill="#21B894"
                />
            </svg>
            Verify Email
        </h2>
        <hr :class="$style['email-hr']" />
        <div :class="$style['email-content']">
            <div :class="$style['email-element']">
                <div :class="$style['element-title']">Email</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input
                        v-model="modalEmail"
                        type="email"
                        required="required"
                        v-bind:disabled="authSendLoginLoading === true"
                    />
                </div>
            </div>
            <div :class="$style['email-element']">
                <div :class="$style['element-title']">Password</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input
                        v-model="modalPassword"
                        type="password"
                        required="required"
                        v-bind:disabled="authSendLoginLoading === true"
                    />
                </div>
            </div>
            <div :class="$style['email-element']">
                <div :class="$style['element-title']">Repeat Password</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input
                        v-model="modalPasswordConfirm"
                        type="password"
                        required="required"
                        v-bind:disabled="authSendLoginLoading === true"
                    />
                </div>
            </div>
        </div>
        <div :class="$style['email-button']">
            <div :class="$style.divider"></div>
            <button
                :class="$style['button-action']"
                class="octagonal hoverable"
                v-bind:disabled="authSendLoginLoading"
            >
                <span>
                    <ButtonLoading v-if="authSendLoginLoading === true" />
                    <div v-else :class="$style['inner-content']">Link Email</div>
                </span>
            </button>
            <div :class="$style.divider"></div>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ButtonLoading from '@/components/ButtonLoading';

    export default {
        name: 'ModalLink',
        components: {
            ButtonLoading
        },
        data() {
            return {
                modalEmail: null,
                modalPassword: null,
                modalPasswordConfirm: null
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'modalsSetShow',
                'modalsSetData',
                'authSendCredentialsLink'
            ]),
            modalLinkButton() {
                if (this.modalEmail === null || this.modalEmail.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered email is invalid.'
                    });
                    return;
                }

                if (this.modalPassword === null || this.modalPassword.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered password is invalid.'
                    });
                    return;
                }

                if (this.modalPassword !== this.modalPasswordConfirm) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered passwords does not match.'
                    });
                    return;
                }

                const data = { email: this.modalEmail, password: this.modalPassword };
                this.authSendCredentialsLink(data);
            }
        },
        computed: {
            ...mapGetters(['authSendLoginLoading', 'modalsData'])
        }
    };
</script>

<style module>
    .modal-link-email {
        width: 838px;
        padding: 30px 35px 35px 35px;
    }

    .email-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .email-title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        color: white;
    }

    .email-element .element-title {
        margin-bottom: 7px;
        color: #5b85ae;
        font-size: 15px;
    }

    .element-title .button-forgot {
        margin-left: 2px;
        color: #5b85ae;
        opacity: 0.5;
    }

    .email-element .element-input input {
        background: none;
        height: 57px;
        color: #5b85ae;
        width: 100%;
        font-size: 14px;
        padding: 14px 20px;
        appearance: none;
    }

    .email-element .element-input {
        background: linear-gradient(0deg, #0f426b55, #0d335555);
        width: 100%;
        position: relative;
        clip-path: var(--octagonal-main);
        color: #5b85ae;
        font-size: 3px;
    }

    .email-element .element-input::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #1e4b7e59;
        clip-path: var(--octagonal-before);
    }

    .email-button {
        display: flex;
        align-items: center;
        gap: 14px;
    }

    .email-button .divider {
        height: 1px;
        background: #0a2f4d;
        flex: 1;
    }

    .email-button .divider:last-child {
        max-width: 24px;
    }

    .email-button .button-action {
        font-size: 16px;
        font-weight: 600;
        height: 34px;
        color: white;
        padding: 5px 20px;
        position: relative;
    }

    .email-button span {
        position: relative;
        z-index: 1;
    }

    .email-button .or {
        font-weight: 500;
        font-size: 15px;
        color: #5b85ae;
        margin-top: -5px;
    }

    .email-button .button-action::before,
    .email-button .button-action::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .email-button .button-action::before {
        clip-path: var(--octagonal-before);
        background: #21b894;
        font-size: 3px;
    }

    .email-button .button-action::after {
        clip-path: var(--octagonal-after);
        background: #21b894;
        font-size: 3px;
    }

    @media screen and (max-width: 600px) {
        .modal-link-email {
            padding: 30px 20px 30px 20px;
        }
    }
</style>
