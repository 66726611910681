<template>
    <transition v-bind:name="homeGetTransition">
        <div v-if="position === index" :class="$style['home-news-element']">
            <keep-alive>
                <component v-bind:is="'Banner' + banner"></component>
            </keep-alive>
        </div>
    </transition>
</template>

<script>
    import BannerLinks from '@/components/banner/BannerLinks';
    import BannerGlory from '@/components/banner/BannerGlory';
    import BannerMystery from '@/components/banner/BannerMystery';
    import BannerWhatsNew from '@/components/banner/BannerWhatsNew';

    export default {
        name: 'HomeNewsBanner',
        components: {
            BannerLinks,
            BannerGlory,
            BannerMystery,
            BannerWhatsNew
        },
        props: ['banner', 'direction', 'position', 'index'],
        computed: {
            homeGetTransition() {
                return 'slider-' + this.direction;
            }
        }
    };
</script>

<style scoped>
    .slider-left-enter-active,
    .slider-left-leave-active,
    .slider-right-enter-active,
    .slider-right-leave-active {
        transition: all 0.5s ease;
    }

    .slider-left-enter {
        transform: translateX(-100%);
    }

    .slider-left-leave-to {
        transform: translateX(100%);
    }

    .slider-right-enter {
        transform: translateX(100%);
    }

    .slider-right-leave-to {
        transform: translateX(-100%);
    }
</style>

<style module>
    .home-news-element {
        position: absolute;
        margin: 0px 14px;
        bottom: 0;
        left: 0;
        right: 0;
        top: 14px;
        max-height: calc(100% - 28px);
    }

    .home-news-element > * {
        border-radius: 8px;
    }
</style>
