<template>
    <div :class="$style['battles-overview']">
        <div :class="$style['overview-header']">
            <div :class="$style['header-players']">PLAYERS</div>
            <div :class="$style['header-cases']">CASES</div>
            <div :class="$style['header-right']">
                <div :class="$style['right-amount']">AMOUNT</div>
                <div :class="$style['right-action']">ACTION</div>
            </div>
        </div>
        <div :class="$style['overview-content']">
            <transition name="fade" mode="out-in">
                <div
                    v-if="socketBattles.connected === false"
                    :class="$style['content-loading']"
                    key="loading"
                >
                    <p :class="$style['content-title']">ALL BATTLES</p>
                    <div :class="$style['loading-placeholder']"></div>
                    <div :class="$style['loading-placeholder']"></div>
                    <div :class="$style['loading-placeholder']"></div>
                    <div :class="$style['loading-placeholder']"></div>
                </div>
                <div
                    v-else-if="battlesGetGames.length > 0"
                    :class="$style['content-list']"
                    key="data"
                >
                    <p v-if="myBattles.length" :class="$style['content-title']">MY BATTLES</p>
                    <BattlesGameElement
                        v-for="game of myBattles"
                        v-bind:key="game._id"
                        v-bind:game="game"
                    />
                    <p v-if="allBattles.length" :class="$style['content-title']">ALL BATTLES</p>
                    <BattlesGameElement
                        v-for="game of allBattles"
                        v-bind:key="game._id"
                        v-bind:game="game"
                    />
                </div>
                <div v-else :class="$style['content-empty']" key="empty">There are no battles.</div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BattlesGameElement from '@/components/battles/BattlesGameElement';

    export default {
        name: 'BattlesOverview',
        components: {
            BattlesGameElement
        },
        computed: {
            ...mapGetters([
                'authUser',
                'socketBattles',
                'battlesGames',
                'battlesHistory',
                'battlesFilterSortGames'
            ]),
            battlesGetGames() {
                let games = this.battlesGames;

                if (this.battlesFilterSortGames === 'price') {
                    games.sort(function (a, b) {
                        return b.amount - a.amount;
                    });
                } else {
                    games.sort(function (a, b) {
                        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                    });
                }

                games = [...games, ...this.battlesHistory];

                return games;
            },
            allBattles() {
                return this.battlesGetGames.filter(
                    (game) =>
                        !game.bets.some((bet) =>
                            this.authUser?.user?.username
                                ? bet.user.username === this.authUser?.user?.username
                                : false
                        )
                );
            },
            myBattles() {
                return this.battlesGetGames.filter((game) =>
                    game.bets.some(
                        (bet) =>
                            this.authUser?.user?.username &&
                            bet.user.username === this.authUser?.user?.username
                    )
                );
            }
        },
        methods: {
            battlesGetBets(game) {
                let bets = [];

                for (let bet = 0; bet < game.playerCount; bet++) {
                    const index = game.bets.findIndex((element) => element.slot === bet);

                    bets.push(index !== -1 ? game.bets[index] : null);
                }

                return bets;
            }
        }
    };
</script>

<style scoped>
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active,
    .fade-enter-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-enter-from {
        opacity: 0;
    }
</style>

<style module>
    .battles-overview {
        width: 100%;
    }

    .battles-overview .overview-header {
        width: 100%;
        display: flex;
        display: none;
        justify-content: space-between;
        align-items: center;
    }

    .battles-overview .header-players,
    .battles-overview .header-cases,
    .battles-overview .header-right {
        font-size: 13px;
        font-weight: 700;
        color: #5e768e;
    }

    .battles-overview .header-players {
        width: 450px;
    }

    .battles-overview .header-cases {
        width: calc(100% - 825px);
    }

    .battles-overview .header-right {
        width: 375px;
        display: flex;
        justify-content: space-between;
        padding-left: 25px;
    }

    .battles-overview .header-action {
        display: flex;
        justify-content: flex-end;
    }

    .battles-overview .overview-content {
        width: 100%;
    }

    .overview-content .content-title {
        font-weight: 700;
        font-size: 14px;
        color: #406992;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .overview-content .content-title:first-child {
        margin-top: 0px;
    }

    .battles-overview .content-loading,
    .battles-overview .content-list {
        width: 100%;
    }

    .battles-overview .loading-placeholder {
        width: 100%;
        height: 95px;
        position: relative;
        margin-top: 8px;
        background: linear-gradient(
            223deg,
            rgba(5, 29, 48, 0.35) 0%,
            rgba(31, 99, 153, 0.09) 50%,
            rgba(5, 29, 48, 0.35) 100%
        );
        border: 1px solid #0a273f;
        overflow: hidden;
    }

    .battles-overview .loading-placeholder:first-child {
        margin-top: 0;
    }

    .battles-overview .loading-placeholder:after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(
            to right,
            #ffffff00 0%,
            rgba(255, 255, 255, 0.1) 50%,
            #ffffff00 100%
        );
    }

    .battles-overview .content-empty {
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    @keyframes loading_animation {
        0% {
            transform: translateX(-100%);
        }
        50% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(100%);
        }
    }

    @media only screen and (max-width: 1100px) {
        .battles-overview .header-players {
            width: auto;
        }

        .battles-overview .header-cases,
        .battles-overview .right-amount {
            display: none;
        }

        .battles-overview .header-right {
            width: auto;
            padding-left: 0;
        }
    }
</style>
