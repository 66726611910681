<template>
    <div :class="$style['unbox-controls']">
        <button
            v-on:click="unboxDemoButton()"
            :class="$style['controls-demo']"
            v-bind:disabled="socketSendLoading !== null || unboxRunning === true"
        >
            <div :class="$style['button-inner']">DEMO SPIN</div>
        </button>
        <button
            v-for="item in [1, 2, 3, 4]"
            v-bind:key="item"
            v-on:click="unboxSetCount(item)"
            v-bind:class="[$style['controls-count'], { [$style.active]: unboxCount === item }]"
            v-bind:disabled="socketSendLoading !== null || unboxRunning === true"
        >
            <span :class="{ 'fade-green': unboxCount === item }">{{ item }}</span>
        </button>
        <button
            v-on:click="unboxBetButton()"
            :class="$style['controls-open']"
            v-bind:disabled="socketSendLoading !== null || unboxRunning === true"
        >
            <span>Open Case</span>
        </button>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'UnboxControls',
        methods: {
            ...mapActions([
                'notificationShow',
                'unboxSetCount',
                'unboxSetRunnning',
                'unboxSetGames',
                'unboxSendBetSocket'
            ]),
            unboxFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            unboxBetButton() {
                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                const data = {
                    boxId: this.unboxBoxData.box._id,
                    unboxCount: this.unboxCount,
                    type: this.userBalanceData.type
                };
                this.unboxSendBetSocket(data);
            },
            unboxDemoButton() {
                let games = [];

                for (let i = 0; i < this.unboxCount; i++) {
                    games.push({
                        demo: true,
                        outcome: Math.floor(Math.random() * (100000 - 1 + 1)) + 1,
                        updatedAt: new Date()
                    });
                }

                this.unboxSetRunnning(true);
                this.unboxSetGames(games);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'authUser',
                'unboxCount',
                'unboxRunning',
                'unboxBoxData',
                'userBalanceData'
            ])
        }
    };
</script>

<style module>
    .unbox-controls {
        display: flex;
        gap: 7px;
        align-items: center;
        margin-top: 25px;
    }

    .unbox-controls .controls-count,
    .unbox-controls .controls-open,
    .unbox-controls .controls-demo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        filter: drop-shadow(0px 2px 0px #00000040);
        height: 39px;
        font-size: 15px;
        font-weight: 600;
        color: #5b85ae;
        padding: 0 7px;
    }

    .unbox-controls .controls-count::before,
    .unbox-controls .controls-open::before,
    .unbox-controls .controls-demo::before,
    .unbox-controls .controls-count::after,
    .unbox-controls .controls-open::after,
    .unbox-controls .controls-demo::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        font-size: 5px;
    }

    .unbox-controls .controls-count::before,
    .unbox-controls .controls-open::before,
    .unbox-controls .controls-demo::before {
        clip-path: var(--octagonal-before);
    }

    .unbox-controls .controls-count::after,
    .unbox-controls .controls-open::after,
    .unbox-controls .controls-demo::after {
        clip-path: var(--octagonal-after);
    }

    .unbox-controls .controls-demo {
        margin-right: auto;
        max-width: 115px;
        flex: 1;
    }

    .unbox-controls .controls-demo::after {
        background: linear-gradient(0deg, #0d2d47, #0d2d47),
            linear-gradient(360deg, rgba(15, 66, 107, 0.35) 8.33%, rgba(13, 51, 85, 0.35) 108.33%);
    }

    .unbox-controls .controls-demo::before {
        background: #1e4b7e59;
    }

    .unbox-controls .controls-count {
        width: 39px;
    }

    .unbox-controls .controls-count::before {
        background: #1e4b7e59;
    }

    .unbox-controls .controls-count::after {
        background: linear-gradient(0deg, #0d2d47, #0d2d47),
            linear-gradient(360deg, rgba(15, 66, 107, 0.35) 8.33%, rgba(13, 51, 85, 0.35) 108.33%);
    }

    .unbox-controls .controls-count.active {
        filter: unset;
    }

    .unbox-controls .controls-count.active::before {
        background: #01e1a440;
    }

    .unbox-controls .controls-count.active::after {
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.68deg,
                rgba(0, 0, 0, 0.128) -11.11%,
                rgba(0, 33, 24, 0.132) 16.04%,
                rgba(0, 99, 73, 0.144986) 58.69%,
                rgba(1, 193, 143, 0.0925409) 79.88%,
                rgba(1, 237, 176, 0.068) 99.83%
            );
    }

    .unbox-controls .controls-open {
        max-width: 115px;
        color: white;
        filter: unset;
        flex: 1;
    }

    .unbox-controls .controls-open::before {
        background: linear-gradient(180deg, rgba(0, 255, 194, 0) 28%, #00ffc2 100%);
    }

    .unbox-controls .controls-open::after {
        background: linear-gradient(263.46deg, #00ffc2 -10.36%, #00aa6d 44.58%);
    }
</style>
