<template>
    <div class="banner-mystery">
        <div class="banner-mystery-bg"></div>
        <h1>A MYSTERY AWAITS, CAN YOU GUESS WHAT IT IS?</h1>
        <img class="mystery-mark" src="@/assets/img/mystery.png" />
        <img class="mystery-mark-01" src="@/assets/img/mystery-01.png" />
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BannerMystery',
        components: {
            AvatarImage
        },
        computed: {
            ...mapGetters(['authUser'])
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    };
</script>

<style scoped>
    .banner-mystery {
        border: 1px solid #17802e;
        border-radius: 8px;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 14px;
        font-size: 10px;
        display: flex;
        height: 100%;
        width: 100%;
        gap: 34px;
    }

    .banner-mystery-bg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
    }

    .banner-mystery-bg::before {
        animation: background-move 6s ease alternate infinite;
        background-image: url('~@/assets/img/banner-mystery-bg.png');
        opacity: 0.8;
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 50px);
        height: 100%;
        content: '';
    }

    .banner-mystery h1 {
        font-family: 'Forum';
        text-align: center;
        color: white;
        font-size: 46px;
        position: relative;
        z-index: 1;
        filter: drop-shadow(0 2px 0px #00000099) drop-shadow(0.125px 0.125px 0 #00000099)
            drop-shadow(-0.125px -0.125px 0 #00000099) drop-shadow(0.125px -0.125px 0 #00000099)
            drop-shadow(-0.125px 0.125px 0 #00000099);
        position: relative;
        width: fit-content;
    }

    .banner-mystery h1::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        user-select: none;
        pointer-events: none;
        content: 'A MYSTERY AWAITS, CAN YOU GUESS WHAT IT IS?';
        background-image: linear-gradient(-45deg, transparent, #00000099, transparent);
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: -900% 50%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        animation: mystery-h1 1.5s ease-out 2s;
    }

    @keyframes mystery-h1 {
        0% {
            background-position: -900% 50%;
        }
        100% {
            background-position: 900% 50%;
        }
    }

    .banner-mystery .mystery-mark {
        animation: mark-bounce 2s ease alternate-reverse infinite;
        position: absolute;
        width: 180px;
        top: 10px;
        left: -25px;
    }

    .banner-mystery .mystery-mark-01 {
        animation: mark-bounce 1.5s ease alternate infinite;
        position: absolute;
        width: 154px;
        right: -10px;
        top: 20px;
    }

    @keyframes mark-bounce {
        0% {
            transform: translateY(5%);
        }
        100% {
            transform: translateY(-5%);
        }
    }

    @keyframes background-move {
        0% {
            left: 0;
        }
        100% {
            left: -50px;
        }
    }

    @media screen and (max-width: 1200px) {
        .banner-mystery .mystery-mark {
            width: 120px;
            top: -25px;
        }
        .banner-mystery .mystery-mark-01 {
            width: 130px;
            top: unset;
            bottom: -15px;
        }
    }

    @media screen and (max-width: 1020px) {
        .banner-mystery h1 {
            max-width: 700px;
        }
    }

    @media screen and (max-width: 550px) {
        .banner-mystery h1 {
            font-size: 32px;
        }
    }
</style>
