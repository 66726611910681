<template>
    <div :class="$style['cashier-selected-element']">
        <div :class="$style['element-content']">
            <div :class="$style['content-item']">
                <div :class="$style['item-image']">
                    <span :class="{ 'fade-gold': cashierGetDiscount < 0 }">
                        {{ cashierGetDiscount < 0 ? '-' : '+' }}{{ Math.abs(cashierGetDiscount) }}%
                    </span>
                    <img v-bind:src="selected.image" />
                </div>
                <div :class="$style['item-info']">
                    <p :class="$style['info-name']">
                        <span>
                            {{ selected.name }}
                        </span>
                    </p>
                    <div
                        :class="[
                            $style['info-amount'],
                            $style[
                                userBalanceData.type === 'robuxCoin' ||
                                cashierGetRouteName === 'LimitedsDeposit'
                                    ? 'robuxCoin'
                                    : 'cryptoCoin'
                            ]
                        ]"
                    >
                        <img
                            width="15"
                            v-if="
                                userBalanceData.type === 'robuxCoin' ||
                                cashierGetRouteName === 'LimitedsDeposit'
                            "
                            src="@/assets/img/icons/robuxCoin.svg"
                        />
                        <img width="11" v-else src="@/assets/img/icons/cryptoCoin.svg" />
                        <p v-if="selected.amountFixed !== undefined">
                            {{ Math.floor(selected.amountFixed / 1000).toLocaleString() }}
                            <span>{{ (selected.amountFixed / 1000).toFixed(2).slice(-3) }}</span>
                        </p>
                        <p v-else-if="selected.amount !== undefined">
                            {{
                                Math.floor(
                                    (selected.amount +
                                        selected.amount * (cashierGetDiscount / 100)) /
                                        1000
                                ).toLocaleString()
                            }}
                            <span>
                                {{
                                    (
                                        (selected.amount +
                                            selected.amount * (cashierGetDiscount / 100)) /
                                        1000
                                    )
                                        .toFixed(2)
                                        .slice(-3)
                                }}
                            </span>
                        </p>
                    </div>
                </div>
                <button
                    v-on:click="cashierRemoveLimitedDataSelected(mainSelected || selected)"
                    :class="$style['item-remove']"
                    class="octagonal hoverable"
                >
                    <svg
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.32453 4.49997L8.82895 0.995447C9.05701 0.767492 9.05701 0.398921 8.82895 0.170966C8.601 -0.0569887 8.23242 -0.0569887 8.00447 0.170966L4.49994 3.67549L0.995527 0.170966C0.767466 -0.0569887 0.399001 -0.0569887 0.171046 0.170966C-0.0570154 0.398921 -0.0570154 0.767492 0.171046 0.995447L3.67546 4.49997L0.171046 8.0045C-0.0570154 8.23245 -0.0570154 8.60102 0.171046 8.82898C0.28465 8.94269 0.434022 8.99981 0.583287 8.99981C0.732552 8.99981 0.881817 8.94269 0.995527 8.82898L4.49994 5.32445L8.00447 8.82898C8.11818 8.94269 8.26744 8.99981 8.41671 8.99981C8.56597 8.99981 8.71524 8.94269 8.82895 8.82898C9.05701 8.60102 9.05701 8.23245 8.82895 8.0045L5.32453 4.49997Z"
                            fill="#5B84AE"
                        />
                    </svg>
                </button>
            </div>
            <div
                v-if="cashierGetRouteName === 'LimitedsDeposit' && selected.items === undefined"
                :class="[
                    $style['content-range'],
                    { [$style.less]: cashierGetDiscount < 0 },
                    $style[
                        userBalanceData.type === 'robuxCoin' ||
                        cashierGetRouteName === 'LimitedsDeposit'
                            ? 'robuxCoin'
                            : 'cryptoCoin'
                    ]
                ]"
            >
                <div
                    :class="$style['range-fill']"
                    :style="{ width: Math.abs(discount - 50) + '%' }"
                ></div>
                <input
                    v-on:input="cashierApplyDiscount"
                    v-model="discount"
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'CashierSelectedElement',
        props: ['selected', 'mainSelected'],
        data() {
            return {
                discount: 50
            };
        },
        methods: {
            ...mapActions(['cashierRemoveLimitedDataSelected']),
            cashierApplyDiscount() {
                this.selected.amountFixed =
                    this.selected.amount + ((this.discount - 50) / 100) * this.selected.amount;
            }
        },
        computed: {
            ...mapGetters(['userBalanceData']),
            cashierGetRouteName() {
                return this.$route.name;
            },
            cashierGetDiscount() {
                if (this.cashierGetRouteName === 'LimitedsDeposit') {
                    return this.discount - 50;
                }

                let a = this.selected.amount;
                const b = this.mainSelected.amount;

                a = this.mainSelected.items.reduce((a, b) => a + b.amount, 0);
                return Math.round(((b - a) / a) * 100) || 0;
            },
            cashierGetImages() {
                let images = [this.selected.image];

                if (this.cashierGetRouteName.includes('withdraw') === true) {
                    images = this.selected.items.map((element) => element.image);
                }

                return images;
            }
        }
    };
</script>

<style module>
    .cashier-selected-element {
        width: 100%;
        padding: 4px;
        background: #0d243a;
        font-size: 14px;
        clip-path: var(--octagonal-main);
    }

    .cashier-selected-element .element-content {
        min-height: 100px;
        background: radial-gradient(50% 50% at center, #081b2d, #091d31);
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        clip-path: var(--octagonal-main);
        position: relative;
        padding: 12px;
        width: 100%;
    }

    .cashier-selected-element .element-content::before {
        background: #1a2e4c;
        font-size: 12px;
    }

    .cashier-selected-element .content-item {
        align-items: center;
        display: flex;
        gap: 12px;
    }

    .cashier-selected-element .item-image img {
        transform: scale(1.1);
        width: 100%;
        height: 100%;
    }

    .cashier-selected-element .item-image span {
        position: absolute;
        font-weight: 600;
        font-size: 11px;
        top: -3px;
        right: -2px;
        color: #bdbdbd;
    }

    .cashier-selected-element .item-image {
        position: relative;
        height: 61px;
        width: 61px;
        min-width: 61px;
    }

    .cashier-selected-element .item-image::after {
        background: #061726;
        font-size: 5px;
        clip-path: var(--octagonal-main) !important;
    }

    .cashier-selected-element .item-image::before {
        font-size: 5px;
        background: #172a47;
    }

    .cashier-selected-element .item-info .info-name span {
        max-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 500;
        width: 100%;
    }

    .cashier-selected-element .item-info .info-name {
        height: 34px;
        width: 100%;
        display: flex;
        align-items: flex-end;
    }

    .cashier-selected-element .item-info {
        margin-top: -2px;
        width: calc(100% - 61px - 26px - 12px - 12px);
        font-size: 13px;
        color: white;
        font-weight: 500;
    }

    .cashier-selected-element .info-amount span {
        font-size: 9px;
        opacity: 0.6;
    }
    .cashier-selected-element .info-amount {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cashier-selected-element .info-amount {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 0px 14px;
        font-weight: 600;
        margin-top: 4px;
        gap: 5px;
        height: 24px;
    }

    .cashier-selected-element .info-amount::after {
        font-size: 3px;
        background: linear-gradient(90deg, #13403f, #1b5554);
    }

    .cashier-selected-element .info-amount::before {
        font-size: 3px;
        background: linear-gradient(0deg, #55f5bf, transparent);
    }

    .cashier-selected-element .info-amount.robuxCoin::after {
        font-size: 3px;
        background: linear-gradient(90deg, #56511c, #626020);
    }

    .cashier-selected-element .info-amount.robuxCoin::before {
        font-size: 3px;
        background: linear-gradient(0deg, #f0f555, transparent);
    }

    .cashier-selected-element .item-remove {
        filter: drop-shadow(0 0 1px #00000059);
        height: 26px;
        width: 26px;
        min-width: 26px;
    }

    .cashier-selected-element .item-remove::after {
        font-size: 3px;
        background: #0f283f;
    }

    .cashier-selected-element .item-remove::before {
        font-size: 3px;
        background: #132c46;
    }

    .cashier-selected-element .content-range {
        position: relative;
        height: 9px;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 5px;
    }

    .cashier-selected-element .range-fill {
        position: absolute;
        left: 50%;
        right: unset;
        top: 0;
        height: 100%;
        background: #3e5162;
        z-index: -1;
    }

    .cashier-selected-element .content-range.less .range-fill {
        right: 50%;
        left: unset;
        background: linear-gradient(0deg, #1b5455, #206162);
    }

    .cashier-selected-element .content-range.less.robuxCoin .range-fill {
        right: 50%;
        left: unset;
        background: linear-gradient(0deg, #55501b, #606220);
    }

    .cashier-selected-element .content-range::before {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        content: '';
        font-size: 0.5px;
        clip-path: var(--octagonal-main);
        background: #03131f;
        z-index: -1;
    }

    .cashier-selected-element .content-range::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 13px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #9ba7b1;
        z-index: -1;
        border-radius: 4px;
    }

    .cashier-selected-element .content-range.less::after {
        background: #00d598;
    }

    .cashier-selected-element .content-range.less.robuxCoin::after {
        background: #d5d500;
    }

    .cashier-selected-element input {
        width: calc(100% + 3px);
        height: 100%;
        left: -2px;
        top: 0;
        position: absolute;
        font-size: 0px;
        -webkit-appearance: none;
        -moz-apperance: none;
        background-color: transparent;
        filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .cashier-selected-element input::before {
        background: rgba(0, 255, 194, 0.12);
    }

    .cashier-selected-element input::-webkit-slider-thumb {
        width: 19px;
        height: 19px;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        background: #0d3355;
        font-size: 1px;
        clip-path: var(--octagonal-after);
    }
    /* 
    .cashier-selected-element .settings-funding.funding-active input::-webkit-slider-thumb {
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    } */

    .cashier-selected-element input::-moz-range-thumb {
        width: 19px;
        height: 19px;
        background: #0d3355;
        cursor: pointer;
        font-size: 1px;
        clip-path: var(--octagonal-after);
    }

    .cashier-selected-element input::-moz-range-thumb {
        background: #dcdcdc;
        border: 5px solid white;
        box-sizing: border-box;
    }

    .cashier-selected-element .info-amount::after,
    .cashier-selected-element .item-remove::after,
    .cashier-selected-element .item-image::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-after);
        z-index: -1;
    }

    .cashier-selected-element .element-content::before,
    .cashier-selected-element .info-amount::before,
    .cashier-selected-element .item-remove::before,
    .cashier-selected-element .item-image::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        clip-path: var(--octagonal-before);
    }
</style>
