<template>
    <div :class="$style['battles-header-game']">
        <div
            v-if="
                battlesGameData.game === null ||
                ['created', 'countdown', 'pending', 'completed'].includes(
                    battlesGameData.game.state
                ) === true
            "
            :class="$style['info-text']"
        >
            {{ battlesGetState }}
        </div>
        <div v-else :class="$style['info-running']">
            <div :class="$style['running-case']">
                {{ battlesGetBoxes[battlesGetRound - 1].name }}
            </div>
            <div :class="$style['running-box']"></div>
            <div :class="$style['running-round']">
                Round
                <span>{{ battlesGetRound }}</span>
                {{ '/' }}{{ battlesGetBoxes.length }}
            </div>
        </div>
        <div :class="$style['game-flex']">
            <div :class="$style['game-left']">
                <router-link to="/battles" :class="$style['link-back']">
                    <div :class="$style['link-inner']">
                        <svg
                            width="10"
                            height="9"
                            viewBox="0 0 10 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.21864 3.60238C9.16544 3.59389 9.11161 3.58997 9.05775 3.59066H2.88651L3.08649 3.49916C3.28196 3.40815 3.45979 3.28428 3.61202 3.13316L5.18387 1.58689C5.52258 1.26881 5.5795 0.757122 5.31874 0.374506C5.01525 -0.0332154 4.43324 -0.121742 4.01874 0.176807C3.98526 0.200941 3.95343 0.227247 3.92352 0.255555L0.272801 3.84686C-0.0906526 4.204 -0.0909723 4.78334 0.272075 5.14088L0.272801 5.1416L3.92352 8.73291C4.28726 9.08973 4.87619 9.08893 5.23895 8.7311C5.26749 8.70294 5.29415 8.67297 5.31874 8.64141C5.5795 8.25879 5.52258 7.7471 5.18387 7.42902L3.61667 5.87818C3.4802 5.74379 3.3233 5.6311 3.1516 5.5442L2.87255 5.42068H9.00656C9.48172 5.43803 9.8986 5.11152 9.98787 4.65207C10.0701 4.15325 9.72571 3.68331 9.21864 3.60238Z"
                                fill="#406992"
                            />
                        </svg>
                    </div>
                </router-link>
                <div :class="$style['game-cost']">
                    BATTLE COST
                    <div :class="$style['cost-amount']">
                        <img
                            v-if="battlesGameCoinType === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="battlesGameCoinType === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                        <div :class="$style['amount-value']">
                            <span>{{ battlesFormatValue(battlesGetAmount).split('.')[0] }}</span
                            >.{{ battlesFormatValue(battlesGetAmount).split('.')[1] }}
                        </div>
                    </div>
                </div>
            </div>
            <div :class="$style['game-info']">
                <BattlesGameBoxes v-bind:game="battlesGameData.game" />
            </div>
            <div :class="$style['game-right']">
                <!-- <div
                    v-if="battlesGameData.game !== null"
                    class="right-info"
                    v-bind:class="{
                        'info-cursed': battlesGameData.game.options.cursed === true,
                        'info-terminal': battlesGameData.game.options.terminal === true
                    }"
                >
                    <IconCursedGradient v-if="battlesGameData.game.options.cursed === true" />
                    <IconTerminalGradient v-if="battlesGameData.game.options.terminal === true" />
                    <div class="info-option">{{ battlesGetOption }}</div>
                    STANDARD
                </div> -->
                <div :class="$style['right-actions']">
                    <button
                        v-on:click="battlesCopyButton(battlesGetLink)"
                        :class="$style['button-link']"
                    >
                        <div :class="$style['button-inner']">
                            <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.1967 1.16684L6.74187 4.6216C6.7352 4.62824 6.73105 4.6363 6.72441 4.64301C7.5755 4.51905 8.44983 4.60403 9.26032 4.92008L11.6051 2.57532C12.3819 1.79852 13.6452 1.79852 14.422 2.57532C15.1988 3.35205 15.1988 4.61545 14.422 5.39218C14.2896 5.52471 10.7675 9.04676 10.9673 8.84694C10.1843 9.62995 8.89983 9.59645 8.15032 8.84694C7.76214 8.45876 7.13012 8.45876 6.74187 8.84694L6.13721 9.45161C6.30492 9.73658 6.4972 10.0108 6.74187 10.2555C8.21718 11.7308 10.7566 11.8486 12.3543 10.2729C12.361 10.2663 12.369 10.2621 12.3757 10.2555L15.8305 6.8007C17.3862 5.24492 17.3862 2.72262 15.8305 1.16684C14.2747 -0.388945 11.7524 -0.388945 10.1967 1.16684Z"
                                />
                                <path
                                    d="M7.74452 12.0696L5.39219 14.422C4.61546 15.1988 3.35205 15.1988 2.57533 14.422C1.79853 13.6452 1.79853 12.3818 2.57533 11.605C2.70779 11.4726 6.23737 7.94299 6.03762 8.14274C6.82056 7.3598 8.10505 7.39323 8.85455 8.14274C9.24273 8.53099 9.87479 8.53099 10.263 8.14274L10.8677 7.53807C10.7 7.2531 10.5077 6.97889 10.263 6.73429C8.79052 5.26174 6.25294 5.13645 4.65055 6.71679C4.64388 6.72343 4.63585 6.72762 4.62914 6.73429L1.16681 10.1966C-0.388904 11.7523 -0.38897 14.2747 1.16681 15.8305C2.72259 17.3862 5.24496 17.3862 6.80067 15.8305L10.263 12.3681C10.2696 12.3615 10.2738 12.3535 10.2804 12.3467C9.42934 12.4707 8.55504 12.3857 7.74452 12.0696Z"
                                />
                            </svg>
                        </div>
                    </button>
                    <button v-on:click="battlesFairButton()" :class="$style['button-fair']">
                        <div :class="$style['button-inner']">
                            <svg
                                width="14"
                                height="17"
                                viewBox="0 0 14 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M13.9999 2.68561V4.43688H12.7643C11.3888 4.43822 10.0388 4.06586 8.85843 3.35957L7.29608 2.41501C7.20521 2.36005 7.10104 2.33099 6.99484 2.33099C6.88865 2.33099 6.78447 2.36005 6.69361 2.41501L5.13636 3.35446C3.95572 4.06004 2.60589 4.43235 1.23048 4.43177H4.20249e-10V2.68561C-5.85389e-06 2.53077 0.0611539 2.38219 0.170166 2.27222C0.279177 2.16225 0.427215 2.0998 0.582053 2.09845H1.23559C2.71662 2.09881 4.1696 1.69452 5.4376 0.929242L6.99995 0L8.5572 0.934348C9.82601 1.69771 11.2785 2.10186 12.7592 2.10356H13.4178C13.5714 2.10618 13.7179 2.16835 13.8265 2.27694C13.9351 2.38553 13.9973 2.53206 13.9999 2.68561Z"
                                    fill="#01E1A4"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12.7643 5.61631C11.1776 5.61556 9.62088 5.18316 8.26106 4.36541L6.99995 3.59955L5.73884 4.35009C4.38025 5.1725 2.82369 5.61017 1.23559 5.61631H0V7.14803C0.00357593 8.92048 0.511507 10.6553 1.46444 12.1498C2.41738 13.6443 3.77596 14.8368 5.38143 15.5878L6.99995 16.3383L8.60825 15.5878C10.216 14.8387 11.5771 13.647 12.5319 12.1523C13.4868 10.6576 13.996 8.92172 13.9999 7.14803V5.61631H12.7643ZM7.29608 10.722C7.18766 10.8262 7.04311 10.8845 6.89273 10.8845C6.74235 10.8845 6.5978 10.8262 6.48938 10.722L4.85555 9.0882L5.67757 8.26618L6.90294 9.48645L8.94523 7.44416L9.76725 8.26618L7.29608 10.722Z"
                                    fill="#01E1A4"
                                />
                            </svg>
                        </div>
                    </button>
                    <!-- <button
                        v-on:click="soundSetBattles(soundBattles === 1 ? 0 : 1)"
                        class="button-sound"
                        v-bind:class="{ 'button-active': soundBattles === 1 }"
                    >
                        <IconSoundOn v-if="soundBattles === 1" />
                        <IconSoundOff v-else />
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconTerminalGradient from '@/components/icons/IconTerminalGradient';
    import IconCursedGradient from '@/components/icons/IconCursedGradient';
    import IconSoundOn from '@/components/icons/IconSoundOn';
    import IconSoundOff from '@/components/icons/IconSoundOff';
    import BattlesGameBoxes from '@/components/battles/BattlesGameBoxes';

    export default {
        name: 'BattlesHeaderGame',
        components: {
            IconTerminalGradient,
            IconCursedGradient,
            IconSoundOn,
            IconSoundOff,
            BattlesGameBoxes
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'soundSetBattles',
                'modalsSetData',
                'modalsSetShow'
            ]),
            battlesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            battlesFairButton() {
                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                this.modalsSetData({ game: this.battlesGameData.game });
                this.modalsSetShow('FairGame');
            },
            battlesCopyButton(value) {
                const el = document.createElement('textarea');
                el.value = value;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                this.notificationShow({
                    type: 'success',
                    message: 'Successfully copied to your clipboard.'
                });
            }
        },
        computed: {
            ...mapGetters(['soundBattles', 'authUser', 'battlesGameData', 'userBalanceData']),
            battlesGameCoinType() {
                if (!this.authUser.user)
                    return this.battlesGameData.game.bets[0].coinType || 'cryptoCoin';
                return (
                    this.battlesGameData.game?.bets?.find(
                        (element) => this.authUser.user?._id === element?.user?._id
                    )?.coinType || this.userBalanceData.type
                );
            },
            battlesGetAmount() {
                let amount = 0;

                if (this.battlesGameData.game !== null) {
                    amount = this.battlesGameData.game.amount;
                }

                return amount;
            },
            battlesGetState() {
                let state = 'Waiting to start...';

                if (this.battlesGameData.game !== null) {
                    if (this.battlesGameData.game.state === 'pending') {
                        state = 'Awaiting EOS Block';
                    } else if (this.battlesGameData.game.state === 'completed') {
                        state = 'Game Ended';
                    }
                }

                return state;
            },
            battlesGetBoxes() {
                let boxes = [];

                if (this.battlesGameData.game !== null) {
                    for (const box of this.battlesGameData.game.boxes) {
                        for (let i = 0; i < box.count; i++) {
                            boxes.push(box.box);
                        }
                    }
                }

                return boxes;
            },
            battlesGetRound() {
                let round = 1;

                if (
                    this.battlesGameData.game.bets[0].outcomes !== undefined &&
                    this.battlesGameData.game.bets[0].outcomes.length >= 1
                ) {
                    round = this.battlesGameData.game.bets[0].outcomes.length;
                }

                return round;
            },
            battlesGetOption() {
                let option = '';

                if (
                    this.battlesGameData.game.options.cursed === true &&
                    this.battlesGameData.game.options.terminal === false
                ) {
                    option = 'CURSED MODE';
                } else if (
                    this.battlesGameData.game.options.cursed === false &&
                    this.battlesGameData.game.options.terminal === true
                ) {
                    option = 'TERMINAL MODE';
                }

                return option;
            },
            battlesGetLink() {
                let link = 'hypedraft.com/battles/';

                if (this.battlesGameData.game !== null) {
                    link = 'rollbet.gg/battles/' + this.battlesGameData.game._id;
                }

                return link;
            }
        }
    };
</script>

<style module>
    .battles-header-game {
        position: relative;
        height: calc(62px + 27px);
        width: 100%;
    }

    .battles-header-game .game-flex {
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: space-between;
    }

    .battles-header-game .game-cost {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: auto;
        font-size: 15px;
        height: 51px;
        font-weight: 800;
        color: #5e768e;
    }

    .battles-header-game .game-left {
        display: flex;
        gap: 25px;
        align-items: center;
        flex: 1;
    }

    .battles-header-game .cost-amount {
        display: flex;
        align-items: center;
        margin-top: 5px;
        justify-content: center;
        padding: 0 10px;
        height: 26px;
        position: relative;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 28.53%,
            rgba(30, 255, 194, 0.35) 91.03%
        );
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .battles-header-game .cost-amount::before {
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        font-size: 3px;
        clip-path: var(--octagonal-after);
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.54deg,
                rgba(0, 0, 0, 0.128) -3.42%,
                rgba(0, 33, 24, 0.132) 35.6%,
                rgba(0, 99, 73, 0.144986) 96.9%,
                rgba(1, 193, 143, 0.0925409) 127.35%,
                rgba(1, 237, 176, 0.068) 156.02%
            );
    }

    .battles-header-game .cost-amount img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        position: relative;
        z-index: 2;
    }

    .battles-header-game .amount-value {
        font-size: 12px;
        font-weight: 600;
        color: #bbbfd0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        z-index: 2;
    }

    .battles-header-game .amount-value span {
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-header-game .game-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 442px;
        height: 62px;
    }

    .battles-header-game .info-text {
        height: 20px;
        font-size: 15px;
        font-weight: 700;
        color: #5e768e;
        margin: auto;
        text-align: center;
        margin-bottom: 7px;
    }

    .battles-header-game .info-running {
        height: 20px;
        display: flex;
        align-items: center;
        margin: auto;
        text-align: center;
        justify-content: center;
        margin-bottom: 7px;
    }

    .battles-header-game .running-case {
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
    }

    .battles-header-game .running-box {
        width: 10px;
        height: 10px;
        margin: 0 8px;
        transform: rotate(45deg);
        background: radial-gradient(140% 140% at 50% 50%, #01db9e 0%, rgba(6, 41, 68, 0) 100%);
        border: 1px solid #01e0a3;
    }

    .battles-header-game .running-round {
        font-size: 15px;
        font-weight: 700;
        color: #5e768e;
    }

    .battles-header-game .running-round span {
        color: white;
    }

    .battles-header-game .game-right {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: auto;
        align-items: flex-end;
    }

    .battles-header-game .right-actions {
        display: flex;
        align-items: center;
    }

    .battles-header-game .right-info,
    .battles-header-game .right-actions {
        display: flex;
        gap: 7px;
        align-items: center;
    }

    .battles-header-game .right-info {
        font-size: 12px;
        font-weight: 800;
        color: #5e768e;
    }

    .battles-header-game .right-info svg {
        margin-right: 6px;
    }

    .battles-header-game .right-info.info-cursed.info-terminal svg:nth-child(2) {
        margin-right: 0;
    }

    .battles-header-game .info-option {
        margin-right: 10px;
    }

    .battles-header-game .right-info.info-cursed .info-option {
        color: #f24034;
    }

    .battles-header-game .right-info.info-terminal .info-option {
        background: linear-gradient(216deg, #ead621 0%, #ff8e26 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .battles-header-game button.button-fair,
    .battles-header-game button.button-link,
    .battles-header-game a.link-back {
        width: 44px;
        height: 44px;
        position: relative;
        padding: 1px;
        background: #0b2d49;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .battles-header-game button.button-fair::before,
    .battles-header-game button.button-link::before,
    .battles-header-game a.link-back::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #0f3759;
        font-size: 5px;
        clip-path: var(--octagonal-before);
    }

    .battles-header-game button.button-fair {
        background: #0d2d39;
    }
    .battles-header-game button.button-fair::before {
        background: #123f44;
    }

    .battles-header-game button.button-fair .button-inner,
    .battles-header-game button.button-link .button-inner,
    .battles-header-game a.link-back .link-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #6e95b6;
        transition: color 0.3s ease;
    }

    .battles-header-game button.button-fair:hover .button-inner,
    .battles-header-game button.button-link:hover .button-inner,
    .battles-header-game a.link-back:hover .link-inner {
        color: #ffffff;
    }

    .battles-header-game button.button-fair .button-inner svg,
    .battles-header-game button.button-link .button-inner svg,
    .battles-header-game a.link-back .link-inner svg {
        fill: #406992;
        transition: fill 0.3s ease;
    }

    .battles-header-game button.button-fair:hover .button-inner svg,
    .battles-header-game button.button-link:hover .button-inner svg,
    .battles-header-game a.link-back:hover .link-inner svg {
        fill: #ffffff;
    }

    .battles-header-game button.button-sound {
        width: 40px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: radial-gradient(
                70% 70% at 50% 50%,
                rgba(152, 151, 193, 0.2) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            #031b2e;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid #07243d;
    }

    .battles-header-game button.button-sound svg {
        fill: #bbbfd0;
        transition: fill 0.3s ease;
    }

    .battles-header-game button.button-sound.button-active svg {
        fill: #00ffc2;
    }

    @media screen and (max-width: 600px) {
        .battles-header-game {
            height: calc(62px + 27px + 51px + 10px);
        }

        .battles-header-game .info-running,
        .battles-header-game .info-text {
            position: absolute;
            bottom: 60px;
            width: 100%;
            left: 0;
        }

        .battles-header-game .game-flex,
        .battles-header-game .game-left {
            align-items: flex-end;
        }

        .battles-header-game .game-info {
            position: absolute;
            bottom: 0;
            left: 10px;
            width: calc(100% - 20px);
            max-width: 100%;
        }
    }
</style>
