var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[_vm.$style['cashier-item-element']],on:{"click":function($event){return _vm.itemButton()}}},[(_vm.itemIsActive)?_c('svg',{class:_vm.$style.check,attrs:{"width":"17","height":"17","viewBox":"0 0 17 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_287_6712)"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.5 0C3.81172 0 0 3.81172 0 8.5C0 13.1883 3.81172 17 8.5 17C13.1883 17 17 13.1883 17 8.5C17 3.81172 13.1883 0 8.5 0Z","fill":"url(#paint0_linear_287_6712)"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M12.6104 5.63455C12.8162 5.84041 12.8162 6.17908 12.6104 6.38494L7.62988 11.3654C7.52695 11.4683 7.39082 11.5215 7.25469 11.5215C7.11855 11.5215 6.98242 11.4683 6.87949 11.3654L4.38926 8.87517C4.1834 8.66931 4.1834 8.33064 4.38926 8.12478C4.59512 7.91892 4.93379 7.91892 5.13965 8.12478L7.25469 10.2398L11.86 5.63455C12.0658 5.42537 12.4045 5.42537 12.6104 5.63455Z","fill":"#16173A"}})]),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_287_6712","x1":"17","y1":"0","x2":"-2.96701","y2":"4.8934","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"#00FFC2"}}),_c('stop',{attrs:{"offset":"1","stop-color":"#00AA6D"}})],1),_c('clipPath',{attrs:{"id":"clip0_287_6712"}},[_c('rect',{attrs:{"width":"17","height":"17","fill":"white"}})])],1)]):_vm._e(),_c('UnboxItemElement',{attrs:{"item":{
            item: {
                amountFixed: _vm.item.amount,
                ..._vm.item
            },
            color: _vm.itemGetColor
        },"coinType":_vm.itemGetCoinType,"hoverable":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }