<template>
    <div
        :class="[
            $style['blackjack-table'],
            $style[blackjackTable?.game?.type === 'standard' ? 'standard' : 'whale']
        ]"
    >
        <div v-if="blackjackTable !== null" :class="$style['table-header']">
            <div :class="$style['header-left']">
                <router-link to="/blackjack" :class="$style['left-back']">
                    <svg
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.21864 3.60238C9.16544 3.59389 9.11161 3.58998 9.05775 3.59066H2.88651L3.08649 3.49916C3.28196 3.40815 3.45979 3.28428 3.61202 3.13316L5.18386 1.58689C5.52258 1.26881 5.5795 0.757122 5.31874 0.374506C5.01525 -0.0332154 4.43324 -0.121742 4.01874 0.176807C3.98526 0.200941 3.95343 0.227247 3.92352 0.255555L0.272801 3.84686C-0.0906526 4.204 -0.0909723 4.78334 0.272075 5.14088L0.272801 5.1416L3.92352 8.73291C4.28726 9.08973 4.87619 9.08893 5.23895 8.7311C5.26749 8.70294 5.29415 8.67297 5.31874 8.64141C5.5795 8.25879 5.52258 7.7471 5.18386 7.42902L3.61667 5.87818C3.4802 5.74379 3.3233 5.6311 3.1516 5.5442L2.87255 5.42068H9.00656C9.48172 5.43803 9.8986 5.11152 9.98786 4.65207C10.0701 4.15325 9.72571 3.68331 9.21864 3.60238Z"
                            fill="#406992"
                        />
                    </svg>
                </router-link>
                <div :class="$style['left-meta']">
                    <p :class="$style['meta-title']">
                        <svg
                            width="11"
                            height="16"
                            viewBox="0 0 11 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.94516 13.5723H8.3042L8.60583 12.7756L8.94516 13.5723ZM2.59212 1.26538C2.96915 2.15583 3.34619 3.04628 3.73265 3.93673C3.83633 4.20855 3.44045 4.37727 3.32734 4.10545L2.97858 3.29936H1.86632C1.78149 3.53368 1.67781 3.843 1.57412 4.07733C1.42331 4.41476 1.06512 4.18981 1.14996 3.9461C1.49871 3.04628 1.83805 2.15583 2.17738 1.256C2.25278 1.06854 2.52614 1.06854 2.59212 1.26538ZM10.1517 0H0.838903C0.377035 0 0 0.374927 0 0.843585V15.1564C0 15.6157 0.377035 16 0.838903 16H10.1517C10.623 16 11 15.6157 11 15.1564V0.843585C11 0.374927 10.623 0 10.1517 0ZM6.32476 4.89279C6.51328 4.89279 6.61697 5.10838 6.49443 5.24897C6.32476 5.49268 6.21165 6.17692 6.17395 6.43937C6.85261 6.43937 7.38046 6.6362 7.74807 7.24546C8.5587 8.61394 6.79606 10.1886 5.80634 10.9291C5.42931 11.2197 5.43873 11.126 5.10883 10.8448C4.36418 10.2168 2.94087 8.91388 3.02571 7.9297C3.11054 7.01113 3.92117 6.36438 4.82605 6.43937L4.81662 6.41125C4.8072 6.33626 4.64696 5.5208 4.54327 5.29584C4.49614 5.18336 4.32648 4.94903 4.62811 4.89279C4.92031 4.84593 5.95716 4.89279 6.32476 4.89279ZM7.44644 14.5847C7.76692 13.7598 8.07798 12.935 8.38903 12.1101C8.46444 11.9227 8.73779 11.9227 8.80377 12.1195C9.15253 12.9443 9.50129 13.7598 9.85004 14.5753C9.96315 14.8377 9.55784 15.0064 9.44473 14.744L9.13368 14.0129H8.13453C8.0497 14.2378 7.95544 14.5284 7.86118 14.744C7.7575 15.0064 7.34276 14.8565 7.44644 14.5847ZM2.79006 2.85882H2.03599L2.39417 1.9215L2.79006 2.85882Z"
                                fill="url(#paint0_linear_72_79)"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_72_79"
                                    x1="11.0178"
                                    y1="2.98206e-07"
                                    x2="-2.32031"
                                    y2="2.25096"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#00FFC2" />
                                    <stop offset="1" stop-color="#00AA6D" />
                                </linearGradient>
                            </defs>
                        </svg>
                        BLACKJACK
                    </p>
                    <p :class="$style['meta-info']">
                        Lobby {{ blackjackTable.table + 1 }} - Game ID {{ blackjackTable.game._id }}
                    </p>
                </div>
            </div>

            <div :class="$style['header-mid']">
                <p :class="$style['mid-type']">
                    <span
                        :class="
                            blackjackTable.game.type === 'standard' ? 'fade-green' : 'fade-gold'
                        "
                    >
                        {{ blackjackTable.game.type === 'standard' ? 'STANDARD' : 'WHALE' }}
                    </span>
                </p>
                <div :class="$style['mid-range']">
                    <p :class="$style['range-amount']">
                        <img
                            v-if="userBalanceData.type === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="userBalanceData.type === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                        {{ blackjackTable.game.type === 'standard' ? '25' : '2,500' }}
                        <span>.00</span>
                    </p>
                    <p :class="$style['range-dash']">-</p>
                    <p :class="$style['range-amount']">
                        <img
                            v-if="userBalanceData.type === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="userBalanceData.type === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                        {{ blackjackTable.game.type === 'standard' ? '15,000' : '35,000' }}
                        <span>.00</span>
                    </p>
                </div>
            </div>

            <div :class="$style['header-right']">
                <button v-on:click="modalsSetShow('BlackjackRules')" :class="$style['right-rules']">
                    <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.8576 9.8861H12.8631V2.09705C12.8631 0.936184 11.9068 0 10.721 0H2.03781C1.99956 0 1.96131 3.70553e-08 1.92305 0.0187237C0.737249 0.149789 -0.104291 1.17959 0.0104641 2.34046C0.12522 3.31409 0.909382 4.10048 1.92305 4.21283C1.96131 4.21283 1.99956 4.23155 2.03781 4.23155H2.15257V12.0206V12.0393C2.17169 13.1815 3.12799 14.1176 4.29467 14.1176H13.7046H13.762H13.8576C15.0434 14.1176 15.9997 13.1815 15.9997 12.0206C16.0189 10.8223 15.0434 9.8861 13.8576 9.8861ZM2.15257 3.08941V3.07068C1.59791 3.07068 1.15802 2.64004 1.15802 2.09705C1.15802 1.55406 1.59791 1.12342 2.15257 1.12342H8.82751C8.50237 1.7413 8.50237 2.47152 8.82751 3.08941H2.15257ZM11.333 10.7474H3.49138V9.62397H11.333V10.7474ZM11.333 8.50055H3.49138V7.37713H11.333V8.50055ZM11.333 6.25371H3.49138V5.13029H11.333V6.25371ZM13.8767 12.9568C13.3221 12.9568 12.8822 12.5261 12.8822 11.9831V11.0095H13.8767C14.4314 11.0095 14.8713 11.4402 14.8713 11.9831C14.8713 12.5261 14.4314 12.9568 13.8767 12.9568Z"
                            fill="#5B85AE"
                        />
                    </svg>
                    <span>Game Rules</span>
                </button>
            </div>
        </div>

        <BlackjackGame v-if="blackjackTable !== null" v-bind:table="blackjackTable" />
        <Bets />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconInfo from '@/components/icons/IconInfo';
    import Bets from '@/components/bets/Bets';
    import BlackjackGame from '@/components/blackjack/BlackjackGame';

    export default {
        name: 'Blackjack',
        metaInfo: {
            title: 'Blackjack - Rollbet.gg',
            meta: [
                {
                    name: 'description',
                    content: 'Rollbet.gg the leading roblox gambling experience.'
                }
            ]
        },
        components: {
            IconInfo,
            Bets,
            BlackjackGame
        },
        data() {
            return {
                blackjackTable: null,
                visualCoinType: 'cryptoCoin'
            };
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        },
        computed: {
            ...mapGetters(['socketBlackjack', 'blackjackTables', 'userBalanceData'])
        },
        watch: {
            blackjackTables: {
                handler(data, oldData) {
                    if (this.blackjackTables.length >= 1) {
                        this.blackjackTable = this.blackjackTables[this.$route.params.tableId - 1];
                    }
                },
                deep: true
            }
        },
        created() {
            if (this.blackjackTables.length >= 1) {
                this.blackjackTable = this.blackjackTables[this.$route.params.tableId - 1];
            }
        }
    };
</script>

<style module>
    .blackjack-table {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 10px;
    }

    .blackjack-table .table-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 14px;
        max-width: 1153px;
        height: 51px;
        position: relative;
    }

    .blackjack-table .header-left,
    .blackjack-table .header-right {
        align-items: center;
        display: flex;
        gap: 14px;
        flex: 1;
    }

    .blackjack-table .header-left {
        justify-content: flex-start;
    }

    .blackjack-table .header-right {
        justify-content: flex-end;
    }

    .blackjack-table .right-rules,
    .blackjack-table .mid-range,
    .blackjack-table .left-back {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        position: relative;
        clip-path: var(--octagonal-main);
        font-size: 5px;
    }

    .blackjack-table .right-rules::before,
    .blackjack-table .mid-range::before,
    .blackjack-table .left-back::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-before);
        font-size: 5px;
    }

    .blackjack-table .left-back::before {
        background: #12385e;
    }

    .blackjack-table .left-back {
        background: linear-gradient(
            360deg,
            rgba(15, 66, 107, 0.35) 8.33%,
            rgba(13, 51, 85, 0.35) 108.33%
        );
        height: 44px;
        width: 44px;
        min-height: 44px;
        min-width: 44px;
    }

    .blackjack-table .left-meta {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

    .blackjack-table .meta-title {
        font-size: 16px;
        font-weight: 700;
        color: white;
        white-space: nowrap;
    }

    .blackjack-table .meta-title svg {
        margin-bottom: -3px;
        margin-right: 4px;
    }

    .blackjack-table .meta-info {
        height: 20px;
        font-size: 15px;
        color: #84a1b8;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .blackjack-table .header-mid {
        display: flex;
        gap: 2px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 51px;
    }

    .blackjack-table .mid-type {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
    }

    .blackjack-table .mid-range {
        min-width: 184px;
        padding: 0px 14px;
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.71deg,
                rgba(0, 0, 0, 0.128) -3.28%,
                rgba(0, 33, 24, 0.132) 21.81%,
                rgba(0, 99, 73, 0.144986) 61.24%,
                rgba(1, 193, 143, 0.0925409) 80.82%,
                rgba(1, 237, 176, 0.068) 99.26%
            );
        font-size: 3px;
        padding: 1px 14px 2px 14px;
        height: 26px;
        width: fit-content;
    }

    .blackjack-table .mid-range::before {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 28.53%,
            rgba(30, 255, 194, 0.35) 91.03%
        );
        font-size: 3px;
    }

    .blackjack-table.whale .mid-range {
        background: linear-gradient(0deg, #293131, #2a383d, #273429);
    }

    .blackjack-table.whale .mid-range::before {
        background: linear-gradient(
            180deg,
            rgba(6, 36, 61, 0) 17.86%,
            rgba(235, 184, 55, 0.74) 100%
        );
    }

    .blackjack-table .range-amount {
        font-size: 14px;
        font-weight: 600;
        color: white;
        position: relative;
        white-space: nowrap;
    }

    .blackjack-table .range-dash {
        font-size: 14px;
        color: white;
    }

    .blackjack-table .range-amount span {
        font-size: 10px;
        font-weight: 500;
        opacity: 0.75;
    }

    .blackjack-table .range-amount img {
        width: 12px;
        height: 16px;
        margin-right: 3px;
        margin-bottom: -4px;
    }

    .blackjack-table .right-rules {
        background: #0d2d47;
        padding: 0 14px;
        color: #5b85ae;
        font-weight: 600;
        height: 40px;
        min-width: 40px;
    }

    .blackjack-table .right-rules span {
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 800px) {
        .blackjack-table .table-header {
            height: calc(44px + 51px + 20px + 14px);
            margin-bottom: -4px;
            align-items: flex-start;
        }

        .blackjack-table .meta-info {
            transform: translate(-50%, 0%);
            position: absolute;
            text-align: center;
            width: 100%;
            bottom: 0;
            left: 50%;
        }

        .blackjack-table .header-mid {
            transform: translate(-50%, 0%);
            bottom: calc(20px + 7px);
            position: absolute;
            left: 50%;
        }
    }
</style>
