const state = {
    userTransactionsData: {
        transactions: null,
        count: null,
        loading: false,
        page: 1
    },
    userBetsData: {
        bets: null,
        count: null,
        loading: false,
        page: 1
    },
    userSeedData: {
        data: null,
        loading: false
    },
    userBalanceData: {
        type: localStorage.getItem('lastUsedCoin') === 'robuxCoin' ? 'robuxCoin' : 'cryptoCoin'
    },
    userNotifications: []
};

const getters = {
    userTransactionsData: (state) => state.userTransactionsData,
    userBetsData: (state) => state.userBetsData,
    userSeedData: (state) => state.userSeedData,
    userBalanceData: (state) => state.userBalanceData,
    userNotifications: (state) => state.userNotifications
};

const mutations = {
    user_set_transactions_data(state, data) {
        state.userTransactionsData.transactions = data.transactions;
        state.userTransactionsData.count = data.count;
    },
    user_set_transactions_data_loading(state, status) {
        state.userTransactionsData.loading = status;
    },
    user_set_transactions_data_page(state, page) {
        state.userTransactionsData.page = page;
    },
    user_set_bets_data(state, data) {
        state.userBetsData.bets = data.bets;
        state.userBetsData.count = data.count;
    },
    user_set_bets_data_loading(state, status) {
        state.userBetsData.loading = status;
    },
    user_set_bets_data_page(state, page) {
        state.userBetsData.page = page;
    },
    user_set_seed_data(state, data) {
        state.userSeedData.data = { seed: data.seed, seedNext: data.seedNext };
    },
    user_set_seed_data_loading(state, status) {
        state.userSeedData.loading = status;
    },
    user_set_balance_data(state, data) {
        state.userBalanceData = data;
    },
    user_set_notifications(state, data) {
        state.userNotifications = data;
    }
};

const actions = {
    userSetTransactionsDataPage({ commit }, page) {
        commit('user_set_transactions_data_page', page);
    },
    userSetBetsDataPage({ commit }, page) {
        commit('user_set_bets_data_page', page);
    },
    userTipSocket({ dispatch }, data) {
        dispatch('notificationShow', {
            type: 'success',
            message:
                "You've been tipped " +
                (Math.floor(data.transaction.amount / 10) / 100).toFixed(2) +
                ' ' +
                data.transaction.coinType.split('Coin')[0].charAt(0).toUpperCase() +
                data.transaction.coinType.split('Coin')[0].slice(1) +
                ' Coin'
        });
    },
    userGetNotifications({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null) {
            return;
        }

        getters.socketGeneral.emit('getUserNotifications', data, (res) => {
            if (res.success === true) {
                commit('user_set_notifications', res.notifications);
            } else {
                dispatch('notificationShow', res.error);
            }
        });
    },
    userGetTransactionsSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.userTransactionsData.loading === true) {
            return;
        }
        commit('user_set_transactions_data_loading', true);

        getters.socketGeneral.emit('getUserTransactions', data, (res) => {
            if (res.success === true) {
                commit('user_set_transactions_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('user_set_transactions_data_loading', false);
        });
    },
    userGetBetsSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.userBetsData.loading === true) {
            return;
        }
        commit('user_set_bets_data_loading', true);

        getters.socketGeneral.emit('getUserBets', data, (res) => {
            if (res.success === true) {
                commit('user_set_bets_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('user_set_bets_data_loading', false);
        });
    },
    userGetSeedSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.userSeedData.loading === true) {
            return;
        }
        commit('user_set_seed_data_loading', true);

        getters.socketGeneral.emit('getUserSeed', data, (res) => {
            if (res.success === true) {
                commit('user_set_seed_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('user_set_seed_data_loading', false);
        });
    },
    userSendUserAnonymousSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.socketSendLoading !== null) {
            return;
        }
        commit('socket_set_send_loading', 'UserAnonymous');

        getters.socketGeneral.emit('sendUserAnonymous', data, (res) => {
            if (res.success === true) {
                commit('auth_set_user_anonymous', res.anonymous);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    userSendUserDiscordSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.socketSendLoading !== null) {
            return;
        }
        commit('socket_set_send_loading', 'UserDiscord');

        getters.socketGeneral.emit('sendUserDiscord', data, (res) => {
            if (res.success === true) {
                commit('auth_set_user', res.user);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    userSendUserRobloxSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.socketSendLoading !== null) {
            return;
        }
        commit('socket_set_send_loading', 'UserRoblox');

        getters.socketGeneral.emit('sendUserRobloxUnlink', data, (res) => {
            if (res.success === true) {
                commit('auth_set_user', res.user);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    userSendSeedSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.socketSendLoading !== null) {
            return;
        }
        commit('socket_set_send_loading', 'UserSeed');

        getters.socketGeneral.emit('sendUserSeed', data, (res) => {
            if (res.success === true) {
                commit('user_set_seed_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    userSendUserTipSocket({ getters, commit, dispatch }, data) {
        if (getters.socketGeneral === null || getters.socketSendLoading !== null) {
            return;
        }
        commit('socket_set_send_loading', 'UserTip');

        getters.socketGeneral.emit('sendUserTip', data, (res) => {
            if (res.success === true) {
                dispatch('modalsSetShow', null);

                dispatch('notificationShow', {
                    type: 'success',
                    message:
                        "You've successfully tipped " +
                        (Math.floor(res.transaction.amount / 10) / 100).toFixed(2) +
                        ' ' +
                        data.type.split('Coin')[0].charAt(0).toUpperCase() +
                        data.type.split('Coin')[0].slice(1) +
                        ' Coin'
                });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    userSetBalanceType({ commit }, type) {
        localStorage.setItem('lastUsedCoin', type);
        commit('user_set_balance_data', { type });
    },
    userSetNotification({ getters, commit, rootState }, data) {
        if (data.document?.data === undefined) return;
        if (data.read !== true && data.deleted !== true) {
            rootState.sound.soundNotification.volume = rootState.sound.soundVolume;
            rootState.sound.soundNotification.currentTime = 0;
            rootState.sound.soundNotification.play();
        }

        commit('user_set_notifications', [
            ...getters.userNotifications.filter((item) => item !== data._id),
            data
        ]);
    },
    userSetNotifications({ commit }, data) {
        commit('user_set_notifications', data);
    },
    userSendNotificationAction({ getters, commit, dispatch }, { action, notification }) {
        if (getters.socketGeneral === null) {
            return;
        }

        const notifications = [notification].flat();
        const ids = notifications.map((stuff) => stuff._id);

        commit(
            'user_set_notifications',
            getters.userNotifications.map((item) =>
                ids.includes(item._id) === true
                    ? { ...item, [action === 'delete' ? 'deleted' : 'read']: true }
                    : item
            )
        );

        getters.socketGeneral.emit(
            'sendUserNotificationAction',
            { action, notificationId: ids },
            (res) => {
                if (res.success === true) {
                } else {
                    commit('user_set_notifications', [
                        ...notifications,
                        ...getters.userNotifications.filter(
                            (item) => ids.includes(item._id) !== true
                        )
                    ]);
                }

                commit('socket_set_send_loading', null);
            }
        );
    }
};

const user = {
    state,
    mutations,
    actions,
    getters
};

export default user;
