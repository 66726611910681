<template>
    <nav
        :class="[
            $style.navbar,
            { [$style.auth]: authUser.user !== null, a: authUser.user !== null }
        ]"
    >
        <div :class="$style['navbar-content']">
            <router-link to="/" :class="$style['link-logo']">
                <img src="@/assets/img/logo.png" alt="Rollbet" height="34" />
                <img src="/chip.png" alt="Rollbet" height="34" />
            </router-link>
            <NavbarHomeDropdown />
            <NavbarRewards v-if="authUser.user !== null" />
            <NavbarCashier v-if="authUser.user !== null" />
            <NavbarNotifications v-if="authUser.user !== null" />
            <AuthButton v-else />
            <button
                class="octagonal before after hoverable"
                :class="[
                    $style['content-chat'],
                    { [$style.active]: generalSidebarMobile === 'Chat' }
                ]"
                v-on:click="
                    generalSetSidebarMobile(generalSidebarMobile === 'Chat' ? null : 'Chat')
                "
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm7.194 2.766a1.7 1.7 0 0 0-.227-.272 1.5 1.5 0 0 0-.469-.324l-.008-.004A1.8 1.8 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.5 2.5 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.5 2.5 0 0 0-.228-.4 1.7 1.7 0 0 0-.227-.273 1.5 1.5 0 0 0-.469-.324l-.008-.004A1.8 1.8 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"
                    ></path>
                </svg>
                <p v-if="generalRain.active !== null" :class="$style['chat-alert']">!</p>
            </button>
            <NavbarUserDropdown v-if="authUser.user !== null" />
        </div>
    </nav>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AuthButton from '@/components/AuthButton';
    import NavbarHomeDropdown from '@/components/navbar/NavbarHomeDropdown';
    import NavbarCashier from '@/components/navbar/NavbarCashier';
    import NavbarUserDropdown from '@/components/navbar/NavbarUserDropdown';
    import NavbarRewards from '@/components/navbar/NavbarRewards';
    import NavbarNotifications from '@/components/navbar/NavbarNotifications';

    export default {
        name: 'Navbar',
        components: {
            AuthButton,
            NavbarHomeDropdown,
            NavbarCashier,
            NavbarUserDropdown,
            NavbarRewards,
            NavbarNotifications
        },
        methods: {
            ...mapActions(['generalSetSidebarMobile'])
        },
        computed: {
            ...mapGetters(['authUser', 'generalSidebarMobile', 'generalRain'])
        }
    };
</script>

<style module>
    .navbar {
        max-width: 100vw;
        width: calc(100% - 325px);
        height: 84px;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, #091e31, #051626);
        border-bottom: 1px solid #0e2843;
        padding: 10px;
        z-index: 51;
    }

    .navbar .navbar-content {
        max-width: 1495px;
        width: 100%;
        padding-top: 0 10px;
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .navbar .link-logo {
        height: 34px;
    }

    .navbar .link-logo img:last-child {
        display: none;
    }

    @media screen and (max-width: 1500px) {
        .navbar {
            width: 100vw;
            height: 72px;
        }
    }

    @media screen and (max-width: 1100px) {
        .navbar .navbar-content {
            gap: 10px;
        }
    }

    @media screen and (max-width: 625px) {
        .navbar.auth .link-logo img:first-child {
            display: none;
        }
        .navbar.auth .link-logo img:last-child {
            display: block;
        }
    }

    .navbar .content-chat {
        color: #537088;
        min-width: 44px;
        height: 44px;
        font-size: 16px;
        display: none;
        align-items: center;
        justify-content: center;
        margin-left: -15px;
    }

    .navbar .content-chat::before,
    .navbar .content-chat::after {
        font-size: 3px;
        background: #182c40;
    }

    .navbar .content-chat .chat-alert {
        justify-content: center;
        background: #cb3434;
        align-items: center;
        position: absolute;
        border-radius: 8px;
        font-weight: 700;
        font-size: 12px;
        color: white;
        display: flex;
        height: 17px;
        width: 17px;
        right: -7px;
        z-index: 1;
        top: -3px;
    }

    @media screen and (max-width: 1500px) {
        .navbar .content-chat {
            display: flex;
        }
    }
    @media screen and (max-width: 1100px) {
        .navbar .content-chat {
            margin-left: -5px;
        }
    }
</style>
