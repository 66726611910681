<template>
    <router-link
        v-bind:to="'/blackjack/' + (table.table + 1)"
        :class="[
            $style['blackjack-tables-element'],
            $style[table.game.type === 'standard' ? 'standard' : 'whale']
        ]"
    >
        <div :class="$style['element-table']"></div>
        <div :class="$style['element-users']">
            <span :class="{ 'fade-gold': table.game.type !== 'standard' }">
                {{ table.players.length }}/
            </span>
            <span>5</span>
        </div>
        <div :class="$style['element-title']">
            <svg
                v-if="table.game.type === 'standard'"
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.13196 11.8758H7.54927L7.82348 11.1787L8.13196 11.8758ZM2.35647 1.10721C2.69923 1.88635 3.04199 2.66549 3.39332 3.44464C3.48758 3.68248 3.12768 3.83011 3.02485 3.59227L2.7078 2.88694H1.69666C1.61954 3.09197 1.52528 3.36262 1.43102 3.56766C1.29392 3.86292 0.968295 3.66608 1.04542 3.45284C1.36247 2.66549 1.67095 1.88635 1.97943 1.099C2.04799 0.934974 2.29649 0.934974 2.35647 1.10721ZM9.22879 0H0.762639C0.342759 0 0 0.328061 0 0.738137V13.2619C0 13.6637 0.342759 14 0.762639 14H9.22879C9.65724 14 10 13.6637 10 13.2619V0.738137C10 0.328061 9.65724 0 9.22879 0ZM5.74979 4.28119C5.92117 4.28119 6.01542 4.46983 5.90403 4.59285C5.74979 4.80609 5.64696 5.4048 5.61268 5.63445C6.22965 5.63445 6.70951 5.80668 7.0437 6.33978C7.78063 7.5372 6.17824 8.91506 5.27849 9.56298C4.93573 9.81722 4.9443 9.73521 4.64439 9.48916C3.96744 8.93966 2.67352 7.79965 2.75064 6.93849C2.82776 6.13474 3.5647 5.56883 4.38732 5.63445L4.37875 5.60984C4.37018 5.54423 4.22451 4.8307 4.13025 4.63386C4.0874 4.53544 3.93316 4.3304 4.20737 4.28119C4.47301 4.24019 5.4156 4.28119 5.74979 4.28119ZM6.7695 12.7616C7.06084 12.0398 7.34362 11.3181 7.62639 10.5964C7.69494 10.4323 7.94345 10.4323 8.00343 10.6046C8.32048 11.3263 8.63753 12.0398 8.95459 12.7534C9.05741 12.983 8.68895 13.1306 8.58612 12.901L8.30334 12.2613H7.39503C7.31791 12.4581 7.23222 12.7124 7.14653 12.901C7.05227 13.1306 6.67524 12.9994 6.7695 12.7616ZM2.53642 2.50146H1.8509L2.17652 1.68131L2.53642 2.50146Z"
                    fill="url(#paint0_linear_72_1841)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_72_1841"
                        x1="10.0162"
                        y1="2.6093e-07"
                        x2="-2.08275"
                        y2="2.12139"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                </defs>
            </svg>
            <svg
                v-else
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.13196 11.8758H7.54927L7.82348 11.1787L8.13196 11.8758ZM2.35647 1.10721C2.69923 1.88635 3.04199 2.66549 3.39332 3.44464C3.48758 3.68248 3.12768 3.83011 3.02485 3.59227L2.7078 2.88694H1.69666C1.61954 3.09197 1.52528 3.36262 1.43102 3.56766C1.29392 3.86292 0.968295 3.66608 1.04542 3.45284C1.36247 2.66549 1.67095 1.88635 1.97943 1.099C2.04799 0.934974 2.29649 0.934974 2.35647 1.10721ZM9.22879 0H0.762639C0.342759 0 0 0.328061 0 0.738137V13.2619C0 13.6637 0.342759 14 0.762639 14H9.22879C9.65724 14 10 13.6637 10 13.2619V0.738137C10 0.328061 9.65724 0 9.22879 0ZM5.74979 4.28119C5.92117 4.28119 6.01542 4.46983 5.90403 4.59285C5.74979 4.80609 5.64696 5.4048 5.61268 5.63445C6.22965 5.63445 6.70951 5.80668 7.0437 6.33978C7.78063 7.5372 6.17824 8.91506 5.27849 9.56298C4.93573 9.81722 4.9443 9.73521 4.64439 9.48916C3.96744 8.93966 2.67352 7.79965 2.75064 6.93849C2.82776 6.13474 3.5647 5.56883 4.38732 5.63445L4.37875 5.60984C4.37018 5.54423 4.22451 4.8307 4.13025 4.63386C4.0874 4.53544 3.93316 4.3304 4.20737 4.28119C4.47301 4.24019 5.4156 4.28119 5.74979 4.28119ZM6.7695 12.7616C7.06084 12.0398 7.34362 11.3181 7.62639 10.5964C7.69494 10.4323 7.94345 10.4323 8.00343 10.6046C8.32048 11.3263 8.63753 12.0398 8.95459 12.7534C9.05741 12.983 8.68895 13.1306 8.58612 12.901L8.30334 12.2613H7.39503C7.31791 12.4581 7.23222 12.7124 7.14653 12.901C7.05227 13.1306 6.67524 12.9994 6.7695 12.7616ZM2.53642 2.50146H1.8509L2.17652 1.68131L2.53642 2.50146Z"
                    fill="url(#paint0_linear_72_1848)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_72_1848"
                        x1="6.42857"
                        y1="12.6875"
                        x2="9.30494"
                        y2="6.2101"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FFB547" />
                        <stop offset="0.764754" stop-color="#FFEEA6" />
                        <stop offset="1" stop-color="#F1CC0A" />
                    </linearGradient>
                </defs>
            </svg>
            <span>Lobby {{ table.table + 1 }}</span>
        </div>
    </router-link>
</template>

<script>
    import IconUsers from '@/components/icons/IconUsers';

    export default {
        name: 'BlackjackTablesElement',
        props: ['table'],
        components: {
            IconUsers
        }
    };
</script>

<style module>
    .blackjack-tables-element {
        appearance: none;
        aspect-ratio: 283 / 167;
        width: 100%;
        position: relative;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        clip-path: var(--octagonal-main);
    }

    .blackjack-tables-element .element-table,
    .blackjack-tables-element::before,
    .blackjack-tables-element::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        font-size: 20px;
        z-index: -2;
    }

    .blackjack-tables-element .element-table {
        background-image: url('~@/assets/img/blackjack/preview.webp');
        background-position: 50% 70%;
        background-repeat: no-repeat;
        background-size: 95% auto;
        z-index: -1;
    }

    .blackjack-tables-element:focus .element-table,
    .blackjack-tables-element:focus .element-users,
    .blackjack-tables-element:focus .element-title,
    .blackjack-tables-element:hover .element-table,
    .blackjack-tables-element:hover .element-users,
    .blackjack-tables-element:hover .element-title {
        filter: brightness(1.5);
    }

    .blackjack-tables-element::after {
        background: linear-gradient(45deg, #081b2ebb -50%, #0e263e99, #071b2cbb 150%);
        clip-path: var(--octagonal-after);
    }

    .blackjack-tables-element::before {
        background: linear-gradient(0deg, #1d486b99, transparent 75%);
        clip-path: var(--octagonal-before);
    }

    .blackjack-tables-element.whale::before {
        background: linear-gradient(
            180deg,
            rgba(255, 235, 159, 0.5) 17.86%,
            rgba(255, 182, 71, 0.5) 100%
        );
    }

    .blackjack-tables-element.whale::after {
        background: linear-gradient(0deg, rgba(255, 184, 0, 0.05), rgba(255, 184, 0, 0.05));
    }

    .blackjack-tables-element .element-title,
    .blackjack-tables-element .element-users {
        position: relative;
        display: flex;
        padding: 1px;
        align-items: center;
        justify-content: center;
        width: fit-content;
        font-weight: 600;
        color: white;
    }

    .blackjack-tables-element .element-title > * {
        position: relative;
        z-index: 1;
    }
    .blackjack-tables-element .element-users > * {
        position: relative;
        z-index: 1;
    }

    .blackjack-tables-element .element-users {
        font-size: 14px;
        height: 33px;
        width: 33px;
        margin-top: 15px;
        margin-left: 15px;
    }
    .blackjack-tables-element .element-users span:last-child {
        opacity: 0.5;
    }
    .blackjack-tables-element.whale .element-users span:last-child {
        color: #ffb547;
    }

    .blackjack-tables-element .element-users::after,
    .blackjack-tables-element .element-users::before {
        background: #03121fe5;
    }

    .blackjack-tables-element .element-title {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;
        margin: 0 auto 10px auto;
        height: 27px;
        max-width: 107px;
        font-size: 15px;
    }

    .blackjack-tables-element .element-title span {
        margin-top: -1px;
    }

    .blackjack-tables-element .element-title::before {
        background: #16426799;
    }

    .blackjack-tables-element .element-title::after {
        background: linear-gradient(
            360deg,
            rgba(15, 66, 107, 0.35) 8.33%,
            rgba(13, 51, 85, 0.35) 108.33%
        );
    }

    .blackjack-tables-element.whale .element-users::before,
    .blackjack-tables-element.whale .element-title::before {
        background: linear-gradient(
            180deg,
            rgba(6, 36, 61, 0) 17.86%,
            rgba(235, 184, 55, 0.5) 100%
        );
    }

    .blackjack-tables-element.whale .element-users::after,
    .blackjack-tables-element.whale .element-title::after {
        background: linear-gradient(
            17.6deg,
            rgba(255, 181, 71, 0.15) 22.6%,
            rgba(255, 238, 166, 0.15) 54.76%,
            rgba(241, 204, 10, 0.15) 64.65%
        );
    }

    .blackjack-tables-element .element-title::before,
    .blackjack-tables-element .element-users::before,
    .blackjack-tables-element .element-title::after,
    .blackjack-tables-element .element-users::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 3px;
    }

    .blackjack-tables-element .element-users::before,
    .blackjack-tables-element .element-title::before {
        clip-path: var(--octagonal-before);
    }
    .blackjack-tables-element .element-users::after,
    .blackjack-tables-element .element-title::after {
        clip-path: var(--octagonal-after);
    }
</style>
