var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['blackjack-value'],
        {
            [_vm.$style['value-won']]: _vm.state === 'won',
            [_vm.$style['value-lose']]: _vm.state === 'lose',
            [_vm.$style.gold]: _vm.coinType === 'robuxCoin',
            [_vm.$style.green]: _vm.coinType === 'cryptoCoin'
        }
    ]},[_c('div',{class:_vm.$style['value-inner']},[_c('span',[_vm._v(_vm._s(_vm.value))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }