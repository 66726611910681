<template>
    <div
        v-bind:class="[
            $style['box-level'],
            $style['level-' + computedColor],
            $style['level-' + rank],
            { [$style['level-vip']]: computedLevel > 90 }
        ]"
    >
        <div :class="$style['level-inner']">
            <svg
                v-if="computedLevel > 90"
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.9367 3.23973L10.748 2.55983C10.5444 2.9125 10.2437 3.14223 9.84636 3.2487C9.44885 3.35521 9.07383 3.30653 8.72116 3.10292C8.36826 2.89926 8.13879 2.59871 8.03227 2.2012C7.92576 1.80367 7.97439 1.4286 8.178 1.0758L6.99675 0.393906C6.76349 0.259456 6.51413 0.227743 6.24846 0.298927C5.98264 0.370153 5.78255 0.522328 5.64794 0.755317L0.697855 9.31821C0.563248 9.5514 0.531611 9.80095 0.602768 10.0665C0.67398 10.3323 0.826096 10.5324 1.05927 10.667L2.24241 11.3565C2.44613 11.0038 2.74669 10.7741 3.14414 10.6676C3.54157 10.5611 3.91659 10.6096 4.26939 10.8134C4.62209 11.017 4.85173 11.3176 4.95824 11.715C5.06474 12.1125 5.01617 12.4874 4.81251 12.8403L6.00315 13.5277C6.23627 13.6623 6.48573 13.6939 6.75153 13.6227C7.01735 13.5515 7.21756 13.3993 7.35203 13.1663L12.2981 4.58852C12.4327 4.35545 12.4643 4.10608 12.393 3.84026C12.3218 3.57447 12.1697 3.37434 11.9367 3.23973ZM10.3164 5.2713L6.94495 11.1107C6.87964 11.2242 6.77981 11.2988 6.6456 11.3348C6.51152 11.3707 6.38778 11.356 6.27435 11.2904L2.85375 9.31573C2.73402 9.24651 2.65691 9.14741 2.62233 9.01833C2.58771 8.88915 2.6049 8.76462 2.674 8.64488L6.04541 2.80542C6.11095 2.69199 6.2108 2.61725 6.34496 2.58131C6.47897 2.5454 6.60278 2.5602 6.71625 2.62567L10.1367 4.60055C10.2565 4.66969 10.3337 4.76885 10.3683 4.898C10.4028 5.02714 10.3856 5.15154 10.3164 5.2713Z"
                    fill="black"
                />
                <path
                    d="M11.9367 3.23973L10.748 2.55983C10.5444 2.9125 10.2437 3.14223 9.84636 3.2487C9.44885 3.35521 9.07383 3.30653 8.72116 3.10292C8.36826 2.89926 8.13879 2.59871 8.03227 2.2012C7.92576 1.80367 7.97439 1.4286 8.178 1.0758L6.99675 0.393906C6.76349 0.259456 6.51413 0.227743 6.24846 0.298927C5.98264 0.370153 5.78255 0.522328 5.64794 0.755317L0.697855 9.31821C0.563248 9.5514 0.531611 9.80095 0.602768 10.0665C0.67398 10.3323 0.826096 10.5324 1.05927 10.667L2.24241 11.3565C2.44613 11.0038 2.74669 10.7741 3.14414 10.6676C3.54157 10.5611 3.91659 10.6096 4.26939 10.8134C4.62209 11.017 4.85173 11.3176 4.95824 11.715C5.06474 12.1125 5.01617 12.4874 4.81251 12.8403L6.00315 13.5277C6.23627 13.6623 6.48573 13.6939 6.75153 13.6227C7.01735 13.5515 7.21756 13.3993 7.35203 13.1663L12.2981 4.58852C12.4327 4.35545 12.4643 4.10608 12.393 3.84026C12.3218 3.57447 12.1697 3.37434 11.9367 3.23973ZM10.3164 5.2713L6.94495 11.1107C6.87964 11.2242 6.77981 11.2988 6.6456 11.3348C6.51152 11.3707 6.38778 11.356 6.27435 11.2904L2.85375 9.31573C2.73402 9.24651 2.65691 9.14741 2.62233 9.01833C2.58771 8.88915 2.6049 8.76462 2.674 8.64488L6.04541 2.80542C6.11095 2.69199 6.2108 2.61725 6.34496 2.58131C6.47897 2.5454 6.60278 2.5602 6.71625 2.62567L10.1367 4.60055C10.2565 4.66969 10.3337 4.76885 10.3683 4.898C10.4028 5.02714 10.3856 5.15154 10.3164 5.2713Z"
                    fill="url(#paint0_linear_228_277)"
                />
                <path
                    d="M6.56283 3.39394L9.54878 5.11778L6.42834 10.5226L3.44251 8.79871L6.56283 3.39394Z"
                    fill="black"
                />
                <path
                    d="M6.56283 3.39394L9.54878 5.11778L6.42834 10.5226L3.44251 8.79871L6.56283 3.39394Z"
                    fill="url(#paint1_linear_228_277)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_228_277"
                        x1="9.66065"
                        y1="11.5815"
                        x2="9.97182"
                        y2="4.72476"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FFB547" />
                        <stop offset="0.764754" stop-color="#FFEEA6" />
                        <stop offset="1" stop-color="#F1CC0A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_228_277"
                        x1="9.66065"
                        y1="11.5815"
                        x2="9.97182"
                        y2="4.72476"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FFB547" />
                        <stop offset="0.764754" stop-color="#FFEEA6" />
                        <stop offset="1" stop-color="#F1CC0A" />
                    </linearGradient>
                </defs>
            </svg>
            {{ computedLevel }}
        </div>
    </div>
</template>

<script>
    import { getLevelFromXP, getColorFromLevel } from '@/utils/user';

    export default {
        name: 'BoxLevel',
        props: ['level', 'xp', 'color', 'rank'],
        computed: {
            computedLevel() {
                return this.level || getLevelFromXP(this.xp) || 0;
            },
            computedColor() {
                return this.color || getColorFromLevel(this.computedLevel);
            }
        }
    };
</script>

<style module>
    .box-level {
        min-width: 25px;
        height: 25px;
        position: relative;
    }

    .box-level::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #9e9e9e 100%);
        clip-path: var(--octagonal-before);
        font-size: 3px;
    }

    .box-level.level-blue::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #559ee4 100%);
    }

    .box-level.level-sky::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #01bcf9 100%);
    }

    .box-level.level-yellow::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #f9c201 100%);
    }

    .box-level.level-purple::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #6562ff 100%);
    }

    .box-level.level-gold::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #ffbe18 100%);
    }

    .box-level.level-vip::before {
        background: linear-gradient(0deg, #ffbe18, #554f31);
    }

    .box-level::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #063141;
        clip-path: var(--octagonal-after);
        font-size: 3px;
    }

    .box-level::after {
        background: #9e9e9e11;
    }

    .box-level.level-blue::after {
        background: #559ee411;
    }

    .box-level.level-sky::after {
        background: #01bcf933;
    }

    .box-level.level-yellow::after {
        background: #f9c20133;
    }

    .box-level.level-purple::after {
        background: #6562ff33;
    }

    .box-level.level-gold::after {
        background: linear-gradient(0deg, #efa83d33, #fff62833);
    }

    .box-level.level-vip::after {
        background: linear-gradient(0deg, #efec3d63, #fff62833);
    }

    .box-level .level-inner {
        display: flex;
        height: 100%;
        justify-content: center;
        position: relative;
        align-items: center;
        padding: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #9e9e9e;
        z-index: 1;
    }

    .box-level .level-inner svg {
        margin-right: 3px;
    }

    .box-level.level-blue .level-inner {
        color: #559ee4;
    }

    .box-level.level-sky .level-inner {
        color: #01bcf9;
    }

    .box-level.level-yellow .level-inner {
        color: #f9c201;
    }

    .box-level.level-purple .level-inner {
        color: #6562ff;
    }

    .box-level.level-gold .level-inner {
        color: #ffbe18;
    }

    .box-level.level-vip .level-inner {
        color: #f1cc0a;
    }
</style>
