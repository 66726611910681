<template>
    <button
        v-on:click="cashierItemButton()"
        v-bind:class="[
            $style['cashier-item-element'],
            {
                [$style.active]: cashierIsActive,
                [$style.bundle]: cashierIsBundle
            }
        ]"
        v-bind:disabled="disabled"
    >
        <svg
            v-if="item.user === authUser.user._id"
            :class="$style.user"
            stroke="#00A3FF"
            fill="#00A3FF"
            stroke-width="0"
            viewBox="0 0 496 512"
            height="17"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
            ></path>
        </svg>
        <svg
            v-else-if="cashierIsActive"
            :class="$style.check"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_287_6712)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.5 0C3.81172 0 0 3.81172 0 8.5C0 13.1883 3.81172 17 8.5 17C13.1883 17 17 13.1883 17 8.5C17 3.81172 13.1883 0 8.5 0Z"
                    fill="url(#paint0_linear_287_6712)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6104 5.63455C12.8162 5.84041 12.8162 6.17908 12.6104 6.38494L7.62988 11.3654C7.52695 11.4683 7.39082 11.5215 7.25469 11.5215C7.11855 11.5215 6.98242 11.4683 6.87949 11.3654L4.38926 8.87517C4.1834 8.66931 4.1834 8.33064 4.38926 8.12478C4.59512 7.91892 4.93379 7.91892 5.13965 8.12478L7.25469 10.2398L11.86 5.63455C12.0658 5.42537 12.4045 5.42537 12.6104 5.63455Z"
                    fill="#16173A"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_287_6712"
                    x1="17"
                    y1="0"
                    x2="-2.96701"
                    y2="4.8934"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00FFC2" />
                    <stop offset="1" stop-color="#00AA6D" />
                </linearGradient>
                <clipPath id="clip0_287_6712">
                    <rect width="17" height="17" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <svg
            v-if="item.isOnHold === true"
            :class="$style.locked"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.99989 7.53589C6.59794 7.53589 6.27197 7.86172 6.27197 8.26381C6.27197 8.53336 6.41893 8.76814 6.63677 8.89405V9.70331H7.36301V8.89405C7.58085 8.76819 7.72781 8.53336 7.72781 8.26381C7.72781 7.86172 7.40188 7.53589 6.99989 7.53589Z"
                fill="#F0A042"
            />
            <path
                d="M7.00014 4.0061C6.38829 4.0061 5.89062 4.50381 5.89062 5.11562V6.29719H8.10957V5.11562C8.10952 4.50391 7.61186 4.0061 7.00014 4.0061Z"
                fill="#F0A042"
            />
            <path
                d="M6.99988 0C3.134 0 0 3.13409 0 6.99998C0 10.8658 3.134 14 6.99988 14C10.8658 14 14 10.8657 14 6.99998C14 3.13409 10.8658 0 6.99988 0ZM9.15079 10.599H4.84931C4.39505 10.599 4.02675 10.2307 4.02675 9.77627V7.12001C4.02675 6.72375 4.30708 6.39283 4.68023 6.31499V5.11578C4.68023 3.83667 5.72092 2.79589 7.00012 2.79589C8.27922 2.79589 9.31991 3.83658 9.31991 5.11578V6.31499C9.69292 6.39307 9.97325 6.72375 9.97325 7.12001V9.77627H9.97335C9.97335 10.2306 9.60505 10.599 9.15079 10.599Z"
                fill="#F0A042"
            />
        </svg>
        <UnboxItemElement
            v-bind:item="{
                item: {
                    amountFixed: item.amount,
                    ...(function () {
                        if (item.items === undefined) return item;
                        else if (item.items.length === 1) return item.items[0];

                        return {
                            name: `${item.items.length}-Item Bundle`,
                            image: item.items.map((element) => element.image)
                        };
                    })()
                },
                color: cashierGetColor,
                tickets: Math.abs(cashierGetTickets),
                ticketsPrefix: cashierGetTickets < 0 ? '-' : '+'
            }"
            v-bind:coinType="cashierGetRouteName.includes('Deposit') === true ? 'robuxCoin' : null"
            v-bind:hoverable="item.user !== authUser.user._id && item.isOnHold !== true"
        />
    </button>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';
    import UnboxItemElement from '@/components/unbox/UnboxItemElement';

    export default {
        name: 'CashierItemElement',
        props: ['item', 'disabled'],
        components: {
            UnboxItemElement
        },
        methods: {
            ...mapActions([
                'modalsSetData',
                'modalsSetShow',
                'notificationShow',
                'cashierAddLimitedDataSelected',
                'cashierRemoveLimitedDataSelected'
            ]),
            cashierItemButton() {
                if (this.disabled) {
                    return;
                } else if (this.modalsShow === 'Gift') {
                    this.modalsSetShow(null);

                    setTimeout(() => {
                        this.modalsSetData({
                            typeCashier: this.modalsData.typeCashier,
                            limited: this.limited
                        });
                        this.modalsSetShow('LimitedsItem');
                    }, 200);
                } else if (
                    (this.cashierGetRouteName.includes('deposit') === true &&
                        this.cashierLimitedData.selected.some(
                            (element) => element.uniqueId === this.item.uniqueId
                        ) === true) ||
                    this.cashierLimitedData.selected.some(
                        (element) => element._id === this.item._id
                    ) === true
                ) {
                    this.cashierRemoveLimitedDataSelected(this.item);
                } else if (
                    this.cashierLimitedData.selected.length <
                    (this.cashierGetRouteName === 'LimitedsDeposit' ? 4 : 1)
                ) {
                    this.cashierAddLimitedDataSelected(Object.assign({}, this.item));
                } else {
                    this.notificationShow({
                        type: 'error',
                        message:
                            this.cashierGetRouteName === 'LimitedsDeposit'
                                ? `You can only bundle upto 4 items per limited listing`
                                : `You can only select 1 bundle/item per transaction`
                    });
                }
            }
        },
        computed: {
            ...mapGetters([
                'modalsShow',
                'modalsData',
                'cashierLimitedData',
                'userBalanceData',
                'authUser'
            ]),
            cashierGetRouteName() {
                return this.$route.name;
            },
            cashierGetColor() {
                let amount = this.item.amount;
                let color = '';

                if (this.item.items?.length)
                    amount =
                        this.item.items.sort((a, b) => b.amount - a.amount)[0].amount || amount;

                amount /= 1000;
                return getLimitedItemColorFromAmount(amount);
            },
            cashierGetTickets() {
                if (
                    this.cashierGetRouteName.includes('Deposit') === true ||
                    this.item.isOnHold === true
                )
                    return null;

                let a = this.item.amount;
                const b = this.item.amount;

                a = this.item.items.reduce((a, b) => a + b.amount, 0);
                return Math.round(((b - a) / a) * 100) * 1000;
            },
            cashierIsActive() {
                return (
                    (this.cashierGetRouteName.includes('deposit') === true &&
                        this.cashierLimitedData.selected.some(
                            (element) => element.uniqueId === this.item.uniqueId
                        ) === true) ||
                    this.cashierLimitedData.selected.some(
                        (element) => element._id === this.item._id
                    ) === true
                );
            },
            cashierIsBundle() {
                return (
                    this.cashierGetRouteName.includes('withdraw') === true &&
                    this.item.items.length > 1
                );
            }
        }
    };
</script>

<style module>
    .cashier-item-element {
        position: relative;
        width: 100%;
    }

    .cashier-item-element svg.check {
        position: absolute;
        left: 7%;
        top: 7%;
    }

    .cashier-item-element svg.user {
        position: absolute;
        left: 7%;
        top: 7%;
    }

    .cashier-item-element svg.locked {
        position: absolute;
        right: 7%;
        top: 7%;
    }

    .cashier-item-element.active > *:not(svg)::before {
        background: currentColor;
    }
</style>
