<template>
    <div :class="[$style.bets, { [$style.mounted]: betsMounted }]">
        <div :class="$style['bets-content']" class="octagonal before">
            <div :class="$style['content-nav']">
                <button
                    v-if="authUser.user !== null"
                    v-on:click="betsSetTab('my')"
                    v-bind:class="[
                        $style['nav-link'],
                        { [$style['link-active']]: betsTab === 'my' }
                    ]"
                >
                    <span v-bind:class="{ 'fade-green': betsTab === 'my' }">My Bets</span>
                </button>
                <button
                    v-on:click="betsSetTab('all')"
                    v-bind:class="[
                        $style['nav-link'],
                        { [$style['link-active']]: betsTab === 'all' }
                    ]"
                >
                    <span v-bind:class="{ 'fade-green': betsTab === 'all' }">All</span>
                </button>
                <button
                    v-on:click="betsSetTab('whale')"
                    v-bind:class="[
                        $style['nav-link'],
                        { [$style['link-active']]: betsTab === 'whale' }
                    ]"
                >
                    <span v-bind:class="{ 'fade-green': betsTab === 'whale' }">Whale Wins</span>
                </button>
                <button
                    v-on:click="betsSetTab('lucky')"
                    v-bind:class="[
                        $style['nav-link'],
                        { [$style['link-active']]: betsTab === 'lucky' }
                    ]"
                >
                    <span v-bind:class="{ 'fade-green': betsTab === 'lucky' }">Lucky Wins</span>
                </button>
            </div>
            <div :class="$style['content-bets']">
                <div :class="$style['bets-list']">
                    <div :class="$style['list-head']">
                        <div :class="$style['head-game']">Game</div>
                        <div :class="$style['head-user']">User</div>
                        <div :class="$style['head-time']">Time</div>
                        <div :class="$style['head-wager']">Bet amount</div>
                        <div :class="$style['head-multiplier']">Multiplier</div>
                        <div :class="$style['head-payout']">Payout</div>
                    </div>
                    <div :class="$style['list-content']">
                        <transition name="fade" mode="out-in">
                            <div
                                v-if="betsGetList.length === 0 || generalBets.loading === true"
                                :class="$style['content-loading']"
                                key="loading"
                            >
                                <LoadingAnimation />
                            </div>
                            <ol
                                v-else-if="betsGetList.length > 0"
                                :class="[$style['content-list'], { m: betsMounted }]"
                                key="data"
                            >
                                <BetsElement
                                    v-for="bet in betsGetList"
                                    v-bind:key="bet._id + betsTab"
                                    v-bind:bet="bet"
                                />
                            </ol>
                            <div v-else :class="$style['content-empty']" key="empty">
                                No bets found.
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import BetsElement from '@/components/bets/BetsElement';

    export default {
        name: 'Bets',
        components: {
            LoadingAnimation,
            BetsElement
        },
        data() {
            return {
                betsList: [],
                betsTab: 'all',
                betsMounted: false
            };
        },
        methods: {
            ...mapActions(['generalGetBetsDataSocket']),
            betsSetTab(tab) {
                this.betsTab = tab;
            },
            betsListEvent() {
                let bets = [];

                if (
                    this.generalBets.bets !== null &&
                    this.generalBets.bets[this.betsTab] !== undefined
                ) {
                    bets = this.generalBets.bets[this.betsTab].slice(0, 12);
                }

                this.betsList = bets;
            }
        },
        computed: {
            ...mapGetters(['authUser', 'generalBets']),
            betsGetList() {
                return this.betsList;
            },
            betsListFirstIds() {
                let ids = '';
                Object.keys(this.generalBets.bets || {}).forEach(
                    (item) => (ids += this.generalBets.bets[item]?.[0]?._id)
                );
                return ids;
            }
        },
        created() {
            if (this.generalBets.bets === null && this.generalBets.loading === false) {
                const data = {};
                this.generalGetBetsDataSocket(data);
            }
        },
        watch: {
            betsListFirstIds: {
                handler() {
                    this.betsMounted = true;
                    this.betsListEvent();
                }
            },
            betsTab: {
                immediate: true,
                handler() {
                    this.betsMounted = false;
                    this.betsListEvent();
                }
            }
        }
    };
</script>

<style scoped>
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active,
    .fade-enter-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-enter-from {
        opacity: 0;
    }
</style>

<style module>
    .bets {
        max-width: 1350px;
        width: 100%;
        margin-top: 25px;
        padding: 4px;
        font-size: 18px;
        clip-path: var(--octagonal-main);
        background: #0d243a;
    }

    .bets .bets-content {
        background: linear-gradient(45deg, #0a2036, #0a1f33, #0a1c2f, #091b2d, #081b2d);
        font-size: 16px;
        clip-path: var(--octagonal-main);
        padding: 0px 10px;
    }

    .bets .bets-content::before {
        background: #16293c;
    }

    .bets .bets-content > * {
        max-width: 1250px;
        margin: 0 auto;
        width: 100%;
    }

    .bets .content-nav {
        width: 100%;
        display: flex;
        align-items: stretch;
        position: relative;
        margin-bottom: 14px;
        gap: 5px;
    }

    .bets .content-nav::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #2b70a42e;
        opacity: 1;
    }

    .bets .content-nav button.nav-link {
        position: relative;
        margin-left: -35px;
        display: flex;
        align-items: flex-end;
        padding: 0px 35px 20px 35px;
        font-size: 16px;
        height: 60px;
        font-weight: 600;
        color: #537088;
        transition: all 0.3s ease;
    }

    .bets .content-nav button.nav-link:first-of-type {
        margin-left: 0px;
    }

    .bets .content-nav button.nav-link::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(256.23deg, #00ffc2 0%, #00aa6d 100%);
        transition: all 0.3s ease;
        opacity: 0;
    }

    .bets .content-nav button.nav-link.link-active::after {
        opacity: 1;
    }

    .bets .content-bets {
        mask-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.9), black);
        border-radius: 8px;
        padding: 1px;
    }

    .bets .bets-list {
        width: 100%;
        height: 440px;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
    }

    .bets .list-head {
        width: 100%;
        height: 39px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        padding: 0 35px;
        background: #081828;
        border-radius: 5px;
        border: 1px solid #112437;
    }

    .bets .head-game,
    .bets .head-user,
    .bets .head-time,
    .bets .head-wager,
    .bets .head-multiplier,
    .bets .head-payout {
        font-weight: 600;
        font-size: 15px;
        color: #537088;
    }

    .bets .head-game {
        width: 20%;
    }

    .bets .head-user {
        width: 20%;
    }

    .bets .head-time {
        width: 20%;
    }

    .bets .head-wager {
        width: 15%;
    }

    .bets .head-multiplier {
        width: 10%;
    }

    .bets .head-payout {
        width: 15%;
        text-align: right;
        font-weight: 700;
    }

    .bets .list-content {
        width: 100%;
        height: calc(100% - 52px);
        mask-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.5) 2%, white 10%);
        overflow: hidden;
    }

    .bets .content-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bets .content-list {
        list-style-type: none;
    }

    .bets .content-data {
        width: 100%;
    }

    .bets .content-empty {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    @media only screen and (max-width: 1100px) {
        .bets {
            width: 100%;
        }
    }

    @media only screen and (max-width: 950px) {
        .bets .bets-list {
            height: 388px;
            border-radius: 0;
        }

        .bets .list-head {
            display: none;
        }

        .bets .list-content {
            height: 100%;
        }
    }

    @media screen and (max-width: 500px) {
        .bets .content-nav button.nav-link {
            padding: 0px 20px 20px 20px;
            margin-left: -20px;
            font-size: 14px;
        }
    }
</style>
