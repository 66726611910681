<template>
    <div :class="$style['unbox-header-box']">
        <router-link :class="$style['link-back']" to="/unbox">
            <svg
                width="10"
                height="9"
                viewBox="0 0 10 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.21864 3.60238C9.16544 3.59389 9.11161 3.58997 9.05775 3.59066H2.88651L3.08649 3.49916C3.28196 3.40815 3.45979 3.28428 3.61202 3.13316L5.18387 1.58689C5.52258 1.26881 5.5795 0.757122 5.31874 0.374506C5.01525 -0.0332154 4.43324 -0.121742 4.01874 0.176807C3.98526 0.200941 3.95343 0.227247 3.92352 0.255555L0.272801 3.84686C-0.0906526 4.204 -0.0909723 4.78334 0.272075 5.14088L0.272801 5.1416L3.92352 8.73291C4.28726 9.08973 4.87619 9.08893 5.23895 8.7311C5.26749 8.70294 5.29415 8.67297 5.31874 8.64141C5.5795 8.25879 5.52258 7.7471 5.18387 7.42902L3.61667 5.87818C3.4802 5.74379 3.3233 5.6311 3.1516 5.5442L2.87255 5.42068H9.00656C9.48172 5.43803 9.8986 5.11152 9.98787 4.65207C10.0701 4.15325 9.72571 3.68331 9.21864 3.60238Z"
                    fill="#406992"
                />
            </svg>
        </router-link>
        <div v-if="unboxBoxData.box !== null" :class="$style['box-info']">
            <img
                v-bind:src="unboxImagePath + '/public/img/' + unboxBoxData.box.slug + '.png'"
                :class="$style['info-img']"
            />
            <div :class="$style['info-text']">
                <p :class="$style['text-title']">{{ unboxBoxData.box.name }}</p>
                <div :class="$style['text-amount']">
                    <p :class="$style['amount-multiplier']" v-if="unboxCount > 1">
                        {{ unboxCount }} x
                    </p>
                    <img
                        v-if="userBalanceData.type === 'cryptoCoin'"
                        src="@/assets/img/icons/cryptoCoin.svg"
                        alt="icon"
                    />
                    <img
                        v-else-if="userBalanceData.type === 'robuxCoin'"
                        src="@/assets/img/icons/robuxCoin.svg"
                        alt="icon"
                    />
                    <p :class="$style['amount-value']">
                        <span> {{ unboxFormatValue(unboxBoxData.box.amount).split('.')[0] }} </span
                        >.{{ unboxFormatValue(unboxBoxData.box.amount).split('.')[1] }}
                    </p>
                </div>
            </div>
        </div>
        <button v-on:click="unboxFairButton()" :class="$style['button-fair']">
            <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.9999 2.68561V4.43688H12.7643C11.3888 4.43822 10.0388 4.06586 8.85843 3.35957L7.29608 2.41501C7.20521 2.36005 7.10104 2.33099 6.99484 2.33099C6.88865 2.33099 6.78447 2.36005 6.69361 2.41501L5.13636 3.35446C3.95572 4.06004 2.60589 4.43235 1.23048 4.43177H4.20249e-10V2.68561C-5.85389e-06 2.53077 0.0611539 2.38219 0.170166 2.27222C0.279177 2.16225 0.427215 2.0998 0.582053 2.09845H1.23559C2.71662 2.09881 4.1696 1.69452 5.4376 0.929242L6.99995 0L8.5572 0.934348C9.82601 1.69771 11.2785 2.10186 12.7592 2.10356H13.4178C13.5714 2.10618 13.7179 2.16835 13.8265 2.27694C13.9351 2.38553 13.9973 2.53206 13.9999 2.68561Z"
                    fill="#01E1A4"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7643 5.61637C11.1776 5.61562 9.62088 5.18322 8.26106 4.36547L6.99995 3.59961L5.73884 4.35015C4.38025 5.17256 2.82369 5.61023 1.23559 5.61637H0V7.14809C0.00357593 8.92054 0.511507 10.6554 1.46444 12.1499C2.41738 13.6444 3.77596 14.8368 5.38143 15.5879L6.99995 16.3384L8.60825 15.5879C10.216 14.8388 11.5771 13.6471 12.5319 12.1524C13.4868 10.6577 13.996 8.92178 13.9999 7.14809V5.61637H12.7643ZM7.29608 10.7221C7.18766 10.8263 7.04311 10.8845 6.89273 10.8845C6.74235 10.8845 6.5978 10.8263 6.48938 10.7221L4.85555 9.08826L5.67757 8.26624L6.90294 9.48651L8.94523 7.44422L9.76725 8.26624L7.29608 10.7221Z"
                    fill="#01E1A4"
                />
            </svg>
        </button>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'UnboxHeaderBox',
        data() {
            return {
                unboxImagePath: process.env.VUE_APP_BACKEND_URL
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'modalsSetShow']),
            unboxFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            unboxFairButton() {
                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                this.modalsSetShow('FairGame');
            }
        },
        computed: {
            ...mapGetters(['authUser', 'unboxBoxData', 'unboxCount', 'userBalanceData'])
        }
    };
</script>

<style module>
    .unbox-header-box {
        display: flex;
        align-items: center;
        margin-bottom: -15px;
        gap: 7px;
    }

    .unbox-header-box .button-fair,
    .unbox-header-box .link-back {
        position: relative;
        height: 44px;
        width: 44px;
        background: linear-gradient(
            360deg,
            rgba(15, 66, 107, 0.35) 8.33%,
            rgba(13, 51, 85, 0.35) 108.33%
        );
        clip-path: var(--octagonal-main);
        font-size: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .unbox-header-box .button-fair::before,
    .unbox-header-box .link-back::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-before);
        font-size: 5px;
        background: #12385e;
    }

    .unbox-header-box .box-info {
        display: flex;
        align-items: center;
        gap: 7px;
        flex: 1;
    }

    .unbox-header-box .info-img {
        transform: scale(0.8);
        aspect-ratio: 1;
        max-width: 85px;
        flex: 1;
        object-fit: contain;
    }

    .unbox-header-box .text-title {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 7px;
        color: white;
    }

    .unbox-header-box .text-amount {
        display: flex;
        gap: 5px;
        height: 26px;
        min-width: 118px;
        align-items: center;
        justify-content: center;
        padding: 0 7px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 28.53%,
            rgba(30, 255, 194, 0.35) 91.03%
        );
        clip-path: var(--octagonal-main);
        font-size: 3px;
        position: relative;
    }

    .unbox-header-box .text-amount::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.54deg,
                rgba(0, 0, 0, 0.128) -3.42%,
                rgba(0, 33, 24, 0.132) 35.6%,
                rgba(0, 99, 73, 0.144986) 96.9%,
                rgba(1, 193, 143, 0.0925409) 127.35%,
                rgba(1, 237, 176, 0.068) 156.02%
            );
        clip-path: var(--octagonal-after);
        font-size: 3px;
    }

    .unbox-header-box .text-amount img {
        width: 12px;
        height: 16px;
        position: relative;
        margin-bottom: -2px;
        z-index: 1;
    }

    .unbox-header-box .text-amount .amount-multiplier {
        margin-bottom: -3.5px;
        margin-right: -2.5px;
    }

    .unbox-header-box .text-amount .amount-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .unbox-header-box .text-amount .amount-multiplier,
    .unbox-header-box .text-amount .amount-value {
        font-weight: 600;
        color: #ffffffbb;
        font-size: 10px;
        position: relative;
        z-index: 1;
    }

    .unbox-header-box .text-amount .amount-value span {
        font-weight: 600;
        color: #fff;
        font-size: 14px;
    }

    .unbox-header-box .button-fair {
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.68deg,
                rgba(0, 0, 0, 0.128) -11.11%,
                rgba(0, 33, 24, 0.132) 16.04%,
                rgba(0, 99, 73, 0.144986) 58.69%,
                rgba(1, 193, 143, 0.0925409) 79.88%,
                rgba(1, 237, 176, 0.068) 99.83%
            );
    }

    .unbox-header-box .button-fair::before {
        background: #01e1a440;
    }
</style>
