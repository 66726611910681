<template>
    <router-link :class="$style['unbox-box-element']" v-bind:to="'/unbox/' + box._id">
        <div :class="$style['element-name']">{{ box.name }}</div>
        <div :class="$style['element-price']">
            <img
                v-if="userBalanceData.type === 'cryptoCoin'"
                src="@/assets/img/icons/cryptoCoin.svg"
                alt="icon"
            />
            <img
                v-else-if="userBalanceData.type === 'robuxCoin'"
                src="@/assets/img/icons/robuxCoin.svg"
                alt="icon"
            />
            <div :class="$style['price-value']">
                <span>{{ unboxFormatValue(this.box.amount).split('.')[0] }}</span
                >.{{ unboxFormatValue(this.box.amount).split('.')[1] }}
            </div>
        </div>
        <div :class="$style['element-image']">
            <img v-bind:src="unboxImagePath + '/public/img/' + box.slug + '.png'" />
        </div>
        <div :class="$style['element-open']">
            <button :class="$style['open-button']">
                <span>Open Case</span>
            </button>
        </div>
    </router-link>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'UnboxBoxElement',
        props: ['box'],
        data() {
            return {
                unboxImagePath: process.env.VUE_APP_BACKEND_URL
            };
        },
        computed: {
            ...mapGetters(['userBalanceData'])
        },
        methods: {
            unboxFormatValue(value) {
                if (value.toString().length > 9)
                    return (
                        parseFloat(Math.floor(value / 10) / 100000000)
                            .toFixed(3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm'
                    );
                else
                    return parseFloat(Math.floor(value / 10) / 100)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        }
    };
</script>

<style module>
    .unbox-box-element {
        aspect-ratio: 190 / 240;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 14px 0;
        align-items: center;
        position: relative;
        background: linear-gradient(
                213.54deg,
                rgba(5, 29, 48, 0.35) 2.37%,
                rgba(31, 99, 153, 0) 106.02%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03));
        clip-path: var(--octagonal-main);
        font-size: 5px;
        gap: 7px;
    }

    .unbox-box-element::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: #0c2a42;
        clip-path: var(--octagonal-before);
    }

    .unbox-box-element .element-name {
        font-size: 15px;
        font-weight: 600;
        color: white;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
    }

    .unbox-box-element .element-price {
        position: relative;
        min-width: 60%;
        height: 28px;
        font-size: 3px;
        background: linear-gradient(
            360deg,
            rgba(15, 66, 107, 0.5) 11.31%,
            rgba(13, 51, 85, 0.5) 104.17%
        );
        clip-path: var(--octagonal-main);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        padding: 0 7px;
    }

    .unbox-box-element .price-value {
        font-size: 10px;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        color: #ffffffbb;
    }

    .unbox-box-element .price-value span {
        font-size: 14px;
        color: white;
    }

    .unbox-box-element .element-image {
        flex: 1;
        width: 70%;
        position: relative;
        margin-bottom: -7px;
    }

    .unbox-box-element .element-image img {
        height: 80%;
        width: 80%;
        left: 50%;
        top: 57%;
        transform: translate(-50%, -60%);
        position: absolute;
        object-fit: contain;
    }

    .unbox-box-element .element-open {
        transition: all 0.1s ease;
        height: 0px;
        opacity: 0;
    }

    .unbox-box-element:hover .element-open {
        margin-top: -10px;
        height: 29px;
        opacity: 1;
    }

    .unbox-box-element .open-button {
        height: 29px;
        width: 92px;
        position: relative;
        font-size: 3px;
        clip-path: var(--octagonal-main);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) -6.47%, #1effc2 77.59%);
    }

    .unbox-box-element .open-button::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
                89.34deg,
                rgba(0, 0, 0, 0.128) -31.79%,
                rgba(0, 33, 24, 0.132) 43.25%,
                rgba(0, 99, 73, 0.144986) 161.11%,
                rgba(1, 193, 143, 0.0925409) 219.68%,
                rgba(1, 237, 176, 0.068) 274.8%
            ),
            linear-gradient(263.46deg, #00ffc2 -10.36%, #00aa6d 44.58%);
        clip-path: var(--octagonal-after);
    }

    .unbox-box-element .open-button span {
        position: relative;
        z-index: 1;
        font-size: 14px;
        color: white;
        font-weight: 600;
    }
</style>
