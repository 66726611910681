<template>
    <div :class="$style['bets-element']">
        <div :class="$style['element-game']">
            <IconCrash v-if="bet.method === 'crash'" />
            <IconRoll v-else-if="bet.method === 'roll'" />
            <IconBlackjack v-else-if="bet.method === 'blackjack'" />
            <IconDuels v-else-if="bet.method === 'duels'" />
            <IconMines v-else-if="bet.method === 'mines'" />
            <IconTowers v-else-if="bet.method === 'towers'" />
            <IconUnbox v-else-if="bet.method === 'unbox'" />
            <IconBattles v-else-if="bet.method === 'battles'" />
            <IconUpgrader v-else-if="bet.method === 'upgrader'" />
            <span>{{ betsGetMethod }}</span>
        </div>
        <button
            v-on:click="betsUserButton(bet?.user)"
            v-bind:class="[
                $style['element-user'],
                bet?.user === null
                    ? $style['user-hidden']
                    : [
                          $style['user-' + betsGetRank(bet?.user)],
                          $style['user-' + betsGetLevelColor(bet?.user)]
                      ]
            ]"
        >
            <AvatarImage
                v-bind:image="bet?.user === null ? 'anonymous' : bet.user?.avatar"
                v-bind:rank="bet?.bot ? null : bet.user?.rank"
                v-bind:level="bet?.bot ? null : bet.user?.level"
            />
            <div
                v-if="bet?.user !== null"
                v-html="bet.user?.username"
                :class="$style['user-username']"
            ></div>
            <div v-else>Anonymous</div>
        </button>
        <div :class="$style['element-time']">
            <span>{{ betsGetDate[0] }},</span>
            {{ betsGetDate[1] }}
        </div>
        <div :class="$style['element-wager']">
            <img :src="betGetType" alt="icon" />
            <div :class="$style['wager-value']">
                <span>{{ betsFormatValue(betsGetAmount).split('.')[0] }}</span
                >.{{ betsFormatValue(betsGetAmount).split('.')[1] }}
            </div>
        </div>
        <div :class="$style['element-multiplier']">
            <span v-bind:class="{ 'fade-green': betsGetMultiplier > 0 }">
                {{ parseFloat(betsGetMultiplier).toFixed(2) }}x
            </span>
        </div>
        <div v-bind:class="[$style['element-payout'], { [$style.positive]: betsGetPayout > 0 }]">
            <img :src="betGetType" alt="icon" />
            <div :class="$style['payout-value']">
                <span>
                    {{
                        (betsGetPayout > 0 ? '+' : betsGetPayout ? '-' : '') +
                        betsFormatValue(Math.abs(betsGetPayout)).split('.')[0]
                    }}</span
                >.{{ betsFormatValue(Math.abs(betsGetPayout)).split('.')[1] }}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';
    import IconCrash from '@/components/icons/IconCrash';
    import IconRoll from '@/components/icons/IconRoll';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconDuels from '@/components/icons/IconDuels';
    import IconMines from '@/components/icons/IconMines';
    import IconTowers from '@/components/icons/IconTowers';
    import IconUnbox from '@/components/icons/IconUnbox';
    import IconBattles from '@/components/icons/IconBattles';
    import IconUpgrader from '@/components/icons/IconUpgrader';

    export default {
        name: 'BetsElement',
        components: {
            AvatarImage,
            IconCrash,
            IconRoll,
            IconBlackjack,
            IconDuels,
            IconMines,
            IconTowers,
            IconUnbox,
            IconBattles,
            IconUpgrader
        },
        props: ['bet'],
        methods: {
            ...mapActions(['generalSetUserInfoData', 'modalsSetShow']),
            betsUserButton(user) {
                if (user === null || user.rank === 'system') {
                    return;
                }

                this.generalSetUserInfoData(user);
                this.modalsSetShow('ChatUser');
            },
            betsFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            betsGetRank(user) {
                let rank = user?.rakeback;

                if (user?.rank !== 'user') {
                    rank = user?.rank;
                }

                return rank;
            },
            betsGetLevelColor(user) {
                let color = '';

                if (user?.level >= 2 && user?.level < 26) {
                    color = 'blue';
                } else if (user?.level >= 26 && user?.level < 51) {
                    color = 'sky';
                } else if (user?.level >= 51 && user?.level < 76) {
                    color = 'yellow';
                } else if (user?.level >= 76 && user?.level <= 90) {
                    color = 'purple';
                } else if (user?.level > 90) {
                    color = 'gold';
                }

                return color;
            }
        },
        computed: {
            betGetType() {
                //idk how good this is but it sometimes it works
                let img = '';

                if (this.bet.coinType === 'robuxCoin') {
                    img = require('@/assets/img/icons/robuxCoin.svg');
                } else {
                    img = require('@/assets/img/icons/cryptoCoin.svg');
                }

                return img;
            },
            betsGetMethod() {
                let method = this.bet?.method.charAt(0).toUpperCase() + this.bet?.method.slice(1);

                if (this.bet?.method === 'duels') {
                    method = 'Dice Duels';
                }

                return method;
            },
            betsGetDate() {
                let date = new Date(this.bet?.updatedAt).toLocaleString('en-US', {
                    hour12: true,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });

                return date.split(',');
            },
            betsGetAmount() {
                let amount = this.bet?.amount;

                if (this.bet?.method === 'blackjack') {
                    amount = Math.floor(
                        this.bet.amount.main + this.bet.amount.sideLeft + this.bet.amount.sideRight
                    );
                    if (this.bet.actions.includes('split') === true) {
                        amount = Math.floor(amount + this.bet.amount.main);
                    }
                }

                return amount;
            },
            betsGetMultiplier() {
                let multiplier = this.bet?.multiplier / 100;

                if (['crash', 'roll', 'upgrader'].includes(this.bet?.method) === true) {
                    multiplier = this.bet.payout / this.betsGetAmount;
                }

                return multiplier;
            },
            betsGetPayout() {
                return -this.betsGetAmount + this.betsGetMultiplier * this.betsGetAmount || 0;
            }
        }
    };
</script>

<style module>
    .bets-element {
        width: 100%;
        height: 1em;
        display: flex;
        border-radius: 5px;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 39px;
        padding: 0 35px;
        background: linear-gradient(270deg, #08243b 0%, #072741 100%);
    }

    ol:global(.m) .bets-element:first-child {
        overflow: hidden;
        height: 0px;
        animation: bets-element 0.3s ease forwards;
    }

    @keyframes bets-element {
        0% {
            height: 0em;
        }
        100% {
            height: 1em;
        }
    }

    .bets-element:nth-child(even) {
        background: unset;
    }

    .bets-element .element-game {
        height: 39px;
        width: 20%;
        display: flex;
        align-items: center;
    }

    .bets-element .element-game svg {
        margin-right: 10px;
        fill: #bbbfd0;
    }

    .bets-element .element-game span {
        mask-image: linear-gradient(90deg, white, rgba(0, 0, 0, 0.75));
        font-weight: 600;
        font-size: 14px;
        color: white;
    }

    .bets-element .element-user {
        height: 39px;
        width: 20%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #88a6bf;
    }

    .bets-element .element-user.user-hidden {
        font-style: italic;
    }

    .bets-element .element-user img {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        border-width: 2px;
        overflow: hidden;
    }

    .bets-element .user-username {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bets-element .element-time {
        height: 39px;
        width: 20%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #88a6bf;
    }

    .bets-element .element-time span {
        margin-right: 4px;
    }

    .bets-element .element-wager,
    .bets-element .element-payout {
        height: 39px;
        width: 15%;
        display: flex;
        align-items: center;
    }

    .bets-element .element-payout {
        justify-content: flex-end;
    }

    .bets-element .element-wager img,
    .bets-element .element-payout img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .bets-element .wager-value,
    .bets-element .payout-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .bets-element .wager-value span,
    .bets-element .payout-value span {
        font-size: 14px;
        font-weight: 800;
    }

    .bets-element .wager-value span,
    .bets-element .payout-value span {
        color: #ffffff;
    }

    .bets-element .element-payout:not(.positive) {
        opacity: 0.5;
    }

    .bets-element .element-multiplier {
        height: 39px;
        width: 10%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #88a6bf;
    }

    .bets-element .element-multiplier.multiplier-positive {
    }

    @media only screen and (max-width: 950px) {
        .bets-element {
            font-size: 78px;
            position: relative;
            display: grid;
            grid-template-rows: 39px 39px;
            grid-template-columns: 33.33% 33.33% 33.33%;
            padding: 0;
            background: none;
            border-top: 1px solid #0f324e;
        }

        .bets-element:nth-child(even) {
            background: none;
        }

        .bets-element .element-game,
        .bets-element .element-user,
        .bets-element .element-time {
            width: 100%;
            height: 100%;
            justify-content: center;
            background-color: rgba(15, 50, 78, 0.35);
        }

        .bets-element .element-game {
            grid-row: 1;
        }

        .bets-element .element-user {
            grid-column: 1;
            grid-row: 1;
        }

        .bets-element .user-username {
            max-width: 90px;
        }

        .bets-element .element-time {
            grid-column: 2;
            grid-row: 1;
        }

        .bets-element .element-wager,
        .bets-element .element-multiplier,
        .bets-element .element-payout {
            width: 100%;
            height: 100%;
            justify-content: center;
            background-color: rgba(4, 26, 45, 0.5);
        }

        .bets-element .element-wager {
            grid-column: 1;
            grid-row: 2;
        }

        .bets-element .element-multiplier {
            position: relative;
            grid-column: 2;
            grid-row: 2;
        }

        .bets-element .element-multiplier::before {
            content: '';
            width: 1px;
            height: 18px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            background-color: #0c324e;
        }

        .bets-element .element-multiplier::after {
            content: '';
            width: 1px;
            height: 18px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            background-color: #0c324e;
        }

        .bets-element .element-payout {
            grid-column: 3;
            grid-row: 2;
        }
    }

    @media only screen and (max-width: 475px) {
        .bets-element .user-username {
            max-width: 85px;
        }

        .bets-element .element-time span {
            display: none;
        }
    }
</style>
