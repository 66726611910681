<template>
    <div
        v-bind:class="[$style['rain-tip-dropdown'], { [$style.active]: rainDropdownTip === true }]"
    >
        <button
            v-on:click="rainSetDropdownTip(!rainDropdownTip)"
            class="octagonal before hoverable"
            :class="[$style['button-toggle'], { [$style.active]: rainDropdownTip }]"
        >
            <div :class="$style['button-inner']">
                <span class="fade-green">Tip Rain</span>
                <div :class="$style['inner-amount']">
                    <img
                        v-if="userBalanceData.type === 'cryptoCoin'"
                        src="@/assets/img/icons/cryptoCoin.svg"
                        alt="icon"
                    />
                    <img
                        v-else-if="userBalanceData.type === 'robuxCoin'"
                        src="@/assets/img/icons/robuxCoin.svg"
                        alt="icon"
                    />
                    <div :class="$style['amount-value']">
                        <span>{{ rainFormatValue(generalRain.site?.amount).split('.')[0] }}</span
                        >.{{ rainFormatValue(generalRain.site?.amount).split('.')[1] }}
                    </div>
                </div>
                <IconDropdown />
            </div>
        </button>
        <div :class="$style['dropdown-window']">
            <div :class="$style['window-inner']">
                <div :class="$style['inner-clock']">
                    <div :class="$style['clock-timer']">
                        <IconTimer />
                        <div :class="$style['timer-value']">
                            <span>{{ rainTimer.split(':')[0] }}</span
                            >m <span>{{ rainTimer.split(':')[1] }}</span
                            >s
                            {{ generalRain.active !== null ? '(ongoing)' : '' }}
                        </div>
                    </div>
                </div>
                <div :class="$style['inner-input']" class="octagonal before after hoverable">
                    <div :class="$style['button-coin']">
                        <img
                            v-if="userBalanceData.type === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="userBalanceData.type === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                    </div>
                    <input
                        v-model="rainAmount"
                        v-on:input="rainValidateInput"
                        type="text"
                        placeholder="..."
                    />
                    <button
                        v-on:click="rainTipButton"
                        :class="$style['button-tip']"
                        class="octagonal before after hoverable"
                    >
                        <div :class="$style['button-inner']">
                            {{ generalRain.active !== null ? 'Tip next rain' : 'Tip' }}
                        </div>
                    </button>
                </div>
                <div :class="$style['inner-info']">
                    50% of the rain pot is evenly shared among players, and 50% is divided by
                    levels.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconRain from '@/components/icons/IconRain';
    import IconDropdown from '@/components/icons/IconDropdown';
    import IconTimer from '@/components/icons/IconTimer';
    import { getValidatedAmount } from '@/utils/general';

    export default {
        name: 'RainTipDropdown',
        components: {
            IconRain,
            IconDropdown,
            IconTimer
        },
        data() {
            return {
                rainTimerInterval: null,
                rainTimer: '00:00',
                rainAmount: ''
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'rainSetDropdownTip', 'generalSendRainTipSocket']),
            rainFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            rainValidateInput() {
                this.rainAmount = getValidatedAmount(this.rainAmount);
            },
            rainStartTimer() {
                const endingTime =
                    new Date(this.generalRain.site?.updatedAt).getTime() +
                    1000 * 60 * (this.generalRain.site?.state === 'running' ? 2 : 28);
                let timeLeft = Math.floor(
                    (endingTime - (new Date().getTime() + this.generalTimeDiff)) / 1000
                );
                timeLeft =
                    this.generalRain.site?.state === 'pending'
                        ? 60 * 2
                        : timeLeft <= 0
                          ? 0
                          : timeLeft;

                let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
                timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
                let timeSeconds = Math.floor(timeLeft % 60);
                timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

                this.rainTimer = timeMinutes.toString() + ':' + timeSeconds.toString();
            },
            rainTipButton() {
                if (this.socketSendLoading !== null) {
                    return;
                }

                const amount = Math.floor(this.rainAmount * 1000);

                if (amount === undefined || isNaN(amount) === true || amount <= 0) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered rain tip amount is invalid.'
                    });
                    return;
                }

                const data = { amount: amount, type: this.userBalanceData.type };
                this.generalSendRainTipSocket(data);

                this.rainAmount = null;
                this.rainSetDropdownTip(false);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'authUser',
                'generalTimeDiff',
                'rainDropdownTip',
                'generalRain',
                'userBalanceData'
            ])
        },
        created() {
            let self = this;
            document.addEventListener('click', function (event) {
                if (!self.$el.contains(event.target) && self.rainDropdownTip === true) {
                    self.rainSetDropdownTip(false);
                }
            });

            this.rainTimerInterval = setInterval(() => {
                this.rainStartTimer();
            }, 500);
        }
    };
</script>

<style module>
    .rain-tip-dropdown {
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 10;
    }

    aside.chat .rain-tip-dropdown {
        flex: 1;
    }

    .rain-tip-dropdown button.button-toggle {
        height: 48px;
        width: 100%;
        position: relative;
        padding: 1px;
        filter: drop-shadow(0px 2px 10px rgba(1, 230, 169, 0.15))
            drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
        z-index: 2;
        color: white;
    }

    .rain-tip-dropdown button.button-toggle::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #01e1a4 100%);
        font-size: 7px;
    }

    .rain-tip-dropdown button.button-toggle .button-inner span {
        font-size: 14px;
        text-align: start;
        line-height: 14px;
        transform: translateY(-1px);
    }

    .rain-tip-dropdown button.button-toggle .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 14px;
        font-size: 7px;
        font-weight: 800;
        background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.128) 0%,
                rgba(0, 33, 24, 0.132) 25%,
                rgba(0, 99, 73, 0.144986) 60%,
                rgba(1, 193, 143, 0.0925409) 80%,
                rgba(1, 237, 176, 0.068) 100%
            )
            #064552;
        clip-path: var(--octagonal-main);
    }

    .rain-tip-dropdown button.button-toggle .button-inner svg:last-of-type {
        margin-left: 12px;
        fill: url(#icon-dropdown-gradient) #a6cae8;
        transition: all 0.3s ease;
    }

    .rain-tip-dropdown.active button.button-toggle .button-inner svg:last-of-type {
        transform: rotate(180deg);
    }

    .rain-tip-dropdown button.button-toggle .inner-amount {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .rain-tip-dropdown button.button-toggle .inner-amount img {
        width: 13px;
        height: 13px;
        margin-right: 5px;
    }

    .rain-tip-dropdown button.button-toggle .amount-value {
        font-size: 8px;
        font-weight: 600;
        word-break: break-all;
        text-align: end;
        color: #bbbfd0;
    }

    .rain-tip-dropdown button.button-toggle .amount-value span {
        font-size: 12px;
        font-weight: 800;
        color: #ffffff;
    }

    .rain-tip-dropdown .dropdown-window {
        height: 0;
        position: absolute;
        top: 83px;
        padding: 0 14px;
        width: 100%;
        left: 0;
        transition: height 0.2s ease;
        overflow: hidden;
        z-index: 1;
    }

    .rain-tip-dropdown.active .dropdown-window {
        height: 172px;
        padding: 0 15px 15px 15px;
    }

    .rain-tip-dropdown .window-inner {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        filter: drop-shadow(1px 2px 0px #00000033) drop-shadow(-1px 2px 0px #00000033);
        padding: 14px 10px;
    }

    .rain-tip-dropdown .window-inner > * {
        position: relative;
        z-index: 1;
    }

    .rain-tip-dropdown .window-inner::after,
    .rain-tip-dropdown .window-inner::before {
        content: '';
        z-index: 0;
        position: absolute;
        left: 0;
        font-size: 7px;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
    }

    .rain-tip-dropdown .window-inner::after {
        clip-path: var(--octagonal-after);
        background: linear-gradient(0deg, #133c40, #0d2437);
    }

    .rain-tip-dropdown .window-inner::before {
        clip-path: var(--octagonal-before);
        background: #133c40;
    }

    .rain-tip-dropdown .inner-clock {
        mask-image: linear-gradient(90deg, transparent, white 40%, white 60%, transparent);
        width: calc(100% + 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .rain-tip-dropdown .inner-clock::before,
    .rain-tip-dropdown .inner-clock::after {
        position: static;
        content: '';
        display: block;
        height: 1px;
        flex: 1;
        background: #01e5a8;
    }

    .rain-tip-dropdown .inner-clock .clock-timer {
        height: 26px;
        width: fit-content;
        min-width: 135px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        align-items: center;
    }

    .rain-tip-dropdown .inner-clock .clock-timer > * {
        position: relative;
        z-index: 1;
    }
    .rain-tip-dropdown .inner-clock .clock-timer::before,
    .rain-tip-dropdown .inner-clock .clock-timer::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 4px;
        z-index: 0;
    }

    .rain-tip-dropdown .inner-clock .clock-timer::before {
        clip-path: var(--octagonal-before);
        background: #28726a;
    }

    .rain-tip-dropdown .inner-clock .clock-timer::after {
        clip-path: var(--octagonal-after);
        background: #17484f;
    }

    .rain-tip-dropdown .inner-clock .clock-timer svg {
        margin-right: 8px;
        fill: #45cd95;
        width: 12px;
        height: 14px;
    }

    .rain-tip-dropdown .timer-value {
        font-size: 13px;
        font-weight: 500;
        color: #bbbfd0;
    }

    .rain-tip-dropdown .timer-value span {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
    }

    .rain-tip-dropdown .inner-info {
        width: 100%;
        margin-top: 7px;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: #6eb6b6;
    }

    .rain-tip-dropdown .inner-input {
        width: 100%;
        height: 40px;
        position: relative;
        color: white;
        margin-top: 12px;
        padding: 1px;
        filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .rain-tip-dropdown .inner-input::before {
        font-size: 4px;
        background: #184049;
    }

    .rain-tip-dropdown .inner-input::after {
        font-size: 4px;
        background: #0a202d;
    }

    .rain-tip-dropdown .inner-input input {
        width: 100%;
        height: 100%;
        padding: 0 80px 0 42px;
        font-size: 10px;
        font-weight: 600;
        color: #ffffff;
        background-color: transparent;
    }

    .rain-tip-dropdown .inner-input input::placeholder {
        color: white;
    }

    .rain-tip-dropdown .button-coin {
        position: absolute;
        left: 0;
        top: 0;
        background: #063e41;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3px;
        left: 5.5px;
        top: 5.5px;
        height: 29px;
        width: 29px;
        clip-path: var(--octagonal-main);
    }

    .rain-tip-dropdown button.button-tip {
        min-width: 42px;
        width: fit-content;
        padding: 0 7px;
        height: 22px;
        position: absolute;
        top: 50%;
        right: 7px;
        color: #073e40;
        transform: translate(0, -50%);
        filter: drop-shadow(0px 2px 10px rgba(1, 230, 169, 0.15))
            drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .rain-tip-dropdown button.button-tip::before {
        font-size: 3px;
        background: linear-gradient(0deg, #1effc2, transparent);
    }

    .rain-tip-dropdown button.button-tip::after {
        font-size: 3px;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    }

    .rain-tip-dropdown button.button-tip .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        color: #073e40;
    }

    @media screen and (max-width: 1500px) {
        .rain-tip-dropdown .dropdown-window {
            top: 71px;
        }
    }
</style>
