export const emojis = [
    {
        name: 'baldwinhand',
        extension: 'webp'
    },
    {
        name: 'batmanhmm',
        extension: 'gif'
    },
    {
        name: 'catjam',
        extension: 'gif'
    },
    {
        name: 'widePeepoHappy',
        extension: 'png'
    },
    {
        name: 'chadgif',
        extension: 'gif'
    },
    {
        name: 'cry~2',
        extension: 'gif'
    },
    {
        name: 'dance',
        extension: 'gif'
    },
    {
        name: 'DiCaprioLaugh',
        extension: 'png'
    },
    {
        name: 'kekwlaugh',
        extension: 'gif'
    },
    {
        name: 'lil_swag',
        extension: 'gif'
    },
    {
        name: 'malariadancep2',
        extension: 'gif'
    },
    {
        name: 'pedroraccoon',
        extension: 'gif'
    },
    {
        name: 'reverso',
        extension: 'gif'
    },
    {
        name: 'tada_animated',
        extension: 'gif'
    },
    {
        name: 'twitchlive',
        extension: 'gif'
    },
    {
        name: 'whatinthegoddamn',
        extension: 'webp'
    },
    {
        name: 'theIlluminati',
        extension: 'png'
    },
    {
        name: 'komodoHype',
        extension: 'png'
    },
    {
        name: 'seemsGood',
        extension: 'png'
    },
    {
        name: 'bibleThump',
        extension: 'png'
    },
    {
        name: 'wutFace',
        extension: 'png'
    },
    {
        name: 'kreyGasm',
        extension: 'png'
    },
    {
        name: 'heyGuys',
        extension: 'png'
    },
    {
        name: 'Kappa',
        extension: 'png'
    },
    {
        name: 'LUL',
        extension: 'png'
    },
    {
        name: 'notLikeThis',
        extension: 'png'
    },
    {
        name: 'ResidentSleeper',
        extension: 'png'
    },
    {
        name: 'travisApology',
        extension: 'gif'
    },
    {
        name: 'rollKing',
        extension: 'gif'
    },
    {
        name: 'rollDrool',
        extension: 'gif'
    },
    {
        name: 'rollAngry',
        extension: 'gif'
    },
    {
        name: 'rollSigh',
        extension: 'gif'
    },
    {
        name: 'rollSad',
        extension: 'gif'
    },
    {
        name: 'rollCry',
        extension: 'gif'
    },
    {
        name: 'rollSkull',
        extension: 'gif'
    },
    {
        name: 'rollDJ',
        extension: 'gif'
    }
];
