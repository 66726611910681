<template>
    <div
        v-bind:class="[
            $style['battles-bet-element'],
            {
                [$style['element-winner']]:
                    battlesGameData.game.state === 'completed' && bet.payout > 0,
                [$style['element-lost']]:
                    battlesGameData.game.state === 'completed' && bet.payout <= 0
            },
            $style['game-' + playerCount]
        ]"
    >
        <div :class="$style['element-top']">
            <div :class="$style['top-inner']">
                <div v-if="bet !== null" :class="$style['inner-info']">
                    <div
                        v-bind:class="[
                            $style['info-user'],
                            $style['user-' + (bet.bot === true ? 'bot' : battlesGetRank(bet.user))],
                            $style['user-' + battlesGetLevelColor(bet.user)]
                        ]"
                    >
                        <div :class="$style['user-avatar']">
                            <AvatarImage
                                v-bind:image="bet.bot ? battlesGetBot.avatar : bet.user.avatar"
                                v-bind:rank="bet.bot ? null : bet.user.rank"
                                v-bind:level="bet.bot ? null : bet.user.level"
                            />
                        </div>
                        <span
                            v-html="bet.bot === true ? battlesGetBot.username : bet.user.username"
                        ></span>
                    </div>
                    <div :class="$style['info-amount']">
                        <img
                            v-if="bet.coinType === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="bet.coinType === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                        <div :class="$style['amount-value']">
                            <span>
                                {{
                                    battlesFormatValue(battlesGetOutcomeAmount).split('.')[0]
                                }} </span
                            >.{{ battlesFormatValue(battlesGetOutcomeAmount).split('.')[1] }}
                        </div>
                    </div>
                </div>
                <div v-else :class="$style['inner-actions']">
                    <div
                        v-if="battlesGameData.game.options.funding > 0"
                        :class="$style['actions-funding']"
                    >
                        <div :class="$style['funding-inner']">
                            <span>-{{ battlesGameData.game.options.funding }}%</span>
                        </div>
                    </div>

                    <button
                        v-on:click="
                            authUser.user !== null &&
                            authUser.user._id === battlesGameData.game.bets[0].user._id
                                ? battlesBotButton()
                                : battlesJoinButton()
                        "
                        :class="$style['button-join']"
                        v-bind:disabled="socketSendLoading !== null"
                    >
                        <div :class="$style['button-inner']">
                            <transition name="fade" mode="out-in">
                                <ButtonLoading
                                    v-if="
                                        socketSendLoading === 'BattlesJoin' ||
                                        socketSendLoading === 'BattlesCall'
                                    "
                                />
                                <div v-else :class="$style['inner-content']">
                                    <div
                                        v-if="
                                            false &&
                                            authUser.user !== null &&
                                            authUser.user._id ===
                                                battlesGameData.game.bets[0].user._id
                                        "
                                        :class="$style['content-bot']"
                                    >
                                        <img src="@/assets/img/char-robot.png" />
                                    </div>
                                    <svg
                                        v-else-if="
                                            authUser.user !== null &&
                                            authUser.user._id !==
                                                battlesGameData.game.bets[0].user._id
                                        "
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.47861 8.33238L6.15342 11.0087L5.08395 12.0789L6.15493 13.1499L5.08471 14.2201L3.21144 12.3469L1.07023 14.4881L0 13.4178L2.14121 11.2759L0.267935 9.40336L1.33816 8.33313L2.40839 9.4026L3.47861 8.33238ZM0.413256 0.456299L3.09715 0.458569L12.0412 9.40336L13.1122 8.33313L14.1824 9.40336L12.3099 11.2766L14.4503 13.4178L13.3801 14.4881L11.2389 12.3469L9.36561 14.2201L8.29539 13.1499L9.36561 12.0789L0.415526 3.12884L0.413256 0.456299ZM11.3554 0.456299L14.0371 0.458569L14.0386 3.12505L10.971 6.19192L8.29463 3.51636L11.3554 0.456299Z"
                                        />
                                    </svg>

                                    {{
                                        authUser.user !== null &&
                                        authUser.user._id === battlesGameData.game.bets[0].user._id
                                            ? 'CALL BOT'
                                            : 'JOIN BATTLE'
                                    }}
                                </div>
                            </transition>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div :class="$style['element-items']">
            <div :class="$style['items-list']">
                <div :class="$style['list-content']">
                    <UnboxItemElement
                        v-for="(item, index) in battlesGetOutcomeItems"
                        v-bind:key="index"
                        v-bind:item="item"
                        v-bind:coinType="bet.coinType"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';
    import ButtonLoading from '@/components/ButtonLoading';
    import BattlesItemElement from '@/components/battles/BattlesItemElement';
    import UnboxItemElement from '@/components/unbox/UnboxItemElement';
    import { getUniqueBot } from '@/utils/bots';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';

    export default {
        name: 'BattlesBetElement',
        components: {
            AvatarImage,
            ButtonLoading,
            BattlesItemElement,
            UnboxItemElement
        },
        props: ['pos', 'bet', 'playerCount'],
        methods: {
            ...mapActions(['notificationShow', 'battlesSendBotSocket', 'battlesSendJoinSocket']),
            battlesFormatValue(value) {
                if (value.toString().length > 9)
                    return (
                        parseFloat(Math.floor(value / 10) / 100000000)
                            .toFixed(3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm'
                    );
                else
                    return parseFloat(Math.floor(value / 10) / 100)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            battlesGetRank(user) {
                let rank = user.rakeback;

                if (user.rank !== 'user') {
                    rank = user.rank;
                }

                return rank;
            },
            battlesGetLevelColor(user) {
                let color = '';

                if (user.level >= 2 && user.level < 26) {
                    color = 'blue';
                } else if (user.level >= 26 && user.level < 51) {
                    color = 'sky';
                } else if (user.level >= 51 && user.level < 76) {
                    color = 'yellow';
                } else if (user.level >= 76 && user.level <= 90) {
                    color = 'purple';
                } else if (user.level > 90) {
                    color = 'gold';
                }

                return color;
            },
            battlesGetItemsFormated(items) {
                return items.map((item) => {
                    const color = getLimitedItemColorFromAmount(item.item.amountFixed / 1000);
                    return { ...item, color };
                });
            },
            battlesBotButton() {
                const data = {
                    gameId: this.battlesGameData.game._id,
                    type: this.userBalanceData.type
                };
                this.battlesSendBotSocket(data);
            },
            battlesJoinButton() {
                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                const data = {
                    gameId: this.battlesGameData.game._id,
                    slot: this.pos,
                    type: this.userBalanceData.type
                };
                this.battlesSendJoinSocket(data);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'authUser', 'battlesGameData', 'userBalanceData']),
            battlesGetBot() {
                return getUniqueBot(this.battlesGameData.game._id + this.pos);
            },
            battlesGetBoxes() {
                let boxes = [];

                if (this.battlesGameData.game !== null) {
                    for (const box of this.battlesGameData.game.boxes) {
                        for (let i = 0; i < box.count; i++) {
                            boxes.push(box.box);
                        }
                    }
                }

                return boxes;
            },
            battlesGetOutcomeItems() {
                let items = [];

                if (this.bet !== null) {
                    for (const [index, outcome] of (this.battlesGameData.game.state === 'completed'
                        ? this.bet.outcomes
                        : this.bet.outcomes.slice(0, -1)
                    ).entries()) {
                        let pos = 0;

                        for (const item of this.battlesGetItemsFormated(
                            this.battlesGetBoxes[index].items
                        )) {
                            pos = pos + item.tickets;
                            if (outcome <= pos) {
                                items.push(item);
                                break;
                            }
                        }
                    }
                }

                return items.reverse();
            },
            battlesGetOutcomeAmount() {
                let amount = 0;

                for (const item of this.battlesGetOutcomeItems) {
                    amount = amount + item.item.amountFixed;
                }

                return amount;
            }
        }
    };
</script>

<style scoped>
    .fade-leave-active {
        transition: opacity 0.1s;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 0.1s;
    }

    .fade-enter {
        opacity: 0;
    }
</style>

<style module>
    .battles-bet-element {
        flex-direction: column;
        display: flex;
        width: 100%;
    }

    .battles-bet-element .element-top {
        width: 100%;
        margin-bottom: 14px;
        background: #0a273f;
        clip-path: var(--octagonal-main);
        font-size: 7px;
        flex: 1;
    }

    .battles-bet-element.element-winner .element-top {
        background: linear-gradient(270deg, rgba(1, 229, 168, 0.7) 0%, rgba(0, 0, 0, 0) 45%),
            #0a273f;
    }

    .battles-bet-element.element-lost .element-top {
        background: linear-gradient(270deg, rgba(245, 80, 70, 0.7) 0%, rgba(0, 0, 0, 0) 45%),
            #0a273f;
    }

    .battles-bet-element .top-inner {
        width: 100%;
        height: 100%;
        min-height: 65px;
        clip-path: var(--octagonal-after);
        font-size: 7px;
        background: linear-gradient(
                223deg,
                rgba(5, 29, 48, 0.35) 0%,
                rgba(31, 99, 153, 0.1) 50%,
                rgba(5, 29, 48, 0.35) 100%
            ),
            #041f35;
    }

    .battles-bet-element.element-winner .top-inner {
        background: linear-gradient(223deg, rgba(1, 232, 171, 0.2) 0%, rgba(0, 0, 0, 0) 50%),
            linear-gradient(
                223deg,
                rgba(5, 29, 48, 0.35) 0%,
                rgba(31, 99, 153, 0.1) 50%,
                rgba(5, 29, 48, 0.35) 100%
            ),
            #041f35;
    }

    .battles-bet-element.element-lost .top-inner {
        background: linear-gradient(223deg, rgba(235, 42, 42, 0.2) 0%, rgba(0, 0, 0, 0) 50%),
            linear-gradient(
                223deg,
                rgba(5, 29, 48, 0.35) 0%,
                rgba(31, 99, 153, 0.1) 50%,
                rgba(5, 29, 48, 0.35) 100%
            ),
            #041f35;
    }

    .battles-bet-element .inner-info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        align-items: center;
        gap: 7px;
        padding: 14px;
    }

    .battles-bet-element .info-user {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .battles-bet-element.element-winner .info-user span {
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .battles-bet-element.element-lost .info-user span {
        color: #f55046;
    }

    .battles-bet-element .user-avatar {
        width: 37px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-right: 7px;
    }

    .battles-bet-element .user-avatar img {
        width: 37px;
        height: 37px;
    }

    .battles-bet-element .info-amount {
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        align-items: center;
    }

    .battles-bet-element .info-amount img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    .battles-bet-element .amount-value {
        font-size: 11px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .battles-bet-element .amount-value span {
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-bet-element .inner-actions {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .battles-bet-element .actions-funding {
        width: 48px;
        height: 21px;
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translate(0, -50%);
        padding: 1px;
        filter: drop-shadow(0px 1px 14px rgba(1, 230, 169, 0.63));
    }

    .battles-bet-element .actions-funding:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #01c98c;
        clip-path: polygon(
            4px 0,
            calc(100% - 4px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 4px) 100%,
            4px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-bet-element .funding-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
                242deg,
                rgba(0, 255, 194, 0.44) 16%,
                rgba(0, 231, 170, 0.22) 32%,
                rgba(0, 218, 157, 0.1) 50%,
                rgba(0, 195, 134, 0.26) 80%,
                rgba(0, 170, 109, 0.44) 100%
            ),
            linear-gradient(180deg, #073137 0%, #082538 100%);
        clip-path: polygon(
            4px 0,
            calc(100% - 4px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 4px) 100%,
            4px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-bet-element .funding-inner span {
        font-size: 11px;
        font-weight: 800;
        background: linear-gradient(239deg, #00ffc2 -100%, #00aa6d 120%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .battles-bet-element button.button-join {
        width: 150px;
        height: 35px;
        position: relative;
        padding: 1px;
    }

    .battles-bet-element.top-actions button.button-join:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 170, 109, 0) 0%, #00ffc2 100%);
        clip-path: polygon(
            6px 0,
            calc(100% - 6px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 6px) 100%,
            6px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-bet-element button.button-join .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        clip-path: polygon(
            5px 0,
            calc(100% - 5px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 5px) 100%,
            5px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-bet-element button.button-join .inner-content {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-bet-element button.button-join .inner-content svg {
        margin-right: 8px;
        fill: #ffffff;
    }

    .battles-bet-element button.button-join .content-bot {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-right: 6px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #4adeaf;
        overflow: hidden;
    }

    .battles-bet-element button.button-join .content-bot img {
        width: 34px;
        transform: scaleX(-1);
    }

    .battles-bet-element .element-items {
        width: 100%;
        height: 400px;
        position: relative;
        padding: 1px;
        background: #02152459;
        font-size: 14px;
        clip-path: var(--octagonal-main);
    }

    .battles-bet-element .element-items::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 14px;
        background: #0a273f;
        z-index: 1;
        clip-path: var(--octagonal-before);
    }

    .battles-bet-element .items-list {
        height: 100%;
        width: 100%;
        overflow: auto;
        mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), white 25%);
    }

    .battles-bet-element .list-content {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        padding: 7px;
        gap: 5px;
    }

    .battles-bet-element.game-3 .list-content {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .battles-bet-element.game-4 .list-content {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 1200px) {
        .battles-bet-element.game-3 .list-content > * {
            font-size: 8px;
        }

        .battles-bet-element.game-4 .inner-info > * {
            width: 100%;
        }
        .battles-bet-element.game-4 .inner-info {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    @media screen and (max-width: 1000px) {
        .battles-bet-element .list-content {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .battles-bet-element.game-4 .list-content > * {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 900px) {
        .battles-bet-element.game-4 .element-items,
        .battles-bet-element.game-4 .element-items::before {
            font-size: 10px;
        }

        .battles-bet-element .list-content > * {
            font-size: 8px;
        }

        .battles-bet-element.game-3 .list-content {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .battles-bet-element.game-3 .inner-info > * {
            width: 100%;
        }
        .battles-bet-element.game-3 .inner-info {
            align-items: flex-start;
            flex-direction: column;
        }

        .battles-bet-element.game-4 .list-content > * {
            font-size: 6px;
        }
    }

    @media screen and (max-width: 700px) {
        .battles-bet-element .list-content {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .battles-bet-element.game-3 .list-content > * {
            font-size: 6px;
        }

        .battles-bet-element.game-4 .element-items,
        .battles-bet-element.game-4 .element-items::before {
            font-size: 14px;
        }

        .battles-bet-element.game-4 .list-content {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .battles-bet-element.game-4 .list-content > * {
            font-size: 10px;
        }
    }

    @media screen and (max-width: 600px) {
        .battles-bet-element .inner-info > * {
            width: 100%;
        }
        .battles-bet-element .inner-info {
            align-items: flex-start;
            flex-direction: column;
        }

        .battles-bet-element.game-3 .list-content {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .battles-bet-element.game-3 .list-content > * {
            font-size: 10px;
        }

        .battles-bet-element.game-4 .element-items,
        .battles-bet-element.game-4 .element-items::before {
            font-size: 12px;
        }

        .battles-bet-element.game-4 .list-content > * {
            font-size: 8px;
        }

        .battles-bet-element.game-4 .element-top {
            margin-bottom: 7px;
        }
        .battles-bet-element.game-4 .top-inner {
            height: 150px;
        }
        .battles-bet-element.game-4 .info-user,
        .battles-bet-element.game-4 .info-amount {
            position: absolute;
            transform: translate(-50%, -50%) rotate(-90deg);
            left: 50%;
            top: 50%;
            width: 120px;
        }
        .battles-bet-element.game-4 .info-user img {
            transform: rotate(90deg);
        }
        .battles-bet-element.game-4 .info-user {
            left: calc(50% - 14px);
        }
        .battles-bet-element.game-4 .info-amount {
            left: calc(50% + 14px);
        }
    }

    @media screen and (max-width: 460px) {
        .battles-bet-element .element-items,
        .battles-bet-element .element-items::before {
            font-size: 10px;
        }

        .battles-bet-element .list-content > * {
            font-size: 6px;
        }

        .battles-bet-element.game-3 .top-inner {
            height: 150px;
        }
        .battles-bet-element.game-3 .info-user,
        .battles-bet-element.game-3 .info-amount {
            position: absolute;
            transform: translate(-50%, -50%) rotate(-90deg);
            left: 50%;
            top: 50%;
            width: 120px;
        }
        .battles-bet-element.game-3 .info-user img {
            transform: rotate(90deg);
        }
        .battles-bet-element.game-3 .info-user {
            left: calc(50% - 14px);
        }
        .battles-bet-element.game-3 .info-amount {
            left: calc(50% + 14px);
        }

        .battles-bet-element.game-3 .list-content > * {
            font-size: 8px;
        }

        .battles-bet-element.game-4 .element-items,
        .battles-bet-element.game-4 .element-items::before {
            font-size: 10px;
        }

        .battles-bet-element.game-4 .list-content > * {
            font-size: 6px;
        }
    }
</style>
