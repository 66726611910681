<template>
    <div class="modal-item modal-faq">
        <div class="faq-header">
            <span class="fade-green">QUESTIONS AND ANSWERS</span>
            <p>
                Here are some answers to frequently asked questions we have collected for you. New
                content is added on a regular basis to help you quickly overcome any difficulties.
                Of course you can also contact our support or ask questions directly in our Discord.
            </p>
        </div>
        <div class="faq-list">
            <FaqElement
                title="What is Rollbet?"
                text="Rollbet is a premium gaming club where you can socialize, participate in events, and win prizes."
            />

            <FaqElement
                title="Why do you need my Roblox credentials?"
                text="To automate our deposit and withdrawal processes, we leverage our P2P system to enable smooth transactions for both depositors and withdrawers. Rollbet prioritizes user security and does not store your password. If at any time you feel uncomfortable with us having access to your credentials, you can easily invalidate them by changing your password on Roblox.com."
            />

            <FaqElement
                title="Why does Rollbet have 2 currencies?"
                text="To create a stable, fair market, we offer two currencies: Crypto and Robux. This ensures prioritized withdrawals and minimizes confusion."
            />

            <FaqElement
                title="Can I transfer coins to another account?"
                text="You can transfer coins to another account by simply clicking on a user's profile in chat and clicking the Tip User button. Keep in mind that tipping is an irreversible action."
            />

            <FaqElement
                title="I am missing coins, what now?"
                text="Please ensure that you have not granted access to your Rollbet or Roblox account to anyone else. If your account is compromised, we will assist you in regaining access. However, we do not provide balance adjustments to compensate for any losses, as maintaining account security is your responsibility. For game-related issues, please open a ticket in the Discord."
            />

            <FaqElement
                title="I deposited with cryptocurrency and I still have not received my deposit."
                text="If you have not received your deposit after 10 confirmations, please open a ticket in our Discord, providing a detailed explanation of the situation along with the transaction IDs."
            />

            <FaqElement
                title="I have discovered a vulnerability or an exploit, what now?"
                text="Join our Discord and create a ticket to report any vulnerabilities or exploits. Depending on the scale of the report and the vulnerability / exploit, you may receive compensation."
            />

            <FaqElement
                title="What is 'Rain Abuse'?"
                text="The rain coins distributed through the 30-minute rain feature are meant to be used exclusively for playing the games on our site. Accumulating free rain coins over time without genuinely participating in the games and on-site activities is considered rain abuse and will result in a permanent ban. Please be aware that all rain bans are final and will not be lifted."
            />

            <FaqElement
                title="How much commission do I get when my referrals place a bet?"
                text="For every bet placed by someone you have referred, you will receive 1% of the total wager. For example, if someone wagers 1,000,000 coins, you would receive 10,000 coins."
            />

            <FaqElement
                title="How does the deposit and withdrawal system work?"
                text="We utilize a Peer-to-Peer (P2P) system to facilitate transactions on our site. As a result, there may be extended wait times for limited deposits and Robux deposits. To make a deposit, a corresponding withdrawal must occur, and vice versa. Rollbet will subsidize long queue times to ensure user satisfaction."
            />

            <FaqElement
                title="Is there a maximum bet or profit?"
                text="Yes, each game mode has a max win limit, except for Case Battles. This limit may increase as the site grows."
            />

            <FaqElement
                title="What happens if my internet disconnects during a Crash round?"
                text="Whether your client is connected or disconnected, the auto-cashout feature will work as intended. We highly recommend you use this feature if your internet connection is faulty or if you have poor internet reliability."
            />

            <FaqElement
                title="What do the different features pay out?"
                text="We've made the different features flexible depending on what currency you have selected, but some are static to the respective currencies and will only pay out Robux for example. Some of those features are: Gift cards, promo codes and rain. For further questions join our Discord and open up a support ticket."
            />

            <FaqElement
                title="How do I know that Rollbet is fair & doesn't rig results?"
                text="Rollbet is a provably fair casino. You can check all game results with our provably fair system, found at the bottom of our website."
            />

            <FaqElement
                title="Can I make money by promoting the website?"
                text="If you have influence / followers and you're looking to promote Rollbet then you must join our Discord and open up a ticket so we may forward you to our marketing department."
            />
        </div>
    </div>
</template>

<script>
    import FaqElement from '@/components/faq/FaqElement';

    export default {
        name: 'ModalFaq',
        components: {
            FaqElement
        }
    };
</script>

<style scoped>
    .modal-faq {
        width: 1000px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 0 30px;
        border-radius: 15px;
        background: radial-gradient(
                100% 100% at 50% -30%,
                rgba(0, 255, 194, 0.2) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(255deg, #07263d 0%, #07243a 100%);
    }

    .modal-faq .faq-header {
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #335361;
    }

    .modal-faq .faq-header span {
        font-size: 32px;
        font-weight: 900;
    }

    .modal-faq .faq-header p {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-faq .faq-list {
        width: 100%;
        height: 600px;
        padding: 30px 10px 30px 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .modal-faq .faq-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .modal-faq .faq-list::-webkit-scrollbar-track {
        margin: 30px 0;
        background-color: #062234;
    }

    .modal-faq .faq-list::-webkit-scrollbar-thumb {
        background-color: #1e5164;
    }

    @media only screen and (max-width: 1190px) {
        .modal-faq {
            width: calc(100vw - 20px);
        }
    }

    @media only screen and (max-width: 700px) {
        .modal-faq {
            padding: 80px 15px 0 15px;
        }
    }
</style>
