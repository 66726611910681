<template>
    <div :class="$style['modal-login']">
        <div :class="$style['side-content']">
            <div :class="$style['roll-banner']">
                <img :class="$style['banner-logo']" src="@/assets/img/logo.png" alt="ROLLBET" />
                <img :class="$style['banner-robot']" src="@/assets/img/char-robot.png" alt="" />
            </div>
            <div :class="$style['divider']"></div>
            <p :class="$style['tos']">
                By accessing this site, I confirm that I am at least 18 years old and have read and
                agree with the&nbsp;
                <span :class="$style['cyan']" v-on:click="modalsSetShow('Terms')"
                    >Terms of Service</span
                >.
            </p>
        </div>
        <div :class="$style['login-content']">
            <div
                v-if="
                    ['login', 'register', 'roblox login', 'roblox cookie'].includes(modalTab) ===
                    true
                "
                :class="$style['content-title']"
            >
                <div :class="$style['button-group']">
                    <button
                        :class="[$style['button-item'], { [$style.active]: modalTab === 'login' }]"
                        class="octagonal hoverable"
                        v-on:click="modalSetTab('login')"
                    >
                        <span :class="{ 'fade-green': modalTab === 'login' }">Sign In</span>
                    </button>
                    <button
                        :class="[
                            $style['button-item'],
                            { [$style.active]: modalTab === 'register' }
                        ]"
                        class="octagonal hoverable"
                        v-on:click="modalSetTab('register')"
                    >
                        <span :class="{ 'fade-green': modalTab === 'register' }">Register</span>
                    </button>
                </div>
                <div :class="$style['content-title']">
                    <p>or</p>
                    <div :class="$style['button-group']">
                        <button
                            :class="[
                                $style['button-item'],
                                { [$style.active]: modalTab === 'roblox login' }
                            ]"
                            class="octagonal hoverable"
                            v-on:click="modalSetTab('roblox login')"
                        >
                            <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.5 0.5L0 14L13.5 17.5L17 4L3.5 0.5ZM10.2005 8.09743L7.56738 7.40509L6.87332 10.0448L9.50644 10.7371L10.2005 8.09743Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <span :class="{ 'fade-green': modalTab === 'roblox login' }">
                                Sign in with Roblox
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else :class="$style['content-title']">
                <div :class="$style['button-group']">
                    <button
                        :class="$style['button-item']"
                        v-on:click="modalSetTab('login')"
                        class="octagonal hoverable"
                    >
                        <span>Go back</span>
                    </button>
                    <button
                        :class="[$style['button-item'], $style['active']]"
                        class="octagonal hoverable"
                    >
                        <span class="fade-green">Reset password</span>
                    </button>
                </div>
            </div>

            <div :class="$style['divider']"></div>

            <div
                v-if="
                    ['login', 'register', 'roblox login', 'roblox cookie'].includes(modalTab) ===
                    true
                "
                :class="$style['content-auth']"
            >
                <LoginRoblox
                    v-if="['roblox login', 'roblox cookie'].includes(modalTab) === true"
                    v-bind:tab="modalTab"
                />
                <LoginCredentials v-else v-bind:tab="modalTab" />
            </div>
            <LoginForgot v-else />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import LoginCredentials from '@/components/login/LoginCredentials';
    import LoginRoblox from '@/components/login/LoginRoblox';
    import LoginForgot from '@/components/login/LoginForgot';

    export default {
        name: 'ModalLogin',
        components: {
            LoginCredentials,
            LoginRoblox,
            LoginForgot
        },
        data() {
            return {
                modalTab: 'login'
            };
        },
        methods: {
            ...mapActions(['modalsSetShow']),
            modalSetTab(tab) {
                this.modalTab = tab;
            },
            modalTermsButton() {
                this.modalsSetShow(null);
                setTimeout(() => {
                    this.modalsSetShow('Terms');
                }, 300);
            }
        }
    };
</script>

<style module>
    .modal-login {
        width: 980px;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px;
        gap: 12px;
    }

    .modal-login .side-content {
        max-width: 304px;
        min-height: 523px;
        width: 100%;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        position: relative;
        clip-path: var(--octagonal-main);
        font-size: 16px;
    }

    .modal-login .side-content::before {
        clip-path: var(--octagonal-before);
        font-size: 16px;
        content: '';
        position: absolute;
        background: linear-gradient(0deg, transparent, #16374f);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .modal-login .login-content {
        padding: 10px;
        width: 100%;
    }

    .login-content .divider {
        width: 100%;
        height: 1px;
        background: #0a2f4d;
        margin-top: 16px;
        margin-bottom: 20px;
    }

    .login-content .content-title {
        display: flex;
        align-items: center;
        color: #5b85ae;
        font-size: 16px;
        font-weight: 500;
        flex-wrap: wrap;
        gap: 8px 16px;
    }

    .content-title .button-group {
        border: 1px solid #153250;
        padding: 6px;
        background: linear-gradient(-45deg, #0c2135, #0b2036);
        display: flex;
        gap: 14px;
        border-radius: 8px;
    }

    .content-title .button-group .button-item {
        position: relative;
        display: flex;
        align-items: center;
        gap: 7px;
        font-weight: 500;
        height: 36px;
        color: #5b85ae;
        padding: 6px 20px;
        background: #0d2d47;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .content-title .button-group .button-item::before {
        font-size: 5px;
        left: 0;
        top: 0;
        content: '';
        background: #1d3c60;
        position: absolute;
        height: 100%;
        width: 100%;
        clip-path: var(--octagonal-before);
    }

    .content-title .button-group .button-item.active {
        background: #143f48;
        color: #00d597;
    }

    .content-title .button-group .button-item.active::before {
        background: linear-gradient(0deg, #2b7c71, transparent);
    }

    .content-title .button-group .button-item span {
        font-size: 16px;
    }

    .side-content .divider {
        height: 1px;
        width: 100%;
        background: linear-gradient(90deg, #7740ed88, #0f66b8);
    }

    .side-content .tos {
        background: #041728ee;
        padding: 12px 10px 16px 10px;
        color: #406992;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    .side-content .tos .cyan {
        color: #2bc0d4;
        cursor: pointer;
    }

    .side-content .roll-banner {
        background-image: url('~@/assets/img/login-banner-bg.png');
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% 100%;
        position: relative;
        width: 100%;
        flex: 1;
    }

    .side-content .roll-banner .banner-logo {
        transform: translate(-50%, -50%);
        position: absolute;
        width: 173px;
        top: 13%;
        left: 50%;
    }

    .side-content .roll-banner .banner-robot {
        transform: translateX(-50%);
        position: absolute;
        bottom: -15%;
        height: 95%;
        left: 51%;
    }

    @media screen and (max-width: 700px) {
        .modal-login {
            flex-direction: column-reverse;
        }

        .modal-login .side-content {
            min-height: unset;
            max-width: 100%;
            height: 200px;
        }

        .side-content .roll-banner {
            background-size: 100% auto;
        }

        .side-content .roll-banner .banner-logo {
            left: 30%;
            top: 50%;
            width: 40%;
        }

        .side-content .roll-banner .banner-robot {
            height: unset;
            width: 50%;
            top: -20%;
            right: 0;
            bottom: unset;
            left: unset;
            transform: unset;
        }
    }
</style>
