<template>
    <div :class="$style['navbar-cashier']">
        <button
            v-on:click="modalsSetShow('Cashier')"
            class="octagonal after before hoverable"
            :class="$style['button-cashier']"
        >
            <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 0H3C1.35 0 0 1.4 0 3.11111C0 4.43333 0.75 5.52222 1.875 5.98889V3.11111C1.875 2.8 2.025 2.48889 2.175 2.25556C2.325 2.02222 2.7 1.94444 3 1.94444H12C12.3 1.94444 12.6 2.1 12.825 2.25556C13.05 2.41111 13.125 2.8 13.125 3.11111V5.98889C14.25 5.52222 15 4.43333 15 3.11111C15 1.4 13.65 0 12 0ZM12 12.4444V3.11111H3V12.4444C3 13.3 3.675 14 4.5 14H10.5C11.325 14 12 13.3 12 12.4444ZM6.9 7.31111L6.375 7.85556C6.15 8.08889 5.775 8.08889 5.55 7.85556C5.325 7.62222 5.325 7.23333 5.55 7L6.525 5.98889C7.05 5.44444 7.875 5.44444 8.4 5.98889L9.375 7C9.6 7.23333 9.6 7.62222 9.375 7.85556C9.15 8.08889 8.775 8.08889 8.55 7.85556L8.025 7.31111V10.5778C8.025 10.8889 7.8 11.2 7.425 11.2C7.05 11.2 6.825 10.9667 6.825 10.5778V7.31111H6.9Z"
                    fill="white"
                />
            </svg>
            <p>Wallet</p>
        </button>
        <div
            v-bind:class="[
                $style['cashier-dropdown'],
                { [$style.active]: cashierDropdown === true }
            ]"
        >
            <button
                v-on:click="cashierSetDropdown(!cashierDropdown)"
                class="octagonal after before hoverable"
                :class="$style['dropdown-button']"
            >
                <div :class="$style['button-icon']">
                    <img
                        :src="
                            require(`@/assets/img/icons/${userBalanceData.type || 'robuxCoin'}.svg`)
                        "
                        alt="icon"
                    />
                </div>
                <div :class="$style['button-amount']">
                    <AmountNumber
                        v-bind:key="userBalanceData.type"
                        v-bind:amount="authUser.user.balance[userBalanceData.type || 'robuxCoin']"
                    />
                    <ol :class="$style['balance-update']">
                        <li
                            v-for="(update, index) in balanceUpdates"
                            v-bind:key="index"
                            :class="$style[update.coinType]"
                        >
                            +{{ Math.floor(update.amount).toLocaleString() }}
                            <span>{{ update.amount.toFixed(2).slice(-3) }}</span>
                        </li>
                    </ol>
                </div>
                <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547938 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84763C4.84671 6.05079 5.15326 6.05079 5.33889 5.84763L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                        fill="#4C6F8D"
                    />
                </svg>
            </button>
            <div :class="$style['dropdown-menu']">
                <div :class="$style['menu-content']" class="octagonal before">
                    <button
                        v-on:click="
                            cashierSetDropdown(false);
                            userSetBalanceType('cryptoCoin');
                        "
                        class="octagonal after before hoverable"
                        :class="[
                            $style['content-item'],
                            { [$style.active]: userBalanceData.type === 'cryptoCoin' }
                        ]"
                    >
                        <img src="@/assets/img/icons/cryptoCoin.svg" width="14" alt="icon" />
                        <span :class="$style['item-title']" class="fade-green">Crypto</span>
                        <AmountNumber v-bind:amount="authUser.user.balance.cryptoCoin" />
                    </button>
                    <button
                        v-on:click="
                            cashierSetDropdown(false);
                            userSetBalanceType('robuxCoin');
                        "
                        class="octagonal after before hoverable"
                        :class="[
                            $style['content-item'],
                            { [$style.active]: userBalanceData.type === 'robuxCoin' }
                        ]"
                    >
                        <img src="@/assets/img/icons/robuxCoin.svg" width="17" alt="icon" />
                        <span :class="$style['item-title']" class="fade-gold">Robux</span>
                        <AmountNumber v-bind:amount="authUser.user.balance.robuxCoin" />
                    </button>
                    <button
                        v-on:click="
                            cashierSetDropdown(false);
                            modalsSetShow('Currencies');
                        "
                        class="octagonal after before hoverable"
                        :class="$style['content-item']"
                    >
                        <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.5172 2.2312e-05C2.46642 -0.00857142 -0.00857142 2.46642 2.2312e-05 5.5172C0.00861605 8.5336 2.52443 11 5.54083 11H11V5.54083C11 2.52443 8.5336 0.00861605 5.5172 2.2312e-05ZM5.98985 8.30157C5.84591 8.42833 5.66759 8.49278 5.45275 8.49278C5.2379 8.49278 5.05958 8.42833 4.91564 8.30157C4.77169 8.17481 4.70079 8.01153 4.70079 7.81387C4.70079 7.61622 4.77169 7.45294 4.91564 7.32618C5.05958 7.19942 5.2379 7.13497 5.45275 7.13497C5.66759 7.13497 5.84591 7.19942 5.98985 7.32618C6.1338 7.45294 6.2047 7.61622 6.2047 7.81387C6.2047 8.01153 6.13165 8.17266 5.98985 8.30157ZM7.26173 4.70724C7.17364 4.88986 7.03184 5.07677 6.83634 5.26583L6.37442 5.69552C6.24337 5.82228 6.15099 5.95118 6.10157 6.08439C6.05001 6.21759 6.02208 6.38517 6.01778 6.58927H4.86622C4.86622 6.19825 4.91134 5.88888 4.99943 5.66329C5.08966 5.43341 5.2379 5.23146 5.42911 5.07462C5.62677 4.90919 5.77931 4.75665 5.88243 4.61915C5.98341 4.4881 6.03712 4.32697 6.03712 4.16369C6.03712 3.75978 5.8631 3.55568 5.51505 3.55568C5.36466 3.55138 5.22072 3.61584 5.12618 3.73185C5.02736 3.85001 4.97365 4.009 4.96935 4.21095H3.61154C3.61798 3.67384 3.78556 3.25705 4.11857 2.95841C4.45158 2.65978 4.91779 2.50939 5.5172 2.50939C6.11446 2.50939 6.57852 2.64689 6.90724 2.92404C7.23595 3.20119 7.39923 3.5922 7.39923 4.10138C7.39708 4.30978 7.35196 4.51818 7.26173 4.70724Z"
                                fill="black"
                            />
                            <path
                                d="M5.5172 2.2312e-05C2.46642 -0.00857142 -0.00857142 2.46642 2.2312e-05 5.5172C0.00861605 8.5336 2.52443 11 5.54083 11H11V5.54083C11 2.52443 8.5336 0.00861605 5.5172 2.2312e-05ZM5.98985 8.30157C5.84591 8.42833 5.66759 8.49278 5.45275 8.49278C5.2379 8.49278 5.05958 8.42833 4.91564 8.30157C4.77169 8.17481 4.70079 8.01153 4.70079 7.81387C4.70079 7.61622 4.77169 7.45294 4.91564 7.32618C5.05958 7.19942 5.2379 7.13497 5.45275 7.13497C5.66759 7.13497 5.84591 7.19942 5.98985 7.32618C6.1338 7.45294 6.2047 7.61622 6.2047 7.81387C6.2047 8.01153 6.13165 8.17266 5.98985 8.30157ZM7.26173 4.70724C7.17364 4.88986 7.03184 5.07677 6.83634 5.26583L6.37442 5.69552C6.24337 5.82228 6.15099 5.95118 6.10157 6.08439C6.05001 6.21759 6.02208 6.38517 6.01778 6.58927H4.86622C4.86622 6.19825 4.91134 5.88888 4.99943 5.66329C5.08966 5.43341 5.2379 5.23146 5.42911 5.07462C5.62677 4.90919 5.77931 4.75665 5.88243 4.61915C5.98341 4.4881 6.03712 4.32697 6.03712 4.16369C6.03712 3.75978 5.8631 3.55568 5.51505 3.55568C5.36466 3.55138 5.22072 3.61584 5.12618 3.73185C5.02736 3.85001 4.97365 4.009 4.96935 4.21095H3.61154C3.61798 3.67384 3.78556 3.25705 4.11857 2.95841C4.45158 2.65978 4.91779 2.50939 5.5172 2.50939C6.11446 2.50939 6.57852 2.64689 6.90724 2.92404C7.23595 3.20119 7.39923 3.5922 7.39923 4.10138C7.39708 4.30978 7.35196 4.51818 7.26173 4.70724Z"
                                fill="#93FF6D"
                            />
                        </svg>
                        <span>Why 2 Currencies?</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AmountNumber from '@/components/AmountNumber';
    import IconCashier from '@/components/icons/IconCashier';

    export default {
        name: 'NavbarCashier',
        components: {
            AmountNumber,
            IconCashier
        },
        data() {
            return {
                updateLocal: { cryptoCoin: 0, robuxCoin: 0, xp: 0, won: 0 },
                xpRaised: false,
                balanceUpdates: [],
                cashierDropdown: false,
                sfxJustPlayed: false
            };
        },
        created() {
            this.updateLocal = Object.assign(
                {},
                {
                    ...this.authUser.user.balance,
                    xp: this.authUser.user.xp,
                    won: this.authUser.user.stats.won
                }
            );

            let self = this;
            document.addEventListener('click', function (event) {
                if (!self.$el.contains(event.target) && self.cashierDropdown === true) {
                    self.cashierSetDropdown(false);
                }
            });
        },
        methods: {
            ...mapActions(['modalsSetShow', 'userSetBalanceType']),
            cashierSetDropdown(value) {
                this.cashierDropdown = value;
            }
        },
        computed: {
            ...mapGetters(['authUser', 'userBalanceData', 'soundDeposit', 'soundVolume']),
            navbarGetBalance() {
                return parseFloat(Math.floor(this.authUser.user.balance / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            changeUserBalanceType(type) {
                this.userSetBalanceType(type);
            }
        },
        watch: {
            'authUser.user': {
                deep: true,
                handler(data) {
                    const xpRaised = data.xp > this.updateLocal.xp;
                    if (xpRaised) this.xpRaised = true;

                    const gained =
                        xpRaised || this.xpRaised || data.stats.won > this.updateLocal.won;
                    const robuxDiff = data.balance.robuxCoin - this.updateLocal.robuxCoin;
                    const cryptoDiff = data.balance.cryptoCoin - this.updateLocal.cryptoCoin;
                    const id = new Date().getTime();
                    const newUpdates = [];

                    if (cryptoDiff > 0)
                        newUpdates.push({ coinType: 'cryptoCoin', amount: cryptoDiff / 1000, id });
                    if (robuxDiff > 0)
                        newUpdates.push({ coinType: 'robuxCoin', amount: robuxDiff / 1000, id });

                    if (gained && newUpdates.length) {
                        this.balanceUpdates = [...this.balanceUpdates, ...newUpdates];
                        this.xpRaised = false;

                        setTimeout(() => {
                            this.balanceUpdates = this.balanceUpdates.filter(
                                (update) => update.id !== id
                            );
                        }, 3000);

                        if (this.sfxJustPlayed === false) {
                            this.soundDeposit.volume = this.soundVolume;
                            this.soundDeposit.currentTime = 0;
                            this.soundDeposit.play();
                            this.sfxJustPlayed = true;
                            setTimeout(() => (this.sfxJustPlayed = false), 3000);
                        }
                    }

                    this.updateLocal = Object.assign(
                        {},
                        { ...data.balance, xp: data.xp, won: data.stats.won }
                    );
                }
            }
        }
    };
</script>

<style module>
    .navbar-cashier {
        display: flex;
        margin-left: auto;
        position: relative;
        align-items: center;
        max-width: fit-content;
        flex: 1;
        gap: 5px;
    }

    .navbar-cashier .balance-update {
        list-style-type: none;
        position: absolute;
        top: calc(100% + 0px);
        padding-top: 12px;
        right: 0;
        display: flex;
        flex-direction: column-reverse;
    }
    .navbar-cashier .balance-update span {
        font-size: 0.75em;
        font-weight: 800;
        opacity: 0.6;
    }
    .navbar-cashier .balance-update li {
        font-weight: 600;
        font-size: 14.5px;
        color: white;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: 0em;
        animation: balance-update-p 0.2s ease forwards;
    }
    .navbar-cashier .balance-update li.cryptoCoin {
        color: #05da8c;
    }
    .navbar-cashier .balance-update li.robuxCoin {
        color: #ffe254;
    }

    @keyframes balance-update-p {
        0% {
            height: 0em;
        }
        100% {
            height: 1.375em;
        }
    }

    .navbar-cashier .button-cashier {
        height: 42px;
        min-width: 42px;
        font-size: 15px;
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        filter: drop-shadow(0 2px 0 #32335f);
        gap: 7px;
        padding: 0 16px;
    }

    .navbar-cashier .button-cashier::before,
    .navbar-cashier .button-cashier::after {
        font-size: 3px;
        background: #5d56a7;
    }

    .navbar-cashier .cashier-dropdown {
        position: relative;
        max-width: 175px;
        flex: 1;
    }

    .navbar-cashier .dropdown-menu {
        max-height: 0px;
        width: 230px;
        transition: max-height 0.3s ease;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 14px);
        overflow: hidden;
    }

    .navbar-cashier .cashier-dropdown.active .dropdown-button {
        filter: unset !important;
    }

    .navbar-cashier .cashier-dropdown.active .dropdown-menu {
        max-height: 177px;
    }

    .navbar-cashier .menu-content {
        height: 140px;
        width: 100%;
        background: #0e293e;
        display: flex;
        flex-direction: column;
        padding: 7px 7px 8px 7px;
        gap: 7px;
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .navbar-cashier .menu-content::before {
        background: #19374e;
    }

    .navbar-cashier .content-item {
        filter: drop-shadow(0px 2px 0px #0f2133);
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        flex: 1;
        padding: 0px 10px;
        color: white;
        gap: 5px;
    }

    .navbar-cashier .content-item:nth-child(3) {
        justify-content: center;
        color: #93ff6d;
    }

    .navbar-cashier .content-item svg,
    .navbar-cashier .content-item img {
        margin-bottom: -2px;
    }

    .navbar-cashier .content-item > div {
        word-break: break-all;
        flex: 1;
        text-align: end;
        font-size: 11px;
        line-height: 11px;
    }

    .navbar-cashier .content-item::after {
        font-size: 3px;
        background: #12283e;
    }

    .navbar-cashier .content-item:nth-child(1)::before {
        font-size: 3px;
        background: #2f8380;
    }

    .navbar-cashier .content-item:nth-child(2)::before {
        font-size: 3px;
        background: #575a3e;
    }

    .navbar-cashier .content-item:nth-child(3)::before {
        font-size: 3px;
        background: #203e59;
    }

    .navbar-cashier .dropdown-button {
        display: flex;
        align-items: center;
        height: 44px;
        width: 100%;
        padding: 7px;
        color: white;
        gap: 7px;
    }

    .navbar-cashier .dropdown-button::before,
    .navbar-cashier .dropdown-button::after {
        background: #182c40;
        font-size: 3px;
    }

    .navbar-cashier .dropdown-button .button-amount {
        position: relative;
        margin: auto;
    }

    .navbar-cashier .button-amount > div {
        font-size: 11.5px;
        line-height: 12px;
        word-break: break-all;
        text-align: end;
    }

    .navbar-cashier .dropdown-button svg:last-child {
        transition: transform 0.3s ease;
        margin-right: 5px;
    }

    .navbar-cashier .cashier-dropdown.active .dropdown-button svg:last-child {
        transform: rotate(-180deg);
    }

    .navbar-cashier .button-icon {
        min-width: 30px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #25435e;
        font-size: 2px;
        clip-path: var(--octagonal-main);
    }

    @media screen and (max-width: 925px) {
        nav .navbar-cashier .button-cashier {
            padding: 0 14px;
        }
        nav .navbar-cashier .button-cashier p {
            display: none;
        }
    }

    @media screen and (max-width: 450px) {
        nav:global(.a) .navbar-cashier {
            margin-left: unset;
        }
    }
</style>
