<template>
    <div :class="$style['cashier-filter-amount']">
        <div :class="$style['amount-min']" class="octagonal hoverable">
            <input
                v-on:change="cashierSetValueMin"
                v-model="cashierValueMin"
                type="text"
                placeholder="Min."
            />
            <img
                v-if="
                    userBalanceData.type === 'robuxCoin' ||
                    cashierGetRouteName === 'LimitedsDeposit'
                "
                src="@/assets/img/icons/robuxCoin.svg"
            />
            <img v-else src="@/assets/img/icons/cryptoCoin.svg" />
        </div>
        <div :class="$style['amount-max']" class="octagonal hoverable">
            <input
                v-on:change="cashierSetValueMax"
                v-model="cashierValueMax"
                type="text"
                placeholder="Max."
            />
            <img
                v-if="
                    userBalanceData.type === 'robuxCoin' ||
                    cashierGetRouteName === 'LimitedsDeposit'
                "
                src="@/assets/img/icons/robuxCoin.svg"
            />
            <img v-else src="@/assets/img/icons/cryptoCoin.svg" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'CashierFilterAmount',
        data() {
            return {
                cashierValueMin: '',
                cashierValueMax: ''
            };
        },
        methods: {
            ...mapActions(['cashierSetFilterAmountMin', 'cashierSetFilterAmountMax']),
            cashierSetValueMin() {
                this.cashierSetFilterAmountMin(this.cashierValueMin);
            },
            cashierSetValueMax() {
                this.cashierSetFilterAmountMax(this.cashierValueMax);
            }
        },
        computed: {
            ...mapGetters(['userBalanceData']),
            cashierGetRouteName() {
                return this.$route.name;
            }
        }
    };
</script>

<style module>
    .cashier-filter-amount {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 215px;
        gap: 7px;
    }

    .cashier-filter-amount .amount-min img,
    .cashier-filter-amount .amount-max img {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
    }

    .cashier-filter-amount .amount-min input,
    .cashier-filter-amount .amount-max input {
        appearance: none;
        background: none;
        height: 100%;
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: white;
        font-size: 14px;
        padding-left: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cashier-filter-amount .amount-min input::placeholder,
    .cashier-filter-amount .amount-max input::placeholder {
        color: #406992;
    }

    .cashier-filter-amount .amount-min,
    .cashier-filter-amount .amount-max {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        position: relative;
        font-size: 5px;
        clip-path: var(--octagonal-main);
        background: #0a2036;
        height: 44px;
    }

    .cashier-filter-amount .amount-min::before,
    .cashier-filter-amount .amount-max::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        font-size: 5px;
        background: #112942;
        clip-path: var(--octagonal-before);
    }
</style>
