<template>
    <div :class="$style['battles-create']">
        <BattlesCreateFilters />

        <div :class="$style['create-select']">
            <button v-on:click="modalsSetShow('BattlesSelect')" :class="$style['button-add']">
                <div :class="$style['button-inner']">
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.5858 4.42169H12V7.6506H7.5858V12H4.4142V7.6506H0V4.42169H4.4142V0H7.5858V4.42169Z"
                        />
                    </svg>
                </div>
            </button>

            <BattlesBoxElement
                v-for="box of battlesGetBoxes"
                v-bind:key="box._id"
                v-bind:box="box"
            />
        </div>

        <BattlesCreateFooter />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconCursedGradient from '@/components/icons/IconCursedGradient';
    import IconTerminalGradient from '@/components/icons/IconTerminalGradient';
    import BattlesCreateFilters from '@/components/battles/BattlesCreateFilters';
    import BattlesCreateFooter from '@/components/battles/BattlesCreateFooter';
    import BattlesBoxElement from '@/components/battles/BattlesBoxElement';

    export default {
        name: 'BattlesCreate',
        components: {
            IconCursedGradient,
            IconTerminalGradient,
            BattlesCreateFilters,
            BattlesCreateFooter,
            BattlesBoxElement
        },
        methods: {
            ...mapActions(['modalsSetShow', 'battlesResetFilter'])
        },
        computed: {
            ...mapGetters(['battlesSelected']),
            battlesGetBoxes() {
                let boxes = [];

                for (const box of this.battlesSelected) {
                    if (boxes.some((element) => element._id === box._id) === false) {
                        boxes.push(box);
                    }
                }

                boxes.sort(function (a, b) {
                    return b.amount - a.amount;
                });

                return boxes;
            }
        },
        beforeRouteLeave(to, from, next) {
            this.battlesResetFilter();
            next();
        }
    };
</script>

<style module>
    .battles-create {
        width: 100%;
    }

    .battles-create .create-filter {
    }

    .battles-create .create-select {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        margin-top: 20px;
        padding: 20px;
        background: linear-gradient(
                211.46deg,
                rgba(5, 29, 48, 0.35) 2.26%,
                rgba(31, 99, 153, 0) 100.96%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        backdrop-filter: blur(2.5px);
        position: relative;
        font-size: 14px;
        gap: 7px;
        clip-path: var(--octagonal-main);
    }

    .battles-create .create-select::before {
        content: '';
        position: absolute;
        background: #0a273f;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-size: 14px;
        clip-path: var(--octagonal-before);
    }

    .battles-create .create-select button.button-add {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 19 / 26;
        width: 100%;
        position: relative;
        background: linear-gradient(10deg, #051421, #0b1e2f, #071929 110%);
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .battles-create .create-select button.button-add::before,
    .battles-create .create-select button.button-add .button-inner::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0a273f;
        font-size: 7px;
        clip-path: var(--octagonal-before);
    }

    .battles-create .create-select button.button-add .button-inner::before {
        font-size: 5px;
    }

    .battles-create .create-select button.button-add .button-inner {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        background: #10273e;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .battles-create .create-select button.button-add .button-inner svg {
        fill: #406992;
        height: 10px;
        width: 10px;
        transition: fill 0.3s ease;
    }

    .battles-create .create-select button.button-add:hover .button-inner svg {
        fill: #ffffff;
    }

    @media only screen and (max-width: 1100px) {
        .battles-create .create-select {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 950px) {
        .battles-create .create-select {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 750px) {
        .battles-create .create-select {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 550px) {
        .battles-create .create-select {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
</style>
