var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['cashier-filter-amount']},[_c('div',{staticClass:"octagonal hoverable",class:_vm.$style['amount-min']},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cashierValueMin),expression:"cashierValueMin"}],attrs:{"type":"text","placeholder":"Min."},domProps:{"value":(_vm.cashierValueMin)},on:{"change":_vm.cashierSetValueMin,"input":function($event){if($event.target.composing)return;_vm.cashierValueMin=$event.target.value}}}),(
                _vm.userBalanceData.type === 'robuxCoin' ||
                _vm.cashierGetRouteName === 'LimitedsDeposit'
            )?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg")}}):_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg")}})]),_c('div',{staticClass:"octagonal hoverable",class:_vm.$style['amount-max']},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cashierValueMax),expression:"cashierValueMax"}],attrs:{"type":"text","placeholder":"Max."},domProps:{"value":(_vm.cashierValueMax)},on:{"change":_vm.cashierSetValueMax,"input":function($event){if($event.target.composing)return;_vm.cashierValueMax=$event.target.value}}}),(
                _vm.userBalanceData.type === 'robuxCoin' ||
                _vm.cashierGetRouteName === 'LimitedsDeposit'
            )?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg")}}):_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg")}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }