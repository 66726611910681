var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['blackjack-game']},[_c('div',{class:_vm.$style['game-controls']},[(_vm.table.game.state === 'created')?_c('div',{class:_vm.$style['controls-info']},[_vm._v(" WAITING FOR PLAYERS TO JOIN... ")]):_vm._e(),(_vm.table.game.state === 'countdown')?_c('BlackjackControlsBet',{attrs:{"table":_vm.table,"blackjackChip":_vm.blackjackChip}}):_vm._e(),_c('BlackjackControlsAction',{attrs:{"table":_vm.table,"active":_vm.authUser.user !== null &&
                _vm.table.game.state === 'running' &&
                _vm.table.playersPos !== null &&
                (_vm.table.playersPos === 'all' ||
                    _vm.authUser.user._id === _vm.blackjackGetPlayer(_vm.table.playersPos).user._id) &&
                new Date().getTime() + _vm.generalTimeDiff <=
                    new Date(_vm.table.game.updatedAt).getTime() + 1000 * 13}})],1),_c('div',{class:_vm.$style['game-table']},[_c('img',{class:_vm.$style['table-chip-stack'],attrs:{"src":require("@/assets/img/blackjack/table-chip-stack.webp")}}),_c('img',{class:_vm.$style['table-card-l'],attrs:{"src":require("@/assets/img/blackjack/table-card-l.webp")}}),_c('img',{class:_vm.$style['table-card-r'],attrs:{"src":require("@/assets/img/blackjack/table-card-r.webp")}}),_c('img',{class:_vm.$style['table-logo'],attrs:{"src":require("@/assets/img/blackjack/table-logo.webp")}}),_c('img',{class:_vm.$style['table-top'],attrs:{"src":require("@/assets/img/blackjack/table.webp")}}),(['running', 'completed'].includes(_vm.table.game.state) === true)?_c('div',{class:_vm.$style['table-dealer']},[_c('transition-group',{staticClass:"d",class:_vm.$style['dealer-cards'],attrs:{"name":"dealer","tag":"div"}},_vm._l((_vm.table.game.dealerCards),function(card,index){return _c('BlackjackCard',{key:index + card.suit + card.rank,attrs:{"card":card}})}),1),(_vm.blackjackGetCardValue(_vm.table.game.dealerCards))?_c('BlackjackValue',{style:({
                    top: 84 + (_vm.table.game.dealerCards.length - 1) * 15 + 'px',
                    left: 60 + (_vm.table.game.dealerCards.length - 1) * 20 + 'px'
                }),attrs:{"value":_vm.blackjackGetCardValue(_vm.table.game.dealerCards),"state":""}}):_vm._e()],1):_vm._e(),_c('ol',{class:_vm.$style['table-seats']},_vm._l((5),function(index){return _c('BlackjackSeatElement',{key:index,attrs:{"table":_vm.table,"seat":index - 1,"blackjackChip":_vm.blackjackChip,"player":_vm.blackjackGetPlayer(index - 1)}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }