<template>
    <li
        v-bind:class="[
            $style['chat-message-element'],
            {
                [$style['element-rain']]:
                    message.type === 'rainTip' || message.type === 'rainCompleted',
                [$style['element-system']]: message.type === 'system'
            }
        ]"
    >
        <div
            v-if="message.type === 'rainTip' || message.type === 'rainCompleted'"
            :class="$style['rain-chips']"
        >
            <img :class="$style['chip']" src="@/assets/img/icons/coin.svg" alt="" />
            <img :class="$style['chip']" src="@/assets/img/icons/coin.svg" alt="" />
            <img :class="$style['chip']" src="@/assets/img/icons/coin.svg" alt="" />
        </div>
        <div
            v-if="message.type === 'rainTip' || message.type === 'rainCompleted'"
            :class="$style['element-rain']"
        >
            <!-- <span class="text-sky-gradient">{{ message.type === 'rainTip' ? 'RAIN TIP' : 'RAIN COMPLETED!' }}</span> -->
            <div :class="$style['rain-content']">
                <div v-if="message.type === 'rainTip'" :class="$style['inner-amount']">
                    <p>
                        <span>
                            {{
                                chatFormatValue(Math.abs(message.transaction.amount)).split('.')[0]
                            }} </span
                        >.{{ chatFormatValue(Math.abs(message.transaction.amount)).split('.')[1] }}
                    </p>
                </div>
                <div v-if="message.type === 'rainTip'" :class="$style['inner-type']">
                    <div :class="$style['type-site']">
                        <AvatarImage
                            v-bind:image="message.transaction.user.avatar"
                            v-bind:rank="message.transaction.user.rank"
                            v-bind:level="message.transaction.user.level"
                        />
                        <p>
                            <b>{{ message.transaction.user.username }}</b>
                            &nbsp;
                            <span class="fade-green">tipped the rain</span>
                        </p>
                    </div>
                </div>
                <p v-if="message.type !== 'rainTip'" :class="$style['end-info']" class="fade-green">
                    <span class="fade-green"><b>Rain Faded Away</b></span>
                    <br />
                    <span class="fade-green">
                        <b>{{ message.rain.participants.length }}</b>
                    </span>
                    Participants were awarded
                </p>
                <div v-if="message.type !== 'rainTip'" :class="$style['end-amount']">
                    <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                    <span class="fade-green">
                        <span>
                            {{ chatFormatValue(Math.abs(message.rain.amount)).split('.')[0] }}</span
                        >.{{ chatFormatValue(Math.abs(message.rain.amount)).split('.')[1] }}
                    </span>
                </div>
            </div>
        </div>
        <div v-else-if="message.type === 'system'" :class="$style['element-system']">
            <img src="@/assets/img/system-avatar.png" :class="$style['system-avatar']" />
            <p :class="$style['system-header']">System Message</p>
            <div v-html="chatGetParsedMessage" :class="$style['system-text']"></div>
        </div>
        <div
            v-else
            v-bind:class="[
                $style['element-message'],
                $style['message-' + (chatGetLevelColor || 'gray')],
                $style['message-' + (chatGetRank || 'user')],
                { [$style['message-vip']]: message.user.level > 90 }
            ]"
        >
            <button :class="$style['user-picture']" v-on:click="chatUserButton()">
                <AvatarImage
                    v-bind:image="message.user.avatar"
                    v-bind:rank="message.user.rank"
                    v-bind:level="message.user.level"
                />
            </button>
            <div :class="$style['element-top']">
                <button v-on:click="chatUserButton()" :class="$style['button-user']">
                    <p :class="$style['user-username']">
                        <span
                            :class="$style['owner-tag']"
                            v-if="
                                'admin' === message.user.rank &&
                                (message.user.username.toLowerCase().includes('julius') ||
                                    message.user.username.toLowerCase().includes('roll') ||
                                    message.user.username.toLowerCase().includes('sam'))
                            "
                        >
                            OWNER&nbsp;
                        </span>
                        <span
                            :class="$style['partner-tag']"
                            v-else-if="'partner' === message.user.rank"
                        >
                            CREATOR&nbsp;
                        </span>
                        <span :class="$style['mod-tag']" v-else-if="'mod' === message.user.rank">
                            MOD&nbsp;
                        </span>
                        <span
                            :class="$style['vip-tag']"
                            v-else-if="message.user.level > 90 && message.user.rank !== 'admin'"
                        >
                            V.I.P.&nbsp;
                        </span>
                        {{ message.user.username }}
                    </p>
                    <BoxLevel
                        v-if="['admin', 'mod', 'partner'].includes(message.user.rank) === false"
                        v-bind:rank="message.user.rank"
                        v-bind:level="message.user.level"
                        v-bind:color="chatGetLevelColor"
                    />
                    <BoxRank v-if="chatGetRank !== null" v-bind:rank="chatGetRank" />
                </button>
                <button
                    v-if="
                        authUser.user !== null &&
                        (authUser.user.rank === 'admin' || authUser.user.rank === 'mod')
                    "
                    v-on:click="chatMuteButton()"
                    :class="$style['button-mute']"
                >
                    <div :class="$style['button-inner']">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                            />
                        </svg>
                    </div>
                </button>
                <button
                    v-if="
                        authUser.user !== null &&
                        (authUser.user.rank === 'admin' || authUser.user.rank === 'mod')
                    "
                    v-on:click="chatRemoveButton()"
                    :class="$style['button-remove']"
                >
                    <div :class="$style['button-inner']">
                        <svg
                            width="10"
                            height="9"
                            viewBox="0 0 10 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.2977 9C0.216509 9 0.144339 8.97429 0.0811908 8.92286C0.0270636 8.86286 0 8.79429 0 8.71714C0 8.66571 0.0135318 8.61857 0.0405954 8.57572L2.88227 4.39714L0.324763 0.424286C0.2977 0.372857 0.284168 0.325714 0.284168 0.282857C0.284168 0.205714 0.311231 0.141429 0.365359 0.0900004C0.428507 0.0300001 0.500677 0 0.581867 0H3.51827C3.77086 0 3.96031 0.107143 4.0866 0.321429L5.03383 1.89L6.06225 0.321429C6.18854 0.107143 6.37799 0 6.63058 0H9.43166C9.51286 0 9.58051 0.0300001 9.63464 0.0900004C9.69779 0.141429 9.72936 0.205714 9.72936 0.282857C9.72936 0.334286 9.71583 0.381428 9.68877 0.424286L7.07713 4.35857L9.95941 8.57572C9.98647 8.61857 10 8.66571 10 8.71714C10 8.79429 9.96843 8.86286 9.90528 8.92286C9.85115 8.97429 9.78349 9 9.7023 9H6.65765C6.41407 9 6.22914 8.90143 6.10284 8.70429L4.92558 6.98143L3.80244 8.70429C3.67614 8.90143 3.4912 9 3.24763 9H0.2977Z"
                            />
                        </svg>
                    </div>
                </button>
            </div>
            <div
                v-if="chatGetGameLink === null"
                v-html="chatGetParsedMessage"
                :class="$style['element-text']"
            ></div>
            <button v-else v-on:click="chatLinkButton()" :class="$style['button-game']">
                <div :class="$style['button-inner']">
                    <svg
                        v-if="chatGetGameLink.includes('duels')"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2.88875 6.73564L5.10999 9.02443L4.22187 9.9397L5.11125 10.8556L4.2225 11.7709L2.66688 10.1688L0.888749 12L0 11.0847L1.77813 9.25293L0.222502 7.65155L1.11125 6.73629L2 7.6509L2.88812 6.73564H2.88875ZM0.34318 0L2.57197 0.00194187L9.99937 7.65155L10.8887 6.73629L11.7775 7.65155L10.2225 9.25357L12 11.0847L11.1113 12L9.33312 10.1688L7.7775 11.7709L6.88875 10.8556L7.7775 9.9397L0.345066 2.28556L0.34318 0ZM9.42992 0L11.6568 0.00194187L11.6581 2.28232L9.11062 4.90512L6.88812 2.61697L9.42992 0Z"
                            fill="url(#paint0_linear_3734_2952)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_3734_2952"
                                x1="12"
                                y1="0"
                                x2="-2.09436"
                                y2="3.45416"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <svg
                        v-else
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.04221 13.0746L1.80154 10.4543C1.64642 10.3767 1.54845 10.2182 1.54845 10.0448V7.68898L4.03654 8.93304C4.48764 9.15859 5.0186 9.15859 5.46971 8.93304L5.5057 8.91504L7.04218 7.9698L7.04221 13.0746ZM7.95782 13.0746V7.96977L9.49429 8.91501L9.53029 8.93301C9.9814 9.15856 10.5124 9.15856 10.9635 8.93301L13.4515 7.68895V10.0448C13.4515 10.2182 13.3536 10.3767 13.1985 10.4543L7.95782 13.0746ZM1.4404 3.86436L0.379758 3.33406C0.040542 3.16445 -0.0969626 2.75197 0.0726454 2.41275C0.139096 2.27985 0.246857 2.17209 0.379758 2.10564L4.44601 0.07253C4.63934 -0.0241359 4.8669 -0.0241359 5.0602 0.07253L7.10347 1.09416C6.95856 1.12629 6.8166 1.17628 6.68101 1.24409L1.4404 3.86436ZM7.8965 1.09413L9.93977 0.0724994C10.1331 -0.0241665 10.3607 -0.0241665 10.554 0.0724994L14.6202 2.10561C14.7531 2.17206 14.8609 2.27982 14.9273 2.41272C15.0969 2.75194 14.9595 3.16442 14.6202 3.33403L13.5596 3.86433L8.31893 1.244C8.18334 1.17625 8.04142 1.12627 7.8965 1.09413ZM5.06026 8.11403C4.86693 8.2107 4.63937 8.2107 4.44607 8.11403L0.379787 6.08092C0.246886 6.01447 0.139125 5.90671 0.0726742 5.77381C-0.0969337 5.4346 0.0405413 5.02211 0.379787 4.85248L1.44043 4.32217L6.83749 7.0207L5.06026 8.11403ZM14.6202 4.85248C14.9595 5.02208 15.097 5.43457 14.9274 5.77381C14.8609 5.90671 14.7531 6.01447 14.6202 6.08092L10.554 8.11403C10.3607 8.2107 10.1331 8.2107 9.93979 8.11403L8.16257 7.0207L13.5596 4.32217L14.6202 4.85248ZM11.97 4.09328L7.50001 6.32828L3.02998 4.09328L7.09054 2.063C7.34832 1.93411 7.65171 1.93411 7.90952 2.063L11.97 4.09328Z"
                            fill="black"
                        />
                        <path
                            d="M7.04221 13.0746L1.80154 10.4543C1.64642 10.3767 1.54845 10.2182 1.54845 10.0448V7.68898L4.03654 8.93304C4.48764 9.15859 5.0186 9.15859 5.46971 8.93304L5.5057 8.91504L7.04218 7.9698L7.04221 13.0746ZM7.95782 13.0746V7.96977L9.49429 8.91501L9.53029 8.93301C9.9814 9.15856 10.5124 9.15856 10.9635 8.93301L13.4515 7.68895V10.0448C13.4515 10.2182 13.3536 10.3767 13.1985 10.4543L7.95782 13.0746ZM1.4404 3.86436L0.379758 3.33406C0.040542 3.16445 -0.0969626 2.75197 0.0726454 2.41275C0.139096 2.27985 0.246857 2.17209 0.379758 2.10564L4.44601 0.07253C4.63934 -0.0241359 4.8669 -0.0241359 5.0602 0.07253L7.10347 1.09416C6.95856 1.12629 6.8166 1.17628 6.68101 1.24409L1.4404 3.86436ZM7.8965 1.09413L9.93977 0.0724994C10.1331 -0.0241665 10.3607 -0.0241665 10.554 0.0724994L14.6202 2.10561C14.7531 2.17206 14.8609 2.27982 14.9273 2.41272C15.0969 2.75194 14.9595 3.16442 14.6202 3.33403L13.5596 3.86433L8.31893 1.244C8.18334 1.17625 8.04142 1.12627 7.8965 1.09413ZM5.06026 8.11403C4.86693 8.2107 4.63937 8.2107 4.44607 8.11403L0.379787 6.08092C0.246886 6.01447 0.139125 5.90671 0.0726742 5.77381C-0.0969337 5.4346 0.0405413 5.02211 0.379787 4.85248L1.44043 4.32217L6.83749 7.0207L5.06026 8.11403ZM14.6202 4.85248C14.9595 5.02208 15.097 5.43457 14.9274 5.77381C14.8609 5.90671 14.7531 6.01447 14.6202 6.08092L10.554 8.11403C10.3607 8.2107 10.1331 8.2107 9.93979 8.11403L8.16257 7.0207L13.5596 4.32217L14.6202 4.85248ZM11.97 4.09328L7.50001 6.32828L3.02998 4.09328L7.09054 2.063C7.34832 1.93411 7.65171 1.93411 7.90952 2.063L11.97 4.09328Z"
                            fill="url(#paint0_linear_3734_2977)"
                        />
                        <path
                            d="M7.04221 13.0746L1.80154 10.4543C1.64642 10.3767 1.54845 10.2182 1.54845 10.0448V7.68898L4.03654 8.93304C4.48764 9.15859 5.0186 9.15859 5.46971 8.93304L5.5057 8.91504L7.04218 7.9698L7.04221 13.0746ZM7.95782 13.0746V7.96977L9.49429 8.91501L9.53029 8.93301C9.9814 9.15856 10.5124 9.15856 10.9635 8.93301L13.4515 7.68895V10.0448C13.4515 10.2182 13.3536 10.3767 13.1985 10.4543L7.95782 13.0746ZM1.4404 3.86436L0.379758 3.33406C0.040542 3.16445 -0.0969626 2.75197 0.0726454 2.41275C0.139096 2.27985 0.246857 2.17209 0.379758 2.10564L4.44601 0.07253C4.63934 -0.0241359 4.8669 -0.0241359 5.0602 0.07253L7.10347 1.09416C6.95856 1.12629 6.8166 1.17628 6.68101 1.24409L1.4404 3.86436ZM7.8965 1.09413L9.93977 0.0724994C10.1331 -0.0241665 10.3607 -0.0241665 10.554 0.0724994L14.6202 2.10561C14.7531 2.17206 14.8609 2.27982 14.9273 2.41272C15.0969 2.75194 14.9595 3.16442 14.6202 3.33403L13.5596 3.86433L8.31893 1.244C8.18334 1.17625 8.04142 1.12627 7.8965 1.09413ZM5.06026 8.11403C4.86693 8.2107 4.63937 8.2107 4.44607 8.11403L0.379787 6.08092C0.246886 6.01447 0.139125 5.90671 0.0726742 5.77381C-0.0969337 5.4346 0.0405413 5.02211 0.379787 4.85248L1.44043 4.32217L6.83749 7.0207L5.06026 8.11403ZM14.6202 4.85248C14.9595 5.02208 15.097 5.43457 14.9274 5.77381C14.8609 5.90671 14.7531 6.01447 14.6202 6.08092L10.554 8.11403C10.3607 8.2107 10.1331 8.2107 9.93979 8.11403L8.16257 7.0207L13.5596 4.32217L14.6202 4.85248ZM11.97 4.09328L7.50001 6.32828L3.02998 4.09328L7.09054 2.063C7.34832 1.93411 7.65171 1.93411 7.90952 2.063L11.97 4.09328Z"
                            fill="url(#paint1_linear_3734_2977)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_3734_2977"
                                x1="15"
                                y1="-0.915223"
                                x2="-1.7964"
                                y2="4.48546"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#3B7EB7" />
                                <stop offset="1" stop-color="#145081" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_3734_2977"
                                x1="15"
                                y1="0"
                                x2="-2.30787"
                                y2="4.86635"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                        </defs>
                    </svg>
                    {{ chatGetGameLink.includes('duels') ? 'DICE DUEL' : 'CASE BATTLE' }}
                </div>
            </button>
        </div>
    </li>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { emojis } from '@/utils/emojis';
    import IconRainGradient from '@/components/icons/IconRainGradient';
    import IconSystem from '@/components/icons/IconSystem';
    import AvatarImage from '@/components/AvatarImage';
    import BoxLevel from '@/components/BoxLevel';
    import BoxRank from '@/components/BoxRank';

    export default {
        name: 'ChatMessageElement',
        components: {
            IconRainGradient,
            IconSystem,
            AvatarImage,
            BoxLevel,
            BoxRank
        },
        props: ['message'],
        methods: {
            ...mapActions([
                'modalsSetShow',
                'modalsSetData',
                'generalSetSidebarMobile',
                'generalSetUserInfoData'
            ]),
            chatFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            chatUserButton() {
                if (this.authUser.user === null || this.message.user.rank === 'system') {
                    return;
                }

                this.generalSetUserInfoData(this.message.user);
                this.modalsSetShow('ChatUser');
            },
            chatMuteButton() {
                this.generalSetUserInfoData(this.message.user);
                this.modalsSetShow('Mute');
            },
            chatRemoveButton() {
                this.modalsSetData({ user: this.message.user, message: this.message });
                this.modalsSetShow('Remove');
            },
            async chatLinkButton() {
                this.generalSetSidebarMobile(null);
                if (this.chatGetGameLink !== this.$route.fullPath) {
                    this.$router.replace(this.chatGetGameLink);
                }
            }
        },
        computed: {
            ...mapGetters(['authUser']),
            chatGetParsedMessage() {
                if (this.message.message.includes(':') !== true) return this.message.message;

                let message = '';

                const splittedMessage = this.message.message.split(/(:)/).filter((_) => _);
                let i = 0;
                while (i < splittedMessage.length) {
                    const item = splittedMessage[i];

                    if (item !== ':') {
                        message += item;
                        i += 1;
                    } else {
                        const nextItem = splittedMessage?.[i + 1];
                        const nextAheadItem = splittedMessage?.[i + 2];
                        if (nextItem === ':' || nextAheadItem !== ':') {
                            message += ':';
                            i += 1;
                        } else {
                            const emoji = emojis.find((item) => item.name === nextItem);
                            if (emoji === undefined) {
                                message += `:${nextItem}`;
                                i += 2;
                            } else {
                                message += `<span class="${this.$style['special-emoji']}"><img height="22.5" src="/emojis/${emoji.name}.${emoji.extension}"></span>`;
                                i += 3;
                            }
                        }
                    }
                }

                return message;
            },
            chatGetLevelColor() {
                let color = '';

                if (this.message.user.level >= 2 && this.message.user.level < 26) {
                    color = 'blue';
                } else if (this.message.user.level >= 26 && this.message.user.level < 51) {
                    color = 'sky';
                } else if (this.message.user.level >= 51 && this.message.user.level < 76) {
                    color = 'yellow';
                } else if (this.message.user.level >= 76 && this.message.user.level <= 90) {
                    color = 'purple';
                } else if (this.message.user.level > 90) {
                    color = 'gold';
                }

                return color;
            },
            chatGetRank() {
                let rank = this.message.user.rakeback;

                if (this.message.user.rank !== 'user') rank = this.message.user.rank;

                return rank;
            },
            chatGetGameLink() {
                let link = null;

                if (
                    this.message.message
                        .replace(/&#x2F;/g, '/')
                        .match(/(rollbet\.com\/battles\/[0-9a-fA-F]{24})/) !== null ||
                    this.message.message
                        .replace(/&#x2F;/g, '/')
                        .match(/(rollbet\.com\/duels\?game=[0-9a-fA-F]{24})$/) !== null
                ) {
                    link = this.message.message
                        .replace(/&#x2F;/g, '/')
                        .replace(/.*rollbet\.com/, '');
                }

                return link;
            }
        }
    };
</script>

<style module>
    .chat-message-element {
        padding: 14px 14px 0 14px;
        width: 100%;
        position: relative;
        z-index: 0;
    }

    .chat-message-element.element-rain .rain-chips {
        position: absolute;
        display: flex;
        align-items: center;
        filter: drop-shadow(0 0 15px #00ff5733);
        justify-content: space-between;
        width: 75px;
        transform: translateX(-50%);
        left: 50%;
        top: 0;
    }

    .chat-message-element.element-rain .rain-chips .chip {
        width: 22px;
        transform: rotate(0deg) translateY(-7px);
    }

    .chat-message-element.element-rain .rain-chips .chip:first-child {
        width: 14px;
        transform: rotate(-15deg);
    }

    .chat-message-element.element-rain .rain-chips .chip:last-child {
        width: 14px;
        transform: rotate(15deg);
    }

    .chat-message-element:has(.element-rain) {
        background-image: url('~@/assets/img/rain-background.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: top center;
    }

    .chat-message-element.element-rain {
        padding: 0px;
        margin: 14px 14px 0 14px;
        width: calc(100% - 28px);
    }

    .chat-message-element.element-rain > * {
        position: relative;
        z-index: 1;
    }

    .chat-message-element.element-rain::before {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, #1e6057, #1e6145);
        width: 100%;
        height: 100%;
        font-size: 7px;
        clip-path: var(--octagonal-before);
        left: 0;
        top: 0;
    }

    .chat-message-element.element-rain::after {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, #1b564a, transparent);
        width: 100%;
        height: 100%;
        font-size: 7px;
        clip-path: var(--octagonal-after);
        left: 0;
        top: 0;
    }

    .chat-message-element:first-of-type {
        margin-top: 0;
    }

    ul .chat-message-element:last-child:not(.element-rain) {
        padding-bottom: 14px;
    }

    ul .chat-message-element.element-rain:last-child {
        margin-bottom: 14px;
    }

    .chat-message-element .element-rain {
        width: 100%;
    }

    .chat-message-element .element-system,
    .chat-message-element .element-message {
        padding: 6px 14px 14px 30px;
        width: calc(100% - 14px);
        position: relative;
        margin-left: 14px;
    }

    .chat-message-element .element-system > *,
    .chat-message-element .element-message > * {
        position: relative;
        z-index: 1;
    }

    .chat-message-element .element-system::before,
    .chat-message-element .element-message::before {
        clip-path: var(--octagonal-before);
        position: absolute;
        background: #092740;
        font-size: 7px;
        height: 100%;
        width: 100%;
        content: '';
        z-index: 0;
        left: 0;
        top: 0;
    }

    .chat-message-element .element-system::after,
    .chat-message-element .element-message::after {
        clip-path: var(--octagonal-after);
        position: absolute;
        background: transparent;
        font-size: 7px;
        height: 100%;
        width: 100%;
        content: '';
        z-index: 0;
        left: 0;
        top: 0;
    }

    .chat-message-element .element-system::before {
        background: #563327;
    }

    .chat-message-element .element-system::after {
        background: #232731;
    }

    .chat-message-element .element-message.message-admin::before {
        background: #037a64;
    }

    .chat-message-element .element-message.message-admin::after {
        background: #052d3c;
    }

    .chat-message-element .element-message.message-partner::before {
        background: #08243b;
    }

    .chat-message-element .element-message.message-partner::after {
        background: #072137;
    }

    .chat-message-element .element-message:is(.message-user).message-vip::before {
        background: linear-gradient(0deg, #545434, #21312f);
    }

    .chat-message-element .element-message:is(.message-user).message-vip::after {
        background: #21312f;
    }

    .chat-message-element .element-message.message-mod::before {
        background: #0f3051;
    }

    .chat-message-element .element-message.message-mod::after {
        background: #052142;
    }

    .chat-message-element .system-avatar,
    .chat-message-element .element-message .user-picture {
        transform: translate(0%, -50%);
        position: absolute;
        height: 37px;
        width: 37px;
        left: -14px;
        top: 50%;
    }

    .chat-message-element .element-message .user-picture img {
        border-width: 2px;
        height: 37px;
        width: 37px;
    }

    .chat-message-element .system-avatar {
        border: 1px solid #ff5c00;
        border-radius: 100%;
    }

    .chat-message-element .system-header {
        display: flex;
        align-items: center;
        color: #ff5c00;
        font-size: 14px;
        font-weight: 800;
        height: 25px;
    }

    .chat-message-element .system-text {
        width: 100%;
        color: white;
        font-size: 14px;
        font-weight: 400;
    }

    .chat-message-element .rain-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        padding: 28px 30px 14px 30px;
    }

    .rain-content .end-info {
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 500;
    }

    .rain-content .end-amount {
        display: flex;
        gap: 7px;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 800;
        font-size: 14px;
        width: 100%;
        max-width: 195px;
        height: 26px;
    }

    .rain-content .end-amount > img {
        transform: translateY(1px);
        width: 13px;
    }

    .rain-content .end-amount > * {
        position: relative;
        z-index: 1;
    }

    .rain-content .end-amount::before,
    .rain-content .end-amount::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        z-index: 0;
        font-size: 5px;
        left: 0;
        top: 0;
    }

    .rain-content .end-amount::before {
        clip-path: var(--octagonal-before);
        background: linear-gradient(0deg, #2f8873, transparent);
    }

    .rain-content .end-amount::after {
        clip-path: var(--octagonal-after);
        background: #15414a;
    }

    .rain-content .inner-amount {
        display: flex;
        align-items: center;
        width: calc(100% + 40px);
        mask-image: linear-gradient(90deg, transparent, white 40%, white 60%, transparent);
        font-weight: 800;
        font-size: 15px;
        color: white;
        margin-bottom: -4px;
        gap: 30px;
    }

    .rain-content .inner-amount::before,
    .rain-content .inner-amount::after {
        content: '';
        position: relative;
        flex: 1;
        background: #01e5a8;
        height: 1px;
    }

    .rain-content .inner-type {
        width: 100%;
        display: flex;
        margin-bottom: 2px;
        justify-content: center;
    }

    .rain-content .type-site {
        width: 100%;
        height: 100%;
        display: flex;
        color: white;
        font-size: 14px;
        height: 23px;
        gap: 7px;
        justify-content: center;
        align-items: center;
    }

    .rain-content .type-site img {
        height: 23px;
        width: 23px;
    }

    .chat-message-element .content-tip,
    .chat-message-element .content-completed {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #79afa1;
    }

    .chat-message-element .rain-content span.content-username,
    .chat-message-element .rain-content span.content-participants {
        font-weight: 700;
        color: #ffffff;
    }

    .chat-message-element .tip-amount,
    .chat-message-element .completed-amount {
        height: 18px;
        position: relative;
        display: inline-block;
        padding-left: 21px;
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .chat-message-element .tip-amount img,
    .chat-message-element .completed-amount img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 1.5px;
        left: 1px;
    }

    .chat-message-element .tip-amount span,
    .chat-message-element .completed-amount span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .chat-message-element .element-top {
        display: flex;
        flex-wrap: wrap;
        min-height: 25px;
        gap: 5px;
        align-items: center;
    }

    .chat-message-element button.button-user {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-right: auto;
    }

    .chat-message-element button.button-user p.user-username {
        max-width: 138px;
        font-size: 14px;
        font-weight: 700;
        margin-right: 2px;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .chat-message-element .element-message.message-bronze button.button-user p.user-username,
    .chat-message-element .element-message.message-silver button.button-user p.user-username,
    .chat-message-element .element-message.message-gold button.button-user p.user-username,
    .chat-message-element .element-message.message-platinum button.button-user p.user-username,
    .chat-message-element .element-message.message-titanium button.button-user p.user-username {
        /* max-width: 85px; */
    }

    /* .chat-message-element .element-message.message-vip button.button-user p.user-username {
        background: linear-gradient(255deg, #d89c43 0%, #d6b233 100%), #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    } */

    .chat-message-element
        .element-message.message-partner
        button.button-user
        p.user-username
        .partner-tag {
        color: #e34343;
    }

    .chat-message-element .element-message.message-mod button.button-user p.user-username .mod-tag {
        color: #4c94e4;
    }

    .chat-message-element
        .element-message.message-admin
        button.button-user
        p.user-username
        .owner-tag {
        background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .chat-message-element .element-message.message-vip button.button-user p.user-username .vip-tag {
        background: linear-gradient(250deg, #ffb547, #ffeea6 76%), #f1cc0a;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .chat-message-element button.button-mute,
    .chat-message-element button.button-remove {
        width: 25px;
        height: 25px;
        position: relative;
    }

    .chat-message-element button.button-mute .button-inner,
    .chat-message-element button.button-remove .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3px;
        font-weight: 800;
        color: #ffffff;
        clip-path: var(--octagonal-main);
    }

    .chat-message-element button.button-mute .button-inner {
        background: #f9c201;
    }

    .chat-message-element button.button-remove .button-inner {
        background: #f55046;
    }

    .chat-message-element button.button-mute .button-inner svg,
    .chat-message-element button.button-remove .button-inner svg {
        width: 10px;
        fill: #ffffff;
    }

    .chat-message-element .element-text {
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #99afbe;
    }

    .chat-message-element .special-emoji img,
    .chat-message-element .special-emoji img {
        margin-bottom: -5px;
    }

    .chat-message-element .element-chatGetParsedMessage-admin .element-text {
        color: #01f3b9;
    }

    .chat-message-element button.button-game {
        height: 34px;
        margin-top: 8px;
        padding: 1px;
        background: #01ad6f;
        clip-path: polygon(
            6px 0,
            calc(100% - 6px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 6px) 100%,
            6px 100%,
            0 75%,
            0 25%
        );
    }

    .chat-message-element button.button-game .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 14px;
        font-size: 13px;
        font-weight: 800;
        color: #01f3b9;
        background: #042f3b;
        clip-path: polygon(
            6px 0,
            calc(100% - 6px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 6px) 100%,
            6px 100%,
            0 75%,
            0 25%
        );
    }

    .chat-message-element button.button-game .button-inner svg {
        margin-right: 6px;
    }
</style>
