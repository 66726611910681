var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['crash-game']},[_c('div',{ref:"crashGraph",class:_vm.$style['game-graph']},[_c('canvas',{attrs:{"id":"crash-graph"}})]),_c('div',{class:_vm.$style['game-info']},[_c('div',{class:_vm.$style['info-network']},[_c('div',{class:_vm.$style['network-point']}),_c('span',[_vm._v("NETWORK STATUS")])]),_c('div',{class:_vm.$style['info-profit']},[_c('transition',{attrs:{"name":"fade"}},[(_vm.crashProfitInfo === true)?_c('div',{class:_vm.$style['profit-amount']},[(_vm.userBalanceData.type === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}):(_vm.userBalanceData.type === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}}):_vm._e(),_vm._v(" 250,000 ")]):_vm._e()]),_c('button',{on:{"click":function($event){return _vm.crashSetProfitInfo(!_vm.crashProfitInfo)}}},[_c('IconInfo'),_vm._v(" MAX PROFIT ")],1)],1)]),(_vm.crashGame !== null)?_c('div',{class:_vm.$style['game-inner']},[(_vm.crashGame.state === 'completed')?_c('div',{class:_vm.$style['inner-completed']},[_c('div',{class:_vm.$style['completed-multiplier']},[_vm._v(" "+_vm._s(parseFloat(_vm.crashGame.outcome / 100).toFixed(2))+" ")]),_c('div',{class:_vm.$style['completed-over']},[_vm._v("ROUND OVER")])]):(_vm.crashGame.state === 'rolling')?_c('div',{class:_vm.$style['inner-rolling']},[_c('div',{class:_vm.$style['rolling-multiplier']},[_c('span',{staticClass:"fade-green"},[_vm._v(" "+_vm._s(parseFloat(Math.floor(_vm.crashMultiplier / 1000) / 100).toFixed(2))+" ")])]),_c('div',{class:_vm.$style['rolling-payout']},[_vm._v("CURRENT PAYOUT")]),_c('transition',{attrs:{"name":"fade"}},[(
                        _vm.authUser.user !== null &&
                        _vm.crashBets.some(
                            (element) =>
                                element.user._id === _vm.authUser.user._id &&
                                element.multiplier === undefined
                        ) === true
                    )?_c('div',{class:_vm.$style['rolling-amount']},[(_vm.crashGetCoinType === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}):(_vm.crashGetCoinType === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}}):_vm._e(),_c('div',{class:_vm.$style['amount-value']},[_c('span',[_vm._v("+"+_vm._s(_vm.crashFormatValue(_vm.crashGetPayoutAmount).split('.')[0]))]),_vm._v("."+_vm._s(_vm.crashFormatValue(_vm.crashGetPayoutAmount).split('.')[1])+" ")])]):_vm._e()])],1):_c('div',{class:_vm.$style['inner-waiting']},[_c('div',{class:_vm.$style['waiting-status']},[_vm._v("PREPARING ROUND")]),_c('div',{class:_vm.$style['waiting-timer']},[_vm._v(_vm._s(_vm.crashText))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }