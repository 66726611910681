export function getRakebackRankFromXP(xp) {
    const betAmount = xp / 1000;
    let rakeback = null;

    if (betAmount >= 100000 && betAmount < 250000) {
        rakeback = 'bronze';
    } else if (betAmount >= 250000 && betAmount < 500000) {
        rakeback = 'silver';
    } else if (betAmount >= 500000 && betAmount < 1000000) {
        rakeback = 'gold';
    } else if (betAmount >= 1000000 && betAmount < 2000000) {
        rakeback = 'ice';
    } else if (betAmount >= 2000000) {
        rakeback = 'fire';
    }

    return rakeback;
}

export function getNextRakebackRankFromXP(xp) {
    const betAmount = xp / 1000;
    let rakeback = 'bronze';

    if (betAmount >= 100000 && betAmount < 250000) {
        rakeback = 'silver';
    } else if (betAmount >= 250000 && betAmount < 500000) {
        rakeback = 'gold';
    } else if (betAmount >= 500000 && betAmount < 1000000) {
        rakeback = 'ice';
    } else if (betAmount >= 1000000 && betAmount < 2000000) {
        rakeback = 'fire';
    } else if (betAmount >= 2000000) {
        rakeback = 'fire';
    }

    return rakeback;
}

export function getXPFromRakebackRank(rank) {
    let xp = 0;

    if (rank === 'bronze') {
        return (xp = 100000);
    } else if (rank === 'silver') {
        return (xp = 250000);
    } else if (rank === 'gold') {
        return (xp = 500000);
    } else if (rank === 'ice') {
        return (xp = 1000000);
    } else if (rank === 'fire') {
        return (xp = 2000000);
    }

    return xp * 1000;
}

export function getPercentFromRakebackRank(rank) {
    let percent = 0;

    if (rank === 'bronze') {
        return (percent = 0.05);
    } else if (rank === 'silver') {
        return (percent = 0.1);
    } else if (rank === 'gold') {
        return (percent = 0.15);
    } else if (rank === 'ice') {
        return (percent = 0.2);
    } else if (rank === 'fire') {
        return (percent = 0.25);
    }

    return percent;
}

export function getLevelFromXP(xp) {
    let level = Math.floor(Math.pow(xp / 1000 / 100, 1 / 3));
    return level;
}

export function getColorFromLevel(level) {
    let color = '';

    if (level >= 2 && level < 26) {
        color = 'blue';
    } else if (level >= 26 && level < 51) {
        color = 'sky';
    } else if (level >= 51 && level < 76) {
        color = 'yellow';
    } else if (level >= 76 && level <= 90) {
        color = 'purple';
    } else if (level > 90) {
        color = 'gold';
    }

    return color;
}

export function getColorFromXP(xp) {
    let level = getLevelFromXP(xp);
    return getColorFromLevel(level);
}
