<template>
    <div :class="$style['cashier-gift-tab']">
        <a
            :class="$style['tab-gift']"
            v-for="(gift, index) in giftCards"
            v-bind:key="index"
            v-bind:href="gift.link"
            target="_blank"
        >
            <img v-bind:src="gift.image" v-bind:alt="gift.alt" />
        </a>
    </div>
</template>

<script>
    export default {
        name: 'CashierCryptoTab',
        data() {
            return {
                giftCards: [
                    {
                        alt: '$3',
                        link: 'https://www.kinguin.net/category/250255/rollbet-3-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/3.png')
                    },
                    {
                        alt: '$5',
                        link: 'https://www.kinguin.net/category/250477/rollbet-5-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/5.png')
                    },
                    {
                        alt: '$10',
                        link: 'https://www.kinguin.net/category/250478/rollbet-10-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/10.png')
                    },
                    {
                        alt: '$25',
                        link: 'https://www.kinguin.net/category/250479/rollbet-25-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/25.png')
                    },
                    {
                        alt: '$50',
                        link: 'https://www.kinguin.net/category/250480/rollbet-50-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/50.png')
                    },
                    {
                        alt: '$100',
                        link: 'https://www.kinguin.net/category/250481/rollbet-100-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/100.png')
                    },
                    {
                        alt: '$250',
                        link: 'https://www.kinguin.net/category/250482/rollbet-250-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/250.png')
                    },
                    {
                        alt: '$500',
                        link: 'https://www.kinguin.net/category/250483/rollbet-500-gift-card?referrer=rollbet.gg',
                        image: require('@/assets/img/kinguin/500.png')
                    }
                ]
            };
        }
    };
</script>

<style module>
    .cashier-gift-tab {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-bottom: 9px;
        display: grid;
        width: 100%;
        gap: 12px;
    }

    .cashier-gift-tab .tab-gift {
        border-radius: 8px;
        overflow: hidden;
        aspect-ratio: 5/3;
        position: relative;
        width: 100%;
    }

    .cashier-gift-tab .tab-gift img {
        height: 100%;
        width: 100%;
    }

    .cashier-gift-tab .tab-gift::before {
        content: '';
        position: absolute;
        left: -100%;
        top: 50%;
        width: 100%;
        height: 1000px;
        animation: tab-gift 1.5s ease-out;
        background: linear-gradient(90deg, transparent, #ffffff11, transparent);
        transform: translateY(-50%) rotate(45deg);
    }

    .cashier-gift-tab .tab-gift:nth-child(2)::before,
    .cashier-gift-tab .tab-gift:nth-child(4)::before {
        animation: tab-gift 1.5s ease-out 0.21s;
    }

    .cashier-gift-tab .tab-gift:nth-child(3)::before,
    .cashier-gift-tab .tab-gift:nth-child(5)::before,
    .cashier-gift-tab .tab-gift:nth-child(7)::before {
        animation: tab-gift 1.5s ease-out 0.42s;
    }

    .cashier-gift-tab .tab-gift:nth-child(6)::before,
    .cashier-gift-tab .tab-gift:nth-child(8)::before {
        animation: tab-gift 1.5s ease-out 0.63s;
    }

    .cashier-gift-tab .tab-gift:is(:hover)::before,
    .cashier-gift-tab .tab-gift:is(:active)::before,
    .cashier-gift-tab .tab-gift:is(:focus)::before {
        animation: tab-gift 1.5s ease-out infinite;
    }

    @keyframes tab-gift {
        0% {
            left: -100%;
        }
        70% {
            left: 200%;
        }
        70.01% {
            left: -100%;
        }
        100% {
            left: 200%;
        }
    }

    @media screen and (max-width: 600px) {
        .cashier-gift-tab {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .cashier-gift-tab .tab-gift:nth-child(2)::before,
        .cashier-gift-tab .tab-gift:nth-child(3)::before {
            animation: tab-gift 1.5s ease-out 0.21s;
        }

        .cashier-gift-tab .tab-gift:nth-child(4)::before,
        .cashier-gift-tab .tab-gift:nth-child(5)::before {
            animation: tab-gift 1.5s ease-out 0.42s;
        }

        .cashier-gift-tab .tab-gift:nth-child(6)::before,
        .cashier-gift-tab .tab-gift:nth-child(7)::before {
            animation: tab-gift 1.5s ease-out 0.63s;
        }

        .cashier-gift-tab .tab-gift:nth-child(8)::before {
            animation: tab-gift 1.5s ease-out 0.84s;
        }
    }
</style>
