export const bots = [
    {
        username: 'SnoopCat',
        avatar: '/bots/snoopcat.png'
    },
    {
        username: 'Al Qaida',
        avatar: '/bots/al-qaida.png'
    },
    {
        username: 'Bombaclat',
        avatar: '/bots/bombaclat.png'
    },
    {
        username: 'Trump',
        avatar: '/bots/trunmp.png'
    },
    {
        username: '9/Eleven',
        avatar: '/bots/911.png'
    },
    {
        username: 'Biden',
        avatar: '/bots/biden.png'
    },
    {
        username: 'Hawk Tuah',
        avatar: '/bots/hawk-tuah.png'
    },
    {
        username: 'SaddamHussein',
        avatar: '/bots/saddam-hussein.png'
    },
    {
        username: 'BinLaden',
        avatar: '/bots/big-laden.png'
    }
];

export function getUniqueBot(key = Math.random() * 100) {
    const string = typeof key === 'object' ? JSON.stringify(key) : key.toString();
    const number = string
        .toString()
        .split('')
        .reduce((hash, char) => hash + char.charCodeAt(0), 0);
    return bots[number % bots.length];
}
