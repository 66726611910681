import { render, staticRenderFns } from "./MinesTile.vue?vue&type=template&id=4aae23f1&scoped=true"
import script from "./MinesTile.vue?vue&type=script&lang=js"
export * from "./MinesTile.vue?vue&type=script&lang=js"
import style0 from "./MinesTile.vue?vue&type=style&index=0&id=4aae23f1&prod&scoped=true&lang=css"
import style1 from "./MinesTile.vue?vue&type=style&index=1&id=4aae23f1&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4aae23f1",
  null
  
)

export default component.exports