<template>
    <div :class="$style['chat-messages']">
        <div
            v-on:scroll="chatHandleScroll()"
            :class="$style['messages-content']"
            ref="chatMessages"
        >
            <transition name="fade" mode="out-in">
                <div
                    v-if="chatMessages.data === null || chatMessages.loading === true"
                    :class="$style['content-loading']"
                    key="loading"
                >
                    <div
                        v-for="index in 4"
                        v-bind:key="index"
                        :class="$style['loading-placeholder']"
                    >
                        <div :class="$style['placeholder-user']">
                            <div :class="$style['user-avatar']"></div>
                            <div :class="$style['user-username']"></div>
                        </div>
                        <div :class="$style['placeholder-text']"></div>
                    </div>
                </div>
                <ul v-else :class="$style['content-list']" key="list">
                    <ChatMessageElement
                        v-for="message in chatMessages.data"
                        v-bind:key="message._id"
                        v-bind:message="{
                            ...message,
                            message: chatCensorApply(message.message || '')
                        }"
                    />
                </ul>
            </transition>
        </div>
        <div :class="$style['content-lock']"></div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ChatMessageElement from '@/components/chat/ChatMessageElement';

    export default {
        name: 'Chat',
        components: {
            ChatMessageElement
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'modalsSetShow',
                'modalsSetData',
                'generalSetSidebarMobile',
                'chatSetScroll',
                'chatGetMessagesSocket',
                'chatSendMessageSocket',
                'chatSendClearSocket',
                'chatSendLockSocket',
                'generalSetUserInfoData',
                'generalGetUserInfoSocket',
                'generalSendRainCreateSocket'
            ]),
            chatCensorApply(msg) {
                return msg;
            },
            chatScrollToBottom() {
                this.$nextTick(() => {
                    let container = this.$refs.chatMessages;
                    container.scrollTop = container.scrollHeight;
                });
            },
            chatHandleScroll() {
                let container = this.$refs.chatMessages;
                this.chatSetScroll(
                    container.scrollHeight - (container.scrollTop + container.clientHeight) < 100
                );
            }
        },
        computed: {
            ...mapGetters(['generalSidebarMobile', 'authUser', 'chatScroll', 'chatMessages'])
        },
        watch: {
            'chatMessages.data': {
                handler(state, oldState) {
                    const message = this.chatMessages.data[this.chatMessages.data.length - 1];

                    if (
                        this.chatScroll === true ||
                        this.chatMessages.data.length === 0 ||
                        (this.authUser.user !== null &&
                            message !== undefined &&
                            message.type === 'user' &&
                            message.user._id === this.authUser.user._id)
                    ) {
                        setTimeout(() => {
                            this.chatScrollToBottom();
                        }, 125);
                    }
                },
                deep: true
            },
            generalSidebarMobile: {
                handler() {
                    if (this.generalSidebarMobile === 'Chat') {
                        this.chatScrollToBottom();
                    }
                }
            }
        },
        updated() {},
        mounted() {
            setTimeout(() => {
                this.chatScrollToBottom();
            }, 500);
        }
    };
</script>

<style scoped>
    .fade-enter-active {
        transition: opacity 0.1s;
    }

    .fade-enter-from {
        opacity: 0;
    }
</style>

<style module>
    .chat-messages {
        width: 100%;
        flex: 1;
        position: relative;
    }

    .messages-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding-top: 14px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scroll-behavior: smooth;
        transition: padding-top 0.3s ease;
    }

    .chat-rain .chat-messages .messages-content {
        padding-top: 144px;
    }

    .content-content::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .content-content::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .content-loading {
        width: 100%;
        padding: 0 15px;
    }

    .content-loading.fade-leave-active {
        transition: opacity 0.2s;
    }

    .content-loading.fade-leave-to {
        opacity: 0;
    }

    .content-lock {
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .chat-rain .content-lock {
        bottom: 157px;
        z-index: 100;
    }

    .content-lock button.button-lock {
        height: 30px;
        position: relative;
        padding: 1px;
        filter: drop-shadow(0px 2px 10px rgba(1, 230, 169, 0.15))
            drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
        z-index: 1;
    }

    .content-lock button.button-lock::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #01e1a4 100%);
        clip-path: polygon(
            7px 0,
            calc(100% - 7px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 7px) 100%,
            7px 100%,
            0 75%,
            0 25%
        );
        z-index: -1;
    }

    .content-lock button.button-lock .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 15px;
        font-size: 10px;
        font-weight: 700;
        color: #ffffff;
        background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.128) 0%,
                rgba(0, 33, 24, 0.132) 25%,
                rgba(0, 99, 73, 0.144986) 60%,
                rgba(1, 193, 143, 0.0925409) 80%,
                rgba(1, 237, 176, 0.068) 100%
            )
            #064552;
        clip-path: polygon(
            7px 0,
            calc(100% - 7px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 7px) 100%,
            7px 100%,
            0 75%,
            0 25%
        );
    }

    .loading-placeholder {
        width: 100%;
        margin-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #0e3350;
    }

    .loading-placeholder:first-of-type {
        margin-top: 0;
    }

    .placeholder-user {
        display: flex;
        align-items: center;
    }

    .user-avatar {
        width: 37px;
        height: 37px;
        position: relative;
        margin-right: 13px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #083357;
    }

    .user-username {
        width: 75px;
        height: 20px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        background-color: #083357;
    }

    .placeholder-text {
        width: 100%;
        height: 19px;
        position: relative;
        margin-top: 8px;
        border-radius: 5px;
        overflow: hidden;
        background-color: #083357;
    }

    .user-avatar::after,
    .user-username::after,
    .placeholder-text::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(
            to right,
            #ffffff00 0%,
            rgba(255, 255, 255, 0.1) 50%,
            #ffffff00 100%
        );
    }

    .content-list {
        list-style-type: none;
        width: 100%;
        height: 100%;
    }
</style>
