var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
        _vm.$style['crash-history-element'],
        {
            [_vm.$style['element-high']]: _vm.crashGetOutcome >= 10,
            [_vm.$style['element-mid']]: _vm.crashGetOutcome < 10 && _vm.crashGetOutcome >= 2
        }
    ],on:{"click":_vm.crashInfoButton}},[_c('div',{class:_vm.$style['element-inner']},[_c('span',[_vm._v(_vm._s(_vm.crashGetOutcome)+"x")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }