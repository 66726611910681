var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-item modal-tip"},[_vm._m(0),_c('div',{staticClass:"tip-avatar"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.generalUserInfo.data === null || _vm.generalUserInfo.loading === true)?_c('div',{staticClass:"avatar-loading"}):_c('div',{staticClass:"avatar-content",class:[
                    'content-' + _vm.modalGetLevelColor,
                    'content-' + _vm.generalUserInfo.data.rank
                ]},[_c('AvatarImage',{attrs:{"image":_vm.generalUserInfo.data.avatar,"rank":_vm.generalUserInfo.data.rank,"level":_vm.generalUserInfo.data.level}})],1)])],1),_c('div',{staticClass:"tip-username"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.generalUserInfo.data === null || _vm.generalUserInfo.loading === true)?_c('div',{staticClass:"username-loading"}):_c('div',{staticClass:"username-content"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.generalUserInfo.data.username)}}),(
                        ['admin', 'mod', 'partner'].includes(_vm.generalUserInfo.data.rank) ===
                        false
                    )?_c('BoxLevel',{attrs:{"level":_vm.generalUserInfo.data.level,"color":_vm.modalGetLevelColor}}):_vm._e(),(_vm.modalGetRank !== null)?_c('BoxRank',{attrs:{"rank":_vm.modalGetRank}}):_vm._e()],1)])],1),_c('div',{staticClass:"tip-date"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.generalUserInfo.data === null || _vm.generalUserInfo.loading === true)?_c('div',{staticClass:"date-loading"}):_c('div',{staticClass:"date-content"},[_vm._v("Member since "+_vm._s(_vm.modalGetDate))])])],1),_c('div',{staticClass:"tip-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalAmount),expression:"modalAmount"}],attrs:{"type":"text","placeholder":"TIP AMOUNT"},domProps:{"value":(_vm.modalAmount)},on:{"input":[function($event){if($event.target.composing)return;_vm.modalAmount=$event.target.value},_vm.modalValidateInput]}}),(_vm.userBalanceData.type === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}):(_vm.userBalanceData.type === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}}):_vm._e(),_c('button',{staticClass:"button-tip",attrs:{"disabled":_vm.socketSendLoading !== null},on:{"click":_vm.modalTipButton}},[_c('div',{staticClass:"button-inner"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.socketSendLoading === 'UserTip')?_c('ButtonLoading',{key:"loading"}):_c('div',{key:"content",staticClass:"inner-content"},[_vm._v("TIP")])],1)],1)])]),_c('div',{staticClass:"tip-info"},[_vm._v(" Please note that this action is irreversable and you are the only one responsible. We cannot and will not refund any tips, therefore double check your tip amount and verify who you are tipping to. ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-title"},[_c('span',{staticClass:"fade-green"},[_vm._v("TIP USERS")])])
}]

export { render, staticRenderFns }