<template>
    <div
        :class="[
            $style['battles-box-element'],
            { [$style['element-selected']]: battlesSelectedCount >= 1 }
        ]"
    >
        <div :class="$style['element-name']">
            {{ box.name }}
        </div>
        <div :class="$style['element-price']">
            <img
                v-if="userBalanceData.type === 'cryptoCoin'"
                src="@/assets/img/icons/cryptoCoin.svg"
                alt="icon"
            />
            <img
                v-else-if="userBalanceData.type === 'robuxCoin'"
                src="@/assets/img/icons/robuxCoin.svg"
                alt="icon"
            />
            <div :class="$style['price-value']">
                <span>{{ battlesFormatValue(box.amount).split('.')[0] }}</span
                >.{{ battlesFormatValue(box.amount).split('.')[1] }}
            </div>
        </div>
        <div :class="$style['element-image']" v-on:click="battlesBoxButton">
            <img v-bind:src="unboxImagePath + '/public/img/' + box.slug + '.png'" />
        </div>
        <div v-if="battlesSelectedCount === 0" :class="$style['element-select']">
            <div :class="$style['button-placeholder']">
                <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.0572 2.94779H8V5.1004H5.0572V8H2.9428V5.1004H0V2.94779H2.9428V0H5.0572V2.94779Z"
                        fill="#406992"
                    />
                </svg>
            </div>
            <button v-on:click="battlesAddButton()" :class="$style['button-add']">
                <span>Add Case</span>
            </button>
        </div>
        <div v-else :class="$style['element-count']">
            <button v-on:click="battlesDecreaseButton()" :class="$style['button-decrease']">
                <svg
                    width="7"
                    height="2"
                    viewBox="0 0 7 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 2V0H7V2H0Z" />
                </svg>
            </button>
            {{ battlesSelectedCount }}
            <button v-on:click="battlesIncreaseButton()" :class="$style['button-increase']">
                <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.0572 2.94779H8V5.1004H5.0572V8H2.9428V5.1004H0V2.94779H2.9428V0H5.0572V2.94779Z"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BattlesBoxElement',
        props: ['box', 'returnModal'],
        data() {
            return {
                unboxImagePath: process.env.VUE_APP_BACKEND_URL
            };
        },
        methods: {
            ...mapActions([
                'battlesAddSelected',
                'battlesRemoveSelected',
                'battlesEmptySelected',
                'modalsSetShow',
                'modalsSetData'
            ]),
            battlesBoxButton() {
                const data = { box: this.box };
                if (this.returnModal) data.returnModal = this.returnModal;
                this.modalsSetData(data);
                this.modalsSetShow('Box');
            },
            battlesFormatValue(value) {
                if (value.toString().length > 9)
                    return (
                        parseFloat(Math.floor(value / 10) / 100000000)
                            .toFixed(3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm'
                    );
                else
                    return parseFloat(Math.floor(value / 10) / 100)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            battlesAddButton() {
                this.battlesAddSelected(this.box);
            },
            battlesIncreaseButton() {
                this.battlesAddSelected(this.box);
            },
            battlesDecreaseButton() {
                this.battlesRemoveSelected(this.box);
            }
        },
        computed: {
            ...mapGetters(['battlesSelected', 'userBalanceData']),
            battlesSelectedCount() {
                return this.battlesSelected.filter((element) => element._id === this.box._id)
                    .length;
            }
        }
    };
</script>

<style module>
    .battles-box-element {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px;
        gap: 10px;
        aspect-ratio: 19 / 26;
        width: 100%;
        position: relative;
        background: linear-gradient(10deg, #051421, #0b1e2f, #071929 110%);
        font-size: 7px;
        clip-path: var(--octagonal-main);
        transition: filter 0.1s ease;
        filter: brightness(1);
    }

    .battles-box-element:hover {
        filter: brightness(1.5);
    }

    .battles-box-element::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0a273f;
        font-size: 7px;
        clip-path: var(--octagonal-before);
    }

    .battles-box-element .element-name {
        width: 100%;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        text-align: center;
        color: white;
    }

    .battles-box-element .element-price {
        position: relative;
        background: linear-gradient(
            360deg,
            rgba(15, 66, 107, 0.5) 11.31%,
            rgba(13, 51, 85, 0.5) 104.17%
        );
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        padding: 4px;
        gap: 3px;
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .battles-box-element .price-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .battles-box-element .price-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-box-element .element-image {
        flex: 1;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        cursor: zoom-in;
    }

    .battles-box-element .element-image img {
        position: absolute;
        left: 0;
        top: 2%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 0.1s ease;
        transform: scale(0.9) rotate(0deg);
    }

    .battles-box-element:hover .element-image img {
        transform: scale(1) rotate(-5deg);
    }

    .battles-box-element .element-image:hover img {
        transform: scale(0.95) rotate(1deg);
    }

    .battles-box-element .element-count {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-box-element .element-count button.button-decrease,
    .battles-box-element .element-count button.button-increase {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 3px;
        clip-path: var(--octagonal-main);
        background: linear-gradient(0deg, #0d2d47, #0d2d47),
            linear-gradient(360deg, rgba(15, 66, 107, 0.35) 8.33%, rgba(13, 51, 85, 0.35) 108.33%);
    }

    .battles-box-element .element-count button.button-decrease::before,
    .battles-box-element .element-count button.button-increase::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #1e4b7e25;
        font-size: 3px;
        clip-path: var(--octagonal-before);
    }

    .battles-box-element .element-count button.button-decrease svg,
    .battles-box-element .element-count button.button-increase svg {
        fill: #6e95b6;
        transition: fill 0.3s ease;
    }

    .battles-box-element .element-count button.button-decrease:hover svg,
    .battles-box-element .element-count button.button-increase:hover svg {
        fill: #ffffff;
    }

    .battles-box-element .element-select {
        height: 30px;
        width: 100%;
        position: relative;
    }

    .battles-box-element .button-add,
    .battles-box-element .button-placeholder {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(0deg, #0d2d47, #0d2d47),
            linear-gradient(360deg, rgba(15, 66, 107, 0.35) 8.33%, rgba(13, 51, 85, 0.35) 108.33%);
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .battles-box-element .button-add::before,
    .battles-box-element .button-placeholder::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #1e4b7e25;
        font-size: 3px;
        clip-path: var(--octagonal-before);
    }

    .battles-box-element .button-add {
        display: none;
    }

    .battles-box-element:hover .button-add {
        display: block;
    }

    .battles-box-element .button-add span {
        color: white;
        font-size: 14px;
        font-weight: 500;
    }

    .battles-box-element .button-add {
        filter: brightness(0.8);
        background: linear-gradient(
                89.34deg,
                rgba(0, 0, 0, 0.128) -31.79%,
                rgba(0, 33, 24, 0.132) 43.25%,
                rgba(0, 99, 73, 0.144986) 161.11%,
                rgba(1, 193, 143, 0.0925409) 219.68%,
                rgba(1, 237, 176, 0.068) 274.8%
            ),
            linear-gradient(246.21deg, #00ffc2 -139.41%, #00aa6d 100.64%);
    }

    .battles-box-element .button-add::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) -6.47%, #1effc2 77.59%);
    }

    @media screen and (max-width: 475px) {
        .battles-box-element {
            padding: 15px;
        }
    }
</style>
