<template>
    <div
        v-bind:class="[
            $style['battles-game-element'],
            $style['element-' + game.state],
            $style['element-' + game.mode],
            {
                [$style['element-cursed']]: game.options.cursed === true,
                [$style['element-terminal']]: game.options.terminal === true
            }
        ]"
    >
        <div :class="$style['element-inner']">
            <div :class="$style['inner-info']">
                <div
                    v-if="game.options.cursed === true"
                    :class="[$style['info-option'], $style['option-cursed']]"
                >
                    <IconCursedGradient />
                </div>
                <div
                    v-if="game.options.terminal === true"
                    :class="[$style['info-option'], $style['option-terminal']]"
                >
                    <IconTerminalGradient />
                </div>
                <div
                    v-if="game.mode === 'group'"
                    :class="[$style['info-option'], $style['option-group']]"
                >
                    <IconGroupGradient />
                </div>
                <div :class="$style['info-type']">
                    <div :class="$style['type-inner']">
                        <span>{{ battlesGetMode }}</span>
                    </div>
                </div>
            </div>
            <div :class="$style['inner-players']">
                <div
                    v-for="(bet, index) in battlesGetBets"
                    v-bind:key="index"
                    :class="$style['players-element']"
                >
                    <div
                        v-bind:class="[
                            $style['element-user'],
                            $style[
                                'user-' +
                                    (bet === null
                                        ? 'empty'
                                        : bet.bot === true
                                          ? 'bot'
                                          : battlesGetRank(bet.user))
                            ],
                            $style[
                                'user-' +
                                    (bet !== null && bet.bot !== true
                                        ? battlesGetLevelColor(bet.user)
                                        : '')
                            ],
                            {
                                [$style['user-winner']]:
                                    game.state === 'completed' && bet.payout > 0
                            }
                        ]"
                    >
                        <AvatarImage
                            v-if="bet !== null"
                            v-bind:image="
                                bet.bot === true
                                    ? battlesGetBot(game._id + index).avatar
                                    : bet.user.avatar
                            "
                            v-bind:rank="bet.bot ? null : bet.user.rank"
                            v-bind:level="bet.bot ? null : bet.user.level"
                        />
                        <IconSpinner v-else />
                    </div>
                    <div
                        v-if="
                            index + 1 !== game.playerCount && (game.mode !== 'team' || index === 1)
                        "
                        :class="$style['element-separator']"
                    >
                        <div :class="$style['separator-inner']">
                            <IconGroupGradient v-if="game.mode === 'group'" />
                            <IconVersusGradient v-else />
                        </div>
                    </div>
                </div>
            </div>
            <div :class="$style['inner-cases']">
                <BattlesGameBoxes v-bind:game="game" />
            </div>
            <div :class="$style['inner-right']">
                <div :class="$style['right-amount']">
                    <div :class="$style['amount-inner']">
                        <div :class="$style['inner-effective']">
                            <img
                                v-if="battlesGameCoinType === 'cryptoCoin'"
                                src="@/assets/img/icons/cryptoCoin.svg"
                                alt="icon"
                            />
                            <img
                                v-else-if="battlesGameCoinType === 'robuxCoin'"
                                src="@/assets/img/icons/robuxCoin.svg"
                                alt="icon"
                            />
                            <div :class="$style['effective-value']">
                                <span>
                                    {{
                                        battlesFormatValue(
                                            game.amount -
                                                Math.floor(
                                                    (game.amount * game.options.funding) / 100
                                                )
                                        ).split('.')[0]
                                    }} </span
                                >.{{
                                    battlesFormatValue(
                                        game.amount -
                                            Math.floor((game.amount * game.options.funding) / 100)
                                    ).split('.')[1]
                                }}
                            </div>
                        </div>
                        <div v-if="game.options.funding > 0" :class="$style['inner-real']">
                            <div :class="$style['real-value']">
                                <span>{{ battlesFormatValue(game.amount).split('.')[0] }}</span
                                >.{{ battlesFormatValue(game.amount).split('.')[1] }}
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="$style['right-separator']"></div>
                <div :class="$style['right-action']">
                    <button
                        v-if="
                            ['countdown', 'pending', 'rolling'].includes(game.state) === true ||
                            (game.state !== 'completed' &&
                                authUser.user !== null &&
                                authUser.user._id === game.bets[0].user._id)
                        "
                        :class="$style['button-waiting']"
                        disabled
                    >
                        <div :class="$style['button-inner']">
                            <IconSpinner />
                        </div>
                    </button>
                    <button
                        v-else-if="game.state !== 'completed'"
                        v-on:click="battlesJoinButton()"
                        :class="$style['button-join']"
                        v-bind:disabled="socketSendLoading !== null"
                    >
                        <div v-if="game.options.funding > 0" :class="$style['button-funding']">
                            <div :class="$style['funding-inner']">
                                <span>-{{ game.options.funding }}%</span>
                            </div>
                        </div>
                        <div :class="$style['button-inner']">
                            <div :class="$style['inner-text']">
                                <svg
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.47861 7.85824L6.15342 10.5285L5.08395 11.5963L6.15493 12.6649L5.08471 13.7327L3.21144 11.8636L1.07023 14L0 12.9322L2.14121 10.7951L0.267935 8.9268L1.33816 7.859L2.40839 8.92605L3.47861 7.85824ZM0.413256 0L3.09715 0.00226551L12.0412 8.9268L13.1122 7.859L14.1824 8.9268L12.3099 10.7958L14.4503 12.9322L13.3801 14L11.2389 11.8636L9.36561 13.7327L8.29539 12.6649L9.36561 11.5963L0.415526 2.66649L0.413256 0ZM11.3554 0L14.0371 0.00226551L14.0386 2.66271L10.971 5.72264L8.29463 3.05313L11.3554 0Z"
                                    />
                                </svg>
                                JOIN
                                <span v-if="game.options.levelMin > 0">
                                    LVL +{{ game.options.levelMin }}!
                                </span>
                            </div>
                        </div>
                    </button>
                    <router-link v-bind:to="'/battles/' + game._id" :class="$style['link-view']">
                        <div :class="$style['link-inner']">
                            <svg
                                width="19"
                                height="11"
                                viewBox="0 0 19 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18.8581 4.63847C18.6885 4.44929 14.6093 0 9.49998 0C4.39071 0 0.311514 4.44929 0.141887 4.63847C-0.0472957 4.84992 -0.0472957 5.16958 0.141887 5.38103C0.311514 5.57021 4.39078 10.0195 9.49998 10.0195C14.6092 10.0195 18.6885 5.57021 18.8581 5.38103C19.0472 5.16958 19.0472 4.84992 18.8581 4.63847ZM9.49998 8.90622C7.35169 8.90622 5.60351 7.15804 5.60351 5.00975C5.60351 2.86146 7.35169 1.11328 9.49998 1.11328C11.6483 1.11328 13.3965 2.86146 13.3965 5.00975C13.3965 7.15804 11.6483 8.90622 9.49998 8.90622Z"
                                />
                                <path
                                    d="M10.0566 3.89648C10.0566 3.3365 10.3346 2.84387 10.7575 2.54088C10.378 2.34661 9.95476 2.22656 9.49999 2.22656C7.96541 2.22656 6.7168 3.47518 6.7168 5.00976C6.7168 6.54433 7.96541 7.79295 9.49999 7.79295C10.8739 7.79295 12.0111 6.78974 12.2358 5.47889C11.1148 5.83978 10.0566 4.99202 10.0566 3.89648Z"
                                />
                            </svg>
                            {{ game.state === 'completed' ? 'REPLAY' : '' }}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';
    import IconTerminalGradient from '@/components/icons/IconTerminalGradient';
    import IconCursedGradient from '@/components/icons/IconCursedGradient';
    import IconVersusGradient from '@/components/icons/IconVersusGradient';
    import IconGroupGradient from '@/components/icons/IconGroupGradient';
    import IconSpinner from '@/components/icons/IconSpinner';
    import BattlesGameBoxes from '@/components/battles/BattlesGameBoxes';
    import { getUniqueBot } from '@/utils/bots';

    export default {
        name: 'BattlesGameElement',
        props: ['game'],
        components: {
            AvatarImage,
            IconTerminalGradient,
            IconCursedGradient,
            IconVersusGradient,
            IconGroupGradient,
            IconSpinner,
            BattlesGameBoxes
        },
        methods: {
            ...mapActions(['notificationShow', 'battlesSendJoinSocket']),
            battlesGetBot(key) {
                return getUniqueBot(key);
            },
            battlesFormatValue(value) {
                if (value.toString().length > 9)
                    return (
                        parseFloat(Math.floor(value / 10) / 100000000)
                            .toFixed(3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm'
                    );
                else
                    return parseFloat(Math.floor(value / 10) / 100)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            battlesGetRank(user) {
                let rank = user.rakeback;

                if (user.rank !== 'user') {
                    rank = user.rank;
                }

                return rank;
            },
            battlesGetLevelColor(user) {
                let color = '';

                if (user.level >= 2 && user.level < 26) {
                    color = 'blue';
                } else if (user.level >= 26 && user.level < 51) {
                    color = 'sky';
                } else if (user.level >= 51 && user.level < 76) {
                    color = 'yellow';
                } else if (user.level >= 76 && user.level <= 90) {
                    color = 'purple';
                } else if (user.level > 90) {
                    color = 'gold';
                }

                return color;
            },
            battlesJoinButton() {
                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                const data = {
                    gameId: this.game._id,
                    slot: this.game.bets.length,
                    type: this.userBalanceData.type
                };
                this.battlesSendJoinSocket(data);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'authUser', 'userBalanceData']),
            battlesGetMode() {
                let mode = '1v1';

                // if (this.game.mode === 'group') {
                //     mode = 'Group';
                // } else if (this.game.mode === 'team') {
                //     mode = '2v2';
                if (this.game.mode === 'team') {
                    mode = '2v2';
                } else if (this.game.playerCount === 3) {
                    mode = '1v1v1';
                } else if (this.game.playerCount === 4) {
                    mode = '1v1v1v1';
                }

                return mode;
            },
            battlesGetOption() {
                let option = '';

                if (this.game.options.cursed === true && this.game.options.terminal === false) {
                    option = 'CURSED';
                } else if (
                    this.game.options.cursed === false &&
                    this.game.options.terminal === true
                ) {
                    option = 'TERMINAL';
                }

                return option;
            },
            battlesGetBets() {
                let bets = [];

                for (let bet = 0; bet < this.game.playerCount; bet++) {
                    const index = this.game.bets.findIndex((element) => element.slot === bet);

                    bets.push(index !== -1 ? this.game.bets[index] : null);
                }

                return bets;
            },
            battlesGameCoinType() {
                if (!this.authUser.user) return this.game.bets[0].coinType || 'cryptoCoin';
                return (
                    this.game.bets.find((element) => this.authUser.user?._id === element?.user?._id)
                        ?.coinType || this.userBalanceData.type
                );
            },
            battlesGetBoxes() {
                let boxes = [];

                for (const box of this.game.boxes) {
                    for (let i = 0; i < box.count; i++) {
                        boxes.push(box.box);
                    }
                }

                return boxes;
            }
        }
    };
</script>

<style module>
    .battles-game-element {
        width: 100%;
        height: 95px;
        margin-top: 8px;
    }

    .battles-game-element:first-child {
        margin-top: 0;
    }

    /* .battles-game-element.element-group .element-inner::before {
        background: rgba(0, 255, 194, 0.5);
    }

    .battles-game-element.element-cursed .element-inner::before {
        background: rgba(242, 64, 52, 0.5);
    }

    .battles-game-element.element-terminal .element-inner::before {
        background: rgba(234, 214, 33, 0.5);
    }

    .battles-game-element.element-cursed.element-terminal .element-inner::before {
        background: linear-gradient(90deg, rgba(210, 59, 39, 0.5) 0%, rgba(234, 214, 33, 0.5) 100%);
    } */

    .battles-game-element .element-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 7px;
        background: linear-gradient(2.5deg, #07192b, #0c243a, #0c243a, #081b2d);
        clip-path: var(--octagonal-main);
        position: relative;
    }

    .battles-game-element .element-inner::before {
        clip-path: var(--octagonal-before);
        background: #0d3051;
        font-size: 7px;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .battles-game-element .inner-info {
        width: 115px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 25px;
        flex-direction: row;
        gap: 5px 7px;
        align-items: center;
    }

    .battles-game-element .info-type,
    .battles-game-element .info-option {
        width: 66px;
        height: 36px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 5px;
        background: #0b2339;
        justify-content: center;
        clip-path: var(--octagonal-main);
        padding: 1px;
        z-index: 1;
    }

    .battles-game-element .info-type .type-inner {
        font-size: 13px;
        font-weight: 600;
        color: #406992;
    }

    .battles-game-element .info-option {
        width: 36px;
    }

    .battles-game-element .info-option > svg {
        height: 16px;
        width: 16px;
    }

    .battles-game-element .info-type:before,
    .battles-game-element .info-option:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: #0d3051;
        clip-path: var(--octagonal-before);
        font-size: 5px;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .info-option.option-terminal {
        background: #203634;
    }

    .info-option.option-terminal::before {
        background: #3d5334;
    }

    .info-option.option-cursed {
        background: #1f2736;
    }

    .info-option.option-cursed::before {
        background: #3d2d34;
    }

    .info-option.option-group {
        background: #123946;
    }

    .info-option.option-group::before {
        background: #1c5759;
    }

    .battles-game-element .inner-players {
        width: 315px;
        display: flex;
        align-items: center;
        padding-right: 32px;
    }

    .battles-game-element .players-element {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .battles-game-element .players-element:last-child {
        margin-right: 0;
    }

    .battles-game-element .element-user {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        background: #041b2d;
        overflow: hidden;
        align-items: center;
    }

    .battles-game-element.element-completed .element-user {
        opacity: 0.35;
        mix-blend-mode: luminosity;
    }

    .battles-game-element.element-completed .element-user.user-winner {
        opacity: 1;
        mix-blend-mode: normal;
    }

    .battles-game-element .element-user.user-empty svg {
        width: 14px;
        fill: #134166;
        animation: waiting_animation 1.5s infinite linear both;
    }

    .battles-game-element .element-user img {
        width: 34px;
        height: 34px;
        border-width: 1px;
    }

    .battles-game-element .element-separator {
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 10px;
        padding: 1px;
        z-index: 1;
    }

    .battles-game-element .element-separator::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .battles-game-element .separator-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .battles-game-element .separator-inner svg {
        height: 16px;
        width: 16px;
    }

    .battles-game-element .inner-cases {
        width: calc(100% - 825px);
    }

    .battles-game-element .inner-right {
        width: 375px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        gap: 5px 20px;
    }

    .battles-game-element .right-amount {
        position: relative;
        padding: 1px;
    }

    .battles-game-element .right-amount:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, #276f69, transparent);
        font-size: 5px;
        clip-path: var(--octagonal-before);
    }

    .battles-game-element .amount-inner {
        width: 100%;
        min-width: 125px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        background: #184c52;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .battles-game-element .inner-effective,
    .battles-game-element .inner-real {
        display: flex;
        align-items: center;
    }

    .battles-game-element .inner-real {
        transform: translate(20%, 70%) scale(0.8);
        position: absolute;
        margin-top: 2px;
    }

    .battles-game-element .amount-inner:has(.inner-real) .inner-effective .effective-value {
        transform: translate(0%, -10%) rotate(4deg) scale(1.05);
    }

    .battles-game-element .inner-effective img {
        width: 14px;
        height: 20px;
        margin-right: 7px;
    }

    .battles-game-element .inner-real img {
        width: 13px;
        height: 13px;
        margin-right: 6px;
        opacity: 0.5;
    }

    .battles-game-element .effective-value {
        font-size: 11px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .battles-game-element .real-value {
        position: relative;
        font-size: 9px;
        font-weight: 600;
        color: #83919c;
    }

    .battles-game-element .real-value:after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        background: #707e89;
    }

    .battles-game-element .effective-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-game-element .real-value span {
        font-size: 11px;
        font-weight: 800;
    }

    .battles-game-element .right-separator {
        width: 1px;
        height: 60px;
        background: linear-gradient(
            0deg,
            rgba(97, 112, 241, 0) 0%,
            rgba(64, 92, 212, 0.35) 50%,
            rgba(28, 71, 182, 0) 100%
        );
    }

    .battles-game-element .right-action {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        flex: 1;
    }

    .battles-game-element .right-action > *:first-child {
        flex: 1;
    }

    .battles-game-element button.button-waiting,
    .battles-game-element button.button-join {
        width: 96px;
        height: 39px;
        position: relative;
        padding: 1px;
    }

    .battles-game-element a.link-view {
        width: 39px;
        height: 39px;
    }

    .battles-game-element button.button-waiting:before,
    .battles-game-element button.button-join:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 170, 109, 0) 0%, #00ffc2 100%);
        font-size: 5px;
        clip-path: var(--octagonal-before);
    }

    .battles-game-element button.button-waiting:before {
        background: #14304d;
    }

    .battles-game-element .button-funding {
        width: 48px;
        height: 21px;
        position: absolute;
        top: -9px;
        left: -7px;
        transform: rotate(-10deg);
        padding: 1px;
        z-index: 1;
    }

    .battles-game-element a.link-view::before,
    .battles-game-element .button-funding:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #01c98c;
        clip-path: var(--octagonal-before);
        font-size: 5px;
    }

    .battles-game-element .funding-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
                242deg,
                rgba(0, 255, 194, 0.4) 16%,
                rgba(0, 231, 170, 0.22) 32%,
                rgba(0, 218, 157, 0.1) 50%,
                rgba(0, 195, 134, 0.26) 80%,
                rgba(0, 170, 109, 0.44) 100%
            ),
            linear-gradient(180deg, #073137 0%, #082538 100%);
        clip-path: var(--octagonal-main);
        font-size: 5px;
    }

    .battles-game-element .funding-inner span {
        font-size: 11px;
        font-weight: 800;
        background: linear-gradient(239deg, #00ffc2 -100%, #00aa6d 120%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .battles-game-element button.button-waiting,
    .battles-game-element a.link-view {
        clip-path: var(--octagonal-main);
        font-size: 5px;
    }

    .battles-game-element button.button-waiting .button-inner,
    .battles-game-element button.button-join .button-inner .inner-text,
    .battles-game-element a.link-view .link-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-game-element button.button-waiting {
        background: #174551;
    }

    .battles-game-element button.button-waiting::before {
        background: none;
    }
    .battles-game-element button.button-join .button-inner {
        background: linear-gradient(
                89.15deg,
                rgba(0, 0, 0, 0.128) -30.36%,
                rgba(0, 33, 24, 0.132) 41.31%,
                rgba(0, 99, 73, 0.144986) 153.9%,
                rgba(1, 193, 143, 0.0925409) 209.84%,
                rgba(1, 237, 176, 0.068) 262.49%
            ),
            linear-gradient(263.46deg, #00ffc2 -10.36%, #00aa6d 44.58%);
        height: 100%;
        width: 100%;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .battles-game-element button.button-join .button-inner .inner-text {
        font-size: 14px;
    }

    .battles-game-element a.link-view .link-inner {
        font-size: 13px;
        font-weight: 700;
        color: #5e768e;
        transition: color 0.3s ease;
    }

    .battles-game-element a.link-view:hover .link-inner {
        color: #ffffff;
    }

    .battles-game-element button.button-waiting .button-inner svg {
        fill: #00ffc2;
        animation: waiting_animation 1.5s infinite linear both;
    }

    .battles-game-element button.button-join .button-inner svg {
        margin-right: 5px;
        fill: #ffffff;
    }

    .battles-game-element a.link-view {
        background: linear-gradient(0deg, #0f426b55, #0d335555);
        position: relative;
    }

    .battles-game-element a.link-view::before {
        background: #12385e;
    }

    .battles-game-element a.link-view .link-inner svg {
        fill: #5e768e;
        transition: fill 0.3s ease;
    }

    .battles-game-element.element-completed a.link-view .link-inner svg {
        margin-right: 8px;
    }

    .battles-game-element a.link-view:hover .link-inner svg {
        fill: #ffffff;
    }

    .battles-game-element button.button-join .inner-text {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .battles-game-element button.button-join .button-inner .inner-text:has(span) {
        transform: translate(-5%, -7%) rotate(-2deg);
    }

    .battles-game-element button.button-join .inner-text span {
        transform: rotate(-3deg);
        font-size: 10px;
        font-weight: 700;
        position: absolute;
        bottom: 2px;
        right: 7px;
    }

    @keyframes waiting_animation {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media only screen and (max-width: 1100px) {
        .battles-game-element {
            height: auto;
        }

        .battles-game-element .element-inner {
            display: grid;
            grid-template-columns: 135px calc(100% - 323px) 188px;
            padding: 10px 0;
        }

        .battles-game-element .inner-info {
            grid-column: 1 / 1;
            grid-row: 1 / 3;
            margin: 0 10px;
            width: unset;
        }

        .battles-game-element .inner-players {
            width: 100%;
            grid-column: 2 / 2;
        }

        .battles-game-element .inner-cases {
            width: 100%;
            grid-column: 2 / 2;
            grid-row: 2 / 2;
            margin-top: 10px;
        }

        .battles-game-element .inner-right {
            width: 100%;
            grid-column: 3 / 3;
            grid-row: 1 / 3;
            flex-direction: column;
            padding: 0 10px 0 25px;
        }

        .battles-game-element .right-amount {
            width: 100%;
        }

        .battles-game-element .right-separator {
            display: none;
        }
    }

    @media only screen and (max-width: 625px) {
        .battles-game-element .element-inner {
            grid-template-columns: calc(100% - 170px) 170px;
            padding: 10px;
        }

        .battles-game-element .inner-info {
            display: none;
        }

        .battles-game-element .inner-players {
            grid-column: 1 / 1;
        }

        .battles-game-element .inner-cases {
            grid-column: 1 / 1;
        }

        .battles-game-element .inner-right {
            grid-column: 2 / 2;
            padding: 0 0 0 25px;
        }
    }
</style>
