<template>
    <div :class="$style['duels-game-winner']">
        <span class="fade-green">DUEL WINNER:</span>
        <div v-if="duelsGame.state !== 'completed'" :class="$style['winner-placeholder']"></div>
        <div v-else :class="$style['winner-content']">
            <div :class="$style['content-inner']">
                <div
                    v-bind:class="[
                        $style['inner-user'],
                        $style[
                            'user-' +
                                (duelsGame.winner.bot === true
                                    ? 'bot'
                                    : duelsGetRank(duelsGame.winner.user))
                        ],
                        $style[
                            'user-' +
                                (duelsGame.winner.bot === true
                                    ? ''
                                    : duelsGetLevelColor(duelsGame.winner.user))
                        ]
                    ]"
                >
                    <AvatarImage
                        v-bind:image="
                            duelsGame.winner.bot ? duelsGetBot.avatar : duelsGame.winner.user.avatar
                        "
                        v-bind:rank="duelsGame.winner.bot ? null : duelsGame.winner.user.rank"
                        v-bind:level="duelsGame.winner.bot ? null : duelsGame.winner.user.level"
                    />
                    <span
                        v-html="
                            duelsGame.winner.bot === true
                                ? duelsGetBot.username
                                : duelsGame.winner.user.username
                        "
                    ></span>
                </div>
                <div :class="$style['inner-roll']">
                    <div :class="$style['roll-inner']">
                        <span class="fade-green">
                            {{ parseFloat(duelsGame.winner.roll / 100).toFixed(2) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { getUniqueBot } from '@/utils/bots';

    export default {
        name: 'DuelsGameWinner',
        props: ['duelsGame'],
        components: {
            AvatarImage
        },
        methods: {
            duelsGetRank(user) {
                let rank = user.rakeback;

                if (user.rank !== 'user') {
                    rank = user.rank;
                }

                return rank;
            },
            duelsGetLevelColor(user) {
                let color = '';

                if (user.level >= 2 && user.level < 26) {
                    color = 'blue';
                } else if (user.level >= 26 && user.level < 51) {
                    color = 'sky';
                } else if (user.level >= 51 && user.level < 76) {
                    color = 'yellow';
                } else if (user.level >= 76 && user.level <= 90) {
                    color = 'purple';
                } else if (user.level > 90) {
                    color = 'gold';
                }

                return color;
            }
        },
        computed: {
            duelsGetBot() {
                const pos = this.duelsGame.bets.findIndex(
                    (element) => element._id === this.duelsGame.winner._id
                );
                return getUniqueBot(this.duelsGame._id + pos);
            }
        }
    };
</script>

<style module>
    .duels-game-winner {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        font-size: 14px;
        font-weight: 800;
    }

    .duels-game-winner .winner-placeholder,
    .duels-game-winner .winner-content {
        width: 460px;
        height: 78px;
        margin-top: 25px;
        border-radius: 8px;
    }

    .duels-game-winner .winner-placeholder {
        border: 1px solid #0b3354;
    }

    .duels-game-winner .winner-content {
        position: relative;
        padding: 1px;
        z-index: 1;
    }

    .duels-game-winner .winner-content:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(0, 255, 194, 0) 0%, #00ffc2 100%);
        z-index: -1;
    }

    .duels-game-winner .winner-content:after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 8px;
        background-color: #001c33;
        z-index: -1;
    }

    .duels-game-winner .content-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        border-radius: 8px;
        background: linear-gradient(
                255deg,
                rgba(0, 255, 194, 0.02) 0%,
                rgba(0, 170, 109, 0.02) 100%
            ),
            radial-gradient(170% 170% at 50% 50%, rgba(0, 255, 194, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
        box-shadow: inset 0px 5px 50px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    .duels-game-winner .inner-user {
        display: flex;
        align-items: center;
    }

    .duels-game-winner .inner-user img {
        width: 41px;
        height: 41px;
        margin-right: 12px;
    }

    .duels-game-winner .inner-user span {
        width: 100px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
    }

    .duels-game-winner .inner-roll {
        width: 70px;
        height: 35px;
        position: relative;
        padding: 1px;
        z-index: 1;
    }

    .duels-game-winner .inner-roll::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 255, 194, 0) 0%, #00ffc2 100%);
        clip-path: polygon(
            4px 0,
            calc(100% - 4px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 4px) 100%,
            4px 100%,
            0 75%,
            0 25%
        );
        z-index: -1;
    }

    .duels-game-winner .inner-roll::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background-color: #001c33;
        clip-path: polygon(
            4px 0,
            calc(100% - 4px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 4px) 100%,
            4px 100%,
            0 75%,
            0 25%
        );
        z-index: -1;
    }

    .duels-game-winner .roll-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        background: radial-gradient(
                130% 80% at 80% 50%,
                rgba(0, 255, 194, 0.2) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(255deg, rgba(0, 255, 194, 0.05) 0%, rgba(0, 170, 109, 0.05) 100%);
        clip-path: polygon(
            4px 0,
            calc(100% - 4px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 4px) 100%,
            4px 100%,
            0 75%,
            0 25%
        );
    }

    @media only screen and (max-width: 510px) {
        .duels-game-winner {
            width: 100%;
        }

        .duels-game-winner .winner-placeholder,
        .duels-game-winner .winner-content {
            width: 100%;
        }
    }
</style>
