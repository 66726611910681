<template>
    <div class="halo" :class="{ active: active === true }">
        <svg width="6.9em" viewBox="0 0 69 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M67.7764 3.4743C67.8615 3.51807 67.9286 3.55778 67.9809 3.59242C67.9286 3.62707 67.8615 3.66678 67.7764 3.71054C67.3917 3.90846 66.7811 4.11901 65.9349 4.32958C64.2536 4.748 61.7913 5.13115 58.7142 5.45505C52.5686 6.10196 44.0616 6.5035 34.6534 6.5035C25.2453 6.5035 16.7382 6.10196 10.5926 5.45505C7.51551 5.13115 5.05318 4.748 3.37189 4.32958C2.52576 4.11901 1.91517 3.90846 1.53042 3.71054C1.44535 3.66678 1.37819 3.62707 1.32589 3.59242C1.37819 3.55777 1.44535 3.51807 1.53042 3.4743C1.91517 3.27639 2.52576 3.06584 3.37189 2.85527C5.05318 2.43684 7.51551 2.0537 10.5926 1.7298C16.7382 1.08289 25.2453 0.681343 34.6534 0.681343C44.0616 0.681343 52.5686 1.08289 58.7142 1.7298C61.7913 2.0537 64.2536 2.43684 65.9349 2.85527C66.7811 3.06584 67.3917 3.27639 67.7764 3.4743Z"
                stroke="currentColor"
                stroke-width="1.36269"
            />
        </svg>
        <svg width="7.4em" viewBox="0 0 74 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_2006_49"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="74"
                height="10"
            >
                <rect width="74" height="10" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_2006_49)">
                <mask
                    id="mask1_2006_49"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="-23"
                    y="-105"
                    width="120"
                    height="120"
                >
                    <rect x="-23" y="-105" width="120" height="120" rx="60" fill="currentColor" />
                </mask>
                <g mask="url(#mask1_2006_49)">
                    <g filter="url(#filter0_i_2006_49)">
                        <path
                            d="M82.5588 5.38231C82.5588 8.02769 61.9178 10.1722 36.456 10.1722C10.9941 10.1722 -9.64679 8.02769 -9.64679 5.38231C-9.64679 2.73692 10.9941 0.592407 36.456 0.592407C61.9178 0.592407 82.5588 2.73692 82.5588 5.38231Z"
                            fill="currentColor"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <svg width="5.2em" viewBox="0 0 52 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_2006_492"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="52"
                height="6"
            >
                <rect width="52" height="6" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_2006_492)">
                <mask
                    id="mask1_2006_492"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="-34"
                    y="-114"
                    width="120"
                    height="120"
                >
                    <rect x="-34" y="-114" width="120" height="120" rx="60" fill="currentColor" />
                </mask>
                <g mask="url(#mask1_2006_492)">
                    <g filter="url(#filter0_i_2006_492)">
                        <path
                            d="M94.3108 2.36972C94.3108 6.3378 63.4835 9.55457 25.456 9.55457C-12.5714 9.55457 -43.3988 6.3378 -43.3988 2.36972C-43.3988 -1.59836 -12.5714 -4.81512 25.456 -4.81512C63.4835 -4.81512 94.3108 -1.59836 94.3108 2.36972Z"
                            fill="currentColor"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Halo',
        props: ['active']
    };
</script>

<style scoped>
    .halo {
        filter: drop-shadow(0em 0em 1em currentColor);
        transform: translate(-50%, 0);
        position: absolute;
        bottom: 0.75em;
        left: 50%;
    }

    .halo svg {
        left: 50%;
        bottom: 0;
        transition: all 0.2s ease;
        position: absolute;
    }

    .halo svg:nth-child(1) {
        transform: translate(-50%, 0%);
        bottom: 2em;
        z-index: 2;
    }

    .halo svg:nth-child(2) {
        mask-image: linear-gradient(0deg, #00000066, #000000aa);
        transform: translate(-50%, 0%);
        bottom: 1.5em;
        z-index: 1;
    }

    .halo svg:nth-child(3) {
        mask-image: linear-gradient(0deg, #00000066, #000000);
        transform: translate(-50%, 0%);
        bottom: 1em;
        z-index: 0;
    }

    .halo.active svg:nth-child(1) {
        animation: halo-float 1s ease alternate infinite 0.4s;
        width: 10em;
        bottom: 1em;
    }

    .halo.active svg:nth-child(2) {
        animation: halo-float 1s ease alternate infinite 0.2s;
        bottom: 0.75em;
        width: 7em;
    }

    .halo.active svg:nth-child(3) {
        animation: halo-float 1s ease alternate infinite;
        bottom: 0em;
        width: 4em;
    }

    @keyframes halo-float {
        0% {
            transform: translate(-50%, -25%);
        }
        100% {
            transform: translate(-50%, 25%);
        }
    }
</style>
