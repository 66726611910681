<template>
    <div :class="$style['banner-whats-new']" class="octagonal before">
        <div :class="$style['new-bg']" class="octagonal before"></div>
        <div :class="$style['new-content']">
            <h1>OUT WITH THE OLD, IN WITH THE NEW!</h1>
            <p>The New Update Just Released, Start Rolling Now!</p>
            <div :class="$style['content-link']">
                <button
                    v-on:click="modalsSetShow('WhatsNew')"
                    :class="$style['link-button']"
                    class="octagonal before hoverable"
                >
                    <span class="fade-gold">{{ "SEE WHAT'S NEW" }}</span>
                </button>
            </div>
        </div>
        <img :class="$style['new-knight']" src="@/assets/img/glory-knight.png" />
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BannerGlory',
        components: {
            AvatarImage
        },
        computed: {
            ...mapGetters(['authUser'])
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    };
</script>

<style module>
    .banner-whats-new {
        border-radius: 8px;
        justify-content: center;
        flex-direction: row;
        align-items: flex-end;
        position: relative;
        padding: 14px 75px 35px 75px;
        font-size: 10px;
        display: flex;
        height: 100%;
        width: 100%;
        gap: 34px;
    }

    .banner-whats-new .new-bg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        clip-path: var(--octagonal-main);
        font-size: 14px;
    }

    .banner-whats-new .new-bg::before {
        background: #514076;
        z-index: 1;
    }

    .banner-whats-new .new-bg::after {
        animation: background-move 6s ease alternate infinite;
        background-image: url('~@/assets/img/banner-whats-new-bg.png');
        background-position: center;
        background-size: cover;
        position: absolute;
        opacity: 0.9;
        left: 0;
        top: 0;
        width: calc(100% + 50px);
        height: 100%;
        content: '';
        z-index: -1;
    }

    @keyframes background-move {
        0% {
            left: 0;
        }
        100% {
            left: -50px;
        }
    }

    .banner-whats-new .new-knight {
        width: 222px;
        position: absolute;
        left: 75%;
        bottom: 1px;
    }

    .banner-whats-new .new-content {
        display: flex;
        flex-direction: column;
        font-weight: 700;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    .banner-whats-new .new-content h1 {
        filter: drop-shadow(0 2px 0px #00000059) drop-shadow(0.125px 0.125px 0 #00000059)
            drop-shadow(-0.125px -0.125px 0 #00000059) drop-shadow(0.125px -0.125px 0 #00000059)
            drop-shadow(-0.125px 0.125px 0 #00000059);
        font-size: 3.8em;
        line-height: 1em;
        position: relative;
        width: fit-content;
        color: white;
    }

    .banner-whats-new .new-content h1::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        user-select: none;
        pointer-events: none;
        content: 'OUT WITH THE OLD, IN WITH THE NEW!';
        background-image: linear-gradient(-45deg, transparent, #ffeb91, transparent);
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: -900% 50%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        animation: whats-new-h1 15s ease-out infinite 2s;
    }

    @keyframes whats-new-h1 {
        0% {
            background-position: -900% 50%;
        }
        10% {
            background-position: 900% 50%;
        }
        100% {
            background-position: 900% 50%;
        }
    }

    .banner-whats-new .new-content p {
        filter: drop-shadow(0.25px 0.25px 0 currentColor) drop-shadow(-0.25px 0.25px 0 currentColor)
            drop-shadow(-0.25px -0.25px 0 currentColor);
        color: rgb(95, 86, 31);
        background: linear-gradient(0.125deg, #ffb547, #ffeea6, #f1cc0a 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 2.6em;
        width: fit-content;
    }

    .banner-whats-new .content-link {
        filter: drop-shadow(0 2px 0px #00000059);
        max-width: 170px;
    }

    .banner-whats-new .link-button span {
        font-size: 15px;
    }

    .banner-whats-new .link-button {
        margin-top: 10px;
        width: 100%;
        max-width: 170px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: radial-gradient(50% 50% at center, #404041, #635947);
        font-weight: 600;
        gap: 7px;
        position: relative;
        color: white;
        clip-path: var(--octagonal-main);
        font-size: 5px;
    }

    .banner-whats-new .link-button::before {
        background: linear-gradient(90deg, #f7af3a, #fbda9c);
        font-size: 5px;
    }

    .banner-whats-new .link-button::after {
        content: '';
        position: absolute;
        left: -100%;
        top: 50%;
        width: 100%;
        height: 1000px;
        animation: link-button 15s ease-out infinite 1s;
        background: linear-gradient(90deg, transparent, #ffffff44, transparent);
        transform: translateY(-50%) rotate(45deg);
        z-index: 2;
    }

    @keyframes link-button {
        0% {
            left: -100%;
        }
        7% {
            left: 200%;
        }
        7.01% {
            left: -100%;
        }
        10% {
            left: 200%;
        }
        100% {
            left: 200%;
        }
    }

    @media screen and (max-width: 1200px) {
        .banner-whats-new {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 800px) {
        .banner-whats-new {
            padding: 14px 30px 20px 30px;
        }
    }

    @media screen and (max-width: 700px) {
        .banner-whats-new .whats-new-content {
            width: 65%;
            margin-right: auto;
            font-size: 8px;
        }

        .banner-whats-new .new-knight {
            left: 65%;
        }
    }

    @media screen and (max-width: 580px) {
        .banner-whats-new {
            padding: 14px 20px 20px 20px;
        }
        .banner-whats-new .whats-new-content {
            width: 70%;
            margin-right: auto;
            font-size: 6px;
        }
        .banner-whats-new .new-knight {
            left: 60%;
        }
    }
</style>
