<template>
    <div :class="[$style['notifications-element'], $style['element-' + notification.type]]">
        <button v-on:click="sendNotificationRemove" :class="$style['button-close']"></button>
        <div :class="$style['element-timer']">
            <div
                :class="$style['timer-progress']"
                v-bind:style="{ height: timerHeight + '%' }"
            ></div>
        </div>
        <div :class="$style['element-inner']">
            <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.5 6.42349e-07C9.12099 -0.000481784 7.75539 0.270779 6.48125 0.798283C5.20712 1.32579 4.04941 2.09919 3.0743 3.0743C2.09919 4.04941 1.32579 5.20712 0.798283 6.48125C0.270779 7.75539 -0.000481784 9.12099 6.42349e-07 10.5C-0.000481784 11.879 0.270779 13.2446 0.798283 14.5187C1.32579 15.7929 2.09919 16.9506 3.0743 17.9257C4.04941 18.9008 5.20712 19.6742 6.48125 20.2017C7.75539 20.7292 9.12099 21.0005 10.5 21C11.879 21.0005 13.2446 20.7292 14.5187 20.2017C15.7929 19.6742 16.9506 18.9008 17.9257 17.9257C18.9008 16.9506 19.6742 15.7929 20.2017 14.5187C20.7292 13.2446 21.0005 11.879 21 10.5C21.0005 9.12099 20.7292 7.75539 20.2017 6.48125C19.6742 5.20712 18.9008 4.04941 17.9257 3.0743C16.9506 2.09919 15.7929 1.32579 14.5187 0.798283C13.2446 0.270779 11.879 -0.000481784 10.5 6.42349e-07ZM10.5 18.0629C10.186 18.0637 9.87886 17.9712 9.61748 17.7972C9.3561 17.6232 9.15225 17.3755 9.03175 17.0856C8.91125 16.7956 8.87954 16.4764 8.94062 16.1684C9.0017 15.8604 9.15282 15.5775 9.37485 15.3554C9.59688 15.1334 9.87982 14.9823 10.1878 14.9212C10.4958 14.8601 10.815 14.8918 11.105 15.0123C11.3949 15.1328 11.6426 15.3367 11.8166 15.5981C11.9906 15.8595 12.0831 16.1666 12.0823 16.4806C12.0814 16.9 11.9144 17.3019 11.6178 17.5984C11.3213 17.8949 10.9194 18.062 10.5 18.0629ZM12.4201 4.99301L11.8217 13.0699C11.8179 13.1258 11.7931 13.1781 11.7522 13.2163C11.7113 13.2545 11.6574 13.2757 11.6014 13.2755H9.3986C9.34264 13.2757 9.28872 13.2545 9.24781 13.2163C9.2069 13.1781 9.18205 13.1258 9.17832 13.0699L8.5799 4.99301C8.56368 4.73087 8.60131 4.46819 8.69047 4.22114C8.77963 3.9741 8.91843 3.74793 9.09831 3.55657C9.2782 3.3652 9.49537 3.2127 9.73644 3.10846C9.9775 3.00422 10.2374 2.95044 10.5 2.95044C10.7626 2.95044 11.0225 3.00422 11.2636 3.10846C11.5046 3.2127 11.7218 3.3652 11.9017 3.55657C12.0816 3.74793 12.2204 3.9741 12.3095 4.22114C12.3987 4.46819 12.4363 4.73087 12.4201 4.99301Z"
                />
            </svg>
            <div :class="$style['inner-content']" v-html="notification.message"></div>
            <!-- <div v-html="'<span class=\'fade-gold\'>You received your deposit</span><p class=\'robux\'><span>233,192.59</span></p>'"></div> -->
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import IconClose from '@/components/icons/IconClose';

    export default {
        name: 'NotificationsElement',
        components: {
            IconClose
        },
        props: ['notification'],
        data() {
            return {
                timer: 5000,
                notificationInterval: null
            };
        },
        methods: {
            ...mapActions(['notificationRemove']),
            sendNotificationRemove() {
                this.notificationRemove(this.notification);
            }
        },
        computed: {
            getNotificationType() {
                return (
                    this.notification.type.charAt(0).toUpperCase() + this.notification.type.slice(1)
                );
            },
            timerHeight() {
                return (100 / 5000) * this.timer;
            }
        },
        created() {
            let self = this;
            const target = Date.now() + self.timer;

            this.notificationInterval = setInterval(function () {
                self.timer = target - Date.now();

                if (self.timer <= 0) {
                    clearInterval(self.notificationInterval);
                    self.notificationRemove(self.notification);
                }
            }, 1);
        },
        beforeDestroy() {
            clearInterval(this.notificationInterval);
        }
    };
</script>

<style module>
    .notifications-element {
        width: 270px;
        min-height: 63px;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-left: 5px;
        font-size: 7px;
        clip-path: var(--octagonal-main);
        background: linear-gradient(0deg, #0e253a, #0f263b, #142e46);
    }

    .notifications-element:first-of-type {
        margin-top: 0px;
    }

    .notifications-element button.button-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 100%;
    }

    .notifications-element .element-timer {
        width: 6px;
        height: 100%;
        display: flex;
        align-items: flex-end;
        position: absolute;
        left: 0;
        top: 0;
    }

    .notifications-element.element-success .element-timer {
        background-color: #365843;
    }

    .notifications-element.element-error .element-timer {
        background-color: #583636;
    }

    .notifications-element .timer-progress {
        width: 6px;
    }

    .notifications-element.element-success .timer-progress {
        background-color: #00aa6d;
    }

    .notifications-element.element-error .timer-progress {
        background-color: #e94848;
    }

    .notifications-element .element-inner {
        width: calc(100% - 3px);
        height: 100%;
        display: flex;
        align-items: flex-start;
        padding: 0 14px;
        min-height: 63px;
    }

    .notifications-element .inner-content {
        font-size: 14px;
        font-weight: 500;
        color: white;
        padding: 7px 0;
        margin: auto 0;
    }

    .notifications-element .inner-content div.crypto,
    .notifications-element .inner-content div.robux {
        display: flex;
        margin-top: 1px;
        max-width: 100%;
        width: fit-content;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 0px 10px 2px 8px;
        font-size: 2px;
        clip-path: var(--octagonal-main);
        position: relative;
        height: 22px;
        z-index: 1;
        background: #524a06;
    }

    .notifications-element .inner-content div.crypto {
        background: #064552;
    }

    .notifications-element .inner-content div.crypto p,
    .notifications-element .inner-content div.robux p {
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        z-index: 1;
    }

    .notifications-element .inner-content div.crypto p span,
    .notifications-element .inner-content div.robux p span {
        opacity: 0.75;
        font-size: 9px;
    }

    .notifications-element .inner-content div.crypto::before,
    .notifications-element .inner-content div.robux::before {
        content: '';
        position: relative;
        width: 15px;
        height: 15px;
        margin-bottom: -1.5px;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        background-image: url('~@/assets/img/icons/robuxCoin.svg');
    }

    .notifications-element .inner-content div.crypto::before {
        background-image: url('~@/assets/img/icons/cryptoCoin.svg');
    }

    .notifications-element .inner-content div.crypto::after,
    .notifications-element .inner-content div.robux::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        font-size: 2px;
        z-index: -1;
        clip-path: var(--octagonal-before);
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 24.62%,
            rgba(238, 238, 36, 0.744) 98.48%
        );
    }

    .notifications-element .inner-content div.crypto::after {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 24.62%,
            rgba(30, 255, 194, 0.35) 98.48%
        );
    }

    .notifications-element.element-success .element-inner:not(:has(.inner-content:has(div))) {
        color: #00aa6d;
        background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .notifications-element .element-inner svg {
        min-height: 38px;
        max-height: 38px;
        min-width: 38px;
        max-width: 38px;
        font-size: 5px;
        clip-path: var(--octagonal-main);
        padding: 7px;
        background: #0a1d2c;
        margin-right: 12px;
        margin-top: 12.5px;
    }

    .notifications-element.element-success .element-inner svg {
        fill: #00aa6d;
    }

    .notifications-element.element-error .element-inner svg {
        fill: #e94848;
    }
</style>
