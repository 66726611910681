<template>
    <CashierCryptoDepositTab v-if="modalsData.typeCashier === 'deposit'" />
    <CashierCryptoWithdrawTab v-else />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import CashierCryptoDepositTab from '@/components/cashier/CashierCryptoDepositTab';
    import CashierCryptoWithdrawTab from '@/components/cashier/CashierCryptoWithdrawTab';

    export default {
        name: 'CashierCryptoTab',
        components: {
            CashierCryptoDepositTab,
            CashierCryptoWithdrawTab
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'modalsSetShow',
                'modalsSetData',
                'cashierGetCryptoDataSocket'
            ]),
            modalFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            modalBackButton() {
                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetShow('Cashier');
                }, 200);
            }
        },
        computed: {
            ...mapGetters(['modalsData', 'cashierCryptoData']),
            modalGetName() {
                let name = this.modalsData.currency.toUpperCase();

                if (this.modalsData.currency === 'btc') {
                    name = 'Bitcoin';
                } else if (this.modalsData.currency === 'eth') {
                    name = 'Ethereum';
                } else if (this.modalsData.currency === 'ltc') {
                    name = 'Litecoin';
                }

                return name;
            }
        },
        created() {
            if (this.cashierCryptoData.loading === false) {
                const data = {
                    cryptoType: this.modalsData.currency,
                    cashierType: this.modalsData.typeCashier
                };

                this.cashierGetCryptoDataSocket(data);
            }
        }
    };
</script>

<style module></style>
