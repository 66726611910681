var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"octagonal after",class:[
        _vm.$style['home-game-element'],
        { [_vm.$style.extended]: _vm.isExtended, [_vm.$style.locked]: _vm.gameName === '' }
    ]},[_c('div',{class:_vm.$style['element-wrapper']},[(_vm.gameName !== '')?_c('router-link',{staticClass:"octagonal before hoverable",class:_vm.$style['element-content'],attrs:{"to":'/' + _vm.gameName}},[(_vm.gameName === 'crash')?_c('IconCrash'):(_vm.gameName === 'roll')?_c('IconRoll'):(_vm.gameName === 'blackjack')?_c('IconBlackjack'):(_vm.gameName === 'upgrader')?_c('IconUpgrader'):(_vm.gameName === 'duels')?_c('IconDuels'):(_vm.gameName === 'mines')?_c('IconMines'):(_vm.gameName === 'towers')?_c('IconTowers'):(_vm.gameName === 'unbox')?_c('IconUnbox'):(_vm.gameName === 'battles')?_c('IconBattles'):_vm._e(),_c('div',{class:_vm.$style['content-img']},[_c('img',{attrs:{"src":require(
                            '@/assets/img/games/static/' +
                                _vm.gameMode +
                                (_vm.isExtended ? '-extended' : '') +
                                '.png'
                        ),"alt":_vm.gameName}})])],1):_c('div',{staticClass:"octagonal before",class:_vm.$style['element-content']},[_c('svg',{attrs:{"width":"12","viewBox":"0 0 12 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.34753 4.41718V5.95268H1.23896C0.555615 5.95268 0 6.47553 0 7.11857V13.54C0 14.1831 0.555615 14.7059 1.23896 14.7059H10.761C11.4444 14.7059 12 14.1831 12 13.54V7.11557C12 6.47252 11.4444 5.94967 10.761 5.94967H10.7291V4.41418C10.7291 1.98022 8.6248 0 6.03832 0C3.45184 0.00300488 1.34753 1.98322 1.34753 4.41718ZM6.73443 10.6133C6.63864 10.6794 6.61309 10.7425 6.61309 10.8476C6.61628 11.3194 6.61628 11.7882 6.61628 12.2599C6.62586 12.4552 6.52049 12.6476 6.33528 12.7347C5.89782 12.945 5.46035 12.6506 5.46035 12.2599V12.2569C5.46035 11.7852 5.46035 11.3134 5.46354 10.8416C5.46354 10.7455 5.44119 10.6824 5.35178 10.6193C4.89835 10.3037 4.74827 9.76587 4.97499 9.29411C5.19532 8.83436 5.73497 8.56392 6.24268 8.66008C6.80788 8.76825 7.20064 9.19495 7.20702 9.72681C7.20702 10.0964 7.05056 10.3969 6.73443 10.6133ZM3.40074 4.41718C3.40074 3.04695 4.58542 1.93515 6.03832 1.93515C7.49441 1.93515 8.67589 3.04996 8.67589 4.41718V5.95268H3.40074V4.41718Z","fill":"#406992"}})]),_c('img',{attrs:{"src":require(
                        '@/assets/img/games/static/' +
                            _vm.gameMode +
                            (_vm.isExtended ? '-extended' : '') +
                            '.png'
                    ),"alt":_vm.gameName}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }