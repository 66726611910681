<template>
    <div :class="[$style['roll-bets'], { [$style.active]: rollBetsActive }]">
        <button v-on:click="rollBetsSetActive(false)" :class="$style['bets-overlay']"></button>
        <div v-on:click="rollBetsSetActive(true)" :class="$style['bets-wrapper']">
            <div :class="$style['wrapper-content']">
                <div :class="$style['content-highest']">
                    <div :class="$style['highest-title']">
                        <span class="fade-green">Highest Bet</span>
                        <div :class="$style['title-icon']">
                            <img width="13" src="@/assets/img/icons/cryptoCoin.svg" />
                        </div>
                        <div :class="$style['title-icon']">
                            <img width="16" src="@/assets/img/icons/robuxCoin.svg" />
                        </div>
                    </div>
                    <div :class="$style['highest-content']">
                        <RollBetsElement
                            v-if="rollGetBets[0] !== undefined"
                            v-bind:bet="rollGetBets[0]"
                            v-bind:high="true"
                        />
                    </div>
                </div>
                <div :class="$style['content-players']">
                    <div :class="$style['players-title']">
                        <span>All Bets</span>
                        <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.5 0.500427C2.91052 0.500427 0 3.41038 0 7.00014C0 10.5899 2.91024 13.4999 6.5 13.4999C10.09 13.4999 13 10.5899 13 7.00014C13 3.41038 10.09 0.500427 6.5 0.500427ZM6.5 2.44392C7.68774 2.44392 8.6502 3.40667 8.6502 4.59383C8.6502 5.78128 7.68774 6.74375 6.5 6.74375C5.31284 6.74375 4.35037 5.78128 4.35037 4.59383C4.35037 3.40667 5.31284 2.44392 6.5 2.44392ZM6.49857 11.8005C5.31398 11.8005 4.22903 11.3691 3.39219 10.655C3.18833 10.4811 3.0707 10.2262 3.0707 9.95863C3.0707 8.75462 4.04516 7.79101 5.24945 7.79101H7.75112C8.9557 7.79101 9.92645 8.75462 9.92645 9.95863C9.92645 10.2264 9.80939 10.4808 9.60524 10.6547C8.76869 11.3691 7.68345 11.8005 6.49857 11.8005Z"
                                fill="black"
                            />
                            <path
                                d="M6.5 0.500427C2.91052 0.500427 0 3.41038 0 7.00014C0 10.5899 2.91024 13.4999 6.5 13.4999C10.09 13.4999 13 10.5899 13 7.00014C13 3.41038 10.09 0.500427 6.5 0.500427ZM6.5 2.44392C7.68774 2.44392 8.6502 3.40667 8.6502 4.59383C8.6502 5.78128 7.68774 6.74375 6.5 6.74375C5.31284 6.74375 4.35037 5.78128 4.35037 4.59383C4.35037 3.40667 5.31284 2.44392 6.5 2.44392ZM6.49857 11.8005C5.31398 11.8005 4.22903 11.3691 3.39219 10.655C3.18833 10.4811 3.0707 10.2262 3.0707 9.95863C3.0707 8.75462 4.04516 7.79101 5.24945 7.79101H7.75112C8.9557 7.79101 9.92645 8.75462 9.92645 9.95863C9.92645 10.2264 9.80939 10.4808 9.60524 10.6547C8.76869 11.3691 7.68345 11.8005 6.49857 11.8005Z"
                                fill="url(#paint0_linear_624_1809)"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_624_1809"
                                    x1="13"
                                    y1="0.500427"
                                    x2="-2.26882"
                                    y2="4.24258"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#00FFC2" />
                                    <stop offset="1" stop-color="#00AA6D" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <span class="fade-green">{{ rollGetPlayerCount }}</span>

                        <div :class="$style['title-icon']">
                            <img width="13" src="@/assets/img/icons/cryptoCoin.svg" />
                        </div>
                        <div :class="$style['title-icon']">
                            <img width="16" src="@/assets/img/icons/robuxCoin.svg" />
                        </div>
                    </div>
                    <div :class="$style['players-wrapper']">
                        <div :class="$style['wrapper-list']">
                            <RollBetsElement
                                v-for="bet of rollGetBets.slice(1, rollGetBets.length)"
                                v-bind:key="bet._id"
                                v-bind:bet="bet"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import IconUsers from '@/components/icons/IconUsers';
    import RollBetsElement from '@/components/roll/RollBetsElement';

    export default {
        name: 'RollBets',
        components: {
            IconUsers,
            RollBetsElement
        },
        data() {
            return {
                rollBetsActive: false
            };
        },
        methods: {
            rollBetsSetActive(bool) {
                this.rollBetsActive = !!bool;
            },
            rollFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString();
            }
        },
        computed: {
            ...mapGetters(['rollGame', 'rollBets']),
            rollGetBets() {
                let bets = [];

                for (let bet of this.rollBets) {
                    const index = bets.findIndex(
                        (element) =>
                            element.user._id === bet.user._id &&
                            element.baseAmount === bet.amount &&
                            element.multiplier === bet.multiplier &&
                            element.coinType === bet.coinType
                    );

                    if (index !== -1) {
                        bets[index].amount = bets[index].amount + bet.amount;
                    } else {
                        bet = { ...bet, baseAmount: bet.amount };
                        bets.push(bet);
                    }
                }

                bets.sort(function (a, b) {
                    return +b.amount - +a.amount;
                });

                return bets;
            },
            rollGetPlayerCount() {
                let players = [];

                for (const bet of this.rollBets) {
                    if (players.includes(bet.user._id) === false) {
                        players.push(bet.user._id);
                    }
                }

                return players.length;
            },
            rollGetBetsAmount() {
                let amount = 0;

                for (const bet of this.rollBets) {
                    amount = Math.floor(amount + bet.amount);
                }

                return amount;
            }
        }
    };
</script>

<style module>
    .roll-bets {
        top: 10px;
        z-index: 10;
        min-width: 75px;
        position: sticky;
        height: fit-content;
        bottom: 0px;
        font-size: 18px;
    }

    .roll-bets .bets-overlay {
        display: none;
        width: 120vw;
        height: 120vh;
        position: fixed;
        top: -91px;
        right: -10px;
        transform: translateX(0px);
        background-color: rgba(2, 20, 35, 0.425);
        transition:
            opacity 0.3s ease,
            transform 0.3s ease;
        pointer-events: none;
        opacity: 0;
        user-select: none;
        z-index: -1;
    }

    .roll-bets .bets-wrapper {
        position: relative;
        width: 313px;
        min-width: 313px;
        max-height: 80vh;
        transition: all 0.2s ease;
        padding: 4px;
        clip-path: var(--octagonal-main);
        background: linear-gradient(45deg, #0d243a, #0d243a);
    }

    .roll-bets .wrapper-content {
        min-height: 60vh;
        height: 100%;
        width: 100%;
        font-size: 0.89em;
        background: linear-gradient(90deg, #0b2137, #0c2033);
        padding: 12px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        clip-path: var(--octagonal-main);
    }

    .roll-bets .wrapper-content > * {
        max-width: calc(313px - 8px - 24px);
        width: 100%;
    }

    .roll-bets .wrapper-content::before {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        clip-path: var(--octagonal-before);
        background: #1a2f4d;
    }

    .roll-bets .content-highest {
        min-height: 104px;
    }

    .roll-bets .content-players {
        flex-direction: column;
        min-height: 210px;
        display: flex;
        flex: 1;
    }

    .roll-bets .players-wrapper {
        position: relative;
        overflow: auto;
        flex: 1;
    }

    .roll-bets .wrapper-list {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        gap: 4px;
        padding: 12px 0;
        flex-direction: column;
    }

    .roll-bets .highest-title span,
    .roll-bets .players-title span {
        color: white;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
    }

    .roll-bets .players-title svg {
        margin-right: -1px;
        margin-left: 2px;
    }

    .roll-bets .highest-title,
    .roll-bets .players-title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        height: 46px;
        filter: drop-shadow(0px 2px 0px #00000040);
        overflow: hidden;
        position: relative;
        padding: 0 12px;
        gap: 5px;
    }

    .roll-bets .players-title {
        margin-top: 20px;
        margin-bottom: unset;
    }

    .roll-bets .highest-title::before,
    .roll-bets .players-title::before {
        position: absolute;
        z-index: -1;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 5px;
        clip-path: var(--octagonal-main);
        background: #0e273e;
    }

    .roll-bets .title-icon:not(:last-child) {
        margin-left: auto;
    }

    .roll-bets .title-icon img {
        margin-bottom: -2px;
    }

    .roll-bets .title-icon {
        font-size: 3px;
        height: 27px;
        width: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(360deg, #0f426b 11.31%, #0d3355 104.17%);
        clip-path: var(--octagonal-main);
    }

    @media screen and (max-width: 1000px) {
        .roll-bets {
            font-size: 10px;
            height: 80vh;
        }

        .roll-bets .bets-wrapper {
            position: absolute;
            height: 80vh;
            width: 75px;
            min-width: 75px;
        }

        .roll-bets:not(.active) .bets-wrapper {
            cursor: pointer;
        }

        .roll-bets.active .bets-wrapper {
            width: 313px;
            min-width: 313px;
        }

        .roll-bets .bets-overlay {
            display: block;
        }

        .roll-bets.active .bets-overlay {
            pointer-events: auto;
            opacity: 1;
        }
    }
</style>
