<template>
    <div :class="$style['app-maintenance']">
        <div :style="boxStyle">
            <img src="/chip.png" alt="" />
            <img src="/chip.png" alt="" />
            <img src="/chip.png" alt="" />
        </div>
        <p>
            UNDER MAINTENANCE,
            <br />
            please come back later
            <span>!</span>
            <span>!</span>
            <span>!</span>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'AppMaintenance',
        data() {
            return {
                colors: Array.from(new Array(37)).map((_, i) => `${i * 10}deg`),
                currentColor: Math.floor(Math.random() * 0),
                translateX: Math.floor(Math.random() * window.innerWidth) + 1,
                translateY: Math.floor(Math.random() * window.innerHeight) + 1,
                boxWidth: Math.max(window.innerWidth, 1000) * 0.22, // Set to your element's width
                boxHeight: Math.max(window.innerWidth, 1000) * 0.075, // Set to your element's height
                xMin: 0,
                yMin: 0,
                xMax: window.innerWidth - Math.max(window.innerWidth, 1000) * 0.22,
                yMax: window.innerHeight - Math.max(window.innerWidth, 1000) * 0.075,
                direction: 'se',
                speed: Math.max(4, 3 + Math.round(window.innerWidth / 1000)),
                timeout: null,
                request: null
            };
        },
        computed: {
            boxStyle() {
                return {
                    transform: `translate3d(${this.translateX}px, ${this.translateY}px, 0)`,
                    filter: `hue-rotate(${this.colors[this.currentColor]})`,
                    width: Math.round(this.boxWidth) + 'px',
                    height: Math.round(this.boxHeight) + 'px',
                    position: 'absolute',
                    left: '0px',
                    top: '0px'
                };
            }
        },
        mounted() {
            this.init();
            window.addEventListener('resize', this.update);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.update);
            cancelAnimationFrame(this.request);
        },
        methods: {
            init() {
                this.request = requestAnimationFrame(this.init);
                this.move();
            },
            update() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    (this.speed = Math.max(4, 3 + Math.round(window.innerWidth / 1000))),
                        (this.boxWidth = Math.max(window.innerWidth, 1000) * 0.22);
                    this.boxHeight = Math.max(window.innerWidth, 1000) * 0.075;
                    this.xMax = window.innerWidth - Math.max(window.innerWidth, 1000) * 0.22;
                    this.yMax = window.innerHeight - Math.max(window.innerWidth, 1000) * 0.075;
                }, 100);
            },
            move() {
                this.setDirection();
            },
            setDirection() {
                switch (this.direction) {
                    case 'ne':
                        this.translateX += this.speed;
                        this.translateY -= this.speed;
                        break;
                    case 'nw':
                        this.translateX -= this.speed;
                        this.translateY -= this.speed;
                        break;
                    case 'se':
                        this.translateX += this.speed;
                        this.translateY += this.speed;
                        break;
                    case 'sw':
                        this.translateX -= this.speed;
                        this.translateY += this.speed;
                        break;
                }
                this.setLimits();
            },
            setLimits() {
                if (this.translateY <= this.yMin) {
                    if (this.direction === 'nw') {
                        this.direction = 'sw';
                    } else if (this.direction === 'ne') {
                        this.direction = 'se';
                    }
                    this.switchColor();
                }
                if (this.translateY >= this.yMax) {
                    if (this.direction === 'se') {
                        this.direction = 'ne';
                    } else if (this.direction === 'sw') {
                        this.direction = 'nw';
                    }
                    this.switchColor();
                }
                if (this.translateX <= this.xMin) {
                    if (this.direction === 'nw') {
                        this.direction = 'ne';
                    } else if (this.direction === 'sw') {
                        this.direction = 'se';
                    }
                    this.switchColor();
                }
                if (this.translateX >= this.xMax) {
                    if (this.direction === 'ne') {
                        this.direction = 'nw';
                    } else if (this.direction === 'se') {
                        this.direction = 'sw';
                    }
                    this.switchColor();
                }
            },
            switchColor() {
                let color = Math.floor(Math.random() * this.colors.length);
                while (color === this.currentColor) {
                    color = Math.floor(Math.random() * this.colors.length);
                }
                this.currentColor = color;
            }
        }
    };
</script>

<style module>
    .app-maintenance {
        width: 100%;
        height: 100%;
        position: fixed;
        display: flex;
        flex-direction: column;
        background-image: url('~@/assets/img/loader-background.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
        justify-content: center;
        align-items: center;
        gap: 14px;
        top: 0;
        left: 0;
    }

    .app-maintenance div {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        display: grid;
    }

    .app-maintenance div img {
        width: 100%;
    }

    .app-maintenance div img:nth-child(1) {
        animation: app-maintenance-chip 2s ease-out infinite 0s;
    }

    .app-maintenance div img:nth-child(2) {
        animation: app-maintenance-chip 2s ease-out infinite 0.5s;
    }

    .app-maintenance div img:nth-child(3) {
        animation: app-maintenance-chip 2s ease-out infinite 1s;
    }

    @keyframes app-maintenance-chip {
        0% {
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }

    .app-maintenance p {
        text-align: center;
        font-size: 25px;
        color: white;
        font-weight: 600;
        letter-spacing: 0.05em;
        position: relative;
        z-index: 1;
    }

    .app-maintenance p span:nth-child(2) {
        animation: dot-00 2s linear infinite;
    }

    .app-maintenance p span:nth-child(3) {
        animation: dot-01 2s linear infinite;
    }

    .app-maintenance p span:nth-child(4) {
        animation: dot-02 2s linear infinite;
    }

    @keyframes dot-00 {
        0% {
            font-size: 0px;
        }
        20% {
            font-size: 0px;
        }
        25% {
            font-size: inherit;
        }
        99% {
            font-size: inherit;
        }
        100% {
            font-size: 0px;
        }
    }

    @keyframes dot-01 {
        0% {
            font-size: 0px;
        }
        45% {
            font-size: 0px;
        }
        50% {
            font-size: inherit;
        }
        99% {
            font-size: inherit;
        }
        100% {
            font-size: 0px;
        }
    }

    @keyframes dot-02 {
        0% {
            font-size: 0px;
        }
        70% {
            font-size: 0px;
        }
        75% {
            font-size: inherit;
        }
        99% {
            font-size: inherit;
        }
        100% {
            font-size: 0px;
        }
    }

    @media screen and (max-width: 1280px) {
        .app-maintenance {
            background-size: cover;
        }
    }
</style>
