var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['bets-element']},[_c('div',{class:_vm.$style['element-game']},[(_vm.bet.method === 'crash')?_c('IconCrash'):(_vm.bet.method === 'roll')?_c('IconRoll'):(_vm.bet.method === 'blackjack')?_c('IconBlackjack'):(_vm.bet.method === 'duels')?_c('IconDuels'):(_vm.bet.method === 'mines')?_c('IconMines'):(_vm.bet.method === 'towers')?_c('IconTowers'):(_vm.bet.method === 'unbox')?_c('IconUnbox'):(_vm.bet.method === 'battles')?_c('IconBattles'):(_vm.bet.method === 'upgrader')?_c('IconUpgrader'):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.betsGetMethod))])],1),_c('button',{class:[
            _vm.$style['element-user'],
            _vm.bet?.user === null
                ? _vm.$style['user-hidden']
                : [
                      _vm.$style['user-' + _vm.betsGetRank(_vm.bet?.user)],
                      _vm.$style['user-' + _vm.betsGetLevelColor(_vm.bet?.user)]
                  ]
        ],on:{"click":function($event){return _vm.betsUserButton(_vm.bet?.user)}}},[_c('AvatarImage',{attrs:{"image":_vm.bet?.user === null ? 'anonymous' : _vm.bet.user?.avatar,"rank":_vm.bet?.bot ? null : _vm.bet.user?.rank,"level":_vm.bet?.bot ? null : _vm.bet.user?.level}}),(_vm.bet?.user !== null)?_c('div',{class:_vm.$style['user-username'],domProps:{"innerHTML":_vm._s(_vm.bet.user?.username)}}):_c('div',[_vm._v("Anonymous")])],1),_c('div',{class:_vm.$style['element-time']},[_c('span',[_vm._v(_vm._s(_vm.betsGetDate[0])+",")]),_vm._v(" "+_vm._s(_vm.betsGetDate[1])+" ")]),_c('div',{class:_vm.$style['element-wager']},[_c('img',{attrs:{"src":_vm.betGetType,"alt":"icon"}}),_c('div',{class:_vm.$style['wager-value']},[_c('span',[_vm._v(_vm._s(_vm.betsFormatValue(_vm.betsGetAmount).split('.')[0]))]),_vm._v("."+_vm._s(_vm.betsFormatValue(_vm.betsGetAmount).split('.')[1])+" ")])]),_c('div',{class:_vm.$style['element-multiplier']},[_c('span',{class:{ 'fade-green': _vm.betsGetMultiplier > 0 }},[_vm._v(" "+_vm._s(parseFloat(_vm.betsGetMultiplier).toFixed(2))+"x ")])]),_c('div',{class:[_vm.$style['element-payout'], { [_vm.$style.positive]: _vm.betsGetPayout > 0 }]},[_c('img',{attrs:{"src":_vm.betGetType,"alt":"icon"}}),_c('div',{class:_vm.$style['payout-value']},[_c('span',[_vm._v(" "+_vm._s((_vm.betsGetPayout > 0 ? '+' : _vm.betsGetPayout ? '-' : '') + _vm.betsFormatValue(Math.abs(_vm.betsGetPayout)).split('.')[0]))]),_vm._v("."+_vm._s(_vm.betsFormatValue(Math.abs(_vm.betsGetPayout)).split('.')[1])+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }