<template>
    <div class="banner-links">
        <div class="banner-links-bg"></div>
        <img class="robot" src="@/assets/img/char-robot.png" />
        <img class="knight" src="@/assets/img/char-knight.png" />
        <div class="link profile">
            <AvatarImage
                v-bind:src="authUser.user?.avatar"
                v-bind:rank="authUser.user?.rank"
                v-bind:xp="authUser.user?.xp"
            />
            <p class="title">PLAY TO LEVEL UP</p>
            <a v-if="authUser.user" href="/profile" class="button">
                <p>My Profile</p>
            </a>
            <button v-else class="button" v-on:click="modalsSetShow('Login')">
                <p>Sign in</p>
            </button>
        </div>
        <div class="link discord">
            <img src="@/assets/img/discord.png" />
            <p class="title">KEEP UP TO DATE</p>
            <a href="https://discord.gg/rollbet" target="_blank" class="button">
                <p>Join Discord</p>
            </a>
        </div>
        <div class="link reward">
            <img src="@/assets/img/chest_coins.png" />
            <p class="title">CLAIM TREASURES</p>
            <a href="/rewards" class="button">
                <p>My Rewards</p>
            </a>
        </div>
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BannerLinks',
        components: {
            AvatarImage
        },
        computed: {
            ...mapGetters(['authUser'])
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    };
</script>

<style scoped>
    .banner-links {
        border: 1px solid #1a3958;
        border-radius: 8px;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        gap: 34px;
    }

    .banner-links-bg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
    }

    .banner-links-bg::before {
        animation: background-move 6s ease alternate infinite;
        background-image: url('~@/assets/img/banner-links-bg.png');
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 50px);
        height: 100%;
        content: '';
    }

    @keyframes background-move {
        0% {
            left: 0;
        }
        100% {
            left: -50px;
        }
    }

    .banner-links .robot {
        position: absolute;
        display: block;
        width: 151px;
        bottom: 0px;
        right: 72%;
    }

    .banner-links .knight {
        position: absolute;
        display: block;
        width: 172px;
        bottom: 0px;
        left: 72%;
    }

    .banner-links .link {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        display: flex;
        position: relative;
        z-index: 1;
    }

    .banner-links .link img {
        height: 60px;
        margin-bottom: 14px;
        display: block;
    }

    .banner-links .link .title {
        display: block;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 15px;
        color: white;
    }

    .banner-links .link .button {
        clip-path: var(--octagonal-main);
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0px 14px;
        max-width: 133px;
        font-size: 5px;
        display: flex;
        height: 35px;
        width: 100%;
    }

    .banner-links .link .button p {
        font-weight: 500;
        font-size: 15px;
    }

    .banner-links .link .button::before {
        clip-path: var(--octagonal-before);
        position: absolute;
        display: block;
        height: 100%;
        content: '';
        width: 100%;
    }

    .banner-links .link.profile .button {
        background: #0b305244;
        color: #72adf3;
    }

    .banner-links .link.profile .button::before {
        background: #72adf340;
    }

    .banner-links .link.discord .button {
        background: #7276f322;
        color: #8b8ef9;
    }

    .banner-links .link.discord .button::before {
        background: #9483ff40;
    }

    .banner-links .link.reward .button {
        background: #01c18f22;
        color: #00d598;
    }

    .banner-links .link.reward .button::before {
        background: #01e1a440;
    }

    .banner-links .link .button::after {
        content: '';
        position: absolute;
        left: -100%;
        top: 50%;
        width: 100%;
        height: 1000px;
        animation: link-button 1.5s ease-out 0.5s;
        background: linear-gradient(90deg, transparent, #ffffff11, transparent);
        transform: translateY(-50%) rotate(45deg);
    }

    .banner-links .link.discord .button::after {
        animation: link-button 1.5s ease-out 1s;
    }

    .banner-links .link.reward .button::after {
        animation: link-button 1.5s ease-out 1.5s;
    }

    @keyframes link-button {
        0% {
            left: -100%;
        }
        70% {
            left: 200%;
        }
        70.01% {
            left: -100%;
        }
        100% {
            left: 200%;
        }
    }

    @media screen and (max-width: 1000px) {
        .banner-links .robot {
            left: -14px;
            right: unset;
        }
        .banner-links .knight {
            right: -14px;
            left: unset;
        }
    }

    @media screen and (max-width: 660px) {
        .banner-links {
            gap: 10px;
        }
        .banner-links .link .title {
            display: none;
        }
        .banner-links .robot {
            width: 120px;
        }
        .banner-links .knight {
            width: 130px;
        }
    }

    @media screen and (max-width: 550px) {
        .banner-links {
            flex-direction: column;
            gap: 10px;
        }
        .banner-links .link {
            flex-direction: row;
        }
        .banner-links .link img {
            margin-bottom: 0px;
            margin-right: 10px;
            min-width: 35px;
            height: 35px;
        }
    }

    @media screen and (max-width: 375px) {
        .banner-links .link img {
            display: none;
        }
    }
</style>
