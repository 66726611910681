<template>
    <div
        v-bind:class="[
            $style['battles-game-boxes'],
            { [$style['boxes-rolling']]: game !== null && game.state === 'rolling' }
        ]"
    >
        <div :class="$style['boxes-background']">
            <div :class="$style['background-inner']"></div>
        </div>
        <div :class="$style['boxes-selector']">
            <div :class="$style['selector-inner']">
                <IconCheckGradient v-if="game !== null && game.state === 'completed'" />
            </div>
        </div>
        <transition-group :class="$style['boxes-list']" name="list" tag="div">
            <button
                v-for="(box, index) in battlesGetBoxes"
                v-bind:key="box.pos"
                v-on:click="battlesBoxButton(box.box)"
                v-bind:class="[
                    $style['button-box'],
                    {
                        [$style['button-active']]:
                            (index === 0 &&
                                ['created', 'countdown', 'pending'].includes(game.state) ===
                                    true) ||
                            box.pos === battlesGetRound - 1
                    }
                ]"
            >
                <img
                    v-bind:src="unboxImagePath + '/public/img/' + box.box.slug + '.png'"
                    :style="{
                        opacity:
                            !index && ['rolling', 'completed'].includes(game.state)
                                ? 0.45
                                : !index
                                  ? 1
                                  : 0.3
                    }"
                />
            </button>
        </transition-group>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import IconCheckGradient from '@/components/icons/IconCheckGradient';

    export default {
        name: 'BattlesGameBoxes',
        components: {
            IconCheckGradient
        },
        props: ['game'],
        data() {
            return {
                unboxImagePath: process.env.VUE_APP_BACKEND_URL
            };
        },
        methods: {
            ...mapActions(['modalsSetShow', 'modalsSetData']),
            battlesBoxButton(box) {
                this.modalsSetData({ box: box });
                this.modalsSetShow('Box');
            }
        },
        computed: {
            battlesGetRound() {
                let round = 1;

                if (
                    this.game !== null &&
                    this.game.bets[0].outcomes !== undefined &&
                    this.game.bets[0].outcomes.length >= 1
                ) {
                    round = this.game.bets[0].outcomes.length;
                }

                return round;
            },
            battlesGetBoxes() {
                let pos = 0;
                let boxes = [];

                if (this.game !== null) {
                    for (const box of this.game.boxes) {
                        for (let i = 0; i < box.count; i++) {
                            boxes.push({ pos: pos, box: box.box });
                            pos = pos + 1;
                        }
                    }
                }

                return boxes.slice(this.battlesGetRound - 1, this.battlesGetRound + 11);
            }
        }
    };
</script>

<style scoped>
    .list-move,
    .list-leave-active {
        transition: all 0.3s;
    }

    .list-leave-active {
        position: absolute;
    }

    .list-leave-to {
        transform: translateX(-62px);
    }
</style>

<style module>
    .battles-game-boxes {
        width: 100%;
        height: 64px;
        position: relative;
        display: flex;
        font-size: 12px;
        align-items: center;
        clip-path: var(--octagonal-main);
        z-index: 1;
    }

    .battles-game-boxes .boxes-background {
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 1px;
        z-index: -1;
    }

    .battles-game-boxes .boxes-background:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #0d314e;
        clip-path: var(--octagonal-before);
        z-index: -1;
    }

    .battles-game-boxes .background-inner {
        width: 100%;
        height: 100%;
        display: flex;
        background: #0e253a;
        clip-path: var(--octagonal-main);
    }

    .battles-game-boxes .boxes-selector {
        width: 77px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 1px;
        z-index: -1;
    }

    .battles-game-boxes .boxes-selector:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #0d314e;
        clip-path: var(--octagonal-before);
        z-index: -1;
    }

    .battles-game-boxes .boxes-selector:after {
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: #0f253b;
        clip-path: var(--octagonal-after);
        z-index: -1;
    }

    .battles-game-boxes .selector-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .battles-game-boxes .selector-inner svg {
        z-index: 10;
    }

    .battles-game-boxes .boxes-list {
        position: relative;
        display: flex;
        align-items: center;
    }

    .battles-game-boxes button.button-box {
        height: 100%;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        transform: scale(0.9);
        align-items: center;
        margin-right: 14px;
    }

    .battles-game-element .battles-game-boxes button.button-box {
        /* pointer-events: none; */
    }

    .battles-game-boxes button.button-box.button-active {
        width: 77px;
        margin-right: 11px;
    }

    .battles-game-boxes button.button-box img {
        width: 48px;
        opacity: 0.3;
    }

    .battles-game-boxes button.button-box.button-active img {
        transform: scale(1);
    }

    .battles-game-boxes.boxes-rolling button.button-box.button-active img {
        opacity: 1;
        mix-blend-mode: normal;
    }
</style>
