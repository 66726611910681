const state = {
    upgraderFilterSearch: '',
    upgraderFilterAmount: {
        min: null,
        max: null
    },
    upgraderFilterSort: 'price-descend',
    upgraderSelected: [],
    upgraderGame: null,
    upgraderItemList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    },
    upgraderBets: {
        recent: [],
        whale: []
    }
};

const getters = {
    upgraderFilterSearch: (state) => state.upgraderFilterSearch,
    upgraderFilterAmount: (state) => state.upgraderFilterAmount,
    upgraderFilterSort: (state) => state.upgraderFilterSort,
    upgraderSelected: (state) => state.upgraderSelected,
    upgraderGame: (state) => state.upgraderGame,
    upgraderItemList: (state) => state.upgraderItemList,
    upgraderBets: (state) => state.upgraderBets
};

const mutations = {
    upgrader_set_filter_search(state, value) {
        state.upgraderFilterSearch = value;
    },
    upgrader_set_filter_amount(state, value) {
        state.upgraderFilterAmount = { ...state.upgraderFilterAmount, ...value };
    },
    upgrader_set_filter_sort(state, value) {
        state.upgraderFilterSort = value;
    },
    upgrader_set_game(state, game) {
        state.upgraderGame = game;
    },
    upgrader_set_item_list(state, data) {
        state.upgraderItemList.data = data.items;
        state.upgraderItemList.count = data.count;
    },
    upgrader_set_item_list_loading(state, status) {
        state.upgraderItemList.loading = status;
    },
    upgrader_set_item_list_page(state, page) {
        state.upgraderItemList.page = page;
    },
    upgrader_set_selected(state, data) {
        state.upgraderSelected = data;
    },
    upgrader_add_bets(state, bet) {
        if (bet.amountFixed >= 2500 * 1000) {
            state.upgraderBets.whale.unshift(bet);
        }
        state.upgraderBets.recent.unshift(bet);
    },
    upgrader_set_bets(state, bets) {
        state.upgraderBets = bets;
    }
};

const actions = {
    upgraderSocketInit({ commit }, data) {
        commit('upgrader_set_bets', data.bets);
    },
    upgraderSocketBet({ commit }, data) {
        commit('upgrader_add_bets', data.bet);
    },
    upgraderSetFilterSearch({ commit }, value) {
        commit('upgrader_set_filter_search', value);
    },
    upgraderSetFilterAmount({ commit }, value) {
        commit('upgrader_set_filter_amount', value);
    },
    upgraderSetFilterSort({ commit }, value) {
        commit('upgrader_set_filter_sort', value);
    },
    upgraderSetGame({ commit }, game) {
        commit('upgrader_set_game', game);
    },
    upgraderSetItemListPage({ commit }, page) {
        commit('upgrader_set_item_list_page', page);
    },
    upgraderGetItemListSocket({ getters, commit, dispatch }, data) {
        if (getters.socketUpgrader === null || getters.upgraderItemList.loading === true) {
            return;
        }
        commit('upgrader_set_item_list_loading', true);

        getters.socketUpgrader.emit('getItemList', data, (res) => {
            if (res.success === true) {
                commit('upgrader_set_item_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('upgrader_set_item_list_loading', false);
        });
    },
    upgraderGetItemEstimateSocket({ getters, commit, dispatch }, data) {
        if (getters.socketUpgrader === null || getters.socketSendLoading !== null) {
            return;
        }
        commit('socket_set_send_loading', 'UpgraderBet');

        getters.socketUpgrader.emit('getItemEstimate', data, (res) => {
            if (res.success === true) {
                if (res.items.length) {
                    commit('upgrader_set_selected', res.items);
                } else {
                    dispatch('notificationShow', {
                        type: 'error',
                        message: 'No computed items found for the expected multiplier'
                    });
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    upgraderSendBetSocket({ getters, commit, dispatch }, data) {
        if (getters.socketUpgrader === null || getters.socketSendLoading !== null) {
            return;
        }
        commit('socket_set_send_loading', 'UpgraderBet');

        getters.socketUpgrader.emit('sendBet', data, (res) => {
            if (res.success === true) {
                setTimeout(() => {
                    commit('auth_update_user', res.user);
                }, 4500);
                commit('upgrader_set_game', res.game);
            } else {
                commit('upgrader_set_game', null);
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    upgraderToggleSelected({ getters, commit, dispatch }, data) {
        if (getters.socketSendLoading !== null) {
            return;
        }

        const item = getters.upgraderSelected.find((item) => item._id === data._id);

        if (item !== undefined) {
            commit(
                'upgrader_set_selected',
                getters.upgraderSelected.filter((selected) => selected._id !== item._id)
            );
            return;
        }

        if (getters.upgraderSelected.length < 4) {
            const amountPayout = getters.upgraderSelected.reduce((a, b) => (a += b.amountFixed), 0);
            if (amountPayout + data.amountFixed > 200000 * 1000) {
                dispatch('notificationShow', {
                    type: 'error',
                    message: `You can only select items with a total of 200,000 max`
                });
            } else {
                commit('upgrader_set_selected', [...getters.upgraderSelected, data]);
            }
        } else {
            dispatch('notificationShow', {
                type: 'error',
                message: `You can only select up to 4 items`
            });
        }
    }
};

const upgrader = {
    state,
    mutations,
    actions,
    getters
};

export default upgrader;
