<template>
    <div :class="$style['modal-whats-new']">
        <div :class="$style['new-content']">
            <p :class="$style['content-tag']">NEW</p>
            <h1 :class="$style['content-title']"><span class="fade-gold">WHAT'S NEW?</span></h1>
            <hr />
            <div :class="$style['content-list']">
                <div :class="[$style['list-item'], $style['rewards']]" class="octagonal after">
                    <img width="53" src="@/assets/img/gem.png" />
                    <img width="35" src="@/assets/img/gem.png" />
                    <img width="25" src="@/assets/img/coin.png" />
                    <img width="61" src="@/assets/img/coin.png" />
                    <svg width="137" fill="currentColor" viewBox="0 0 133 157">
                        <path
                            d="M 25 6 A 1 1 0 0 0 25 8 A 1 1 0 0 0 25 6 M 2 53 A 1 1 0 0 0 2 57 A 1 1 0 0 0 2 53 M 24 114 A 1 1 0 0 0 22 117 A 1 1 0 0 0 24 114 M 58 97 A 1 1 0 0 0 58 100 A 1 1 0 0 0 58 97 M 131 120 A 1 1 0 0 0 132 123 A 1 1 0 0 0 131 120 M 147 111 A 1 1 0 0 0 148 115 A 1 1 0 0 0 147 111 M 131 61 A 1 1 0 0 0 134 62 A 1 1 0 0 0 131 61 M 132 1 A 1 1 0 0 0 135 4 A 1 1 0 0 0 132 1 M 156 66 A 1 1 0 0 0 155 68 A 1 1 0 0 0 156 66"
                        ></path>
                    </svg>
                    <router-link
                        to="/rewards"
                        v-on:click.native="modalsSetShow(null)"
                        :class="$style['item-content']"
                        class="octagonal before after hoverable"
                    >
                        <h4><span class="fade-gold">BETTER REWARDS</span></h4>
                        <p>
                            We have made it easier for our users and partners to view their
                            statistics and rewards!
                        </p>
                    </router-link>
                </div>
                <div :class="[$style['list-item'], $style['game']]" class="octagonal after">
                    <svg width="137" fill="currentColor" viewBox="0 0 133 157">
                        <path
                            d="M 25 6 A 1 1 0 0 0 25 8 A 1 1 0 0 0 25 6 M 2 53 A 1 1 0 0 0 2 57 A 1 1 0 0 0 2 53 M 24 114 A 1 1 0 0 0 22 117 A 1 1 0 0 0 24 114 M 58 97 A 1 1 0 0 0 58 100 A 1 1 0 0 0 58 97 M 131 120 A 1 1 0 0 0 132 123 A 1 1 0 0 0 131 120 M 147 111 A 1 1 0 0 0 148 115 A 1 1 0 0 0 147 111 M 131 61 A 1 1 0 0 0 134 62 A 1 1 0 0 0 131 61 M 132 1 A 1 1 0 0 0 135 4 A 1 1 0 0 0 132 1 M 156 66 A 1 1 0 0 0 155 68 A 1 1 0 0 0 156 66"
                        ></path>
                    </svg>
                    <svg width="137" fill="currentColor" viewBox="0 0 133 157">
                        <path
                            d="M 25 6 A 1 1 0 0 0 25 8 A 1 1 0 0 0 25 6 M 2 53 A 1 1 0 0 0 2 57 A 1 1 0 0 0 2 53 M 24 114 A 1 1 0 0 0 22 117 A 1 1 0 0 0 24 114 M 58 97 A 1 1 0 0 0 58 100 A 1 1 0 0 0 58 97 M 131 120 A 1 1 0 0 0 132 123 A 1 1 0 0 0 131 120 M 147 111 A 1 1 0 0 0 148 115 A 1 1 0 0 0 147 111 M 131 61 A 1 1 0 0 0 134 62 A 1 1 0 0 0 131 61 M 132 1 A 1 1 0 0 0 135 4 A 1 1 0 0 0 132 1 M 156 66 A 1 1 0 0 0 155 68 A 1 1 0 0 0 156 66"
                        ></path>
                    </svg>
                    <svg width="137" fill="currentColor" viewBox="0 0 133 157">
                        <path
                            d="M 25 6 A 1 1 0 0 0 25 8 A 1 1 0 0 0 25 6 M 2 53 A 1 1 0 0 0 2 57 A 1 1 0 0 0 2 53 M 24 114 A 1 1 0 0 0 22 117 A 1 1 0 0 0 24 114 M 58 97 A 1 1 0 0 0 58 100 A 1 1 0 0 0 58 97 M 131 120 A 1 1 0 0 0 132 123 A 1 1 0 0 0 131 120 M 147 111 A 1 1 0 0 0 148 115 A 1 1 0 0 0 147 111 M 131 61 A 1 1 0 0 0 134 62 A 1 1 0 0 0 131 61 M 132 1 A 1 1 0 0 0 135 4 A 1 1 0 0 0 132 1 M 156 66 A 1 1 0 0 0 155 68 A 1 1 0 0 0 156 66"
                        ></path>
                    </svg>
                    <div :class="$style.spiral">
                        <div>
                            <svg
                                width="26"
                                stroke="#aafffb"
                                fill="#4ae152"
                                stroke-width="1"
                                viewBox="0 0 22 43"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M 22 0 L 0 24 L 8 24 L 0 43 L 23 19 L 14 19 Z"></path>
                            </svg>
                        </div>
                    </div>
                    <router-link
                        to="/upgrader"
                        v-on:click.native="modalsSetShow(null)"
                        :class="$style['item-content']"
                        class="octagonal after before hoverable"
                    >
                        <h4><span class="fade-green">NEW GAME MODE</span></h4>
                        <p>
                            New game mode's in town. <b>Upgrader</b> is here and looking better than
                            ever!
                        </p>
                    </router-link>
                </div>
                <div :class="[$style['list-item'], $style['cases']]" class="octagonal after">
                    <img width="88" src="@/assets/img/case.png" />
                    <img width="122" src="@/assets/img/case_01.png" />
                    <img width="85" src="@/assets/img/case_02.png" />
                    <svg width="137" fill="currentColor" viewBox="0 0 133 157">
                        <path
                            d="M 25 6 A 1 1 0 0 0 25 8 A 1 1 0 0 0 25 6 M 2 53 A 1 1 0 0 0 2 57 A 1 1 0 0 0 2 53 M 24 114 A 1 1 0 0 0 22 117 A 1 1 0 0 0 24 114 M 58 97 A 1 1 0 0 0 58 100 A 1 1 0 0 0 58 97 M 131 120 A 1 1 0 0 0 132 123 A 1 1 0 0 0 131 120 M 147 111 A 1 1 0 0 0 148 115 A 1 1 0 0 0 147 111 M 131 61 A 1 1 0 0 0 134 62 A 1 1 0 0 0 131 61 M 132 1 A 1 1 0 0 0 135 4 A 1 1 0 0 0 132 1 M 156 66 A 1 1 0 0 0 155 68 A 1 1 0 0 0 156 66"
                        ></path>
                    </svg>
                    <router-link
                        to="/unbox"
                        v-on:click.native="modalsSetShow(null)"
                        :class="$style['item-content']"
                        class="octagonal after before hoverable"
                    >
                        <h4><span>NEW CASES</span></h4>
                        <p>
                            Your requests have been heard, and we have added <b>40 new cases</b>,
                            especially cheaper ones
                        </p>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'ModalWhatsNew',
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    };
</script>

<style module>
    .modal-whats-new {
        width: 1140px;
        padding: 6px;
    }

    .modal-whats-new::before {
        background: linear-gradient(45deg, #364c98, #2b438259);
    }

    .modal-whats-new .new-content {
        font-size: 17px;
        clip-path: var(--octagonal-main);
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .modal-whats-new .content-tag {
        position: absolute;
        transform: rotate(-45deg) translate(-27.5%, -25%);
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 100px;
        background: linear-gradient(70deg, #f8b456 35%, #fcedab, #ecca3b 75%);
        filter: drop-shadow(0px 0px 10px #ffeea659);
        color: #052239;
        font-weight: 500;
        font-size: 14px;
    }

    .modal-whats-new .content-title {
        filter: drop-shadow(0px 0px 20px #ffeea659);
        font-size: 25px;
        font-weight: 700;
        text-align: center;
        margin: 20px 0;
    }

    .modal-whats-new hr {
        mask-image: linear-gradient(90deg, transparent, black, transparent);
        border-color: #2a4382;
        margin-bottom: 25px;
    }

    .modal-whats-new .content-list {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
        padding: 40px 25px 25px 25px;
        gap: 10px;
    }

    .modal-whats-new .list-item {
        flex: 1;
        min-width: 283px;
        padding: 4px;
    }

    .modal-whats-new .list-item::after {
        background: linear-gradient(0deg, #0e2840, #0e2840);
        font-size: 20px;
    }

    .modal-whats-new .list-item > *:not(.item-content) {
        pointer-events: none;
        position: absolute;
        z-index: 1;
    }

    .modal-whats-new .list-item.cases > svg:first-of-type {
        transform: translate(-50%, -50%) scaleX(-100%);
        filter: drop-shadow(0 0 2px currentColor);
        left: 50%;
        top: calc(50% - 40px);
    }
    .modal-whats-new .list-item.cases img:nth-child(1) {
        transform: translate(-178%, -5%) rotate(-20deg);
        animation: up-down-00 2s alternate linear infinite 2s;
        left: 50%;
        top: 20px;
    }
    @keyframes up-down-00 {
        0% {
            transform: translate(-178%, -5%) rotate(-20deg);
        }
        100% {
            transform: translate(-178%, 5%) rotate(-20deg);
        }
    }
    .modal-whats-new .list-item.cases img:nth-child(2) {
        transform: translate(-50%, -5%);
        animation: up-down-01 2s alternate linear infinite 1s;
        top: -24px;
        left: 50%;
    }
    @keyframes up-down-01 {
        0% {
            transform: translate(-50%, -5%);
        }
        100% {
            transform: translate(-50%, 5%);
        }
    }
    .modal-whats-new .list-item.cases img:nth-child(3) {
        transform: translate(178%, -5%) rotate(10deg);
        animation: up-down-02 2s alternate linear infinite;
        right: 50%;
        top: 20px;
    }
    @keyframes up-down-02 {
        0% {
            transform: translate(178%, -5%) rotate(10deg);
        }
        100% {
            transform: translate(178%, 5%) rotate(10deg);
        }
    }

    .modal-whats-new .list-item.game > svg:first-of-type {
        transform: translate(-50%, -50%);
        filter: drop-shadow(0 0 2px currentColor);
        left: 50%;
        top: calc(50% - 40px);
    }
    .modal-whats-new .list-item.game > svg {
        transform: translate(-40%, -50%) scale(50%) rotate(90deg);
        filter: drop-shadow(0 0 2px currentColor);
        left: 50%;
        top: calc(50% - 40px);
    }
    .modal-whats-new .list-item.game > svg:last-of-type {
        transform: translate(-55%, -50%) scale(80%) rotate(180deg);
        filter: drop-shadow(0 0 2px currentColor);
        left: 50%;
        top: calc(50% - 80px);
    }
    .modal-whats-new .list-item.game .spiral {
        left: 50%;
        width: 110px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        top: -25px;
    }
    .modal-whats-new .list-item.game .spiral div {
        z-index: 1;
    }
    .modal-whats-new .list-item.game .spiral svg {
        filter: drop-shadow(0 0 20px #50afa0);
    }
    .modal-whats-new .list-item.game .spiral::after {
        background: radial-gradient(circle at center, transparent, #0c2338);
        box-shadow: 0 0 30px 0px #ffffff59;
    }
    .modal-whats-new .list-item.game .spiral div::after,
    .modal-whats-new .list-item.game .spiral div::before,
    .modal-whats-new .list-item.game .spiral::after {
        transform: rotate(0deg) translateY(5%);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        border: 1px solid white;
        box-sizing: border-box;
        content: '';
        transform-origin: 50% 50%;
        animation: spiral 20s linear infinite;
        left: 0%;
        top: 0%;
    }

    .modal-whats-new .list-item.game .spiral div::after {
        animation: spiral 20s linear infinite 1s;
        opacity: 0.5;
    }
    .modal-whats-new .list-item.game .spiral div::before {
        animation: spiral 20s linear infinite 2s;
        opacity: 0.1;
    }

    .modal-whats-new .list-item.rewards > svg:first-of-type {
        color: yellow;
        transform: translate(-50%, -50%);
        filter: drop-shadow(0 0 2px currentColor);
        left: 50%;
        top: calc(50% - 40px);
    }
    .modal-whats-new .list-item.rewards img:nth-child(1) {
        transform: translate(-50%, -55%) rotate(-8deg);
        animation: up-down-03 2s alternate linear infinite;
        left: 35%;
        top: 50px;
    }
    @keyframes up-down-03 {
        0% {
            transform: translate(-50%, -55%) rotate(-8deg);
        }
        100% {
            transform: translate(-50%, -45%) rotate(-8deg);
        }
    }
    .modal-whats-new .list-item.rewards img:nth-child(2) {
        transform: translate(0%, -55%) rotate(3.5deg);
        animation: up-down-04 2s alternate linear infinite 1s;
        left: 44%;
        top: 14px;
    }
    @keyframes up-down-04 {
        0% {
            transform: translate(0%, -55%) rotate(3.5deg);
        }
        100% {
            transform: translate(0%, -45%) rotate(3.5deg);
        }
    }
    .modal-whats-new .list-item.rewards img:nth-child(3) {
        transform: translate(0%, -60%) rotate(80deg);
        animation: up-down-05 2s alternate linear infinite 2s;
        right: 46%;
        top: 75px;
    }
    @keyframes up-down-05 {
        0% {
            transform: translate(0%, -60%) rotate(80deg);
        }
        100% {
            transform: translate(0%, -40%) rotate(80deg);
        }
    }
    .modal-whats-new .list-item.rewards img:nth-child(4) {
        transform: translate(-50%, -55%) rotate(45deg);
        animation: up-down-06 2s alternate linear infinite 3s;
        left: 67%;
        top: 50px;
    }
    @keyframes up-down-06 {
        0% {
            transform: translate(-50%, -55%) rotate(45deg);
        }
        100% {
            transform: translate(-50%, -45%) rotate(45deg);
        }
    }

    @keyframes spiral {
        0% {
            transform: rotate(0deg) translateY(5%);
        }
        12.5% {
            transform: rotate(45deg) translateY(-5%);
        }
        25% {
            transform: rotate(90deg) translateY(5%);
        }
        37.5% {
            transform: rotate(135deg) translateY(-5%);
        }
        50% {
            transform: rotate(180deg) translateY(5%);
        }
        62.5% {
            transform: rotate(225deg) translateY(-5%);
        }
        75% {
            transform: rotate(270deg) translateY(5%);
        }
        87.5% {
            transform: rotate(315deg) translateY(-5%);
        }
        100% {
            transform: rotate(360deg) translateY(5%);
        }
    }

    .modal-whats-new .item-content {
        display: block;
        padding: 95px 27px 7px 27px;
        min-height: 190px;
        height: 100%;
        font-size: 18px;
        clip-path: var(--octagonal-main);
        background: radial-gradient(
            150px 150px at 80% 25%,
            color-mix(in hsl, currentColor, #ffffff00 80%),
            #02132159
        );
    }

    .modal-whats-new .item-content::after {
        background: radial-gradient(
            200px 150px at 10% 100%,
            color-mix(in hsl, currentColor, #ffffff00 90%),
            #02132159
        );
    }

    .modal-whats-new .list-item.cases {
        color: violet;
    }
    .modal-whats-new .list-item.cases .item-content::after {
        color: lime;
    }
    .modal-whats-new .list-item.cases .item-content::before {
        background: linear-gradient(120deg, #a45cff, #6a35ff);
    }

    .modal-whats-new .list-item.game {
        color: cyan;
    }
    .modal-whats-new .list-item.game .item-content::after {
        color: blue;
    }
    .modal-whats-new .list-item.game .item-content::before {
        background: linear-gradient(120deg, #5cffd8, #359eff);
    }

    .modal-whats-new .list-item.rewards {
        color: lime;
    }
    .modal-whats-new .list-item.rewards .item-content::after {
        color: yellow;
    }
    .modal-whats-new .list-item.rewards .item-content::before {
        background: linear-gradient(120deg, #ffbe5c, #ffae35);
    }

    .modal-whats-new .item-content h4 {
        text-align: center;
        font-size: 18px;
        margin-bottom: 3px;
    }

    .modal-whats-new .item-content p {
        font-size: 15px;
        color: white;
        text-align: center;
        max-width: 340px;
        margin: 0 auto;
    }
</style>
