<template>
    <div :class="$style.upgrader">
        <div :class="$style['upgrader-bets']">
            <UpgraderBets />
        </div>
        <div :class="$style['upgrader-content']">
            <UpgraderGame />
            <hr />
            <UpgraderItems />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import UpgraderGame from '@/components/upgrader/UpgraderGame';
    import UpgraderItems from '@/components/upgrader/UpgraderItems';
    import UpgraderBets from '@/components/upgrader/UpgraderBets';

    export default {
        name: 'Upgrader',
        metaInfo: {
            title: 'Upgrader - Rollbet.gg'
        },
        components: {
            LoadingAnimation,
            UpgraderGame,
            UpgraderItems,
            UpgraderBets
        },
        methods: {
            ...mapActions(['socketConnectUpgrader', 'socketDisconnectUpgrader'])
        },
        computed: {
            ...mapGetters(['socketUpgrader'])
        },
        created() {
            this.socketConnectUpgrader();
        },
        beforeRouteLeave(to, from, next) {
            this.socketDisconnectUpgrader();
            next();
        }
    };
</script>

<style module>
    .upgrader {
        width: 100%;
        max-width: 1495px;
        margin: 0 auto;
        display: flex;
        align-items: stretch;
        padding: 45px 10px;
        gap: 24px;
    }

    .upgrader hr {
        border-color: #2972a929;
        margin-bottom: 18px;
        margin-top: 25px;
    }

    .upgrader-bets {
        transition: min-width 0.3s ease;
        position: relative;
        min-width: 310px;
    }

    .upgrader-content {
        flex-direction: column;
        display: flex;
        width: 100%;
    }

    @media only screen and (max-width: 1500px) {
        .upgrader {
            padding: 30px 10px 45px 10px;
        }
    }

    @media screen and (max-width: 900px) {
        .upgrader-bets {
            min-width: 225px;
        }
    }

    @media screen and (max-width: 700px) {
        .upgrader-bets {
            min-width: 60px;
        }
    }
</style>
