<template>
    <button
        v-on:click="rollInfoButton"
        v-bind:class="[$style['roll-history-element'], $style[rollGetOutcome]]"
    >
        <span :class="[$style['element-inner'], { 'fade-gold': rollGetOutcome === 'ultra' }]">
            {{ Math.round(game.outcome / 100)
            }}<span>{{
                (game.outcome / 100)
                    .toFixed(2)
                    .slice(-3)
                    .replace('.00', '')
                    .replace(/(?<=[.]\d)[0]/, '')
            }}</span
            >x
        </span>
    </button>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'RollHistoryElement',
        props: ['game'],
        methods: {
            ...mapActions(['modalsSetData', 'modalsSetShow']),
            rollInfoButton() {
                this.modalsSetData({ game: this.game, type: 'roll' });
                this.modalsSetShow('FairGame');
            }
        },
        computed: {
            rollGetOutcome() {
                const value = parseFloat(this.game.outcome / 100).toFixed(2);
                if (value >= 50) return 'ultra';
                if (value >= 20) return 'high';
                if (value >= 5) return 'mid';
                if (value >= 2) return 'low';
                return 'null';
            }
        }
    };
</script>

<style module>
    .roll-history-element {
        display: flex;
        justify-content: flex-end;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        animation: roll-history-element 0.2s ease;
        color: #5a8bbb;
    }

    @keyframes roll-history-element {
        0% {
            width: 0px;
        }
        100% {
            width: 71px;
        }
    }

    .roll-history-element .element-inner {
        margin-left: 7px;
        font-size: 13px;
        font-weight: 600;
        width: 64px;
        height: 35px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
    }

    .roll-history-element .element-inner::before {
        background: linear-gradient(0deg, currentColor, transparent);
        clip-path: var(--octagonal-before);
        opacity: 0.6;
    }

    .roll-history-element .element-inner::after {
        background: radial-gradient(150% 75% at 100% 50%, currentColor, transparent);
        opacity: 0.3;
        z-index: -1;
        clip-path: var(--octagonal-after);
    }

    .roll-history-element.null .element-inner::after {
        background: #15324b;
        opacity: 1;
    }

    .roll-history-element.low {
        color: #44cb93;
    }

    .roll-history-element.mid {
        color: #784bd8;
    }

    .roll-history-element.high {
        color: #ea4934;
    }

    .roll-history-element.ultra {
        color: #ebc93b;
    }

    .roll-history-element .element-inner::before,
    .roll-history-element .element-inner::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        font-size: 5px;
    }
</style>
