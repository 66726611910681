<template>
    <div
        :class="[
            $style['roll-bets-element'],
            high ? $style[bet.coinType === 'cryptoCoin' ? 'crypto-high' : 'robux-high'] : ''
        ]"
    >
        <div :class="$style['element-avatar']">
            <AvatarImage
                v-bind:image="bet.user.avatar"
                v-bind:rank="bet.user.rank"
                v-bind:level="bet.user.level"
            />
        </div>
        <div :class="$style['element-info']">
            <p :class="$style['info-username']" v-html="bet.user.username"></p>
            <span
                :class="[
                    $style['info-multiplier'],
                    high ? (bet.coinType === 'cryptoCoin' ? 'fade-green' : 'fade-gold') : ''
                ]"
                >{{
                    parseFloat(bet.multiplier / 100)
                        .toFixed(2)
                        .replace('.00', '')
                        .replace(/(?<=[.]\d)[0]/, '')
                }}x</span
            >
            <div :class="$style['info-amount']">
                <img
                    v-if="bet.coinType === 'cryptoCoin'"
                    src="@/assets/img/icons/cryptoCoin.svg"
                    width="12"
                    alt="icon"
                />
                <img
                    v-else-if="bet.coinType === 'robuxCoin'"
                    src="@/assets/img/icons/robuxCoin.svg"
                    width="14"
                    alt="icon"
                />
                <div :class="$style['amount-value']">
                    <span>{{ Math.round(bet.amount / 1000) }}</span
                    >{{
                        (bet.amount / 1000)
                            .toFixed(2)
                            .slice(-3)
                            .replace('.00', '')
                            .replace(/(?<=[.]\d)[0]/, '')
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'RollBetsElement',
        components: {
            AvatarImage
        },
        props: ['bet', 'high'],
        methods: {
            rollFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString();
            }
        }
    };
</script>

<style module>
    .roll-bets-element {
        background: #081c2e;
        align-items: center;
        display: flex;
        padding: 12px;
        height: 46px;
        width: 100%;
        font-size: 5px;
        clip-path: var(--octagonal-main);
        position: relative;
    }

    .roll-bets-element::before {
        font-size: 5px;
        background: #153351;
    }

    .roll-bets-element .element-avatar img {
        width: 27px;
    }

    .roll-bets-element .element-info {
        padding-left: 12px;
        display: flex;
        flex: 1;
        overflow: hidden;
        align-items: center;
        gap: 7px;
    }

    .roll-bets-element .info-username {
        flex: 1;
        max-width: fit-content;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: white;
        font-size: 14px;
        font-weight: 500;
    }

    .roll-bets-element .info-multiplier {
        height: 22px;
        min-width: 36px;
        position: relative;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 7px 1px 7px;
        font-weight: 500;
        color: #99afbe;
    }

    .roll-bets-element .info-amount img {
        margin-bottom: -2px;
    }

    .roll-bets-element .info-amount {
        display: flex;
        margin-left: auto;
        align-items: center;
        padding: 0 12px;
        position: relative;
        width: fit-content;
        background: linear-gradient(360deg, #0a2f4e 11.31%, #092944 104.17%);
        font-size: 3px;
        clip-path: var(--octagonal-main);
        height: 24px;
        gap: 7px;
    }

    .roll-bets-element .amount-value {
        font-size: 13px;
        color: white;
        font-weight: 600;
    }

    .roll-bets-element .info-multiplier::before {
        background: #132d45;
        font-size: 2px;
    }

    .roll-bets-element .info-multiplier::after {
        background: #0e243a;
        font-size: 2px;
    }

    .roll-bets-element.crypto-high {
        background: linear-gradient(45deg, #0d2b36, #10323d);
    }

    .roll-bets-element.crypto-high::before {
        background: linear-gradient(45deg, #174b4c, #195144);
    }

    .roll-bets-element.crypto-high .info-multiplier::after {
        background: #1d5440;
    }

    .roll-bets-element.crypto-high .info-multiplier::before {
        background: #276a53;
    }

    .roll-bets-element.crypto-high .info-amount {
        background: linear-gradient(90deg, #133d47, #184c52);
    }

    .roll-bets-element.crypto-high .info-amount::before {
        font-size: 3px;
        background: linear-gradient(-90deg, #205e5d, #1d4d52);
    }

    .roll-bets-element.robux-high {
        background: linear-gradient(45deg, #362a0d99, #3d3710);
    }

    .roll-bets-element.robux-high::before {
        background: linear-gradient(45deg, #4c3f17, #514319);
    }

    .roll-bets-element.robux-high .info-multiplier::after {
        background: #544b1d99;
    }

    .roll-bets-element.robux-high .info-multiplier::before {
        background: #6a6827;
    }

    .roll-bets-element.robux-high .info-amount {
        background: linear-gradient(90deg, #473d1399, #524c18);
    }

    .roll-bets-element.robux-high .info-amount::before {
        font-size: 3px;
        background: linear-gradient(-90deg, #5e5220, #52481d);
    }

    .roll-bets-element::before,
    .roll-bets-element .info-amount::before,
    .roll-bets-element .info-multiplier::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        clip-path: var(--octagonal-before);
    }

    .roll-bets-element .info-multiplier::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        clip-path: var(--octagonal-after);
        z-index: -1;
    }
</style>
