<template>
    <div :class="$style['cashier-withdraw']">
        <div :class="$style['section-title']">Roblox</div>
        <div :class="$style['withdraw-section']">
            <CashierElement
                v-on:click.native="modalRobuxButton()"
                type="roblox"
                method="robux"
                v-bind:enabled="generalSettings.robux.withdraw.enabled"
            />
            <CashierElement
                v-on:click.native="modalLimitedsButton()"
                type="roblox"
                method="limiteds"
                v-bind:enabled="generalSettings.limited.withdraw.enabled"
            />
        </div>
        <div :class="$style['section-title']">Crypto & Fiat</div>
        <div :class="$style['withdraw-section']">
            <CashierElement
                v-on:click.native="modalCryptoButton('btc')"
                type="crypto"
                method="btc"
                v-bind:enabled="
                    generalSettings.crypto.withdraw.enabled && userBalanceData.type === 'cryptoCoin'
                "
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('eth')"
                type="crypto"
                method="eth"
                v-bind:enabled="
                    generalSettings.crypto.withdraw.enabled && userBalanceData.type === 'cryptoCoin'
                "
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('ltc')"
                type="crypto"
                method="ltc"
                v-bind:enabled="
                    generalSettings.crypto.withdraw.enabled && userBalanceData.type === 'cryptoCoin'
                "
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('usdt')"
                type="crypto"
                method="usdt"
                v-bind:enabled="
                    generalSettings.crypto.withdraw.enabled && userBalanceData.type === 'cryptoCoin'
                "
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('usdc')"
                type="crypto"
                method="usdc"
                v-bind:enabled="
                    generalSettings.crypto.withdraw.enabled && userBalanceData.type === 'cryptoCoin'
                "
            />
            <CashierElement
                v-on:click.native="modalCryptoButton('sol')"
                type="crypto"
                method="sol"
                v-bind:enabled="
                    generalSettings.crypto.withdraw.enabled && userBalanceData.type === 'cryptoCoin'
                "
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import CashierElement from '@/components/cashier/CashierElement';

    export default {
        name: 'CashierWithdrawTab',
        components: {
            CashierElement
        },
        methods: {
            ...mapActions(['modalsSetShow', 'modalsSetData']),
            modalRobuxButton() {
                this.$parent.modalTab = 'Robux';
                this.modalsSetData({ typeCashier: 'withdraw' });
            },
            modalLimitedsButton() {
                this.modalsSetShow(null);
                this.modalsSetData({ typeCashier: 'withdraw' });

                this.$router.push({ name: 'LimitedsWithdraw' });
            },
            modalGiftButton() {
                this.$parent.modalTab = 'Gift';
                this.modalsSetData({ typeCashier: 'withdraw' });
            },
            modalCryptoButton(currency) {
                this.$parent.modalTab = 'Crypto';
                this.modalsSetData({ typeCashier: 'withdraw', currency: currency });
            }
        },
        computed: {
            ...mapGetters(['generalSettings', 'userBalanceData'])
        }
    };
</script>

<style module>
    .cashier-withdraw {
        margin-bottom: 8px;
        width: 100%;
    }

    .cashier-withdraw .withdraw-section {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-top: 7px;
        gap: 7px;
    }

    .cashier-withdraw .withdraw-section:not(:last-child) {
        margin-bottom: 20px;
    }

    .cashier-withdraw .withdraw-section:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    .cashier-withdraw .section-title {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        color: #5b85ae;
    }

    @media only screen and (max-width: 800px) {
        .cashier-withdraw .section-content {
            justify-content: flex-start;
        }
    }

    @media only screen and (max-width: 700px) {
        .cashier-withdraw .withdraw-section {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 460px) {
        .cashier-withdraw .withdraw-section {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
</style>
