var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fair-blackjack"},[_c('div',{staticClass:"blackjack-title"},[_vm._v("BLACKJACK")]),_vm._m(0),_c('div',{staticClass:"blackjack-code"},[_c('pre',[_vm._v("                "),_c('code',[_vm._v("\n"+_vm._s(`const crypto = require('crypto');
const ChanceJs = require('chance');

const cardRanks = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
const cardSuits = ['heart', 'spade', 'diamond', 'club'];

const deckCount = 8;
const gameId = '6443aa99f4290f71b555f4bd';
const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const publicSeed = '128b71e19b3c4bd9ec01eba9d6840bdda2a8d0972ac918c9d937c7f861879727';

const fairGenerateBlackjackDeck = () => {
    let deck = [];

    for(let d =  0; d < deckCount; d++) {
        for(let s = 0; s < cardSuits.length; s++) {
            for(let r = 0; r < cardRanks.length; r++) {
                deck.push({ rank: cardRanks[r], suit: cardSuits[s] });
            }
        }
    }

    return deck;
}

const fairShuffleBlackjackDeck = (deck) => {
    const combined = \`${_vm.gameId}-${_vm.serverSeed}-${_vm.publicSeed}\`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const chance = new ChanceJs(hash);
    deck = chance.shuffle(deck);

    console.log(deck);
}

let deck = fairGenerateBlackjackDeck();
fairShuffleBlackjackDeck(deck);`)+"\n                ")]),_vm._v("\n            ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blackjack-text"},[_c('p',[_vm._v(" Our system generates the result for each game by using the SHA-256 hash of 2 separate inputs: ")]),_c('p',[_c('span',[_vm._v("Game ID:")]),_vm._v(" The games unique identifier which is generated when the game is created. ")]),_c('p',[_c('span',[_vm._v("Server Seed:")]),_vm._v(" Is a cryptographically secure pseudo-randomly generated string. ")]),_c('p',[_c('span',[_vm._v("Public Seed:")]),_vm._v(" Is the id of an EOS Block found after the bets are made. ")])])
}]

export { render, staticRenderFns }