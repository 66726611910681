<template>
    <div class="modal-item modal-currencies">
        <div class="currencies-title">
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.02814 3.65105e-05C4.03597 -0.014026 -0.014026 4.03597 3.65105e-05 9.02814C0.014099 13.9641 4.13089 18 9.06681 18H18V9.06681C18 4.13089 13.9641 0.014099 9.02814 3.65105e-05ZM9.80158 13.5844C9.56603 13.7918 9.27424 13.8973 8.92268 13.8973C8.57111 13.8973 8.27932 13.7918 8.04377 13.5844C7.80822 13.377 7.69221 13.1098 7.69221 12.7863C7.69221 12.4629 7.80822 12.1957 8.04377 11.9883C8.27932 11.7809 8.57111 11.6754 8.92268 11.6754C9.27424 11.6754 9.56603 11.7809 9.80158 11.9883C10.0371 12.1957 10.1531 12.4629 10.1531 12.7863C10.1531 13.1098 10.0336 13.3734 9.80158 13.5844ZM11.8828 7.70276C11.7387 8.00158 11.5067 8.30744 11.1867 8.61682L10.4309 9.31994C10.2164 9.52736 10.0653 9.7383 9.98439 9.95627C9.90002 10.1742 9.85431 10.4485 9.84728 10.7824H7.96291C7.96291 10.1426 8.03674 9.63635 8.18088 9.26721C8.32854 8.89103 8.57111 8.56057 8.884 8.30393C9.20744 8.03322 9.45705 7.78361 9.6258 7.55862C9.79103 7.34416 9.87892 7.08049 9.87892 6.8133C9.87892 6.15237 9.59416 5.81838 9.02463 5.81838C8.77853 5.81135 8.54299 5.91682 8.3883 6.10666C8.22658 6.30002 8.13869 6.56018 8.13166 6.89065H5.90979C5.92034 6.01174 6.19456 5.32971 6.73948 4.84104C7.2844 4.35237 8.04729 4.10628 9.02814 4.10628C10.0055 4.10628 10.7649 4.33128 11.3027 4.78479C11.8406 5.23831 12.1078 5.87815 12.1078 6.71135C12.1043 7.05237 12.0305 7.39338 11.8828 7.70276Z"
                    fill="black"
                />
                <path
                    d="M9.02814 3.65105e-05C4.03597 -0.014026 -0.014026 4.03597 3.65105e-05 9.02814C0.014099 13.9641 4.13089 18 9.06681 18H18V9.06681C18 4.13089 13.9641 0.014099 9.02814 3.65105e-05ZM9.80158 13.5844C9.56603 13.7918 9.27424 13.8973 8.92268 13.8973C8.57111 13.8973 8.27932 13.7918 8.04377 13.5844C7.80822 13.377 7.69221 13.1098 7.69221 12.7863C7.69221 12.4629 7.80822 12.1957 8.04377 11.9883C8.27932 11.7809 8.57111 11.6754 8.92268 11.6754C9.27424 11.6754 9.56603 11.7809 9.80158 11.9883C10.0371 12.1957 10.1531 12.4629 10.1531 12.7863C10.1531 13.1098 10.0336 13.3734 9.80158 13.5844ZM11.8828 7.70276C11.7387 8.00158 11.5067 8.30744 11.1867 8.61682L10.4309 9.31994C10.2164 9.52736 10.0653 9.7383 9.98439 9.95627C9.90002 10.1742 9.85431 10.4485 9.84728 10.7824H7.96291C7.96291 10.1426 8.03674 9.63635 8.18088 9.26721C8.32854 8.89103 8.57111 8.56057 8.884 8.30393C9.20744 8.03322 9.45705 7.78361 9.6258 7.55862C9.79103 7.34416 9.87892 7.08049 9.87892 6.8133C9.87892 6.15237 9.59416 5.81838 9.02463 5.81838C8.77853 5.81135 8.54299 5.91682 8.3883 6.10666C8.22658 6.30002 8.13869 6.56018 8.13166 6.89065H5.90979C5.92034 6.01174 6.19456 5.32971 6.73948 4.84104C7.2844 4.35237 8.04729 4.10628 9.02814 4.10628C10.0055 4.10628 10.7649 4.33128 11.3027 4.78479C11.8406 5.23831 12.1078 5.87815 12.1078 6.71135C12.1043 7.05237 12.0305 7.39338 11.8828 7.70276Z"
                    fill="#93FF6D"
                />
            </svg>
            <p>WHY 2 CURRENCIES?</p>
        </div>
        <div class="currencies-info">
            <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.0281 11C14.036 10.986 9.98597 15.036 10 20.0281C10.0141 24.9641 14.1309 29 19.0668 29H28V20.0668C28 15.1309 23.9641 11.0141 19.0281 11ZM19.8016 24.5844C19.566 24.7918 19.2742 24.8973 18.9227 24.8973C18.5711 24.8973 18.2793 24.7918 18.0438 24.5844C17.8082 24.377 17.6922 24.1098 17.6922 23.7863C17.6922 23.4629 17.8082 23.1957 18.0438 22.9883C18.2793 22.7809 18.5711 22.6754 18.9227 22.6754C19.2742 22.6754 19.566 22.7809 19.8016 22.9883C20.0371 23.1957 20.1531 23.4629 20.1531 23.7863C20.1531 24.1098 20.0336 24.3734 19.8016 24.5844ZM21.8828 18.7028C21.7387 19.0016 21.5067 19.3074 21.1867 19.6168L20.4309 20.3199C20.2164 20.5274 20.0653 20.7383 19.9844 20.9563C19.9 21.1742 19.8543 21.4485 19.8473 21.7824H17.9629C17.9629 21.1426 18.0367 20.6363 18.1809 20.2672C18.3285 19.891 18.5711 19.5606 18.884 19.3039C19.2074 19.0332 19.457 18.7836 19.6258 18.5586C19.791 18.3442 19.8789 18.0805 19.8789 17.8133C19.8789 17.1524 19.5942 16.8184 19.0246 16.8184C18.7785 16.8114 18.543 16.9168 18.3883 17.1067C18.2266 17.3 18.1387 17.5602 18.1317 17.8906H15.9098C15.9203 17.0117 16.1946 16.3297 16.7395 15.841C17.2844 15.3524 18.0473 15.1063 19.0281 15.1063C20.0055 15.1063 20.7649 15.3313 21.3027 15.7848C21.8406 16.2383 22.1078 16.8781 22.1078 17.7114C22.1043 18.0524 22.0305 18.3934 21.8828 18.7028Z"
                    fill="black"
                />
                <path
                    d="M19.0281 11C14.036 10.986 9.98597 15.036 10 20.0281C10.0141 24.9641 14.1309 29 19.0668 29H28V20.0668C28 15.1309 23.9641 11.0141 19.0281 11ZM19.8016 24.5844C19.566 24.7918 19.2742 24.8973 18.9227 24.8973C18.5711 24.8973 18.2793 24.7918 18.0438 24.5844C17.8082 24.377 17.6922 24.1098 17.6922 23.7863C17.6922 23.4629 17.8082 23.1957 18.0438 22.9883C18.2793 22.7809 18.5711 22.6754 18.9227 22.6754C19.2742 22.6754 19.566 22.7809 19.8016 22.9883C20.0371 23.1957 20.1531 23.4629 20.1531 23.7863C20.1531 24.1098 20.0336 24.3734 19.8016 24.5844ZM21.8828 18.7028C21.7387 19.0016 21.5067 19.3074 21.1867 19.6168L20.4309 20.3199C20.2164 20.5274 20.0653 20.7383 19.9844 20.9563C19.9 21.1742 19.8543 21.4485 19.8473 21.7824H17.9629C17.9629 21.1426 18.0367 20.6363 18.1809 20.2672C18.3285 19.891 18.5711 19.5606 18.884 19.3039C19.2074 19.0332 19.457 18.7836 19.6258 18.5586C19.791 18.3442 19.8789 18.0805 19.8789 17.8133C19.8789 17.1524 19.5942 16.8184 19.0246 16.8184C18.7785 16.8114 18.543 16.9168 18.3883 17.1067C18.2266 17.3 18.1387 17.5602 18.1317 17.8906H15.9098C15.9203 17.0117 16.1946 16.3297 16.7395 15.841C17.2844 15.3524 18.0473 15.1063 19.0281 15.1063C20.0055 15.1063 20.7649 15.3313 21.3027 15.7848C21.8406 16.2383 22.1078 16.8781 22.1078 17.7114C22.1043 18.0524 22.0305 18.3934 21.8828 18.7028Z"
                    fill="#93FF6D"
                />
                <path
                    d="M3.85207 0H34.6982L38 3.66897V13.1034V34.331L34.6982 38H3.85207L0 34.331V13.1034V3.66897L3.85207 0Z"
                    fill="#062237"
                />
                <path
                    d="M3.85207 0H34.6982L38 3.66897V13.1034V34.331L34.6982 38H3.85207L0 34.331V13.1034V3.66897L3.85207 0Z"
                    fill="url(#paint0_linear_248_2609)"
                    fill-opacity="0.15"
                />
                <path
                    d="M19.5 9C18.121 8.99952 16.7554 9.27078 15.4813 9.79828C14.2071 10.3258 13.0494 11.0992 12.0743 12.0743C11.0992 13.0494 10.3258 14.2071 9.79828 15.4813C9.27078 16.7554 8.99952 18.121 9 19.5C8.99952 20.879 9.27078 22.2446 9.79828 23.5187C10.3258 24.7929 11.0992 25.9506 12.0743 26.9257C13.0494 27.9008 14.2071 28.6742 15.4813 29.2017C16.7554 29.7292 18.121 30.0005 19.5 30C20.879 30.0005 22.2446 29.7292 23.5187 29.2017C24.7929 28.6742 25.9506 27.9008 26.9257 26.9257C27.9008 25.9506 28.6742 24.7929 29.2017 23.5187C29.7292 22.2446 30.0005 20.879 30 19.5C30.0005 18.121 29.7292 16.7554 29.2017 15.4813C28.6742 14.2071 27.9008 13.0494 26.9257 12.0743C25.9506 11.0992 24.7929 10.3258 23.5187 9.79828C22.2446 9.27078 20.879 8.99952 19.5 9ZM19.5 27.0629C19.186 27.0637 18.8789 26.9712 18.6175 26.7972C18.3561 26.6232 18.1522 26.3755 18.0318 26.0856C17.9113 25.7956 17.8795 25.4764 17.9406 25.1684C18.0017 24.8604 18.1528 24.5775 18.3749 24.3554C18.5969 24.1334 18.8798 23.9823 19.1878 23.9212C19.4958 23.8601 19.815 23.8918 20.105 24.0123C20.3949 24.1328 20.6426 24.3367 20.8166 24.5981C20.9906 24.8595 21.0831 25.1666 21.0823 25.4806C21.0814 25.9 20.9144 26.3019 20.6178 26.5984C20.3213 26.8949 19.9194 27.062 19.5 27.0629ZM21.4201 13.993L20.8217 22.0699C20.8179 22.1258 20.7931 22.1781 20.7522 22.2163C20.7113 22.2545 20.6574 22.2757 20.6014 22.2755H18.3986C18.3426 22.2757 18.2887 22.2545 18.2478 22.2163C18.2069 22.1781 18.1821 22.1258 18.1783 22.0699L17.5799 13.993C17.5637 13.7309 17.6013 13.4682 17.6905 13.2211C17.7796 12.9741 17.9184 12.7479 18.0983 12.5566C18.2782 12.3652 18.4954 12.2127 18.7364 12.1085C18.9775 12.0042 19.2374 11.9504 19.5 11.9504C19.7626 11.9504 20.0225 12.0042 20.2636 12.1085C20.5046 12.2127 20.7218 12.3652 20.9017 12.5566C21.0816 12.7479 21.2204 12.9741 21.3095 13.2211C21.3987 13.4682 21.4363 13.7309 21.4201 13.993Z"
                    fill="#3483D5"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_248_2609"
                        x1="165.571"
                        y1="-2.20137"
                        x2="101.187"
                        y2="92.802"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#3B7EB7" />
                        <stop offset="1" stop-color="#145081" />
                    </linearGradient>
                </defs>
            </svg>
            <p>
                To create a stable, fair market, Rollbet offers both
                <span class="fade-green">Crypto</span>
                and
                <span class="fade-gold">Robux</span>
                currencies. This ensures players can deposit and withdraw in their preferred
                currency with minimal confusion.
                <span class="fade-green">Crypto</span>
                users get prioritized crypto withdrawals, while
                <span class="fade-gold">Robux</span>
                users have prioritized
                <span class="fade-gold">Robux</span>
                and skin withdrawals. Below are the benefits of each currency.
            </p>
        </div>
        <div class="currencies-pros">
            <div class="pros-crypto">
                <div>
                    <img src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                    <span class="fade-green">Crypto</span>
                </div>
                <div>
                    <span class="fade-green">No limitation to any withdraw method</span>
                </div>
                <div>
                    <span class="fade-green">Instant deposits</span>
                </div>
                <div>
                    <span class="fade-green">Prioritized Crypto withdrawals</span>
                </div>
            </div>
            <div class="pros-robux">
                <div>
                    <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                    <span class="fade-gold">Robux</span>
                </div>
                <div>
                    <span class="fade-gold">Limited to only Robux & Skin withdrawals</span>
                </div>
                <div>
                    <span class="fade-gold">Rain, promo codes and giveaways pay out Robux</span>
                </div>
                <div>
                    <span class="fade-gold">More withdraw options will be enabled soon</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalCurrencies'
    };
</script>

<style scoped>
    .modal-currencies {
        width: 733px;
        max-width: 733px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px;
        border-radius: 15px;
        background: radial-gradient(
                100% 100% at 50% -31.45%,
                rgba(0, 255, 194, 0.2) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(255deg, #07263d 0%, #07243a 100%);
    }

    .modal-currencies::after {
        content: '';
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 0 15px 15px;
        background: linear-gradient(0deg, #07263d 0%, rgba(7, 36, 58, 0) 100%);
    }

    .modal-currencies .currencies-title {
        display: flex;
        gap: 14px;
        align-items: center;
        width: 100%;
        font-size: 18px;
        font-weight: 700;
        color: white;
        padding-bottom: 20px;
        border-bottom: 1px solid #3f5bd333;
    }

    .modal-currencies .currencies-info svg {
        min-width: 38px;
    }
    .modal-currencies .currencies-info {
        display: flex;
        padding: 14px;
        gap: 14px;
        background: #051c3059;
        border: 1px solid #0b315259;
        font-weight: 500;
        border-radius: 8px;
        align-items: center;
        color: #5b84ae;
        margin: 14px 0;
    }

    .modal-currencies .currencies-pros {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        font-size: 13px;
    }

    .modal-currencies .pros-crypto,
    .modal-currencies .pros-robux {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .modal-currencies .pros-robux div,
    .modal-currencies .pros-crypto div {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #276ba147;
        gap: 7px;
        text-align: center;
        padding: 7px;
        min-height: 36px;
        font-size: 5px;
        font-weight: 500;
        clip-path: var(--octagonal-main);
        position: relative;
    }

    .modal-currencies .pros-robux div:first-child,
    .modal-currencies .pros-crypto div:first-child {
        background: linear-gradient(90deg, #0f426b55, #0d335555);
        font-weight: 600;
        margin: 6px 0;
    }

    .modal-currencies .pros-robux div:first-child::before,
    .modal-currencies .pros-crypto div:first-child::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: #12385e;
        height: 100%;
        font-size: 5px;
        clip-path: var(--octagonal-before);
    }

    .modal-currencies span,
    .modal-currencies p {
        font-size: 13px;
    }

    @media only screen and (max-width: 990px) {
        .modal-currencies {
            width: calc(100vw - 20px);
            padding: 35px 20px;
        }
    }
</style>
