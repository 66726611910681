<template>
    <div
        :class="[
            $style['blackjack-controls-bet'],
            {
                [$style.gold]: userBalanceData.type === 'robuxCoin',
                [$style.green]: userBalanceData.type === 'cryptoCoin'
            }
        ]"
    >
        <div :class="$style['timer-icon']">
            <svg
                width="17"
                height="21"
                viewBox="0 0 17 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.29764 3.22912V2.14875H9.82147C10.2716 2.14875 10.6399 1.78044 10.6399 1.3303C10.6399 0.880156 10.2716 0.511841 9.82147 0.511841H7.14515C6.69496 0.511841 6.3267 0.880156 6.3267 1.3303C6.3267 1.78044 6.69496 2.14875 7.14515 2.14875H7.66073V3.22912C6.35122 3.35191 5.12354 3.77747 4.05137 4.44042L3.30659 3.46645C3.0283 3.10633 2.51266 3.04089 2.15254 3.31094C1.80061 3.58924 1.72693 4.10487 2.00523 4.46499L2.74186 5.43077C1.05584 6.98585 0 9.20383 0 11.6674C-8.18456e-08 16.349 3.80584 20.1548 8.47919 20.1548C13.1608 20.1548 16.9666 16.349 16.9666 11.6674C16.9666 7.27229 13.5945 3.63835 9.29764 3.22912ZM8.47919 17.5194C5.24633 17.5194 2.63545 14.9003 2.63545 11.6756C2.63545 8.72097 4.82072 6.2738 7.66893 5.8891V11.6756C7.66893 12.1257 8.03724 12.494 8.48738 12.494H14.2657C13.8728 15.3341 11.4339 17.5194 8.47919 17.5194Z"
                    fill="black"
                />
                <path
                    d="M9.29764 3.22912V2.14875H9.82147C10.2716 2.14875 10.6399 1.78044 10.6399 1.3303C10.6399 0.880156 10.2716 0.511841 9.82147 0.511841H7.14515C6.69496 0.511841 6.3267 0.880156 6.3267 1.3303C6.3267 1.78044 6.69496 2.14875 7.14515 2.14875H7.66073V3.22912C6.35122 3.35191 5.12354 3.77747 4.05137 4.44042L3.30659 3.46645C3.0283 3.10633 2.51266 3.04089 2.15254 3.31094C1.80061 3.58924 1.72693 4.10487 2.00523 4.46499L2.74186 5.43077C1.05584 6.98585 0 9.20383 0 11.6674C-8.18456e-08 16.349 3.80584 20.1548 8.47919 20.1548C13.1608 20.1548 16.9666 16.349 16.9666 11.6674C16.9666 7.27229 13.5945 3.63835 9.29764 3.22912ZM8.47919 17.5194C5.24633 17.5194 2.63545 14.9003 2.63545 11.6756C2.63545 8.72097 4.82072 6.2738 7.66893 5.8891V11.6756C7.66893 12.1257 8.03724 12.494 8.48738 12.494H14.2657C13.8728 15.3341 11.4339 17.5194 8.47919 17.5194Z"
                    fill="url(#paint0_linear_72_2887)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_72_2887"
                        x1="16.9666"
                        y1="0.511841"
                        x2="-3.25208"
                        y2="4.7918"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
        PLACE YOUR BETS
        <span class="fade-green">({{ parseFloat(blackjackTimer).toFixed(2) }}s)</span>
        <div
            :class="$style['bet-container']"
            v-if="
                authUser.user !== null &&
                table.players.some((element) => element.user._id === authUser.user._id) === true
            "
        >
            <div :class="$style['container-amount']">
                <button
                    v-on:dragstart="(e) => blackjackBetHandleDrag(e, 0)"
                    v-on:click="$parent.blackjackSetChip(0)"
                    v-bind:class="[$style['button-red'], { [$style.active]: blackjackChip === 0 }]"
                    draggable="true"
                    id="bj-chip"
                >
                    <span>
                        {{ table.game.type === 'standard' ? '25' : '2.5K' }}
                    </span>
                </button>
                <button
                    v-on:dragstart="(e) => blackjackBetHandleDrag(e, 1)"
                    v-on:click="$parent.blackjackSetChip(1)"
                    v-bind:class="[
                        $style['button-purple'],
                        { [$style.active]: blackjackChip === 1 }
                    ]"
                    draggable="true"
                    id="bj-chip"
                >
                    <span>
                        {{ table.game.type === 'standard' ? '250' : '5K' }}
                    </span>
                </button>
                <button
                    v-on:dragstart="(e) => blackjackBetHandleDrag(e, 2)"
                    v-on:click="$parent.blackjackSetChip(2)"
                    v-bind:class="[$style['button-blue'], { [$style.active]: blackjackChip === 2 }]"
                    draggable="true"
                    id="bj-chip"
                >
                    <span>
                        {{ table.game.type === 'standard' ? '1K' : '10K' }}
                    </span>
                </button>
                <button
                    v-on:dragstart="(e) => blackjackBetHandleDrag(e, 3)"
                    v-on:click="$parent.blackjackSetChip(3)"
                    v-bind:class="[
                        $style['button-orange'],
                        { [$style.active]: blackjackChip === 3 }
                    ]"
                    draggable="true"
                    id="bj-chip"
                >
                    <span>
                        {{ table.game.type === 'standard' ? '2.5K' : '25K' }}
                    </span>
                </button>
            </div>
            <div :class="$style['container-buttons']">
                <button
                    v-on:click="blackjackClearButton()"
                    :class="$style['button-clear']"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <span>Clear</span>
                </button>
                <button
                    v-on:click="blackjackRedoButton()"
                    :class="$style['button-redo']"
                    v-bind:disabled="socketSendLoading !== null || blackjackRecent === null"
                >
                    <span>Redo</span>
                </button>
                <button
                    v-on:click="blackjackMaxButton()"
                    :class="$style['button-max']"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <span>Max Bet</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BlackjackControlsBet',
        props: ['table', 'blackjackChip'],
        data() {
            return {
                blackjackTimerRepeater: null,
                blackjackTimer: 13
            };
        },
        methods: {
            ...mapActions(['blackjackSendClearSocket', 'blackjackSendBetSocket']),
            blackjackStartTimer() {
                const timeEnding = new Date(this.table.game.updatedAt).getTime() + 1000 * 13;
                this.blackjackTimer =
                    (timeEnding - (new Date().getTime() + this.generalTimeDiff)) / 1000;

                if (this.blackjackTimer <= 0) {
                    this.blackjackTimer = 0;
                } else {
                    this.blackjackTimerRepeater = requestAnimationFrame(this.blackjackStartTimer);
                }
            },
            blackjackClearButton() {
                const data = { table: this.table.table };
                this.blackjackSendClearSocket(data);
            },
            blackjackRedoButton() {
                const data = { table: this.table.table, bets: this.blackjackRecent };
                this.blackjackSendBetSocket(data);
            },
            blackjackMaxButton() {
                const highestAmount =
                    this.table.game.type === 'standard'
                        ? process.env.VUE_APP_BLACKJACK_MAX_AMOUNT_STANDARD * 1000
                        : process.env.VUE_APP_BLACKJACK_MAX_AMOUNT_WHALE * 1000;
                const lowestAmount =
                    this.table.game.type === 'standard'
                        ? process.env.VUE_APP_BLACKJACK_MIN_AMOUNT_STANDARD * 1000
                        : process.env.VUE_APP_BLACKJACK_MIN_AMOUNT_WHALE * 1000;
                const userBalance = Object.assign({}, this.authUser.user.balance);
                const totalBet = { robuxCoin: 0, cryptoCoin: 0 };
                let bets = [];

                for (const player of this.table.players.filter(
                    (element) => element.user._id === this.authUser.user._id
                )) {
                    const amountCurrent =
                        player.bet !== null
                            ? player.bet.amount.main +
                              player.bet.amount.sideLeft +
                              player.bet.amount.sideRight
                            : 0;

                    let amountBet = Math.floor(highestAmount - amountCurrent);
                    // Make amountBet divisible by lowest amount
                    amountBet = amountBet - (amountBet % lowestAmount);

                    if (amountBet === 0) continue;
                    // Bet amount addition needed / not maxed yet
                    bets.push({
                        seat: player.seat,
                        amount: { main: amountBet, sideLeft: 0, sideRight: 0 },
                        coinType: player.bet?.coinType || this.userBalanceData.type
                    });

                    totalBet[player.bet?.coinType || this.userBalanceData.type] += amountBet;
                }

                for (const coinType of Object.keys(totalBet)) {
                    if (userBalance[coinType] > totalBet[coinType]) continue;

                    const filteredBets = bets
                        .filter((bet) => bet.coinType === coinType)
                        .sort((a, b) => b.amount.main - a.amount.main);
                    const sanitizedBets = bets.filter((bet) => bet.coinType !== coinType);
                    const newBets = filteredBets.map(() => 0);

                    let count = 0;

                    while (
                        count * lowestAmount <= totalBet[coinType] &&
                        (count + 1) * lowestAmount <= userBalance[coinType]
                    ) {
                        const index = count % newBets.length;
                        const newBet = newBets[index] + lowestAmount;
                        if (newBet <= filteredBets[index].amount.main) newBets[index] = newBet;
                        count += 1;
                    }

                    bets = [
                        ...filteredBets.map((bet, i) => ({
                            ...bet,
                            amount: { ...bet.amount, main: newBets[i] }
                        })),
                        ...sanitizedBets
                    ].filter((bet) => bet.amount.main !== 0);
                }

                // bets = bets
                //     // Added balance and lesser amount priority
                //     .sort((a, b) => a.amount.main - b.amount.main)
                //     .map(bet => {
                //         let balanceNew = userBalance[bet.coinType] - bet.amount.main
                //         let amountNew = bet.amount.main

                //         if (balanceNew < 0) {
                //             amountNew = amountNew - Math.abs(balanceNew)
                //             amountNew = amountNew - (amountNew % lowestAmount)
                //             balanceNew = userBalance[bet.coinType] - amountNew
                //         }

                //         userBalance[bet.coinType] = balanceNew
                //         return { ...bet, amount: { ...bet.amount, main: amountNew } }
                //     })
                //     .filter(bet => bet.amount.main !== 0)

                const data = { table: this.table.table, bets };
                if (bets.length) this.blackjackSendBetSocket(data);
            },
            blackjackBetHandleDrag(event, index) {
                event.dataTransfer.setData('text', event.target.id);
                this.$parent.blackjackSetChip(index);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'generalTimeDiff',
                'authUser',
                'blackjackRecent',
                'userBalanceData'
            ])
        },
        watch: {
            table: {
                handler(data, oldData) {
                    if (
                        this.table.game.state === 'countdown' ||
                        (this.table.game.state === 'running' && this.table.playerPos !== null)
                    ) {
                        this.blackjackStartTimer();
                    }
                },
                deep: true
            }
        },
        beforeUnmount() {
            cancelAnimationFrame(this.blackjackTimerRepeater);
        },
        created() {
            if (
                this.table.game.state === 'countdown' ||
                (this.table.game.state === 'running' && this.table.playerPos !== null)
            ) {
                this.blackjackStartTimer();
            }
        }
    };
</script>

<style module>
    .blackjack-controls-bet {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background: #0f2b4177;
        backdrop-filter: blur(4px);
        border: 1px solid #0b325366;
        font-weight: 600;
        border-radius: 8px;
        color: white;
        z-index: 1;
    }

    .blackjack-controls-bet > span {
        font-size: 13px;
        font-weight: 500;
        display: inline-block;
    }

    .blackjack-controls-bet .timer-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: #11324d;
        height: 37px;
        width: 37px;
        left: 10px;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .blackjack-controls-bet .bet-container {
        transform: translate(-50%, -50%);
        opacity: 1;
        position: absolute;
        top: calc(100% + 260px);
        left: 50%;
        animation: blackjack-bet-entry 0.25s ease;
    }

    @keyframes blackjack-bet-entry {
        0% {
            transform: translate(-50%, 0%);
            opacity: 0;
        }
        100% {
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }

    .blackjack-controls-bet .container-amount {
        display: flex;
        gap: 7px;
    }

    .blackjack-controls-bet .container-amount button {
        background-size: contain;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        opacity: 0.6;
    }

    .blackjack-controls-bet.gold .container-amount button.active {
        outline: 4px solid #eec763;
        opacity: 1;
    }

    .blackjack-controls-bet.green .container-amount button.active {
        outline: 4px solid #3efa8d;
        opacity: 1;
    }

    .blackjack-controls-bet .container-amount button span {
        color: white;
        font-size: 13px;
        font-weight: 600;
        display: inline-block;
        transform: translateY(-1px);
    }

    .blackjack-controls-bet .container-amount button.button-red {
        background-image: url('~@/assets/img/blackjack/chip-red.png');
    }

    .blackjack-controls-bet .container-amount button.button-purple {
        background-image: url('~@/assets/img/blackjack/chip-purple.png');
    }

    .blackjack-controls-bet .container-amount button.button-blue {
        background-image: url('~@/assets/img/blackjack/chip-blue.png');
    }

    .blackjack-controls-bet .container-amount button.button-orange {
        background-image: url('~@/assets/img/blackjack/chip-orange.png');
    }

    .blackjack-controls-bet .container-buttons {
        display: flex;
        background: #081929;
        max-width: 251px;
        margin: auto;
        margin-top: 10px;
        padding: 7px;
        gap: 7px;
        position: relative;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .blackjack-controls-bet .container-buttons button {
        background: #142e46;
        position: relative;
        height: 35px;
        flex: 1;
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .blackjack-controls-bet .container-buttons::before,
    .blackjack-controls-bet .container-buttons button::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #153250;
        font-size: 5px;
        clip-path: var(--octagonal-before);
    }

    .blackjack-controls-bet .container-buttons button::before {
        background: linear-gradient(0deg, #26445e, transparent);
        font-size: 3px;
    }

    .blackjack-controls-bet .container-buttons button span {
        font-size: 14px;
        font-weight: 600;
        color: #6285ab;
    }

    .blackjack-controls-bet .container-buttons button:hover {
        filter: brightness(1.25);
    }

    .blackjack-controls-bet .container-buttons button.button-max span {
        color: white;
    }

    .blackjack-controls-bet .container-buttons button.button-max {
        background: linear-gradient(45deg, #00aa6d, #00ffc2 120%);
    }

    .blackjack-controls-bet .container-buttons button.button-max::before {
        background: linear-gradient(0deg, #1effc2, transparent);
    }

    .blackjack-controls-bet.gold .container-buttons button.button-max {
        background: linear-gradient(45deg, #aa8b00, #ffd000 120%);
    }

    .blackjack-controls-bet.gold .container-buttons button.button-max::before {
        background: linear-gradient(0deg, #ffe51e, transparent);
    }

    @media screen and (max-width: 600px) {
        .blackjack-controls-bet .bet-container {
            /* top: calc(-100% - 260px - 130px); */
        }
    }
</style>
