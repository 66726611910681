<template>
    <div :class="$style['unbox-overview']">
        <transition name="fade" mode="out-in">
            <div
                v-if="socketUnbox.connected === false"
                :class="$style['overview-loading']"
                key="loading"
            >
                <div :class="$style['loading-placeholder']"></div>
                <div :class="$style['loading-placeholder']"></div>
                <div :class="$style['loading-placeholder']"></div>
                <div :class="$style['loading-placeholder']"></div>
                <div :class="$style['loading-placeholder']"></div>
                <div :class="$style['loading-placeholder']"></div>
                <div :class="$style['loading-placeholder']"></div>
            </div>
            <div v-else-if="unboxGetBoxes.length > 0" :class="$style['overview-list']" key="data">
                <UnboxBoxElement
                    v-for="box of unboxGetBoxes"
                    v-bind:key="box._id"
                    v-bind:box="box"
                />
            </div>
            <div v-else :class="$style['overview-empty']" key="empty">There are no boxes.</div>
        </transition>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import UnboxBoxElement from '@/components/unbox/UnboxBoxElement';

    export default {
        name: 'UnboxOverview',
        components: {
            UnboxBoxElement
        },
        computed: {
            ...mapGetters([
                'socketUnbox',
                'unboxFilterSearch',
                'unboxFilterSort',
                'unboxFilterSelect',
                'unboxBoxes'
            ]),
            unboxGetBoxes() {
                let boxes = [];

                for (const box of this.unboxBoxes) {
                    if (
                        box.categories.includes(this.unboxFilterSelect) === true &&
                        box.name
                            .toLowerCase()
                            .includes(this.unboxFilterSearch.toLowerCase().trim()) === true
                    ) {
                        boxes.push(box);
                    }
                }

                if (this.unboxFilterSort === 'highest') {
                    boxes.sort(function (a, b) {
                        return b.amount - a.amount;
                    });
                } else {
                    boxes.sort(function (a, b) {
                        return a.amount - b.amount;
                    });
                }

                return boxes;
            }
        }
    };
</script>

<style scoped>
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-enter-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-enter-from {
        opacity: 0;
    }
</style>

<style module>
    .unbox-overview {
        width: 100%;
    }

    .unbox-overview .overview-loading,
    .unbox-overview .overview-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 7px;
    }

    .unbox-overview .loading-placeholder {
        aspect-ratio: 190 / 240;
        width: 100%;
        position: relative;
        background: linear-gradient(
            223deg,
            rgba(5, 29, 48, 0.35) 0%,
            rgba(31, 99, 153, 0.09) 50%,
            rgba(5, 29, 48, 0.35) 100%
        );
        border: 1px solid #0a273f;
        overflow: hidden;
    }

    .unbox-overview .loading-placeholder:after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(
            to right,
            #ffffff00 0%,
            rgba(255, 255, 255, 0.1) 50%,
            #ffffff00 100%
        );
    }

    .unbox-overview .loading-placeholder:nth-child(7n) {
        margin-right: 0;
    }

    .unbox-overview .overview-empty {
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    @keyframes loading_animation {
        0% {
            transform: translateX(-100%);
        }
        50% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(100%);
        }
    }

    @media screen and (max-width: 1200px) {
        .unbox-overview .overview-loading,
        .unbox-overview .overview-list {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 900px) {
        .unbox-overview .overview-loading,
        .unbox-overview .overview-list {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 800px) {
        .unbox-overview .overview-loading,
        .unbox-overview .overview-list {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 600px) {
        .unbox-overview .overview-loading,
        .unbox-overview .overview-list {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 400px) {
        .unbox-overview .overview-loading,
        .unbox-overview .overview-list {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
</style>
