<template>
    <div :class="$style.privacy">
        <p :class="$style['privacy-title']">
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.34097 9.17376L12.0248 7.78655C12.2548 7.66802 12.5128 7.6053 12.7708 7.59833V2.07039C12.7708 0.934123 11.8436 0 10.7003 0H4.55901C4.01526 0 3.47848 0.223075 3.09505 0.606467L0.606382 3.09509C0.216139 3.48546 0 4.00828 0 4.55901V12.8683C0 14.0046 0.927017 14.9387 2.07034 14.9387H9.78721C9.03174 13.736 8.59761 12.2154 8.42774 10.9304C8.33022 10.2194 8.69272 9.50835 9.34097 9.17376ZM4.92849 2.78837H7.84927C8.2327 2.78837 8.54636 3.10207 8.54636 3.48546C8.54636 3.8689 8.2327 4.18256 7.84927 4.18256H4.92849C4.54506 4.18256 4.2314 3.8689 4.2314 3.48546C4.2314 3.10207 4.54506 2.78837 4.92849 2.78837ZM5.31176 10.3867H3.48546C3.10203 10.3867 2.78837 10.073 2.78837 9.6896C2.78837 9.29923 3.10203 8.99251 3.48546 8.99251H5.31176C5.69519 8.99251 6.00885 9.29923 6.00885 9.6896C6.00885 10.073 5.69519 10.3867 5.31176 10.3867ZM3.48546 7.28463C3.10203 7.28463 2.78837 6.97093 2.78837 6.58753C2.78837 6.20414 3.10203 5.89044 3.48546 5.89044H9.29213C9.67556 5.89044 9.98922 6.20414 9.98922 6.58753C9.98922 6.97093 9.67556 7.28463 9.29213 7.28463H3.48546Z"
                    fill="#21B894"
                />
                <path
                    d="M15.9985 10.7492C16.0124 10.6098 15.9427 10.4773 15.8172 10.4145L12.9591 9.02036C12.8964 8.99249 12.8336 8.98552 12.771 8.99947C12.7361 8.99947 12.7012 9.00645 12.6663 9.02734L9.98265 10.4145C9.85705 10.4773 9.78744 10.6028 9.80837 10.7422C10.1019 12.7597 10.9078 14.9879 12.764 15.8519C12.8476 15.8937 12.9521 15.8937 13.0428 15.8519C14.8952 15.0042 15.7038 12.7676 15.9985 10.7492Z"
                    fill="#21B894"
                />
            </svg>
            Privacy Policy
        </p>
        <div :class="$style['privacy-content']">
            <div :class="$style['content-article']">
                <h1>Privacy Policy</h1>
                <div :class="$style['article-sticky-cover']"></div>
                <div v-for="(item, index) in privacyContent" v-bind:key="index">
                    <br />
                    <br />
                    <h2 :class="$style['article-sticky-title']">
                        {{ item.title }}
                    </h2>
                    <div
                        v-for="(subItem, subIndex) in item.items"
                        v-bind:key="`${index}-${subIndex}`"
                        :class="[
                            $style['article-accordion'],
                            { [$style.active]: expand.includes(`${index}-${subIndex}`) }
                        ]"
                    >
                        <button
                            :class="$style['article-sticky-subtitle']"
                            v-on:click="expandAction(`${index}-${subIndex}`)"
                        >
                            <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547938 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84763C4.84671 6.05079 5.15326 6.05079 5.33889 5.84763L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                                    fill="#5B84AE"
                                />
                            </svg>
                            <h4>{{ subItem.title }}</h4>
                        </button>
                        <div v-html="subItem.html"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Privacy',
        metaInfo: {
            title: 'Privacy - Rollbet.gg'
        },
        data() {
            return {
                expand: [],
                privacyContent: [
                    {
                        title: `1. Data Collection and Usage`,
                        items: [
                            {
                                title: `1.1 Account Registration Information`,
                                html: `<p>When you create an account on our platform, we gather essential personal details such as your Roblox username, email address, and IP address, which are necessary for account authentication and security.</p>`
                            },
                            {
                                title: `1.2 Website and Service Interaction Data`,
                                html: `<p>We monitor and record data related to your interactions with our website and services, including your browser type, the specific pages you visit, and the duration of your visits. This data helps us understand user behavior and enhance our services.</p>`
                            },
                            {
                                title: `1.3 Cookies and Tracking Technologies`,
                                html: `<p>We employ cookies and similar tracking technologies to collect information regarding your interactions with our website. While you can disable cookies through your browser settings, doing so may limit your ability to use certain features of our site.</p>`
                            },
                            {
                                title: `1.4 Location Information`,
                                html: `<p>We may derive location data from your IP address or other information provided by your device to tailor our services to your region and enhance your user experience.</p>`
                            },
                            {
                                title: `1.5 Virtual Currency Data`,
                                html: `<p>We collect information on your usage of in-game currencies, such as Robux and Gems, including transaction history and balances, which allows us to manage your account activities and ensure a secure gaming environment.</p>`
                            }
                        ]
                    },
                    {
                        title: `2. Utilization of Collected Information`,
                        items: [
                            {
                                title: `2.1 Service Provision and Enhancement`,
                                html: `<p>The information we gather is integral to our ability to provide, maintain, and improve our website and services. This includes, but is not limited to:</p><ul><li>Verifying your identity and ensuring the security of your account</li><li>Facilitating transactions, including those involving Robux and Gems</li><li>Communicating with you about account activities and service updates</li><li>Delivering customer support and resolving inquiries</li><li>Optimizing website performance and user experience</li></ul>`
                            },
                            {
                                title: `2.2 Marketing and Communications`,
                                html: `<p>We may use your personal data to send you promotional materials, including newsletters and special offers, related to Rollbet and its services. You retain the right to opt out of these communications at any time via the unsubscribe link provided in our emails.</p>`
                            },
                            {
                                title: `2.3 Research and Development`,
                                html: `<p>Your data may be utilized in our ongoing efforts to develop and refine our services. This includes the testing and implementation of new features, as well as assessing the impact of in-game currencies such as Robux and Gems.</p>`
                            },
                            {
                                title: `2.4 Legal and Compliance Purposes`,
                                html: `<p>We may process your data to fulfill our legal obligations, enforce our terms of service, and prevent fraudulent activities. This includes complying with regulatory requirements and responding to legal processes.</p>`
                            }
                        ]
                    },
                    {
                        title: `3. Disclosure of Information`,
                        items: [
                            {
                                title: `3.1 Third-Party Service Providers`,
                                html: `<p>We may share your personal information with third-party vendors who assist us in delivering our services. These providers are obligated to keep your data confidential and are only authorized to use it in connection with the services they provide to us.</p>`
                            },
                            {
                                title: `3.2 Payment Processors`,
                                html: `<p>Information related to your transactions may be shared with third-party payment processors to facilitate payments. We advise you to review their privacy policies to understand how your data will be handled.</p>`
                            },
                            {
                                title: `3.3 Legal Obligations`,
                                html: `<p>We may disclose your personal information if required by law, such as in response to a court order, subpoena, or other legal process, or to comply with regulatory obligations.</p>`
                            },
                            {
                                title: `3.4 Business Transactions`,
                                html: `<p>In the event of a merger, acquisition, or sale of Rollbet, your information may be transferred as part of the business assets involved in such a transaction.</p>`
                            }
                        ]
                    },
                    {
                        title: `4. Data Security`,
                        items: [
                            {
                                title: `4.1 Protective Measures`,
                                html: `<p>We implement a range of security measures designed to safeguard your personal data from unauthorized access, alteration, or disclosure. However, no system can be entirely secure, and we cannot guarantee absolute security.</p>`
                            },
                            {
                                title: `4.2 Account Security Responsibility`,
                                html: `<p>You are responsible for maintaining the confidentiality of your account credentials and for all activities conducted under your account. We recommend using a strong password and logging out of your account when finished.</p>`
                            }
                        ]
                    },
                    {
                        title: `5. Children’s Privacy`,
                        items: [
                            {
                                title: `5.1 Age Restrictions`,
                                html: `<p>Our services are intended for users aged 18 and older. We do not knowingly collect personal data from children under 18. Should we discover that we have inadvertently obtained such information, we will take steps to delete it promptly.</p>`
                            }
                        ]
                    },
                    {
                        title: `6. Updates to this Privacy Policy`,
                        items: [
                            {
                                title: `6.1 Policy Modifications`,
                                html: `<p>We may revise this Privacy Policy periodically. All changes will be posted on our website, and continued use of our services following any changes constitutes acceptance of the updated terms.</p>`
                            }
                        ]
                    },
                    {
                        title: `Contact Information`,
                        items: [
                            {
                                title: `Contact Us`,
                                html: `<p>If you have any questions or concerns regarding this Privacy Policy, please reach out to us in <a href="https://discord.gg/rollbet" target="_blank">our Discord</a>.</p>`
                            }
                        ]
                    }
                ]
            };
        },
        methods: {
            expandAction(index) {
                if (this.expand.includes(index)) {
                    this.expand = this.expand.filter((item) => item !== index);
                } else {
                    this.expand = [...this.expand, index];
                }
            }
        }
    };
</script>

<style module>
    .privacy .privacy-content h1 {
        font-size: 22px;
        color: white;
        font-weight: 600;
    }

    .privacy .privacy-content h2 {
        font-size: 20px;
        color: white;
        font-weight: 600;
        padding-top: 7px;
        margin-top: -7px;
        padding-bottom: 28px;
        margin-bottom: -14px;
        white-space: nowrap;
    }

    .privacy .privacy-content br {
        display: block;
        margin: 9px 0;
    }

    .privacy .privacy-content h4 {
        font-size: 15px;
        color: white;
        font-weight: 600;
        line-height: 1.5;
    }

    .privacy .privacy-content ul li::before {
        display: inline-block;
        content: '';
        width: 3px;
    }
    .privacy .privacy-content ul {
        list-style-position: inside;
        margin-left: 8px;
    }

    .privacy .privacy-content ul,
    .privacy .privacy-content p {
        color: #4f6a83;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 12px;
    }

    .privacy .privacy-content section {
        padding-left: 24px;
    }

    .privacy .privacy-content a {
        color: #21b894;
    }

    .privacy {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 45px 10px;
    }

    .privacy-title {
        width: 100%;
        max-width: 1345px;
        color: white;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 7px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .privacy-content {
        width: 100%;
        max-width: 1345px;
        background: #081624;
        padding: 45px 20px 60px 20px;
        border-radius: 15px;
    }

    .content-article {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding-right: 20px;
    }

    .article-accordion > button svg {
        transition: transform 0.3s ease;
        display: none;
    }

    .article-accordion.active > button svg {
        transform: rotate(-180deg);
    }

    .article-accordion > button {
        width: 100%;
        display: flex;
        gap: 7px;
        align-items: center;
        pointer-events: none;
    }

    .article-accordion > div {
        transition: max-height 0.3s ease;
        overflow: hidden;
        flex-direction: column;
        justify-content: flex-end;
        display: flex;
        max-height: unset;
    }

    .article-accordion.active > div {
        max-height: 1000px;
    }

    .article-sticky-cover {
        mask-image: linear-gradient(0deg, transparent, black 30%);
        backdrop-filter: blur(2px);
        background: #08162459;
        margin-bottom: -100px;
        position: sticky;
        height: 100px;
        width: 100%;
        z-index: 0;
        top: 0;
    }

    .article-sticky-title {
        background: linear-gradient(0deg, transparent, #081624 60%);
        position: sticky;
        z-index: 2;
        top: 0;
    }

    .article-sticky-subtitle {
        position: sticky;
        z-index: 1;
        top: 34px;
    }

    @media only screen and (max-width: 1500px) {
        .privacy {
            padding: 30px 10px 45px 10px;
        }
    }

    @media screen and (max-width: 475px) {
        /* .article-accordion > button svg {
            display: block;
        }

        .article-accordion > button {
            pointer-events: all;
        }

        .article-accordion > div {
            max-height: 7px;
            padding-left: 16px;
        } */
    }
</style>
