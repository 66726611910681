<template>
    <div :class="[$style['cashier-robux-element'], $style[offer.state]]">
        <div :class="[$style['element-section'], $style['section-date']]">
            <div :class="$style['section-title']">DATE</div>
            <div :class="$style['section-content']">
                {{
                    new Date(offer.createdAt || '').toLocaleString('en-US', {
                        hour12: true,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                }}
            </div>
        </div>
        <div :class="[$style['element-section'], $style['section-amount']]">
            <div :class="$style['section-title']">AMOUNT</div>
            <div :class="$style['section-content']">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                {{ cashierFormatValue(offer.amount) }}
            </div>
        </div>
        <div
            :class="[
                $style['element-section'],
                $style['section-state'],
                $style['state-' + offer.state]
            ]"
        >
            <div :class="$style['section-title']">STATE</div>
            <div :class="$style['section-content']">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                <p>
                    {{ cashierFormatValue(offer.amountProcessed) }} /&nbsp;
                    <span>{{ cashierFormatValue(offer.amount) }}</span>
                </p>
            </div>
        </div>
        <div :class="[$style['element-section'], $style['section-action']]">
            <div :class="$style['section-title']">ACTION</div>
            <div :class="$style['section-content']">
                <div
                    v-if="['canceled', 'failed', 'completed'].includes(offer.state) === true"
                    :class="$style['action-placeholder']"
                ></div>
                <button
                    v-else
                    v-on:click="cashierCancelButton()"
                    :class="$style['button-cancel']"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <svg
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.32453 4.49997L8.82895 0.995447C9.05701 0.767492 9.05701 0.398921 8.82895 0.170966C8.601 -0.0569887 8.23242 -0.0569887 8.00447 0.170966L4.49994 3.67549L0.995527 0.170966C0.767466 -0.0569887 0.399001 -0.0569887 0.171046 0.170966C-0.0570154 0.398921 -0.0570154 0.767492 0.171046 0.995447L3.67546 4.49997L0.171046 8.0045C-0.0570154 8.23245 -0.0570154 8.60102 0.171046 8.82898C0.28465 8.94269 0.434022 8.99981 0.583287 8.99981C0.732552 8.99981 0.881817 8.94269 0.995527 8.82898L4.49994 5.32445L8.00447 8.82898C8.11818 8.94269 8.26744 8.99981 8.41671 8.99981C8.56597 8.99981 8.71524 8.94269 8.82895 8.82898C9.05701 8.60102 9.05701 8.23245 8.82895 8.0045L5.32453 4.49997Z"
                            fill="#5B84AE"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'CashierRobuxElement',
        props: ['offer'],
        methods: {
            ...mapActions(['cashierSendRobuxCancelSocket']),
            ...mapGetters(['userBalanceData']),
            cashierFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            cashierCancelButton() {
                const data = { offerId: this.offer._id, type: this.userBalanceData.type };
                this.cashierSendRobuxCancelSocket(data);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'modalsShow', 'modalsData', 'generalUserInfo']),
            cashierGetState() {
                let state = this.offer.state.charAt(0).toUpperCase() + this.offer.state.slice(1);

                if (['created', 'pending'].includes(this.offer.state) === true) {
                    state = 'Active';
                }

                return state;
            }
        }
    };
</script>

<style module>
    .cashier-robux-element {
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 0 20px;
    }

    .cashier-robux-element:nth-child(odd) {
        background-color: #0d253b;
    }

    .cashier-robux-element.canceled .element-section > *,
    .cashier-robux-element.failed .element-section > * {
        opacity: 0.3;
    }
    .cashier-robux-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .cashier-robux-element .element-section.section-date,
    .cashier-robux-element .element-section.section-state,
    .cashier-robux-element .element-section.section-amount {
        width: 30%;
    }

    .cashier-robux-element .element-section.section-action {
        width: 10%;
        align-items: flex-end;
    }

    .cashier-robux-element .section-title {
        display: none;
        font-size: 13px;
        font-weight: 600;
        color: #5b85ae;
    }

    .cashier-robux-element .section-content {
        display: flex;
        align-items: center;
    }

    .cashier-robux-element .element-section.section-date .section-content {
        font-size: 14px;
        font-weight: 400;
        color: #5b84ae;
    }

    .cashier-robux-element .element-section.section-state .section-content,
    .cashier-robux-element .element-section.section-amount .section-content {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .cashier-robux-element .element-section.section-state .section-content img,
    .cashier-robux-element .element-section.section-amount .section-content img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .cashier-robux-element .element-section.section-state .section-content span,
    .cashier-robux-element .element-section.section-amount .section-content span {
        margin-right: 5px;
        color: rgba(255, 255, 255, 0.5);
    }

    .cashier-robux-element button.button-cancel {
        width: 26px;
        height: 26px;
        filter: drop-shadow(0px 1px 0px #00000059);
    }

    .cashier-robux-element button.button-cancel::before,
    .cashier-robux-element button.button-cancel::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 2px;
        z-index: -1;
    }

    .cashier-robux-element button.button-cancel::before {
        clip-path: var(--octagonal-before);
        background: #132d48;
    }
    .cashier-robux-element button.button-cancel::after {
        clip-path: var(--octagonal-after);
        background: #0f2840;
    }

    @media only screen and (max-width: 800px) {
        .cashier-robux-element {
            height: auto;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .cashier-robux-element .element-section.section-date,
        .cashier-robux-element .element-section.section-state,
        .cashier-robux-element .element-section.section-amount,
        .cashier-robux-element .element-section.section-action {
            width: 50%;
            margin-top: 10px;
        }

        .cashier-robux-element .element-section.section-state {
            width: fit-content;
            min-width: 50%;
        }

        .cashier-robux-element .element-section.section-amount,
        .cashier-robux-element .element-section.section-date {
            margin-top: 0;
        }

        .cashier-robux-element .element-section.section-action {
            width: unset;
            max-width: 50%;
            flex: 1;
            align-items: flex-start;
        }

        .cashier-robux-element .section-title {
            display: block;
        }

        .cashier-robux-element .section-content {
            margin-top: 5px;
        }

        .cashier-robux-element .element-section.section-action .action-placeholder {
            width: auto;
        }
    }
</style>
