<template>
    <div :class="$style['upgrader-filter-search']" class="octagonal before after hoverable">
        <input
            v-on:input="upgraderInput(upgraderSearch)"
            v-model="upgraderSearch"
            type="text"
            placeholder="Search Items"
        />
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.7204 9.43396H10.0429L9.80274 9.2024C10.6432 8.2247 11.1492 6.9554 11.1492 5.57461C11.1492 2.49571 8.65352 0 5.57461 0C2.49571 0 0 2.49571 0 5.57461C0 8.65352 2.49571 11.1492 5.57461 11.1492C6.9554 11.1492 8.2247 10.6432 9.2024 9.80274L9.43396 10.0429V10.7204L13.7221 15L15 13.7221L10.7204 9.43396ZM5.57461 9.43396C3.43911 9.43396 1.71527 7.71012 1.71527 5.57461C1.71527 3.43911 3.43911 1.71527 5.57461 1.71527C7.71012 1.71527 9.43396 3.43911 9.43396 5.57461C9.43396 7.71012 7.71012 9.43396 5.57461 9.43396Z"
                fill="#406992"
            />
        </svg>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'UpgraderFilterSearch',
        data() {
            return {
                upgraderSearch: ''
            };
        },
        methods: {
            ...mapActions(['upgraderSetFilterSearch']),
            upgraderInput() {
                this.upgraderSetFilterSearch(this.upgraderSearch);
            }
        },
        computed: {
            ...mapGetters(['upgraderFilterSearch'])
        },
        created() {
            this.upgraderSearch = this.upgraderFilterSearch;
        }
    };
</script>

<style module>
    .upgrader-filter-search {
        width: 100%;
        max-width: 209px;
        height: 46px;
        margin-right: auto;
    }

    .upgrader-filter-search svg {
        position: absolute;
        top: calc(50% - 7.5px);
        left: 9px;
    }

    .upgrader-filter-search input {
        appearance: none;
        background: none;
        padding-left: calc(9px + 15px + 7px);
        font-size: 14px;
        color: #406992;
        font-weight: 500;
        height: 100%;
        width: 100%;
    }

    .upgrader-filter-search::before {
        background: #112a43;
        font-size: 3px;
    }

    .upgrader-filter-search::after {
        background: linear-gradient(0deg, #0b243b, #0c2339);
        font-size: 3px;
    }
</style>
