var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['battles-filter-mode'],
        {
            [_vm.$style['mode-open']]: _vm.battlesDropdown === true,
            [_vm.$style['mode-group']]: _vm.battlesFilterType === 'group'
        }
    ]},[_c('button',{class:_vm.$style['button-toggle'],on:{"click":function($event){return _vm.battlesToggleDropdown()}}},[_c('div',{class:_vm.$style['button-value']},[_vm._v(" MODE: "),_c('span',[_vm._v(_vm._s(_vm.battlesFilterMode))])]),_c('svg',{attrs:{"width":"10","height":"6","viewBox":"0 0 10 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.5176 1.66411e-06L0.482354 8.43375e-08C0.0547936 9.58042e-09 -0.16302 0.516304 0.143533 0.822859L4.66115 5.34052C4.8467 5.52607 5.15325 5.52607 5.33888 5.34052L9.8565 0.822861C10.163 0.516306 9.94516 1.73887e-06 9.5176 1.66411e-06Z"}})])]),_c('div',{class:_vm.$style['mode-menu']},[_c('div',{class:_vm.$style['menu-inner']},[_c('button',{on:{"click":function($event){return _vm.battlesModeButton('1v1')}}},[_vm._v("1v1")]),_c('button',{on:{"click":function($event){return _vm.battlesModeButton('1v1v1')}}},[_vm._v("1v1v1")]),_c('button',{on:{"click":function($event){return _vm.battlesModeButton('1v1v1v1')}}},[_vm._v("1v1v1v1")]),(_vm.battlesFilterType === 'standard')?_c('button',{on:{"click":function($event){return _vm.battlesModeButton('2v2')}}},[_vm._v(" 2v2 ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }