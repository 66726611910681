<template>
    <form :class="$style['login-forgot']" v-on:submit.prevent="modalResetButton">
        <div :class="$style['forgot-element']">
            <div :class="$style['element-title']">Enter Email</div>
            <div :class="$style['element-input']" class="octagonal hoverable">
                <input v-model="loginEmail" type="email" required />
            </div>
        </div>
        <div :class="$style['forgot-button']">
            <div :class="$style.divider"></div>
            <button
                v-on:click="modalResetButton()"
                type="button"
                :class="$style['button-action']"
                class="octagonal hoverable"
                v-bind:disabled="authSendLoginLoading === true"
            >
                <span>Reset</span>
            </button>
            <div :class="$style.divider"></div>
        </div>
    </form>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'LoginForgot',
        data() {
            return {
                loginEmail: null
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'modalsSetShow', 'modalsSetData']),
            modalResetButton() {
                if (this.loginEmail === null || this.loginEmail.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered email is invalid.'
                    });
                    return;
                }

                setTimeout(() => {
                    this.modalsSetData({
                        typeCaptcha: 'credentialsRequest',
                        data: { type: 'reset', email: this.loginEmail }
                    });
                    this.modalsSetShow('Captcha');
                }, 200);
            }
        }
    };
</script>

<style module>
    .login-forgot {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .forgot-element .element-title {
        margin-bottom: 7px;
        color: #5b85ae;
        font-size: 15px;
    }

    .element-title .button-forgot {
        margin-left: 2px;
        color: #5b85ae;
        opacity: 0.5;
    }

    .forgot-element .element-input input {
        background: none;
        height: 57px;
        color: #5b85ae;
        width: 100%;
        font-size: 14px;
        padding: 14px 20px;
        appearance: none;
    }

    .forgot-element .element-input {
        background: linear-gradient(0deg, #0f426b55, #0d335555);
        width: 100%;
        position: relative;
        clip-path: var(--octagonal-main);
        color: #5b85ae;
        font-size: 7px;
    }

    .forgot-element .element-input::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #1e4b7e55;
        clip-path: var(--octagonal-before);
    }

    .forgot-button {
        display: flex;
        align-items: center;
        gap: 14px;
    }

    .forgot-button .divider {
        height: 1px;
        background: #0a2f4d;
        flex: 1;
    }

    .forgot-button .divider:last-child {
        max-width: 24px;
    }

    .forgot-button .button-action {
        font-size: 16px;
        font-weight: 500;
        height: 34px;
        color: white;
        padding: 5px 14px;
        position: relative;
    }

    .forgot-button span {
        position: relative;
        z-index: 1;
    }

    .forgot-button .button-action::before,
    .forgot-button .button-action::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .forgot-button .button-action::before {
        background: linear-gradient(0deg, #00ffc2, transparent);
        clip-path: var(--octagonal-before);
        font-size: 5px;
    }

    .forgot-button .button-action::after {
        background: linear-gradient(45deg, #37a970, #50e9b0);
        clip-path: var(--octagonal-after);
        font-size: 5px;
    }
</style>
