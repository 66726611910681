<template>
    <li
        v-bind:class="[$style['roll-card'], $style[rollGetOutcome], { [$style['active']]: active }]"
    >
        <div :class="$style['card-container']">
            <div :class="$style['container-content']">
                <div :class="$style['content-multiplier']">
                    <span :class="{ 'fade-gold': rollGetOutcome === 'ultra' }">
                        {{
                            parseFloat(multiplier)
                                .toFixed(2)
                                .replace('.00', '')
                                .replace(/(?<=[.]\d)[0]/, '')
                        }}x
                    </span>
                </div>
                <div :class="$style['content-thumbnail']">
                    <img
                        v-if="rollGetOutcome !== 'blank' && rollGetItem !== undefined"
                        :src="rollGetItem.image"
                        :alt="rollGetItem.name"
                        width="100%"
                    />
                    <Halo v-if="rollGetOutcome !== 'blank'" v-bind:active="active" />
                    <svg
                        v-else
                        width="6.6em"
                        viewBox="0 0 66 66"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M33 65.5C50.9493 65.5 65.5 50.9493 65.5 33C65.5 15.0507 50.9493 0.5 33 0.5C15.0507 0.5 0.5 15.0507 0.5 33C0.5 50.9493 15.0507 65.5 33 65.5Z"
                            fill="#092D4B"
                            stroke="#0E385B"
                        />
                        <path
                            d="M53.2241 8.20447C58.2355 14.3468 60.7884 22.1307 60.3881 30.0479C59.9878 37.9651 56.6627 45.4518 51.0572 51.0572C45.4518 56.6627 37.9651 59.9878 30.0479 60.3881C22.1307 60.7884 14.3468 58.2355 8.20447 53.2241C11.0325 56.6921 14.5565 59.5283 18.5489 61.5496C22.5412 63.5709 26.9132 64.7323 31.3823 64.9589C35.8515 65.1854 40.3185 64.472 44.4947 62.8648C48.671 61.2576 52.4638 58.7922 55.628 55.628C58.7922 52.4638 61.2576 48.671 62.8648 44.4947C64.472 40.3185 65.1854 35.8515 64.9589 31.3823C64.7323 26.9132 63.5709 22.5412 61.5496 18.5489C59.5283 14.5565 56.6921 11.0325 53.2241 8.20447Z"
                            fill="#072742"
                        />
                        <path
                            d="M47.8572 33C50.3819 33 52.4286 30.9533 52.4286 28.4285C52.4286 25.9038 50.3819 23.8571 47.8572 23.8571C45.3325 23.8571 43.2858 25.9038 43.2858 28.4285C43.2858 30.9533 45.3325 33 47.8572 33Z"
                            fill="#052037"
                        />
                        <path
                            d="M18.1428 33C20.6676 33 22.7143 30.9533 22.7143 28.4285C22.7143 25.9038 20.6676 23.8571 18.1428 23.8571C15.6181 23.8571 13.5714 25.9038 13.5714 28.4285C13.5714 30.9533 15.6181 33 18.1428 33Z"
                            fill="#052037"
                        />
                        <path
                            d="M42.4302 47.4721C40.7928 45.6228 37.0913 44.4286 32.9999 44.4286C28.9085 44.4286 25.207 45.6228 23.5696 47.4721L21.8588 45.9565C23.9419 43.6038 28.2113 42.1429 32.9999 42.1429C37.7885 42.1429 42.058 43.6038 44.1411 45.9565L42.4302 47.4721Z"
                            fill="#052037"
                        />
                        <path
                            d="M46.2098 53.4543C44.9825 52.8076 43.9551 51.838 43.2383 50.6503C42.5216 49.4625 42.1428 48.1015 42.1428 46.7143C42.1428 45.327 42.5216 43.9661 43.2383 42.7783C43.9551 41.5905 44.9825 40.621 46.2098 39.9743L47.2187 42.0257C46.3747 42.4837 45.67 43.1611 45.1789 43.9863C44.6878 44.8115 44.4286 45.754 44.4286 46.7143C44.4286 47.6746 44.6878 48.617 45.1789 49.4423C45.67 50.2675 46.3747 50.9448 47.2187 51.4028L46.2098 53.4543Z"
                            fill="#052037"
                        />
                        <path
                            d="M19.7902 53.4543L18.7812 51.4028C19.6253 50.9448 20.33 50.2675 20.8211 49.4423C21.3122 48.617 21.5714 47.6746 21.5714 46.7143C21.5714 45.754 21.3122 44.8115 20.8211 43.9863C20.33 43.1611 19.6253 42.4837 18.7812 42.0257L19.7902 39.9743C21.0175 40.621 22.0449 41.5905 22.7617 42.7783C23.4784 43.9661 23.8572 45.327 23.8572 46.7143C23.8572 48.1015 23.4784 49.4625 22.7617 50.6503C22.0449 51.838 21.0175 52.8076 19.7902 53.4543Z"
                            fill="#052037"
                        />
                    </svg>
                </div>
                <div :class="$style['content-potential']">
                    <div
                        :class="[
                            $style['potential-amount'],
                            { [$style['multi']]: Object.keys(rollGetPotentials).length > 1 }
                        ]"
                    >
                        <div
                            v-for="(coinType, index) in Object.keys(rollGetPotentials)"
                            v-if="Object.keys(rollGetPotentials).length > 0"
                            v-bind:key="index"
                            :class="$style['amount-value']"
                        >
                            <img
                                v-bind:src="
                                    require(`@/assets/img/icons/${coinType || 'cryptoCoin'}.svg`)
                                "
                                alt="icon"
                            />
                            <p>
                                {{
                                    rollGetPotentials[coinType]
                                        .toFixed(2)
                                        .replace('.00', '')
                                        .replace(/(?<=[.]\d)[0]/, '')
                                }}
                            </p>
                        </div>
                        <div
                            v-if="Object.keys(rollGetPotentials).length < 1"
                            :class="$style['amount-value']"
                        >
                            <img
                                v-bind:src="
                                    require(
                                        `@/assets/img/icons/${userBalanceData.type || 'cryptoCoin'}.svg`
                                    )
                                "
                                alt="icon"
                            />
                            <p>0</p>
                        </div>
                    </div>
                </div>
                <div :class="$style['content-webs']">
                    <svg
                        v-if="rollGetOutcome !== 'blank'"
                        viewBox="0 0 262 262"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M96.7584 8.81912H117.644H125.129H143.238H154.103H158.933H164.124H200.503L214.036 21.0646V38.2361L214.036 38.2372L214.036 77.3543L214.036 127.755L214.036 127.758L214.036 143.43L214.036 143.434L214.582 181.918L214.582 181.921L215.153 238.685L200.496 253.181H164.124H158.933H154.103H143.238H125.129H117.644H96.7584H61.707L46.846 239.806V181.344V143.43V127.755V77.3543V38.2361V21.0723L61.7002 8.81912H96.7584Z"
                            stroke="currentColor"
                            stroke-opacity="0.5"
                            stroke-width="0.555961"
                        />
                        <path
                            d="M130.071 12.2671L148.705 17.2601L155.383 19.0495L171.54 23.3786L181.234 25.9761L185.542 27.1306L190.174 28.3716L222.623 37.0663L231.751 51.2478L227.312 67.8138L227.311 67.8148L217.93 102.828L205.842 147.941L205.841 147.943L202.082 161.971L202.081 161.975L193.689 195.165L179.913 248.696L163.374 258.159L130.932 249.466L126.3 248.225L121.992 247.07L112.298 244.473L96.141 240.143L89.463 238.354L70.8289 233.361L39.5645 224.984L29.5229 209.474L43.8647 155.949L52.9579 122.013L56.7172 107.983L68.8052 62.87L78.1872 27.856L82.6241 11.2974L98.7999 3.88808L130.071 12.2671Z"
                            stroke="currentColor"
                            stroke-opacity="0.5"
                            stroke-width="0.555961"
                        />
                        <path
                            d="M70.8289 28.6389L89.463 23.6459L96.141 21.8566L112.298 17.5274L121.992 14.9299L126.3 13.7755L130.932 12.5344L163.381 3.83969L178.376 11.5573L182.815 28.1233L182.816 28.1243L192.197 63.1373L204.285 108.25L204.286 108.253L208.045 122.28L208.046 122.284L217.372 155.224L232.208 208.472L222.616 224.936L190.174 233.628L185.542 234.869L181.234 236.024L171.54 238.621L155.383 242.951L148.705 244.74L130.071 249.733L98.8065 258.11L82.355 249.699L68.0133 196.175L58.9201 162.238L55.1607 148.208L43.0727 103.095L33.6908 68.0811L29.2539 51.5225L39.5579 37.018L70.8289 28.6389Z"
                            stroke="currentColor"
                            stroke-opacity="0.5"
                            stroke-width="0.555961"
                        />
                        <path
                            d="M8.81912 165.242L8.81911 144.356L8.81911 136.871L8.81911 118.762L8.81911 107.897L8.81911 103.068L8.81911 97.8764L8.81911 61.4971L21.0646 47.9641L38.2361 47.9641L38.2372 47.9641L77.3543 47.9641L127.755 47.9641L127.758 47.9641L143.43 47.9641L143.434 47.964L181.918 47.4186L181.921 47.4186L238.685 46.8469L253.181 61.5042L253.181 97.8764L253.181 103.068L253.181 107.897L253.181 118.762L253.181 136.871L253.181 144.356L253.181 165.242L253.181 200.293L239.806 215.154L181.344 215.154L143.43 215.154L127.755 215.154L77.3543 215.154L38.2361 215.154L21.0723 215.154L8.81912 200.3L8.81912 165.242Z"
                            stroke="currentColor"
                            stroke-opacity="0.31"
                            stroke-width="0.555961"
                        />
                        <path
                            d="M12.2671 131.929L17.2601 113.295L19.0495 106.617L23.3786 90.4605L25.9761 80.7665L27.1306 76.4581L28.3716 71.8265L37.0663 39.3773L51.2478 30.2495L67.8138 34.6884L67.8148 34.6887L102.828 44.0704L147.941 56.1584L147.943 56.159L161.971 59.9177L161.975 59.9187L195.165 68.3113L248.696 82.0875L258.159 98.6261L249.466 131.068L248.225 135.7L247.07 140.008L244.473 149.702L240.143 165.859L238.354 172.537L233.361 191.171L224.984 222.436L209.474 232.477L155.949 218.135L122.013 209.042L107.983 205.283L62.87 193.195L27.856 183.813L11.2974 179.376L3.88808 163.2L12.2671 131.929Z"
                            stroke="currentColor"
                            stroke-opacity="0.31"
                            stroke-width="0.555961"
                        />
                        <path
                            d="M28.6389 191.171L23.6459 172.537L21.8566 165.859L17.5274 149.703L14.9299 140.009L13.7755 135.7L12.5344 131.069L3.83968 98.6193L11.5573 83.6237L28.1233 79.1849L28.1243 79.1846L63.1373 69.8029L108.25 57.715L108.253 57.7142L122.28 53.9556L122.284 53.9545L155.224 44.6278L208.471 29.7925L224.936 39.3842L233.628 71.8265L234.869 76.4581L236.024 80.7666L238.621 90.4606L242.951 106.617L244.74 113.295L249.733 131.929L258.11 163.194L249.699 179.645L196.175 193.987L162.238 203.08L148.208 206.839L103.095 218.927L68.0811 228.309L51.5225 232.746L37.018 222.442L28.6389 191.171Z"
                            stroke="currentColor"
                            stroke-opacity="0.31"
                            stroke-width="0.555961"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Halo from '@/components/Halo';

    export default {
        name: 'RollCard',
        props: ['multiplier', 'active'],
        components: {
            Halo
        },
        data() {
            return {
                rollItems: {
                    1: [
                        {
                            name: 'Snow Leopard Fedora',
                            image: 'https://tr.rbxcdn.com/9e94543b15cd954eb0d917795754e1fd/420/420/Hat/Png'
                        },
                        {
                            name: 'Outrageous Builders Club Hard Hat',
                            image: 'https://tr.rbxcdn.com/b6bda745d351b5a54e6f756011a213c4/420/420/Hat/Png'
                        },
                        {
                            name: 'Happy Time Magic Flower Bowler',
                            image: 'https://tr.rbxcdn.com/0bf4ffa21de7df581b47763f489e289f/420/420/Hat/Png'
                        },
                        {
                            name: 'Valentine`s Day 2012 Cap',
                            image: 'https://tr.rbxcdn.com/90b2a00ea7e876a77d33f56536edc824/420/420/Hat/Png'
                        },
                        {
                            name: 'Purple Steampunk Robin Hood',
                            image: 'https://tr.rbxcdn.com/7888e6c24b2d22e62a0bf5ac63a70699/420/420/Hat/Png'
                        },
                        {
                            name: 'BreezeKreig Adventurer',
                            image: 'https://tr.rbxcdn.com/8fb3ea6745a47e0a380067c131fa0d00/420/420/Hat/Png'
                        },
                        {
                            name: 'Scare Mayor Top Hat',
                            image: 'https://tr.rbxcdn.com/0d53dad0629c58b66e9179d2da3e9256/420/420/Hat/Png'
                        },
                        {
                            name: 'Cool Snowman Head',
                            image: 'https://tr.rbxcdn.com/f3b4af97e3911db2dfe28421c3595766/420/420/Hat/Png'
                        },
                        {
                            name: 'Bluesteel Viking Helm of Infinite Pillage',
                            image: 'https://tr.rbxcdn.com/ff2133ea838bac343ddfa5862496c444/420/420/Hat/Png'
                        }
                    ],
                    2: [
                        {
                            name: 'Rubber Duckie',
                            image: 'https://tr.rbxcdn.com/113fa7eb924049ff5f88d02fce1d4f8a/420/420/Hat/Png'
                        },
                        {
                            name: 'Black Iron Commando',
                            image: 'https://tr.rbxcdn.com/4b6fc07650d13e9efee5c49804142254/420/420/Hat/Png'
                        },
                        {
                            name: 'Silverthorn Antlers',
                            image: 'https://tr.rbxcdn.com/8441eb33c48eda88de3de42285660847/420/420/Hat/Png'
                        },
                        {
                            name: 'Skull and Crossbones Fedora',
                            image: 'https://tr.rbxcdn.com/e681aded6c842f62d1419dafcb29fda2/420/420/Hat/Png'
                        },
                        {
                            name: 'I Heart Mom Sign',
                            image: 'https://tr.rbxcdn.com/bf93f9cccd93c463e2eb61a6d3a699bb/420/420/Hat/Png'
                        },
                        {
                            name: 'Brighteyes` Halloween Leftovers',
                            image: 'https://tr.rbxcdn.com/7b6154a8a009a34f3cc46e36d5294f61/420/420/Hat/Png'
                        },
                        {
                            name: 'Blue Top Hat with White Band',
                            image: 'https://tr.rbxcdn.com/de1729e6d30713d92988650ad66b3039/420/420/Hat/Png'
                        },
                        {
                            name: '2010 New Year`s Top Hat',
                            image: 'https://tr.rbxcdn.com/7524ef6c7b9072119c71d7749d6cdf63/420/420/Hat/Png'
                        },
                        {
                            name: 'Rainbow Hatbot',
                            image: 'https://tr.rbxcdn.com/55b0f2cea4e25d2b7d633fc6d5e2125e/420/420/Hat/Png'
                        },
                        {
                            name: 'Steampunk Sweetheart Top Hat',
                            image: 'https://tr.rbxcdn.com/898b6660946bddd67e4d8f2c4d659188/420/420/Hat/Png'
                        }
                    ],
                    3: [
                        {
                            name: 'Virtual Commando',
                            image: 'https://tr.rbxcdn.com/fbc8970009609b5768576135f2b859bf/420/420/Hat/Png'
                        },
                        {
                            name: 'Bucket',
                            image: 'https://tr.rbxcdn.com/df0c5908713fa2274945d50cb2f9fefd/420/420/Hat/Png'
                        },
                        {
                            name: 'Viridian Hood',
                            image: 'https://tr.rbxcdn.com/353dceed9989f3eab007d5c0991e192e/420/420/Hat/Png'
                        },
                        {
                            name: 'Green Banded Top Hat',
                            image: 'https://tr.rbxcdn.com/f92fc4fa0961cf363b61e8ebe2ecddce/420/420/Hat/Png'
                        },
                        {
                            name: 'Sinister Q.',
                            image: 'https://tr.rbxcdn.com/c4c95d8cbedbefff38a1bab5291bbbb0/420/420/Hat/Png'
                        },
                        {
                            name: 'Perfectly Legitimate Business Hat',
                            image: 'https://tr.rbxcdn.com/e4d7679a2b1b98e013f40c21120a61b4/420/420/Hat/Png'
                        },
                        {
                            name: 'Laser Blue Riding Hood',
                            image: 'https://tr.rbxcdn.com/5633742fae106826f706860dfda8c81d/420/420/Hat/Png'
                        },
                        {
                            name: 'Tasteless Top Hat',
                            image: 'https://tr.rbxcdn.com/fbec099ac30f171629dfbf6a2afb1be6/420/420/Hat/Png'
                        },
                        {
                            name: 'Bubble Trouble',
                            image: 'https://tr.rbxcdn.com/4ebed570d8a8c4c78cae980cac07b252/420/420/Face/Png'
                        },
                        {
                            name: 'Masked Hood of the Lightning Striker',
                            image: 'https://tr.rbxcdn.com/6b519d9663a77b03a7d84005cc08dd74/420/420/Hat/Png'
                        }
                    ],
                    4: [
                        {
                            name: 'Viridian Domino Crown',
                            image: 'https://tr.rbxcdn.com/63d317cdd54d45d48790fd144449d904/420/420/Hat/Png'
                        },
                        {
                            name: 'Blizzaria: The Frozen',
                            image: 'https://tr.rbxcdn.com/8764abd4f744e3b120b94d612527ba31/420/420/Hat/Png'
                        },
                        {
                            name: 'Dark Conjurer',
                            image: 'https://tr.rbxcdn.com/860a6ec8608adb7afabc8761c8a6a2d3/420/420/Hat/Png'
                        },
                        {
                            name: 'Dominus Formidulosus',
                            image: 'https://tr.rbxcdn.com/e290a5aea0c26e51d67291bb8d631482/420/420/Hat/Png'
                        },
                        {
                            name: 'JJ5x5`s White Top Hat',
                            image: 'https://tr.rbxcdn.com/da1dfb4a13ccd754e92b6a85fe092180/420/420/Hat/Png'
                        },
                        {
                            name: 'The Agonizingly Ugly Bucket of Doom',
                            image: 'https://tr.rbxcdn.com/bd61dd4010911e300822b273328c9f8f/420/420/Hat/Png'
                        },
                        {
                            name: 'Silver King of the Night',
                            image: 'https://tr.rbxcdn.com/44c810d67479b34e7a5740dd4b7e432f/420/420/Hat/Png'
                        },
                        {
                            name: 'Al Capwn',
                            image: 'https://tr.rbxcdn.com/0c880734978be582f56a4dfe51c83275/420/420/Hat/Png'
                        },
                        {
                            name: 'Valkyrie Helm',
                            image: 'https://tr.rbxcdn.com/fd070f5e848041d725135a3a46eb3c3c/420/420/Hat/Png'
                        },
                        {
                            name: 'The Classic ROBLOX Fedora',
                            image: 'https://tr.rbxcdn.com/c1b55f2c2d4b72bdf428caf2165ed351/420/420/Hat/Png'
                        }
                    ],
                    5: [
                        {
                            name: 'Sparkle Time Fedora',
                            image: 'https://tr.rbxcdn.com/d0e9bb77748368e93c0076284b52f3d5/420/420/Hat/Png'
                        },
                        {
                            name: 'Dominus Vespertilio',
                            image: 'https://tr.rbxcdn.com/c4181c9c5cd7a380efe445cf11aa4f57/420/420/Hat/Png'
                        },
                        {
                            name: 'White Sparkle Time Fedora',
                            image: 'https://tr.rbxcdn.com/17bffc4f71b817c5b624d1a4e9c2b153/420/420/Hat/Png'
                        },
                        {
                            name: 'Red Domino Crown',
                            image: 'https://tr.rbxcdn.com/c927546d657cb9e4615ae82b305209b0/420/420/Hat/Png'
                        },
                        {
                            name: 'Lord of the Tixeration',
                            image: 'https://tr.rbxcdn.com/a874417143da06e4ab46e76e24749033/420/420/Hat/Png'
                        },
                        {
                            name: 'Dominus Messor',
                            image: 'https://tr.rbxcdn.com/7494aeb448e9d1d22112aa4b1f1b9dfd/420/420/Hat/Png'
                        },
                        {
                            name: 'Teal Sparkle Time Fedora',
                            image: 'https://tr.rbxcdn.com/255dbb30a3810e863f1964c89175fd33/420/420/Hat/Png'
                        },
                        {
                            name: 'Dominus Aureus',
                            image: 'https://tr.rbxcdn.com/4862212c0743129f5979d19dd229721f/420/420/Hat/Png'
                        },
                        {
                            name: 'Domino Crown',
                            image: 'https://tr.rbxcdn.com/9265fc31ee48ff2f82ab9844f7db897a/420/420/Hat/Png'
                        },
                        {
                            name: 'Dominus Frigidus',
                            image: 'https://tr.rbxcdn.com/d441ecc55b0ac0b606d6f8ae70945f73/420/420/Hat/Png'
                        }
                    ]
                }
            };
        },
        methods: {
            rollFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString();
            }
        },
        computed: {
            ...mapGetters(['authUser', 'rollGame', 'rollBets', 'userBalanceData']),
            rollGetItem() {
                let item = this.rollItems[5][9];

                if (this.multiplier <= 2.5) {
                    item = this.rollItems[1][Math.round((this.multiplier - 1) / (1.5 / 9))];
                } else if (this.multiplier <= 5) {
                    item = this.rollItems[2][Math.round((this.multiplier - 2.5) / (2.5 / 9))];
                } else if (this.multiplier <= 50) {
                    item = this.rollItems[3][Math.round((this.multiplier - 5) / (45 / 9))];
                } else if (this.multiplier <= 100) {
                    item = this.rollItems[4][Math.round((this.multiplier - 50) / (50 / 9))];
                } else if (this.multiplier <= 1000) {
                    item = this.rollItems[5][Math.round((this.multiplier - 100) / (900 / 9))];
                }

                return item;
            },
            rollGetPotentials() {
                let potentials = {};

                if (this.authUser.user !== null) {
                    for (const coinType of ['cryptoCoin', 'robuxCoin']) {
                        for (const bet of this.rollBets.filter(
                            (element) =>
                                element.user._id === this.authUser.user._id &&
                                element.coinType === coinType
                        )) {
                            if (this.multiplier >= bet.multiplier / 100) {
                                potentials[coinType] =
                                    (potentials[coinType] || 0) +
                                    Math.floor(bet.amount * (bet.multiplier / 100)) / 1000;
                            }
                        }
                    }
                }

                return potentials;
            },
            rollGetMinBetMultipliers() {
                let multipliers = {};

                if (this.authUser.user !== null) {
                    for (const coinType of ['cryptoCoin', 'robuxCoin']) {
                        for (const bet of this.rollBets.filter(
                            (element) =>
                                element.user._id === this.authUser.user._id &&
                                element.coinType === coinType
                        )) {
                            if (
                                multipliers[coinType] === undefined ||
                                bet.multiplier < multipliers[coinType]
                            ) {
                                multipliers[coinType] = bet.multiplier;
                            }
                        }
                    }
                }

                if ('cryptoCoin' in multipliers) multipliers.cryptoCoin /= 100;
                if ('robuxCoin' in multipliers) multipliers.robuxCoin /= 100;

                return multipliers;
            },
            rollGetOutcome() {
                if (
                    Object.keys(this.rollGetMinBetMultipliers).length === 1 &&
                    this.multiplier < Object.values(this.rollGetMinBetMultipliers)[0]
                )
                    return 'blank';
                if (
                    Object.keys(this.rollGetMinBetMultipliers).length === 2 &&
                    this.multiplier < this.rollGetMinBetMultipliers.robuxCoin &&
                    this.multiplier < this.rollGetMinBetMultipliers.cryptoCoin
                )
                    return 'blank';
                if (this.multiplier >= 50) return 'ultra';
                if (this.multiplier >= 20) return 'high';
                if (this.multiplier >= 5) return 'mid';
                if (this.multiplier >= 2) return 'low';
                return 'null';
            }
        }
    };
</script>

<style module>
    .roll-card {
        width: 16.8em;
        font-size: 10px;
        flex-shrink: 0;
        color: #668fb8;
        margin-right: 0.4em;
        transform: scale(1);
        transition: all 0.2s ease;
    }

    .roll-card.active {
        filter: drop-shadow(0em 0em 2em color-mix(in srgb, currentColor 10%, #00000088));
        transform: scale(1.15);
        z-index: 1;
    }

    .roll-card:not(.blank).active {
        filter: drop-shadow(0em 0em 2em color-mix(in srgb, currentColor 40%, #00000088));
    }

    .roll-card .card-container {
        position: relative;
        padding: 0.4em;
    }

    .roll-card .card-container::after {
        font-size: 1.8em;
        clip-path: var(--octagonal-main) !important;
        background: #0d243a;
    }

    .roll-card .container-content {
        aspect-ratio: 160 / 221;
        width: 100%;
        padding: 0.778em 1em;
        font-size: 1.6em;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #0b2035;
        position: relative;
        clip-path: var(--octagonal-main);
    }

    .roll-card .container-content > * {
        font-size: 0.625em;
    }

    .roll-card .container-content::before {
        font-size: 1em;
        background: #182d44;
    }

    .roll-card:not(.blank):not(.null) .container-content::before {
        background: currentColor;
        opacity: 0.4;
    }

    .roll-card .content-multiplier {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        position: relative;
        font-weight: 600;
        padding: 0 1.2em;
        height: 2.5em;
        z-index: 1;
    }

    .roll-card .potential-amount::before,
    .roll-card .content-multiplier::before {
        font-size: 0.3em;
        opacity: 0.2;
        background: currentColor;
    }

    .roll-card:not(.blank):not(.null) .potential-amount::before,
    .roll-card:not(.blank):not(.null) .content-multiplier::before {
        opacity: 0.4;
    }

    .roll-card .potential-amount::after,
    .roll-card .content-multiplier::after {
        font-size: 0.3em;
        backdrop-filter: blur(2em);
        background: linear-gradient(0deg, #1e4c7744, #1e4c7744);
    }

    .roll-card .content-thumbnail {
        position: relative;
        width: 100%;
        flex: 1;
    }

    .roll-card .content-thumbnail > svg {
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
    }

    .roll-card .content-thumbnail img {
        transform: translate(-50%, 0%);
        object-fit: contain;
        position: absolute;
        aspect-ratio: 1;
        left: 50%;
        top: 1em;
        transition: all 0.2s ease;
        width: 80%;
        z-index: 3;
    }

    .roll-card.active .content-thumbnail img {
        animation: roll-card-thumbnail-float-img 1s ease alternate infinite 0.6s;
        width: 100%;
    }

    @keyframes roll-card-thumbnail-float-img {
        0% {
            transform: translate(-50%, 0%);
        }
        100% {
            transform: translate(-50%, 8%);
        }
    }

    .roll-card .content-potential {
        position: relative;
        height: 2.4em;
        width: 100%;
        z-index: 1;
    }

    .roll-card .potential-amount {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0.25em 0rem;
        width: 100%;
        bottom: 0;
    }

    .roll-card .amount-value img {
        width: 0.8em;
        margin-bottom: -0.1em;
    }

    .roll-card .amount-value {
        font-size: 1.3em;
        display: flex;
        align-items: center;
        gap: 0.5em;
        color: white;
        font-weight: 600;
    }

    .roll-card .potential-amount.multi .amount-value {
        font-size: 0.9em;
    }

    .roll-card .content-webs {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        mask-image: linear-gradient(0deg, #00000088, #00000044);
    }

    .roll-card .content-webs svg {
        position: absolute;
        height: 112%;
        left: 50%;
        bottom: -3%;
        transform: translate(-50%, 0%);
        z-index: -2;
        transition: transform 7s ease;
    }

    .roll-card.active .content-webs svg {
        transform: translate(-50%, -10%) rotate(45deg) scale(1.2);
    }

    .roll-card.low {
        color: #44cb93;
    }
    .roll-card.low .container-content {
        background: linear-gradient(0deg, #0a272c, #071a25);
    }
    .roll-card.low .content-multibplier::after,
    .roll-card.low .potential-amount::after {
        background: #082227;
    }
    .roll-card.low .card-container::after {
        background: #0c2937;
    }

    .roll-card.mid {
        color: #784bd8;
    }
    .roll-card.mid .container-content {
        background: linear-gradient(0deg, #121333, #0c152b);
    }
    .roll-card.mid .content-multiplier::after,
    .roll-card.mid .potential-amount::after {
        background: #0a0327;
    }
    .roll-card.mid .card-container::after {
        background: #0e152f;
    }

    .roll-card.high {
        color: #ea4934;
    }
    .roll-card.high .container-content {
        background: linear-gradient(0deg, #26131d, #111621);
    }
    .roll-card.high .content-multiplier::after,
    .roll-card.high .potential-amount::after {
        background: #130c12;
    }
    .roll-card.high .card-container::after {
        background: #251726;
    }

    .roll-card.ultra {
        color: #ebc93b;
    }
    .roll-card.ultra .container-content {
        background: linear-gradient(0deg, #28281f, #131c21);
    }
    .roll-card.ultra .content-multiplier::after,
    .roll-card.ultra .potential-amount::after {
        background: #423827;
    }
    .roll-card.ultra .card-container::after {
        background: #262018;
    }

    .roll-card:last-of-type {
        margin-right: 0em;
    }

    .roll-card:not(.blank) .potential-amount::before,
    .roll-card .content-multiplier::before,
    .roll-card .container-content::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-before);
    }

    .roll-card .potential-amount::after,
    .roll-card .content-multiplier::after,
    .roll-card .card-container::after,
    .roll-card .container-content::after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        clip-path: var(--octagonal-after);
    }
</style>
