/**
 *
 * Frontend - backend should share this file
 *
 */

const xorCipher = (text, key = 'diwatapares') => {
    const CHARSET = '_-ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charsetLength = CHARSET.length;

    // Ensure text is a string, stringify if it's an object (e.g., JSON)
    const input = typeof text === 'string' ? text : JSON.stringify(text);

    const cipheredText = Array.from(input, (char, index) => {
        const textCharCode = CHARSET.indexOf(char);
        if (textCharCode === -1) {
            // If char is not in CHARSET, leave it unchanged
            return char;
        }
        const keyCharCode = CHARSET.indexOf(key[index % key.length]);
        const cipheredCharCode = (textCharCode ^ keyCharCode) % charsetLength;
        return CHARSET[cipheredCharCode < 0 ? cipheredCharCode + charsetLength : cipheredCharCode];
    }).join('');

    return cipheredText;
};

export const cipher = (data = '') => {
    // Encrypt the message
    const encrypted = xorCipher(
        typeof data === 'object' ? JSON.stringify(data) : data?.toString() || ''
    );

    // Ensure encrypted message is the same length as plaintext
    return encrypted;
};

export const decipher = (data = '') => {
    const decrypted = xorCipher(data);
    try {
        return JSON.parse(decrypted);
    } catch (error) {
        return decrypted;
    }
};
