<template>
    <div :class="$style['roll-user-bets']">
        <RollUserBetsElement v-for="bet of rollGetUserBets" v-bind:key="bet._id" v-bind:bet="bet" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import RollUserBetsElement from '@/components/roll/RollUserBetsElement';

    export default {
        name: 'RollUserBets',
        components: {
            RollUserBetsElement
        },
        computed: {
            ...mapGetters(['authUser', 'rollBets']),
            rollGetUserBets() {
                let bets = [];

                for (let bet of this.authUser.user !== null
                    ? this.rollBets.filter((element) => element.user._id === this.authUser.user._id)
                    : []) {
                    const index = bets.findIndex(
                        (element) =>
                            element.baseAmount === bet.amount &&
                            element.multiplier === bet.multiplier &&
                            element.coinType === bet.coinType
                    );

                    if (index !== -1) {
                        bets[index].amount = bets[index].amount + bet.amount;
                    } else {
                        bet = { ...bet, baseAmount: bet.amount };
                        bets.push(bet);
                    }
                }

                bets.sort(function (a, b) {
                    return +b.amount - +a.amount;
                });

                return bets;
            }
        }
    };
</script>

<style module>
    .roll-user-bets {
        width: 100%;
        min-height: 116px;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 6px;
        margin-top: 25px;
    }

    @media screen and (max-width: 1650px) {
        .roll-user-bets {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 1500px) {
        .roll-user-bets {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 1350px) {
        .roll-user-bets {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .roll-user-bets-element {
            font-size: 9px !important;
        }
    }

    @media screen and (max-width: 1200px) {
        .roll-user-bets {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .roll-user-bets-element {
            font-size: 10px !important;
        }
    }

    @media screen and (max-width: 1050px) {
        .roll-user-bets {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .roll-user-bets-element {
            font-size: 9px !important;
        }
    }

    @media screen and (max-width: 1000px) {
        .roll-user-bets {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .roll-user-bets-element {
            font-size: 9px !important;
        }
    }

    @media screen and (max-width: 950px) {
        .roll-user-bets {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .roll-user-bets-element {
            font-size: 10px !important;
        }
    }

    @media screen and (max-width: 800px) {
        .roll-user-bets {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .roll-user-bets-element {
            font-size: 9px !important;
        }
    }

    @media screen and (max-width: 750px) {
        .roll-user-bets {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .roll-user-bets-element {
            font-size: 10px !important;
        }
    }

    @media screen and (max-width: 600px) {
        .roll-user-bets-element {
            font-size: 9px !important;
        }
    }

    @media screen and (max-width: 550px) {
        .roll-user-bets-element {
            font-size: 8px !important;
        }
    }

    @media screen and (max-width: 475px) {
        .roll-user-bets-element {
            font-size: 7px !important;
        }
    }

    @media screen and (max-width: 450px) {
        .roll-user-bets-element {
            font-size: 6px !important;
        }
    }
</style>
