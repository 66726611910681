<template>
    <div :class="$style.fairness">
        <p :class="$style['fairness-title']">
            <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.00002 9.83007C7.00002 9.55393 6.77616 9.33007 6.50002 9.33007H6.32539L4.61299 5.4646C4.28429 5.55103 3.95316 5.62677 3.62012 5.69227L5.23169 9.33007H1.76839L3.35812 5.74153C2.97112 5.811 2.58179 5.86657 2.19079 5.90767L0.674656 9.33007H0.500022C0.223889 9.33007 2.23313e-05 9.55393 2.23313e-05 9.83007C-0.00737767 11.5484 1.82569 12.7018 3.50002 12.6634C5.17402 12.7022 7.00792 11.548 7.00002 9.83007Z"
                    fill="#21B894"
                />
                <path
                    d="M16.4995 9.33007H16.3249L14.8088 5.90767C14.4178 5.86657 14.0284 5.811 13.6414 5.74153L15.2312 9.33007H11.7679L13.3794 5.69227C13.0464 5.6268 12.7152 5.55103 12.3866 5.4646L10.6741 9.33007H10.4995C10.2234 9.33007 9.99951 9.55393 9.99951 9.83007C9.99951 10.611 10.3801 11.3371 11.0712 11.8747C12.3601 12.919 14.6389 12.919 15.9278 11.8746C16.6189 11.3371 16.9995 10.611 16.9995 9.83007C16.9995 9.55393 16.7757 9.33007 16.4995 9.33007Z"
                    fill="#21B894"
                />
                <path
                    d="M0.500455 4.99674C1.87266 4.99674 3.23885 4.8105 4.56112 4.44327C7.13799 3.72744 9.86209 3.72744 12.439 4.44327C13.7612 4.81054 15.1274 4.99674 16.4996 4.99674C17.0667 4.99674 17.1957 4.2018 16.6577 4.02244H16.6576C15.3599 3.58984 14.0955 3.03367 12.8997 2.3693L12.1097 1.93047C11.1542 1.39964 10.0883 1.08607 8.99999 1.01224V0.496838C8.97539 -0.165863 8.02436 -0.165362 7.99999 0.496838V1.01224C6.91169 1.08607 5.84576 1.39964 4.89026 1.93047L4.10026 2.3693C2.90445 3.03367 1.64012 3.58984 0.342321 4.02244H0.342288C-0.195712 4.2018 -0.0667119 4.99674 0.500455 4.99674Z"
                    fill="#21B894"
                />
                <path
                    d="M10.5 15.0636H6.5C5.67157 15.0636 5 15.7352 5 16.5636C5 16.8397 5.22387 17.0636 5.5 17.0636H11.5C11.7761 17.0636 12 16.8397 12 16.5636C12 15.7352 11.3284 15.0636 10.5 15.0636Z"
                    fill="#21B894"
                />
                <path
                    d="M8 14.0635H9V4.91573C8.66703 4.90333 8.33297 4.90333 8 4.91573V14.0635Z"
                    fill="#21B894"
                />
            </svg>
            Fairness Policy
        </p>
        <div :class="$style['fairness-content']">
            <div :class="$style['content-article']">
                <h1>Fairness Policy</h1>
                <br />
                <br />
                <h4>
                    Our platform ensures that every game outcome is predetermined and fully
                    verifiable, even before you place your bet. To maintain transparency and
                    fairness, we employ a robust system where we provide you with a hashed version
                    of the game result prior to the start of each game. This hash is derived from
                    the combination of the server seed and the public seed, which together determine
                    the final result of the game.
                    <br />
                    After the game concludes, we disclose the server seed, enabling you to
                    independently verify the hash provided at the outset. By comparing the disclosed
                    server seed with the pre-game hash, you can confirm that the results were
                    generated fairly and without manipulation. This approach guarantees that all
                    game outcomes are predetermined, fair, and transparent, ensuring you can
                    participate with complete confidence in the integrity of our system.
                </h4>
                <br />
                <div :class="$style['article-options']">
                    <button
                        v-for="(item, index) in Object.keys(fairnessContent)"
                        class="octagonal before after hoverable"
                        :class="{ [$style.expand]: item === (expand || 'battles') }"
                        v-on:click="expandAction(item)"
                        v-bind:key="index"
                    >
                        <span :class="{ 'fade-green': item === (expand || 'battles') }">{{
                            fairnessContent[item].title
                        }}</span>
                    </button>
                </div>
                <br />
                <h3>
                    Our system ensures fairness and transparency through a meticulous process
                    involving multiple cryptographic elements. Each game result is determined using
                    a SHA-256 hash derived from a combination of several inputs:
                </h3>
                <br />
                <ul>
                    <li
                        v-for="(item, index) in fairnessContent[expand || 'battles'].inputs"
                        v-if="item in getInputs"
                    >
                        {{ getInputs[item].split(': ')[0] }}:
                        <span>{{ getInputs[item].split(': ')[1] }}</span>
                    </li>
                </ul>
                <br />
                <h3>
                    This method ensures a transparent and secure process for generating game
                    results.
                </h3>
                <br />
                <br />
                <router-link v-bind:to="'/' + expand || 'battles'">
                    {{ fairnessContent[expand || 'battles'].title }}
                </router-link>
                <br />
                <div
                    v-for="(item, index) in Object.keys(fairnessContent)"
                    v-bind:key="(prismLoaded ? 'loaded-' : '') + index"
                    :class="[
                        $style['article-code'],
                        { [$style.active]: item === (expand || 'battles') }
                    ]"
                >
                    <pre
                        class="language-javascript"
                    ><code class="language-javascript">{{ getBreakdownCode(item) }}</code></pre>
                </div>
                <br />
            </div>
        </div>
    </div>
</template>

<script>
    import { gamesBreakdown } from '@/utils/fair';
    import '@/assets/css/prism.css';

    export default {
        name: 'Fairness',
        metaInfo: {
            title: 'Fairness - Rollbet.gg'
        },
        data() {
            return {
                expand: null,
                prismLoaded: false
            };
        },
        computed: {
            fairnessContent() {
                return {
                    battles: {
                        title: `Case Battles`,
                        inputs: ['gameId', 'serverSeed', 'publicSeed', 'roundCount', 'playerCount']
                    },
                    unbox: {
                        title: `Loot Cases`,
                        inputs: ['clientSeed', 'serverSeed', 'nonce']
                    },
                    roll: {
                        title: `Roll`,
                        inputs: ['gameId', 'serverSeed', 'publicSeed']
                    },
                    blackjack: {
                        title: `Blackjack`,
                        inputs: ['gameId', 'serverSeed', 'publicSeed']
                    },
                    // upgrader: {
                    //     title: `Upgrader`,
                    //     inputs: ['serverSeed', 'nonce', 'clientSeed']
                    // },
                    mines: {
                        title: `Mines`,
                        inputs: ['clientSeed', 'serverSeed', 'nonce', 'minesCount']
                    },
                    towers: {
                        title: `Towers`,
                        inputs: ['clientSeed', 'serverSeed', 'nonce', 'towersRisk']
                    },
                    duels: {
                        title: `Dice Duels`,
                        inputs: ['gameId', 'serverSeed', 'publicSeed', 'playerCount']
                    },
                    crash: {
                        title: `Crash`,
                        inputs: ['gameId', 'serverSeed']
                    }
                };
            },
            getInputs() {
                return {
                    serverSeed: `Server Seed: A cryptographically secure string generated through a pseudo-random process.`,
                    nonce: `Nonce: A sequentially incremented number associated with each user bet.`,
                    clientSeed: `Client Seed: A user-defined string that can be modified at any time.`,
                    publicSeed: `Public Seed: An EOS Block identifier assigned after the final player joins the game, or after the final bet is placed.`,
                    gameId: `Game ID: A unique identifier assigned to each game upon its creation.`,
                    roundCount: `Round Count: The specific number of the game round.`,
                    playerCount: `Player Count: The position of the user within the game.`,
                    minesCount: `Mines Count: The specific number of mines in the game round.`,
                    towersRisk: `Towers Risk: The difficulty of the towers game round.`
                };
            }
        },
        methods: {
            getBreakdownCode(index) {
                return gamesBreakdown[index] ? gamesBreakdown[index]() || '' : '';
            },
            expandAction(index) {
                if (this.expand === index) {
                    // this.expand = null;
                } else {
                    this.expand = index;
                }
            }
        },
        created() {
            const script = document.createElement('script');
            script.src = '/js/prism.js';
            script.id = 'prism';
            script.onload = () => {
                this.prismLoaded = true;
            };
            document.head.appendChild(script);
        },
        destroyed() {
            const script = document.getElementById('prism');
            if (script) {
                script.remove(); // Removes the script element
            }
        }
    };
</script>

<style module>
    .fairness {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 45px 10px;
    }

    .fairness-title {
        width: 100%;
        max-width: 1345px;
        color: white;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 7px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .fairness-content {
        width: 100%;
        max-width: 1345px;
        background: #081624;
        padding: 45px 20px 60px 20px;
        border-radius: 15px;
    }

    .content-article {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding-right: 20px;
    }

    .fairness .fairness-content h1 {
        font-size: 22px;
        color: white;
        font-weight: 600;
    }

    .fairness .fairness-content h2 {
        font-size: 20px;
        color: white;
        font-weight: 600;
        padding-top: 7px;
        margin-top: -7px;
        padding-bottom: 28px;
        margin-bottom: -14px;
        white-space: nowrap;
    }

    .fairness .fairness-content br {
        display: block;
        margin: 9px 0;
    }

    .fairness .fairness-content h3 {
        font-size: 18px;
        color: white;
        font-weight: 500;
        line-height: 1.5;
    }

    .fairness .fairness-content h4 {
        font-size: 15px;
        color: white;
        font-weight: 500;
        line-height: 1.5;
    }

    .fairness .fairness-content ul li::before {
        display: inline-block;
        content: '';
        width: 3px;
    }
    .fairness .fairness-content ul {
        list-style-position: inside;
        margin-left: 8px;
    }

    .fairness .fairness-content ul,
    .fairness .fairness-content p {
        color: #4f6a83;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.3;
    }

    .fairness .fairness-content ul {
        color: white;
    }

    .fairness .fairness-content span {
        color: #4f6a83;
    }

    .fairness .fairness-content section {
        padding-left: 24px;
    }

    .fairness .fairness-content a {
        color: #21b894;
        font-size: 16px;
        font-weight: 600;
    }

    .fairness .article-options {
        display: flex;
        border-radius: 8px;
        padding: 7px;
        gap: 7px;
        flex-wrap: wrap;
        border: 1px solid #0b3152;
        width: fit-content;
        background: #051c3059;
    }

    .fairness .article-options button {
        filter: drop-shadow(0 2px 0 #06121e);
        padding: 0px 22px;
        height: 36px;
        font-size: 15px;
        font-weight: 500;
        color: #5b85ae;
        z-index: 1;
    }

    .fairness .article-options button::before {
        background: #1a3959;
        font-size: 3px;
    }

    .fairness .article-options button::after {
        background: #163450;
        font-size: 3px;
    }

    .fairness .article-options button.expand {
        font-weight: 600;
        filter: unset;
        color: #48d49c;
    }

    .fairness .article-options button.expand::before {
        background: linear-gradient(0deg, #2e8374, #1a5056);
    }

    .fairness .article-options button.expand::after {
        background: linear-gradient(45deg, #143e48, #1a5056);
    }

    .fairness .article-code {
        background: #061422;
        border-radius: 15px;
        overflow: hidden;
        display: none;
    }

    .fairness .article-code.active {
        display: block;
    }

    .fairness pre {
        background: none;
        margin-top: -1.1em;
        font-size: 15px;
    }

    @media only screen and (max-width: 1500px) {
        .fairness {
            padding: 30px 10px 45px 10px;
        }
    }
</style>
