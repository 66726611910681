<template>
    <div :class="$style['unbox-header-overview']">
        <div :class="$style['overview-title']">
            <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0 5.81131H6.77137V7.23969H0V5.81131Z" fill="black" />
                <path
                    d="M0 5.81131H6.77137V7.23969H0V5.81131Z"
                    fill="url(#paint0_linear_187_19553)"
                />
                <path
                    d="M18.3071 0.113598C18.4429 0.405245 18.5192 0.730659 18.5192 1.07391V4.62515H20V2.45124C20 1.35654 19.2873 0.427387 18.3071 0.113598Z"
                    fill="black"
                />
                <path
                    d="M18.3071 0.113598C18.4429 0.405245 18.5192 0.730659 18.5192 1.07391V4.62515H20V2.45124C20 1.35654 19.2873 0.427387 18.3071 0.113598Z"
                    fill="url(#paint1_linear_187_19553)"
                />
                <path
                    d="M18.5613 8.42588V13.1362H17.9753C17.597 13.1362 17.2892 13.4477 17.2892 13.8307V14.4238H2.71082V13.8307C2.71082 13.4477 2.40301 13.1362 2.02469 13.1362H1.43875V8.42588H0V16H20V8.42588H18.5613Z"
                    fill="black"
                />
                <path
                    d="M18.5613 8.42588V13.1362H17.9753C17.597 13.1362 17.2892 13.4477 17.2892 13.8307V14.4238H2.71082V13.8307C2.71082 13.4477 2.40301 13.1362 2.02469 13.1362H1.43875V8.42588H0V16H20V8.42588H18.5613Z"
                    fill="url(#paint2_linear_187_19553)"
                />
                <path
                    d="M10 9.99167C11.1341 9.99167 12.0568 9.05773 12.0568 7.90977V5.81134H7.94324V7.90977C7.94324 9.05769 8.8659 9.99167 10 9.99167ZM9.40496 7.02075H10.5768V8.20695H9.40496V7.02075Z"
                    fill="black"
                />
                <path
                    d="M10 9.99167C11.1341 9.99167 12.0568 9.05773 12.0568 7.90977V5.81134H7.94324V7.90977C7.94324 9.05769 8.8659 9.99167 10 9.99167ZM9.40496 7.02075H10.5768V8.20695H9.40496V7.02075Z"
                    fill="url(#paint3_linear_187_19553)"
                />
                <path
                    d="M3.78809 13.2376H16.212C16.3965 12.6769 16.8354 12.2326 17.3894 12.0458V8.42588H13.188C12.9428 9.98348 11.6068 11.1778 10 11.1778C8.3932 11.1778 7.05727 9.98348 6.81199 8.42588H2.61063V12.0458C3.16461 12.2326 3.60352 12.6769 3.78809 13.2376Z"
                    fill="black"
                />
                <path
                    d="M3.78809 13.2376H16.212C16.3965 12.6769 16.8354 12.2326 17.3894 12.0458V8.42588H13.188C12.9428 9.98348 11.6068 11.1778 10 11.1778C8.3932 11.1778 7.05727 9.98348 6.81199 8.42588H2.61063V12.0458C3.16461 12.2326 3.60352 12.6769 3.78809 13.2376Z"
                    fill="url(#paint4_linear_187_19553)"
                />
                <path
                    d="M2.65262 1.07391V4.62515H17.3474V1.07391C17.3474 0.481755 16.8714 2.43374e-09 16.2864 2.43374e-09H3.71356C3.12856 -3.95375e-05 2.65262 0.481715 2.65262 1.07391Z"
                    fill="black"
                />
                <path
                    d="M2.65262 1.07391V4.62515H17.3474V1.07391C17.3474 0.481755 16.8714 2.43374e-09 16.2864 2.43374e-09H3.71356C3.12856 -3.95375e-05 2.65262 0.481715 2.65262 1.07391Z"
                    fill="url(#paint5_linear_187_19553)"
                />
                <path
                    d="M0 4.62511H1.48074V1.07391C1.48074 0.730659 1.55707 0.405245 1.69289 0.113598C0.712656 0.427387 0 1.35654 0 2.4512V4.62511Z"
                    fill="black"
                />
                <path
                    d="M0 4.62511H1.48074V1.07391C1.48074 0.730659 1.55707 0.405245 1.69289 0.113598C0.712656 0.427387 0 1.35654 0 2.4512V4.62511Z"
                    fill="url(#paint6_linear_187_19553)"
                />
                <path d="M13.2286 5.81131H20V7.23969H13.2286V5.81131Z" fill="black" />
                <path
                    d="M13.2286 5.81131H20V7.23969H13.2286V5.81131Z"
                    fill="url(#paint7_linear_187_19553)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_187_19553"
                        x1="57.8635"
                        y1="-0.643727"
                        x2="13.4947"
                        y2="32.3907"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                </defs>
            </svg>
            LOOT CASES
        </div>
        <div :class="$style['overview-filter']">
            <UnboxFilterSearch />
            <UnboxFilterSort />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import UnboxFilterSearch from '@/components/unbox/UnboxFilterSearch';
    import UnboxFilterSort from '@/components/unbox/UnboxFilterSort';

    export default {
        name: 'UnboxHeaderOverview',
        components: {
            UnboxFilterSearch,
            UnboxFilterSort
        },
        methods: {
            ...mapActions(['unboxSetFilterSelect'])
        },
        computed: {
            ...mapGetters(['unboxFilterSelect'])
        }
    };
</script>

<style module>
    .unbox-header-overview {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
        gap: 14px 7px;
    }

    .unbox-header-overview .overview-title {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    .unbox-header-overview .overview-select {
        width: calc(100% - 625px);
        display: flex;
        align-items: center;
        margin-left: 35px;
        padding-bottom: 25px;
    }

    .unbox-header-overview .overview-select button.button-select {
        height: 45px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: 25px;
        font-size: 14px;
        font-weight: 700;
        color: rgba(110, 149, 182, 0.5);
        transition: color 0.3s ease;
    }

    .unbox-header-overview .overview-select button.button-select:last-child {
        margin-right: 0;
    }

    .unbox-header-overview .overview-select button.button-select.button-active {
        color: #ffffff;
    }

    .unbox-header-overview .overview-select button.button-select.button-active::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -27px;
        background: linear-gradient(146deg, #6170f1 0%, #1c47b6 100%);
    }

    .unbox-header-overview .overview-select button.button-select svg {
        margin-bottom: 7px;
        fill: rgba(110, 149, 182, 0.5);
        transition: all 0.3s ease;
    }

    .unbox-header-overview .overview-select button.button-select.button-active svg {
        fill: #6170f1;
        filter: drop-shadow(0px 2px 25px #5267e4);
    }

    .unbox-header-overview .overview-filter {
        display: flex;
        align-items: center;
        gap: 7px;
    }
</style>
