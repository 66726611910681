var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['battles-bet-element'],
        {
            [_vm.$style['element-winner']]:
                _vm.battlesGameData.game.state === 'completed' && _vm.bet.payout > 0,
            [_vm.$style['element-lost']]:
                _vm.battlesGameData.game.state === 'completed' && _vm.bet.payout <= 0
        },
        _vm.$style['game-' + _vm.playerCount]
    ]},[_c('div',{class:_vm.$style['element-top']},[_c('div',{class:_vm.$style['top-inner']},[(_vm.bet !== null)?_c('div',{class:_vm.$style['inner-info']},[_c('div',{class:[
                        _vm.$style['info-user'],
                        _vm.$style['user-' + (_vm.bet.bot === true ? 'bot' : _vm.battlesGetRank(_vm.bet.user))],
                        _vm.$style['user-' + _vm.battlesGetLevelColor(_vm.bet.user)]
                    ]},[_c('div',{class:_vm.$style['user-avatar']},[_c('AvatarImage',{attrs:{"image":_vm.bet.bot ? _vm.battlesGetBot.avatar : _vm.bet.user.avatar,"rank":_vm.bet.bot ? null : _vm.bet.user.rank,"level":_vm.bet.bot ? null : _vm.bet.user.level}})],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.bet.bot === true ? _vm.battlesGetBot.username : _vm.bet.user.username)}})]),_c('div',{class:_vm.$style['info-amount']},[(_vm.bet.coinType === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}):(_vm.bet.coinType === 'robuxCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}}):_vm._e(),_c('div',{class:_vm.$style['amount-value']},[_c('span',[_vm._v(" "+_vm._s(_vm.battlesFormatValue(_vm.battlesGetOutcomeAmount).split('.')[0])+" ")]),_vm._v("."+_vm._s(_vm.battlesFormatValue(_vm.battlesGetOutcomeAmount).split('.')[1])+" ")])])]):_c('div',{class:_vm.$style['inner-actions']},[(_vm.battlesGameData.game.options.funding > 0)?_c('div',{class:_vm.$style['actions-funding']},[_c('div',{class:_vm.$style['funding-inner']},[_c('span',[_vm._v("-"+_vm._s(_vm.battlesGameData.game.options.funding)+"%")])])]):_vm._e(),_c('button',{class:_vm.$style['button-join'],attrs:{"disabled":_vm.socketSendLoading !== null},on:{"click":function($event){_vm.authUser.user !== null &&
                        _vm.authUser.user._id === _vm.battlesGameData.game.bets[0].user._id
                            ? _vm.battlesBotButton()
                            : _vm.battlesJoinButton()}}},[_c('div',{class:_vm.$style['button-inner']},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
                                    _vm.socketSendLoading === 'BattlesJoin' ||
                                    _vm.socketSendLoading === 'BattlesCall'
                                )?_c('ButtonLoading'):_c('div',{class:_vm.$style['inner-content']},[(
                                        false &&
                                        _vm.authUser.user !== null &&
                                        _vm.authUser.user._id ===
                                            _vm.battlesGameData.game.bets[0].user._id
                                    )?_c('div',{class:_vm.$style['content-bot']},[_c('img',{attrs:{"src":require("@/assets/img/char-robot.png")}})]):(
                                        _vm.authUser.user !== null &&
                                        _vm.authUser.user._id !==
                                            _vm.battlesGameData.game.bets[0].user._id
                                    )?_c('svg',{attrs:{"width":"15","height":"15","viewBox":"0 0 15 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M3.47861 8.33238L6.15342 11.0087L5.08395 12.0789L6.15493 13.1499L5.08471 14.2201L3.21144 12.3469L1.07023 14.4881L0 13.4178L2.14121 11.2759L0.267935 9.40336L1.33816 8.33313L2.40839 9.4026L3.47861 8.33238ZM0.413256 0.456299L3.09715 0.458569L12.0412 9.40336L13.1122 8.33313L14.1824 9.40336L12.3099 11.2766L14.4503 13.4178L13.3801 14.4881L11.2389 12.3469L9.36561 14.2201L8.29539 13.1499L9.36561 12.0789L0.415526 3.12884L0.413256 0.456299ZM11.3554 0.456299L14.0371 0.458569L14.0386 3.12505L10.971 6.19192L8.29463 3.51636L11.3554 0.456299Z"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.authUser.user !== null && _vm.authUser.user._id === _vm.battlesGameData.game.bets[0].user._id ? 'CALL BOT' : 'JOIN BATTLE')+" ")])],1)],1)])])])]),_c('div',{class:_vm.$style['element-items']},[_c('div',{class:_vm.$style['items-list']},[_c('div',{class:_vm.$style['list-content']},_vm._l((_vm.battlesGetOutcomeItems),function(item,index){return _c('UnboxItemElement',{key:index,attrs:{"item":item,"coinType":_vm.bet.coinType}})}),1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }