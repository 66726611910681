<template>
    <div :class="$style['modal-two-step']">
        <h4 :class="$style['step-title']">2FA Input</h4>
        <hr />
        <p :class="$style['step-label']">2FA Code</p>
        <div :class="$style['step-input']" class="octagonal before after hoverable">
            <input v-model="modalCode" v-on:keydown.enter="modalCancelButton()" type="text" />
        </div>
        <div :class="$style['step-info']">
            <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.85207 0H34.6982L38 3.66897V13.1034V34.331L34.6982 38H3.85207L0 34.331V13.1034V3.66897L3.85207 0Z"
                    fill="#062237"
                />
                <path
                    d="M3.85207 0H34.6982L38 3.66897V13.1034V34.331L34.6982 38H3.85207L0 34.331V13.1034V3.66897L3.85207 0Z"
                    fill="url(#paint0_linear_633_2499)"
                    fill-opacity="0.15"
                />
                <path
                    d="M19.5 9C18.121 8.99952 16.7554 9.27078 15.4813 9.79828C14.2071 10.3258 13.0494 11.0992 12.0743 12.0743C11.0992 13.0494 10.3258 14.2071 9.79828 15.4813C9.27078 16.7554 8.99952 18.121 9 19.5C8.99952 20.879 9.27078 22.2446 9.79828 23.5187C10.3258 24.7929 11.0992 25.9506 12.0743 26.9257C13.0494 27.9008 14.2071 28.6742 15.4813 29.2017C16.7554 29.7292 18.121 30.0005 19.5 30C20.879 30.0005 22.2446 29.7292 23.5187 29.2017C24.7929 28.6742 25.9506 27.9008 26.9257 26.9257C27.9008 25.9506 28.6742 24.7929 29.2017 23.5187C29.7292 22.2446 30.0005 20.879 30 19.5C30.0005 18.121 29.7292 16.7554 29.2017 15.4813C28.6742 14.2071 27.9008 13.0494 26.9257 12.0743C25.9506 11.0992 24.7929 10.3258 23.5187 9.79828C22.2446 9.27078 20.879 8.99952 19.5 9ZM19.5 27.0629C19.186 27.0637 18.8789 26.9712 18.6175 26.7972C18.3561 26.6232 18.1522 26.3755 18.0318 26.0856C17.9113 25.7956 17.8795 25.4764 17.9406 25.1684C18.0017 24.8604 18.1528 24.5775 18.3749 24.3554C18.5969 24.1334 18.8798 23.9823 19.1878 23.9212C19.4958 23.8601 19.815 23.8918 20.105 24.0123C20.3949 24.1328 20.6426 24.3367 20.8166 24.5981C20.9906 24.8595 21.0831 25.1666 21.0823 25.4806C21.0814 25.9 20.9144 26.3019 20.6178 26.5984C20.3213 26.8949 19.9194 27.062 19.5 27.0629ZM21.4201 13.993L20.8217 22.0699C20.8179 22.1258 20.7931 22.1781 20.7522 22.2163C20.7113 22.2545 20.6574 22.2757 20.6014 22.2755H18.3986C18.3426 22.2757 18.2887 22.2545 18.2478 22.2163C18.2069 22.1781 18.1821 22.1258 18.1783 22.0699L17.5799 13.993C17.5637 13.7309 17.6013 13.4682 17.6905 13.2211C17.7796 12.9741 17.9184 12.7479 18.0983 12.5566C18.2782 12.3652 18.4954 12.2127 18.7364 12.1085C18.9775 12.0042 19.2374 11.9504 19.5 11.9504C19.7626 11.9504 20.0225 12.0042 20.2636 12.1085C20.5046 12.2127 20.7218 12.3652 20.9017 12.5566C21.0816 12.7479 21.2204 12.9741 21.3095 13.2211C21.3987 13.4682 21.4363 13.7309 21.4201 13.993Z"
                    fill="#3483D5"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_633_2499"
                        x1="165.571"
                        y1="-2.20137"
                        x2="101.187"
                        y2="92.802"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#3B7EB7" />
                        <stop offset="1" stop-color="#145081" />
                    </linearGradient>
                </defs>
            </svg>
            <p :class="$style['info-text']">
                To ensure security, we require all users to enable two-factor authentication when
                engaging in trading activities. Insert your code above, and click the green button
                below to process the trade.
            </p>
        </div>
        <div :class="$style['step-control']">
            <button
                v-on:click="modalCancelButton()"
                :class="$style['control-cancel']"
                class="octagonal before after hoverable"
            >
                Cancel
            </button>
            <button
                v-on:click="modalVerifyButton()"
                :class="$style['control-submit']"
                class="octagonal before after hoverable"
            >
                Proceed
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ButtonLoading from '@/components/ButtonLoading';

    export default {
        name: 'ModalTip',
        components: {
            ButtonLoading
        },
        data() {
            return {
                modalCode: null
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'cashierSendLimitedEnableSocket',
                'cashierSendLimitedDepositSocket',
                'cashierSendLimitedWithdrawSocket'
            ]),
            modalVerifyButton() {
                if (this.socketSendLoading !== null) return;
                if (this.modalCode === null || this.modalCode.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered 2fa code is invalid.'
                    });
                    return;
                }

                const data = {
                    challengeId: this.modalsData.challengeId,
                    twoStepId: this.modalsData.twoStepId,
                    twoStepCode: this.modalCode.trim(),
                    transactionId: this.modalsData.transactionId,
                    type: this.userBalanceData.type
                };
                this.cashierSendLimitedWithdrawSocket(data);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'modalsData', 'userBalanceData'])
        }
    };
</script>

<style module>
    .modal-two-step {
        width: 838px;
        padding: 30px 35px 30px 35px;
    }

    .step-title {
        color: white;
        font-size: 18px;
        font-weight: 600;
    }

    .modal-two-step hr {
        border-color: #0a2f4d;
        margin: 24px 0;
    }

    .step-label {
        font-size: 14px;
        font-weight: 500;
        color: #5b84ae;
        margin-bottom: 7px;
    }

    .step-input::before {
        background: #1e4b7e59;
        font-size: 5px;
    }

    .step-input::after {
        background: linear-gradient(
            360deg,
            rgba(15, 66, 107, 0.35) 20.76%,
            rgba(13, 51, 85, 0.35) 101.46%
        );
        font-size: 5px;
    }

    .step-input {
        margin-bottom: 24px;
        color: #5b85ae;
    }

    .step-input input {
        appearance: none;
        background: none;
        padding: 0 20px;
        color: #5b85ae;
        font-size: 14px;
        height: 57px;
        width: 100%;
    }

    .step-info {
        display: flex;
        background: #051c3059;
        border: 1px solid #0b315259;
        border-radius: 8px;
        gap: 16px;
        padding: 14px;
        margin-bottom: 26px;
    }

    .info-text {
        font-size: 14px;
        color: #5b84ae;
        font-weight: 500;
        flex: 1;
    }

    .step-control {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
        flex-wrap: wrap;
    }

    .step-control button {
        font-size: 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        max-width: 127px;
        padding: 0 20px;
        height: 41px;
    }

    .step-control .control-cancel {
        filter: drop-shadow(0px 2px 0px #00000040);
        color: #5b85ae;
    }

    .step-control .control-cancel::after {
        background: linear-gradient(0deg, #0d2d47, #0d2d47),
            linear-gradient(360deg, rgba(15, 66, 107, 0.35) 8.33%, rgba(13, 51, 85, 0.35) 108.33%);
        font-size: 3px;
    }

    .step-control .control-cancel::before {
        background: #1e4b7e59;
        font-size: 3px;
    }

    .step-control .control-submit {
        filter: drop-shadow(0px 2px 0px #136762);
        color: white;
    }

    .step-control .control-submit::after {
        background: #21b894;
        font-size: 3px;
    }

    .step-control .control-submit::before {
        background: #21b894;
        font-size: 3px;
    }

    @media screen and (max-width: 600px) {
        .modal-two-step {
            padding: 30px 20px 30px 20px;
        }
    }
</style>
