var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.$style.bets, { [_vm.$style.mounted]: _vm.betsMounted }]},[_c('div',{staticClass:"octagonal before",class:_vm.$style['bets-content']},[_c('div',{class:_vm.$style['content-nav']},[(_vm.authUser.user !== null)?_c('button',{class:[
                    _vm.$style['nav-link'],
                    { [_vm.$style['link-active']]: _vm.betsTab === 'my' }
                ],on:{"click":function($event){return _vm.betsSetTab('my')}}},[_c('span',{class:{ 'fade-green': _vm.betsTab === 'my' }},[_vm._v("My Bets")])]):_vm._e(),_c('button',{class:[
                    _vm.$style['nav-link'],
                    { [_vm.$style['link-active']]: _vm.betsTab === 'all' }
                ],on:{"click":function($event){return _vm.betsSetTab('all')}}},[_c('span',{class:{ 'fade-green': _vm.betsTab === 'all' }},[_vm._v("All")])]),_c('button',{class:[
                    _vm.$style['nav-link'],
                    { [_vm.$style['link-active']]: _vm.betsTab === 'whale' }
                ],on:{"click":function($event){return _vm.betsSetTab('whale')}}},[_c('span',{class:{ 'fade-green': _vm.betsTab === 'whale' }},[_vm._v("Whale Wins")])]),_c('button',{class:[
                    _vm.$style['nav-link'],
                    { [_vm.$style['link-active']]: _vm.betsTab === 'lucky' }
                ],on:{"click":function($event){return _vm.betsSetTab('lucky')}}},[_c('span',{class:{ 'fade-green': _vm.betsTab === 'lucky' }},[_vm._v("Lucky Wins")])])]),_c('div',{class:_vm.$style['content-bets']},[_c('div',{class:_vm.$style['bets-list']},[_c('div',{class:_vm.$style['list-head']},[_c('div',{class:_vm.$style['head-game']},[_vm._v("Game")]),_c('div',{class:_vm.$style['head-user']},[_vm._v("User")]),_c('div',{class:_vm.$style['head-time']},[_vm._v("Time")]),_c('div',{class:_vm.$style['head-wager']},[_vm._v("Bet amount")]),_c('div',{class:_vm.$style['head-multiplier']},[_vm._v("Multiplier")]),_c('div',{class:_vm.$style['head-payout']},[_vm._v("Payout")])]),_c('div',{class:_vm.$style['list-content']},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.betsGetList.length === 0 || _vm.generalBets.loading === true)?_c('div',{key:"loading",class:_vm.$style['content-loading']},[_c('LoadingAnimation')],1):(_vm.betsGetList.length > 0)?_c('ol',{key:"data",class:[_vm.$style['content-list'], { m: _vm.betsMounted }]},_vm._l((_vm.betsGetList),function(bet){return _c('BetsElement',{key:bet._id + _vm.betsTab,attrs:{"bet":bet}})}),1):_c('div',{key:"empty",class:_vm.$style['content-empty']},[_vm._v(" No bets found. ")])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }