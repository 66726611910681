var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['battles-create-footer']},[_c('div',{class:_vm.$style['footer-left']},[_c('div',{class:[
                _vm.$style['left-private'],
                { [_vm.$style['private-active']]: _vm.battlesFilterPrivate === true }
            ]},[_vm._v(" PRIVATE BATTLE "),_c('button',{on:{"click":function($event){return _vm.battlesPrivateToggle()}}})]),(_vm.battlesFilterPrivate === true)?_c('div',{class:[
                _vm.$style['left-affiliate'],
                { [_vm.$style['affiliate-active']]: _vm.battlesFilterAffiliate === true }
            ]},[_vm._v(" AFFILIATES ONLY "),_c('button',{on:{"click":function($event){return _vm.battlesSetFilterAffiliate(!_vm.battlesFilterAffiliate)}}})]):_vm._e()]),_c('div',{class:_vm.$style['footer-right']},[_c('div',{class:[
                _vm.$style['right-cursed'],
                { [_vm.$style['cursed-active']]: _vm.battlesFilterCursed === true }
            ]},[_c('IconCursedGradient'),_c('span',[_vm._v("CURSED MODE")]),_c('button',{on:{"click":function($event){return _vm.battlesCursedToggle(!_vm.battlesFilterCursed)}}})],1),_c('div',{class:[
                _vm.$style['right-terminal'],
                { [_vm.$style['terminal-active']]: _vm.battlesFilterTerminal === true }
            ]},[_c('IconTerminalGradient'),_c('span',[_vm._v("TERMINAL MODE")]),_c('button',{on:{"click":function($event){return _vm.battlesTerminalToggle(!_vm.battlesFilterTerminal)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }