<template>
    <button
        v-on:click="unboxSetFilterSort(unboxFilterSort === 'highest' ? 'lowest' : 'highest')"
        v-bind:class="[
            $style['unbox-filter-sort'],
            { [$style['sort-lowest']]: unboxFilterSort === 'Lowest' }
        ]"
    >
        <div :class="$style['button-inner']">
            <div :class="$style['inner-value']">
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.0747223 0.4245C0.135935 0.297432 0.231739 0.1902 0.351135 0.115113C0.47053 0.0400265 0.608678 0.000128836 0.749722 5.28408e-08H15.7497C15.891 -5.29869e-05 16.0295 0.0398245 16.1492 0.115037C16.2688 0.19025 16.3648 0.297738 16.426 0.425114C16.4872 0.55249 16.5112 0.694571 16.4952 0.834984C16.4792 0.975398 16.4238 1.10843 16.3355 1.21875L10.4997 8.51325V15.75C10.4998 15.8983 10.4558 16.0433 10.3734 16.1667C10.2911 16.29 10.174 16.3862 10.037 16.443C9.94597 16.4809 9.84831 16.5003 9.74972 16.5C9.55082 16.5 9.36009 16.4209 9.21947 16.2803L6.21947 13.2803C6.07881 13.1396 5.99976 12.9489 5.99972 12.75V8.51325L0.163973 1.21875C0.0757275 1.10824 0.0205269 0.975038 0.00473404 0.834504C-0.0110569 0.693971 0.0132046 0.551837 0.0747223 0.4245Z"
                    />
                </svg>
                SORT BY:
                <span>{{ unboxFilterSort === 'highest' ? 'HIGH' : 'LOW' }}</span>
            </div>
            <svg
                :class="$style['toggle-icon']"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.5176 1.66411e-06L0.482354 8.43375e-08C0.0547936 9.58042e-09 -0.16302 0.516304 0.143533 0.822859L4.66115 5.34052C4.8467 5.52607 5.15325 5.52607 5.33888 5.34052L9.8565 0.822861C10.163 0.516306 9.94516 1.73887e-06 9.5176 1.66411e-06Z"
                />
            </svg>
        </div>
    </button>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'UnboxFilterSort',
        methods: {
            ...mapActions(['unboxSetFilterSort'])
        },
        computed: {
            ...mapGetters(['unboxFilterSort'])
        }
    };
</script>

<style module>
    button.unbox-filter-sort {
        max-width: 209px;
        width: 100%;
        height: 46px;
        position: relative;
        padding-bottom: 1px;
    }

    button.unbox-filter-sort::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background: #04121f;
        font-size: 5px;
        clip-path: var(--octagonal-before);
        z-index: -1;
    }

    button.unbox-filter-sort .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        padding-top: 1px;
        background: linear-gradient(10deg, #112d48, #0e263f);
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    button.unbox-filter-sort .button-inner svg.toggle-icon {
        width: 7.5px;
        fill: #406992;
        transition: all 0.3s ease;
    }

    button.unbox-filter-sort.sort-lowest .button-inner svg.toggle-icon {
        transform: rotate(180deg);
    }

    button.unbox-filter-sort .inner-value {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #49687d;
    }

    button.unbox-filter-sort .inner-value svg {
        width: 12px;
        margin-right: 8px;
        fill: #01e0a3;
    }

    button.unbox-filter-sort .inner-value span {
        text-transform: uppercase;
        margin-left: 5px;
        font-weight: 700;
        color: white;
    }
</style>
