var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['upgrader-bets'],
        {
            [_vm.$style['mounted']]: _vm.mounted,
            [_vm.$style['expand']]: _vm.expand
        }
    ]},[_c('button',{class:_vm.$style['bets-overlay'],on:{"click":function($event){_vm.expand = false}}}),_c('div',{class:_vm.$style['bets-option'],on:{"click":function($event){_vm.expand = true}}},[_c('button',{class:{ [_vm.$style['active']]: _vm.mode === 'recent' },on:{"click":function($event){return _vm.upgraderListMode('recent')}}},[_c('p',{staticClass:"octagonal before after hoverable"},[_vm._v("Recent")])]),_c('button',{class:{ [_vm.$style['active']]: _vm.mode === 'whale' },on:{"click":function($event){return _vm.upgraderListMode('whale')}}},[_c('p',{staticClass:"octagonal before after hoverable"},[_vm._v("Big Wins")])])]),_c('div',{class:_vm.$style['bets-list-wrapper'],on:{"click":function($event){_vm.expand = !_vm.expand}}},[_c('ol',{class:_vm.$style['bets-list']},_vm._l((_vm.betsList),function(bet,index){return _c('li',{key:_vm.mode + index,class:_vm.$style['list-item-wrapper']},[_c('div',{staticClass:"octagonal",class:_vm.$style['list-item']},[_c('div',{staticClass:"octagonal before",class:[
                            _vm.$style['item-content'],
                            { [_vm.$style['lose']]: bet.payout === 0 }
                        ]},[_c('button',{class:_vm.$style['content-user'],on:{"click":function($event){return _vm.upgraderBetUserButton(bet.user)}}},[_c('AvatarImage',{attrs:{"image":bet.user ? bet.user?.avatar : 'anonymous',"rank":bet.user?.rank,"level":bet.user?.level}}),_c('p',[_vm._v(_vm._s(bet.user?.username || 'Anonymous'))]),_c('hr')],1),_c('div',{class:_vm.$style['content-game']},[_c('div',{class:_vm.$style['game-side']},[_c('div',{class:[
                                        _vm.$style['side-item'],
                                        _vm.$style[
                                            'item-' +
                                                (bet.coinType === 'robuxCoin'
                                                    ? 'yellow'
                                                    : 'green')
                                        ]
                                    ]},[(bet.coinType === 'cryptoCoin')?_c('img',{attrs:{"src":require("@/assets/img/icons/cryptoCoin.svg"),"width":"25"}}):_c('img',{attrs:{"src":require("@/assets/img/icons/robuxCoinSingle.svg"),"width":"30"}}),_c('div',{class:_vm.$style['item-spiral']},[_c('div')])]),_c('p',[_vm._v(" "+_vm._s(parseFloat( (bet.amount / 1000).toFixed(2) ).toLocaleString())+" ")])]),_c('div',{class:_vm.$style['game-multiplier']},[_c('svg',{attrs:{"width":"20","height":"40","viewBox":"0 0 20 40","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.711 18.1157H18.8632L1.54762 37.0012L7.75089 22.0362L8.03752 21.3447H7.289H1.13675L18.4524 2.45814L12.2491 17.4242L11.9625 18.1157H12.711Z","fill":"url(#paint0_linear_137_1576)","stroke":"#97FFF7"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_137_1576","x1":"20","y1":"0.0302734","x2":"-4.52194","y2":"3.08096","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"#00FFC2"}}),_c('stop',{attrs:{"offset":"1","stop-color":"#00AA6D"}})],1)],1)]),_c('p',[_vm._v(_vm._s(bet.multiplier / 100)+"x")])]),_c('div',{class:_vm.$style['game-side']},[_c('div',{class:[
                                        _vm.$style['side-item'],
                                        _vm.$style['side-item-' + bet.items?.length],
                                        _vm.$style[
                                            'item-' + _vm.upgraderGetItemsColor(bet.payout / 1000)
                                        ]
                                    ]},[_vm._l((bet.items),function(item,i){return _c('img',{key:i,attrs:{"src":item.image,"width":"55"}})}),_c('div',{class:_vm.$style['item-spiral']},[_c('div')])],2),_c('p',[_c('span',{class:bet.coinType === 'cryptoCoin'
                                                ? 'fade-lime'
                                                : 'fade-gold'},[_vm._v(_vm._s(parseFloat( (bet.payout / 1000).toFixed(2) ).toLocaleString()))])])])]),_c('svg',{class:_vm.$style['content-dots'],attrs:{"xmlns":"http://www.w3.org/2000/svg"}},[_c('defs',[_c('pattern',{attrs:{"id":"polka-dots","x":"0","y":"0","width":"7","height":"7","patternUnits":"userSpaceOnUse"}},[_c('circle',{attrs:{"cx":"2","cy":"2","r":"2","fill":"currentColor"}})])]),_c('rect',{attrs:{"width":"100%","height":"100%","fill":"url(#polka-dots)"}})])])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }