<template>
    <div
        :class="[
            $style['modal-fair-game'],
            {
                [$style.verification]: provablyFair !== true && modalsData?.game?.fair !== undefined
            }
        ]"
    >
        <div
            :class="[
                $style['game-dropdown'],
                { [$style.open]: dropdownOpen && modalsData?.game?.fair !== undefined }
            ]"
            ref="dropdown"
        >
            <button
                v-on:click="dropdownOpen = !dropdownOpen"
                v-if="provablyFair === true || modalsData?.game?.fair === undefined"
                :class="$style['dropdown-title']"
            >
                <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.41186 10.399C7.41186 10.1066 7.17483 9.86956 6.88245 9.86956H6.69754L4.88439 5.77667C4.53635 5.86819 4.18574 5.94838 3.83311 6.01773L5.53949 9.86956H1.87243L3.5557 6.0699C3.14593 6.14345 2.73369 6.20229 2.31968 6.24581L0.714349 9.86956H0.529441C0.237061 9.86956 2.36451e-05 10.1066 2.36451e-05 10.399C-0.00781173 12.2184 1.9331 13.4396 3.70594 13.399C5.47843 13.4401 7.42023 12.218 7.41186 10.399Z"
                        fill="#21B894"
                    />
                    <path
                        d="M17.4703 9.86956H17.2854L15.6801 6.24581C15.2661 6.20229 14.8539 6.14345 14.4441 6.0699L16.1274 9.86956H12.4603L14.1667 6.01773C13.8141 5.94842 13.4634 5.86819 13.1154 5.77667L11.3022 9.86956H11.1173C10.8249 9.86956 10.5879 10.1066 10.5879 10.399C10.5879 11.2259 10.9909 11.9947 11.7226 12.5639C13.0874 13.6696 15.5002 13.6697 16.8649 12.5638C17.5967 11.9947 17.9997 11.2259 17.9997 10.399C17.9998 10.1066 17.7627 9.86956 17.4703 9.86956Z"
                        fill="#21B894"
                    />
                    <path
                        d="M0.529899 5.28126C1.98283 5.28126 3.42941 5.08407 4.82947 4.69523C7.55795 3.93728 10.4423 3.93728 13.1708 4.69523C14.5708 5.0841 16.0174 5.28126 17.4703 5.28126C18.0708 5.28126 18.2074 4.43955 17.6377 4.24964H17.6377C16.2635 3.79158 14.9248 3.20269 13.6586 2.49924L12.8222 2.03459C11.8105 1.47252 10.6818 1.14051 9.5295 1.06233V0.516608C9.50345 -0.185081 8.49646 -0.184552 8.47066 0.516608V1.06233C7.31833 1.14051 6.18969 1.47252 5.17797 2.03459L4.34149 2.49924C3.07534 3.20269 1.73662 3.79158 0.362462 4.24964H0.362426C-0.207227 4.43955 -0.0706369 5.28126 0.529899 5.28126Z"
                        fill="#21B894"
                    />
                    <path
                        d="M11.1175 15.9404H6.8822C6.00502 15.9404 5.29395 16.6514 5.29395 17.5286C5.29395 17.821 5.53098 18.058 5.82336 18.058H12.1764C12.4687 18.058 12.7058 17.821 12.7058 17.5286C12.7058 16.6514 11.9947 15.9404 11.1175 15.9404Z"
                        fill="#21B894"
                    />
                    <path
                        d="M8.47083 14.8814H9.52966V5.19546C9.1771 5.18233 8.82338 5.18233 8.47083 5.19546V14.8814Z"
                        fill="#21B894"
                    />
                </svg>
                <p>Provably Fair</p>
                <svg
                    v-if="modalsData?.game?.fair !== undefined"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547937 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84762C4.84671 6.05079 5.15326 6.05079 5.33889 5.84762L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                        fill="#5B84AE"
                    />
                </svg>
            </button>
            <button
                v-on:click="dropdownOpen = !dropdownOpen"
                v-else
                :class="$style['dropdown-title']"
            >
                <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.41186 10.3989C7.41186 10.1065 7.17483 9.8695 6.88245 9.8695H6.69754L4.88439 5.77661C4.53635 5.86813 4.18574 5.94832 3.83311 6.01767L5.53949 9.8695H1.87243L3.5557 6.06984C3.14593 6.14339 2.73369 6.20223 2.31968 6.24575L0.714349 9.8695H0.529441C0.237061 9.8695 2.36451e-05 10.1065 2.36451e-05 10.3989C-0.00781173 12.2184 1.9331 13.4396 3.70594 13.3989C5.47843 13.44 7.42023 12.2179 7.41186 10.3989Z"
                        fill="white"
                    />
                    <path
                        d="M7.41186 10.3989C7.41186 10.1065 7.17483 9.8695 6.88245 9.8695H6.69754L4.88439 5.77661C4.53635 5.86813 4.18574 5.94832 3.83311 6.01767L5.53949 9.8695H1.87243L3.5557 6.06984C3.14593 6.14339 2.73369 6.20223 2.31968 6.24575L0.714349 9.8695H0.529441C0.237061 9.8695 2.36451e-05 10.1065 2.36451e-05 10.3989C-0.00781173 12.2184 1.9331 13.4396 3.70594 13.3989C5.47843 13.44 7.42023 12.2179 7.41186 10.3989Z"
                        fill="url(#paint0_linear_44_6679)"
                    />
                    <path
                        d="M17.4703 9.8695H17.2854L15.6801 6.24575C15.2661 6.20223 14.8539 6.14339 14.4441 6.06984L16.1274 9.8695H12.4603L14.1667 6.01767C13.8141 5.94835 13.4634 5.86813 13.1154 5.77661L11.3022 9.8695H11.1173C10.8249 9.8695 10.5879 10.1065 10.5879 10.3989C10.5879 11.2258 10.9909 11.9947 11.7226 12.5638C13.0874 13.6696 15.5002 13.6696 16.8649 12.5638C17.5967 11.9947 17.9997 11.2258 17.9997 10.3989C17.9998 10.1065 17.7627 9.8695 17.4703 9.8695Z"
                        fill="white"
                    />
                    <path
                        d="M17.4703 9.8695H17.2854L15.6801 6.24575C15.2661 6.20223 14.8539 6.14339 14.4441 6.06984L16.1274 9.8695H12.4603L14.1667 6.01767C13.8141 5.94835 13.4634 5.86813 13.1154 5.77661L11.3022 9.8695H11.1173C10.8249 9.8695 10.5879 10.1065 10.5879 10.3989C10.5879 11.2258 10.9909 11.9947 11.7226 12.5638C13.0874 13.6696 15.5002 13.6696 16.8649 12.5638C17.5967 11.9947 17.9997 11.2258 17.9997 10.3989C17.9998 10.1065 17.7627 9.8695 17.4703 9.8695Z"
                        fill="url(#paint1_linear_44_6679)"
                    />
                    <path
                        d="M0.529899 5.2812C1.98283 5.2812 3.42941 5.08401 4.82947 4.69517C7.55795 3.93722 10.4423 3.93722 13.1708 4.69517C14.5708 5.08404 16.0174 5.2812 17.4703 5.2812C18.0708 5.2812 18.2074 4.43949 17.6377 4.24957H17.6377C16.2635 3.79152 14.9248 3.20263 13.6586 2.49918L12.8222 2.03453C11.8105 1.47246 10.6818 1.14045 9.5295 1.06227V0.516547C9.50345 -0.185142 8.49646 -0.184613 8.47066 0.516547V1.06227C7.31833 1.14045 6.18969 1.47246 5.17797 2.03453L4.34149 2.49918C3.07534 3.20263 1.73662 3.79152 0.362462 4.24957H0.362426C-0.207227 4.43949 -0.0706369 5.2812 0.529899 5.2812Z"
                        fill="white"
                    />
                    <path
                        d="M0.529899 5.2812C1.98283 5.2812 3.42941 5.08401 4.82947 4.69517C7.55795 3.93722 10.4423 3.93722 13.1708 4.69517C14.5708 5.08404 16.0174 5.2812 17.4703 5.2812C18.0708 5.2812 18.2074 4.43949 17.6377 4.24957H17.6377C16.2635 3.79152 14.9248 3.20263 13.6586 2.49918L12.8222 2.03453C11.8105 1.47246 10.6818 1.14045 9.5295 1.06227V0.516547C9.50345 -0.185142 8.49646 -0.184613 8.47066 0.516547V1.06227C7.31833 1.14045 6.18969 1.47246 5.17797 2.03453L4.34149 2.49918C3.07534 3.20263 1.73662 3.79152 0.362462 4.24957H0.362426C-0.207227 4.43949 -0.0706369 5.2812 0.529899 5.2812Z"
                        fill="url(#paint2_linear_44_6679)"
                    />
                    <path
                        d="M11.1175 15.9403H6.8822C6.00502 15.9403 5.29395 16.6514 5.29395 17.5286C5.29395 17.8209 5.53098 18.058 5.82336 18.058H12.1764C12.4687 18.058 12.7058 17.8209 12.7058 17.5286C12.7058 16.6514 11.9947 15.9403 11.1175 15.9403Z"
                        fill="white"
                    />
                    <path
                        d="M11.1175 15.9403H6.8822C6.00502 15.9403 5.29395 16.6514 5.29395 17.5286C5.29395 17.8209 5.53098 18.058 5.82336 18.058H12.1764C12.4687 18.058 12.7058 17.8209 12.7058 17.5286C12.7058 16.6514 11.9947 15.9403 11.1175 15.9403Z"
                        fill="url(#paint3_linear_44_6679)"
                    />
                    <path
                        d="M8.47083 14.8813H9.52966V5.19539C9.1771 5.18226 8.82338 5.18226 8.47083 5.19539V14.8813Z"
                        fill="white"
                    />
                    <path
                        d="M8.47083 14.8813H9.52966V5.19539C9.1771 5.18226 8.82338 5.18226 8.47083 5.19539V14.8813Z"
                        fill="url(#paint4_linear_44_6679)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_44_6679"
                            x1="7.41189"
                            y1="5.77661"
                            x2="-1.32066"
                            y2="7.85738"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_44_6679"
                            x1="17.9997"
                            y1="5.77661"
                            x2="9.26807"
                            y2="7.85899"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_44_6679"
                            x1="18.0002"
                            y1="-0.00952148"
                            x2="4.7797"
                            y2="11.0137"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_44_6679"
                            x1="12.7058"
                            y1="15.9403"
                            x2="7.38919"
                            y2="20.5007"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_44_6679"
                            x1="9.52966"
                            y1="5.18555"
                            x2="8.21228"
                            y2="5.2208"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                    </defs>
                </svg>
                <p>Verification</p>
                <svg
                    v-if="modalsData?.game?.fair !== undefined"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547937 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84762C4.84671 6.05079 5.15326 6.05079 5.33889 5.84762L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                        fill="#5B84AE"
                    />
                </svg>
            </button>
            <div :class="$style['dropdown-menu']">
                <div :class="$style['menu-container']">
                    <div :class="$style['menu-content']" class="octagonal before">
                        <button
                            class="octagonal after before hoverable"
                            :class="[
                                $style['content-item'],
                                { [$style.active]: provablyFair === false }
                            ]"
                            v-on:click="modalTabSwitch(false)"
                        >
                            Verification
                        </button>
                        <button
                            class="octagonal after before hoverable"
                            :class="[
                                $style['content-item'],
                                { [$style.active]: provablyFair === true }
                            ]"
                            v-on:click="modalTabSwitch(true)"
                        >
                            Provably Fair
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div :class="$style['seed-content']">
            <div :class="$style['content-element']">
                <div :class="$style['element-title']">Active Client Seed</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ userSeedData.data?.seed?.seedClient }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-on:click="modalCopyButton(userSeedData.data?.seed?.seedClient)"
                    >
                        <span>Copy</span>
                    </button>
                </div>
            </div>
            <div :class="$style['content-element']">
                <div :class="$style['element-title']">(Hashed) Active Client Seed</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ userSeedData.data?.seed?.hash }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-on:click="modalCopyButton(userSeedData.data?.seed?.hash)"
                    >
                        <span>Copy</span>
                    </button>
                </div>
            </div>
            <div :class="$style['content-element']">
                <div :class="$style['element-title']">Amount Of Bets Made With Pair</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{
                        typeof userSeedData.data?.seed?.nonce === 'number'
                            ? userSeedData.data?.seed?.nonce
                            : '--'
                    }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-on:click="modalCopyButton(userSeedData.data?.seed?.nonce)"
                    >
                        <span>Copy</span>
                    </button>
                </div>
            </div>
            <hr />
            <p :class="$style['content-title']">Rotate Seed Pair</p>
            <div :class="$style['content-element']">
                <div :class="$style['element-title']">New Client Seed</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ modalClientSeed }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-bind:disabled="socketSendLoading !== null"
                        v-on:click="modalCycleButton()"
                    >
                        <span>Change</span>
                    </button>
                </div>
            </div>
            <div :class="$style['content-element']">
                <div :class="$style['element-title']">(Hashed) Next Server Seed</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ userSeedData.data?.seedNext?.hash }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-on:click="modalCopyButton(userSeedData.data?.seedNext?.hash)"
                    >
                        <span>Copy</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="modalsData?.game?.fair !== undefined" :class="$style['game-content']">
            <div :class="$style['content-element']">
                <div :class="$style['element-title']">
                    ({{
                        {
                            battles: 'Case Battles',
                            unbox: 'Loot Cases',
                            duels: 'Dice Duels'
                        }[modalsData.type] ||
                        modalsData.type[0].toUpperCase() + modalsData.type.slice(1).toLowerCase()
                    }}) Game ID
                </div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ modalsData?.game?._id }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-on:click="modalCopyButton(modalsData?.game?._id)"
                    >
                        <span>Copy</span>
                    </button>
                </div>
            </div>
            <div v-if="modalGetClientSeed" :class="$style['content-element']">
                <div :class="$style['element-title']">Client Seed</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ modalGetClientSeed }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-on:click="modalCopyButton(modalGetClientSeed)"
                    >
                        <span>Copy</span>
                    </button>
                </div>
            </div>
            <div :class="$style['content-element']">
                <div :class="$style['element-title']">(Hashed) Server Seed</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ modalGetHashServer }}</span>
                    <button
                        :class="$style['content-button']"
                        class="octagonal before after hoverable"
                        v-on:click="modalCopyButton(modalGetHashServer)"
                    >
                        <span>Copy</span>
                    </button>
                </div>
            </div>
            <div v-if="modalGetNonce" :class="$style['content-element']">
                <div :class="$style['element-title']">Nonce</div>
                <div :class="$style['element-content']" class="octagonal before after">
                    <span>{{ modalGetNonce }}</span>
                </div>
            </div>
            <hr />
            <div :class="$style['content-breakdown']">
                <div :class="$style['breakdown-title']">Breakdown</div>
                <div :class="$style['breakdown-code']" class="octagonal before">
                    <pre
                        v-bind:key="(prismLoaded ? 'loaded-' : '') + modalGetBreakdownCode.length"
                        class="language-javascript"
                    ><code class="language-javascript">{{ modalGetBreakdownCode }}</code></pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { gamesBreakdown } from '@/utils/fair';
    import '@/assets/css/prism.css';

    export default {
        name: 'ModalFairGame',
        data() {
            return {
                prismLoaded: false,
                provablyFair: false,
                dropdownOpen: false,
                modalClientSeed: null
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'userGetSeedSocket', 'userSendSeedSocket']),
            modalCopyButton(value) {
                const el = document.createElement('textarea');
                el.value = value;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                this.notificationShow({
                    type: 'success',
                    message: 'Successfully copied to your clipboard.'
                });
            },
            modalCycleButton() {
                const data = { seedClient: this.modalClientSeed.trim() };
                this.userSendSeedSocket(data);
            },
            modalTabSwitch(value) {
                if (this.modalsData?.game?.fair === undefined) {
                    this.provablyFair = true;
                } else {
                    this.provablyFair = value;
                }
                this.dropdownOpen = false;
            }
        },
        computed: {
            ...mapGetters(['modalsData', 'socketSendLoading', 'userSeedData']),
            modalGetClientSeed() {
                return this.modalsData?.game?.fair?.seed?.seedClient;
            },
            modalGetHashServer() {
                let hash = '';

                if (this.modalsData?.game?.fair?.seed !== undefined) {
                    hash = this.modalsData?.game?.fair?.seed.hash;
                } else {
                    hash = this.modalsData?.game?.fair?.hash;
                }

                return hash;
            },
            modalGetNonce() {
                return this.modalsData?.game?.fair?.nonce;
            },
            modalGetSeedServer() {
                let seed = '';

                if (
                    this.modalsData?.game?.fair?.seed !== undefined &&
                    this.modalsData?.game?.fair?.seed.seedServer !== undefined
                ) {
                    seed = this.modalsData?.game?.fair?.seed.seedServer;
                } else if (this.modalsData?.game?.fair?.seedServer !== undefined) {
                    seed = this.modalsData?.game?.fair?.seedServer;
                }

                return seed || this.modalGetHashServer;
            },
            modalGetSeedPublic() {
                let seed = '';

                if (this.modalsData?.game?.fair?.seedPublic !== undefined) {
                    seed = this.modalsData?.game?.fair?.seedPublic;
                }

                return seed;
            },
            modalGetBreakdownCode() {
                let params = [];

                if (this.modalsData.type === 'battles')
                    params = [
                        this.modalsData?.game?._id,
                        this.modalGetSeedServer,
                        this.modalGetSeedPublic,
                        2,
                        2
                    ];
                else if (this.modalsData.type === 'blackjack')
                    params = [
                        this.modalsData?.game?._id,
                        this.modalGetSeedServer,
                        this.modalGetSeedPublic
                    ];
                else if (this.modalsData.type === 'crash')
                    params = [this.modalsData?.game?._id, this.modalGetSeedServer];
                else if (this.modalsData.type === 'duels')
                    params = [
                        this.modalsData?.game?._id,
                        this.modalGetSeedServer,
                        this.modalGetSeedPublic,
                        2
                    ];
                else if (this.modalsData.type === 'mines')
                    params = [
                        this.modalGetClientSeed,
                        this.modalGetSeedServer,
                        this.modalGetNonce,
                        1
                    ];
                else if (this.modalsData.type === 'roll')
                    params = [
                        this.modalsData?.game?._id,
                        this.modalGetSeedServer,
                        this.modalGetSeedPublic
                    ];
                else if (this.modalsData.type === 'towers')
                    params = [
                        this.modalGetClientSeed,
                        this.modalGetSeedServer,
                        this.modalGetNonce,
                        'hard'
                    ];
                else if (this.modalsData.type === 'unbox')
                    params = [this.modalGetClientSeed, this.modalGetSeedServer, this.modalGetNonce];

                return (
                    (gamesBreakdown[this.modalsData.type] !== undefined
                        ? gamesBreakdown[this.modalsData.type](...params)
                        : '') || ''
                );
            }
        },
        created() {
            const script = document.createElement('script');
            script.src = '/js/prism.js';
            script.id = 'prism';
            script.onload = () => {
                this.prismLoaded = true;
            };
            document.head.appendChild(script);

            if (this.modalsData?.game?.fair === undefined) {
                this.provablyFair = true;
            }

            if (this.userSeedData.loading === false) {
                if (this.userSeedData.data === null) {
                    const data = {};
                    this.userGetSeedSocket(data);
                } else {
                    this.modalClientSeed = this.userSeedData.data.seedNext.seedClient;
                }
            }

            document.addEventListener('click', (event) => {
                if (
                    this.$refs.dropdown &&
                    !this.$refs.dropdown.contains(event.target) &&
                    this.dropdownOpen === true
                ) {
                    this.dropdownOpen = false;
                }
            });
        },
        destroyed() {
            const script = document.getElementById('prism');
            if (script) {
                script.remove(); // Removes the script element
            }
        },
        watch: {
            userSeedData: {
                deep: true,
                handler() {
                    if (this.userSeedData.loading === false && this.userSeedData.data !== null) {
                        this.modalClientSeed = this.userSeedData.data.seedNext.seedClient;
                    }
                }
            }
        }
    };
</script>

<style module>
    .modal-fair-game {
        width: 838px;
        max-width: (100vw - 20px);
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 30px 30px 30px 30px;
    }

    .dropdown-title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        gap: 7px;
        color: white;
        margin-bottom: 2px;
    }

    .game-dropdown {
        position: relative;
        width: fit-content;
        z-index: 2;
    }

    .dropdown-menu {
        position: absolute;
        left: -7px;
        top: calc(100% + 7px);
        min-width: calc(100% + 14px);
        transition: max-height 0.3s ease;
        max-height: 0vh;
        overflow: hidden;
    }

    .game-dropdown.open .dropdown-menu {
        font-size: 7px;
        clip-path: var(--octagonal-main);
        max-height: 55vh;
    }

    .menu-container {
        width: 100%;
        height: 100%;
    }

    .menu-content {
        display: flex;
        flex-direction: column;
        background: #0e293e;
        padding: 7px 7px 8px 7px;
        gap: 7px;
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .menu-content::before {
        background: #1f3d56;
    }

    .content-item {
        filter: drop-shadow(0px 2px 0px #0f2133);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 10px;
        color: #6a8ba6;
        gap: 5px;
    }

    .content-item::after {
        font-size: 3px;
        background: #12283e;
    }

    .content-item::before {
        font-size: 3px;
        background: #203e59;
    }

    .seed-content,
    .game-content {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .modal-fair-game .game-content {
        display: none;
    }

    .modal-fair-game.verification .game-content {
        display: flex;
    }

    .modal-fair-game.verification .seed-content {
        display: none;
    }

    hr {
        border-color: #0a2f4d;
    }

    .element-title {
        font-size: 14px;
        color: #5b84ae;
        font-weight: 600;
        margin-bottom: 7px;
    }

    .element-content {
        color: white;
        font-size: 15px;
        padding: 13.5px 22px;
        display: flex;
        gap: 7px;
        justify-content: space-between;
        z-index: 1;
    }

    .element-content > span {
        margin: auto 0;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .element-content::before {
        font-size: 5px;
        background: #1e4b7e59;
    }

    .element-content::after {
        font-size: 5px;
        background: linear-gradient(45deg, #0f2840, #0e243a);
    }

    .content-title {
        font-weight: 600;
        font-size: 18px;
        color: white;
    }

    .content-button {
        color: #5b85ae;
        font-size: 14px;
        font-weight: 600;
        padding: 6px 18px;
        z-index: 1;
    }

    .content-button::after {
        background: #163551;
        font-size: 2px;
    }

    .content-button::before {
        background: #1d3c5f;
        font-size: 2px;
    }

    .breakdown-title {
        font-size: 18px;
        font-weight: 600;
        color: white;
        margin-bottom: 14px;
    }

    .content-breakdown pre {
        background: none;
        margin-top: -1.1em;
        font-size: 15px;
    }

    .breakdown-code {
        background: linear-gradient(0deg, #0e263d, #0d243b);
        overflow: hidden;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .breakdown-code::before {
        background: #1e4b7e59;
    }
</style>
