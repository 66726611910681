<template>
    <div :class="$style['blackjack-overview']">
        <div
            v-for="item in ['standard', 'whale']"
            v-bind:key="item"
            :class="[$style['overview-list'], $style[item]]"
        >
            <div :class="$style['list-header']">
                <p :class="$style['header-title']">
                    <span :class="'fade-' + (item !== 'standard' ? 'gold' : 'green')">
                        {{ item.toUpperCase() }} TABLES
                    </span>
                </p>
                <div :class="$style['header-range']">
                    <p :class="$style['range-amount']">
                        <img
                            v-if="userBalanceData.type === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="userBalanceData.type === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                        {{ item === 'standard' ? '25' : '2,500' }}<span>.00</span>
                    </p>
                    <p :class="$style['range-dash']">-</p>
                    <p :class="$style['range-amount']">
                        <img
                            v-if="userBalanceData.type === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="userBalanceData.type === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                        {{ item === 'standard' ? '15,000' : '35,000' }}<span>.00</span>
                    </p>
                </div>
            </div>
            <hr :class="$style['list-divider']" />
            <transition name="fade" mode="out-in">
                <div
                    v-if="socketBlackjack.connected === false"
                    :class="$style['content-list']"
                    key="loading"
                >
                    <div
                        v-for="huh in item === 'standard' ? 8 : 4"
                        v-bind:key="huh"
                        :class="$style['loading-placeholder']"
                    ></div>
                </div>
                <div v-else :class="$style['content-list']" key="data">
                    <BlackjackTablesElement
                        v-for="table of blackjackGetTables[item]"
                        v-bind:key="table.table"
                        v-bind:table="table"
                    />
                </div>
            </transition>
        </div>
        <Bets />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Bets from '@/components/bets/Bets';
    import IconTables from '@/components/icons/IconTables';
    import IconUserGradient from '@/components/icons/IconUserGradient';
    import IconWhaleGradient from '@/components/icons/IconWhaleGradient';
    import BlackjackTablesElement from '@/components/blackjack/BlackjackTablesElement';

    export default {
        name: 'BlackjackOverview',
        components: {
            Bets,
            IconTables,
            IconUserGradient,
            IconWhaleGradient,
            BlackjackTablesElement
        },
        computed: {
            ...mapGetters(['socketBlackjack', 'blackjackTables', 'userBalanceData']),
            blackjackGetTables() {
                let tables = { standard: [], whale: [] };

                for (const table of this.blackjackTables) {
                    if (table.game.type === 'standard') {
                        tables.standard.push(table);
                    } else {
                        tables.whale.push(table);
                    }
                }

                return tables;
            }
        }
    };
</script>

<style module>
    .blackjack-overview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 10px;
    }

    .blackjack-overview .overview-list {
        max-width: 1153px;
        width: 100%;
    }

    .blackjack-overview .header-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 7px;
    }

    .blackjack-overview .header-range {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 184px;
        padding: 0px 14px;
        position: relative;
        gap: 5px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 28.53%,
            rgba(30, 255, 194, 0.35) 91.03%
        );
        clip-path: var(--octagonal-main);
        font-size: 3px;
        height: 26px;
        width: fit-content;
    }

    .blackjack-overview .header-range::before {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.71deg,
                rgba(0, 0, 0, 0.128) -3.28%,
                rgba(0, 33, 24, 0.132) 21.81%,
                rgba(0, 99, 73, 0.144986) 61.24%,
                rgba(1, 193, 143, 0.0925409) 80.82%,
                rgba(1, 237, 176, 0.068) 99.26%
            );
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-after);
        z-index: -1;
        font-size: 3px;
    }

    .blackjack-overview .whale .header-range {
        background: linear-gradient(
            180deg,
            rgba(6, 36, 61, 0) 17.86%,
            rgba(235, 184, 55, 0.74) 100%
        );
    }

    .blackjack-overview .whale .header-range::before {
        background: linear-gradient(0deg, #293131, #2a383d, #273429);
    }

    .blackjack-overview .range-amount {
        font-size: 14px;
        font-weight: 600;
        color: white;
        position: relative;
    }

    .blackjack-overview .range-dash {
        font-size: 14px;
        color: white;
    }

    .blackjack-overview .range-amount span {
        font-size: 10px;
        font-weight: 500;
        opacity: 0.75;
    }

    .blackjack-overview .range-amount img {
        width: 12px;
        height: 16px;
        margin-right: 3px;
        margin-bottom: -3px;
    }

    .blackjack-overview .list-divider {
        border: unset;
        height: 1px;
        margin: 20px 0;
        background: linear-gradient(
            90deg,
            rgba(97, 112, 241, 0.15) 0%,
            rgba(28, 71, 182, 0.15) 100%
        );
    }

    .blackjack-overview .content-list {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-bottom: 30px;
        gap: 7px;
    }

    .blackjack-overview .loading-placeholder {
        aspect-ratio: 283 / 167;
        width: 100%;
        font-size: 20px;
        clip-path: var(--octagonal-main);
        position: relative;
        background: radial-gradient(285% 150% at 50% 50%, #001323 0%, #000e1a 100%);
    }

    .blackjack-overview .loading-placeholder::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(
            to right,
            #ffffff00 0%,
            rgba(255, 255, 255, 0.1) 50%,
            #ffffff00 100%
        );
    }

    @keyframes loading_animation {
        0% {
            transform: translateX(-100%);
        }
        50% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(100%);
        }
    }

    @media screen and (max-width: 800px) {
        .blackjack-overview .content-list {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: 550px) {
        .blackjack-overview .content-list {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
</style>
