<template>
    <div :class="$style['roll-user-bets-element']">
        <div :class="$style['element-info']">
            <div :class="$style['info-item']">
                <span>BET:</span>
                <div :class="$style['item-box']">
                    <img
                        v-bind:src="
                            require(`@/assets/img/icons/${bet.coinType || 'cryptoCoin'}.svg`)
                        "
                        width="11em"
                        alt="icon"
                    />
                    <p :class="$style['box-value']">
                        {{ Math.floor(bet.amount / 1000)
                        }}<span>{{
                            (bet.amount / 1000)
                                .toFixed(2)
                                .slice(-3)
                                .replace('.00', '')
                                .replace(/(?<=[.]\d)[0]/, '')
                        }}</span>
                    </p>
                </div>
                <div :class="$style['item-box']" class="multiplier">
                    <p>
                        {{
                            parseFloat(bet.multiplier / 100)
                                .toFixed(2)
                                .replace('.00', '')
                                .replace(/(?<=[.]\d)[0]/, '')
                        }}x
                    </p>
                </div>
            </div>
            <div :class="$style['info-item']">
                <span>PAYOUT:</span>
                <div :class="$style['item-box']">
                    <img
                        v-bind:src="
                            require(`@/assets/img/icons/${bet.coinType || 'cryptoCoin'}.svg`)
                        "
                        width="11em"
                        alt="icon"
                    />
                    <p :class="$style['box-value']">
                        {{ Math.floor((bet.amount / 1000) * (bet.multiplier / 100))
                        }}<span>{{
                            ((bet.amount / 1000) * (bet.multiplier / 100))
                                .toFixed(2)
                                .slice(-3)
                                .replace('.00', '')
                                .replace(/(?<=[.]\d)[0]/, '')
                        }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div :class="$style['element-percentage']">
            <span
                :class="[
                    $style['percentage-amount'],
                    bet.coinType === 'robuxCoin' ? 'fade-gold' : 'fade-green'
                ]"
                >{{
                    parseFloat(9500 / bet.multiplier)
                        .toFixed(2)
                        .replace('.00', '')
                        .replace(/(?<=[.]\d)[0]/, '')
                }}%</span
            >
            <div
                :class="[
                    $style['percentage-progress'],
                    $style[bet.coinType === 'robuxCoin' ? 'gold' : 'green']
                ]"
                v-bind:style="{ height: 9500 / bet.multiplier + '%' }"
            ></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RollUserBetsElement',
        props: ['bet']
    };
</script>

<style module>
    .roll-user-bets-element {
        font-size: 10px;
        width: 100%;
        aspect-ratio: 295 / 116;
        position: relative;
        display: flex;
        padding: 0.9em;
        clip-path: var(--octagonal-main);
        background: #10283e;
        gap: 0.4em;
    }

    .roll-user-bets-element::before {
        background: #193957;
    }

    .roll-user-bets-element .element-info {
        display: flex;
        flex-direction: column;
        gap: 0.4em;
        flex: 1;
    }

    .roll-user-bets-element .info-item {
        background: #142f48;
        align-items: center;
        color: #406992;
        padding-left: 2em;
        display: flex;
        font-size: 0.5em;
        font-weight: 600;
        gap: 0.8em;
        flex: 1;
        clip-path: var(--octagonal-main);
    }

    .roll-user-bets-element .info-item > * {
        white-space: nowrap;
        font-size: 2.6em;
    }

    .roll-user-bets-element .item-box {
        align-items: center;
        background: linear-gradient(0deg, #1c4269, #173555);
        padding: 2em 8em;
        color: white;
        display: flex;
        font-size: 0.2em;
        clip-path: var(--octagonal-main);
        gap: 5em;
    }

    .roll-user-bets-element .item-box > * {
        font-size: 13em;
    }

    .roll-user-bets-element .item-box > img {
        margin-bottom: -0.2em;
    }

    .roll-user-bets-element .item-box.multiplier > * {
        white-space: nowrap;
        font-weight: 500;
        opacity: 0.4;
    }

    .roll-user-bets-element .element-percentage {
        position: relative;
        background: #142f48;
        font-size: 0.5em;
        clip-path: var(--octagonal-main);
        width: 15.6%;
    }

    .roll-user-bets-element .percentage-amount {
        position: absolute;
        font-weight: 500;
        font-size: 2.6em;
        top: 50%;
        left: -0.25em;
        text-align: center;
        transform-origin: 50% 50%;
        transform: translate(0%, -50%) rotate(-90deg);
        z-index: 2;
    }

    .roll-user-bets-element .percentage-progress {
        background: #1b5455;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
    }

    .roll-user-bets-element .percentage-progress.gold {
        background: #525224;
    }

    .roll-user-bets-element::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-before);
    }
</style>
