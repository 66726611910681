<template>
    <div
        v-bind:class="[
            $style['blackjack-card'],
            {
                [$style['card-hidden']]: card.rank === 'hidden',
                [$style['card-won']]: state === 'won',
                [$style['card-lose']]: state === 'lose',
                [$style.green]: coinType === 'cryptoCoin',
                [$style.gold]: coinType === 'robuxCoin'
            },
            $style['card-' + card.suit]
        ]"
    >
        <div :class="$style['card-inner']">
            <div :class="$style['inner-front']" v-if="card.rank !== 'hidden'">
                <img
                    v-bind:src="
                        require(
                            '@/assets/img/blackjack/cards/' +
                                card.rank.toLowerCase() +
                                '_' +
                                card.suit +
                                '.png'
                        )
                    "
                    v-bind:alt="card.rank + '-' + card.suit"
                />
            </div>
            <div :class="$style['inner-back']">
                <img src="@/assets/img/blackjack/cards/back.png" alt="card" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BlackjackCard',
        props: ['card', 'state', 'coinType']
    };
</script>

<style module>
    .blackjack-card {
        width: 60px;
        height: 84px;
        position: absolute;
        bottom: 0;
        left: 0;
        perspective: 1000px;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
    }

    div:global(.l) > .blackjack-card,
    div:global(.r) > .blackjack-card {
        width: 53px;
        height: 74px;
    }

    div:global(.d) > .blackjack-card {
        top: 0;
        bottom: inherit;
    }

    .blackjack-card:nth-child(2) {
        bottom: 15px;
        left: 20px;
    }

    div:global(.d) > .blackjack-card:nth-child(2) {
        top: 15px;
        bottom: inherit;
    }

    .blackjack-card:nth-child(3) {
        bottom: 30px;
        left: 40px;
    }

    div:global(.d) > .blackjack-card:nth-child(3) {
        top: 30px;
        bottom: inherit;
    }

    .blackjack-card:nth-child(4) {
        bottom: 45px;
        left: 60px;
    }

    div:global(.d) > .blackjack-card:nth-child(4) {
        top: 45px;
        bottom: inherit;
    }

    .blackjack-card:nth-child(5) {
        bottom: 60px;
        left: 80px;
    }

    div:global(.d) > .blackjack-card:nth-child(5) {
        top: 60px;
        bottom: inherit;
    }

    .blackjack-card:nth-child(6) {
        bottom: 75px;
        left: 100px;
    }

    div:global(.d) > .blackjack-card:nth-child(6) {
        top: 75px;
        bottom: inherit;
    }

    .blackjack-card.card-club,
    .blackjack-card.card-spade {
        color: #000000;
    }

    .blackjack-card.card-heart,
    .blackjack-card.card-diamond {
        color: #db3d2e;
    }

    .blackjack-card .card-inner {
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        animation: dealcard-dealer 0.6s linear forwards;
    }

    .blackjack-card.card-hidden .card-inner {
        transform: rotateY(-180deg);
        animation: none;
    }

    .blackjack-card .inner-front,
    .blackjack-card .inner-back {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .blackjack-card .inner-front {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #f0f0f0;
        transition: all 0.3s;
        font-size: 2px;
        clip-path: var(--octagonal-main);
    }

    .blackjack-card .inner-front::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        transition: all 0.3s;
        background: transparent;
        clip-path: var(--octagonal-before);
    }

    .blackjack-card.card-won .inner-front::before {
        background: linear-gradient(0deg, #1effc2, transparent);
    }

    .blackjack-card.gold.card-won .inner-front::before {
        background: linear-gradient(0deg, #fff01e, transparent);
    }

    .blackjack-card.card-lose .inner-front::before {
        background: linear-gradient(0deg, #ff5787, transparent);
    }

    .blackjack-card.card-hidden .inner-front {
        display: none;
    }

    .blackjack-card .inner-front img {
        height: 100%;
        transition: all 0.3s ease;
    }

    .blackjack-card.card-lose .inner-front img {
        filter: brightness(0.6);
    }

    .blackjack-card .inner-back {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotateY(-180deg);
        background: #19323f;
        font-size: 2px;
        clip-path: var(--octagonal-main);
    }

    .blackjack-card .inner-back img {
        height: 100%;
    }

    .blackjack-card:nth-child(1)::before {
        content: '';
        background-image: url('~@/assets/img/blackjack/winning-orb.png');
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        transform: translateY(25%);
        transition: all 0.3s ease;
        opacity: 0;
        left: -51%;
        top: 18%;
        width: 147px;
        height: 121px;
    }

    .blackjack-card.gold:nth-child(1)::before {
        background-image: url('~@/assets/img/blackjack/winning-orb-01.png');
    }

    .blackjack-card.card-won:nth-child(1)::before {
        transform: translateY(0%);
        opacity: 1;
    }

    @keyframes dealcard-dealer {
        0% {
            transform: rotateY(-180deg);
        }
        50% {
            transform: rotateY(-180deg);
        }
        100% {
            transform: rotateY(0deg);
        }
    }
</style>
