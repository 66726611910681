var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:[
        _vm.$style.navbar,
        { [_vm.$style.auth]: _vm.authUser.user !== null, a: _vm.authUser.user !== null }
    ]},[_c('div',{class:_vm.$style['navbar-content']},[_c('router-link',{class:_vm.$style['link-logo'],attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":"Rollbet","height":"34"}}),_c('img',{attrs:{"src":"/chip.png","alt":"Rollbet","height":"34"}})]),_c('NavbarHomeDropdown'),(_vm.authUser.user !== null)?_c('NavbarRewards'):_vm._e(),(_vm.authUser.user !== null)?_c('NavbarCashier'):_vm._e(),(_vm.authUser.user !== null)?_c('NavbarNotifications'):_c('AuthButton'),_c('button',{staticClass:"octagonal before after hoverable",class:[
                _vm.$style['content-chat'],
                { [_vm.$style.active]: _vm.generalSidebarMobile === 'Chat' }
            ],on:{"click":function($event){return _vm.generalSetSidebarMobile(_vm.generalSidebarMobile === 'Chat' ? null : 'Chat')}}},[_c('svg',{attrs:{"stroke":"currentColor","fill":"currentColor","stroke-width":"0","viewBox":"0 0 16 16","height":"1em","width":"1em","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm7.194 2.766a1.7 1.7 0 0 0-.227-.272 1.5 1.5 0 0 0-.469-.324l-.008-.004A1.8 1.8 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.5 2.5 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.5 2.5 0 0 0-.228-.4 1.7 1.7 0 0 0-.227-.273 1.5 1.5 0 0 0-.469-.324l-.008-.004A1.8 1.8 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"}})]),(_vm.generalRain.active !== null)?_c('p',{class:_vm.$style['chat-alert']},[_vm._v("!")]):_vm._e()]),(_vm.authUser.user !== null)?_c('NavbarUserDropdown'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }