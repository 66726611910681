<template>
    <router-link
        class="octagonal before after hoverable"
        :class="$style['navbar-rewards']"
        to="/rewards"
    >
        <img src="@/assets/img/rewards-link-element.png" alt="" height="56" />
        <p>
            <!-- <span v-if="rakebackAlerts" class="rewards-alert">
                {{ rakebackAlerts }}
            </span> -->
            CLAIM YOUR
            <br />
            <span class="fade-gold">REWARDS</span>
            <!-- <span>LEADERBOARD</span>
            <br />
            <span class="fade-gold">$1,000.00</span> -->
        </p>
    </router-link>
</template>

<script>
    // import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'NavbarRewards'
        // methods: {
        //     ...mapActions(['rakebackGetDataSocket'])
        // },
        // computed: {
        //     ...mapGetters(['generalSidebarMobile', 'rakebackData', 'authUser', 'userBalanceData']),
        //     rakebackAlerts() {
        //         let count = 0;
        //         if (this.authUser?.user?.rakeback?.[this.userBalanceData.type]?.available)
        //             count += 1;
        //         if (this.rakebackData?.boxes?.length) count += this.rakebackData?.boxes?.length;
        //         return count;
        //     }
        // },
        // created() {
        //     if (this.rakebackData.loading === false) {
        //         const data = {};
        //         this.rakebackGetDataSocket(data);
        //     }
        // }
    };
</script>

<style module>
    .navbar-rewards {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        color: white;
        min-width: 155px;
        padding-left: 50px;
    }

    .navbar-rewards p {
        position: relative;
        font-size: 12.5px;
        text-align: center;
        line-height: 12px;
        font-weight: 600;
    }

    /* .navbar-rewards {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        color: white;
        min-width: 155px;
        padding-left: 50px;
    }

    .navbar-rewards p {
        position: relative;
        font-size: 12.5px;
        text-align: center;
        line-height: 12px;
        font-weight: 600;
        padding: 0 14px 0 18px;
    } */

    .navbar-rewards img {
        position: absolute;
        bottom: 1px;
        clip-path: var(--octagonal-main);
        font-size: 4px;
        left: 2px;
    }

    .navbar-rewards .rewards-alert {
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0px 4.5px;
        border-radius: 8px;
        font-weight: 700;
        font-size: 12px;
        color: white;
        display: flex;
        height: 17px;
        right: -14px;
        z-index: 1;
        top: -6px;
    }

    .navbar-rewards::after {
        font-size: 3px;
        background: #03111d;
        background-image: url('~@/assets/img/rewards-link-background.png');
        background-size: cover;
    }

    .navbar-rewards::before {
        font-size: 3px;
        background: linear-gradient(15deg, #f9d4a1, #eea86a);
    }

    @media screen and (max-width: 875px) {
        nav > * > .navbar-rewards {
            display: none;
        }
    }
</style>
