<template>
    <img
        v-bind:class="[
            $style['avatar-image'],
            $style[avatarGetLevelColor],
            $style[avatarRank],
            { [$style.vip]: avatarGetLevel > 90 },
            { [$style.tilt]: tiltBorder },
            { [$style.anonymous]: image === 'anonymous' }
        ]"
        v-bind:src="avatarImage"
        @error="avatarImageError()"
    />
</template>

<script>
    import { getColorFromLevel } from '@/utils/user';

    export default {
        name: 'AvatarImage',
        props: ['image', 'rank', 'xp', 'level', 'tiltBorder'],
        data() {
            return {
                avatarImage: null,
                avatarRank: this.rank
            };
        },
        methods: {
            avatarImageError() {
                this.avatarImage = require('@/assets/img/bob.png');
            }
        },
        computed: {
            avatarGetLevel() {
                let level = this.level || Math.floor(Math.pow(this.xp / 1000 / 100, 1 / 3));
                return level;
            },
            avatarGetLevelColor() {
                return getColorFromLevel(this.avatarGetLevel);
            }
        },
        watch: {
            image: {
                immediate: true,
                deep: true,
                handler() {
                    this.avatarImage =
                        this.image === 'blank'
                            ? null
                            : this.image === 'anonymous'
                              ? require('@/assets/img/anon.png')
                              : this.image === undefined || this.image === null
                                ? require('@/assets/img/bob.png')
                                : this.image;
                }
            }
        }
    };
</script>

<style module>
    .avatar-image {
        border-radius: 100%;
        overflow: hidden;
        aspect-ratio: 1;
        border-radius: 100%;
        position: relative;
        border: 3px solid transparent;
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #9e9e9e, #9e9e9e 69%) border-box;
    }

    .avatar-image.blue {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #559ee4, #081b2c 69%) border-box;
    }

    .avatar-image.sky {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #01bcf9, #081b2c 69%) border-box;
    }

    .avatar-image.yellow {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #f9c201, #081b2c 69%) border-box;
    }

    .avatar-image.purple {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #6562ff, #081b2c 69%) border-box;
    }

    .avatar-image.gold,
    .avatar-image.vip {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #ffe600, #fff195 26%, #ffbe18) border-box;
    }

    .avatar-image.mod {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #498fdd, #081b2c 69%) border-box;
    }

    .avatar-image.partner {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #d64548, #081b2c 69%) border-box;
    }

    .avatar-image.admin {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(0deg, #0dd4b1, #081b2c 69%) border-box;
    }

    .avatar-image.anonymous {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #5b84ae, #5b84ae 69%) border-box;
    }

    .avatar-image.tilt.blue {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #559ee4, #081b2c 69%) border-box;
    }

    .avatar-image.tilt.sky {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #01bcf9, #081b2c 69%) border-box;
    }

    .avatar-image.tilt.yellow {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #f9c201, #081b2c 69%) border-box;
    }

    .avatar-image.tilt.purple {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #6562ff, #081b2c 69%) border-box;
    }

    .avatar-image.tilt.gold,
    .avatar-image.tilt.vip {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #ffe600, #fff195 26%, #ffbe18) border-box;
    }

    .avatar-image.tilt.mod {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #498fdd, #081b2c 69%) border-box;
    }

    .avatar-image.tilt.partner {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #d64548, #081b2c 69%) border-box;
    }

    .avatar-image.tilt.admin {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #0dd4b1, #081b2c 69%) border-box;
    }

    .avatar-image.anonymous {
        background:
            linear-gradient(#081b2c, #081b2c) padding-box,
            linear-gradient(125deg, #5b84ae, #5b84ae 69%) border-box;
    }
    /*     
    .avatar-image.undefined {
        border: none;
        background: #081b2c;
    } */
</style>
