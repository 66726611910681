<template>
    <div
        :class="[
            $style.notifications,
            { [$style.restricted]: modalsShow, [$style.chat]: generalSidebarMobile === 'Chat' }
        ]"
    >
        <transition-group name="slide">
            <NotificationsElement
                v-for="notification in notifications"
                v-bind:key="notification.id"
                v-bind:notification="notification"
            />
        </transition-group>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import NotificationsElement from '@/components/notifications/NotificationsElement';

    export default {
        name: 'Notification',
        components: {
            NotificationsElement
        },
        computed: {
            ...mapGetters(['generalSidebarMobile', 'notifications', 'modalsShow'])
        }
    };
</script>

<style scoped>
    .slide-enter-active,
    .slide-leave-active {
        transition: all 0.4s;
    }

    .slide-enter,
    .slide-leave-to {
        opacity: 0;
        transform: translate(50px, 0);
    }
</style>

<style module>
    .notifications {
        position: fixed;
        z-index: 103;
        top: calc(90px + 14px);
        right: 14px;
        transition: all 0.3s ease;
    }

    @media screen and (min-width: 1501px) {
        .notifications {
            top: calc(90px + 14px);
            right: calc(325px + 14px);
        }
    }

    @media screen and (max-width: 1500px) {
        .notifications.chat {
            top: calc(90px + 14px);
            right: calc(325px + 14px);
        }
    }

    @media screen and (max-width: 1175px) {
        .notifications.chat,
        .notifications {
            top: calc(70px + 14px);
        }
    }

    @media screen and (max-width: 621px) {
        .notifications.chat {
            right: unset;
            left: 14px;
            top: 14px;
        }
    }

    .notifications.restricted.chat,
    .notifications.restricted {
        top: 14px;
        right: 14px;
    }
</style>
