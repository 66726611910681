var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['cashier-selected-element']},[_c('div',{class:_vm.$style['element-content']},[_c('div',{class:_vm.$style['content-item']},[_c('div',{class:_vm.$style['item-image']},[_c('span',{class:{ 'fade-gold': _vm.cashierGetDiscount < 0 }},[_vm._v(" "+_vm._s(_vm.cashierGetDiscount < 0 ? '-' : '+')+_vm._s(Math.abs(_vm.cashierGetDiscount))+"% ")]),_c('img',{attrs:{"src":_vm.selected.image}})]),_c('div',{class:_vm.$style['item-info']},[_c('p',{class:_vm.$style['info-name']},[_c('span',[_vm._v(" "+_vm._s(_vm.selected.name)+" ")])]),_c('div',{class:[
                        _vm.$style['info-amount'],
                        _vm.$style[
                            _vm.userBalanceData.type === 'robuxCoin' ||
                            _vm.cashierGetRouteName === 'LimitedsDeposit'
                                ? 'robuxCoin'
                                : 'cryptoCoin'
                        ]
                    ]},[(
                            _vm.userBalanceData.type === 'robuxCoin' ||
                            _vm.cashierGetRouteName === 'LimitedsDeposit'
                        )?_c('img',{attrs:{"width":"15","src":require("@/assets/img/icons/robuxCoin.svg")}}):_c('img',{attrs:{"width":"11","src":require("@/assets/img/icons/cryptoCoin.svg")}}),(_vm.selected.amountFixed !== undefined)?_c('p',[_vm._v(" "+_vm._s(Math.floor(_vm.selected.amountFixed / 1000).toLocaleString())+" "),_c('span',[_vm._v(_vm._s((_vm.selected.amountFixed / 1000).toFixed(2).slice(-3)))])]):(_vm.selected.amount !== undefined)?_c('p',[_vm._v(" "+_vm._s(Math.floor( (_vm.selected.amount + _vm.selected.amount * (_vm.cashierGetDiscount / 100)) / 1000 ).toLocaleString())+" "),_c('span',[_vm._v(" "+_vm._s(( (_vm.selected.amount + _vm.selected.amount * (_vm.cashierGetDiscount / 100)) / 1000 ) .toFixed(2) .slice(-3))+" ")])]):_vm._e()])]),_c('button',{staticClass:"octagonal hoverable",class:_vm.$style['item-remove'],on:{"click":function($event){return _vm.cashierRemoveLimitedDataSelected(_vm.mainSelected || _vm.selected)}}},[_c('svg',{attrs:{"width":"9","height":"9","viewBox":"0 0 9 9","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.32453 4.49997L8.82895 0.995447C9.05701 0.767492 9.05701 0.398921 8.82895 0.170966C8.601 -0.0569887 8.23242 -0.0569887 8.00447 0.170966L4.49994 3.67549L0.995527 0.170966C0.767466 -0.0569887 0.399001 -0.0569887 0.171046 0.170966C-0.0570154 0.398921 -0.0570154 0.767492 0.171046 0.995447L3.67546 4.49997L0.171046 8.0045C-0.0570154 8.23245 -0.0570154 8.60102 0.171046 8.82898C0.28465 8.94269 0.434022 8.99981 0.583287 8.99981C0.732552 8.99981 0.881817 8.94269 0.995527 8.82898L4.49994 5.32445L8.00447 8.82898C8.11818 8.94269 8.26744 8.99981 8.41671 8.99981C8.56597 8.99981 8.71524 8.94269 8.82895 8.82898C9.05701 8.60102 9.05701 8.23245 8.82895 8.0045L5.32453 4.49997Z","fill":"#5B84AE"}})])])]),(_vm.cashierGetRouteName === 'LimitedsDeposit' && _vm.selected.items === undefined)?_c('div',{class:[
                _vm.$style['content-range'],
                { [_vm.$style.less]: _vm.cashierGetDiscount < 0 },
                _vm.$style[
                    _vm.userBalanceData.type === 'robuxCoin' ||
                    _vm.cashierGetRouteName === 'LimitedsDeposit'
                        ? 'robuxCoin'
                        : 'cryptoCoin'
                ]
            ]},[_c('div',{class:_vm.$style['range-fill'],style:({ width: Math.abs(_vm.discount - 50) + '%' })}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.discount),expression:"discount"}],attrs:{"type":"range","min":"0","max":"100","step":"1"},domProps:{"value":(_vm.discount)},on:{"input":_vm.cashierApplyDiscount,"__r":function($event){_vm.discount=$event.target.value}}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }