var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['modal-cashier']},[_c('div',{class:_vm.$style['cashier-header']},[_c('div',{class:_vm.$style['header-buttons']},[(['Deposit', 'Withdraw', 'Redeem'].includes(_vm.modalTab))?_c('button',{class:[
                    _vm.$style['button-nav'],
                    { [_vm.$style['button-active']]: _vm.modalTab === 'Deposit' }
                ],on:{"click":function($event){return _vm.modalSetTab('Deposit')}}},[_c('div',{class:_vm.$style['button-inner']},[_c('span',{class:{ 'fade-green': _vm.modalTab === 'Deposit' }},[_vm._v(" Deposit ")])])]):_c('button',{class:_vm.$style['button-nav'],on:{"click":function($event){return _vm.modalSetTab(_vm.modalsData.typeCashier === 'withdraw' ? 'Withdraw' : 'Deposit')}}},[_c('div',{class:_vm.$style['button-inner']},[(_vm.modalTab === 'Gift')?_c('svg',{class:_vm.$style.back,attrs:{"width":"10","height":"9","viewBox":"0 0 10 9","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.21864 3.60238C9.16544 3.59389 9.11161 3.58997 9.05775 3.59066L2.88651 3.59066L3.08649 3.49916C3.28196 3.40815 3.45979 3.28428 3.61202 3.13316L5.18387 1.58689C5.52258 1.26881 5.5795 0.757122 5.31874 0.374506C5.01525 -0.0332154 4.43324 -0.121742 4.01874 0.176807C3.98526 0.200941 3.95343 0.227247 3.92352 0.255555L0.272801 3.84686C-0.0906526 4.204 -0.0909723 4.78334 0.272075 5.14088L0.272801 5.1416L3.92352 8.73291C4.28726 9.08973 4.87619 9.08893 5.23895 8.7311C5.26749 8.70294 5.29415 8.67297 5.31874 8.64141C5.5795 8.25879 5.52258 7.7471 5.18387 7.42902L3.61667 5.87818C3.4802 5.74379 3.3233 5.6311 3.1516 5.5442L2.87255 5.42068L9.00656 5.42068C9.48172 5.43803 9.8986 5.11152 9.98787 4.65207C10.0701 4.15325 9.72571 3.68331 9.21864 3.60238Z","fill":"#406992"}})]):_vm._e(),_c('span',[_vm._v("Go back")])])]),(['Deposit', 'Withdraw', 'Redeem'].includes(_vm.modalTab))?_c('button',{class:[
                    _vm.$style['button-nav'],
                    _vm.$style['iconed'],
                    { [_vm.$style['button-active']]: _vm.modalTab === 'Withdraw' }
                ],on:{"click":function($event){return _vm.modalSetTab('Withdraw')}}},[_c('div',{class:_vm.$style['button-inner']},[_c('span',{class:{ 'fade-green': _vm.modalTab === 'Withdraw' }},[_vm._v(" Withdraw ")])])]):_c('button',{class:[_vm.$style['button-nav'], _vm.$style['button-active']]},[_c('div',{class:_vm.$style['button-inner']},[_c('img',{attrs:{"src":require(
                                `@/assets/img/cashier/${(function () {
                                    let image = 'robux-icon.png';

                                    switch (_vm.modalsData.currency || _vm.modalTab) {
                                        case 'Credit':
                                            image = 'credit.png';
                                            break;
                                        case 'Gift':
                                            image = 'kinguin.png';
                                            break;
                                        case 'btc':
                                        case 'eth':
                                        case 'ltc':
                                        case 'usdt':
                                        case 'sol':
                                        case 'usdc':
                                            image = `${_vm.modalsData.currency}.png`;
                                            break;
                                    }

                                    return image;
                                })()}`
                            )}}),_c('span',{staticClass:"fade-green"},[_vm._v(" "+_vm._s((function () { let title = _vm.modalTab; if (_vm.modalTab === 'Credit') return 'Credit Cards'; if (_vm.modalTab === 'Gift') return 'Gift Cards'; switch (_vm.modalsData.currency) { case 'btc': title = 'Bitcoin'; break; case 'eth': title = 'Ethereum'; break; case 'ltc': title = 'Litecoin'; break; case 'usdt': title = 'USDT (ERC20)'; break; case 'usdc': title = 'USDC (ERC20)'; break; case 'sol': title = 'Solana'; break; } return `${_vm.modalsData.typeCashier[0].toUpperCase()}${_vm.modalsData.typeCashier.slice(1)} ${title}`; })())+" ")])])]),(['Robux', 'Crypto', 'Proceed', 'Credit'].includes(_vm.modalTab) !== true)?_c('button',{class:[
                    _vm.$style['button-nav'],
                    { [_vm.$style['button-active']]: _vm.modalTab === 'Redeem' }
                ],on:{"click":function($event){return _vm.modalSetTab('Redeem')}}},[_c('div',{class:_vm.$style['button-inner']},[_c('span',{class:{ 'fade-green': _vm.modalTab === 'Redeem' }},[_vm._v(" Redeem ")])])]):_vm._e()])]),_c('Cashier' + _vm.modalTab + 'Tab',{tag:"component"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }