<template>
    <div class="admin-user-settings">
        <div class="settings-element element-toggle">
            <div class="element-name">RAIN LOCK</div>
            <button
                v-on:click="adminValueButton('limits.blockRain', !modalsData.user.limits.blockRain)"
                v-bind:class="{
                    'button-active': modalsData.user.limits.blockRain === true
                }"
                v-bind:disabled="socketSendLoading !== null"
            ></button>
        </div>
        <div class="settings-element element-toggle">
            <div class="element-name">TIP LOCK</div>
            <button
                v-on:click="adminValueButton('limits.blockTip', !modalsData.user.limits.blockTip)"
                v-bind:class="{
                    'button-active': modalsData.user.limits.blockTip === true
                }"
                v-bind:disabled="socketSendLoading !== null"
            ></button>
        </div>
        <div
            v-if="modalsData.user.limits.blockTip === true"
            class="settings-element element-sub element-number"
        >
            <div class="element-name">SET TIP LIMIT</div>
            <div class="element-input">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                <input v-model="adminLimitTip" type="text" />
                <button
                    v-on:click="
                        adminValueButton('limits.limitTip', Math.floor(adminLimitTip * 1000))
                    "
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-toggle">
            <div class="element-name">SPONSORSHIP LOCK</div>
            <button
                v-on:click="
                    adminValueButton('limits.blockSponsor', !modalsData.user.limits.blockSponsor)
                "
                v-bind:class="{
                    'button-active': modalsData.user.limits.blockSponsor === true
                }"
                v-bind:disabled="socketSendLoading !== null"
            ></button>
        </div>
        <div class="settings-element element-toggle">
            <div class="element-name">LEADERBOARD LOCK</div>
            <button
                v-on:click="
                    adminValueButton(
                        'limits.blockLeaderboard',
                        !modalsData.user.limits.blockLeaderboard
                    )
                "
                v-bind:class="{
                    'button-active': modalsData.user.limits.blockLeaderboard === true
                }"
                v-bind:disabled="socketSendLoading !== null"
            ></button>
        </div>
        <div class="settings-element element-toggle">
            <div class="element-name">WITHDRAW ENABLE LIMIT</div>
            <button
                v-on:click="
                    adminValueButton('limits.limitWithdraw', !modalsData.user.limits.limitWithdraw)
                "
                v-bind:class="{
                    'button-active': modalsData.user.limits.limitWithdraw === true
                }"
                v-bind:disabled="socketSendLoading !== null"
            ></button>
        </div>
        <div class="settings-element element-number" v-if="modalsData.user.limits.limitWithdraw">
            <div class="element-name">SET LIMIT TO WITHDRAW (in dollars this will be fixed)</div>
            <div class="element-input">
                <input v-model="adminLimitToWithdraw" type="text" />

                <button
                    v-on:click="adminValueButton('limits.limitToWithdraw', adminLimitToWithdraw)"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-button">
            <div class="element-name">CLEAR LEADERBOARD</div>
            <button v-on:click="adminValueButton('leaderboard.points', 0)">
                <div class="button-inner">CLEAR</div>
            </button>
        </div>
        <div class="settings-element element-text">
            <div class="element-name">USERNAME</div>
            <div class="element-input">
                <input v-model="adminUsername" type="text" />
                <button
                    v-on:click="adminValueButton('username', adminUsername)"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-select">
            <div class="element-name">SET ROBLOX AUTO-UPDATE</div>
            <div class="element-input">
                <select v-model="adminRobloxAutoUpdate">
                    <option value="none">NONE</option>
                    <option value="all">ALL</option>
                    <option value="username">USERNAME</option>
                    <option value="avatar">AVATAR</option>
                </select>
                <button
                    v-on:click="adminValueButton('robloxAutoUpdate', adminRobloxAutoUpdate)"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-select">
            <div class="element-name">SET RANK</div>
            <div class="element-input">
                <select v-model="adminRank">
                    <option value="user">USER</option>
                    <option value="partner">PARTNER</option>
                    <option value="mod">MOD</option>
                    <option value="admin">ADMIN</option>
                </select>
                <button
                    v-on:click="adminValueButton('rank', adminRank)"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-number">
            <div class="element-name">SET LEVEL</div>
            <div class="element-input">
                <input v-model="adminLevel" type="text" />
                <button
                    v-on:click="
                        adminValueButton('xp', Math.floor(1000 * 100 * Math.pow(adminLevel, 3)) + 1)
                    "
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-number">
            <div class="element-name">SET ROBUXCOIN BALANCE</div>
            <div class="element-input">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                <input v-model="adminBalanceRobuxCoin" type="text" />
                <button
                    v-on:click="adminBalanceButton('robuxCoin')"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>

        <div class="settings-element element-number">
            <div class="element-name">SET CRYPTOCOIN BALANCE</div>
            <div class="element-input">
                <img src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                <input v-model="adminBalanceCryptoCoin" type="text" />
                <button
                    v-on:click="adminBalanceButton('cryptoCoin')"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>

        <div class="settings-element element-number">
            <div class="element-name">SET ROBUXCOIN VAULT</div>
            <div class="element-input">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                <input v-model="adminVaultRobux" type="text" />
                <button
                    v-on:click="
                        adminValueButton('vault.robuxCoin', Math.floor(adminVaultRobux * 1000))
                    "
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>

        <div class="settings-element element-number">
            <div class="element-name">SET CRYPTOCOIN VAULT</div>
            <div class="element-input">
                <img src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                <input v-model="adminVaultCrypto" type="text" />
                <button
                    v-on:click="
                        adminValueButton('vault.cryptoCoin', Math.floor(adminVaultCrypto * 1000))
                    "
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-number">
            <div class="element-name">SET DEPOSIT</div>
            <div class="element-input">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                <input v-model="adminDeposit" type="text" />
                <button
                    v-on:click="adminValueButton('stats.deposit', Math.floor(adminDeposit * 1000))"
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-number">
            <div class="element-name">SET WITHDRAW</div>
            <div class="element-input">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                <input v-model="adminWithdraw" type="text" />
                <button
                    v-on:click="
                        adminValueButton('stats.withdraw', Math.floor(adminWithdraw * 1000))
                    "
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-number">
            <div class="element-name">SET BET NEEDED TO WITHDRAW</div>
            <div class="element-input">
                <img src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                <input v-model="adminBetWithdraw" type="text" />
                <button
                    v-on:click="
                        adminValueButton(
                            'limits.betToWithdraw',
                            Math.floor(adminBetWithdraw * 1000)
                        )
                    "
                    v-bind:disabled="socketSendLoading !== null"
                >
                    <div class="button-inner">UPDATE</div>
                </button>
            </div>
        </div>
        <div class="settings-element element-button">
            <div class="element-name">MUTE USER</div>
            <button v-on:click="adminMuteButton()" class="button-purple">
                <div class="button-inner">MUTE</div>
            </button>
        </div>
        <div class="settings-element element-button">
            <div class="element-name">BAN USER</div>
            <button v-on:click="adminBanButton()" class="button-purple">
                <div class="button-inner">BAN</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminUserSettings',
        data() {
            return {
                adminUsername: null,
                adminRobloxAutoUpdate: null,
                adminLimitTip: null,
                adminRank: null,
                adminBalance: null,
                adminVault: null,
                adminDeposit: null,
                adminWithdraw: null,
                adminLevel: null,
                adminBetWithdraw: null,
                adminLimitToWithdraw: null,
                adminBalanceRobuxCoin: null,
                adminBalanceCryptoCoin: null,
                adminVaultRobux: null,
                adminVaultCrypto: null
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'modalsSetShow',
                'adminSendUserValueSocket',
                'adminSendUserBalanceSocket',
                'generalSetUserInfoData'
            ]),
            adminValueButton(setting, value) {
                if (setting === 'limits.limitTip' && (isNaN(value) === true || value < 0)) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered tip limit is invalid.'
                    });
                    return;
                }

                if (setting === 'xp' && (isNaN(value) === true || value < 0)) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered user level is invalid.'
                    });
                    return;
                }

                const data = { userId: this.modalsData.user._id, setting: setting, value: value };
                this.adminSendUserValueSocket(data);
            },
            adminBalanceButton(type) {
                const balance =
                    type === 'robuxCoin'
                        ? Math.floor(this.adminBalanceRobuxCoin * 1000)
                        : Math.floor(this.adminBalanceCryptoCoin * 1000);

                if (balance === null || isNaN(balance) === true || balance < 0) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered user balance is invalid.'
                    });
                    return;
                }

                const data = { userId: this.modalsData.user._id, balance: balance, type: type };
                this.adminSendUserBalanceSocket(data);
            },
            adminMuteButton() {
                this.modalsSetShow(null);
                this.generalSetUserInfoData(this.modalsData.user);

                setTimeout(() => {
                    this.modalsSetShow('Mute');
                }, 300);
            },
            adminBanButton() {
                this.modalsSetShow(null);
                this.generalSetUserInfoData(this.modalsData.user);

                setTimeout(() => {
                    this.modalsSetShow('Ban');
                }, 300);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'modalsData'])
        },
        watch: {
            modalsData: {
                handler(data, oldData) {
                    this.adminUsername = this.modalsData.user.username;
                    this.adminRobloxAutoUpdate = this.modalsData.user.robloxAutoUpdate || 'all';
                    this.adminLimitTip = (
                        Math.floor(this.modalsData.user.limits.limitTip / 10) / 100
                    ).toFixed(2);
                    this.adminRank = this.modalsData.user.rank;
                    this.adminBalanceRobuxCoin = (
                        Math.floor(this.modalsData.user.balance.robuxCoin / 10) / 100
                    ).toFixed(2);
                    this.adminBalanceCryptoCoin = (
                        Math.floor(this.modalsData.user.balance.cryptoCoin / 10) / 100
                    ).toFixed(2);
                    this.adminVault = (
                        Math.floor(this.modalsData.user.vault.amount / 10) / 100
                    ).toFixed(2);

                    this.adminVaultRobux = (
                        Math.floor(this.modalsData.user.vault.robuxCoin / 10) / 100
                    ).toFixed(2);

                    this.adminVaultCrypto = (
                        Math.floor(this.modalsData.user.vault.cryptoCoin / 10) / 100
                    ).toFixed(2);

                    this.adminDeposit = (
                        Math.floor(this.modalsData.user.stats.deposit / 10) / 100
                    ).toFixed(2);
                    this.adminWithdraw = (
                        Math.floor(this.modalsData.user.stats.withdraw / 10) / 100
                    ).toFixed(2);

                    this.adminLevel = Math.floor(
                        Math.pow(this.modalsData.user.xp / 1000 / 100, 1 / 3)
                    );
                    this.adminBetWithdraw = (
                        Math.floor(this.modalsData.user.limits.betToWithdraw / 10) / 100
                    ).toFixed(2);

                    if (this.modalsData.user.limits.limitWithdraw === true) {
                        this.adminLimitToWithdraw = this.modalsData.user.limits.limitToWithdraw;
                    }
                },
                deep: true
            }
        },
        created() {
            this.adminUsername = this.modalsData.user.username;
            this.adminRobloxAutoUpdate = this.modalsData.user.robloxAutoUpdate || 'all';
            this.adminLimitTip = (
                Math.floor(this.modalsData.user.limits.limitTip / 10) / 100
            ).toFixed(2);
            this.adminRank = this.modalsData.user.rank;
            this.adminBalanceRobuxCoin = (
                Math.floor(this.modalsData.user.balance.robuxCoin / 10) / 100
            ).toFixed(2);
            this.adminBalanceCryptoCoin = (
                Math.floor(this.modalsData.user.balance.cryptoCoin / 10) / 100
            ).toFixed(2);
            this.adminVault = (Math.floor(this.modalsData.user.vault.amount / 10) / 100).toFixed(2);
            this.adminVaultRobux = (
                Math.floor(this.modalsData.user.vault.robuxCoin / 10) / 100
            ).toFixed(2);
            this.adminVaultCrypto = (
                Math.floor(this.modalsData.user.vault.cryptoCoin / 10) / 100
            ).toFixed(2);

            this.adminDeposit = (Math.floor(this.modalsData.user.stats.deposit / 10) / 100).toFixed(
                2
            );
            this.adminWithdraw = (
                Math.floor(this.modalsData.user.stats.withdraw / 10) / 100
            ).toFixed(2);
            this.adminLevel = Math.floor(Math.pow(this.modalsData.user.xp / 1000 / 100, 1 / 3));
            this.adminBetWithdraw = (
                Math.floor(this.modalsData.user.limits.betToWithdraw / 10) / 100
            ).toFixed(2);

            this.adminLimitToWithdraw = this.modalsData.user.limits.limitToWithdraw;
        }
    };
</script>

<style scoped>
    .admin-user-settings {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .admin-user-settings .settings-element {
        width: 100%;
        height: 47px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        padding: 0 20px;
        border-radius: 5px;
        background: rgba(19, 66, 88, 0.3);
    }

    .admin-user-settings .settings-element:first-of-type {
        margin-top: 0;
    }

    .admin-user-settings .element-name {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #bbbfd0;
    }

    .admin-user-settings .settings-element.element-toggle button {
        width: 45px;
        height: 15px;
        position: relative;
        filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .admin-user-settings .settings-element.element-toggle button:disabled {
        cursor: not-allowed;
    }

    .admin-user-settings .settings-element.element-toggle button::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #072435;
        clip-path: polygon(
            4px 0,
            calc(100% - 4px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 4px) 100%,
            4px 100%,
            0 75%,
            0 25%
        );
    }

    .admin-user-settings .settings-element.element-toggle button::after {
        content: '';
        width: 25px;
        height: 19px;
        position: absolute;
        top: -2px;
        left: 0;
        background: #1c5064;
        clip-path: polygon(
            3px 0,
            calc(100% - 3px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 3px) 100%,
            3px 100%,
            0 75%,
            0 25%
        );
        transition: all 0.3s ease;
    }

    .admin-user-settings .settings-element.element-toggle button.button-active::after {
        transform: translate(20px, 0);
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    }

    .admin-user-settings .settings-element.element-text .element-input,
    .admin-user-settings .settings-element.element-number .element-input {
        position: relative;
        display: flex;
        align-items: center;
    }

    .admin-user-settings .settings-element.element-number .element-input img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 9px;
        left: 15px;
    }

    .admin-user-settings .settings-element.element-text .element-input input,
    .admin-user-settings .settings-element.element-number .element-input input,
    .admin-user-settings .settings-element.element-select .element-input select {
        width: 220px;
        height: 33px;
        margin-right: 20px;
        padding: 0 15px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        background: rgba(10, 36, 52, 0.5);
    }

    .admin-user-settings .settings-element.element-number .element-input input {
        padding: 0 15px 0 37px;
    }

    .admin-user-settings .settings-element.element-button button,
    .admin-user-settings .settings-element.element-text .element-input button,
    .admin-user-settings .settings-element.element-number .element-input button,
    .admin-user-settings .settings-element.element-select .element-input button {
        width: 60px;
        height: 30px;
        filter: drop-shadow(0px 4px 25px rgba(1, 236, 174, 0.15))
            drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .admin-user-settings .settings-element.element-button button .button-inner,
    .admin-user-settings .settings-element.element-text .element-input button .button-inner,
    .admin-user-settings .settings-element.element-number .element-input button .button-inner,
    .admin-user-settings .settings-element.element-select .element-input button .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 800;
        color: #ffffff;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        clip-path: polygon(
            4px 0,
            calc(100% - 4px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 4px) 100%,
            4px 100%,
            0 75%,
            0 25%
        );
    }

    .admin-user-settings .settings-element.element-button button.button-purple .button-inner {
        background: #f55046;
    }

    @media only screen and (max-width: 793px) {
        .admin-user-settings {
            padding: 0 20px;
        }
    }
</style>
