<template>
    <button :class="$style['roll-recent-element']">
        <div :class="$style['element-amount']">
            <img
                v-if="bet.coinType === 'cryptoCoin'"
                src="@/assets/img/icons/cryptoCoin.svg"
                width="12"
                alt="icon"
            />
            <img
                v-else-if="bet.coinType === 'robuxCoin'"
                src="@/assets/img/icons/robuxCoin.svg"
                width="16"
                alt="icon"
            />
            <p :class="$style['amount-value']">
                {{ (bet.amount / 1000).toFixed(2).split('.')[0]
                }}<span>{{
                    (bet.amount / 1000)
                        .toFixed(2)
                        .slice(-3)
                        .replace('.00', '')
                        .replace(/(?<=[.]\d)[0]/, '')
                }}</span>
            </p>
        </div>
        <div :class="$style['element-multiplier']">
            <p :class="$style['multiplier-value']">
                {{ Math.round(bet.multiplier / 100)
                }}<span>{{
                    (bet.multiplier / 100)
                        .toFixed(2)
                        .slice(-3)
                        .replace('.00', '')
                        .replace(/(?<=[.]\d)[0]/, '')
                }}</span
                >x
            </p>
        </div>
    </button>
</template>

<script>
    export default {
        name: 'RollRecentElement',
        props: ['bet'],
        methods: {
            rollFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString();
            }
        }
    };
</script>

<style module>
    .roll-recent-element {
        height: 38px;
        padding: 7px 12px;
        font-size: 5px;
        display: flex;
        max-width: fit-content;
        align-items: center;
        gap: 4px;
        clip-path: var(--octagonal-main);
        background: #072740;
    }

    .roll-recent-element img {
        margin-bottom: -3px;
    }

    .roll-recent-element p span {
        white-space: nowrap;
        opacity: 0.6;
    }

    .roll-recent-element p {
        font-size: 13px;
        color: white;
        font-weight: 600;
    }

    .roll-recent-element .element-multiplier p {
        opacity: 0.6;
    }

    .roll-recent-element .element-multiplier p span {
        opacity: 1;
    }

    .roll-recent-element .element-multiplier,
    .roll-recent-element .element-amount {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        padding: 0px 10px 3px 10px;
        background: linear-gradient(360deg, #0f426b 11.31%, #0d3355 104.17%);
        font-size: 3px;
        clip-path: var(--octagonal-main);
        height: 100%;
        gap: 7px;
    }
</style>
