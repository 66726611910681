<template>
    <div :class="$style['rain-join']">
        <div :class="$style['rain-chips']">
            <img :class="$style['chip']" src="@/assets/img/icons/coin.svg" alt="" />
            <img :class="$style['chip']" src="@/assets/img/icons/coin.svg" alt="" />
            <img :class="$style['chip']" src="@/assets/img/icons/coin.svg" alt="" />
        </div>
        <div :class="$style['join-inner']">
            <div :class="$style['inner-amount']">
                <p>
                    <span>{{ rainFormatValue(generalRain.active?.amount).split('.')[0] }}</span
                    >.{{ rainFormatValue(generalRain.active?.amount).split('.')[1] }}
                </p>
            </div>
            <div :class="$style['inner-type']">
                <div :class="$style['type-site']">
                    <AvatarImage
                        v-if="generalRain.active?.type === 'user'"
                        v-bind:image="generalRain.active?.creator.avatar"
                        v-bind:rank="generalRain.active?.creator.rank"
                        v-bind:level="generalRain.active?.creator.level"
                    />
                    <img v-else src="@/assets/img/chip.png" :class="$style['site-logo']" />

                    <p v-if="generalRain.active?.type === 'user'">
                        <b>{{ generalRain.active?.creator?.username }}</b>
                        &nbsp;
                        <span class="fade-green">hosted a rain</span>
                    </p>
                    <p v-else>
                        <b>Rollbet</b>
                        &nbsp;
                        <span class="fade-green">hosted a rain</span>
                    </p>
                </div>
            </div>
            <div :class="$style['inner-bottom']">
                <div :class="$style['info-timer']" class="octagonal before after">
                    <IconTimerGradient />
                    <div :class="$style['timer-value']">
                        <span>{{ rainTimer.split(':')[0] }}</span>
                        m
                        <span>{{ rainTimer.split(':')[1] }}</span>
                        s
                    </div>
                </div>
                <button
                    v-on:click="rainClaimButton()"
                    :class="$style['button-claim']"
                    class="octagonal before after hoverable"
                    v-bind:disabled="rainIsClaimed === true"
                >
                    <div :class="$style['button-inner']">
                        <span>{{ rainIsClaimed === true ? 'Claimed' : 'Claim Rain' }}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconRain from '@/components/icons/IconRain';
    import IconTimerGradient from '@/components/icons/IconTimerGradient';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'RainJoin',
        components: {
            IconRain,
            IconTimerGradient,
            AvatarImage
        },
        data() {
            return {
                rainTimerInterval: null,
                rainTimer: '00:00'
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'generalClearRainActive',
                'modalsSetData',
                'modalsSetShow'
            ]),
            rainFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            rainStartTimer() {
                const endingTime =
                    new Date(this.generalRain.active?.updatedAt).getTime() + 1000 * 60 * 2;
                let timeLeft = Math.floor(
                    (endingTime - (new Date().getTime() + this.generalTimeDiff)) / 1000
                );
                timeLeft = timeLeft <= 0 ? 0 : timeLeft;

                if (timeLeft === 0) {
                    this.generalClearRainActive();
                }

                let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
                timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
                let timeSeconds = Math.floor(timeLeft % 60);
                timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

                this.rainTimer = timeMinutes.toString() + ':' + timeSeconds.toString();
            },
            rainClaimButton() {
                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                this.modalsSetData({ typeCaptcha: 'rainJoin', data: {} });
                this.modalsSetShow('Captcha');
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'authUser', 'generalTimeDiff', 'generalRain']),
            rainGetLevelColor() {
                let color = '';

                if (
                    this.generalRain.active?.creator.level >= 2 &&
                    this.generalRain.active?.creator.level < 26
                ) {
                    color = 'blue';
                } else if (
                    this.generalRain.active?.creator.level >= 26 &&
                    this.generalRain.active?.creator.level < 51
                ) {
                    color = 'sky';
                } else if (
                    this.generalRain.active?.creator.level >= 51 &&
                    this.generalRain.active?.creator.level < 76
                ) {
                    color = 'yellow';
                } else if (
                    this.generalRain.active?.creator.level >= 76 &&
                    this.generalRain.active?.creator.level <= 90
                ) {
                    color = 'purple';
                } else if (this.generalRain.active?.creator.level > 90) {
                    color = 'gold';
                }

                return color;
            },
            rainIsClaimed() {
                return (
                    this.authUser.user !== null &&
                    this.generalRain.active?.participants.some(
                        (element) => element.user === this.authUser.user._id
                    ) === true
                );
            }
        },
        beforeDestroy() {
            clearInterval(this.rainTimerInterval);
        },
        created() {
            this.rainTimerInterval = setInterval(() => {
                this.rainStartTimer();
            }, 500);
        }
    };
</script>

<style module>
    .rain-join {
        width: 100%;
        height: 130px;
        position: relative;
        z-index: 0;
        background-image: url('~@/assets/img/rain-background.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: top center;
    }

    .rain-join > * {
        position: relative;
        z-index: 1;
    }

    .rain-join::before {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, #1e6057, #1e6145);
        width: 100%;
        height: 100%;
        font-size: 7px;
        clip-path: var(--octagonal-before);
        left: 0;
        top: 0;
    }

    .rain-join::after {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, #1b564a, transparent);
        width: 100%;
        height: 100%;
        font-size: 7px;
        clip-path: var(--octagonal-after);
        left: 0;
        top: 0;
    }

    .rain-join .rain-chips {
        position: absolute;
        display: flex;
        align-items: center;
        filter: drop-shadow(0 0 15px #00ff5733);
        justify-content: space-between;
        width: 100%;
        left: 0;
        top: -10px;
    }

    .rain-join .rain-chips .chip {
        width: 22px;
        transform: rotate(0deg) translateY(-3px);
    }

    .rain-join .rain-chips .chip:first-child {
        width: 14px;
        transform: rotate(-15deg);
    }

    .rain-join .rain-chips .chip:last-child {
        width: 14px;
        transform: rotate(15deg);
    }

    .rain-join .join-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        padding: 28px 30px 14px 30px;
    }

    .rain-join .inner-amount {
        display: flex;
        align-items: center;
        width: calc(100% + 40px);
        mask-image: linear-gradient(90deg, transparent, white 40%, white 60%, transparent);
        font-weight: 800;
        font-size: 15px;
        color: white;
        margin-bottom: -4px;
        gap: 30px;
    }

    .rain-join .inner-amount::before,
    .rain-join .inner-amount::after {
        content: '';
        position: relative;
        flex: 1;
        background: #01e5a8;
        height: 1px;
    }

    .rain-join .inner-type {
        width: 100%;
        display: flex;
        margin-bottom: 2px;
        justify-content: center;
    }

    .rain-join .type-site {
        width: 100%;
        height: 100%;
        display: flex;
        color: white;
        font-size: 14px;
        height: 23px;
        gap: 7px;
        justify-content: center;
        align-items: center;
    }

    .rain-join .type-site > img:not(.site-logo) {
        height: 23px;
        width: 23px;
    }

    .rain-join .type-site .site-logo {
        width: 15px;
    }

    .rain-join .info-amount {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }

    .rain-join .info-amount img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
    }

    .rain-join .info-timer {
        height: 26px;
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
    }

    .rain-join .info-timer svg {
        width: 12px;
        margin-right: 8px;
    }
    .rain-join .timer-value {
        font-size: 13px;
        font-weight: 600;
        color: #bbbfd0;
    }
    .rain-join .timer-value span {
        font-size: 12px;
        font-weight: 800;
        color: #ffffff;
    }

    .rain-join .inner-bottom {
        gap: 7px;
        align-items: center;
        width: 100%;
        display: flex;
    }

    .rain-join button.button-claim {
        flex: 1;
        height: 26px;
        position: relative;
        color: #073e40;
        padding: 1px;
        filter: drop-shadow(0px 2px 15px rgba(1, 230, 169, 0.15))
            drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
        z-index: 1;
    }

    .rain-join button.button-claim::before {
        background: linear-gradient(0deg, #53e5b4, transparent);
        font-size: 4px;
    }

    .rain-join button.button-claim::after {
        background: #37a971;
        font-size: 4px;
    }

    .rain-join .info-timer::before {
        background: linear-gradient(0deg, #2f8a72, transparent);
        font-size: 4px;
    }

    .rain-join .info-timer::after {
        background: #17474e;
        font-size: 4px;
    }

    .rain-join button.button-claim .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rain-join button.button-claim:disabled .button-inner {
        background: linear-gradient(
            265deg,
            rgba(0, 255, 194, 0.25) 20%,
            rgba(0, 170, 109, 0.25) 70%
        );
    }

    .rain-join button.button-claim .button-inner span {
        font-size: 13px;
        font-weight: 600;
        color: #073e40;
    }

    .rain-join button.button-claim:disabled .button-inner span {
        background: linear-gradient(256.23deg, #00ffc2 0%, #00aa6d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
</style>
