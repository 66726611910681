<template>
    <div class="banner-glory">
        <div class="banner-glory-bg"></div>
        <div class="glory-content">
            <h1>NEW REWARDS, NEW GAMES, NEW EVERYTHING!</h1>
            <p>A New Update is Releasing Very Soon, Stay Tuned!</p>
            <a class="content-link" href="https://discord.gg/rollbet" target="_blank">
                <div class="link-button">
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.5447 0.994743C12.5249 0.53434 11.4314 0.195118 10.288 0.000815247C10.2672 -0.00293612 10.2463 0.00644229 10.2356 0.0251684C10.095 0.271314 9.93919 0.592394 9.8301 0.844781C8.60032 0.663608 7.37681 0.663608 6.17225 0.844781C6.06312 0.586797 5.90169 0.271314 5.76041 0.0251684C5.74969 0.00705727 5.72887 -0.00232114 5.70806 0.000815247C4.56531 0.194472 3.47175 0.533694 2.45131 0.994743C2.44246 0.998495 2.4349 1.00474 2.42987 1.01285C0.355618 4.06207 -0.212633 7.03635 0.0661174 9.97374C0.0673674 9.9881 0.0755861 10.0018 0.0869299 10.0106C1.45546 10.9995 2.78115 11.5999 4.08218 11.9978C4.103 12.0041 4.12506 11.9966 4.13831 11.9797C4.44606 11.5662 4.7204 11.1301 4.95562 10.6716C4.9695 10.6447 4.95625 10.6128 4.92787 10.6022C4.49272 10.4398 4.07837 10.2417 3.67978 10.0169C3.64825 9.99877 3.64572 9.95439 3.67475 9.93315C3.75862 9.87131 3.84253 9.80695 3.92262 9.74198C3.93712 9.73011 3.95731 9.72762 3.97434 9.73509C6.59284 10.9115 9.42769 10.9115 12.0153 9.73509C12.0323 9.72698 12.0525 9.7295 12.0676 9.74134C12.1478 9.80631 12.2316 9.87128 12.3161 9.93312C12.3451 9.95436 12.3432 9.99874 12.3117 10.0168C11.9131 10.2461 11.4988 10.4398 11.063 10.6016C11.0346 10.6122 11.022 10.6447 11.0359 10.6715C11.2762 11.1294 11.5505 11.5655 11.8526 11.9791C11.8652 11.9966 11.8879 12.0041 11.9087 11.9978C13.2161 11.5999 14.5418 10.9995 15.9103 10.0106C15.9223 10.0018 15.9299 9.98871 15.9311 9.97435C16.2647 6.57841 15.3723 3.62851 13.5655 1.01347C13.5611 1.00474 13.5535 0.998495 13.5447 0.994743ZM5.34672 8.18516C4.55837 8.18516 3.90878 7.47302 3.90878 6.5984C3.90878 5.7238 4.54575 5.01163 5.34672 5.01163C6.15394 5.01163 6.79725 5.73005 6.78463 6.5984C6.78463 7.47302 6.14762 8.18516 5.34672 8.18516ZM10.6632 8.18516C9.87488 8.18516 9.22529 7.47302 9.22529 6.5984C9.22529 5.7238 9.86226 5.01163 10.6632 5.01163C11.4704 5.01163 12.1137 5.73005 12.1011 6.5984C12.1011 7.47302 11.4705 8.18516 10.6632 8.18516Z"
                            fill="#5865F2"
                        />
                        <path
                            d="M13.5447 0.994743C12.5249 0.53434 11.4314 0.195118 10.288 0.000815247C10.2672 -0.00293612 10.2463 0.00644229 10.2356 0.0251684C10.095 0.271314 9.93919 0.592394 9.8301 0.844781C8.60032 0.663608 7.37681 0.663608 6.17225 0.844781C6.06312 0.586797 5.90169 0.271314 5.76041 0.0251684C5.74969 0.00705727 5.72887 -0.00232114 5.70806 0.000815247C4.56531 0.194472 3.47175 0.533694 2.45131 0.994743C2.44246 0.998495 2.4349 1.00474 2.42987 1.01285C0.355618 4.06207 -0.212633 7.03635 0.0661174 9.97374C0.0673674 9.9881 0.0755861 10.0018 0.0869299 10.0106C1.45546 10.9995 2.78115 11.5999 4.08218 11.9978C4.103 12.0041 4.12506 11.9966 4.13831 11.9797C4.44606 11.5662 4.7204 11.1301 4.95562 10.6716C4.9695 10.6447 4.95625 10.6128 4.92787 10.6022C4.49272 10.4398 4.07837 10.2417 3.67978 10.0169C3.64825 9.99877 3.64572 9.95439 3.67475 9.93315C3.75862 9.87131 3.84253 9.80695 3.92262 9.74198C3.93712 9.73011 3.95731 9.72762 3.97434 9.73509C6.59284 10.9115 9.42769 10.9115 12.0153 9.73509C12.0323 9.72698 12.0525 9.7295 12.0676 9.74134C12.1478 9.80631 12.2316 9.87128 12.3161 9.93312C12.3451 9.95436 12.3432 9.99874 12.3117 10.0168C11.9131 10.2461 11.4988 10.4398 11.063 10.6016C11.0346 10.6122 11.022 10.6447 11.0359 10.6715C11.2762 11.1294 11.5505 11.5655 11.8526 11.9791C11.8652 11.9966 11.8879 12.0041 11.9087 11.9978C13.2161 11.5999 14.5418 10.9995 15.9103 10.0106C15.9223 10.0018 15.9299 9.98871 15.9311 9.97435C16.2647 6.57841 15.3723 3.62851 13.5655 1.01347C13.5611 1.00474 13.5535 0.998495 13.5447 0.994743ZM5.34672 8.18516C4.55837 8.18516 3.90878 7.47302 3.90878 6.5984C3.90878 5.7238 4.54575 5.01163 5.34672 5.01163C6.15394 5.01163 6.79725 5.73005 6.78463 6.5984C6.78463 7.47302 6.14762 8.18516 5.34672 8.18516ZM10.6632 8.18516C9.87488 8.18516 9.22529 7.47302 9.22529 6.5984C9.22529 5.7238 9.86226 5.01163 10.6632 5.01163C11.4704 5.01163 12.1137 5.73005 12.1011 6.5984C12.1011 7.47302 11.4705 8.18516 10.6632 8.18516Z"
                            fill="url(#paint0_linear_563_134)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_563_134"
                                x1="10.2857"
                                y1="10.875"
                                x2="11.7822"
                                y2="4.58414"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#FFB547" />
                                <stop offset="0.764754" stop-color="#FFEEA6" />
                                <stop offset="1" stop-color="#F1CC0A" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <span class="fade-gold">JOIN DISCORD</span>
                </div>
            </a>
        </div>
        <img class="glory-knight" src="@/assets/img/glory-knight.png" />
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BannerGlory',
        components: {
            AvatarImage
        },
        computed: {
            ...mapGetters(['authUser'])
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    };
</script>

<style scoped>
    .banner-glory {
        border: 1px solid #ffba09;
        border-radius: 8px;
        justify-content: center;
        flex-direction: row;
        align-items: flex-end;
        position: relative;
        padding: 14px 45px 35px 45px;
        font-size: 10px;
        display: flex;
        height: 100%;
        width: 100%;
        gap: 34px;
    }

    .banner-glory-bg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
    }

    .banner-glory-bg::before {
        animation: background-move 6s ease alternate infinite;
        background-image: url('~@/assets/img/banner-glory-bg.png');
        background-position: center;
        background-size: cover;
        position: absolute;
        opacity: 0.9;
        left: 0;
        top: 0;
        width: calc(100% + 50px);
        height: 100%;
        content: '';
    }

    @keyframes background-move {
        0% {
            left: 0;
        }
        100% {
            left: -50px;
        }
    }

    .banner-glory .glory-knight {
        width: 209px;
        position: absolute;
        right: -18px;
        bottom: 1px;
    }

    .banner-glory .glory-content {
        display: flex;
        flex-direction: column;
        font-weight: 700;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    .banner-glory .glory-content h1 {
        filter: drop-shadow(0 2px 0px #00000059) drop-shadow(0.125px 0.125px 0 #00000059)
            drop-shadow(-0.125px -0.125px 0 #00000059) drop-shadow(0.125px -0.125px 0 #00000059)
            drop-shadow(-0.125px 0.125px 0 #00000059);
        font-size: 3.8em;
        line-height: 1em;
        position: relative;
        width: fit-content;
        color: white;
    }

    .banner-glory .glory-content h1::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        user-select: none;
        pointer-events: none;
        content: 'NEW REWARDS, NEW GAMES, NEW EVERYTHING!';
        background-image: linear-gradient(-45deg, transparent, #ffeb91, transparent);
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: -900% 50%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        animation: glory-h1 1.5s ease-out 2s;
    }

    @keyframes glory-h1 {
        0% {
            background-position: -900% 50%;
        }
        100% {
            background-position: 900% 50%;
        }
    }

    .banner-glory .glory-content p {
        filter: drop-shadow(0.25px 0.25px 0 currentColor) drop-shadow(-0.25px 0.25px 0 currentColor)
            drop-shadow(-0.25px -0.25px 0 currentColor);
        color: rgb(95, 86, 31);
        background: linear-gradient(0.125deg, #ffb547, #ffeea6, #f1cc0a 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 2.6em;
        width: fit-content;
    }

    .banner-glory .content-link {
        filter: drop-shadow(0 2px 0px #00000059);
        max-width: 170px;
    }

    .banner-glory .link-button span {
        font-size: 15px;
    }

    .banner-glory .link-button {
        margin-top: 10px;
        width: 100%;
        max-width: 170px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: radial-gradient(50% 50% at center, #404041, #635947);
        gap: 7px;
        position: relative;
        clip-path: var(--octagonal-main);
        font-size: 5px;
    }

    .banner-glory .link-button::before {
        clip-path: var(--octagonal-before);
        background: linear-gradient(90deg, #f7af3a, #fbda9c);
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        font-size: 5px;
        content: '';
    }

    .banner-glory .link-button::after {
        content: '';
        position: absolute;
        left: -100%;
        top: 50%;
        width: 100%;
        height: 1000px;
        animation: link-button 1.5s ease-out 1s;
        background: linear-gradient(90deg, transparent, #ffffff44, transparent);
        transform: translateY(-50%) rotate(45deg);
        z-index: 2;
    }

    @keyframes link-button {
        0% {
            left: -100%;
        }
        70% {
            left: 200%;
        }
        70.01% {
            left: -100%;
        }
        100% {
            left: 200%;
        }
    }

    @media screen and (max-width: 1200px) {
        .banner-glory {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 800px) {
        .banner-glory {
            padding: 14px 30px 20px 30px;
        }
    }

    @media screen and (max-width: 700px) {
        .banner-glory .glory-content {
            width: 80%;
            margin-right: auto;
            font-size: 8px;
        }
    }

    @media screen and (max-width: 580px) {
        .banner-glory {
            padding: 14px 20px 20px 20px;
        }
        .banner-glory .glory-content {
            width: 70%;
            margin-right: auto;
            font-size: 6px;
        }
    }
</style>
