<template>
    <div :class="$style['modal-box']">
        <button v-on:click="modalsSetShow(null)" :class="$style['button-close']">
            <div :class="$style['button-inner']">
                <IconClose />
            </div>
        </button>
        <div :class="$style['box-header']">
            <div :class="$style['header-info']">
                <img v-bind:src="unboxImagePath + '/public/img/' + modalsData.box.slug + '.png'" />
                <div :class="$style['info-text']">
                    <div :class="$style['text-name']">{{ modalsData.box.name }}</div>
                    <div :class="$style['text-amount']">
                        <img
                            v-if="userBalanceData.type === 'cryptoCoin'"
                            src="@/assets/img/icons/cryptoCoin.svg"
                            alt="icon"
                        />
                        <img
                            v-else-if="userBalanceData.type === 'robuxCoin'"
                            src="@/assets/img/icons/robuxCoin.svg"
                            alt="icon"
                        />
                        <div :class="$style['amount-value']">
                            <span>{{ modalFormatValue(modalsData.box.amount).split('.')[0] }}</span
                            >.{{ modalFormatValue(modalsData.box.amount).split('.')[1] }}
                        </div>
                    </div>
                </div>
            </div>
            <div :class="$style['header-title']">POTENTIAL DROPS</div>
        </div>
        <div :class="$style['box-items']">
            <UnboxItemElement
                v-for="(item, index) in battlesGetBoxItems"
                v-bind:key="index"
                v-bind:item="item"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconClose from '@/components/icons/IconClose';
    import UnboxItemElement from '@/components/unbox/UnboxItemElement';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';

    export default {
        name: 'ModalBox',
        components: {
            IconClose,
            UnboxItemElement
        },
        data() {
            return {
                unboxImagePath: process.env.VUE_APP_BACKEND_URL
            };
        },
        methods: {
            ...mapActions(['modalsSetShow']),
            modalFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        computed: {
            ...mapGetters(['socketUnbox', 'socketBattles', 'modalsData', 'userBalanceData']),
            battlesGetBoxItems() {
                return this.modalsData.box.items.map((item) => {
                    const color = getLimitedItemColorFromAmount(item.item.amountFixed / 1000);
                    return { ...item, color };
                });
            }
        }
    };
</script>

<style module>
    .modal-box {
        width: 750px;
        position: relative;
        padding: 0 25px 20px 25px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
            linear-gradient(223deg, rgba(5, 29, 48, 0.35) 0%, rgba(31, 99, 153, 0) 100%), #042238;
    }

    .modal-box button.button-close {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 1;
    }

    .modal-box button.button-close .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #082c45;
        clip-path: polygon(
            6px 0,
            calc(100% - 6px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 6px) 100%,
            6px 100%,
            0 75%,
            0 25%
        );
    }

    .modal-box button.button-close .button-inner svg {
        fill: #6e95b6;
        transition: all 0.3s ease;
    }

    .modal-box button.button-close:hover .button-inner svg {
        fill: #ffffff;
    }

    .modal-box .box-header {
        width: 100%;
        height: 87px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-box .box-header:after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(
            90deg,
            rgba(97, 112, 241, 0.15) 0%,
            rgba(28, 71, 182, 0.15) 100%
        );
    }

    .modal-box .header-info {
        position: absolute;
        top: 50%;
        left: 0;
        display: flex;
        align-items: center;
        transform: translate(0, -50%);
    }

    .modal-box .header-info > img {
        height: 60px;
        transform: translateY(8%) scale(0.9);
    }

    .modal-box .info-text {
        display: flex;
        flex-direction: column;
        margin-left: 18px;
    }

    .modal-box .text-name {
        font-size: 18px;
        font-weight: 600;
        color: #406992;
    }

    .modal-box .text-amount {
        display: flex;
        height: 26px;
        min-width: 118px;
        padding: 0 10px;
        align-items: center;
        justify-content: center;
        margin-top: 3px;
        font-size: 3px;
        background: linear-gradient(0deg, #064552, #064552),
            linear-gradient(
                89.54deg,
                rgba(0, 0, 0, 0.128) -3.42%,
                rgba(0, 33, 24, 0.132) 35.6%,
                rgba(0, 99, 73, 0.144986) 96.9%,
                rgba(1, 193, 143, 0.0925409) 127.35%,
                rgba(1, 237, 176, 0.068) 156.02%
            );
        clip-path: var(--octagonal-main);
        position: relative;
    }

    .modal-box .text-amount::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 28.53%,
            rgba(30, 255, 194, 0.35) 91.03%
        );
        clip-path: var(--octagonal-before);
    }

    .modal-box .text-amount img {
        width: 13px;
        height: 18px;
        margin-right: 5px;
    }

    .modal-box .amount-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-box .amount-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .modal-box .header-title {
        font-size: 18px;
        font-weight: 700;
        color: white;
    }

    .modal-box .box-items {
        width: 100%;
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        margin-top: 14px;
    }

    @media only screen and (max-width: 770px) {
        .modal-box {
            width: calc(100vw - 20px);
            padding: 0 10px 20px 10px;
        }

        .modal-box .info-text {
            display: none;
        }
    }

    @media only screen and (max-width: 700px) {
        .modal-box .box-items {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 550px) {
        .modal-box .box-items {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
</style>
