<template>
    <div :class="$style['cashier-credit-tab']">
        <p :class="$style['tab-title']">Conversion</p>
        <div :class="$style['tab-conversion']">
            <div :class="$style['tab-amount']">
                <input
                    v-model="usdAmount"
                    v-on:input="modalValidateUsdInput"
                    type="text"
                    placeholder="0"
                />
                <div :class="$style['amount-icon']">
                    <svg
                        width="8"
                        height="15"
                        viewBox="0 0 8 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.14726 6.67067C5.54139 6.35222 4.89726 6.10958 4.2595 5.85785C3.8896 5.71228 3.53565 5.54243 3.22315 5.30587C2.60772 4.8388 2.7257 4.08058 3.44637 3.78033C3.65045 3.69541 3.86409 3.66811 4.08093 3.65598C4.91639 3.61352 5.7104 3.7591 6.46614 4.10485C6.84241 4.27772 6.96677 4.22313 7.09432 3.85008C7.22825 3.45581 7.33986 3.05547 7.46422 2.65816C7.54713 2.39126 7.44509 2.21536 7.18042 2.10314C6.69573 1.89994 6.19828 1.75436 5.67532 1.6755C4.99292 1.57542 4.99292 1.57238 4.98973 0.920313C4.98654 0.00134797 4.98654 0.00134795 4.01716 0.00134795C3.87685 0.00134795 3.73654 -0.00168494 3.59624 0.00134795C3.14343 0.0134795 3.0669 0.0893017 3.05415 0.523005C3.04777 0.71711 3.05415 0.911214 3.05096 1.10835C3.04777 1.6846 3.04458 1.6755 2.46422 1.87567C1.06116 2.36093 0.193814 3.2708 0.10134 4.72659C0.0184316 6.01556 0.72634 6.886 1.83922 7.51988C2.52481 7.91112 3.28374 8.14162 4.01078 8.44794C4.29458 8.56622 4.56563 8.7027 4.80159 8.89074C5.49994 9.4397 5.37239 10.3526 4.5433 10.6983C4.10006 10.8833 3.63131 10.9288 3.14981 10.8712C2.40682 10.7833 1.69573 10.5983 1.02608 10.2677C0.633865 10.0736 0.519069 10.1251 0.385141 10.5285C0.270345 10.8773 0.168304 11.2291 0.0662633 11.5809C-0.0708541 12.054 -0.019834 12.1663 0.455294 12.3877C1.06116 12.6667 1.70848 12.8092 2.36856 12.9093C2.88514 12.9882 2.90108 13.0094 2.90746 13.5189C2.91065 13.7494 2.91065 13.983 2.91384 14.2135C2.91703 14.5046 3.06371 14.6745 3.3794 14.6805C3.73654 14.6866 4.09687 14.6866 4.45402 14.6775C4.74739 14.6714 4.89726 14.5198 4.89726 14.2377C4.89726 13.9223 4.9132 13.6039 4.90045 13.2884C4.8845 12.9669 5.03119 12.8032 5.35644 12.7182C6.1058 12.5241 6.74356 12.142 7.23463 11.5718C8.59943 9.99471 8.07966 7.68669 6.14726 6.67067Z"
                            fill="black"
                        />
                        <path
                            d="M6.14726 6.67067C5.54139 6.35222 4.89726 6.10958 4.2595 5.85785C3.8896 5.71228 3.53565 5.54243 3.22315 5.30587C2.60772 4.8388 2.7257 4.08058 3.44637 3.78033C3.65045 3.69541 3.86409 3.66811 4.08093 3.65598C4.91639 3.61352 5.7104 3.7591 6.46614 4.10485C6.84241 4.27772 6.96677 4.22313 7.09432 3.85008C7.22825 3.45581 7.33986 3.05547 7.46422 2.65816C7.54713 2.39126 7.44509 2.21536 7.18042 2.10314C6.69573 1.89994 6.19828 1.75436 5.67532 1.6755C4.99292 1.57542 4.99292 1.57238 4.98973 0.920313C4.98654 0.00134797 4.98654 0.00134795 4.01716 0.00134795C3.87685 0.00134795 3.73654 -0.00168494 3.59624 0.00134795C3.14343 0.0134795 3.0669 0.0893017 3.05415 0.523005C3.04777 0.71711 3.05415 0.911214 3.05096 1.10835C3.04777 1.6846 3.04458 1.6755 2.46422 1.87567C1.06116 2.36093 0.193814 3.2708 0.10134 4.72659C0.0184316 6.01556 0.72634 6.886 1.83922 7.51988C2.52481 7.91112 3.28374 8.14162 4.01078 8.44794C4.29458 8.56622 4.56563 8.7027 4.80159 8.89074C5.49994 9.4397 5.37239 10.3526 4.5433 10.6983C4.10006 10.8833 3.63131 10.9288 3.14981 10.8712C2.40682 10.7833 1.69573 10.5983 1.02608 10.2677C0.633865 10.0736 0.519069 10.1251 0.385141 10.5285C0.270345 10.8773 0.168304 11.2291 0.0662633 11.5809C-0.0708541 12.054 -0.019834 12.1663 0.455294 12.3877C1.06116 12.6667 1.70848 12.8092 2.36856 12.9093C2.88514 12.9882 2.90108 13.0094 2.90746 13.5189C2.91065 13.7494 2.91065 13.983 2.91384 14.2135C2.91703 14.5046 3.06371 14.6745 3.3794 14.6805C3.73654 14.6866 4.09687 14.6866 4.45402 14.6775C4.74739 14.6714 4.89726 14.5198 4.89726 14.2377C4.89726 13.9223 4.9132 13.6039 4.90045 13.2884C4.8845 12.9669 5.03119 12.8032 5.35644 12.7182C6.1058 12.5241 6.74356 12.142 7.23463 11.5718C8.59943 9.99471 8.07966 7.68669 6.14726 6.67067Z"
                            fill="url(#paint0_linear_236_4249)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_236_4249"
                                x1="11.8261"
                                y1="-2.17552"
                                x2="-2.46746"
                                y2="0.280039"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#00FFC2" />
                                <stop offset="1" stop-color="#00AA6D" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <svg
                width="7"
                height="16"
                viewBox="0 0 7 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.546955 4.19815C0.58419 4.20475 0.621873 4.2078 0.659575 4.20726L4.97945 4.20726L4.83946 4.27843C4.70263 4.34922 4.57814 4.44556 4.47159 4.5631L3.37129 5.76575C3.13419 6.01315 3.09435 6.41113 3.27688 6.70872C3.48933 7.02583 3.89673 7.09469 4.18688 6.86248C4.21032 6.84371 4.2326 6.82325 4.25354 6.80124L6.80904 4.008C7.06346 3.73022 7.06368 3.27962 6.80955 3.00154L6.80904 3.00098L4.25354 0.20774C3.99891 -0.0697901 3.58667 -0.0691673 3.33274 0.209141C3.31276 0.231047 3.2941 0.254354 3.27688 0.278907C3.09435 0.576497 3.13419 0.974478 3.37129 1.22187L4.46833 2.42808C4.56386 2.53261 4.67369 2.62026 4.79388 2.68784L4.98921 2.78392L0.695406 2.78392C0.362795 2.77042 0.0709797 3.02438 0.00849454 3.38172C-0.0490668 3.7697 0.192003 4.13521 0.546955 4.19815Z"
                    fill="#406992"
                />
                <path
                    d="M6.45305 11.8019C6.41581 11.7952 6.37813 11.7922 6.34043 11.7927H2.02055L2.16054 11.7216C2.29737 11.6508 2.42186 11.5544 2.52841 11.4369L3.62871 10.2342C3.86581 9.98685 3.90565 9.58887 3.72312 9.29128C3.51067 8.97417 3.10327 8.90531 2.81312 9.13752C2.78968 9.15629 2.7674 9.17675 2.74646 9.19877L0.190961 11.992C-0.0634568 12.2698 -0.0636806 12.7204 0.190452 12.9985L0.190961 12.999L2.74646 15.7923C3.00109 16.0698 3.41333 16.0692 3.66726 15.7909C3.68724 15.769 3.7059 15.7456 3.72312 15.7211C3.90565 15.4235 3.86581 15.0255 3.62871 14.7781L2.53167 13.5719C2.43614 13.4674 2.32631 13.3797 2.20612 13.3122L2.01079 13.2161H6.30459C6.63721 13.2296 6.92902 12.9756 6.99151 12.6183C7.04907 12.2303 6.808 11.8648 6.45305 11.8019Z"
                    fill="#406992"
                />
            </svg>
            <div :class="$style['tab-amount']">
                <input
                    v-model="cryptoAmount"
                    v-on:input="modalValidateCryptoInput"
                    type="text"
                    placeholder="0"
                />
                <div :class="$style['amount-icon']">
                    <img width="17" src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                </div>
            </div>
        </div>
        <p :class="$style['tab-title']">Buy amount</p>
        <div :class="$style['tab-amount']">
            <input
                v-model="modalAmount"
                v-on:input="modalValidateInput"
                type="text"
                placeholder="Amount of Gems"
            />
            <div :class="$style['amount-icon']">
                <img width="17" src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
            </div>
            <button :class="$style['amount-clear']" v-on:click="modalClearButton()">
                <p>Clear</p>
            </button>
        </div>
        <div :class="$style['tab-button']">
            <button
                v-on:click="modalActionButton()"
                :class="$style['button-action']"
                v-bind:disabled="socketSendLoading !== null"
            >
                <transition name="fade" mode="out-in">
                    <ButtonLoading v-if="socketSendLoading === 'CreditDeposit'" key="loading" />
                    <div v-else :class="$style['action-content']" key="content">
                        <span class="fade-green">Buy</span>
                        <img width="17" src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                        <span>{{ modalGetAmount }}</span>
                    </div>
                </transition>
            </button>
        </div>

        <div :class="$style['tab-note']">
            <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.85207 0H34.6982L38 3.66897V13.1034V34.331L34.6982 38H3.85207L0 34.331V13.1034V3.66897L3.85207 0Z"
                    fill="#062237"
                />
                <path
                    d="M3.85207 0H34.6982L38 3.66897V13.1034V34.331L34.6982 38H3.85207L0 34.331V13.1034V3.66897L3.85207 0Z"
                    fill="url(#paint0_linear_236_6627)"
                    fill-opacity="0.15"
                />
                <path
                    d="M19.5 9C18.121 8.99952 16.7554 9.27078 15.4813 9.79828C14.2071 10.3258 13.0494 11.0992 12.0743 12.0743C11.0992 13.0494 10.3258 14.2071 9.79828 15.4813C9.27078 16.7554 8.99952 18.121 9 19.5C8.99952 20.879 9.27078 22.2446 9.79828 23.5187C10.3258 24.7929 11.0992 25.9506 12.0743 26.9257C13.0494 27.9008 14.2071 28.6742 15.4813 29.2017C16.7554 29.7292 18.121 30.0005 19.5 30C20.879 30.0005 22.2446 29.7292 23.5187 29.2017C24.7929 28.6742 25.9506 27.9008 26.9257 26.9257C27.9008 25.9506 28.6742 24.7929 29.2017 23.5187C29.7292 22.2446 30.0005 20.879 30 19.5C30.0005 18.121 29.7292 16.7554 29.2017 15.4813C28.6742 14.2071 27.9008 13.0494 26.9257 12.0743C25.9506 11.0992 24.7929 10.3258 23.5187 9.79828C22.2446 9.27078 20.879 8.99952 19.5 9ZM19.5 27.0629C19.186 27.0637 18.8789 26.9712 18.6175 26.7972C18.3561 26.6232 18.1522 26.3755 18.0318 26.0856C17.9113 25.7956 17.8795 25.4764 17.9406 25.1684C18.0017 24.8604 18.1528 24.5775 18.3749 24.3554C18.5969 24.1334 18.8798 23.9823 19.1878 23.9212C19.4958 23.8601 19.815 23.8918 20.105 24.0123C20.3949 24.1328 20.6426 24.3367 20.8166 24.5981C20.9906 24.8595 21.0831 25.1666 21.0823 25.4806C21.0814 25.9 20.9144 26.3019 20.6178 26.5984C20.3213 26.8949 19.9194 27.062 19.5 27.0629ZM21.4201 13.993L20.8217 22.0699C20.8179 22.1258 20.7931 22.1781 20.7522 22.2163C20.7113 22.2545 20.6574 22.2757 20.6014 22.2755H18.3986C18.3426 22.2757 18.2887 22.2545 18.2478 22.2163C18.2069 22.1781 18.1821 22.1258 18.1783 22.0699L17.5799 13.993C17.5637 13.7309 17.6013 13.4682 17.6905 13.2211C17.7796 12.9741 17.9184 12.7479 18.0983 12.5566C18.2782 12.3652 18.4954 12.2127 18.7364 12.1085C18.9775 12.0042 19.2374 11.9504 19.5 11.9504C19.7626 11.9504 20.0225 12.0042 20.2636 12.1085C20.5046 12.2127 20.7218 12.3652 20.9017 12.5566C21.0816 12.7479 21.2204 12.9741 21.3095 13.2211C21.3987 13.4682 21.4363 13.7309 21.4201 13.993Z"
                    fill="#3483D5"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_236_6627"
                        x1="165.571"
                        y1="-2.20137"
                        x2="101.187"
                        y2="92.802"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#3B7EB7" />
                        <stop offset="1" stop-color="#145081" />
                    </linearGradient>
                </defs>
            </svg>
            <p>
                When buying
                <span class="fade-green">Gems</span>
                with credit card you will be taken to one of our 3rd party processors which is where
                you will go through the process of depositing with credit cards. It&lsquo;s quite
                simple, you must choose the amount you want to deposit, thereafter purchase and pay
                through stripe.
            </p>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ButtonLoading from '@/components/ButtonLoading';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import IconLeftGradient from '@/components/icons/IconLeftGradient';
    import IconRightGradient from '@/components/icons/IconRightGradient';
    import CashierRobuxElement from '@/components/cashier/CashierRobuxElement';
    import { getValidatedAmount } from '@/utils/general';

    export default {
        name: 'ModalRobux',
        components: {
            ButtonLoading,
            LoadingAnimation,
            IconLeftGradient,
            IconRightGradient,
            CashierRobuxElement
        },
        data() {
            return {
                modalAmount: null,
                cryptoAmount: 1000,
                usdAmount: process.env.VUE_APP_CONV_RATE_ROBUX
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'modalsSetShow', 'cashierSendCreditDepositSocket']),
            modalValidateInput() {
                this.modalAmount = getValidatedAmount(this.modalAmount);
            },
            modalValidateUsdInput() {
                this.usdAmount = getValidatedAmount(this.usdAmount);
            },
            modalValidateCryptoInput() {
                this.cryptoAmount = getValidatedAmount(this.cryptoAmount);
            },
            modalBackButton() {
                this.modalsSetShow(null);
                setTimeout(() => {
                    this.modalsSetShow('Cashier');
                }, 200);
            },
            modalClearButton() {
                this.modalAmount = null;
            },
            modalActionButton() {
                // this.notificationShow({
                //     type: 'error',
                //     message: 'Credit transactions are still a work in progress.'
                // });

                // return;

                const data = { amount: Math.floor(this.modalAmount * 1000) };
                this.cashierSendCreditDepositSocket(data);
            },
            modifiedToFixed(num, adj = -1) {
                const mathed = adj === -1 ? Math.floor(num * 100) : Math.ceil(num * 100);
                const divided = mathed / 100;
                return parseFloat(divided.toFixed(2));
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'modalsData',
                'cashierRobuxData',
                'userBalanceData'
            ]),
            modalGetAmount() {
                let amount = Math.floor(this.modalAmount * 100) / 100;

                if (amount === null || isNaN(amount) === true) {
                    amount = 0;
                }

                return amount;
            }
        },
        watch: {
            usdAmount: {
                handler() {
                    const crypto = this.modifiedToFixed(
                        (this.usdAmount || 0) * (1000 / process.env.VUE_APP_CONV_RATE_ROBUX),
                        -1
                    );
                    if (crypto !== this.cryptoAmount) this.cryptoAmount = crypto;
                }
            },
            cryptoAmount: {
                handler() {
                    const usd = this.modifiedToFixed(
                        (process.env.VUE_APP_CONV_RATE_ROBUX / 1000) * (this.cryptoAmount || 0),
                        1
                    );
                    if (usd !== this.usdAmount) this.usdAmount = usd;
                }
            },
            modalAmount: {
                handler() {
                    const usd = this.modifiedToFixed(
                        (process.env.VUE_APP_CONV_RATE_ROBUX / 1000) * (this.modalAmount || 0),
                        1
                    );
                    if (usd !== this.usdAmount) this.usdAmount = usd;
                    if (this.cryptoAmount !== this.modalAmount)
                        this.cryptoAmount = this.modalAmount;
                }
            }
        }
    };
</script>

<style module>
    .cashier-credit-tab {
        width: 100%;
    }

    .cashier-credit-tab .tab-title {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 7px;
        color: #5885ae;
    }

    .cashier-credit-tab .tab-conversion {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
        gap: 7px;
    }

    .cashier-credit-tab .tab-amount {
        position: relative;
        background: linear-gradient(45deg, #0f2941, #0c2339);
        height: 57px;
        font-size: 5px;
        flex: 1;
        clip-path: var(--octagonal-main);
    }

    .cashier-credit-tab .tab-amount::before {
        font-size: 5px;
        background: #122c47;
    }

    .cashier-credit-tab .tab-amount:has(input:focus)::before {
        filter: brightness(1.8);
    }

    .cashier-credit-tab .tab-amount input {
        appearance: none;
        background: none;
        font-size: 15px;
        font-weight: 600;
        color: #5186ae;
        width: 100%;
        height: 100%;
        padding-left: 57px;
    }

    .cashier-credit-tab .amount-icon {
        width: 33px;
        height: 33px;
        position: absolute;
        background: #153453;
        top: 12px;
        left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: var(--octagonal-main);
        font-size: 3px;
    }

    .cashier-credit-tab .amount-clear {
        position: absolute;
        top: 13px;
        right: 13px;
        height: 30px;
        width: 70px;
        display: flex;
        align-items: center;
        font-weight: 500;
        justify-content: center;
        color: #6285ab;
        filter: drop-shadow(0px 1px 0px #00000059);
    }

    .cashier-credit-tab .amount-clear::before,
    .cashier-credit-tab .amount-clear::after {
        font-size: 3px;
    }

    .cashier-credit-tab .amount-clear::before {
        background: #1a3756;
    }

    .cashier-credit-tab .amount-clear::after {
        background: #15314c;
    }

    .cashier-credit-tab .tab-button {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        margin-top: 24px;
        gap: 14px;
    }

    .cashier-credit-tab .tab-button::before,
    .cashier-credit-tab .tab-button::after {
        content: '';
        flex: 1;
        height: 1px;
        background: #122e48;
    }

    .cashier-credit-tab .tab-button .button-action {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0px 20px;
        height: 34px;
        z-index: 1;
        filter: drop-shadow(0px 2px 0px #00000059);
    }

    .cashier-credit-tab .tab-button .button-action .action-content {
        display: flex;
        align-items: center;
        gap: 7px;
        justify-content: center;
        color: white;
        font-weight: 600;
        font-size: 15px;
    }

    .cashier-credit-tab .tab-button .button-action::before {
        background: linear-gradient(
            180deg,
            rgba(0, 255, 194, 0) 27.9%,
            rgba(0, 255, 194, 0.35) 104.05%
        );
        font-size: 3px;
    }

    .cashier-credit-tab .tab-button .button-action::after {
        background: linear-gradient(0deg, #1e6059, #236b65);
        font-size: 3px;
    }

    .cashier-credit-tab .tab-button .button-action img {
        margin-bottom: -3px;
    }

    .cashier-credit-tab .tab-note {
        padding: 12px;
        gap: 12px;
        border: 1px solid #0b315259;
        margin-bottom: 8px;
        border-radius: 8px;
        background: #051c3059;
        display: flex;
        color: #5b84ae;
        font-size: 14px;
        font-weight: 400;
    }

    .cashier-credit-tab .tab-note p {
        flex: 1;
    }

    .cashier-credit-tab .offers-header {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        color: #5b85ae;
        height: 38px;
        border-radius: 8px;
        border: 1px solid #0b315259;
        background: #0b2034;
        padding: 0px 18px;
        margin-bottom: 16px;
    }

    .cashier-credit-tab .offers-header .header-amount,
    .cashier-credit-tab .offers-header .header-state,
    .cashier-credit-tab .offers-header .header-date {
        width: 30%;
    }

    .cashier-credit-tab .offers-header .header-action {
        text-align: end;
        width: 10%;
    }

    .cashier-credit-tab .content-loading {
        margin: auto;
        width: fit-content;
    }

    .cashier-credit-tab .content-empty {
        font-size: 14px;
        color: #5885ae;
        font-weight: 500;
        text-align: center;
    }

    .cashier-credit-tab .offers-pagination {
        border-top: 1px solid #0a2f4d;
        margin-top: 20px;
        padding-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        color: #5b85ae;
    }

    .cashier-credit-tab .pagination-info span {
        color: white;
    }

    .cashier-credit-tab .offers-pagination .button-next svg,
    .cashier-credit-tab .offers-pagination .button-prev svg {
        fill: #5b85ae;
    }

    .cashier-credit-tab .offers-pagination .button-next,
    .cashier-credit-tab .offers-pagination .button-prev {
        height: 35px;
        width: 35px;
        position: relative;
        filter: drop-shadow(0px 1px 0px #00000059);
        z-index: 1;
    }

    .cashier-credit-tab .offers-pagination .button-next::after,
    .cashier-credit-tab .offers-pagination .button-prev::after {
        background: #102940;
        font-size: 3px;
    }

    .cashier-credit-tab .offers-pagination .button-next::before,
    .cashier-credit-tab .offers-pagination .button-prev::before {
        background: #102940;
        font-size: 3px;
    }

    .cashier-credit-tab .offers-pagination .button-next::before,
    .cashier-credit-tab .offers-pagination .button-prev::before,
    .cashier-credit-tab .tab-button .button-action::before,
    .cashier-credit-tab .tab-amount::before,
    .cashier-credit-tab .amount-clear::before {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: '';
        z-index: -1;
        clip-path: var(--octagonal-before);
    }

    .cashier-credit-tab .offers-pagination .button-next::after,
    .cashier-credit-tab .offers-pagination .button-prev::after,
    .cashier-credit-tab .tab-button .button-action::after,
    .cashier-credit-tab .amount-clear::after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: '';
        clip-path: var(--octagonal-after);
        z-index: -1;
    }

    @media screen and (max-width: 800px) {
        .cashier-credit-tab .offers-header {
            display: none;
        }
    }
</style>
