<template>
    <div
        ref="unbox-spinner"
        v-bind:class="[$style['unbox-spinner'], $style['spinner-' + unboxCount]]"
    >
        <ol :class="$style['spinner-inner']">
            <li
                v-for="i in unboxCount"
                v-bind:key="i"
                v-bind:ref="'spinner-' + i"
                :class="[$style['inner-wheel'], [null, 'a', 'b', 'c', 'd'][unboxCount]]"
            >
                <UnboxReel
                    v-bind:ref="'reel-' + i"
                    v-bind:style="unboxReelStyle[i]"
                    v-bind:reel="unboxReels[i]"
                    v-bind:pos="i"
                    v-bind:running="unboxRunning"
                    v-bind:coinType="unboxGetCoinType"
                />
            </li>
        </ol>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import UnboxReel from '@/components/unbox/UnboxReel';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';

    export default {
        name: 'UnboxSpinner',
        components: {
            UnboxReel
        },
        data() {
            return {
                unboxReelsSpinTimeout: null,
                unboxReelsPosRepeater: {
                    1: null,
                    2: null,
                    3: null,
                    4: null
                },
                unboxReels: {
                    1: [],
                    2: [],
                    3: [],
                    4: []
                },
                unboxReelStyle: {
                    1: {
                        transform: `translateX(-${this.unboxGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    },
                    2: {
                        transform: `translateX(-${this.unboxGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    },
                    3: {
                        transform: `translateX(-${this.unboxGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    },
                    4: {
                        transform: `translateX(-${this.unboxGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    }
                }
            };
        },
        methods: {
            ...mapActions(['unboxSetRunnning']),
            unboxGetItemsFormated(items) {
                return items.map((item) => {
                    const color = getLimitedItemColorFromAmount(item.item.amountFixed / 1000);
                    return { ...item, color };
                });
            },
            unboxGetOutcomeItem(game) {
                let pos = 0;
                let outcomeItem = null;

                for (const item of this.unboxGetItemsFormated(this.unboxBoxData.box.items)) {
                    pos = pos + item.tickets;
                    if (game.outcome <= pos) {
                        outcomeItem = item;
                        break;
                    }
                }

                return outcomeItem;
            },
            unboxAddReels(fresh) {
                let items = this.unboxGetItems;

                const unboxReels = { 1: [], 2: [], 3: [], 4: [] };

                for (const reel of Object.keys(unboxReels)) {
                    for (let i = 0; i < 80; i++) {
                        unboxReels[reel].push(items[Math.floor(Math.random() * items.length)]);
                    }

                    const fillerLength = 11;
                    const fillerIndex = (this.unboxGames.length === 0 || fresh ? 21 : 61) - 6 - 1;
                    const fillerItems =
                        this.unboxReels[reel]?.slice(fillerIndex, fillerIndex + fillerLength) || [];
                    if (fillerItems.length === fillerLength) {
                        const insertFillerIndex = 15 - 1;
                        unboxReels[reel] = [
                            ...unboxReels[reel].slice(0, insertFillerIndex),
                            ...fillerItems,
                            ...unboxReels[reel].slice(insertFillerIndex + fillerLength)
                        ];
                    }
                }

                this.unboxReels = unboxReels;
            },
            unboxGetItemPercentOffset(index, offset = 0.5) {
                const height = 105;
                const gap = 20;
                const total = (105 + 20) * 80 - 20;
                return ((index * (height + gap) + Math.ceil(height * offset)) / total) * 100;
            }
        },
        computed: {
            ...mapGetters([
                'soundVolume',
                'soundTick',
                'soundUnbox',
                'generalTimeDiff',
                'unboxCount',
                'unboxRunning',
                'unboxGames',
                'unboxBoxData',
                'userBalanceData',
                'soundCommon',
                'soundUncommon',
                'soundRare',
                'soundEpic'
            ]),
            unboxGetItems() {
                return this.unboxGetItemsFormated(this.unboxBoxData.box.items);
                // let items = [];

                // for (let item of this.unboxGetItemsFormated(this.unboxBoxData.box.items)) {
                //     const count = Math.floor(item.tickets / 1000);
                //     for (let i = 0; i < (count <= 0 ? 1 : count); i++) {
                //         items.push(item);
                //     }
                // }

                // return items;
            },
            unboxGetCoinType() {
                return this.unboxGames[0]?.coinType || this.userBalanceData.type;
            }
        },
        watch: {
            unboxCount: {
                handler() {
                    if (this.unboxCount === 1) {
                        this.unboxReelStyle[1] = {
                            transform: `translateX(-${this.unboxGetItemPercentOffset(20)}%)`,
                            transition: 'none'
                        };
                    } else {
                        this.unboxReelStyle = {
                            1: {
                                transform: `translateY(-${this.unboxGetItemPercentOffset(20)}%)`,
                                transition: 'none'
                            },
                            2: {
                                transform: `translateY(-${this.unboxGetItemPercentOffset(20)}%)`,
                                transition: 'none'
                            },
                            3: {
                                transform: `translateY(-${this.unboxGetItemPercentOffset(20)}%)`,
                                transition: 'none'
                            },
                            4: {
                                transform: `translateY(-${this.unboxGetItemPercentOffset(20)}%)`,
                                transition: 'none'
                            }
                        };
                    }
                }
            },
            unboxGames: {
                deep: true,
                handler(data, dataOld) {
                    if (this.unboxGames.length >= 1) {
                        if (dataOld.length !== 0) {
                            this.unboxAddReels(dataOld.length !== data.length);
                        }

                        for (const [index, game] of this.unboxGames.entries()) {
                            if (this.unboxCount === 1) {
                                this.unboxReelStyle[index + 1] = {
                                    transform: `translateX(-${this.unboxGetItemPercentOffset(20)}%)`,
                                    transition: 'none'
                                };
                            } else {
                                this.unboxReelStyle[index + 1] = {
                                    transform: `translateY(-${this.unboxGetItemPercentOffset(20)}%)`,
                                    transition: 'none'
                                };
                            }

                            const unboxOutcomeItem = this.unboxGetOutcomeItem(game);

                            this.unboxReels[index + 1][60] = unboxOutcomeItem;

                            setTimeout(() => {
                                const timeEnding =
                                    new Date(game.updatedAt).getTime() +
                                    3000 +
                                    Math.round(Math.random() * 2000);
                                let timeLeft =
                                    timeEnding -
                                    (new Date().getTime() +
                                        (game.demo !== true ? this.generalTimeDiff : 0));
                                timeLeft = timeLeft > 0 ? timeLeft : 0;

                                const spinPos =
                                    Math.random() < 0.33
                                        ? this.unboxGetItemPercentOffset(61, Math.random() * 0.15)
                                        : Math.random() < 0.5
                                          ? this.unboxGetItemPercentOffset(60, Math.random())
                                          : this.unboxGetItemPercentOffset(
                                                59,
                                                0.95 + Math.random() * 0.05
                                            );

                                if (this.unboxCount === 1) {
                                    this.unboxReelStyle[index + 1] = {
                                        transform: `translateX(-${this.unboxGetItemPercentOffset(20, 0)}%)`,
                                        transition: 'transform 0.5s cubic-bezier(0.1, 0, 0.2, 1)'
                                    };
                                    setTimeout(() => {
                                        this.unboxReelStyle[index + 1] = {
                                            transform: `translateX(-${spinPos}%)`,
                                            transition:
                                                'transform ' +
                                                (timeLeft - 500) / 1000 +
                                                's cubic-bezier(0.1, 0, 0.2, 1)'
                                        };
                                    }, 500);
                                } else {
                                    this.unboxReelStyle[index + 1] = {
                                        transform: `translateY(-${this.unboxGetItemPercentOffset(20, 0)}%)`,
                                        transition: 'transform 0.5s cubic-bezier(0.1, 0, 0.2, 1)'
                                    };
                                    setTimeout(() => {
                                        this.unboxReelStyle[index + 1] = {
                                            transform: `translateY(-${spinPos}%)`,
                                            transition:
                                                'transform ' +
                                                (timeLeft - 500) / 1000 +
                                                's cubic-bezier(0.1, 0, 0.2, 1)'
                                        };
                                    }, 500);
                                }

                                this.unboxReelsSpinTimeout = setTimeout(() => {
                                    if (this.unboxCount === 1) {
                                        this.unboxReelStyle[index + 1] = {
                                            transform: `translateX(-${this.unboxGetItemPercentOffset(60)}%)`,
                                            transition:
                                                'transform 0.25s cubic-bezier(0.1, 0, 0.2, 1)'
                                        };
                                    } else {
                                        this.unboxReelStyle[index + 1] = {
                                            transform: `translateY(-${this.unboxGetItemPercentOffset(60)}%)`,
                                            transition:
                                                'transform 0.25s cubic-bezier(0.1, 0, 0.2, 1)'
                                        };
                                    }

                                    if (this.unboxReelsPosRepeater[index + 1])
                                        cancelAnimationFrame(this.unboxReelsPosRepeater[index + 1]);
                                    setTimeout(() => {
                                        this.unboxSetRunnning(false);
                                        let soundKey = 'soundCommon';

                                        switch (unboxOutcomeItem.color) {
                                            case 'green':
                                                soundKey = 'soundUncommon';
                                                break;
                                            case 'purple':
                                                soundKey = 'soundRare';
                                                break;
                                            case 'yellow':
                                            case 'red':
                                                soundKey = 'soundEpic';
                                                break;
                                        }

                                        this[soundKey].volume = this.soundVolume;
                                        this[soundKey].currentTime = 0;
                                        this[soundKey].play();
                                    }, 250);
                                }, timeLeft + 100);
                            }, 250);
                        }
                    }
                }
            }
        },
        created() {
            this.unboxAddReels();
        },
        beforeDestroy() {
            this.unboxSetRunnning(false);
            clearTimeout(this.unboxReelsSpinTimeout);
            for (const repeater of Object.keys(this.unboxReelsPosRepeater)) {
                if (this.unboxReelsPosRepeater[repeater]) {
                    cancelAnimationFrame(this.unboxReelsPosRepeater[repeater]);
                }
            }
        }
    };
</script>

<style module>
    .unbox-spinner {
        width: 100%;
        height: 254px;
        position: relative;
        padding: 1px;
        z-index: 1;
    }

    .unbox-spinner::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #0b2840;
        clip-path: polygon(
            24px 0,
            calc(50% - 8px) 0,
            50% 8px,
            calc(50% + 8px) 0,
            calc(100% - 24px) 0,
            100% 15%,
            100% 85%,
            calc(100% - 24px) 100%,
            calc(50% + 8px) 100%,
            50% calc(100% - 8px),
            calc(50% - 8px) 100%,
            24px 100%,
            0 85%,
            0 15%
        );
        z-index: -1;
    }

    .unbox-spinner.spinner-2::before,
    .unbox-spinner.spinner-3::before,
    .unbox-spinner.spinner-4::before {
        clip-path: polygon(
            24px 0,
            calc(100% - 24px) 0,
            100% 15%,
            100% calc(50% - 8px),
            calc(100% - 8px) 50%,
            100% calc(50% + 8px),
            100% 85%,
            calc(100% - 24px) 100%,
            24px 100%,
            0 85%,
            0 calc(50% + 8px),
            8px 50%,
            0 calc(50% - 8px),
            0 15%
        );
    }

    .unbox-spinner::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background: #020f1a66;
        clip-path: polygon(
            24px 0,
            calc(50% - 8px) 0,
            50% 8px,
            calc(50% + 8px) 0,
            calc(100% - 24px) 0,
            100% 15%,
            100% 85%,
            calc(100% - 24px) 100%,
            calc(50% + 8px) 100%,
            50% calc(100% - 8px),
            calc(50% - 8px) 100%,
            24px 100%,
            0 85%,
            0 15%
        );
        z-index: -1;
    }

    .unbox-spinner.spinner-2::after,
    .unbox-spinner.spinner-3::after,
    .unbox-spinner.spinner-4::after {
        clip-path: polygon(
            24px 0,
            calc(100% - 24px) 0,
            100% 15%,
            100% calc(50% - 8px),
            calc(100% - 8px) 50%,
            100% calc(50% + 8px),
            100% 85%,
            calc(100% - 24px) 100%,
            24px 100%,
            0 85%,
            0 calc(50% + 8px),
            8px 50%,
            0 calc(50% - 8px),
            0 15%
        );
    }

    .unbox-spinner .spinner-inner {
        list-style-type: none;
        width: 100%;
        height: 100%;
        display: flex;
    }

    .unbox-spinner .inner-wheel {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .unbox-spinner .inner-wheel::before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: linear-gradient(0deg, #0b2840, #0b284066, #0b2840);
    }

    .unbox-spinner .inner-wheel > * {
        left: 50%;
        top: unset;
        position: absolute;
    }

    .unbox-spinner.spinner-2 .inner-wheel > *,
    .unbox-spinner.spinner-3 .inner-wheel > *,
    .unbox-spinner.spinner-4 .inner-wheel > * {
        top: 50%;
        left: unset;
        position: absolute;
    }

    .unbox-spinner .inner-wheel:last-child::before {
        display: none;
    }

    .unbox-spinner.spinner-2 .inner-wheel {
        width: 50%;
    }

    .unbox-spinner.spinner-3 .inner-wheel {
        width: 33.33%;
    }

    .unbox-spinner.spinner-4 .inner-wheel {
        width: 25%;
    }

    .unbox-spinner .inner-wheel > * {
        font-size: 1.2px;
    }

    @media only screen and (max-width: 900px) {
        .unbox-spinner.spinner-1 {
            height: 200px;
        }

        .unbox-spinner .inner-wheel > * {
            font-size: 1px;
        }
    }

    @media only screen and (max-width: 700px) {
        .unbox-spinner.spinner-1 {
            height: 175px;
        }

        .unbox-spinner .inner-wheel > * {
            font-size: 0.8px;
        }
    }

    @media only screen and (max-width: 500px) {
        .unbox-spinner.spinner-1 {
            height: 150px;
        }

        .unbox-spinner .inner-wheel > * {
            font-size: 0.6px;
        }
    }
</style>
