<template>
    <div :class="$style['modal-blackjack-rules']">
        <div :class="$style['rules-content']">
            <h1><span class="fade-lime">Blackjack Game Rules</span></h1>
            <br />
            <br />

            <h2>1. Blackjack</h2>
            <h4>1.1 <span class="fade-lime">1.5x - Blackjack</span></h4>
            <p>Achieving a total of 21 pays 1.5 times your original bet.</p>
            <br />
            <br />

            <h2>2. 21 + 3 Payouts</h2>
            <h4>2.1 <span class="fade-lime">5x - Flush</span></h4>
            <p>A Flush pays 5 times your bet when you have three cards of the same suit.</p>

            <h4>2.2 <span class="fade-lime">10x - Straight</span></h4>
            <p>
                A Straight pays 10 times your bet for three consecutive cards in any suit, such as
                2, 3, 4; Aces count as high or low.
            </p>

            <h4>2.3 <span class="fade-lime">30x - Three of a Kind</span></h4>
            <p>
                Three of a Kind rewards you with 30 times your bet when you have three cards of the
                same rank or face value.
            </p>

            <h4>2.4 <span class="fade-lime">40x - Straight Flush</span></h4>
            <p>
                A Straight Flush pays 40 times your bet for three consecutive cards all in the same
                suit.
            </p>

            <h4>2.5 <span class="fade-lime">100x - Suited Three of a Kind</span></h4>
            <p>
                A Suited Three of a Kind grants you 100 times your bet when all three cards are of
                the same rank and suit.
            </p>
            <br />
            <br />

            <h2>3. Perfect Pairs</h2>
            <h4>3.1 <span class="fade-lime">25x - Perfect Pair</span></h4>
            <p>
                A Perfect Pair pays 25 times your side bet when you have two identical cards in both
                rank and suit.
            </p>

            <h4>3.2 <span class="fade-lime">10x - Colored Pair</span></h4>
            <p>
                A Colored Pair rewards you with 10 times your side bet for two cards of the same
                rank and color but different suits.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalBlackjackRules'
    };
</script>

<style module>
    .modal-blackjack-rules {
        width: 600px;
        position: relative;
        padding: 25px;
    }

    .rules-content {
        width: 100%;
        max-width: 1345px;
        background: #081624;
        padding: 20px;
        border-radius: 15px;
    }

    .modal-blackjack-rules h1 {
        font-size: 22px;
        color: white;
        font-weight: 600;
    }

    .modal-blackjack-rules h2 {
        font-size: 20px;
        color: white;
        font-weight: 600;
        padding-top: 7px;
        margin-top: -7px;
        padding-bottom: 28px;
        margin-bottom: -14px;
        white-space: nowrap;
    }

    .modal-blackjack-rules br {
        display: block;
        margin: 9px 0;
    }

    .modal-blackjack-rules h4 {
        font-size: 15px;
        color: white;
        font-weight: 600;
        line-height: 1.5;
    }

    .modal-blackjack-rules p {
        color: #4f6a83;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 12px;
    }

    @media only screen and (max-width: 840px) {
        .modal-blackjack-rules {
            padding: 20px;
        }
    }
</style>
