var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style['duels-user-element'],
        {
            [_vm.$style['element-rolling']]: _vm.duelsIsRolling === true,
            [_vm.$style['element-winner']]:
                (_vm.duelsGame.state === 'completed' || _vm.duelsGame.winner !== undefined) &&
                _vm.bet._id === _vm.duelsGame.winner._id
        }
    ]},[(_vm.bet === undefined)?_c('div',{class:_vm.$style['element-placeholder']},[(_vm.duelsGame.bets.length === _vm.index - 1)?_c('button',{attrs:{"disabled":_vm.socketSendLoading !== null},on:{"click":function($event){return _vm.duelsActionButton()}}},[_c('div',{class:_vm.$style['button-inner']},[_vm._v(" "+_vm._s(_vm.authUser.user !== null && _vm.authUser.user._id === _vm.duelsGame.bets[0].user._id ? 'CALL BOT' : 'JOIN')+" ")])]):_vm._e()]):_c('div',{class:_vm.$style['element-content']},[_c('div',{class:_vm.$style['content-top']},[_c('div',{class:[
                    _vm.$style['top-user'],
                    _vm.$style['user-' + (_vm.bet.bot === true ? 'bot' : _vm.duelsGetRank(_vm.bet.user))],
                    _vm.$style['user-' + (_vm.bet.bot === true ? '' : _vm.duelsGetLevelColor(_vm.bet.user))]
                ]},[_c('AvatarImage',{attrs:{"image":_vm.bet.bot ? _vm.duelsGetBot.avatar : _vm.bet.user.avatar,"rank":_vm.bet.bot ? null : _vm.bet.user.rank,"level":_vm.bet.bot ? null : _vm.bet.user.level}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.bet.bot === true ? _vm.duelsGetBot.username : _vm.bet.user.username)}})],1),_c('div',{class:_vm.$style['top-roll']},[_c('div',{class:_vm.$style['roll-inner']},[(_vm.bet.roll === undefined || _vm.duelsIsRolling === true)?_c('svg',{attrs:{"width":"11","height":"3","viewBox":"0 0 11 3","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 1.16211C0 0.779297 0.102539 0.489909 0.307617 0.293945C0.512695 0.0979818 0.811198 0 1.20312 0C1.58138 0 1.87305 0.10026 2.07812 0.300781C2.28776 0.501302 2.39258 0.788411 2.39258 1.16211C2.39258 1.52214 2.28776 1.80697 2.07812 2.0166C1.86849 2.22168 1.57682 2.32422 1.20312 2.32422C0.820312 2.32422 0.524089 2.22396 0.314453 2.02344C0.104818 1.81836 0 1.53125 0 1.16211Z"}}),_c('path',{attrs:{"d":"M3.99219 1.16211C3.99219 0.779297 4.09473 0.489909 4.2998 0.293945C4.50488 0.0979818 4.80339 0 5.19531 0C5.57357 0 5.86523 0.10026 6.07031 0.300781C6.27995 0.501302 6.38477 0.788411 6.38477 1.16211C6.38477 1.52214 6.27995 1.80697 6.07031 2.0166C5.86068 2.22168 5.56901 2.32422 5.19531 2.32422C4.8125 2.32422 4.51628 2.22396 4.30664 2.02344C4.09701 1.81836 3.99219 1.53125 3.99219 1.16211Z"}}),_c('path',{attrs:{"d":"M7.98438 1.16211C7.98438 0.779297 8.08691 0.489909 8.29199 0.293945C8.49707 0.0979818 8.79557 0 9.1875 0C9.56575 0 9.85742 0.10026 10.0625 0.300781C10.2721 0.501302 10.377 0.788411 10.377 1.16211C10.377 1.52214 10.2721 1.80697 10.0625 2.0166C9.85286 2.22168 9.5612 2.32422 9.1875 2.32422C8.80469 2.32422 8.50846 2.22396 8.29883 2.02344C8.08919 1.81836 7.98438 1.53125 7.98438 1.16211Z"}})]):_c('span',{class:{
                            'fade-green':
                                (_vm.duelsGame.state === 'completed' ||
                                    _vm.duelsGame.winner !== undefined) &&
                                _vm.bet._id === _vm.duelsGame.winner._id
                        }},[_vm._v(" "+_vm._s(parseFloat(_vm.bet.roll / 100).toFixed(2))+" ")])])])]),_c('div',{class:_vm.$style['content-bottom']},[(_vm.duelsIsRolling === true)?_c('span',{staticClass:"fade-green"},[_vm._v("ROLLING")]):_c('div',{class:_vm.$style['bottom-chance']},[_vm._v(" CHANCE: "),_c('span',[_vm._v(_vm._s(parseFloat(100 / _vm.duelsGame.playerCount).toFixed(2))+"%")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }