<template>
    <div :class="$style['modal-cashier']">
        <div :class="$style['cashier-header']">
            <div :class="$style['header-buttons']">
                <button
                    v-if="['Deposit', 'Withdraw', 'Redeem'].includes(modalTab)"
                    v-on:click="modalSetTab('Deposit')"
                    v-bind:class="[
                        $style['button-nav'],
                        { [$style['button-active']]: modalTab === 'Deposit' }
                    ]"
                >
                    <div :class="$style['button-inner']">
                        <span v-bind:class="{ 'fade-green': modalTab === 'Deposit' }">
                            Deposit
                        </span>
                    </div>
                </button>
                <button
                    v-else
                    v-on:click="
                        modalSetTab(modalsData.typeCashier === 'withdraw' ? 'Withdraw' : 'Deposit')
                    "
                    :class="$style['button-nav']"
                >
                    <div :class="$style['button-inner']">
                        <svg
                            :class="$style.back"
                            v-if="modalTab === 'Gift'"
                            width="10"
                            height="9"
                            viewBox="0 0 10 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.21864 3.60238C9.16544 3.59389 9.11161 3.58997 9.05775 3.59066L2.88651 3.59066L3.08649 3.49916C3.28196 3.40815 3.45979 3.28428 3.61202 3.13316L5.18387 1.58689C5.52258 1.26881 5.5795 0.757122 5.31874 0.374506C5.01525 -0.0332154 4.43324 -0.121742 4.01874 0.176807C3.98526 0.200941 3.95343 0.227247 3.92352 0.255555L0.272801 3.84686C-0.0906526 4.204 -0.0909723 4.78334 0.272075 5.14088L0.272801 5.1416L3.92352 8.73291C4.28726 9.08973 4.87619 9.08893 5.23895 8.7311C5.26749 8.70294 5.29415 8.67297 5.31874 8.64141C5.5795 8.25879 5.52258 7.7471 5.18387 7.42902L3.61667 5.87818C3.4802 5.74379 3.3233 5.6311 3.1516 5.5442L2.87255 5.42068L9.00656 5.42068C9.48172 5.43803 9.8986 5.11152 9.98787 4.65207C10.0701 4.15325 9.72571 3.68331 9.21864 3.60238Z"
                                fill="#406992"
                            />
                        </svg>
                        <span>Go back</span>
                    </div>
                </button>

                <button
                    v-if="['Deposit', 'Withdraw', 'Redeem'].includes(modalTab)"
                    v-on:click="modalSetTab('Withdraw')"
                    v-bind:class="[
                        $style['button-nav'],
                        $style['iconed'],
                        { [$style['button-active']]: modalTab === 'Withdraw' }
                    ]"
                >
                    <div :class="$style['button-inner']">
                        <span v-bind:class="{ 'fade-green': modalTab === 'Withdraw' }">
                            Withdraw
                        </span>
                    </div>
                </button>
                <button v-else :class="[$style['button-nav'], $style['button-active']]">
                    <div :class="$style['button-inner']">
                        <img
                            v-bind:src="
                                require(
                                    `@/assets/img/cashier/${(function () {
                                        let image = 'robux-icon.png';

                                        switch (modalsData.currency || modalTab) {
                                            case 'Credit':
                                                image = 'credit.png';
                                                break;
                                            case 'Gift':
                                                image = 'kinguin.png';
                                                break;
                                            case 'btc':
                                            case 'eth':
                                            case 'ltc':
                                            case 'usdt':
                                            case 'sol':
                                            case 'usdc':
                                                image = `${modalsData.currency}.png`;
                                                break;
                                        }

                                        return image;
                                    })()}`
                                )
                            "
                        />
                        <span class="fade-green">
                            {{
                                (function () {
                                    let title = modalTab;

                                    if (modalTab === 'Credit') return 'Credit Cards';
                                    if (modalTab === 'Gift') return 'Gift Cards';

                                    switch (modalsData.currency) {
                                        case 'btc':
                                            title = 'Bitcoin';
                                            break;
                                        case 'eth':
                                            title = 'Ethereum';
                                            break;
                                        case 'ltc':
                                            title = 'Litecoin';
                                            break;
                                        case 'usdt':
                                            title = 'USDT (ERC20)';
                                            break;
                                        case 'usdc':
                                            title = 'USDC (ERC20)';
                                            break;
                                        case 'sol':
                                            title = 'Solana';
                                            break;
                                    }

                                    return `${modalsData.typeCashier[0].toUpperCase()}${modalsData.typeCashier.slice(1)} ${title}`;
                                })()
                            }}
                        </span>
                    </div>
                </button>

                <button
                    v-if="['Robux', 'Crypto', 'Proceed', 'Credit'].includes(modalTab) !== true"
                    v-on:click="modalSetTab('Redeem')"
                    v-bind:class="[
                        $style['button-nav'],
                        { [$style['button-active']]: modalTab === 'Redeem' }
                    ]"
                >
                    <div :class="$style['button-inner']">
                        <span v-bind:class="{ 'fade-green': modalTab === 'Redeem' }"> Redeem </span>
                    </div>
                </button>
            </div>
        </div>

        <component v-bind:is="'Cashier' + modalTab + 'Tab'" />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import CashierDepositTab from '@/components/cashier/CashierDepositTab';
    import CashierWithdrawTab from '@/components/cashier/CashierWithdrawTab';
    import CashierRobuxTab from '@/components/cashier/CashierRobuxTab';
    import CashierCreditTab from '@/components/cashier/CashierCreditTab';
    import CashierCryptoTab from '@/components/cashier/CashierCryptoTab';
    import CashierGiftTab from '@/components/cashier/CashierGiftTab';
    import CashierRedeemTab from '@/components/cashier/CashierRedeemTab';

    export default {
        name: 'ModalCashier',
        components: {
            CashierDepositTab,
            CashierWithdrawTab,
            CashierRobuxTab,
            CashierCreditTab,
            CashierCryptoTab,
            CashierGiftTab,
            CashierRedeemTab
        },
        data() {
            return {
                modalTab: 'Deposit'
            };
        },
        methods: {
            ...mapActions(['affiliatesGetDataSocket']),
            modalSetTab(tab) {
                this.modalTab = tab;
            }
        },
        computed: {
            ...mapGetters(['modalsData', 'affiliatesData'])
        },
        created() {
            if (this.modalsData?.cashierTab !== undefined) {
                this.modalTab = this.modalsData.cashierTab;
            }

            if (this.affiliatesData.loading === false) {
                const data = {
                    sort: 'date-descend',
                    page: this.affiliatesData.page
                };
                this.affiliatesGetDataSocket(data);
            }
        }
    };
</script>

<style module>
    .modal-cashier {
        max-width: calc(100vw - 40px);
        width: 820px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 35px 25px 35px;
        border-radius: 15px;
        background: radial-gradient(
                100% 100% at 50% -30%,
                rgba(0, 255, 194, 0.2) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(255deg, #07263d 0%, #07243a 100%);
    }

    .modal-cashier .cashier-header {
        width: 100%;
        padding-bottom: 16px;
        margin-bottom: 20px;
        border-bottom: 1px solid #0a2f4d;
    }

    .modal-cashier .header-buttons {
        display: flex;
        align-items: center;
        background: #051c3066;
        width: fit-content;
        padding: 6px;
        gap: 6px;
        border-radius: 8px;
        border: 1px solid #0b3152;
    }

    .modal-cashier button.button-nav {
        height: 36px;
        position: relative;
        filter: drop-shadow(0px 2px 0px #081928);
        transition: all 0.3s ease;
    }

    .modal-cashier button.button-nav.button-active {
        filter: unset;
    }

    .modal-cashier button.button-nav .button-inner::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #15314b;
        font-size: 3px;
        clip-path: var(--octagonal-before);
    }

    .modal-cashier button.button-nav.button-active .button-inner::before {
        background: linear-gradient(0deg, #2d8273, transparent);
    }

    .modal-cashier button.button-nav .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        padding: 0 18px;
        background-color: #15314c;
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .modal-cashier button.button-nav .button-inner svg.back {
        display: none;
    }

    .modal-cashier button.button-nav.iconed .button-inner {
        padding: 0 14px;
    }

    .modal-cashier button.button-nav .button-inner img {
        width: 16px;
    }

    .modal-cashier button.button-nav.button-active .button-inner {
        background: #17474e;
    }

    .modal-cashier button.button-nav .button-inner svg {
        fill: #bbbfd0;
    }

    .modal-cashier button.button-nav.button-active .button-inner svg {
        fill: #00ffc2;
    }

    .modal-cashier button.button-nav .button-inner span {
        font-size: 16px;
        font-weight: 600;
        color: #6285ab;
    }

    @media only screen and (max-width: 840px) {
        .modal-cashier {
            padding: 20px 20px 12px 20px;
        }
    }

    @media only screen and (max-width: 500px) {
        .modal-cashier .cashier-header {
            justify-content: center;
        }

        .modal-cashier button.button-nav .button-inner {
            padding: 0 14px;
        }

        .modal-cashier button.button-nav .button-inner svg.back {
            display: block;
        }
        .modal-cashier button.button-nav .button-inner:has(svg.back) span {
            display: none;
        }

        .modal-cashier button.button-nav.iconed .button-inner {
            padding: 0 12px;
            gap: 5px;
        }
    }
</style>
