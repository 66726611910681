<template>
    <form :class="$style['login-credentials']" v-on:submit.prevent="loginActionButton()">
        <div v-if="tab === 'login'" :class="$style['credentials-element']">
            <div :class="$style['element-title']">Email / Username</div>
            <div :class="$style['element-input']" class="octagonal hoverable">
                <input
                    v-model="loginUser"
                    v-bind:type="loginUser?.includes('@') ? 'email' : 'text'"
                    required
                />
            </div>
        </div>
        <div v-else :class="$style['credentials-grouped']">
            <div :class="$style['credentials-element']">
                <div :class="$style['element-title']">Email</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input v-model="loginEmail" type="email" required />
                </div>
            </div>
            <div :class="$style['credentials-element']">
                <div :class="$style['element-title']">Username</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input v-model="loginUsername" type="text" required />
                </div>
            </div>
        </div>
        <div :class="$style['credentials-element']">
            <div :class="$style['element-title']">
                Password
                <button
                    type="button"
                    v-if="tab === 'login'"
                    v-on:click="$parent.modalSetTab('forgot')"
                    tabindex="-1"
                    :class="$style['button-forgot']"
                >
                    (forgot password?)
                </button>
            </div>
            <div :class="$style['element-input']" class="octagonal hoverable">
                <input v-model="loginPassword" type="password" required />
            </div>
        </div>
        <div v-if="tab === 'register'" :class="$style['credentials-element']">
            <div :class="$style['element-title']">Confirm Password</div>
            <div :class="$style['element-input']" class="octagonal hoverable">
                <input v-model="loginPasswordConfirm" type="password" required />
            </div>
        </div>
        <div :class="$style['credentials-button']">
            <div :class="$style.divider"></div>
            <button
                type="submit"
                :class="$style['button-action']"
                class="octagonal hoverable"
                v-bind:disabled="authSendLoginLoading === true"
            >
                <span>{{ tab[0]?.toUpperCase() }}{{ tab.slice(1)?.toLowerCase() }}</span>
            </button>
            <div :class="$style.divider"></div>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'LoginCredentials',
        props: ['tab'],
        data() {
            return {
                // For register
                loginUsername: null,
                loginEmail: null,
                // For login
                loginUser: null,
                // For both
                loginPassword: null,
                // For register
                loginPasswordConfirm: null
            };
        },
        methods: {
            ...mapActions([
                'modalsSetShow',
                'modalsSetData',
                'notificationShow',
                'authSendCredentialsLogin',
                'authSendCredentialsRegister'
            ]),
            loginActionButton() {
                if (this.loginPassword === null || this.loginPassword.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered password is invalid.'
                    });
                    return;
                }

                if (this.tab === 'register') {
                    if (this.loginPassword !== this.loginPasswordConfirm) {
                        this.notificationShow({
                            type: 'error',
                            message: 'Your entered passwords does not match.'
                        });
                        return;
                    }

                    if (this.loginUsername === null || this.loginUsername.trim() === '') {
                        this.notificationShow({
                            type: 'error',
                            message: 'Your entered username is invalid.'
                        });
                        return;
                    }

                    if (this.loginEmail === null || this.loginEmail.trim() === '') {
                        this.notificationShow({
                            type: 'error',
                            message: 'Your entered email is invalid.'
                        });
                        return;
                    }

                    this.modalsSetData({
                        typeCaptcha: 'credentialsRegister',
                        data: {
                            username: this.loginUsername,
                            email: this.loginEmail,
                            password: this.loginPassword
                        }
                    });
                } else {
                    if (this.loginUser === null || this.loginUser.trim() === '') {
                        this.notificationShow({
                            type: 'error',
                            message: 'Your entered email / username is invalid.'
                        });
                        return;
                    }

                    this.modalsSetData({
                        typeCaptcha: 'credentialsLogin',
                        data: { user: this.loginUser, password: this.loginPassword }
                    });
                }

                setTimeout(() => {
                    this.modalsSetShow('Captcha');
                }, 200);
            },
            loginTermsButton() {
                this.modalsSetShow(null);
                setTimeout(() => {
                    this.modalsSetShow('Terms');
                }, 300);
            }
        },
        computed: {
            ...mapGetters(['authSendLoginLoading'])
        }
    };
</script>

<style module>
    .login-credentials {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .credentials-grouped {
        display: flex;
        width: 100%;
        gap: 14px;
    }

    .credentials-grouped .credentials-element {
        width: 100%;
    }

    .credentials-element .element-title {
        margin-bottom: 7px;
        color: #5b85ae;
        font-size: 15px;
    }

    .element-title .button-forgot {
        margin-left: 2px;
        color: #5b85ae;
        opacity: 0.5;
    }

    .credentials-element .element-input input {
        background: none;
        height: 57px;
        color: #5b85ae;
        width: 100%;
        font-size: 14px;
        padding: 14px 20px;
        appearance: none;
    }

    .credentials-element .element-input {
        background: linear-gradient(0deg, #0f426b55, #0d335555);
        width: 100%;
        position: relative;
        clip-path: var(--octagonal-main);
        color: #5b85ae;
        font-size: 7px;
    }

    .credentials-element .element-input::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #1e4b7e55;
        clip-path: var(--octagonal-before);
    }

    .credentials-button {
        display: flex;
        align-items: center;
        gap: 14px;
    }

    .credentials-button .divider {
        height: 1px;
        background: #0a2f4d;
        flex: 1;
    }

    .credentials-button .divider:last-child {
        max-width: 24px;
    }

    .credentials-button .button-action {
        font-size: 16px;
        font-weight: 500;
        height: 34px;
        color: white;
        padding: 5px 14px;
        position: relative;
    }

    .credentials-button span {
        position: relative;
        z-index: 1;
    }

    .credentials-button .button-action::before,
    .credentials-button .button-action::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .credentials-button .button-action::before {
        background: linear-gradient(0deg, #00ffc2, transparent);
        clip-path: var(--octagonal-before);
        font-size: 5px;
    }

    .credentials-button .button-action::after {
        background: linear-gradient(45deg, #37a970, #50e9b0);
        clip-path: var(--octagonal-after);
        font-size: 5px;
    }

    @media screen and (max-width: 880px) {
        .credentials-grouped {
            flex-direction: column;
        }
    }
</style>
