<template>
    <div
        v-bind:class="[
            $style['battles-filter-price'],
            { [$style['price-open']]: battlesDropdown === true }
        ]"
    >
        <button v-on:click="battlesToggleDropdown()" :class="$style['button-toggle']">
            <div :class="$style['button-inner']">
                <div :class="$style['inner-value']">
                    <img
                        v-if="userBalanceData.type === 'cryptoCoin'"
                        src="@/assets/img/icons/cryptoCoin.svg"
                        alt="icon"
                    />
                    <img
                        v-else-if="userBalanceData.type === 'robuxCoin'"
                        src="@/assets/img/icons/robuxCoin.svg"
                        alt="icon"
                    />
                    PRICE RANGE:
                    <span>{{ battlesFilterPrice }}</span>
                </div>
                <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.5176 1.66411e-06L0.482354 8.43375e-08C0.0547936 9.58042e-09 -0.16302 0.516304 0.143533 0.822859L4.66115 5.34052C4.8467 5.52607 5.15325 5.52607 5.33888 5.34052L9.8565 0.822861C10.163 0.516306 9.94516 1.73887e-06 9.5176 1.66411e-06Z"
                    />
                </svg>
            </div>
        </button>
        <div :class="$style['price-menu']">
            <div :class="$style['menu-inner']">
                <button v-on:click="battlesPriceButton('ALL')">ALL</button>
                <button v-on:click="battlesPriceButton('0 - 5K')">0 - 5K</button>
                <button v-on:click="battlesPriceButton('5K - 25K')">5K - 25K</button>
                <button v-on:click="battlesPriceButton('25K - 100K')">25K - 100K</button>
                <button v-on:click="battlesPriceButton('+100K')">+100K</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BattlesFilterPrice',
        data() {
            return {
                battlesDropdown: false
            };
        },
        methods: {
            ...mapActions(['battlesSetFilterPrice']),
            battlesToggleDropdown() {
                this.battlesDropdown = !this.battlesDropdown;
            },
            battlesPriceButton(value) {
                this.battlesSetFilterPrice(value);
                this.battlesToggleDropdown();
            }
        },
        computed: {
            ...mapGetters(['battlesFilterPrice', 'userBalanceData'])
        },
        created() {
            let self = this;
            document.addEventListener('click', function (event) {
                if (!self.$el.contains(event.target) && self.battlesDropdown == true) {
                    self.battlesToggleDropdown();
                }
            });
        }
    };
</script>

<style module>
    .battles-filter-price {
        width: 100%;
        max-width: 220px;
        position: relative;
        z-index: 5;
    }

    .battles-filter-price button.button-toggle {
        width: 100%;
        height: 46px;
        position: relative;
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
    }

    .battles-filter-price button.button-toggle .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        padding: 0 15px;
        font-size: 5px;
        background: linear-gradient(
            360deg,
            rgba(15, 66, 107, 0.5) 8.33%,
            rgba(13, 51, 85, 0.5) 108.33%
        );
        clip-path: var(--octagonal-main);
    }

    .battles-filter-price button.button-toggle .button-inner svg {
        width: 9px;
        fill: #bbbfd0;
        transition: all 0.3s ease;
    }

    .battles-filter-price.price-open button.button-toggle .button-inner svg {
        transform: rotate(180deg);
    }

    .battles-filter-price button.button-toggle .inner-value {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: #49687d;
    }

    .battles-filter-price button.button-toggle .inner-value img {
        width: 13px;
        height: 13px;
        margin-right: 7px;
    }

    .battles-filter-price button.button-toggle .inner-value span {
        text-transform: uppercase;
        margin-left: 5px;
        font-size: 13px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-filter-price .price-menu {
        width: calc(100% - 18px);
        height: 0;
        position: absolute;
        top: 51px;
        left: 9px;
        overflow: hidden;
        transition: height 0.2s ease;
        z-index: -1;
    }

    .battles-filter-price.price-open .price-menu {
        height: 237px;
    }

    .battles-filter-price .menu-inner {
        width: 100%;
        background: #061d30;
        border: 1px solid #0a273f;
    }

    .battles-filter-price .menu-inner button {
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        font-size: 14px;
        font-weight: 600;
        color: #8bacc8;
        border-bottom: 1px solid #0a273f;
        transition: all 0.3s ease;
    }

    .battles-filter-price .menu-inner button:last-child {
        border-bottom: none;
    }

    .battles-filter-price .menu-inner button:hover {
        background: rgba(19, 66, 88, 0.15);
    }
</style>
