<template>
    <div :class="$style['cashier-filter-search']" class="octagonal hoverable">
        <input
            v-on:change="cashierSetValue"
            v-model="cashierValue"
            type="text"
            placeholder="SEARCH ITEMS"
        />
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.7204 9.43396H10.0429L9.80274 9.2024C10.6432 8.2247 11.1492 6.9554 11.1492 5.57461C11.1492 2.49571 8.65352 0 5.57461 0C2.49571 0 0 2.49571 0 5.57461C0 8.65352 2.49571 11.1492 5.57461 11.1492C6.9554 11.1492 8.2247 10.6432 9.2024 9.80274L9.43396 10.0429V10.7204L13.7221 15L15 13.7221L10.7204 9.43396ZM5.57461 9.43396C3.43911 9.43396 1.71527 7.71012 1.71527 5.57461C1.71527 3.43911 3.43911 1.71527 5.57461 1.71527C7.71012 1.71527 9.43396 3.43911 9.43396 5.57461C9.43396 7.71012 7.71012 9.43396 5.57461 9.43396Z"
                fill="#406992"
            />
        </svg>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'CashierFilterSerach',
        data() {
            return {
                cashierValue: ''
            };
        },
        methods: {
            ...mapActions(['cashierSetFilterSearch']),
            cashierSetValue() {
                this.cashierSetFilterSearch(this.cashierValue);
            }
        }
    };
</script>

<style module>
    .cashier-filter-search {
        width: 100%;
        max-width: 179px;
        min-width: 120px;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        position: relative;
        font-size: 5px;
        clip-path: var(--octagonal-main);
        background: #0a2036;
        height: 44px;
    }

    .cashier-filter-search svg {
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
    }

    .cashier-filter-search input {
        appearance: none;
        background: none;
        height: 100%;
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: white;
        font-size: 14px;
        padding-left: 38px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cashier-filter-search input::placeholder {
        color: #406992;
    }

    .cashier-filter-search::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        font-size: 5px;
        background: #112942;
        clip-path: var(--octagonal-before);
    }
</style>
