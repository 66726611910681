<template>
    <div :class="$style['blackjack-game']">
        <div :class="$style['game-controls']">
            <div v-if="table.game.state === 'created'" :class="$style['controls-info']">
                WAITING FOR PLAYERS TO JOIN...
            </div>
            <BlackjackControlsBet
                v-bind:table="table"
                v-bind:blackjackChip="blackjackChip"
                v-if="table.game.state === 'countdown'"
            />
            <BlackjackControlsAction
                v-bind:table="table"
                v-bind:active="
                    authUser.user !== null &&
                    table.game.state === 'running' &&
                    table.playersPos !== null &&
                    (table.playersPos === 'all' ||
                        authUser.user._id === blackjackGetPlayer(table.playersPos).user._id) &&
                    new Date().getTime() + generalTimeDiff <=
                        new Date(table.game.updatedAt).getTime() + 1000 * 13
                "
            />
        </div>
        <div :class="$style['game-table']">
            <img
                :class="$style['table-chip-stack']"
                src="@/assets/img/blackjack/table-chip-stack.webp"
            />
            <img :class="$style['table-card-l']" src="@/assets/img/blackjack/table-card-l.webp" />
            <img :class="$style['table-card-r']" src="@/assets/img/blackjack/table-card-r.webp" />
            <img :class="$style['table-logo']" src="@/assets/img/blackjack/table-logo.webp" />
            <img :class="$style['table-top']" src="@/assets/img/blackjack/table.webp" />

            <div
                v-if="['running', 'completed'].includes(table.game.state) === true"
                :class="$style['table-dealer']"
            >
                <transition-group name="dealer" tag="div" :class="$style['dealer-cards']" class="d">
                    <BlackjackCard
                        v-for="(card, index) of table.game.dealerCards"
                        v-bind:key="index + card.suit + card.rank"
                        v-bind:card="card"
                    />
                </transition-group>
                <BlackjackValue
                    v-if="blackjackGetCardValue(table.game.dealerCards)"
                    v-bind:value="blackjackGetCardValue(table.game.dealerCards)"
                    state=""
                    v-bind:style="{
                        top: 84 + (table.game.dealerCards.length - 1) * 15 + 'px',
                        left: 60 + (table.game.dealerCards.length - 1) * 20 + 'px'
                    }"
                />
            </div>
            <ol :class="$style['table-seats']">
                <BlackjackSeatElement
                    v-for="index in 5"
                    v-bind:key="index"
                    v-bind:table="table"
                    v-bind:seat="index - 1"
                    v-bind:blackjackChip="blackjackChip"
                    v-bind:player="blackjackGetPlayer(index - 1)"
                />
            </ol>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import BlackjackCard from '@/components/blackjack/BlackjackCard';
    import BlackjackValue from '@/components/blackjack/BlackjackValue';
    import BlackjackSeatElement from '@/components/blackjack/BlackjackSeatElement';
    import BlackjackControlsBet from '@/components/blackjack/BlackjackControlsBet';
    import BlackjackControlsAction from '@/components/blackjack/BlackjackControlsAction';

    export default {
        name: 'BlackjackGame',
        components: {
            BlackjackCard,
            BlackjackValue,
            BlackjackSeatElement,
            BlackjackControlsBet,
            BlackjackControlsAction
        },
        props: ['table'],
        data() {
            return {
                blackjackChip: 0
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'blackjackSendJoinSocket',
                'blackjackSendBetSocket',
                'blackjackSendInsuranceSocket',
                'blackjackSendHitSocket',
                'blackjackSendStandSocket',
                'blackjackSendSplitSocket',
                'blackjackSendDoubleSocket'
            ]),
            blackjackGetPlayer(seat) {
                return this.table.players[
                    this.table.players.findIndex((element) => element.seat === seat)
                ];
            },
            blackjackGetAmount(chip) {
                const amounts =
                    this.table.game.type === 'standard'
                        ? [25, 250, 1000, 2500]
                        : [2500, 5000, 10000, 25000];
                return amounts[chip];
            },
            blackjackGetCardValue(cards) {
                let value = 0;
                let aces = false;

                for (const card of cards) {
                    if (card.rank === 'A') {
                        value = value + 1;
                        aces = true;
                    } else if (card.rank == 'K' || card.rank == 'Q' || card.rank == 'J') {
                        value = value + 10;
                    } else if (card.rank != 'hidden') {
                        value = value + Math.floor(card.rank);
                    }
                }

                if (aces == true && value <= 11) {
                    value = value + 10;
                }

                return value;
            },
            blackjackSetChip(chip) {
                this.blackjackChip = chip;
            },
            blackjackJoinButton(seat) {
                if (this.socketSendLoading !== null) {
                    return;
                }

                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                const data = {
                    table: this.table.table,
                    seat: seat,
                    type: this.userBalanceData.type
                };
                this.blackjackSendJoinSocket(data);
            },
            blackjackBetButton(seat, type) {
                if (this.socketSendLoading !== null) {
                    return;
                }

                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                let amount = { main: 0, sideLeft: 0, sideRight: 0 };
                amount[type] = Math.floor(this.blackjackGetAmount(this.blackjackChip) * 1000);
                const data = {
                    table: this.table.table,
                    bets: [{ seat: seat, amount: amount, coinType: this.userBalanceData.type }]
                };
                this.blackjackSendBetSocket(data);
            },
            blackjackInsuranceButton(insurance) {
                if (this.socketSendLoading !== null) {
                    return;
                }

                const data = { table: this.table.table, insurance: insurance };
                this.blackjackSendInsuranceSocket(data);
            },
            blackjackHitButton() {
                if (this.socketSendLoading !== null) {
                    return;
                }

                const data = { table: this.table.table, seat: this.table.playersPos };
                this.blackjackSendHitSocket(data);
            },
            blackjackStandButton() {
                if (this.socketSendLoading !== null) {
                    return;
                }

                const data = { table: this.table.table, seat: this.table.playersPos };
                this.blackjackSendStandSocket(data);
            },
            blackjackSplitButton() {
                if (this.socketSendLoading !== null) {
                    return;
                }

                const data = { table: this.table.table, seat: this.table.playersPos };
                this.blackjackSendSplitSocket(data);
            },
            blackjackDoubleButton() {
                if (this.socketSendLoading !== null) {
                    return;
                }

                const data = { table: this.table.table, seat: this.table.playersPos };
                this.blackjackSendDoubleSocket(data);
            }
        },
        computed: {
            ...mapGetters(['generalTimeDiff', 'socketSendLoading', 'authUser', 'userBalanceData'])
        }
    };
</script>

<style scoped>
    .dealer-enter-active {
        transition: all 0.6s;
    }

    .dealer-enter {
        transform: translate(5px, 25px);
        opacity: 0;
    }
</style>

<style module>
    .blackjack-game {
        width: 1090px;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 14px;
        padding-top: 15px;
        gap: 14px;
    }

    .blackjack-game::before {
        position: absolute;
        top: 0;
        content: '';
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
            90deg,
            rgba(97, 112, 241, 0.15) 0%,
            rgba(28, 71, 182, 0.15) 100%
        );
    }

    .blackjack-game .game-table {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 530px;
        margin-bottom: 100px;
    }

    .blackjack-game .game-table img {
        pointer-events: none;
        user-select: none;
        position: absolute;
        object-fit: cover;
    }

    .blackjack-game .game-table img.table-card-l {
        width: 70px;
        right: 77%;
        top: 40px;
    }

    .blackjack-game .game-table img.table-card-r {
        width: 70px;
        left: 77%;
        top: 40px;
    }

    .blackjack-game .game-table img.table-chip-stack {
        width: 50%;
        max-width: 200px;
        top: 34px;
    }

    .blackjack-game .game-table img.table-top {
        object-fit: fill;
        height: 100%;
        width: 100%;
        min-width: 800px;
        z-index: -1;
    }

    .blackjack-game .game-table img.table-logo {
        width: 714px;
    }

    .blackjack-game .game-controls > * {
        max-width: 465px;
    }

    .blackjack-game .game-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        margin: auto;
        gap: 14px;
    }

    .blackjack-game .controls-info {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0f2b4177;
        backdrop-filter: blur(4px);
        border: 1px solid #0b325366;
        position: absolute;
        height: 100%;
        width: 100%;
        font-weight: 600;
        border-radius: 8px;
        z-index: 1;
        color: white;
        filter: brightness(1);
        animation: blackjack-game-loader 0.8s ease-out alternate infinite;
    }

    @keyframes blackjack-game-loader {
        0% {
            filter: brightness(1.25);
        }
        100% {
            filter: brightness(1);
        }
    }

    .blackjack-game .table-dealer {
        position: absolute;
        top: 75px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .blackjack-game .table-dealer .dealer-cards {
        transform: translateY(35px);
        width: 80px;
    }

    .blackjack-game .table-seats {
        list-style-type: none;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }

    @media only screen and (max-width: 1110px) {
        .blackjack-game {
            width: 100%;
        }
    }

    @media screen and (max-width: 600px) {
        .blackjack-game {
            /* flex-direction: column-reverse; */
        }

        .blackjack-game .game-table {
            /* margin-bottom: 120px; */
        }
    }
</style>
