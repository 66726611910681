<template>
    <div
        ref="battles-spinner"
        :class="[$style['battles-spinner'], $style['game-' + state], $style['game-' + playerCount]]"
    >
        <div :class="$style['spinner-confetti']">
            <canvas id="battles-confetti" :class="$style['confetti-content']"></canvas>
        </div>
        <div :class="$style['spinner-inner']">
            <div
                v-if="['pending', 'countdown'].includes(battlesGameData.game.state)"
                :class="$style['inner-countdown']"
            >
                <div :class="$style['countdown-content']">
                    <p>3</p>
                    <p>2</p>
                    <p>1</p>
                </div>
            </div>

            <div
                v-for="(bet, index) in battlesGetBets"
                v-bind:key="index"
                :class="$style['inner-element']"
            >
                <transition name="fade" mode="out-in">
                    <div
                        v-if="
                            ['created', 'countdown'].includes(battlesGameData.game.state) ===
                                true && bet === null
                        "
                        :class="$style['element-waiting']"
                    >
                        <svg
                            width="25"
                            height="27"
                            viewBox="0 0 25 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.2869 6.62293C14.9566 6.62293 16.3102 5.26943 16.3102 3.5998C16.3102 1.93016 14.9566 0.57666 13.2869 0.57666C11.6172 0.57666 10.2637 1.93016 10.2637 3.5998C10.2637 5.26943 11.6172 6.62293 13.2869 6.62293Z"
                            />
                            <path
                                d="M13.2864 26.4238C14.2881 26.4238 15.1002 25.6117 15.1002 24.6101C15.1002 23.6084 14.2881 22.7964 13.2864 22.7964C12.2847 22.7964 11.4727 23.6084 11.4727 24.6101C11.4727 25.6117 12.2847 26.4238 13.2864 26.4238Z"
                            />
                            <path
                                d="M5.85686 9.39614C7.3597 9.39614 8.57799 8.1779 8.57799 6.67512C8.57799 5.17234 7.3597 3.9541 5.85686 3.9541C4.35403 3.9541 3.13574 5.17234 3.13574 6.67512C3.13574 8.1779 4.35403 9.39614 5.85686 9.39614Z"
                            />
                            <path
                                d="M20.7148 23.0434C21.5496 23.0434 22.2264 22.3666 22.2264 21.5318C22.2264 20.697 21.5496 20.0203 20.7148 20.0203C19.8799 20.0203 19.2031 20.697 19.2031 21.5318C19.2031 22.3666 19.8799 23.0434 20.7148 23.0434Z"
                            />
                            <path
                                d="M2.78032 16.5219C4.11575 16.5219 5.19833 15.4393 5.19833 14.104C5.19833 12.7686 4.11575 11.686 2.78032 11.686C1.44489 11.686 0.362305 12.7686 0.362305 14.104C0.362305 15.4393 1.44489 16.5219 2.78032 16.5219Z"
                            />
                            <path
                                d="M23.7915 15.3134C24.459 15.3134 25 14.7724 25 14.105C25 13.4375 24.459 12.8965 23.7915 12.8965C23.1241 12.8965 22.583 13.4375 22.583 14.105C22.583 14.7724 23.1241 15.3134 23.7915 15.3134Z"
                            />
                            <path
                                d="M4.36239 20.0367C3.53545 20.8636 3.53545 22.2015 4.36239 23.0284C5.18834 23.8553 6.5283 23.8553 7.35425 23.0284C8.18118 22.2015 8.18118 20.8636 7.35425 20.0367C6.5283 19.2088 5.18932 19.201 4.36239 20.0367Z"
                            />
                            <path
                                d="M20.714 7.58346C21.2146 7.58346 21.6204 7.17767 21.6204 6.6771C21.6204 6.17654 21.2146 5.77075 20.714 5.77075C20.2134 5.77075 19.8076 6.17654 19.8076 6.6771C19.8076 7.17767 20.2134 7.58346 20.714 7.58346Z"
                            />
                        </svg>
                        <div :class="$style['waiting-text']">
                            <div :class="$style['text-box']"></div>
                            WAITING FOR PLAYER
                        </div>
                    </div>
                    <div
                        v-else-if="
                            ['created', 'countdown'].includes(battlesGameData.game.state) ===
                                true && bet !== null
                        "
                        :class="$style['element-ready']"
                    >
                        <IconVersusGradient />
                        <div :class="$style['ready-text']">
                            <div :class="$style['text-box']"></div>
                            <span>READY TO START</span>
                        </div>
                    </div>
                    <div
                        v-else-if="
                            ['pending', 'rolling'].includes(battlesGameData.game.state) === true
                        "
                        :class="$style['element-wheel']"
                    >
                        <BattlesReel
                            v-bind:ref="'reel-' + (index + 1)"
                            v-bind:style="battlesReelStyle[index + 1]"
                            v-bind:reel="battlesReels[index + 1]"
                            v-bind:pos="index + 1"
                            v-bind:running="battlesRunnings[index + 1]"
                            v-bind:coinType="bet.coinType"
                            v-bind:playerCount="playerCount"
                        />
                    </div>
                    <div
                        v-else-if="['completed'].includes(battlesGameData.game.state) === true"
                        v-bind:class="[
                            $style['element-completed'],
                            { [$style['completed-winner']]: bet.payout > 0 }
                        ]"
                    >
                        <svg
                            width="24"
                            height="23"
                            viewBox="0 0 24 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.68826 12.879L10.0621 17.2554L8.31332 19.0054L10.0646 20.7567L8.31456 22.5067L5.25137 19.4435L1.75004 22.9449L0 21.1948L3.50132 17.6923L0.43813 14.6303L2.18817 12.8803L3.93822 14.6291L5.68826 12.879ZM0.675759 0L5.06448 0.00371299L19.6898 14.6303L21.4411 12.8803L23.1912 14.6303L20.1292 17.6935L23.6293 21.1948L21.8793 22.9449L18.3779 19.4435L15.3147 22.5067L13.5647 20.7567L15.3147 19.0054L0.679472 4.37016L0.675759 0ZM18.5685 0L22.9535 0.00371299L22.956 4.36397L17.9398 9.37894L13.5635 5.00384L18.5685 0Z"
                            />
                        </svg>
                        <div :class="$style['completed-text']">
                            {{ bet.payout > 0 ? 'WINNER' : 'LOST' }}
                        </div>
                        <div :class="$style['completed-amount']">
                            <img
                                v-if="bet.coinType === 'cryptoCoin'"
                                src="@/assets/img/icons/cryptoCoin.svg"
                                alt="icon"
                            />
                            <img
                                v-else-if="bet.coinType === 'robuxCoin'"
                                src="@/assets/img/icons/robuxCoin.svg"
                                alt="icon"
                            />
                            <div :class="$style['amount-value']">
                                <span>{{ battlesFormatValue(bet.payout).split('.')[0] }}</span
                                >.{{ battlesFormatValue(bet.payout).split('.')[1] }}
                            </div>
                        </div>
                        <div :class="$style['completed-action']">
                            <button
                                v-if="
                                    index === 0 &&
                                    authUser.user !== null &&
                                    authUser.user._id === bet.user._id
                                "
                                v-on:click="battlesCreateButton()"
                                :class="$style['button-recreate']"
                                v-bind:disabled="socketSendLoading !== null"
                            >
                                <div :class="$style['button-inner']">
                                    <transition name="fade" mode="out-in">
                                        <ButtonLoading
                                            v-if="socketSendLoading === 'BattlesCreate'"
                                        />
                                        <div v-else :class="$style['inner-content']">
                                            <svg
                                                width="15"
                                                height="14"
                                                viewBox="0 0 15 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3.47861 7.85824L6.15342 10.5285L5.08395 11.5963L6.15493 12.6649L5.08471 13.7327L3.21144 11.8636L1.07023 14L0 12.9322L2.14121 10.7951L0.267935 8.9268L1.33816 7.859L2.40839 8.92605L3.47861 7.85824ZM0.413256 0L3.09715 0.00226551L12.0412 8.9268L13.1122 7.859L14.1824 8.9268L12.3099 10.7958L14.4503 12.9322L13.3801 14L11.2389 11.8636L9.36561 13.7327L8.29539 12.6649L9.36561 11.5963L0.415526 2.66649L0.413256 0ZM11.3554 0L14.0371 0.00226551L14.0386 2.66271L10.971 5.72264L8.29463 3.05313L11.3554 0Z"
                                                />
                                            </svg>
                                            RE-CREATE BATTLE
                                        </div>
                                    </transition>
                                </div>
                            </button>
                        </div>
                    </div>
                </transition>

                <div :class="$style['element-seperator']">
                    <div :class="$style['seperator-box']">
                        <div :class="$style['box-inner']">
                            <IconGroupGradient
                                v-if="
                                    battlesGameData.game.mode === 'group' ||
                                    (battlesGameData.game.mode === 'team' &&
                                        (index === 0 || index === 2))
                                "
                            />
                            <IconVersusGradient v-else />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconVersusGradient from '@/components/icons/IconVersusGradient';
    import IconGroupGradient from '@/components/icons/IconGroupGradient';
    import ButtonLoading from '@/components/ButtonLoading';
    import JSConfetti from 'js-confetti';
    import BattlesReel from '@/components/battles/BattlesReel';
    import { getLimitedItemColorFromAmount } from '@/utils/cashier';

    export default {
        name: 'BattlesSpinner',
        components: {
            IconVersusGradient,
            IconGroupGradient,
            ButtonLoading,
            BattlesReel
        },
        props: ['state', 'playerCount'],
        data() {
            return {
                battlesConfetti: null,
                battlesRunnings: {
                    1: false,
                    2: false,
                    3: false,
                    4: false
                },
                battlesReelsPosRepeater: {
                    1: null,
                    2: null,
                    3: null,
                    4: null
                },
                battlesReelsPos: 20,
                battlesReels: {
                    1: [],
                    2: [],
                    3: [],
                    4: []
                },
                battlesReelStyle: {
                    1: {
                        transform: `translateY(-${this.battlesGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    },
                    2: {
                        transform: `translateY(-${this.battlesGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    },
                    3: {
                        transform: `translateY(-${this.battlesGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    },
                    4: {
                        transform: `translateY(-${this.battlesGetItemPercentOffset(20)}%)`,
                        transition: 'none'
                    }
                }
            };
        },
        methods: {
            ...mapActions(['battlesSendCreateSocket']),
            battlesFormatValue(value) {
                if (value.toString().length > 9)
                    return (
                        parseFloat(Math.floor(value / 10) / 100000000)
                            .toFixed(3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm'
                    );
                else
                    return parseFloat(Math.floor(value / 10) / 100)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            battlesGetItemsFormated(items) {
                return items.map((item) => {
                    const color = getLimitedItemColorFromAmount(item.item.amountFixed / 1000);
                    return { ...item, color };
                });
            },
            battlesGetBoxItems(box) {
                return this.battlesGetItemsFormated(box.items);
                // let items = [];

                // for (let item of this.battlesGetItemsFormated(box.items)) {
                //     const count = Math.floor(item.tickets / 1000);
                //     for (let i = 0; i < (count <= 0 ? 1 : count); i++) {
                //         items.push(item);
                //     }
                // }

                // return items;
            },
            battlesGetOutcomeItem(outcome, items) {
                let outcomeItem = null;
                let pos = 0;

                for (const item of this.battlesGetItemsFormated(items)) {
                    pos = pos + item.tickets;
                    if (outcome <= pos) {
                        outcomeItem = item;
                        break;
                    }
                }

                return outcomeItem;
            },
            battlesAddReels(fresh) {
                let items = this.battlesGetBoxItems(
                    this.battlesGetBoxes[this.battlesGameData.game.bets[0].outcomes.length - 1]
                );

                const battlesReels = { 1: [], 2: [], 3: [], 4: [] };

                for (const reel of Object.keys(battlesReels)) {
                    for (let i = 0; i < 80; i++) {
                        battlesReels[reel].push(items[Math.floor(Math.random() * items.length)]);
                    }

                    const fillerLength = 11;
                    const fillerIndex = (fresh ? 21 : 61) - 6 - 1;
                    const fillerItems =
                        this.battlesReels[reel]?.slice(fillerIndex, fillerIndex + fillerLength) ||
                        [];
                    if (fillerItems.length === fillerLength) {
                        const insertFillerIndex = 15 - 1;
                        battlesReels[reel] = [
                            ...battlesReels[reel].slice(0, insertFillerIndex),
                            ...fillerItems,
                            ...battlesReels[reel].slice(insertFillerIndex + fillerLength)
                        ];
                    }
                }

                this.battlesReels = battlesReels;
            },
            battlesGetItemPercentOffset(index, offset = 0.5) {
                const height = 105;
                const gap = 20;
                const total = (105 + 20) * 80 - 20;
                return ((index * (height + gap) + Math.ceil(height * offset)) / total) * 100;
            },
            battlesCreateButton() {
                const boxes = this.battlesGameData.game.boxes.map((element) => ({
                    _id: element.box._id,
                    count: element.count
                }));

                const data = {
                    boxes: boxes,
                    playerCount: this.battlesGameData.game.playerCount,
                    mode: this.battlesGameData.game.mode,
                    levelMin: this.battlesGameData.game.options.levelMin,
                    funding: this.battlesGameData.game.options.funding,
                    private: this.battlesGameData.game.options.private,
                    affiliateOnly: this.battlesGameData.game.options.affiliateOnly,
                    cursed: this.battlesGameData.game.options.cursed,
                    terminal: this.battlesGameData.game.options.terminal,
                    type:
                        this.battlesGetBets.find((bet) => bet.user?._id === this.authUser.user?._id)
                            ?.coinType || this.userBalanceData.type
                };
                this.battlesSendCreateSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'soundVolume',
                'soundBattles',
                'soundTick',
                'soundUnbox',
                'soundCash',
                'soundStart',
                'soundWin',
                'soundCommon',
                'soundUncommon',
                'soundRare',
                'soundEpic',
                'soundCountdown',
                'generalTimeDiff',
                'socketSendLoading',
                'authUser',
                'battlesGameData',
                'userBalanceData'
            ]),
            battlesGetBets() {
                let bets = [];

                for (let bet = 0; bet < this.battlesGameData.game.playerCount; bet++) {
                    const index = this.battlesGameData.game.bets.findIndex(
                        (element) => element.slot === bet
                    );

                    bets.push(index !== -1 ? this.battlesGameData.game.bets[index] : null);
                }

                return bets;
            },
            battlesGetBoxes() {
                let boxes = [];

                if (this.battlesGameData.game !== null) {
                    for (const box of this.battlesGameData.game.boxes) {
                        for (let i = 0; i < box.count; i++) {
                            boxes.push(box.box);
                        }
                    }
                }

                return boxes;
            }
        },
        watch: {
            battlesGameData: {
                deep: true,
                handler(data, dataOld) {
                    if (this.battlesGameData.game.state === 'countdown') {
                        setTimeout(() => {
                            this.soundCountdown.volume = this.soundVolume;
                            this.soundCountdown.currentTime = 0;
                            this.soundCountdown.play();
                        }, 1750);
                    } else if (this.battlesGameData.game.state === 'rolling') {
                        this.soundStart.volume = this.soundVolume;
                        this.soundStart.currentTime = 0;
                        this.soundStart.play();

                        this.battlesAddReels(dataOld.length === 0);
                        // this.battlesGetReelsPos();
                        this.battlesRunnings = {
                            1: true,
                            2: true,
                            3: true,
                            4: true
                        };

                        for (const [index, bet] of this.battlesGameData.game.bets?.entries()) {
                            this.battlesReelStyle[index + 1] = {
                                transform: `translateY(-${this.battlesGetItemPercentOffset(20)}%)`,
                                transition: 'none'
                            };

                            const battlesOutcomeItem = this.battlesGetOutcomeItem(
                                bet.outcomes[bet.outcomes.length - 1],
                                this.battlesGetBoxes[bet.outcomes.length - 1].items
                            );

                            this.battlesReels[index + 1][60] = battlesOutcomeItem;

                            setTimeout(() => {
                                const timeEnding =
                                    new Date(this.battlesGameData.game.updatedAt).getTime() +
                                    3000 +
                                    Math.round(Math.random() * 2000);
                                let timeLeft =
                                    timeEnding - (new Date().getTime() + this.generalTimeDiff);
                                timeLeft = timeLeft > 0 ? timeLeft : 0;

                                const spinPos =
                                    Math.random() < 0.33
                                        ? this.battlesGetItemPercentOffset(61, Math.random() * 0.15)
                                        : Math.random() < 0.5
                                          ? this.battlesGetItemPercentOffset(60, Math.random())
                                          : this.battlesGetItemPercentOffset(
                                                59,
                                                0.95 + Math.random() * 0.05
                                            );

                                this.battlesReelStyle[index + 1] = {
                                    transform: `translateY(-${this.battlesGetItemPercentOffset(20, 0)}%)`,
                                    transition: 'transform 0.5s cubic-bezier(0.1, 0, 0.2, 1)'
                                };

                                setTimeout(() => {
                                    this.battlesReelStyle[index + 1] = {
                                        transform: `translateY(-${spinPos}%)`,
                                        transition:
                                            'transform ' +
                                            (timeLeft - 500) / 1000 +
                                            's cubic-bezier(0.1, 0, 0.2, 1)'
                                    };
                                }, 500);

                                setTimeout(() => {
                                    this.battlesReelStyle[index + 1] = {
                                        transform: `translateY(-${this.battlesGetItemPercentOffset(60)}%)`,
                                        transition: 'transform 0.25s cubic-bezier(0.1, 0, 0.2, 1)'
                                    };

                                    cancelAnimationFrame(this.battlesReelsPosRepeater[index + 1]);
                                    setTimeout(() => {
                                        this.battlesRunnings[index + 1] = false;
                                        let soundKey = 'soundCommon';

                                        switch (battlesOutcomeItem.color) {
                                            case 'green':
                                                soundKey = 'soundUncommon';
                                                break;
                                            case 'purple':
                                                soundKey = 'soundRare';
                                                break;
                                            case 'yellow':
                                            case 'red':
                                                soundKey = 'soundEpic';
                                                break;
                                        }

                                        this[soundKey].volume = this.soundVolume;
                                        this[soundKey].currentTime = 0;
                                        this[soundKey].play();
                                    }, 250);
                                }, timeLeft + 100);
                            }, 250);
                        }
                    } else if (this.battlesGameData.game.state === 'completed') {
                        const userIndex = this.battlesGetBets?.findIndex(
                            (bet) =>
                                this.authUser.user?._id && bet.user._id === this.authUser.user?._id
                        );
                        if (
                            userIndex < 0 ||
                            (userIndex >= 0 && this.battlesGetBets[userIndex].payout > 0)
                        ) {
                            this.soundWin.volume = this.soundVolume;
                            this.soundWin.currentTime = 0;
                            this.soundWin.play();

                            if (this.battlesConfetti) {
                                Array.from(Array(5)).forEach((_, i) =>
                                    setTimeout(() => {
                                        this.battlesConfetti.addConfetti({
                                            confettiRadius: 3,
                                            confettiNumber: 500 - i * 100
                                        });
                                    }, i * 1000)
                                );
                            }
                        }
                    }
                }
            }
        },
        mounted() {
            const canvas = document.getElementById('battles-confetti');
            this.battlesConfetti = new JSConfetti({ canvas });
        }
    };
</script>

<style scoped>
    .fade-leave-active {
        transition: opacity 0.1s;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 0.1s;
    }

    .fade-enter {
        opacity: 0;
    }
</style>

<style module>
    .battles-spinner {
        width: 100%;
        position: relative;
        padding: 1px;
        z-index: 1;
    }

    .battles-spinner::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #0b2840;
        font-size: 24px;
        clip-path: var(--octagonal-before);
        z-index: -1;
    }

    .battles-spinner::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #041f36;
        font-size: 24px;
        clip-path: var(--octagonal-after);
        z-index: -1;
    }

    .battles-spinner .spinner-inner {
        width: 100%;
        height: 100%;
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        background: rgba(2, 21, 36, 0.33);
        font-size: 24px;
        clip-path: var(--octagonal-main);
        z-index: 1;
    }

    .battles-spinner.game-3 .spinner-inner {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .battles-spinner.game-4 .spinner-inner {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .battles-spinner .inner-waiting {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        background: rgba(2, 21, 36, 0.6);
        backdrop-filter: blur(4px);
        z-index: 10;
    }

    .battles-spinner .waiting-info {
        width: 330px;
        height: 110px;
        position: relative;
        padding: 1px;
    }

    .battles-spinner .waiting-info:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 255, 194, 0) 0%, #00ffc2 100%);
        clip-path: polygon(
            10px 0,
            calc(100% - 10px) 0,
            100% 10px,
            100% calc(50% - 6px),
            calc(100% - 5px) 50%,
            100% calc(50% + 6px),
            100% calc(100% - 10px),
            calc(100% - 10px) 100%,
            10px 100%,
            0 calc(100% - 10px),
            0 calc(50% + 6px),
            5px 50%,
            0 calc(50% - 6px),
            0 10px
        );
    }

    .battles-spinner .info-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        background: radial-gradient(
                60% 60% at 50% 50%,
                rgba(10, 238, 179, 0.2) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            #021524;
        clip-path: polygon(
            10px 0,
            calc(100% - 10px) 0,
            100% 10px,
            100% calc(50% - 6px),
            calc(100% - 5px) 50%,
            100% calc(50% + 6px),
            100% calc(100% - 10px),
            calc(100% - 10px) 100%,
            10px 100%,
            0 calc(100% - 10px),
            0 calc(50% + 6px),
            5px 50%,
            0 calc(50% - 6px),
            0 10px
        );
    }

    .battles-spinner .inner-box {
        width: 14px;
        height: 14px;
        margin-bottom: 10px;
        transform: rotate(45deg);
        background: linear-gradient(256deg, #00ffc2 0%, #00aa6d 100%);
        border: 1px solid #00ffc2;
    }

    .battles-spinner .inner-element {
        width: 100%;
        height: 293px;
        position: relative;
        display: flex;
        transition: all 0.5s ease;
    }

    .battles-spinner.game-countdown .inner-element {
        animation: battles-initial-hide 1s ease 1s forwards;
    }

    @keyframes battles-initial-hide {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .battles-spinner.game-pending .inner-element {
        opacity: 0;
    }
    .battles-spinner.game-rolling .inner-element {
        opacity: 1;
    }

    .battles-spinner .inner-countdown {
        mask-image: linear-gradient(0deg, transparent, black, transparent);
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .battles-spinner .countdown-content {
        padding: 250px 0;
        position: absolute;
        top: calc(50% - 125px);
        animation: battles-spinner-countdown 4s ease 1s;
        left: 50%;
    }

    @keyframes battles-spinner-countdown {
        0% {
            transform: translateX(-50%) translateY(0px);
        }
        20% {
            transform: translateX(-50%) translateY(0px);
        }
        25% {
            transform: translateX(-50%) translateY(-250px);
        }
        45% {
            transform: translateX(-50%) translateY(-250px);
        }
        50% {
            transform: translateX(-50%) translateY(-500px);
        }
        70% {
            transform: translateX(-50%) translateY(-500px);
        }
        75% {
            transform: translateX(-50%) translateY(-750px);
        }
        95% {
            transform: translateX(-50%) translateY(-750px);
        }
        100% {
            transform: translateX(-50%) translateY(-1000px);
        }
    }

    .battles-spinner .countdown-content p {
        font-size: 102px;
        font-weight: 600;
        color: white;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .battles-spinner .spinner-confetti {
        position: absolute;
        pointer-events: none;
        mask-image: linear-gradient(
            90deg,
            transparent,
            black 5vw,
            black calc(100% - 5vw),
            transparent
        );
        width: calc(100% + 10vw);
        height: calc(100% + 20vh);
        top: -10vh;
        left: -5vw;
    }

    .battles-spinner .confetti-content {
        height: 100%;
        width: 100%;
        mask-image: linear-gradient(
            0deg,
            transparent,
            black 10vh,
            black calc(100% - 10vh),
            transparent
        );
    }

    .battles-spinner .element-waiting,
    .battles-spinner .element-ready,
    .battles-spinner .element-completed {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .battles-spinner .element-completed {
        justify-content: flex-end;
        padding-bottom: 34px;
    }

    .battles-spinner .element-completed.completed-winner {
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(1, 241, 180, 0.2) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    .battles-spinner .element-completed:after {
        content: '';
        max-width: 162px;
        width: 40%;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        background: linear-gradient(263deg, #f55046 45%, #9a322c 100%);
        clip-path: polygon(3px 0, calc(100% - 3px) 0, 100% 100%, 0 100%);
    }

    .battles-spinner .element-completed.completed-winner:after {
        background: linear-gradient(256deg, #00ffc2 0%, #00aa6d 100%);
    }

    .battles-spinner .element-waiting svg {
        fill: #6e95b6;
        animation: bet_loading_animation 1.5s infinite linear both;
    }

    .battles-spinner .element-ready svg {
        width: 23px;
        height: 23px;
    }

    .battles-spinner .waiting-text,
    .battles-spinner .ready-text {
        display: flex;
        align-items: center;
        margin-top: 9px;
        font-size: 14px;
        font-weight: 800;
        color: #6e95b6;
    }

    .battles-spinner .ready-text span {
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .battles-spinner .text-box {
        width: 10px;
        height: 10px;
        margin-right: 9px;
        transform: rotate(45deg);
    }

    .battles-spinner .waiting-text .text-box {
        background: radial-gradient(140% 140% at 50% 50%, #062944 0%, rgba(6, 41, 68, 0) 100%);
        border: 1px solid #0a304f;
    }

    .battles-spinner .ready-text .text-box {
        background: radial-gradient(140% 140% at 50% 50%, #01db9e 0%, rgba(6, 41, 68, 0) 100%);
        border: 1px solid #01e0a3;
    }

    .battles-spinner .element-completed svg {
        fill: #f24034;
    }

    .battles-spinner .element-completed.completed-winner svg {
        fill: #00ffc2;
    }

    .battles-spinner .completed-text {
        margin-top: 9px;
        font-size: 14px;
        font-weight: 800;
        background: linear-gradient(224deg, #f24034 37.24%, #792c00 178.55%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .battles-spinner .element-completed.completed-winner .completed-text {
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .battles-spinner .completed-amount {
        margin-top: 6px;
        display: flex;
        align-items: center;
    }

    .battles-spinner .completed-amount img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    .battles-spinner .amount-value {
        font-size: 12px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .battles-spinner .amount-value span {
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-spinner .completed-action {
        width: 100%;
        height: 35px;
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5px;
    }

    .battles-spinner button.button-recreate {
        max-width: 100%;
        width: fit-content;
        height: 100%;
        position: relative;
        margin: auto;
        padding: 1px;
    }

    .battles-spinner button.button-recreate:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 170, 109, 0) 0%, #00ffc2 100%);
        clip-path: polygon(
            7px 0,
            calc(100% - 7px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 7px) 100%,
            7px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-spinner button.button-recreate .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        clip-path: polygon(
            7px 0,
            calc(100% - 7px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 7px) 100%,
            7px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-spinner button.button-recreate .inner-content {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-spinner button.button-recreate .inner-content svg {
        height: 15px;
        min-width: 14px;
        margin-right: 5px;
        fill: #ffffff;
    }

    .battles-spinner .element-wheel {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

    .battles-spinner .element-wheel > * {
        font-size: 1.5px;
        position: absolute;
        top: 50%;
    }

    .battles-spinner.game-4 .element-wheel > * {
        font-size: 1.2px;
    }

    .battles-spinner .element-seperator {
        width: 1px;
        height: 100%;
        position: relative;
        background: linear-gradient(180deg, rgba(4, 28, 48, 0.35) 0%, rgba(1, 213, 152, 0.35) 100%);
    }

    .battles-spinner.game-created .element-seperator {
        background: linear-gradient(180deg, rgba(4, 28, 48, 0.35) 0%, rgba(1, 213, 152, 0.35) 100%);
    }

    .battles-spinner .inner-element:last-child .element-seperator {
        display: none;
    }

    .battles-spinner .seperator-box {
        width: 35px;
        height: 28px;
        position: absolute;
        top: 50%;
        left: -17px;
        transform: translate(0, -50%);
        padding: 1px;
    }

    .battles-spinner .seperator-box:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 255, 194, 0) 0%, #00ffc2 100%);
        clip-path: polygon(
            5px 0,
            calc(100% - 5px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 5px) 100%,
            5px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-spinner.game-created .seperator-box:before {
        background: linear-gradient(180deg, rgba(0, 255, 194, 0) 0%, #4c667c 100%);
    }

    .battles-spinner .box-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(10, 238, 179, 0.2) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            #051f33;
        clip-path: polygon(
            5px 0,
            calc(100% - 5px) 0,
            100% 25%,
            100% 75%,
            calc(100% - 5px) 100%,
            5px 100%,
            0 75%,
            0 25%
        );
    }

    .battles-spinner.game-created .box-inner {
        mix-blend-mode: luminosity;
    }

    .battles-spinner .box-inner svg {
        width: 16px;
        height: 16px;
    }

    .battles-spinner.game-created .box-inner svg {
        mix-blend-mode: luminosity;
        opacity: 0.8;
    }

    @keyframes bet_loading_animation {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media screen and (max-width: 900px) {
        .battles-spinner.game-3 .element-wheel > * {
            font-size: 1px;
        }
        .battles-spinner.game-4 .element-wheel > * {
            font-size: 0.9px;
        }
    }

    @media screen and (max-width: 700px) {
        .battles-spinner .element-wheel > * {
            font-size: 1px;
        }
        .battles-spinner.game-3 .element-wheel > * {
            font-size: 0.8px;
        }
        .battles-spinner.game-4 .element-wheel > * {
            font-size: 0.7px;
        }
    }
    @media screen and (max-width: 500px) {
        .battles-spinner.game-3 .element-wheel > * {
            font-size: 0.7px;
        }
        .battles-spinner.game-4 .element-wheel > * {
            font-size: 0.6px;
        }
    }
</style>
