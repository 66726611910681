export function getStatusUID(status = '') {
    status = status.toLowerCase();
    if (['created'].includes(status)) return 1;
    else if (['pending', 'open'].includes(status)) return 2;
    else if (['completed', 'confirming'].includes(status)) return 3;
    else if (['approved'].includes(status)) return 4;
    else if (['canceled', 'cancelled'].includes(status)) return 0;
    return null;
}

export function getValidatedAmount(amountParam, number = false) {
    let amount = amountParam?.toString() || '';
    // Detect multiple dots
    if (amount.includes('.') && amount.split('.').length >= 3)
        amount =
            amount.split('.')[0] +
            '.' +
            amount
                .split('.')
                .slice(1)
                .reduce((a, b) => (a += b), '');
    // Detect multiple starting 0s with dot + 0s exception
    if (amount.startsWith('0') && amount.startsWith('0.') !== true)
        amount = amount.replace(/(?<!(\d|[.]))(0{2,})(?=[^0])/g, '');
    // Detect none digit/dot characters
    amount = amount.replace(/[^\d.]/g, '') || '';

    if (number === false) return amount;
    return parseInt(amount || 0);
}
