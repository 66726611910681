<template>
    <button
        v-bind:class="[$style['cashier-element'], $style['element-' + method]]"
        v-bind:disabled="enabled !== true"
    >
        <div :class="$style['element-inner']">
            <div :class="$style['inner-background']">
                <img src="@/assets/img/cashier/element-background.png" />
            </div>
            <div :class="$style['inner-image']">
                <img v-bind:src="require('@/assets/img/cashier/' + method + '.png')" />
            </div>
            <div :class="$style['inner-text']">
                <div :class="$style['text-method']">{{ modalGetMethod }}</div>
                <div :class="$style['text-type']">{{ modalGetType }}</div>
            </div>
        </div>
    </button>
</template>

<script>
    export default {
        name: 'CashierElement',
        props: ['type', 'method', 'enabled'],
        computed: {
            modalGetMethod() {
                let method = this.method.charAt(0).toUpperCase() + this.method.substring(1);

                if (this.method === 'credit') {
                    method = 'Credit Card';
                } else if (this.method === 'csgo') {
                    method = 'CS:GO';
                } else if (this.method === 'dota') {
                    method = 'Dota 2';
                } else if (this.method === 'gift') {
                    method = 'Gift Cards';
                } else if (this.method === 'btc') {
                    method = 'Bitcoin';
                } else if (this.method === 'eth') {
                    method = 'Ethereum';
                } else if (this.method === 'ltc') {
                    method = 'Litecoin';
                } else if (this.method === 'sol') {
                    method = 'Solana';
                } else if (['usdt', 'usdc', 'g2a'].includes(this.method) === true) {
                    method = this.method.toUpperCase();
                }

                return method;
            },
            modalGetType() {
                let type = this.type.charAt(0).toUpperCase() + this.type.substring(1);

                if (this.type === 'gift') {
                    type = 'Gift Cards';
                }

                return type;
            }
        }
    };
</script>

<style module>
    .cashier-element {
        filter: drop-shadow(0px 2px 0px #00000040);
        aspect-ratio: 191 / 87;
        width: 100%;
    }

    .cashier-element:not(:disabled):hover .element-inner .inner-image,
    .cashier-element:not(:disabled):hover .element-inner .inner-text {
        filter: brightness(1.5);
    }

    .cashier-element:disabled {
        mix-blend-mode: luminosity;
        opacity: 0.35;
    }

    .cashier-element:nth-child(5) {
        margin-right: 0;
    }

    .cashier-element.element-robux .element-inner::before {
        background: #1ede53;
    }

    .cashier-element.element-limiteds .element-inner::before {
        background: #5742d4;
    }

    .cashier-element.element-csgo .element-inner::before {
        background: #e19736;
    }

    .cashier-element.element-dota .element-inner::before {
        background: #8e1212;
    }

    .cashier-element.element-credit .element-inner::before {
        background: #148eff;
    }

    .cashier-element.element-btc .element-inner::before {
        background: #f7931a;
    }

    .cashier-element.element-eth .element-inner::before {
        background: #627eea;
    }

    .cashier-element.element-ltc .element-inner::before {
        background: #345d9d;
    }

    .cashier-element.element-usdt .element-inner::before {
        background: #27a17c;
    }

    .cashier-element.element-usdc .element-inner::before {
        background: #2775ca;
    }

    .cashier-element.element-kinguin .element-inner::before {
        background: #ffbc00;
    }

    .cashier-element.element-g2a .element-inner::before {
        background: #f05f00;
    }

    .cashier-element.element-gift .element-inner::before {
        background: #148eff;
    }

    .cashier-element .element-inner {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 8px;
        overflow: hidden;
        z-index: 1;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .cashier-element .element-inner:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 5px;
        opacity: 0.2;
        clip-path: var(--octagonal-before);
    }

    .cashier-element.element-robux .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(30, 222, 83, 0.15) 0%, rgba(30, 222, 83, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(30, 222, 83, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-limiteds .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(62, 100, 200, 0.15) 0%, rgba(62, 100, 200, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(76, 62, 156, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-csgo .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(225, 151, 54, 0.15) 0%, rgba(225, 151, 54, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(225, 151, 54, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-dota .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(142, 18, 18, 0.15) 0%, rgba(142, 18, 18, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(142, 18, 18, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-credit .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(20, 142, 255, 0.15) 0%, rgba(20, 142, 255, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(20, 142, 255, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-btc .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(247, 147, 26, 0.15) 0%, rgba(247, 147, 26, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(247, 147, 26, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-eth .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(98, 126, 234, 0.15) 0%, rgba(98, 126, 234, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(98, 126, 234, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-ltc .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(52, 93, 157, 0.15) 0%, rgba(52, 93, 157, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(52, 93, 157, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-usdt .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(39, 161, 124, 0.15) 0%, rgba(39, 161, 124, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(39, 161, 124, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-sol .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(39, 117, 202, 0.15) 0%, rgba(39, 117, 202, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(180, 39, 202, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-usdc .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(39, 117, 202, 0.15) 0%, rgba(39, 117, 202, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(39, 117, 202, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-kinguin .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(255, 188, 0, 0.15) 0%, rgba(255, 188, 0, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(255, 188, 0, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(266deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-g2a .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(240, 95, 0, 0.15) 0%, rgba(240, 95, 0, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(240, 95, 0, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element.element-gift .element-inner {
        background: radial-gradient(
                650% 160% at 100% 25%,
                rgba(37, 20, 0, 0.27) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(0deg, rgba(20, 142, 255, 0.15) 0%, rgba(20, 142, 255, 0.15) 100%),
            radial-gradient(40% 40% at center, rgba(20, 142, 255, 0.5) -30%, rgba(0, 0, 0, 0) 180%),
            linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
            repeating-linear-gradient(
                -55deg,
                transparent,
                transparent 5px,
                rgba(2, 21, 36, 0.12) 5px,
                rgba(2, 21, 36, 0.12) 10px
            );
    }

    .cashier-element .inner-background {
        position: absolute;
        left: 0;
        top: 0;
    }

    .cashier-element .inner-background img {
        width: 100%;
        height: 100%;
        opacity: 0.6;
    }

    .cashier-element .inner-image {
        margin-left: 7px;
        width: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    .cashier-element .inner-image img {
        width: 100%;
    }

    .cashier-element.element-csgo .inner-image,
    .cashier-element.element-dota .inner-image,
    .cashier-element.element-btc .inner-image,
    .cashier-element.element-eth .inner-image,
    .cashier-element.element-ltc .inner-image,
    .cashier-element.element-usdt .inner-image,
    .cashier-element.element-credit .inner-image,
    .cashier-element.element-kinguin .inner-image,
    .cashier-element.element-sol .inner-image,
    .cashier-element.element-usdc .inner-image {
        width: 43px;
    }

    .cashier-element .inner-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .cashier-element .text-method {
        font-size: 14px;
        font-weight: 800;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .cashier-element.element-robux .text-method {
        color: #1ede53;
    }

    .cashier-element.element-limiteds .text-method {
        color: #8472f1;
    }

    .cashier-element.element-csgo .text-method {
        color: #e19736;
    }

    .cashier-element.element-dota .text-method {
        color: #e75b5b;
    }

    .cashier-element.element-credit .text-method {
        color: #148eff;
    }

    .cashier-element.element-btc .text-method {
        color: #f7931a;
    }

    .cashier-element.element-eth .text-method {
        color: #627eea;
    }

    .cashier-element.element-ltc .text-method {
        color: #527fc6;
    }

    .cashier-element.element-usdt .text-method {
        color: #27a17c;
    }

    .cashier-element.element-sol .text-method {
        color: #d987ff;
    }

    .cashier-element.element-usdc .text-method {
        color: #2775ca;
    }

    .cashier-element.element-kinguin .text-method {
        color: #ffbc00;
    }

    .cashier-element.element-g2a .text-method {
        color: #f05f00;
    }

    .cashier-element.element-gift .text-method {
        color: #148eff;
    }

    .cashier-element .text-type {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
