<template>
    <aside
        v-bind:class="[
            $style.chat,
            {
                [$style['chat-open']]: generalSidebarMobile === 'Chat',
                [$style['chat-rain']]: generalRain.active !== null
            }
        ]"
    >
        <div :class="$style['chat-header']">
            <RainTipDropdown />
            <div :class="$style['header-online']">
                <div :class="$style['online-dot']"></div>
                <span class="fade-green">{{ chatGetOnline }}</span>
            </div>
            <button
                v-on:click="generalSetSidebarMobile(null)"
                :class="$style['button-close']"
                class="octagonal before after hoverable"
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path
                        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                    ></path>
                </svg>
            </button>
            <div :class="$style['content-rain']">
                <RainJoin v-if="generalRain.active !== null" />
            </div>
        </div>
        <ChatMessages />
        <div :class="$style['chat-footer']">
            <div v-if="chatMessage.length > 300" :class="$style['footer-warning']">
                <svg
                    width="16"
                    version="1.1"
                    viewBox="0 0 20 20"
                    x="0px"
                    y="0px"
                    class="ScIconSVG-sc-1q25cff-1 jpczqG"
                >
                    <g>
                        <path
                            fill="#ffb31a"
                            fill-rule="evenodd"
                            d="M10.954 3.543c-.422-.724-1.486-.724-1.908 0l-6.9 11.844c-.418.719.11 1.613.955 1.613h13.798c.844 0 1.373-.894.955-1.613l-6.9-11.844zM11 15H9v-2h2v2zm0-3H9V7h2v5z"
                            clip-rule="evenodd"
                        ></path>
                    </g>
                </svg>
                <p>Reached Character Limit of 300</p>
            </div>
            <div :class="$style['footer-input']" class="octagonal after before hoverable">
                <textarea
                    ref="chatTextarea"
                    v-model="chatMessage"
                    v-on:keydown="chatMessageKeydown"
                    v-on:input="chatMessageHandler"
                    type="text"
                    placeholder="Message..."
                ></textarea>
                <ChatEmojisDropdown />
                <button
                    v-on:click="chatMessageTrigger"
                    :class="$style['input-submit']"
                    class="octagonal before after hoverable"
                >
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.428 6.08465L1.11548 0.0785018C1.00958 0.0268482 0.893308 0 0.775485 0C0.3472 0 0 0.3472 0 0.775485V0.797866C0 0.901924 0.0127591 1.00559 0.0379973 1.10655L1.21907 5.83081C1.25133 5.95986 1.36043 6.05519 1.49262 6.06989L6.68379 6.64669C6.86382 6.66667 7 6.81883 7 7C7 7.18117 6.86382 7.33333 6.68379 7.35331L1.49262 7.93011C1.36043 7.94481 1.25133 8.04014 1.21907 8.16919L0.0379973 12.8934C0.0127591 12.9944 0 13.0981 0 13.2021V13.2245C0 13.6528 0.3472 14 0.775485 14C0.893308 14 1.00958 13.9731 1.11548 13.9215L13.428 7.91535C13.778 7.74467 14 7.38939 14 7C14 6.61061 13.778 6.25533 13.428 6.08465Z"
                            fill="white"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </aside>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconChatGradient from '@/components/icons/IconChatGradient';
    import IconClose from '@/components/icons/IconClose';
    import IconUsers from '@/components/icons/IconUsers';
    import IconMessage from '@/components/icons/IconMessage';
    import IconRules from '@/components/icons/IconRules';
    import ChatRoomDropdown from '@/components/chat/ChatRoomDropdown';
    import ChatMessages from '@/components/chat/ChatMessages';
    import ChatMessageElement from '@/components/chat/ChatMessageElement';
    import ChatEmojisDropdown from '@/components/chat/ChatEmojisDropdown';
    import RainTipDropdown from '@/components/rain/RainTipDropdown';
    import RainJoin from '@/components/rain/RainJoin';

    export default {
        name: 'Chat',
        components: {
            IconChatGradient,
            IconClose,
            IconUsers,
            IconMessage,
            IconRules,
            ChatMessages,
            ChatRoomDropdown,
            ChatMessageElement,
            ChatEmojisDropdown,
            RainTipDropdown,
            RainJoin
        },
        data() {
            return {
                chatMessage: '',
                chatTextareaHeight: 45.5
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'modalsSetShow',
                'modalsSetData',
                'generalSetSidebarMobile',
                'chatSetScroll',
                'chatGetMessagesSocket',
                'chatSendMessageSocket',
                'chatSendClearSocket',
                'chatSendLockSocket',
                'generalSetUserInfoData',
                'generalGetUserInfoSocket',
                'generalSendRainCreateSocket'
            ]),
            chatAddEmoji(emoji) {
                this.chatMessage = this.chatMessage + emoji;
                this.chatMessageHandler({ scroll: true });
            },
            chatHandleCommand(command) {
                this.generalSetUserInfoData(null);
                const args = command.split(' ');

                if (
                    args[0] === '/tip' ||
                    (['/mute', '/ban'].includes(args[0]) === true &&
                        ['admin', 'mod'].includes(this.authUser.user.rank) === true)
                ) {
                    if (args[1] === undefined || args[1].trim() === '') {
                        this.notificationShow({
                            type: 'error',
                            message: 'You need to provide a valid user id.'
                        });
                        return;
                    }

                    const data = { username: args[1], type: 'command' };
                    this.generalGetUserInfoSocket(data);

                    if (args[0] === '/tip') {
                        this.modalsSetData({ chatUserTab: 'tip' });
                        this.modalsSetShow('ChatUser');
                    } else if (args[0] === '/mute') {
                        this.modalsSetShow('Mute');
                    } else if (args[0] === '/ban') {
                        this.modalsSetShow('Ban');
                    }
                } else if (args[0] === '/rain') {
                    const data = {
                        amount: Math.floor(args[1] * 1000),
                        type: this.userBalanceData.type
                    };
                    this.generalSendRainCreateSocket(data);
                } else if (args[0] === '/clear') {
                    const data = {};
                    this.chatSendClearSocket(data);
                } else if (args[0] === '/lock') {
                    const data = { value: false };
                    this.chatSendLockSocket(data);
                } else if (args[0] === '/unlock') {
                    const data = { value: true };
                    this.chatSendLockSocket(data);
                }
            },
            chatMessageHandler({ scroll }) {
                this.$refs.chatTextarea.style.height = 45.5 + 'px';
                const naturalHeight = Math.round(
                    Math.min(this.$refs.chatTextarea.scrollHeight, 12.5 * 13)
                );
                this.$refs.chatTextarea.style.height = naturalHeight + 'px';

                if (scroll === true) {
                    this.$refs.chatTextarea.scrollTop = this.$refs.chatTextarea.scrollHeight;
                }
            },
            chatMessageKeydown(event) {
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    this.chatMessageTrigger();
                }
            },
            chatMessageTrigger() {
                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                if (
                    this.generalSettings.chat.enabled === false &&
                    this.authUser.user.rank !== 'admin'
                ) {
                    this.notificationShow({
                        type: 'error',
                        message:
                            'You can not send a chat message because the action is not available at the moment.'
                    });
                    return;
                }

                if (
                    this.generalSettings.chat.mode === 'normal' &&
                    this.authUser.user.rank !== 'admin' &&
                    new Date().getTime() - this.chatLastMessage < 3000
                ) {
                    this.notificationShow({
                        type: 'error',
                        message: 'You can only send 1 message every 3 seconds.'
                    });
                    return;
                }

                if (
                    this.generalSettings.chat.mode === 'slow' &&
                    this.authUser.user.rank !== 'admin' &&
                    new Date().getTime() - this.chatLastMessage < 6000
                ) {
                    this.notificationShow({
                        type: 'error',
                        message:
                            'You can only send 1 message every 6 seconds because the chat is in slow mode.'
                    });
                    return;
                }

                const message = this.chatMessage.trim();

                if (message === '') {
                    this.notificationShow({ type: 'error', message: 'Please enter a message.' });
                    return;
                }

                if (['/tip', '/rain'].includes(message.split(' ')[0]) === true) {
                    this.chatHandleCommand(message);
                } else if (
                    ['mod', 'admin'].includes(this.authUser.user.rank) === true &&
                    ['/clear', '/lock', '/unlock', '/mute', '/ban'].includes(
                        message.split(' ')[0]
                    ) === true
                ) {
                    this.chatHandleCommand(message);
                } else {
                    const data = { message: message };
                    this.chatSendMessageSocket(data);
                }

                this.chatMessage = '';
                this.$refs.chatTextarea.style.height = 45.5 + 'px';
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'generalSidebarMobile',
                'generalSettings',
                'authUser',
                'chatScroll',
                'chatRoom',
                'chatOnline',
                'chatLastMessage',
                'generalRain',
                'userBalanceData'
            ]),
            chatGetOnline() {
                let online = 0;

                for (const [key, value] of Object.entries(this.chatOnline)) {
                    online = online + value;
                }

                return online;
            }
        }
    };
</script>

<style module>
    aside.chat {
        width: 325px;
        max-width: 100vw;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        background: linear-gradient(180deg, #0c2339 0%, #081827 calc(100% - 92px));
        box-shadow: -3px 0px 10px rgba(0, 0, 0, 0.07);
        z-index: 50;
        display: flex;
        flex-direction: column;
        transition: transform 0.3s ease;
    }

    aside.chat .chat-header {
        display: flex;
        gap: 14px;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 14px;
        height: 84px;
        border-bottom: 1px solid #133252;
    }

    aside.chat .content-rain {
        margin: 0 14px 14px 14px;
        padding-bottom: 14px;
        width: calc(100% - 28px);
        height: 0px;
        transition: height 0.2s ease;
        background: linear-gradient(
            180deg,
            rgba(6, 36, 62, 1) calc(100% - 14px),
            rgba(6, 36, 62, 0.1) 100%
        );
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        z-index: 2;
        overflow: hidden;
    }

    aside.chat.chat-rain .content-rain {
        height: calc(130px + 14px + 14px);
    }

    aside.chat .chat-header button.button-close {
        display: none;
        min-width: 48px;
        height: 48px;
        margin-left: -9px;
        font-size: 7px;
        color: #6e95b6;
    }

    aside.chat .chat-header button.button-close::after,
    aside.chat .chat-header button.button-close::before {
        background: #182c40;
    }

    aside.chat .chat-header button.button-close svg {
        fill: #6e95b6;
        font-size: 18px;
    }

    aside.chat .header-online {
        display: flex;
        background: #102f41;
        justify-content: center;
        padding: 10px;
        height: 48px;
        min-width: 48px;
        align-items: center;
        display: flex;
        gap: 7px;
        font-size: 7px;
        font-weight: 800;
        clip-path: var(--octagonal-main);
    }

    aside.chat .header-online .online-dot {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
    }

    aside.chat .header-online .online-dot::after {
        content: '';
        position: absolute;
        background: #00ffc2;
        display: block;
        width: 6px;
        height: 6px;
        font-size: 1px;
        clip-path: var(--octagonal-main);
        animation: chat-dot-after 3s ease infinite;
        animation-fill-mode: forwards;
        animation-delay: 1s;
    }

    aside.chat .header-online .online-dot::before {
        content: '';
        position: absolute;
        background: #00aa6d;
        display: block;
        width: 12px;
        height: 12px;
        font-size: 3px;
        clip-path: var(--octagonal-main);
        animation: chat-dot-before 3s ease infinite;
        animation-fill-mode: forwards;
    }

    @keyframes chat-dot-after {
        0% {
            transform: rotate(0deg) scale(1);
        }
        15% {
            transform: rotate(-45deg) scale(2);
        }
        30% {
            transform: rotate(-90deg) scale(1);
        }
        100% {
            transform: rotate(-90deg) scale(1);
        }
    }

    @keyframes chat-dot-before {
        0% {
            transform: rotate(0deg) scale(1);
        }
        15% {
            transform: rotate(-45deg) scale(1.2);
        }
        30% {
            transform: rotate(-90deg) scale(1);
        }
        100% {
            transform: rotate(-90deg) scale(1);
        }
    }

    aside.chat .header-online span {
        font-size: 14px;
    }

    aside.chat .chat-footer {
        width: 100%;
        min-height: 92px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: #061c2f;
        align-items: center;
        border-top: 1px solid #05253e;
        padding: 14px;
    }

    aside.chat .footer-warning {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: 0 14px;
        color: #59758d;
        height: 20px;
        font-size: 13px;
        font-weight: 500;
        overflow: hidden;
        animation: footer-warning 0.3s ease;
        gap: 3px;
    }

    @keyframes footer-warning {
        0% {
            height: 0px;
        }
        100% {
            height: 20px;
        }
    }

    aside.chat .footer-input {
        width: 100%;
        color: #59758d;
        display: flex;
        align-items: center;
        min-height: 63px;
        z-index: 1;
    }

    aside.chat .footer-input > * {
        position: relative;
        z-index: 1;
    }

    aside.chat .input-submit {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 16px;
        right: 14px;
        display: flex;
        color: white;
        align-items: center;
        justify-content: center;
        filter: drop-shadow(0px 2px 0px #0b1a27);
    }

    aside.chat .input-submit::before,
    aside.chat .input-submit::after {
        font-size: 3px;
        background: #203a51;
    }

    aside.chat .footer-input::before,
    aside.chat .footer-input::after {
        background: #072239;
        font-size: 7px;
    }

    aside.chat .footer-input textarea {
        appearance: none;
        width: 100%;
        min-height: 3.5em;
        height: 3.5em;
        padding: 1em;
        padding-right: 6.5em;
        display: flex;
        flex: 1;
        resize: none;
        color: currentColor;
        font-weight: 500;
        font-size: 13px;
        background-color: transparent;
    }

    aside.chat .footer-input textarea::placeholder {
        font-weight: 500;
        color: currentColor;
    }

    aside.chat .footer-actions {
        display: flex;
        align-items: center;
    }

    aside.chat .footer-actions button.button-rules {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 800;
    }

    aside.chat .footer-actions button.button-rules {
        margin-right: 15px;
        color: #327eba;
    }

    @keyframes loading_animation {
        0% {
            transform: translateX(-100%);
        }
        50% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(100%);
        }
    }

    @media only screen and (max-width: 1500px) {
        aside.chat {
            transform: translate(100%, 0);
            height: calc(100% - 72px);
            top: 72px;
            z-index: 10;
        }

        aside.chat.chat-open {
            transform: translate(0, 0);
        }

        aside.chat .chat-header {
            height: 72px;
        }

        aside.chat .chat-header button.button-close {
            display: block;
        }
    }
</style>
