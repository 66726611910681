<template>
    <div :class="[$style['navbar-notifications'], { [$style.active]: notificationActive }]">
        <button
            :class="$style['notifications-button']"
            class="octagonal after before hoverable"
            v-on:click="() => (notificationActive = !notificationActive)"
        >
            <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.1819 8.90351C16.7303 8.90351 16.3638 8.53697 16.3638 8.08533C16.3638 5.79032 15.4703 3.63359 13.8478 2.01031C13.5279 1.6904 13.5279 1.17331 13.8478 0.853397C14.1678 0.533487 14.6848 0.533487 15.0048 0.853397C16.9365 2.78513 18.0001 5.35341 18.0001 8.08533C18.0001 8.53697 17.6336 8.90351 17.1819 8.90351Z"
                    fill="#F8AE57"
                />
                <path
                    d="M0.818307 8.90351C0.366669 8.90351 0.00012207 8.53697 0.00012207 8.08533C0.00012207 5.35341 1.06376 2.78513 2.9955 0.853397C3.31541 0.533487 3.8325 0.533487 4.15241 0.853397C4.47232 1.17331 4.47232 1.6904 4.15241 2.01031C2.52995 3.63277 1.63649 5.79032 1.63649 8.08533C1.63649 8.53697 1.26994 8.90351 0.818307 8.90351Z"
                    fill="#F8AE57"
                />
                <path
                    d="M16.6737 13.838C15.4366 12.7923 14.7273 11.2639 14.7273 9.64476V7.36366C14.7273 4.48447 12.5885 2.1011 9.81814 1.70182V0.818184C9.81814 0.365728 9.4516 0 8.99996 0C8.54832 0 8.18178 0.365728 8.18178 0.818184V1.70182C5.41058 2.1011 3.27267 4.48447 3.27267 7.36366V9.64476C3.27267 11.2639 2.5633 12.7923 1.31884 13.8445C1.00057 14.117 0.818115 14.513 0.818115 14.9319C0.818115 15.7214 1.46039 16.3637 2.24994 16.3637H15.75C16.5395 16.3637 17.1818 15.7214 17.1818 14.9319C17.1818 14.513 16.9993 14.117 16.6737 13.838Z"
                    fill="#F8AE57"
                />
                <path
                    d="M9.00015 19.6364C10.4819 19.6364 11.7214 18.5802 12.0062 17.1819H5.99414C6.27887 18.5802 7.51842 19.6364 9.00015 19.6364Z"
                    fill="#F8AE57"
                />
            </svg>
            <span
                v-if="freshNotificationCount > 0"
                v-bind:key="freshNotificationCount"
                :class="$style['button-alert']"
            >
                {{ freshNotificationCount }}
            </span>
        </button>
        <div :class="$style['notifications-menu']">
            <div :class="$style['menu-wrapper']">
                <div :class="$style['menu-content']" class="octagonal before">
                    <p :class="$style['content-placeholder']" v-if="getNotifications.length < 1">
                        It seems empty here...
                    </p>
                    <div
                        v-for="(item, index) in getNotifications"
                        v-bind:key="index + item.updatedAt.toString()"
                        class="octagonal before after"
                        :class="[
                            $style['content-item'],
                            $style[item.type],
                            item.type === 'tip'
                                ? item.data.receiver.user._id === authUser.user?._id
                                    ? $style['deposit']
                                    : ''
                                : '',
                            { [$style.read]: item.read }
                        ]"
                    >
                        <div :class="$style['item-icon']" class="octagonal after">
                            <svg
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.3638 6.80122C13.0126 6.80122 12.7275 6.52122 12.7275 6.17622C12.7275 4.4231 12.0325 2.7756 10.7706 1.5356C10.5218 1.29122 10.5218 0.896223 10.7706 0.651848C11.0194 0.407473 11.4216 0.407473 11.6704 0.651848C13.1729 2.12747 14.0002 4.08935 14.0002 6.17622C14.0002 6.52122 13.7151 6.80122 13.3638 6.80122Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M0.636376 6.80122C0.285097 6.80122 0 6.52122 0 6.17622C0 4.08935 0.827289 2.12747 2.32977 0.651848C2.5786 0.407473 2.98079 0.407473 3.22961 0.651848C3.47843 0.896223 3.47843 1.29122 3.22961 1.5356C1.96767 2.77497 1.27275 4.4231 1.27275 6.17622C1.27275 6.52122 0.987656 6.80122 0.636376 6.80122Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M12.9686 10.5706C12.0064 9.77187 11.4546 8.60437 11.4546 7.3675V5.625C11.4546 3.42562 9.79114 1.605 7.63637 1.3V0.625C7.63637 0.279375 7.35127 0 6.99999 0C6.64871 0 6.36362 0.279375 6.36362 0.625V1.3C4.20821 1.605 2.54536 3.42562 2.54536 5.625V7.3675C2.54536 8.60437 1.99362 9.77187 1.02569 10.5756C0.778142 10.7837 0.63623 11.0862 0.63623 11.4062C0.63623 12.0094 1.13579 12.5 1.74989 12.5H12.2501C12.8642 12.5 13.3638 12.0094 13.3638 11.4062C13.3638 11.0862 13.2218 10.7837 12.9686 10.5706Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M7.0004 15C8.15288 15 9.11699 14.1931 9.33845 13.125H4.66235C4.88381 14.1931 5.84792 15 7.0004 15Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div :class="$style['item-content']">
                            <h4 v-if="['deposit', 'giftCard'].includes(item.type)">
                                <span class="fade-green">{{
                                    item.data.state === 'completed'
                                        ? 'Deposit Received'
                                        : item.data.state === 'cancelled'
                                          ? 'Deposit Cancelled'
                                          : 'Deposit Detected'
                                }}</span>
                            </h4>
                            <h4
                                v-else-if="
                                    item.type === 'limited' &&
                                    ((item.data.state === 'created' &&
                                        item.data.deposit.user._id === authUser.user._id) ||
                                        item.data.state === 'completed')
                                "
                            >
                                <span class="fade-gold">Item Listing</span>
                            </h4>
                            <h4
                                v-else-if="
                                    item.type === 'limited' &&
                                    ['pending', 'created'].includes(item.data.state)
                                "
                            >
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.53548 9.67584L5.39341 11.818C5.39341 11.818 5.39341 11.818 5.39336 11.818C5.39336 11.818 5.39336 11.8181 5.39331 11.8181C4.5075 12.7039 3.0661 12.704 2.18019 11.8181C1.75102 11.3889 1.51472 10.8183 1.51472 10.2114C1.51472 9.60465 1.75102 9.03415 2.18004 8.60498C2.18009 8.60493 2.18014 8.60488 2.18019 8.60483L4.32225 6.46271C4.61798 6.16694 4.61798 5.68738 4.3222 5.39165C4.02648 5.09593 3.54692 5.09593 3.25114 5.39165L1.10908 7.53377C1.10893 7.53392 1.10878 7.53412 1.10862 7.53428C0.393726 8.24942 0 9.20022 0 10.2114C0 11.2229 0.393878 12.1739 1.10913 12.8891C1.8474 13.6273 2.81708 13.9965 3.7868 13.9965C4.75652 13.9965 5.72625 13.6273 6.46442 12.8891C6.46447 12.8891 6.46447 12.889 6.46447 12.889L8.60654 10.7469C8.90226 10.4512 8.90226 9.97161 8.60649 9.67584C8.31081 9.38011 7.8313 9.38011 7.53548 9.67584Z"
                                        fill="#5B84AE"
                                    />
                                    <path
                                        d="M7.53548 9.67584L5.39341 11.818C5.39341 11.818 5.39341 11.818 5.39336 11.818C5.39336 11.818 5.39336 11.8181 5.39331 11.8181C4.5075 12.7039 3.0661 12.704 2.18019 11.8181C1.75102 11.3889 1.51472 10.8183 1.51472 10.2114C1.51472 9.60465 1.75102 9.03415 2.18004 8.60498C2.18009 8.60493 2.18014 8.60488 2.18019 8.60483L4.32225 6.46271C4.61798 6.16694 4.61798 5.68738 4.3222 5.39165C4.02648 5.09593 3.54692 5.09593 3.25114 5.39165L1.10908 7.53377C1.10893 7.53392 1.10878 7.53412 1.10862 7.53428C0.393726 8.24942 0 9.20022 0 10.2114C0 11.2229 0.393878 12.1739 1.10913 12.8891C1.8474 13.6273 2.81708 13.9965 3.7868 13.9965C4.75652 13.9965 5.72625 13.6273 6.46442 12.8891C6.46447 12.8891 6.46447 12.889 6.46447 12.889L8.60654 10.7469C8.90226 10.4512 8.90226 9.97161 8.60649 9.67584C8.31081 9.38011 7.8313 9.38011 7.53548 9.67584Z"
                                        fill="url(#paint0_linear_128_633)"
                                    />
                                    <path
                                        d="M14 3.78511C14 2.77363 13.6061 1.82269 12.8908 1.10744C11.4144 -0.369009 9.01195 -0.368958 7.53556 1.10744C7.53551 1.10754 7.53541 1.10759 7.53536 1.10769L5.39334 3.24966C5.09757 3.54538 5.09757 4.02499 5.39334 4.32072C5.54128 4.46865 5.73506 4.54257 5.92889 4.54257C6.12268 4.54257 6.31656 4.4686 6.4644 4.32072L8.60641 2.17875C8.60646 2.17865 8.60657 2.1786 8.60667 2.1785C9.49248 1.29269 10.9339 1.29264 11.8198 2.1785C12.2489 2.60767 12.4853 3.17826 12.4853 3.78511C12.4853 4.39191 12.249 4.9624 11.8199 5.39157L11.8198 5.39172L9.67772 7.53384C9.382 7.82956 9.382 8.30912 9.67777 8.6049C9.82566 8.75279 10.0195 8.82675 10.2133 8.82675C10.4071 8.82675 10.6009 8.75279 10.7488 8.6049L12.8909 6.46278C12.8911 6.46263 12.8912 6.46243 12.8914 6.46228C13.6063 5.74713 14 4.79634 14 3.78511Z"
                                        fill="#5B84AE"
                                    />
                                    <path
                                        d="M14 3.78511C14 2.77363 13.6061 1.82269 12.8908 1.10744C11.4144 -0.369009 9.01195 -0.368958 7.53556 1.10744C7.53551 1.10754 7.53541 1.10759 7.53536 1.10769L5.39334 3.24966C5.09757 3.54538 5.09757 4.02499 5.39334 4.32072C5.54128 4.46865 5.73506 4.54257 5.92889 4.54257C6.12268 4.54257 6.31656 4.4686 6.4644 4.32072L8.60641 2.17875C8.60646 2.17865 8.60657 2.1786 8.60667 2.1785C9.49248 1.29269 10.9339 1.29264 11.8198 2.1785C12.2489 2.60767 12.4853 3.17826 12.4853 3.78511C12.4853 4.39191 12.249 4.9624 11.8199 5.39157L11.8198 5.39172L9.67772 7.53384C9.382 7.82956 9.382 8.30912 9.67777 8.6049C9.82566 8.75279 10.0195 8.82675 10.2133 8.82675C10.4071 8.82675 10.6009 8.75279 10.7488 8.6049L12.8909 6.46278C12.8911 6.46263 12.8912 6.46243 12.8914 6.46228C13.6063 5.74713 14 4.79634 14 3.78511Z"
                                        fill="url(#paint1_linear_128_633)"
                                    />
                                    <path
                                        d="M4.32242 9.67596C4.4703 9.82385 4.66414 9.89782 4.85792 9.89782C5.05175 9.89782 5.24559 9.82385 5.39347 9.67596L9.67771 5.39173C9.97348 5.096 9.97348 4.61644 9.67771 4.32067C9.38198 4.02495 8.90242 4.02495 8.60665 4.32067L4.32242 8.60485C4.02664 8.90068 4.02664 9.38024 4.32242 9.67596Z"
                                        fill="#5B84AE"
                                    />
                                    <path
                                        d="M4.32242 9.67596C4.4703 9.82385 4.66414 9.89782 4.85792 9.89782C5.05175 9.89782 5.24559 9.82385 5.39347 9.67596L9.67771 5.39173C9.97348 5.096 9.97348 4.61644 9.67771 4.32067C9.38198 4.02495 8.90242 4.02495 8.60665 4.32067L4.32242 8.60485C4.02664 8.90068 4.02664 9.38024 4.32242 9.67596Z"
                                        fill="url(#paint2_linear_128_633)"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_128_633"
                                            x1="5.67535"
                                            y1="13.169"
                                            x2="7.08413"
                                            y2="8.72662"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#FFB547" />
                                            <stop offset="0.764754" stop-color="#FFEEA6" />
                                            <stop offset="1" stop-color="#F1CC0A" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint1_linear_128_633"
                                            x1="10.847"
                                            y1="7.99926"
                                            x2="12.2557"
                                            y2="3.55688"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#FFB547" />
                                            <stop offset="0.764754" stop-color="#FFEEA6" />
                                            <stop offset="1" stop-color="#F1CC0A" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint2_linear_128_633"
                                            x1="7.82848"
                                            y1="9.35417"
                                            x2="8.75418"
                                            y2="6.43571"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#FFB547" />
                                            <stop offset="0.764754" stop-color="#FFEEA6" />
                                            <stop offset="1" stop-color="#F1CC0A" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <span
                                    v-if="item.data.deposit.user._id === authUser.user._id"
                                    class="fade-gold"
                                    ><a href="https://www.roblox.com/trades" target="_blank"
                                        >Incoming Trade From Listing</a
                                    ></span
                                >
                                <span
                                    v-else-if="item.data.withdraw?.user?._id === authUser.user._id"
                                    class="fade-gold"
                                    ><a href="https://www.roblox.com/trades" target="_blank"
                                        >Limiteds Withdraw Pending</a
                                    ></span
                                >
                            </h4>
                            <h4
                                v-else-if="
                                    item.type === 'limited' && item.data.state === 'cancelled'
                                "
                            >
                                <span class="fade-gold">Cancelled Trade From Listing</span>
                            </h4>
                            <h4
                                v-else-if="
                                    item.type === 'withdraw' && item.data.state === 'approved'
                                "
                            >
                                <svg
                                    v-if="item.data.link"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.53548 9.67587L5.39341 11.818C5.39341 11.818 5.39341 11.818 5.39336 11.818C5.39336 11.818 5.39336 11.8181 5.39331 11.8181C4.5075 12.7039 3.0661 12.704 2.18019 11.8181C1.75102 11.3889 1.51472 10.8183 1.51472 10.2115C1.51472 9.60468 1.75102 9.03418 2.18004 8.60501C2.18009 8.60496 2.18014 8.60491 2.18019 8.60486L4.32225 6.46274C4.61798 6.16697 4.61798 5.68741 4.3222 5.39168C4.02648 5.09596 3.54692 5.09596 3.25114 5.39168L1.10908 7.5338C1.10893 7.53395 1.10878 7.53415 1.10862 7.53431C0.393726 8.24946 0 9.20025 0 10.2115C0 11.223 0.393878 12.1739 1.10913 12.8891C1.8474 13.6274 2.81708 13.9965 3.7868 13.9965C4.75652 13.9965 5.72625 13.6274 6.46442 12.8891C6.46447 12.8891 6.46447 12.889 6.46447 12.889L8.60654 10.7469C8.90226 10.4512 8.90226 9.97164 8.60649 9.67587C8.31081 9.38014 7.8313 9.38014 7.53548 9.67587Z"
                                        fill="#938CE8"
                                    />
                                    <path
                                        d="M14 3.78508C14 2.7736 13.6061 1.82266 12.8908 1.10741C11.4144 -0.369039 9.01195 -0.368989 7.53556 1.10741C7.53551 1.10751 7.53541 1.10756 7.53536 1.10766L5.39334 3.24963C5.09757 3.54535 5.09757 4.02496 5.39334 4.32068C5.54128 4.46862 5.73506 4.54254 5.92889 4.54254C6.12268 4.54254 6.31656 4.46857 6.4644 4.32068L8.60641 2.17872C8.60646 2.17862 8.60657 2.17857 8.60667 2.17847C9.49248 1.29266 10.9339 1.29261 11.8198 2.17847C12.2489 2.60764 12.4853 3.17823 12.4853 3.78508C12.4853 4.39188 12.249 4.96237 11.8199 5.39154L11.8198 5.39169L9.67772 7.53381C9.382 7.82953 9.382 8.30909 9.67777 8.60487C9.82566 8.75276 10.0195 8.82672 10.2133 8.82672C10.4071 8.82672 10.6009 8.75276 10.7488 8.60487L12.8909 6.46275C12.8911 6.4626 12.8912 6.4624 12.8914 6.46225C13.6063 5.7471 14 4.79631 14 3.78508Z"
                                        fill="#938CE8"
                                    />
                                    <path
                                        d="M4.32242 9.67593C4.4703 9.82382 4.66414 9.89779 4.85792 9.89779C5.05175 9.89779 5.24559 9.82382 5.39347 9.67593L9.67771 5.3917C9.97348 5.09597 9.97348 4.61641 9.67771 4.32064C9.38198 4.02492 8.90242 4.02492 8.60665 4.32064L4.32242 8.60482C4.02664 8.90065 4.02664 9.38021 4.32242 9.67593Z"
                                        fill="#938CE8"
                                    />
                                </svg>
                                <a
                                    v-if="item.data.link"
                                    v-bind:href="item.data.link"
                                    target="_blank"
                                    >Withdraw Sent</a
                                >
                                <span v-else>Withdraw Sent</span>
                            </h4>
                            <h4 v-else-if="item.type === 'withdraw'">
                                <span>{{
                                    item.data.state === 'cancelled'
                                        ? 'Withdraw Cancelled'
                                        : 'Wthdraw is Processing'
                                }}</span>
                            </h4>
                            <h4
                                v-else-if="
                                    item.type === 'tip' &&
                                    item.data.sender.user._id === authUser.user._id
                                "
                            >
                                <span>You Sent a Tip</span>
                            </h4>
                            <h4
                                v-else-if="
                                    item.type === 'tip' &&
                                    item.data.receiver.user._id === authUser.user._id
                                "
                            >
                                <span class="fade-green">Tip Received</span>
                            </h4>

                            <p v-if="item.type === 'deposit'">
                                Your
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                {{ item.data.currency ? item.data.currency.toUpperCase() : '' }}
                                deposit
                                {{
                                    item.data.state === 'completed'
                                        ? 'has been confirmed and credited.'
                                        : item.data.state === 'cancelled'
                                          ? 'has been cancelled.'
                                          : 'was detected and is confirming'
                                }}
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'limited' &&
                                    item.data.state === 'created' &&
                                    item.data.deposit.user._id === authUser.user._id
                                "
                            >
                                You listed a
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                item to the limiteds marketplace
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'limited' &&
                                    item.data.state === 'pending' &&
                                    item.data.deposit.user._id === authUser.user._id
                                "
                            >
                                Your listed item for
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                received a trade offer from user:
                                <button
                                    v-on:click="notificationUserButton(item.data.withdraw?.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.withdraw?.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.withdraw?.user?.avatar"
                                        v-bind:rank="item.data.withdraw?.user?.rank"
                                        v-bind:xp="item.data.withdraw?.user?.xp"
                                    />
                                    {{ item.data.withdraw?.user?.username }}
                                </button>
                                that must be accepted.
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'limited' &&
                                    ['pending', 'created'].includes(item.data.state) &&
                                    item.data.withdraw?.user?._id === authUser.user._id
                                "
                            >
                                Limiteds withdraw for
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                is currently pending. Waiting for user
                                <button
                                    v-on:click="notificationUserButton(item.data.deposit.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.deposit.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.deposit.user.avatar"
                                        v-bind:rank="item.data.deposit.user.rank"
                                        v-bind:xp="item.data.deposit.user.xp"
                                    />
                                    {{ item.data.deposit?.user?.username }}
                                </button>
                                to accept the trade.
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'limited' &&
                                    item.data.state === 'completed' &&
                                    item.data.deposit.user._id === authUser.user._id
                                "
                            >
                                Your listed item for
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                has just been sold to user:
                                <button
                                    v-on:click="notificationUserButton(item.data.withdraw?.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.withdraw?.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.withdraw?.user?.avatar"
                                        v-bind:rank="item.data.withdraw?.user?.rank"
                                        v-bind:xp="item.data.withdraw?.user?.xp"
                                    />
                                    {{ item.data.withdraw?.user?.username }}
                                </button>
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'limited' &&
                                    item.data.state === 'completed' &&
                                    item.data.withdraw?.user?._id === authUser.user._id
                                "
                            >
                                Your limiteds withdraw for
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                listed by user:
                                <button
                                    v-on:click="notificationUserButton(item.data.withdraw?.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.withdraw?.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.deposit.user.avatar"
                                        v-bind:rank="item.data.deposit.user.rank"
                                        v-bind:xp="item.data.deposit.user.xp"
                                    />
                                    {{ item.data.withdraw?.user?.username }}
                                </button>
                                was carried out successfully
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'limited' &&
                                    item.data.state === 'cancelled' &&
                                    item.data.deposit.user._id === authUser.user._id
                                "
                            >
                                Your listed item for
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                to be sold to user:
                                <button
                                    v-on:click="notificationUserButton(item.data.withdraw?.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.withdraw?.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.withdraw?.user?.avatar"
                                        v-bind:rank="item.data.withdraw?.user?.rank"
                                        v-bind:xp="item.data.withdraw?.user?.xp"
                                    />
                                    {{ item.data.withdraw?.user?.username }}
                                </button>
                                has been cancelled
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'limited' &&
                                    item.data.state === 'cancelled' &&
                                    item.data.withdraw?.user?._id === authUser.user._id
                                "
                            >
                                Your limiteds withdraw for
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                listed by user:
                                <button
                                    v-on:click="notificationUserButton(item.data.deposit.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.deposit.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.deposit.user.avatar"
                                        v-bind:rank="item.data.deposit.user.rank"
                                        v-bind:xp="item.data.deposit.user.xp"
                                    />
                                    {{ item.data.withdraw?.user?.username }}
                                </button>
                                has just been cancelled
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'withdraw' && item.data.coinType === 'cryptoCoin'
                                "
                            >
                                Your
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                {{ item.data.currency ? item.data.currency.toUpperCase() : '' }}
                                {{
                                    item.data.state === 'approved'
                                        ? 'withdraw was sent out successfully'
                                        : item.data.state === 'cancelled'
                                          ? 'withdraw has been cancelled'
                                          : 'withdraw is currently being processed'
                                }}
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'withdraw' && item.data.coinType === 'robuxCoin'
                                "
                            >
                                Your
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                {{
                                    item.data.state === 'completed'
                                        ? 'Robux withdraw was sent out successfully'
                                        : item.data.state === 'cancelled'
                                          ? 'Robux withdraw has been cancelled'
                                          : 'Robux withdraw is currently in queue'
                                }}
                                <span
                                    v-if="['pending', 'created'].includes(item.data.state)"
                                    :class="$style.amount"
                                >
                                    #{{ item.data.queue || '--' }}
                                </span>
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'tip' &&
                                    item.data.sender.user._id === authUser.user._id
                                "
                            >
                                Your tip of
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                was sent to user
                                <button
                                    v-on:click="notificationUserButton(item.data.receiver.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.receiver.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.receiver.user.avatar"
                                        v-bind:rank="item.data.receiver.user.rank"
                                        v-bind:xp="item.data.receiver.user.xp"
                                    />
                                    {{ item.data.receiver.user.username }}
                                </button>
                            </p>
                            <p
                                v-else-if="
                                    item.type === 'tip' &&
                                    item.data.receiver.user._id === authUser.user._id
                                "
                            >
                                You received a tip of
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                from user
                                <button
                                    v-on:click="notificationUserButton(item.data.sender.user)"
                                    :class="[
                                        $style.user,
                                        notificationGetUserClasses(item.data.sender.user)
                                    ]"
                                >
                                    <AvatarImage
                                        v-bind:image="item.data.sender.user.avatar"
                                        v-bind:rank="item.data.sender.user.rank"
                                        v-bind:xp="item.data.sender.user.xp"
                                    />
                                    {{ item.data.sender.user.username }}
                                </button>
                            </p>
                            <p v-else-if="item.type === 'giftCard'">
                                Your
                                <span :class="$style.amount">
                                    <img
                                        v-bind:src="
                                            require(
                                                `@/assets/img/icons/${item.data.coinType || 'cryptoCoin'}.svg`
                                            )
                                        "
                                    />
                                    {{
                                        parseFloat((item.data.amount / 1000).toFixed(2))
                                            .toLocaleString()
                                            .replace('.00', '')
                                            .replace(/(?<=[.]\d)[0]/, '')
                                    }}
                                </span>
                                deposit through gift cards
                                {{
                                    item.data.state === 'completed'
                                        ? 'has been credited'
                                        : item.data.state === 'cancelled'
                                          ? 'has been cancelled'
                                          : 'is currently being processed'
                                }}
                            </p>
                        </div>
                        <button
                            v-on:click="notificationDeleteButton(item)"
                            class="octagonal before after hoverable"
                            :class="$style['item-delete']"
                        >
                            <svg
                                width="13"
                                height="15"
                                viewBox="0 0 13 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.948853 3.71769C0.948853 3.83387 1.33807 10.4535 1.53559 13.5934C1.59078 14.4619 2.16589 14.9877 3.046 14.9877H9.88932C10.8914 14.9877 11.4201 14.4735 11.4811 13.4773C11.6437 10.7934 12.0387 4.24924 12.0678 3.71478L0.948853 3.71769ZM8.83493 10.3257C9.20963 10.7004 9.20963 11.3075 8.83493 11.6822C8.46024 12.0569 7.85317 12.0569 7.47847 11.6822L6.50832 10.712L5.53817 11.6822C5.16348 12.0569 4.55641 12.0569 4.18171 11.6822C3.80701 11.3075 3.80701 10.7004 4.18171 10.3257L5.15186 9.35558L4.18171 8.38253C3.80701 8.00783 3.80701 7.40076 4.18171 7.02607C4.55641 6.65137 5.16348 6.65137 5.53817 7.02607L6.50832 7.99621L7.47847 7.02607C7.85317 6.65137 8.46024 6.65137 8.83493 7.02607C9.20963 7.40076 9.20963 8.00783 8.83493 8.38253L7.86479 9.35558L8.83493 10.3257Z"
                                    fill="#4D7598"
                                />
                                <path
                                    d="M12.0561 1.07446C11.2689 1.06865 10.4847 1.06284 9.70043 1.07736C9.65396 0.537103 9.20084 0.118835 8.65767 0.118835H4.34138C3.79822 0.118835 3.34509 0.537103 3.30152 1.07736C2.49403 1.06284 1.68364 1.06865 0.876153 1.07446C0.452077 1.08027 0.144185 1.31554 0.0425231 1.68734C-0.13466 2.33507 0.257466 2.86952 0.928436 2.87243C2.79031 2.87824 4.64927 2.87533 6.50824 2.87533H12.0503C12.6515 2.87533 12.9972 2.5384 13.0001 1.9778C13.003 1.41721 12.6544 1.07736 12.0561 1.07446Z"
                                    fill="#4D7598"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div :class="$style['menu-void']" v-on:click="notificationActive = false"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getColorFromLevel, getColorFromXP } from '@/utils/user';
    import { getLinkFromCryptoTransaction } from '@/utils/cashier';
    import { getStatusUID } from '@/utils/general';
    import { mapActions, mapGetters } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'NavbarNotifications',
        components: {
            AvatarImage
        },
        data() {
            return {
                notificationActive: false
            };
        },
        methods: {
            ...mapActions([
                'userGetNotifications',
                'userSendNotificationAction',
                'modalsSetShow',
                'generalSetUserInfoData'
            ]),
            notificationDeleteButton(item) {
                this.userSendNotificationAction({ action: 'delete', notification: item });
                setTimeout(() => (this.notificationActive = true), 50);
            },
            notificationUserButton(user) {
                if (
                    this.authUser.user === null ||
                    user.rank === 'system' ||
                    user.stats === undefined
                ) {
                    return;
                }

                this.generalSetUserInfoData({
                    ...user,
                    level: Math.floor(Math.pow(user.xp / 1000 / 100, 1 / 3))
                });
                this.modalsSetShow('ChatUser');
            },
            notificationGetUserClasses(user) {
                let color;
                if (user?.xp) {
                    color = getColorFromXP(user.xp);
                } else {
                    color = getColorFromLevel(user?.level);
                }

                return `${this.$style[color]} ${this.$style[user?.rank]}`;
            },
            formatNotification(item) {
                try {
                    const notification = JSON.parse(JSON.stringify(item));
                    notification.data = item.document.data;

                    // User id/object reformat
                    const sortUnpopulatedUser = (prop) => {
                        if (typeof notification.data[prop]?.user === 'string')
                            notification.data[prop].user = { _id: notification.data[prop].user };
                    };
                    sortUnpopulatedUser('receiver');
                    sortUnpopulatedUser('sender');
                    sortUnpopulatedUser('withdraw');
                    sortUnpopulatedUser('deposit');

                    // Notification type reformat
                    if (item.document.model === 'LimitedTransaction') {
                        notification.type = 'limited';
                        if (notification.data.deposit.user._id === this.authUser.user._id)
                            notification.data.coinType = 'robuxCoin';
                        else notification.data.coinType = notification.data.withdraw.coinType;
                    } else if (item.document.model === 'TipTransaction') {
                        notification.type = 'tip';
                    } else if (item.document.model === 'CreditTransaction') {
                        notification.type = item.document.data.type;
                        notification.data.coinType = 'cryptoCoin';
                    } else if (item.document.model === 'CryptoTransaction') {
                        notification.data.currency = (
                            item.document.data.data.cryptoCurrency ||
                            item.document.data.data.currency
                        )?.toLowerCase();
                        notification.data.link = getLinkFromCryptoTransaction(item.document.data);
                        notification.type = item.document.data.type;
                        notification.data.coinType = 'cryptoCoin';
                    } else if (item.document.model === 'GiftTransaction') {
                        notification.type = 'giftCard';
                    } else if (item.document.model === 'RobuxOffer') {
                        notification.type = item.document.data.type;
                    } else if (item.document.model === 'RobuxTransaction') {
                        if (notification.data.withdraw.user._id === this.authUser.user._id) {
                            notification.data.coinType = item.document.data.offer.withdraw.coinType;
                            notification.type = item.document.data.offer.withdraw.type;
                        } else {
                            notification.data.coinType = item.document.data.offer.deposit.coinType;
                            notification.type = item.document.data.offer.deposit.type;
                        }
                    } else {
                        notification.type = item.document.data.type;
                    }

                    // Additional data reformat
                    const status = getStatusUID(notification.data.state);
                    if (status === null) notification.data.state = 'created';
                    else
                        notification.data.state = [
                            'cancelled',
                            'created',
                            'pending',
                            'completed',
                            'approved'
                        ][status];

                    delete notification.document;
                    return notification;
                } catch (err) {
                    console.log(err);
                    return null;
                }
            }
        },
        computed: {
            ...mapGetters(['authUser', 'userNotifications']),
            freshNotificationCount() {
                return this.userNotifications.filter(({ deleted, read }) =>
                    deleted === true ? false : read !== true
                ).length;
            },
            getNotifications() {
                let notifications = this.userNotifications
                    .filter(
                        (notification) =>
                            notification.deleted !== true &&
                            notification.document?.data !== undefined
                    )
                    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                    .map(this.formatNotification);

                return [
                    ...notifications.filter((notification) => notification?.read === false),
                    ...notifications.filter((notification) => notification?.read === true)
                ];
            }
        },
        created() {
            this.userGetNotifications();

            let self = this;
            document.addEventListener('click', function (event) {
                if (!self.$el.contains(event.target) && self.notificationActive === true) {
                    self.notificationActive = false;
                }
            });
        },
        watch: {
            notificationActive: {
                deep: true,
                handler() {
                    if (this.notificationActive === false) {
                        const unreads = this.getNotifications.filter(({ deleted, read }) =>
                            deleted === true ? false : read === false
                        );
                        if (unreads.length)
                            this.userSendNotificationAction({
                                action: 'read',
                                notification: unreads
                            });
                    }
                }
            }
        }
    };
</script>

<style module>
    .navbar-notifications {
        position: relative;
        z-index: 1;
    }

    .navbar-notifications .notifications-button {
        display: flex;
        align-items: center;
        color: #dfa12a;
        font-size: 3px;
        width: 44px;
        height: 44px;
        justify-content: center;
    }

    .navbar-notifications .notifications-button::before,
    .navbar-notifications .notifications-button::after {
        background: #20314a;
    }

    .navbar-notifications .button-alert {
        justify-content: center;
        background: #cb3434;
        align-items: center;
        position: absolute;
        padding: 0px 4.5px 1px 4.5px;
        border-radius: 8px;
        font-weight: 700;
        font-size: 12px;
        color: white;
        display: flex;
        height: 17px;
        right: -7px;
        z-index: 1;
        top: -3px;
        animation: button-alert 0.2s ease;
    }
    @keyframes button-alert {
        0% {
            transform: scale(1);
        }
        20% {
            transform: scale(2);
        }
        100% {
            transform: scale(1);
        }
    }

    .navbar-notifications .notifications-menu {
        max-height: 0vh;
        width: 348px;
        position: absolute;
        left: 50%;
        top: calc(100% + 14px);
        transform: translateX(-50%);
        transition: max-height 0.3s ease;
        font-size: 7px;
        clip-path: var(--octagonal-main);
        overflow: auto;
    }

    .navbar-notifications.active .notifications-button::before {
        background: currentColor !important;
    }
    .navbar-notifications.active .notifications-button {
        filter: unset !important;
    }

    .navbar-notifications.active .notifications-menu {
        max-height: calc(80vh - 100px);
    }

    .navbar-notifications .menu-void {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .navbar-notifications .menu-wrapper {
        height: 100%;
        width: 100%;
    }

    .navbar-notifications .menu-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        background: #0e293e;
        padding: 7px 7px 8px 7px;
        gap: 7px;
        min-height: 80px;
        font-size: 7px;
        clip-path: var(--octagonal-main);
        position: relative;
        z-index: 1;
    }

    .navbar-notifications .menu-content::before {
        background: #2a4a66;
    }

    .navbar-notifications .content-placeholder {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translate(0, -50%);
        color: #547696;
        font-size: 14px;
        font-weight: 600;
    }

    .navbar-notifications .content-item {
        filter: drop-shadow(0px 2px 0px #0f2133);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        padding: 7px 7px 7px 0px;
        overflow: hidden;
        color: #938ce8;
        gap: 5px;
    }

    .navbar-notifications .content-item.read > div {
        opacity: 0.5;
        filter: brightness(0.9);
    }

    .navbar-notifications .content-item.giftCard,
    .navbar-notifications .content-item.deposit {
        color: #2adf72;
    }

    .navbar-notifications .content-item.limited {
        color: #e3b849;
    }

    .navbar-notifications .content-item::after {
        font-size: 3px;
        background: #12283e;
    }

    .navbar-notifications .content-item::before {
        font-size: 3px;
        background: #203e59;
    }

    .navbar-notifications .item-icon {
        min-width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navbar-notifications .item-icon svg {
        position: relative;
        filter: drop-shadow(0px 0px 10px color-mix(in srgb, currentColor, #ffffff88));
        z-index: 1;
    }

    .navbar-notifications .item-icon::after {
        background: currentColor;
        font-size: 3px;
        opacity: 0.25;
        filter: brightness(0.5);
        width: calc(100% + 10px);
        left: -10px;
        z-index: 0;
    }

    .navbar-notifications .item-content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .navbar-notifications .item-content h4 a {
        color: currentColor;
    }

    .navbar-notifications .item-content h4 {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 600;
        font-size: 15px;
    }

    .navbar-notifications .item-content p {
        font-size: 14px;
        font-weight: 500;
        color: #6286a3;
    }

    .navbar-notifications .item-content .amount,
    .navbar-notifications .item-content .user {
        font-weight: 500;
        color: white;
    }

    .navbar-notifications .item-content .amount img,
    .navbar-notifications .item-content .user img {
        max-width: 18px;
        max-height: 18px;
        object-fit: cover;
        margin-bottom: -4px;
        margin-left: 4px;
        margin-right: 1px;
    }

    .navbar-notifications .item-content .user img {
        border-width: 2px;
    }

    .navbar-notifications .item-content .user.blue {
        color: #559ee4;
    }

    .navbar-notifications .item-content .user.sky {
        color: #01bcf9;
    }

    .navbar-notifications .item-content .user.yellow {
        color: #f9c201;
    }

    .navbar-notifications .item-content .user.purple {
        color: #6562ff;
    }

    .navbar-notifications .item-content .user.gold,
    .navbar-notifications .item-content .user.vip {
        color: #ffe600;
    }

    .navbar-notifications .item-content .user.mod {
        color: #498fdd;
    }

    .navbar-notifications .item-content .user.partner {
        color: #d64548;
    }

    .navbar-notifications .item-content .user.admin {
        color: #0dd4b1;
    }

    .navbar-notifications .item-delete {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 29px;
        height: 29px;
        filter: drop-shadow(0px 2px 0px #0f2133);
        font-size: 2px;
        color: #547696;
    }

    .navbar-notifications .item-delete::after {
        background: #142e46;
    }

    .navbar-notifications .item-delete::before {
        background: #16314b;
    }

    nav > * > .navbar-notifications {
        margin-left: -15px;
    }

    @media screen and (max-width: 1100px) {
        nav > * > .navbar-notifications .notifications-menu {
            transform: unset;
            left: unset;
            right: calc(-5px - 44px - 10px - 47px);
        }

        nav > * > .navbar-notifications {
            margin-left: auto;
        }
    }
</style>
