<template>
    <button
        v-on:click="cashierSetFilterSort(cashierFilterSort === 'Highest' ? 'Lowest' : 'Highest')"
        v-bind:class="[
            $style['cashier-filter-sort'],
            { [$style['sort-lowest']]: cashierFilterSort === 'Lowest' }
        ]"
        class="octagonal hoverable"
    >
        <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.0537109 1.00548C0.0979443 0.913659 0.167173 0.836172 0.25345 0.781913C0.339727 0.727654 0.439555 0.698824 0.541475 0.698731H11.3807C11.4828 0.698692 11.5829 0.727508 11.6693 0.781858C11.7558 0.836208 11.8251 0.91388 11.8694 1.00592C11.9136 1.09797 11.9309 1.20064 11.9194 1.3021C11.9078 1.40357 11.8678 1.4997 11.804 1.57942L7.58696 6.85053V12.0799C7.58699 12.1871 7.55524 12.2919 7.49572 12.381C7.4362 12.4701 7.35158 12.5396 7.25257 12.5807C7.18682 12.6081 7.11625 12.6221 7.045 12.6219C6.90128 12.6218 6.76345 12.5647 6.66184 12.4631L4.49399 10.2952C4.39235 10.1936 4.33523 10.0558 4.3352 9.91207V6.85053L0.118204 1.57942C0.0544378 1.49956 0.014548 1.40331 0.00313663 1.30176C-0.0082747 1.2002 0.00925763 1.0975 0.0537109 1.00548V1.00548Z"
                fill="white"
            />
            <path
                d="M0.0537109 1.00548C0.0979443 0.913659 0.167173 0.836172 0.25345 0.781913C0.339727 0.727654 0.439555 0.698824 0.541475 0.698731H11.3807C11.4828 0.698692 11.5829 0.727508 11.6693 0.781858C11.7558 0.836208 11.8251 0.91388 11.8694 1.00592C11.9136 1.09797 11.9309 1.20064 11.9194 1.3021C11.9078 1.40357 11.8678 1.4997 11.804 1.57942L7.58696 6.85053V12.0799C7.58699 12.1871 7.55524 12.2919 7.49572 12.381C7.4362 12.4701 7.35158 12.5396 7.25257 12.5807C7.18682 12.6081 7.11625 12.6221 7.045 12.6219C6.90128 12.6218 6.76345 12.5647 6.66184 12.4631L4.49399 10.2952C4.39235 10.1936 4.33523 10.0558 4.3352 9.91207V6.85053L0.118204 1.57942C0.0544378 1.49956 0.014548 1.40331 0.00313663 1.30176C-0.0082747 1.2002 0.00925763 1.0975 0.0537109 1.00548V1.00548Z"
                fill="url(#paint0_linear_287_6981)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_287_6981"
                    x1="-1.77603"
                    y1="0.176841"
                    x2="14.1147"
                    y2="4.46101"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00FFC2" />
                    <stop offset="1" stop-color="#00AA6D" />
                </linearGradient>
            </defs>
        </svg>
        <p>
            SORT BY:&nbsp;
            <span>{{ cashierFilterSort === 'Highest' ? 'HIGH' : 'LOW' }}</span>
        </p>
        <svg
            :class="$style.caret"
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.87755 0.267579L0.348556 0.267578C0.0395947 0.267578 -0.117801 0.640667 0.103719 0.862188L3.36822 4.12671C3.50229 4.26079 3.72381 4.26079 3.85795 4.12671L7.12245 0.862189C7.34391 0.640668 7.18652 0.267579 6.87755 0.267579Z"
                fill="#406992"
            />
        </svg>
    </button>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'CashierFilterSort',
        methods: {
            ...mapActions(['cashierSetFilterSort'])
        },
        computed: {
            ...mapGetters(['cashierFilterSort'])
        },
        created() {
            let self = this;
            document.addEventListener('click', function (event) {
                if (!self.$el.contains(event.target) && self.cashierDropdown === true) {
                    self.cashierSetDropdown(false);
                }
            });
        }
    };
</script>

<style module>
    .cashier-filter-sort {
        width: 100%;
        max-width: 179px;
        min-width: min-content;
        display: flex;
        align-items: center;
        flex: 1;
        gap: 7px;
        padding: 7px 14px;
        position: relative;
        font-size: 5px;
        clip-path: var(--octagonal-main);
        background: #0a2036;
        height: 44px;
    }

    .cashier-filter-sort p {
        color: #406992;
        font-weight: 600;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: auto;
        white-space: nowrap;
    }

    .cashier-filter-sort p span {
        color: white;
    }

    .cashier-filter-sort svg.caret {
        transform: rotate(0deg);
        transition: transform 0.2s ease;
    }

    .cashier-filter-sort.sort-lowest svg.caret {
        transform: rotate(180deg);
    }

    .cashier-filter-sort::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        font-size: 5px;
        background: #112942;
        clip-path: var(--octagonal-before);
    }
</style>
