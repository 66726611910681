<template>
    <div :class="$style['roll-history']">
        <div :class="$style['history-title']">PREVIOUS ROLLS:</div>
        <transition-group :class="$style['history-list']" name="list" tag="div">
            <RollHistoryElement
                v-for="game of rollHistory"
                v-if="game !== null"
                v-bind:key="game._id"
                v-bind:game="game"
            />
        </transition-group>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import RollHistoryElement from '@/components/roll/RollHistoryElement';

    export default {
        name: 'RollHistory',
        components: {
            RollHistoryElement
        },
        computed: {
            ...mapGetters(['rollHistory'])
        }
    };
</script>

<style module>
    .roll-history {
        display: flex;
        align-items: center;
        width: calc(100% + 7.5vw);
        margin-bottom: 22px;
        mask-image: linear-gradient(90deg, black, black calc(100% - 15vw), transparent);
    }

    .roll-history .history-title {
        min-width: 116px;
        font-size: 13px;
        font-weight: 600;
        color: #406992;
        white-space: nowrap;
    }

    .roll-history .history-list {
        mask-image: linear-gradient(90deg, transparent, black 7px);
        flex: 1;
        display: flex;
    }

    @media only screen and (max-width: 750px) {
        .roll-history {
            flex-direction: column;
            align-items: flex-start;
        }

        .roll-history .history-list {
            margin-top: 3px;
            transform: translateX(-7px);
        }
    }
</style>
