<template>
    <div :class="$style['roll-controls']">
        <div :class="$style['controls-content']">
            <form :class="$style['content-field']" v-on:submit.prevent="rollBetButton">
                <div :class="$style['field-amount']">
                    <p :class="$style['amount-title']">ENTER BET:</p>
                    <div :class="$style['amount-container']">
                        <div :class="$style['container-input']">
                            <input
                                v-model="rollAmount"
                                v-on:input="rollValidateInput"
                                type="text"
                                placeholder="0"
                                required
                            />
                            <div :class="$style['input-icon']">
                                <img
                                    v-if="userBalanceData.type === 'cryptoCoin'"
                                    src="@/assets/img/icons/cryptoCoin.svg"
                                    alt="icon"
                                />
                                <img
                                    v-else-if="userBalanceData.type === 'robuxCoin'"
                                    src="@/assets/img/icons/robuxCoin.svg"
                                    alt="icon"
                                />
                            </div>
                        </div>
                        <div :class="$style['container-buttons']">
                            <button type="button" v-on:click="rollSetAmount('clear')">Clear</button>
                            <button
                                type="button"
                                v-on:click="rollSetAmount('+1k')"
                                :class="$style['button-add']"
                            >
                                +1k
                            </button>
                            <button
                                type="button"
                                v-on:click="rollSetAmount('+5k')"
                                :class="$style['button-add']"
                            >
                                +5k
                            </button>
                            <button
                                type="button"
                                v-on:click="rollSetAmount('+10k')"
                                :class="$style['button-extra']"
                            >
                                +10k
                            </button>
                            <button
                                type="button"
                                v-on:click="rollSetAmount('+50k')"
                                :class="$style['button-extra']"
                            >
                                +50k
                            </button>
                            <button type="button" v-on:click="rollSetAmount('1/2')">1/2</button>
                            <button type="button" v-on:click="rollSetAmount('2x')">2x</button>
                            <button
                                type="button"
                                v-on:click="rollSetAmount('max')"
                                :class="$style['button-max']"
                            >
                                Max
                            </button>
                        </div>
                    </div>
                </div>
                <div :class="$style['field-bet']">
                    <p :class="$style['bet-title']">MULTIPLIER</p>
                    <div :class="$style['bet-container']">
                        <div :class="$style['container-input']">
                            <input
                                v-model="rollMultiplier"
                                v-on:input="rollValidateInput"
                                type="text"
                                placeholder="0"
                                required
                            />
                            <div :class="$style['input-icon']">
                                <svg
                                    width="10"
                                    height="9"
                                    viewBox="0 0 10 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.39852 0.397058L5.06743 2.52794L6.68866 0.397058C6.87939 0.132353 7.12841 0 7.4357 0H9.53376C9.69271 0 9.79337 0.0397059 9.83576 0.119118C9.87814 0.198529 9.85165 0.295588 9.75628 0.410294L6.51382 4.35441L9.91523 8.58971C10 8.69559 10.0212 8.79265 9.97881 8.88088C9.94702 8.96029 9.85165 9 9.69271 9H7.41981C7.1549 9 6.91118 8.86765 6.68866 8.60294L4.82901 6.23382L3.12831 8.60294C2.92698 8.86765 2.68327 9 2.39717 9H0.28321C0.134862 9 0.0447934 8.95147 0.0130045 8.85441C-0.0187843 8.74853 0.00770639 8.64265 0.0924766 8.53677L3.33494 4.32794L0.203738 0.436765C0.108371 0.330882 0.0765822 0.233823 0.108371 0.145588C0.150756 0.0485293 0.246123 0 0.394471 0H2.65148C2.93758 0 3.18659 0.132353 3.39852 0.397058Z"
                                        fill="#5C9BDB"
                                    />
                                </svg>
                            </div>
                        </div>
                        <button type="submit" :class="$style['button-bet']">
                            <img width="17" src="@/assets/img/chip.png" />
                            <span class="fade-green">Place Bet</span>
                        </button>
                    </div>
                </div>
            </form>
            <div :class="$style['content-recent']">
                <div :class="$style['recent-title']">RECENT BETS</div>
                <div :class="$style['recent-list']">
                    <RollRecentElement
                        v-for="bet of rollGetRecentBets"
                        v-bind:key="bet._id"
                        v-on:click.native="rollSetBet(bet)"
                        v-bind:bet="bet"
                    />
                </div>
            </div>
            <button
                v-on:click="rollToggleRepeat"
                :class="[$style['content-repeat'], { [$style.active]: rollRepeat }]"
            >
                <p :class="$style['repeat-title']">REPEAT BETS</p>
                <div></div>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import RollRecentElement from '@/components/roll/RollRecentElement';
    import { getValidatedAmount } from '@/utils/general';

    export default {
        name: 'RollControls',
        components: {
            RollRecentElement
        },
        data() {
            return {
                rollAmount: null,
                rollMultiplier: null,
                rollRepeat: false
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'rollSendBetSocket']),
            rollValidateInput() {
                this.rollAmount = getValidatedAmount(this.rollAmount);
            },
            rollSetAmount(action) {
                let amount = Math.floor(this.rollAmount * 1000);

                if (action === 'clear') {
                    amount = 0;
                } else if (action === '+1k') {
                    amount = Math.floor(amount + 1000000);
                } else if (action === '+5k') {
                    amount = Math.floor(amount + 5000000);
                } else if (action === '+10k') {
                    amount = Math.floor(amount + 10000000);
                } else if (action === '+50k') {
                    amount = Math.floor(amount + 50000000);
                } else if (action === '1/2') {
                    amount = Math.floor(amount / 2);
                } else if (action === '2x') {
                    amount = Math.floor(amount * 2);
                } else if (action === 'max') {
                    amount =
                        this.userBalanceData.type === 'robuxCoin'
                            ? this.authUser.user.balance.robuxCoin <=
                              process.env.VUE_APP_ROLL_MAX_AMOUNT * 1000
                                ? this.authUser.user.balance.robuxCoin
                                : process.env.VUE_APP_ROLL_MAX_AMOUNT * 1000
                            : this.authUser.user.balance.cryptoCoin <=
                                process.env.VUE_APP_ROLL_MAX_AMOUNT * 1000
                              ? this.authUser.user.balance.cryptoCoin
                              : process.env.VUE_APP_ROLL_MAX_AMOUNT * 1000;
                }

                let maxAmount = amount;
                if (maxAmount > this.authUser.user?.balance?.[this.userBalanceData.type])
                    maxAmount = this.authUser.user?.balance?.[this.userBalanceData.type];
                if (maxAmount > process.env.VUE_APP_ROLL_MAX_AMOUNT * 1000)
                    maxAmount = process.env.VUE_APP_ROLL_MAX_AMOUNT * 1000;
                this.rollAmount = parseFloat(Math.floor(maxAmount / 10) / 100)
                    .toFixed(2)
                    .replace('.00', '')
                    .replace(/(?<=[.]\d)[0]/, '');
            },
            rollSetBet(bet) {
                this.rollAmount = parseFloat(bet.amount / 1000).toFixed(2);
                this.rollMultiplier = parseFloat(bet.multiplier / 100).toFixed(2);
            },
            rollToggleRepeat() {
                this.rollRepeat = !this.rollRepeat;
            },
            rollBetButton() {
                if (this.socketSendLoading !== null) {
                    return;
                }

                if (this.authUser.user === null) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Sign in to proceed with this action.'
                    });
                    return;
                }

                const amount = Math.floor(this.rollAmount * 1000);
                const multiplier = Math.floor(this.rollMultiplier * 100);

                if (amount === null || isNaN(amount) === true || amount <= 0) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered bet amount is invalid.'
                    });
                    return;
                }

                if (multiplier === null || isNaN(multiplier) === true || multiplier <= 100) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered bet multiplier is invalid.'
                    });
                    return;
                }

                const data = {
                    amount: amount,
                    multiplier: multiplier,
                    type: this.userBalanceData.type
                };
                this.rollSendBetSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'rollRecent',
                'authUser',
                'userBalanceData',
                'rollGame',
                'rollBets'
            ]),
            rollGetRecentBets() {
                let bets = [];

                for (let bet of this.rollRecent) {
                    const index = bets.findIndex(
                        (element) =>
                            element.baseAmount === bet.amount &&
                            element.multiplier === bet.multiplier &&
                            element.coinType === bet.coinType
                    );

                    if (index !== -1) {
                        bets[index].amount = bets[index].amount + bet.amount;
                    } else {
                        bet = { ...bet, baseAmount: bet.amount };
                        bets.push(bet);
                    }
                }

                bets.sort(function (a, b) {
                    return +b.amount - +a.amount;
                });

                return bets;
            }
        },
        watch: {
            rollGame: {
                deep: true,
                handler(data, oldData) {
                    if (data.state === 'created' && this.rollRepeat) {
                        const userBets = this.rollBets.filter(
                            (element) => element.user._id === this.authUser.user._id
                        );
                        if (userBets.length < 1 && this.rollGetRecentBets.length > 0) {
                            this.rollGetRecentBets.forEach((bet, i) =>
                                setTimeout(
                                    () =>
                                        this.rollSendBetSocket({
                                            amount: bet.amount,
                                            type: bet.coinType,
                                            multiplier: bet.multiplier
                                        }),
                                    i * 500
                                )
                            );
                        }
                    }
                }
            }
        }
    };
</script>

<style module>
    .roll-controls {
        position: relative;
        z-index: 2;
        padding: 4px;
        font-size: 18px;
        clip-path: var(--octagonal-main);
        background: linear-gradient(45deg, #0d243a, #0d243a);
    }

    .roll-controls .controls-content {
        height: 100%;
        width: 100%;
        font-size: 0.89em;
        background: linear-gradient(90deg, #0b2137, #0c2033);
        padding: 24px;
        display: flex;
        flex-direction: column;
        position: relative;
        clip-path: var(--octagonal-main);
    }

    .roll-controls .controls-content::before {
        background: #1a2f4d;
    }

    .roll-controls .content-field {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        margin-bottom: 18px;
        gap: 18px 10px;
    }

    .roll-controls .content-recent {
        margin-bottom: 18px;
        min-height: 58px;
    }

    .roll-controls .content-repeat {
        display: flex;
        align-items: center;
        gap: 0px;
        width: fit-content;
        margin-bottom: 5px;
    }

    .roll-controls .content-repeat div {
        width: 33px;
        height: 11px;
        position: relative;
        margin-left: 12px;
        margin-bottom: -1px;
        filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .roll-controls .content-repeat div:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-color: #031523;
        font-size: 1px;
        clip-path: var(--octagonal-main);
    }

    .roll-controls .content-repeat div:after {
        content: '';
        width: 21px;
        height: 21px;
        position: absolute;
        top: -5px;
        left: 0;
        background: #0a2f4b;
        font-size: 2px;
        clip-path: var(--octagonal-main);
        transition: transform 0.3s ease;
    }

    .roll-controls .content-repeat.active div::before {
        background: linear-gradient(0deg, #00ffc2, #00aa6d);
    }

    .roll-controls .content-repeat.active div:after {
        transform: translate(12px, 0);
        background: #dcdcdc;
        border: 4px solid white;
        box-sizing: border-box;
    }

    .roll-controls .field-amount {
        max-width: 721px;
        flex: 1;
    }

    .roll-controls .field-bet {
        max-width: 410px;
        min-width: 292px;
        flex: 1;
    }

    .roll-controls .bet-container {
        display: flex;
        gap: 7px;
    }

    .roll-controls .bet-title,
    .roll-controls .repeat-title,
    .roll-controls .recent-title,
    .roll-controls .amount-title {
        color: #406992;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .roll-controls .repeat-title {
        margin-bottom: unset;
    }

    .roll-controls .content-repeat.active .repeat-title {
        color: white;
    }

    .roll-controls .bet-container .container-input,
    .roll-controls .amount-container {
        position: relative;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        min-height: 46px;
        gap: 3px 0px;
        width: 100%;
        font-size: 5px;
        background: #052339;
        clip-path: var(--octagonal-main);
    }

    .roll-controls .amount-container {
        padding: 6px 0;
    }

    .roll-controls .bet-container .container-input::before,
    .roll-controls .amount-container::before {
        background: #11385b;
    }

    .roll-controls .container-input::after {
        content: ':';
        font-size: 14px;
        position: absolute;
        left: 42px;
        color: #406992;
        top: 49%;
        transform: translateY(-50%);
    }

    .roll-controls .container-input input {
        appearance: none;
        background: none;
        font-size: 14px;
        font-weight: 600;
        color: white;
        width: 100%;
        height: 46px;
        padding-left: 50px;
    }

    .roll-controls .container-buttons button::after {
        background: #1d4465;
        z-index: -1;
        font-size: 3px;
    }

    .roll-controls .container-buttons button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        flex: 1;
        font-size: 12px;
        padding: 0 7px;
        color: #6192bb;
        font-weight: 600;
    }

    .roll-controls .container-buttons {
        min-width: 362px;
        padding: 0px 6px;
        align-items: center;
        gap: 1px;
        display: flex;
        flex: 1;
    }

    .roll-controls .container-input {
        position: relative;
        height: 46px;
        font-size: 14px;
        font-weight: 600;
        flex: 1;
        color: white;
    }

    .roll-controls .amount-container .container-input {
        margin-top: -6px;
        margin-bottom: -6px;
        min-width: 150px;
    }

    .roll-controls .input-icon {
        left: 7px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        background: linear-gradient(360deg, #0f426b 11.31%, #0d3355 104.17%);
        justify-content: center;
        position: absolute;
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .roll-controls .button-bet {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 46px;
        gap: 7px;
        flex: 1;
        font-size: 15px;
        font-weight: 600;
    }

    .roll-controls .button-bet::before {
        font-size: 5px;
        background: #2b8373;
    }

    .roll-controls .button-bet::after {
        font-size: 5px;
        background: #1b5455;
    }

    .roll-controls .recent-list {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .roll-controls .button-bet::before,
    .roll-controls .controls-content::before,
    .roll-controls .bet-container .container-input::before,
    .roll-controls .amount-container::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        clip-path: var(--octagonal-before);
    }

    .roll-controls .button-bet::after,
    .roll-controls .container-buttons button::after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        clip-path: var(--octagonal-after);
    }

    @media screen and (max-width: 550px) {
        .roll-controls .controls-content {
            padding: 16px;
        }

        .roll-controls .field-bet,
        .roll-controls .container-buttons {
            min-width: unset;
        }
        .roll-controls .container-buttons button.button-extra {
            display: none;
        }
    }
</style>
